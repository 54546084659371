/**
 * 商品
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";
import * as types from "../../../mutation-types/backyard-types";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/orders`

const order_subscription = {
  namespaced: true,
  state: {
    page: 1,
    query: {
      order_detail_id: null,
    },
    pagination: {
      current_page: 1,
      total_count: 0
    },
    order_subscriptions: [],
  },
  getters: {
    hasOrderDetailId (state) {
      return state.query.order_detail_id != null
    },
    viewPage(state) {
      return state.page
    },
    searchOrderDetailId (state) {
      return state.query.order_detail_id
    }
  },
  actions: {
    fetchAll({ commit, state }, { order_id, page }) {
      const _page = (page !== undefined) ? page : state.page
      const params = { page: _page, order_detail_id: state.query.order_detail_id }
      commit(types.BACK_ORDER_SUBSCRIPTION_QUERY, { params })
      const api_path = `${API_PATH}/${order_id}/order_subscriptions`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_ORDER_SUBSCRIPTION, { content })
          resolve(content)
        })
      })
    },
    edit({ _commit }, { order_id, id }) {
      const api_path = `${API_PATH}/${order_id}/order_subscriptions/${id}/edit`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    update( { commit }, { order_id, params } ) {
      const api_path = `${API_PATH}/${order_id}/order_subscriptions/${params.id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.patch(api_path, params).then(data => {
          let content = data.content
          commit('snackbar/showInfo', {message: '取り消しました'}, {root: true})
          resolve(content)
        })
      })
    },
    fetchQuery({ commit, state }, { order_detail_id }) {
      const orderDetailId = (order_detail_id !== undefined)? order_detail_id : state.query.order_detail_id
      const params = {
        order_detail_id: orderDetailId,
      }
      commit(types.BACK_ORDER_SUBSCRIPTION_QUERY, { params })
    },
    // sendMail( { commit, state }, {order_id, id} ) {
    //   const api_path = `${API_PATH}/${order_id}/order_subscriptions/${id}/send_mail`
    //   return new Promise( ( resolve, _reject ) => {
    //     Vue.$http.get(api_path).then( data => {
    //       let content = data.content
    //       commit('snackbar/showInfo', { message: '送信しました' }, { root: true })
    //       resolve(content)
    //     } )
    //   })
    // }
  },
  mutations: {
    [types.BACK_ORDER_SUBSCRIPTION] (state, { content }) {
      state.order_subscriptions = content.order_subscriptions
      state.pagination = content.pagination
    },
    [types.BACK_ORDER_SUBSCRIPTION_QUERY] (state, { params }) {
      state.query.order_detail_id = params.order_detail_id
      state.page = params.page
    },
  },
}

export default order_subscription