<!-- バックヤード > 会員お知らせ一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn
            color="primary"
            @click="noticeMemberCreate"
          >
            会員お知らせ追加
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="キーワード"
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="2">
                  <v-checkbox
                    v-model="dispDateSearch"
                    label="表示日で絞り込む"
                    hide-details="auto"
                    class="pa-0 ma-0"
                  />
                </v-col>
                <v-col cols="2">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    min-width="290px"
                    offset-y
                    :disabled="dispDateSearch === false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="date"
                        dense
                        label="表示日"
                        readonly
                        hide-details="auto"
                        outlined
                        clearable
                        placeholder=" "
                        :disabled="dispDateSearch === false"
                        v-on="on"
                        @click:clear="date = null"
                      />
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="date"
                      @input="dateMenu = false"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <div>時点表示されているもの</div>
                </v-col>
              </v-row>

              <v-btn color="primary">
                検索
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    タイトル
                  </th>
                  <th class="text-left">
                    詳細
                  </th>
                  <th class="text-center">
                    登録会員数
                  </th>
                  <th class="text-left">
                    表示期間
                  </th>
                  <th class="text-left">
                    作成日時
                  </th>
                  <th class="text-left">
                    更新日時
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="noticeMember in noticeMembers"
                  :key="noticeMember.id"
                >
                  <td>{{ noticeMember.name }}</td>
                  <td>{{ noticeMember.detail }}</td>
                  <td class="text-center">
                    {{ noticeMember.count }}
                  </td>
                  <td>{{ noticeMember.date }}</td>
                  <td>{{ noticeMember.create }}</td>
                  <td>{{ noticeMember.update }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="noticeMemberEdit"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-pagination
      v-model="page"
      circle
      :length="10"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      :total-visible="5"
    />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";

  export default {
    components: {
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        dispDateSearch: false,
        date: new Date().toISOString().substr(0, 10),
        dateMenu: false,
        page: 1,
        noticeMembers:[
          {id: 1, name: '値下げしました', detail: 'ジューンブライドに向けて…値下げしました。この機会にぜひお試し下さい！', date: '2020-06-01〜2020-06-30', count: '30', create: '2020-06-01 10:00', update: '2020-06-30 12:00'},
          {id: 2, name: 'コロナ還元セール実施中', detail: 'コロナに…還元セールを実施中です。', date: '2020-04-01〜9999-12-31', count: '20', create: '2020-06-01 10:00', update: '2020-06-30 12:00'},
        ],
      }
    },
    methods: {
      noticeMemberCreate: function () {
        this.$router.push({name: 'byNoticeMemberCreateIndex'})
      },
      noticeMemberEdit: function () {
        this.$router.push({name: 'byNoticeMemberEditIndex', params: {id: 1}})
      },
    }
  }
</script>

<style lang="scss">

</style>
