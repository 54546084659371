<!-- バックヤード > 商品追加 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-row>
      <v-col>
        <basic-form
          v-if="product"
          :is-edit="false"
          :product="product"
          :company-mode="company_mode"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

  import {mapActions} from "vuex";
  import BasicForm from './forms/_Basic'

  export default {
    name: 'ProductNew',
    components: {
      BasicForm,
    },
    mixins: [
    ],
    data () {
      return {
        product: null,
        company_mode: '',
        breadcrumbs: [
          { text: '商品一覧', disabled: false, to: '/backyard/products', exact: true },
          { text: '商品詳細', disabled: true, to: '' },
        ],
      }
    },
    created() {
      this.newProduct().then( (content) => {
        this.product = content
        this.company_mode = content.company_mode
      } )
    },
    methods: {
      ...mapActions('backProduct', {
        newProduct: 'new'
      })
    }
  }
</script>

<style lang="scss">

</style>
