/**
 * 商品
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const order_delivery_document = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetchAll({ _commit }, { product_id }) {
      const api_path = `${API_PATH}/${product_id}/product_documents`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {},
}

export default order_delivery_document