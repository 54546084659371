/**
 * 商品
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/product_reviews`

const product_review = {
  namespaced: true,
  state: {
    product: null,
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {
      keyword: '',
      start_date: null,
      end_date: null,
      with_unpublished: false,
    },
  },
  getters: {
    hasProductReviews: ( state ) => {
      return (state.pagination.total_count > 0)
    },
  },
  actions: {
    fetchAll({ dispatch, commit, state }, {keyword, start_date, end_date, with_unpublished, page}) {
      const queries = {
        keyword: keyword,
        start_date: start_date,
        end_date: end_date,
        with_unpublished: with_unpublished,
      }
      dispatch('fetchQuery', queries)
      const params = {
        keyword: state.query.keyword,
        start_date: state.query.start_date,
        end_date: state.query.end_date,
        with_unpublished: state.query.with_unpublished,
        page: page
      }
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_PRODUCT_REVIEW, { content })
          resolve(data.content)
        })
      })
    },
    fetchQuery({ commit, state}, {keyword, start_date, end_date, with_unpublished }) {
      const keywordText = (keyword !== undefined)? keyword : state.query.keyword
      const startDate = (start_date !== undefined)? start_date : state.query.start_date
      const endDate = (end_date !== undefined) ? end_date : state.query.end_date
      const withUnpublished = (with_unpublished !== undefined) ? with_unpublished: state.query.with_unpublished
      const params = {
        keyword: keywordText,
        start_date: startDate,
        end_date: endDate,
        with_unpublished: withUnpublished
      }
      commit(types.BACK_PRODUCT_REVIEW_QUERY, { params })
    },
    resetQuery({ commit, state }) {
      const params = {
        keyword: '',
        start_date: null,
        end_date: null,
        with_unpublished: false
      }
      commit(types.BACK_PRODUCT_REVIEW_QUERY, { params })
    },
    fetch ({ commit, state }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    toggle( { commit, state }, { id } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.patch(api_path).then(data => {
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
  },
  mutations: {
    [types.BACK_PRODUCT_REVIEW] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_PRODUCT_REVIEW_QUERY] (state, { params }) {
      state.query.keyword = params.keyword
      state.query.start_date = params.start_date
      state.query.end_date = params.end_date
      state.query.with_unpublished = params.with_unpublished
    },
  },
}

export default product_review