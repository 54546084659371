<!-- バックヤード > SHOPサイト画面設定 > 基本情報タブ -->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-title class="py-1">
                サイト
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:20"
                      name="サイトタイトル"
                    >
                      <v-text-field
                        v-model="site_title"
                        :error-messages="errors"
                        dense
                        label="サイトタイトル"
                        hide-details="auto"
                        outlined
                        counter="20"
                        placeholder="bizRENT"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-row
                      class="text-center"
                      @dragover.prevent
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    >
                      <v-col>
                        <validation-provider
                          v-slot="{ errors }"
                          name="favicon"
                          rules="mimes:image/jpeg,image/png"
                        >
                          <v-file-input
                            v-model="favicon_file"
                            :background-color="isDragging ? 'blue' : 'null'"
                            :error-messages="errors"
                            :show-size="1000"
                            accept="image/jpeg,image/png"
                            append-icon="mdi-folder"
                            counter
                            dense
                            label="favicon"
                            hint="画像ファイルを選択してください"
                            outlined
                            :clearable="false"
                            persistent-hint
                            placeholder=" "
                            prepend-icon=""
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>
                              <span
                                v-else-if="index === 2"
                                class="overline grey--text text--darken-3 mx-2"
                              >+{{ files.length - 2 }} File(s)</span>
                            </template>
                          </v-file-input>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="8"
                    md="3"
                  >
                    <div
                      v-if="favicon_image_url"
                      style="position: relative;border: 1px solid #ddd;"
                    >
                      <v-card style="background-color: #fafafa;">
                        <v-card-subtitle>
                          プレビュー
                        </v-card-subtitle>
                        <v-card-text
                          class="justify-center"
                        >
                          <v-card
                            flat
                            tile
                          >
                            <v-img
                              :src="favicon_image_url | load_image_url"
                              max-height="200"
                              contain
                              aspect-ratio="1"
                              class="gray lighten-4 align-center"
                            />
                          </v-card>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            small
                            color="error"
                            @click="delete_favicon"
                          >
                            削除
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider />

              <v-card-title>
                ヘッダータイトル
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="image_logo"
                  dense
                  hide-details="auto"
                  class="pa-0 ma-0"
                >
                  <v-radio
                    label="テキスト"
                    value="text"
                    class="fitLabel"
                  />
                  <v-sheet
                    :hidden="image_logo !== 'text'"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          rules="max:10"
                          name="タイトル"
                        >
                          <v-text-field
                            v-model="logo_title"
                            dense
                            label="タイトル"
                            hide-details="auto"
                            outlined
                            clearable
                            counter="10"
                            placeholder=" "
                            :disabled="image_logo !== 'text'"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-sheet>

                  <v-radio
                    label="画像"
                    value="image"
                    class="fitLabel"
                  />
                  <v-sheet
                    :hidden="image_logo !== 'image'"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="favicon"
                          rules="mimes:image/jpeg,image/png"
                        >
                          <v-row
                            class="text-center"
                            @dragover.prevent
                            @dragenter="onDragEnter"
                            @dragleave="onDragLeave"
                            @drop="onLogoFileDrop"
                          >
                            <v-col>
                              <v-file-input
                                v-model="logo_file"
                                :background-color="isDragging ? 'blue' : 'null'"
                                :disabled="image_logo !== 'image'"
                                :error-messages="errors"
                                :show-size="1000"
                                accept="image/jpeg,image/png"
                                append-icon="mdi-folder"
                                dense
                                counter
                                hint="画像ファイルを選択して下さい"
                                label="ヘッダ画像"
                                hide-details="auto"
                                outlined
                                persistent-hint
                                placeholder=" "
                                prepend-icon=""
                              >
                                <template v-slot:selection="{ index, text }">
                                  <v-chip
                                    v-if="index < 2"
                                    color="deep-purple accent-4"
                                    dark
                                    label
                                    small
                                  >
                                    {{ text }}
                                  </v-chip>
                                  <span
                                    v-else-if="index === 2"
                                    class="overline grey--text text--darken-3 mx-2"
                                  >+{{ files.length - 2 }} File(s)</span>
                                </template>
                              </v-file-input>
                            </v-col>
                          </v-row>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="8"
                        md="3"
                      >
                        <div
                          v-if="logo_image_url"
                          style="position: relative;border: 1px solid #ddd;"
                        >
                          <v-card style="background-color: #fafafa;">
                            <v-card-subtitle>
                              プレビュー
                            </v-card-subtitle>
                            <v-card-text
                              class="justify-center"
                            >
                              <v-card
                                flat
                                tile
                              >
                                <v-img
                                  :src="logo_image_url | load_image_url"
                                  max-height="200"
                                  contain
                                  aspect-ratio="1"
                                  class="gray lighten-4 align-center"
                                />
                              </v-card>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />
                              <v-btn
                                small
                                color="error"
                                @click="delete_logo_file"
                              >
                                削除
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </div>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-radio-group>
              </v-card-text>
            </v-card>

            <v-divider />

            <v-card-title>
              ヘッダーカラー
            </v-card-title>
            <v-col
                cols="12"
                md="4"
            >
              <v-app-bar
                  class="header_style"
                  :style="headerStyle"
                  dense
                  dark
              >
                <v-app-bar-nav-icon>
                </v-app-bar-nav-icon>
                <v-toolbar-title>
                  {{ logo_title || 'ヘッダータイトル' }}
                </v-toolbar-title>

              </v-app-bar>
            </v-col>

            <v-card-text>
              <v-row>
                <v-col
                    cols="12"
                    md="2"
                >
                  <v-text-field
                      v-model="header_color"
                      hide-details="auto"
                      label="ヘッダー背景カラー"
                      dense
                      readonly
                      outlined
                      placeholder=" "
                      @click="headerColorMenu = true"
                  >
                    <template v-slot:append>
                      <v-menu
                          v-model="headerColorMenu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div
                              :style="headerColorSwatchStyle"
                              v-on="on"
                          />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                                v-model="header_color"
                                flat
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="2"
                >
                  <v-text-field
                      v-model="icon_color"
                      hide-details="auto"
                      label="ヘッダー文字カラー"
                      dense
                      readonly
                      outlined
                      placeholder=" "
                      @click="iconColorMenu = true"
                  >
                    <template v-slot:append>
                      <v-menu
                          v-model="iconColorMenu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div
                              :style="iconColorSwatchStyle"
                              v-on="on"
                          />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                                v-model="icon_color"
                                flat
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-title>
              申込ボタンカラー
            </v-card-title>
            <v-col
                cols="12"
                md="4"
            >
              <v-btn
                  :style="buttonColorStyle"
              >
                <v-icon>mdi-cart</v-icon>
                レンタル申し込み
                <div class="lang_lbl">{{$t('view.レンタル申し込み')}}</div>
              </v-btn>
            </v-col>

            <v-card-text>
              <v-row>
                <v-col
                    cols="12"
                    md="2"
                >
                  <v-text-field
                      v-model="button_color"
                      hide-details="auto"
                      label="申込ボタン背景カラー"
                      dense
                      readonly
                      outlined
                      placeholder=" "
                      @click="buttonColorMenu = true"
                  >
                    <template v-slot:append>
                      <v-menu
                          v-model="buttonColorMenu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div
                              :style="buttonColorSwatchStyle"
                              v-on="on"
                          />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                                v-model="button_color"
                                flat
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="2"
                >
                  <v-text-field
                      v-model="button_font_color"
                      hide-details="auto"
                      label="申込ボタン文字カラー"
                      dense
                      readonly
                      outlined
                      placeholder=" "
                      @click="buttonFontColorMenu = true"
                  >
                    <template v-slot:append>
                      <v-menu
                          v-model="buttonFontColorMenu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div
                              :style="buttonFontColorSwatchStyle"
                              v-on="on"
                          />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                                v-model="button_font_color"
                                flat
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>

              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-title>
              その他カラー
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col
                    cols="12"
                    md="2"
                >
                  <v-text-field
                      v-model="site_color"
                      hide-details="auto"
                      label="その他カラー"
                      dense
                      readonly
                      outlined
                      placeholder=" "
                      @click="siteColorMenu = true"
                  >
                    <template v-slot:append>
                      <v-menu
                          v-model="siteColorMenu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div
                              :style="siteColorSwatchStyle"
                              v-on="on"
                          />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                                v-model="site_color"
                                flat
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-title>
              企業ロゴ
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="mimes:image/jpeg,image/png"
                    name="画像ファイル"
                  >
                    <v-row
                      class="text-center"
                      @dragover.prevent
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onCompanyLogoDrop"
                    >
                      <v-col>
                        <v-file-input
                          v-model="company_logo"
                          :background-color="isDragging ? 'blue' : 'null'"
                          :clearable="false"
                          :show-size="1000"
                          :error-messages="errors"
                          dense
                          counter
                          label="企業ロゴ"
                          hint="画像ファイルを選択して下さい"
                          accept="image/jpeg,image/png"
                          append-icon="mdi-folder"
                          hide-details="auto"
                          outlined
                          persistent-hint
                          placeholder=" "
                          prepend-icon=""
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>
                            <span
                              v-else-if="index === 2"
                              class="overline grey--text text--darken-3 mx-2"
                            >+{{ files.length - 2 }} File(s)</span>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="8"
                  md="3"
                >
                  <div
                    v-if="company_image_url"
                    style="position: relative;border: 1px solid #ddd;"
                  >
                    <v-card style="background-color: #fafafa;">
                      <v-card-subtitle>
                        プレビュー
                      </v-card-subtitle>
                      <v-card-text
                        class="justify-center"
                      >
                        <v-card
                          flat
                          tile
                        >
                          <v-img
                            :src="company_image_url | load_image_url"
                            max-height="200"
                            contain
                            aspect-ratio="1"
                            class="gray lighten-4 align-center"
                          />
                        </v-card>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer />
                        <v-btn
                          v-if="company_image_url"
                          small
                          color="error"
                          @click="delete_company_logo"
                        >
                          削除
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-title>
              検索条件指定
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-radio-group
                    v-model="use_refined_search_area"
                    row
                    dense
                    hide-details="auto"
                    class="pa-0 ma-0"
                  >
                    <v-radio
                      label="条件を常に表示"
                      :value="true"
                    />
                    <v-radio
                      label="≡記号表示"
                      :value="false"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-title>
              フッター設定
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="max:255"
                    name="コピーライト"
                  >
                    <v-text-field
                      v-model="footer_text"
                      label="コピーライト"
                      dense
                      counter="255"
                      hide-details="auto"
                      outlined
                      placeholder=" "
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="mb-1">
                    カテゴリーフッター表示
                  </p>
                  <v-radio-group
                    v-model="use_category_fotter"
                    row
                    dense
                    hide-details="auto"
                    class="pa-0 ma-0"
                  >
                    <v-radio
                      :value="false"
                      label="表示しない"
                    />
                    <v-radio
                      :value="true"
                      label="表示する"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <p class="mb-1">
                    {{ companyModeCaptionName }}フッター表示
                  </p>
                  <v-radio-group
                    v-model="use_campaign_fotter"
                    row
                    dense
                    hide-details="auto"
                    class="pa-0 ma-0"
                  >
                    <v-radio
                      label="表示しない"
                      :value="false"
                    />
                    <v-radio
                      label="表示する"
                      :value="true"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="mb-1">
                    タグフッター表示
                  </p>
                  <v-radio-group
                    v-model="use_tag_fotter"
                    row
                    dense
                    hide-details="auto"
                    class="pa-0 ma-0"
                  >
                    <v-radio
                      label="表示しない"
                      :value="false"
                    />
                    <v-radio
                      label="表示する"
                      :value="true"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-title>利用規約</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <p class="my-1">
                    利用規約の表示（決済手続き中の画面表示）
                  </p>
                  <v-radio-group
                    v-model="use_agree_terms"
                    row
                    dense
                    hide-details="auto"
                    class="pa-0 ma-0"
                  >
                    <v-radio
                      :value="false"
                      label="表示しない"
                    />
                    <v-radio
                      :value="true"
                      label="表示する"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-select
                    v-model="terms_my_page_id"
                    :items="my_pages"
                    item-value="id"
                    item-text="title"
                    label="利用規約マイページ"
                    dense
                    hide-details="auto"
                    outlined
                    placeholder=" "
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="max:255"
                    name="利用規約の注釈"
                  >
                    <v-text-field
                      v-model="terms_title"
                      dense
                      counter="255"
                      hide-details="auto"
                      label="利用規約の注釈"
                      outlined
                      placeholder=" "
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-title>支払方法の注釈</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <p class="my-1">
                    支払方法の注釈の表示（支払方法の選択画面表示）
                  </p>
                  <v-radio-group
                      v-model="use_payment_note"
                      row
                      dense
                      hide-details="auto"
                      class="pa-0 ma-0"
                  >
                    <v-radio
                        :value="false"
                        label="表示しない"
                    />
                    <v-radio
                        :value="true"
                        label="表示する"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    md="5"
                >
                  <v-select
                      v-model="payment_note_my_page_id"
                      :items="my_pages"
                      item-value="id"
                      item-text="title"
                      label="支払方法の注釈マイページ"
                      dense
                      hide-details="auto"
                      outlined
                      placeholder=" "
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                      v-slot="{ errors }"
                      rules="max:255"
                      name="支払方法の注釈タイトル"
                  >
                    <v-text-field
                        v-model="payment_note_title"
                        dense
                        counter="255"
                        hide-details="auto"
                        label="支払方法の注釈タイトル"
                        outlined
                        placeholder=" "
                        :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :block="$vuetify.breakpoint.mdAndDown"
                :disabled="invalid"
                @click="submit"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AlertMessage from '../../../../components/AlertMessage'
import { mapActions } from 'vuex'
export default {
  name: 'BasicForm',
  components: {
    AlertMessage,
  },
  props: {
    themeOption: {
      type: Object,
      default: () => {
        return {}
      },
    },
    companyModeCaptionName: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      siteColorMenu: false,
      headerColorMenu: false,
      iconColorMenu: false,
      buttonColorMenu: false,
      buttonFontColorMenu: false,

      favicon_image_url: this.themeOption.favicon_image_url,
      favicon_file: null,
      has_favicon_deleted: false,
      company_image_url: this.themeOption.company_image_url,
      company_logo: null,
      has_company_logo_deleted: false,
      image_logo: this.themeOption.image_logo,
      logo_title: this.themeOption.logo_title,
      logo_image_url: this.themeOption.logo_image_url,
      logo_file: null,
      has_logo_deleted: false,
      site_color: this.themeOption.site_color,
      header_color: this.themeOption.header_color,
      icon_color: this.themeOption.icon_color,
      button_color: this.themeOption.button_color,
      button_font_color: this.themeOption.button_font_color,
      footer_text: this.themeOption.footer_text,
      twitter_url: this.themeOption.twitter_url,
      facebook_url: this.themeOption.facebook_url,
      site_title: this.themeOption.site_title,
      use_category_fotter: this.themeOption.use_category_fotter,
      use_campaign_fotter: this.themeOption.use_campaign_fotter,
      use_tag_fotter: this.themeOption.use_tag_fotter,
      use_refined_search_area: this.themeOption.use_refined_search_area,
      use_agree_terms: this.themeOption.use_agree_terms,
      terms_my_page_id: this.themeOption.terms_my_page_id,
      terms_title: this.themeOption.terms_title,
      use_payment_note: this.themeOption.use_payment_note,
      payment_note_my_page_id: this.themeOption.payment_note_my_page_id,
      payment_note_title: this.themeOption.payment_note_title,

      isDragging: false,
      dragCount: 0,
      my_pages: null,
    }
  },
  computed: {
    siteColorSwatchStyle() {
      let _default_color = this.site_color == null ? '#2D3753' : this.site_color
      return this.colorChangeEvent(_default_color, this.siteColorMenu)
    },
    headerColorSwatchStyle() {
      let _default_color = this.header_color == null ? '#2D3753' : this.header_color
      return this.colorChangeEvent(_default_color, this.headerColorMenu)
    },
    iconColorSwatchStyle() {
      let _default_icon_color = this.icon_color == null ? '#FFFFFF' : this.icon_color
      return this.colorChangeEvent(_default_icon_color, this.iconColorMenu)
    },
    buttonColorSwatchStyle() {
      let _default_color = this.button_color == null ? '#FFFFFF' : this.button_color
      return this.colorChangeEvent(_default_color, this.buttonColorMenu)
    },
    buttonFontColorSwatchStyle() {
      let _default_color = this.button_font_color == null ? '#FFFFFF' : this.button_font_color
      return this.colorChangeEvent(_default_color, this.buttonFontColorMenu)
    },
    buttonColorStyle() {
      let _default_color = this.button_color == null ? '#FFFFFF' : this.button_color
      let _default_font_color = this.button_font_color == null ? '#FFFFFF' : this.button_font_color
      return this.buttonColorChangeEvent(_default_color,_default_font_color)
    },
    headerStyle() {
      return {
        '--site-color': this.header_color,
        '--icon-color': this.icon_color,
      };
    },
  },
  watch: {
    favicon_file (newVal) {
      if (newVal) {
        this.getBase64(newVal).then((image) => {
          this.favicon_image_url = image
          this.has_favicon_deleted = false
        })
      }
    },
    company_logo (newVal) {
      if (newVal) {
        this.getBase64(newVal).then((image) => {
          this.company_image_url = image
          this.has_company_logo_deleted = false
        })

      }
    },
    logo_file (newVal) {
      if (newVal) {
        this.getBase64(newVal).then((image) => {
          this.logo_image_url = image
          this.has_logo_deleted = false
        })
      }
    }
  },

  created() {
    this.searchMyPage().then((content) => {
      this.my_pages = content.my_pages
    })
  },
  methods: {
    ...mapActions('backThemeOption', {
      updateThemeOption: 'update',
    }),
    ...mapActions('backSearchMyPage', {
      searchMyPage: 'fetch',
    }),
    colorChangeEvent (color, menu) {
      return {
        backgroundColor: color,
        marginBottom: '7px',
        border: '1px solid #ddd',
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    buttonColorChangeEvent (color, font_color) {
      return {
        backgroundColor: color,
        color: font_color,
      }
    },
    buildParams () {
      const params = new FormData()
      if (this.favicon_file || this.has_favicon_deleted) {
        params.append('favicon_file', this.favicon_file)
      }
      if (this.company_logo || this.has_company_logo_deleted) {
        params.append('company_logo', this.company_logo)
      }
      params.append('image_logo', this.image_logo)
      params.append('logo_title', this.logo_title)
      if (this.logo_file || this.has_logo_deleted) {
        params.append('logo_file', this.logo_file)
      }
      params.append('site_title', this.site_title)
      params.append('site_color', this.site_color)
      params.append('header_color', this.header_color)
      params.append('icon_color', this.icon_color)
      params.append('button_color', this.button_color)
      params.append('button_font_color', this.button_font_color)
      params.append('footer_text', this.footer_text)
      params.append('twitter_url', this.twitter_url)
      params.append('facebook_url', this.facebook_url)
      params.append('use_category_fotter', this.use_category_fotter)
      params.append('use_campaign_fotter', this.use_campaign_fotter)
      params.append('use_tag_fotter', this.use_tag_fotter)
      params.append('use_refined_search_area', this.use_refined_search_area)
      params.append('use_agree_terms', this.use_agree_terms)
      params.append('terms_my_page_id', this.terms_my_page_id)
      params.append('terms_title', this.terms_title)
      params.append('use_payment_note', this.use_payment_note)
      params.append('payment_note_my_page_id', this.payment_note_my_page_id)
      params.append('payment_note_title', this.payment_note_title)
      return params
    },
    delete_favicon() {
      this.favicon_image_url = null
      this.favicon_file = null
      this.has_favicon_deleted = true
    },
    delete_company_logo(){
      this.company_image_url = null
      this.company_logo = null
      this.has_company_logo_deleted = true
    },
    delete_logo_file(){
      this.logo_image_url = null
      this.logo_file = null
      this.has_logo_deleted = true
    },
    submit() {
      const params = this.buildParams()
      this.updateThemeOption({params: params}).then((content) => {
        this.favicon_image_url = content.favicon_image_url
        this.company_image_url = content.company_image_url
        this.image_logo = content.image_logo
        this.logo_title = content.logo_title
        this.logo_image_url = content.logo_image_url
        this.site_title = content.site_title
        this.site_color = content.site_color
        this.header_color = content.header_color
        this.icon_color = content.icon_color
        this.button_color = content.button_color
        this.button_font_color = content.button_font_color
        this.footer_text = content.footer_text
        this.twitter_url = content.twitter_url
        this.facebook_url = content.facebook_url
        this.use_category_fotter = content.use_category_fotter
        this.use_campaign_fotter = content.use_campaign_fotter
        this.use_tag_fotter = content.use_tag_fotter
        this.use_refined_search_area = content.use_refined_search_area
        this.use_agree_terms = content.use_agree_terms
        this.terms_my_page_id = content.terms_my_page_id
        this.terms_title = content.terms_title
        this.use_payment_note = content.use_payment_note
        this.payment_note_my_page_id = content.payment_note_my_page_id
        this.payment_note_title = content.payment_note_title

        this.favicon_file = null
        this.company_logo = null
        this.logo_file = null
        this.has_favicon_deleted = false
        this.has_company_logo_deleted = false
        this.has_logo_deleted = false
      })
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const _files = e.dataTransfer.files;
      this.favicon_file = _files[0]
    },

    onLogoFileDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const _files = e.dataTransfer.files;
      this.logo_file = _files[0];
    },

    onCompanyLogoDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const _files = e.dataTransfer.files;
      this.company_logo = _files[0];
    },

    onDragEnter(e) {
      e.preventDefault();
      this.isDragging = true;
      this.dragCount++;
    },

    onDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    },
    getBase64 (file) {
      /* Promiseにアロー関数を渡して非同期処理を書く。*/
      return new Promise((resolve, reject) => {

        /* 組み込みオブジェクトを使い、Base64エンコードされた画像データを取得  */
        const reader = new FileReader()
        reader.readAsDataURL(file)

        /* 成功時と失敗時の処理。resolveの引数に結果を渡して、のちの.then(result => ...)で受け取る  */
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
  }
}
</script>

<style lang="scss">
.header_style{
  background-color:var(--site-color) !important;
  color:var(--icon-color) !important;
  .v-icon{
    color:var(--icon-color) !important;
  }
}
</style>
