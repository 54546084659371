<!-- バックヤード > キャンペーン一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          {{ company_mode_caption_name }}
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              @click="newCampaign"
            >
              {{ company_mode_caption_name }}追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="inputTitle"
                  :label="`${company_mode_caption_name}名`"
                  dense
                  hide-details="auto"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-checkbox
                  v-model="inputDisplaySearch"
                  label="表示日で絞り込む"
                  hide-details="auto"
                  class="pa-0 ma-0"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :disabled="inputDisplaySearch === false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="inputDisplayDate"
                      :disabled="inputDisplaySearch === false"
                      clearable
                      dense
                      hide-details="auto"
                      hint="時点表示されているもの"
                      label="表示日"
                      outlined
                      placeholder=" "
                      persistent-hint
                      readonly
                      v-on="on"
                      @click:clear="inputDisplayDate = null"
                    />
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="inputDisplayDate"
                    @input="dateMenu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="resetCampaigns"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ company_mode_caption_name }}名
                  </th>
                  <th class="text-center">
                    登録商品数
                  </th>
                  <th class="text-left">
                    表示期間
                  </th>
                  <th class="text-left">
                    作成日時
                  </th>
                  <th class="text-left">
                    更新日時
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="campaign in campaigns"
                  :key="campaign.id"
                >
                  <td>{{ campaign.title }}</td>
                  <td class="text-center">
                    {{ campaign.count }}
                  </td>
                  <td>{{ campaign.display_period_start_date | from_to_date_format(campaign.display_period_end_date) }}</td>
                  <td>{{ campaign.created_at | unix_datetime_format }}</td>
                  <td>{{ campaign.updated_at | unix_datetime_format }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="editCampaign(campaign)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>


        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="isCampaigns"
      cols="12"
    >
      <v-col>
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  name: 'CampaignIndex',
  components: {
  },
  mixins: [],
  data () {
    return {
      page: 1,
      totalVisible: 7,
      inputTitle: '',
      inputDisplaySearch: false,
      inputDisplayDate: new Date().toISOString().substr(0, 10),
      dateMenu: false,
      company_mode_caption_name: '',
      campaigns: [],
    }
  },
  computed: {
    ...mapState('backCampaign', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
    ...mapGetters('backCampaign', [
      'isCampaigns'
    ])
  },
  created() {
    this.page = this.pagination.current_page
    this.searchCampaigns()
  },
  methods: {
    ...mapActions('backCampaign', {
      fetchCampaigns: 'fetchCampaigns',
      fetchQuery: 'fetchQuery',
    }),
    resetFilters () {
      this.inputTitle = ''
      this.inputDisplaySearch = false
      this.inputDisplayDate = ''
      this.resetCampaigns()
    },
    newCampaign () {
      // キャンペーン追加へ
      this.$router.push({name: 'CampaignNew'})
    },
    editCampaign (campaign) {
      // キャンペーン詳細へ
      this.$router.push({name: 'CampaignEdit', params: { id: campaign.id }})
    },
    resetCampaigns () {
      this.filterDialog = false
      this.page = 1
      this.searchCampaigns()
    },
    changePage () {
      this.searchCampaigns()
    },
    searchCampaigns () {
      const params = { title: this.inputTitle, display_search: this.inputDisplaySearch, display_date: this.inputDisplayDate, page: this.page }
      this.fetchCampaigns(params).then((content) => {
        this.company_mode_caption_name = content.company_mode_caption_name
        this.campaigns = content.campaigns
      })
    }
  }
}
</script>

<style lang="scss">

</style>
