<template>
  <div id="app">
    <v-app>
      <HeaderNav v-if="isLogin" />
      <v-main class="blue-grey lighten-5">
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>
      <snackbar />
      <loading />
    </v-app>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Loading from '../components/Loading'
  import Snackbar from '../components/Snackbar'
  import HeaderNav from './components/HeaderNav'
  import FaviconMixin from '../mixins/favicon-mixin'

    export default {
      components: {
        HeaderNav,
        Loading,
        Snackbar,
      },
      mixins: [
        FaviconMixin
      ],
      data () {
        return {
          auth : true,
        }
      },
      computed: {
        ...mapState('adminAuth', {
          loggedIn: 'logged_in',
        }),
        isLogin: function () {
          return this.loggedIn
        }
      },
    }
</script>

<style scoped lang="scss">

</style>
