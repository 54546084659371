var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-right mb-3"},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.openItemSearchDialog}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.relationTableHeaders,"items":_vm.campaignProducts,"items-per-page":-1,"dense":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.image_url !== null)?_c('v-img',{attrs:{"src":item.image_url,"max-height":"45","max-width":"60"}}):_vm._e()],1)]}},{key:"item.category_names",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"wrap_text",staticStyle:{"width":"200px"}},[_vm._v("\n                  "+_vm._s(item.category_names)+"\n                ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("comma_format")(item.price))+" 円\n              ")]}},{key:"item.display_date",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("from_to_date_format")(item.display_start_date,item.display_end_date))+"\n              ")]}},{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"color":"error","icon":"","small":""},on:{"click":function($event){return _vm.productDeleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.productDeleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}])})],1)],1)],1),_vm._v(" "),_c('v-row',[(_vm.isCampaignProducts)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pagination.total_pages,"total-visible":_vm.totalVisible,"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right","circle":""},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)],1)],1),_vm._v(" "),_c('item-search-dialog',{ref:"ItemSearchDialog",on:{"itemSearchDialogCallback":_vm.itemSearchDialogCallback}}),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }