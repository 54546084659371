<!-- バックヤード > 会員詳細 > お知らせ一覧 -->
<template>
  <div>
    <v-card flat>
      <v-card-title>お知らせ</v-card-title>
      <v-card-text>
        <div class="text-right mb-3">
          <v-btn
            color="primary"
            @click="addCustomerNotice"
          >
            お知らせ追加
          </v-btn>
        </div>

        <v-card outlined>
          <v-card-text>
            <v-data-table
              :headers="noticeTableHeaders"
              :items="notices"
              :items-per-page="-1"
              disable-sort
              hide-default-footer
            >
              <template v-slot:item.description="{ item }">
                {{ item.description | truncate(30) }}
              </template>
              <template v-slot:item.notice_date="{ item }">
                {{ item.notice_date | date_format }}
              </template>
              <template v-slot:item.display_date="{ item }">
                <td>{{ item.display_start_date | from_to_date_format(item.display_end_date) }}</td>
              </template>
              <template v-slot:item.editable="{ item }">
                <v-btn
                  v-if="$vuetify.breakpoint.mdAndUp"
                  icon
                  color="cyan darken-2"
                  @click="editCustomerNotice(item)"
                >
                  <v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  small
                  @click="editCustomerNotice(item)"
                >
                  編集する
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12">
        <v-pagination
          v-if="isCustomerNotice"
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>

    <!--  お知らせ登録ダイアログ  -->
    <v-dialog
      v-model="noticeDialog"
      scrollable
      max-width="600px"
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-form>
          <v-card flat>
            <v-card-title>
              お知らせ登録
            </v-card-title>
            <v-card-text>
              <v-col cols="12">
                <v-row class="pt-4">
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="タイトル"
                      rules="required|max:50"
                    >
                      <v-text-field
                        v-model="customerNotice.title"
                        :error-messages="errors"
                        counter="50"
                        dense
                        label="タイトル"
                        outlined
                        placeholder="お知らせタイトル"
                        required
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="詳細"
                      rules="required|max:1024"
                    >
                      <v-textarea
                        v-model="customerNotice.description"
                        :error-messages="errors"
                        counter="1024"
                        label="詳細"
                        outlined
                        placeholder="詳細を入力してください。"
                        required
                        value=""
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="5"
                  >
                    <v-menu
                      v-model="noticeDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <validation-provider
                          v-slot="{ errors }"
                          class="requiredMark"
                          name="お知らせ日"
                          rules="required"
                        >
                          <v-text-field
                            v-model="customerNotice.notice_date"
                            :error-messages="errors"
                            clearable
                            dense
                            hide-details="auto"
                            label="お知らせ日"
                            outlined
                            placeholder=" "
                            readonly
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="customerNotice.notice_date"
                        @input="noticeDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="5"
                  >
                    <v-menu
                      v-model="displayStartDateMenu"
                      :close-on-content-click="false"
                      min-width="290px"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="開始日"
                        >
                          <v-text-field
                            v-model="customerNotice.display_start_date"
                            :error-messages="errors"
                            clearable
                            dense
                            hide-details="auto"
                            label="開始日"
                            outlined
                            placeholder=" "
                            readonly
                            v-on="on"
                          >
                            <template
                              v-if="!$vuetify.breakpoint.smAndDown"
                              v-slot:append-outer
                            >
                              &emsp;〜
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="customerNotice.display_start_date"
                        @input="displayStartDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                  >
                    <v-menu
                      v-model="displayEndDateMenu"
                      :close-on-content-click="false"
                      min-width="290px"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="終了日"
                        >
                          <v-text-field
                            v-model="customerNotice.display_end_date"
                            :error-messages="errors"
                            clearable
                            dense
                            hide-details="auto"
                            label="終了日"
                            outlined
                            placeholder=" "
                            readonly
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="customerNotice.display_end_date"
                        @input="displayEndDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="customerNotice.use_mail"
                      class="pa-0 ma-0"
                      hide-details="auto"
                      label="メール送信する"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="isEdit"
                color="error"
                @click="deleteCustomerNotice"
              >
                削除
              </v-btn>
              <v-spacer />
              <v-btn @click="noticeDialog = false">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="submitCustomerNotice"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
    <confirm ref="confirm" />
  </div>
</template>

<script>

import Confirm from '../../../../components/Confirm'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  name: 'CustomerNoticeIndex',
  components: {
    Confirm,
  },
  data () {
    return {
      /**
       *                 <td>{{ notice.title }}</td>
       <td>{{ notice.description | truncate(30) }}</td>
       <td>{{ notice.notice_date | date_format }}</td>
       <td>{{ notice.display_start_date | from_to_date_format(notice.display_end_date) }}</td>

       */
      page: 1,
      totalVisible: 7,
      noticeTableHeaders:[
        { value: 'title', text: 'タイトル' },
        { value: 'description', text: '詳細' },
        { value: 'notice_date', text: 'お知らせ日' },
        { value: 'display_date', text: '表示期間' },
        { value: 'editable', text: '', align: 'end' },
      ],
      notices: [],
      customerId: null,
      noticeDialog: false,
      isEdit: false,
      noticeDateMenu: false,
      displayStartDateMenu: false,
      displayEndDateMenu: false,
      targetCustomerNotice: null,
      customerNotice: {
        title: null,
        description: null,
        notice_date: null,
        display_start_date: null,
        display_end_date: null,
        use_mail: true,
      },

    }
  },
  computed: {
    ...mapState('backCustomerNotice', {
      pagination: 'pagination',
    }),
    ...mapGetters('backCustomerNotice', [
      'isCustomerNotice'
    ]),
  },
  created() {
    this.customerId = this.$route.params.id
    this.page = this.pagination.current_page
    this.searchCustomerNotice()
  },
  methods: {
    ...mapActions('backCustomerNotice', {
      findCustomerNotices: 'findCustomerNotices',
      newCustomerNotice: 'new',
      showCustomerNotice: 'show',
      createCustomerNotice: 'create',
      updateCustomerNotice: 'update',
      destroyCustomerNotice: 'destroy',
    }),
    searchCustomerNotice () {

      const params = {
        customer_id: this.customerId,
        page: this.page
      }
      this.findCustomerNotices(params).then((content) => {
        this.notices = content.notices
      })
    },
    changePage () {
      this.searchCustomerNotice()
    },
    addCustomerNotice () {
      this.isEdit = false
      const params = {
        customer_id: this.customerId,
      }
      this.newCustomerNotice(params).then((content) => {
        this.setCustomerNotice(content)
        this.noticeDialog = true
      })
    },
    editCustomerNotice (customerNotice) {
      const params = {
        customer_id: this.customerId,
        id: customerNotice.id
      }
      this.showCustomerNotice(params).then((content) => {
        this.setCustomerNotice(content)
        this.isEdit = true
        this.noticeDialog = true
      })
    },
    deleteCustomerNotice () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          let params = {
            customer_id: this.customerId,
            id: this.targetCustomerNotice.id
          }
          this.destroyCustomerNotice(params).then(() => {
            this.noticeDialog = false
            this.$refs.observer.reset()
            this.page = 1
            this.searchCustomerNotice()
          })
        }
      })
    },
    setCustomerNotice(content) {
      this.targetCustomerNotice = content
      this.customerNotice.title = content.title
      this.customerNotice.description = content.description
      this.customerNotice.notice_date = content.notice_date
      this.customerNotice.display_start_date = content.display_start_date
      this.customerNotice.display_end_date = content.display_end_date
    },
    submitCustomerNotice () {
      let params = {
        customer_id: this.customerId,
        params: this.buildParams()
      }
      if (this.isEdit) {
        params = { ...params, ...{id: this.targetCustomerNotice.id} }
        this.updateCustomerNotice(params).then(() => {
          this.noticeDialog = false
          this.$refs.observer.reset()
          this.searchCustomerNotice()
        })
      } else {
        this.createCustomerNotice(params).then(() => {
          this.noticeDialog = false
          this.$refs.observer.reset()
          this.searchCustomerNotice()
        })
      }
    },
    buildParams () {
      return {
        title: this.customerNotice.title,
        description: this.customerNotice.description,
        notice_date: this.customerNotice.notice_date,
        display_start_date: this.customerNotice.display_start_date,
        display_end_date: this.customerNotice.display_end_date,
        use_mail: this.customerNotice.use_mail
      }
    }
  }
}

</script>

<style lang="scss">

</style>
