<!-- バックヤード > 受注詳細 > 入金履歴一覧 -->
<template>
  <div>
    <v-card outlined>
      <v-card-actions>
        <v-row>
          <v-col cols="5">
            <v-select
              v-model="order_detail_id"
              hint="商品を選択してください"
              :items="products"
              label="受注商品"
              item-text="product_name"
              item-value="id"
              persistent-hint
              dense
              hide-details="auto"
              outlined
              placeholder=" "
              required
              @change="searchOrderSubscriptions"
            />
          </v-col>
        </v-row>
      </v-card-actions>
      <v-data-table
        v-if="orderSubscriptions"
        :headers="subscriptionTableHeaders"
        :items="orderSubscriptions"
        :items-per-page="-1"
        disable-sort
        hide-default-footer
      >
        <template v-slot:item.rental_minimum_period="{ item }">
          {{ item.rental_minimum_period }}{{ item.billing_cycle === 'cycle_month' ? 'ヶ月' : '年' }}
        </template>
        <template v-slot:item.number_of_payments="{ item }">
          {{ item.number_of_payments }}&nbsp;回目
        </template>
        <template v-slot:item.total_amount="{ item }">
          {{ item.total_amount | comma_format }}&nbsp;円
        </template>
        <template v-slot:item.canceled_at="{ item }">
          <template v-if="item.canceled">
            <span @click="openCanceledConfirmModalClickEvent(item)">
              {{ item.canceled_at }}
            </span>
          </template>
          <template
            v-else
          >
            {{ item.canceled_at }}
          </template>
        </template>
        <template v-slot:item.editable="{ item }">
          <v-btn
            v-if="item.payment_failed_date != null"
            small
            color="info"
            @click="reSendMailButtonClick(item)"
          >
            メール再送
          </v-btn>
          <v-btn
            v-if="item.can_cancel && item.number_of_payments > 1"
            small
            color="error"
            @click="openCancelModalClickEvent(item)"
          >
            受注取消
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-row>
      <v-col
        v-if="pagination.total_count > 0"
        cols="12"
      >
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>

    <template>
      <v-dialog
        v-model="openCancelDialog"
        width="600"
      >
        <v-form>
          <validation-observer v-slot="{ invalid }">
            <v-card>
              <v-card-title>受注取り消し</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="取り消し理由"
                      class="requiredMark"
                    >
                      <v-textarea
                        v-model="orderSubscription.cancel_note"
                        outlined
                        counter
                        label="取り消し理由"
                        value=""
                        auto-grow
                        row-height="15"
                        dense
                        hide-details="auto"
                        placeholder=" "
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="openCancelDialog = false">
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="registerCancelSubscriptionClickEvent"
                >
                  取り消し
                </v-btn>
              </v-card-actions>
            </v-card>
          </validation-observer>
        </v-form>
      </v-dialog>
    </template>

    <template>
      <v-dialog
        v-model="openConfirmDialog"
        width="600"
      >
        <v-card>
          <v-card-title>受注取り消し</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
              >
                <div class="overline">
                  取り消し理由
                </div>
              </v-col>
              <v-col cols="12">
                <h4>{{ orderSubscription.cancel_note }}</h4>
                <v-divider />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="openConfirmDialog = false">
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  components: {},
  mixins: [],
  data () {
    return {
      page: 1,
      totalVisible: 7,
      subscriptionTableHeaders: [
        { value: 'settlement_plan_date', text: '決済予定日', align: 'center' },
        { value: 'rental_minimum_period', text: '最低レンタル期間', align: 'center' },
        { value: 'number_of_payments', text: '回数', align: 'right' },
        { value: 'settlement_confirmed_at', text: '決済確定日', align: 'center' },
        { value: 'payment_failed_date', text: '決済失敗日', align: 'center' },
        { value: 'payment_failed_message', text: '決済失敗理由' },
        { value: 'total_amount', text: '金額', align: 'right' },
        { value: 'canceled_at', text: 'キャンセル日' },
        { value: 'editable', text: '', align: 'end' },
      ],
      products: [],
      order_detail_id: null,
      openCancelDialog: false,
      openConfirmDialog: false,
      orderSubscription: {
        id: null,
        payment_failed_date: null,
        payment_failed_message: null,
        cancel_note: null,
        canceled_person: null,
        canceled_date: null,
      }
    }
  },
  computed: {
    ...mapState('backOrderSubscription', {
      orderSubscriptions: 'order_subscriptions',
      pagination: 'pagination'
    }),
    ...mapGetters('backOrderSubscription', [
      'viewPage',
      'hasOrderDetailId',
      'searchOrderDetailId'
    ]),
  },
  watch: {
    order_detail_id (newValue) {
      this.fetchQuery({order_detail_id: newValue})
      this.searchOrderSubscriptions()
    }
  },
  created() {
    this.order_detail_id = this.searchOrderDetailId
    const params = { only_subscription: true }
    this.fetchOrderDetail({order_id: this.$route.params.id, params: params}).then((content) => {
      this.products = content.order_details
      if (this.order_detail_id == null) {
        const first_detail = content.order_details[0]
        this.order_detail_id = first_detail.id
      }
    })
  },
  methods: {
    ...mapActions('backOrderSubscription',  {
      fetchOrderSubscriptions: 'fetchAll',
      editOrderSubscription: 'edit',
      updateOrderSubscription: 'update',
      fetchQuery: 'fetchQuery',
    }),
    ...mapActions('backOrderSubscriptionFailedMail', {
      sendMail: 'create'
    }),
    ...mapActions('backOrderDetail', {
      fetchOrderDetail: 'fetchAll'
    }),
    changePage () {
      this.searchOrderSubscriptions()
    },
    searchOrderSubscriptions () {
      this.fetchOrderSubscriptions({ order_id: this.$route.params.id, page: this.page })
    },
    openCanceledConfirmModalClickEvent (order_subscription) {
      this.editOrderSubscription({ order_id: this.$route.params.id, id: order_subscription.id }).then((content) => {
        this.orderSubscription = content
        this.openConfirmDialog = true
      })
    },
    openCancelModalClickEvent (order_subscription) {
      this.editOrderSubscription({ order_id: this.$route.params.id, id: order_subscription.id }).then((content) => {
        this.orderSubscription = content
        this.openCancelDialog = true
      })
    },
    reSendMailButtonClick (order_subscription) {
      this.sendMail({ order_id: this.$route.params.id, id: order_subscription.id })
    },
    registerCancelSubscriptionClickEvent () {
      this.updateOrderSubscription({ order_id: this.$route.params.id, params: this.orderSubscription }).then(() => {
        this.searchOrderSubscriptions()
        this.openCancelDialog = false
      })
    },
  },
}
</script>

<style lang="scss">
</style>
