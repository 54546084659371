<!-- バックヤード > 商品検索ダイアログ -->
<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    @input="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title>商品検索</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="selectedCategories"
              :items="categoryContent.categories"
              item-text="name"
              item-value="id"
              label="カテゴリー"
              dense
              clearable
              hide-details="auto"
              outlined
              placeholder=" "
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="inputtedKeyword"
              dense
              clearable
              label="キーワード"
              hide-details="auto"
              outlined
              placeholder=" "
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              class="float-right"
              @click="resetProducts"
            >
              検索
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-simple-table
              fixed-header
              dense
              height="380px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      class="text-left"
                      style="width: 120px;"
                    />
                    <th
                      class="text-left"
                      style="width: 250px;"
                    >
                      品番1/商品名
                    </th>
                    <th
                      class="text-left"
                      style="width: 300px;"
                    >
                      カテゴリー
                    </th>
<!--                    <th-->
<!--                      class="text-center"-->
<!--                      style="width: 130px;"-->
<!--                    >-->
<!--                      金額-->
<!--                    </th>-->
                    <th class="text-left">
                      表示期間
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="product in products"
                    :key="product.id"
                    :class="product.checkable ? 'custom-bg' : ''"
                    @click="rowClickEvent(product)"
                  >
                    <td>
                      <v-img
                        v-if="product.image_url !== null"
                        :src="product.image_url"
                        max-height="40"
                        max-width="100"
                        contain
                      />
                    </td>
                    <td>{{ product.part_number_1 }}<br>{{ product.name }}</td>
                    <td>{{ product.category_names }}</td>
<!--                    <td class="text-right">-->
<!--                      {{ product.price | comma_format }} 円-->
<!--                    </td>-->
                    <td>{{ product.display_period_start_date | from_to_date_format(product.display_period_end_date) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-if="pagination.total_count > 0"
            cols="12"
          >
            <v-pagination
              v-model="page"
              :length="pagination.total_pages"
              :total-visible="totalVisible"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              circle
              @input="changePage"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancelButtonClickEvent">
          キャンセル
        </v-btn>
        <v-btn
          color="primary"
          @click="registerButtonClickEvent"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState, mapActions } from 'vuex'

  export default {
    components: {},
    mixins: [],
    props: {},
    data () {
      return {
        singleSelect: false,
        withoutComponent: false,
        dialog: false,
        page: 1,
        totalVisible: 7,
        selectedCategories: [],
        inputtedKeyword: null,
        products:[],
        selectedProductIds: [],
        exclude_product_id: null,
        localStockItem: null,
        useCalendar: null,
        pagination: {
          total_pages: 0,
          total_count: 0
        }
      }
    },
    computed: {
      ...mapState('backSearchCategory', {
        categoryContent: 'content'
      }),
    },
    watch : {
      products () {
        for (const elm of this.products) {
          elm.checkable = (this.selectedProductIds.some( b => b == elm.id))
        }
      }
    },
    created() {
      this.fetchSearchCategory()
    },
    methods: {
      ...mapActions('backSearchCategory', {
        fetchSearchCategory: 'fetch'
      }),
      ...mapActions('backSearchProduct', {
        fetchProducts: 'fetchAll',
      }),
      /**
       * 商品モーダルを表示する.
       * @param { Object } params - 表示オプション
       * @param { Number } params.exclude_product_id - 除外する商品IDを指定
       * @param { boolean } params.singleSelect - 単一選択モードを利用する場合はtrue
       * @param { boolean } params.withoutComponent - セット商品を除外する
       * @param { boolean } params.local_stock_item - 現地在庫品を省く場合はfalse
       * @param { boolean } params.use_calendar - カレンダー使用のみ検索する場合はtrue
       */
      openDialog (params) {
        this.singleSelect = params['singleSelect'] ? params['singleSelect'] : false
        this.exclude_product_id = params['exclude_product_id']
        this.withoutComponent = params['withoutComponent']
        this.localStockItem = params['local_stock_item']
        this.useCalendar = params['use_calendar'] ? params['use_calendar'] : null
        this.dialog = true
      },
      resetProducts () {
        this.page = 1
        this.selectedProductIds = []
        this.searchProducts()
      },
      searchProducts () {
        const params = {
          category_ids: this.selectedCategories,
          keyword: this.inputtedKeyword,
          exclude_product_id: this.exclude_product_id,
          without_component: this.withoutComponent,
          local_stock_item: this.localStockItem,
          use_calendar: this.useCalendar,
          page: this.page
        }
        console.log(params)
        this.fetchProducts({params: params}).then((content) => {
          this.products = content.products
          this.pagination = content.pagination
        })
      },
      changePage () {
        this.searchProducts()
      },
      cancelButtonClickEvent () {
        this.closeDialog()
      },
      /**
       * OKボタンクリック イベント
       * 一覧で選択した値を呼び出し元に受け渡す
       * 単一選択の場合でも配列で返すので注意.
       * @return { Number } 単一選択モードの場合は選択したID
       * @return { Array } 選択したIDの配列
       */
      registerButtonClickEvent () {
        let value = this.singleSelect ? this.selectedProductIds[0] : this.selectedProductIds
        this.$emit('itemSearchDialogCallback', value)
        this.closeDialog()
      },
      rowClickEvent (product) {
        if (this.singleSelect) {
          this.selectedProductIds = []

          for(const _product of this.products) {
            _product.checkable = false
          }

          product.checkable = true
          this.selectedProductIds.push(product.id)
        } else {
          product.checkable = !product.checkable
          if (product.checkable) {
            this.selectedProductIds.push(product.id)
          } else {
            this.selectedProductIds = this.selectedProductIds.filter(b => b !== product.id)
          }

        }
      },
      closeDialog () {
        // すべて初期化
        Object.assign(this.$data, this.$options.data());
      }
    }
  }
</script>

<style scoped>
.custom-bg {
  background-color: beige;
}

tbody tr {
  cursor: pointer;
}

</style>