/**
 * frontend用の認証
 */
import * as types from '../../mutation-types/frontend-types'
import * as httpConst from '../../../constants/httpConst'
import Vue from 'vue'
import store from 'store'

const isFrontLoggedIn = () => {
  return (store.get('frontLoggedIn'))
}

const getCurrentUser = () => {
  const default_current_user = {
    name: null,
  }
  let current_user = null
  if (isFrontLoggedIn()) current_user = store.get('current_user')
  return (current_user) ? current_user : default_current_user
}

const API_PATH = `${httpConst.FRONTEND_BASE}/oauth`

/**
 * logged_in: Boolean (true: ログイン済)
 */
const auth = {
  namespaced: true,
  state: {
    logged_in: isFrontLoggedIn(),
    name: getCurrentUser().name
  },
  getters: {
    avatarName: ( state ) => {
      return (state.name) ? state.name.slice(0, 2) : ''
    },
    honorificName: ( state ) => {
      return (state.name) ? `${state.name} さん` : ''
    }
  },
  actions: {
    signInFrontend ({ commit }, { params }) {
      const fixedParams = {
        grant_type: 'password',
        client_id: process.env.FRONTEND_CLIENT_ID,
        client_secret: process.env.FRONTEND_CLIENT_SECRET,
        scope: 'public frontend'
      }
      const parameter = { ...params, ...fixedParams }

      const api_path = `${API_PATH}/token`
      return new Promise((resolve, _reject) => {
        Vue.$http.post(api_path, parameter).then( data => {
          const user = data.user
          commit(types.SIGN_IN_FRONT, { user })
          resolve(data)
        })
      })
    },
    signOutFrontend ({ commit }) {
      const api_path = `${API_PATH}/revoke`
      return new Promise((resolve) => {
        Vue.$http.post(api_path).then( data => {
          commit(types.SIGN_OUT_FRONT)
          resolve(data)
        })
      })
    }
  },
  mutations: {
    forceSignOut (state) {
      state.logged_in = false
      store.remove('frontLoggedIn')
      store.remove('current_user')
      store.remove('tmpCustomerId')
    },
    [types.SIGN_IN_FRONT] (state, { user }) {
      state.logged_in = true
      state.name = user.name
      // localStorageに保持
      store.set('frontLoggedIn', true)
      store.set('current_user', user)
    },
    [types.SIGN_OUT_FRONT] (state) {
      state.logged_in = false
      // localStorageに保持
      store.remove('frontLoggedIn')
      store.remove('current_user')
      store.remove('tmpCustomerId')
    },
  },
}

export default auth
