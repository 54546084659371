<!-- バックヤード > 配送業者 form -->
<template>
  <div>
    <alert-message />
    <validation-observer v-slot="{ invalid }">
      <v-form>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  class="requiredMark"
                  name="配送業者名"
                  rules="required|max:30"
                >
                  <v-text-field
                    v-model="name"
                    :error-messages="errors"
                    counter="30"
                    dense
                    hide-details="auto"
                    label="配送業者名"
                    outlined
                    placeholder=" "
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="追跡サイトURL"
                  rules="max:255"
                >
                  <v-text-field
                    v-model="tracking_site_url"
                    :error-messages="errors"
                    counter="255"
                    dense
                    hide-details="auto"
                    label="追跡サイトURL"
                    outlined
                    placeholder=" "
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  class="requiredMark"
                  name="表示順"
                  rules="required|numeric|min_value:0|max_value:2147483647"
                >
                  <v-text-field
                    v-model="position"
                    :error-messages="errors"
                    dense
                    hide-details="auto"
                    label="表示順"
                    outlined
                    placeholder=" "
                    required
                    reverse
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="isEdit"
              color="error"
              @click="deleteButtonClickEvent"
            >
              削除
            </v-btn>
            <v-spacer />
            <v-btn @click="deliveryCompanyCancel">
              キャンセル
            </v-btn>
            <v-btn
              color="primary"
              :disabled="invalid"
              @click="submit"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>

        <!--ダイアログ-->
      </v-form>
    </validation-observer>
    <confirm
      v-if="isEdit"
      ref="confirm"
    />
  </div>
</template>

<script>

import Confirm from '../../../../components/Confirm'
import AlertMessage from '../../../../components/AlertMessage'
import { mapActions } from 'vuex'

export default {
  name: 'DeliveryCompanyForm',
  components: {
    AlertMessage,
    Confirm,
  },
  mixins: [],
  props: {
    deliveryCompany: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      id: this.deliveryCompany.id,
      name: this.deliveryCompany.name,
      tracking_site_url: this.deliveryCompany.tracking_site_url,
      position: this.deliveryCompany.position,
    }
  },
  methods: {
    ...mapActions('backDeliveryCompany', {
      createDeliveryCompany: 'create',
      updateDeliveryCompany: 'update',
      destroyDeliveryCompany: 'destroy'
    }),
    // formデータ
    buildParams () {
      return {
        name: this.name,
        tracking_site_url: this.tracking_site_url,
        position: this.position
      }
    },
    submit: function() {
      // パラメータ
      const params = this.buildParams()
      if (this.isEdit) {
        // 更新
        this.updateDeliveryCompany({ id: this.deliveryCompany.id, params: params })
      } else {
        // 新規
        this.createDeliveryCompany({ params: params }).then(() => {
          // 一覧へ遷移
          this.$router.push({name: 'byDeliveryCompanyIndex'})
        })
      }
    },
    // 削除
    deleteButtonClickEvent () {
      // 削除確認ダイアログ立ち上げ
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        // OKなら削除
        if (confirm) {
          this.destroyDeliveryCompany({ id: this.deliveryCompany.id }).then(() => {
            this.$router.push({name: 'byDeliveryCompanyIndex'})
          })
        }
      })
    },
    // キャンセルボタンクリック
    deliveryCompanyCancel: function () {
      this.$router.push({name: 'byDeliveryCompanyIndex'})
    },
  },
}
</script>

<style lang="scss">
</style>
