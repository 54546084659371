<template>
  <v-container>
    <order-summary @toNextStep="nextStep" />
  </v-container>
</template>

<script>
import OrderSummary from './forms/_SummaryAuction'
import CartStepperMixin from '../../../mixins/frontend/cart-stepper-mixin'
import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";
export default {
  name: 'CartsIndexAuction',
  components: {
    OrderSummary,
  },
  mixins: [
    CartStepperMixin,
    PageHeaderMixin
  ],
  data () {
    return {
      delivery: null,
    }
  },
  created() {
    this.descriptionContent = this.$route.meta.siteSubTitle
    this.pageTitle = this.$route.meta.siteSubTitle
  },
  methods: {
  },
}
</script>

<style scoped lang="scss">
</style>