<!-- バックヤード > パスワードを忘れた方 -->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          xs="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="3"
        >
          <v-form>
            <validation-observer v-slot="{ invalid }">
              <v-card outlined>
                <v-card-title>
                  <span class="title font-weight-light">パスワードを忘れた方</span>
                </v-card-title>
                <v-card-subtitle>
                  <p>登録されているメールアドレスに再発行の案内を送信します。</p>
                  <p>ログインIDを入力し送信ボタンを押して下さい。</p>
                </v-card-subtitle>

                <v-card-text>
                  <v-row>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="ログインID"
                        class="requiredMark"
                      >
                        <v-text-field
                          v-model="login_id"
                          label="ログインID"
                          hide-details="auto"
                          dense
                          outlined
                          counter
                          placeholder=" "
                          required
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="cancelButtonClickEvent">
                    キャンセル
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :disabled="invalid"
                    @click="registerButtonClickEvent"
                  >
                    送信
                  </v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

  import {mapActions} from 'vuex'

  export default {
    name: 'ForgetIndex',
    components: {
    },
    mixins: [
    ],
    data () {
      return {
        login_id: null
      }
    },
    methods: {
      ...mapActions('backForgetPassword', {
        regist: 'create'
      }),
      cancelButtonClickEvent: function () {
        this.$router.go(-1)
      },
      registerButtonClickEvent: function () {
        let params = {  login_id: this.login_id }
        this.regist({ params: params }).then(() => {
          this.$router.push({name: 'byForgetSent'})
        })
      },
    },
  }
</script>

<style lang="scss">
</style>
