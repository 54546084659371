/**
 * 配送方法
 */
import Vue from 'vue'
import * as httpConst from '../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/delivery_desired_times`

const deliveryDesiredTime = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetchAll({ _commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          resolve(data.content)
        })
      })
    },
    new ({ _commit }) {
      const api_path = `${API_PATH}/new`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    show ({ _commit }, { id }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    create ({ commit }, { params }) {
      return new Promise((resolve, _reject) => {
          Vue.$http.post(API_PATH, params).then(data => {
              commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
              resolve(data.content)
          })
      })
    },
    update ({ commit }, { id, params }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.put(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
    destroy ({ commit }, { id, params }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.delete(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
  },
  mutations: {},
}

export default deliveryDesiredTime
