<template>
  <div>
    <v-card
      v-if="isDropzoneImage"
      @dragover.stop.prevent="dragging=true"
      @dragenter.prevent="dragging=true"
      @dragleave.prevent="dragging=false"
      @drop.stop.prevent="onDrop"
      :class="{ 'grey lighten-2': dragging }"
      outlined
    >
      <v-card-text>
        <input
          ref="input_file"
          class="d-none"
          type="file"
          :accept="acceptFileFormat"
          @change="fileSelected"
          :multiple="maxSize > 1"
        >
        <v-row class="d-flex flex-column" dense align="center" justify="center" @click="showFileDialog">
          <v-icon :class="[dragging ? 'mt-2, mb-6' : 'mt-5']" size="60">
            mdi-cloud-upload
          </v-icon>
          <p>
            ファイルをドラッグするか、ここをクリックしてください
          </p>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row v-if="previewableImages.length > 0">
      <template
        v-for="(image, index) in previewImages"
      >
        <v-col
          v-if="image._destroy == null"
          cols="12"
          :key="index"
        >
          <v-card style="background-color: #fafafa;">
            <v-card-subtitle>Preview</v-card-subtitle>
            <v-img
              :src="image.url | load_image_url"
              max-height="300"
              contain
              aspect-ratio="1"
              class="gray lighten-4 align-center"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn
                small
                color="error"
                @click="removeFile(index)"
              >
                削除
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>

/**
 * example
 *  <dropzone-image
 *   :key="refreshKey"
 *   :images.sync="images"
 *   @fileChanged="fileChanged"
 *   />
 *  key: コンポートンと再描画用のカウンター（登録後にカウントアップなどを行えば、コンポーネントを再描画する
 *  images.sync: 登録、削除するファイルの配列
 *    既存ファイル表示: {id: 1, url: 画像URL}
 *    既存ファイル削除: {id: 1, url: 画像URL, _destroy: true}
 *    新規ファイル: {url: 画像URL, file: ファイルオブジェクト, _destroy: null}
 *  fileChanged: ファイルを追加、削除したさいに発火するイベント
 */

export default {
  name: 'DropzoneImage',
  props: {
    // 画像の最大数
    maxSize: {
      type: Number,
      default: 1,
    },
    // 画像の配列
    images: {
      type: Array,
      default: () => [],
    },
    // 許可するファイル種別
    accepts: {
      type: Array,
      default: () => ['image/jpg', 'image/jpeg', 'image/png'],
    },
    // 許可する拡張子（表示用）
    altFileFormat: {
      type: String,
      default: '.jpg .jpeg .png',
    },
    // 即時削除
    immediateDelete: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      dragging: false,
      isFileChanged: false,
      maxFiles: this.maxSize === null ? 99 : this.maxSize,
      files: [],
      previewImages: this.images,
    }
  },
  computed: {
    maxFilesAlertMessage () {
      return `アップロードできる画像は${this.maxFiles}枚までです`
    },
    acceptAlertMessage () {
      return `アップロードできる画像の種類は「${this.altFileFormat}」です`
    },
    acceptFileFormat () {
      return this.accepts ? this.accepts.join(',') : null
    },
    previewableImages () {
      return this.previewImages.filter(m => !m._destroy)
    },
    isDropzoneImage () {
      return this.maxSize > this.previewableImages.length
    }
  },
  methods: {
    showFileDialog () {
      this.$refs.input_file.click()
    },
    removeFile (index) {
      let remove_image = this.previewImages[index]
      // 即時削除
      if (this.immediateDelete) {
        this.$emit('removeFile', remove_image, index)
      } else {
        // 既存ファイル
        if (remove_image.id) {
          remove_image._destroy = true
          this.$set(this.previewImages, index, remove_image)
        } else {
          this.previewImages.splice(index, 1)
        }
        this.emitUpdateImages()
      }
    },
    onDrop (event) {
      this.dragging = false
      this.addFiles(event.dataTransfer.files)
    },
    fileSelected (event) {
      this.addFiles(event.target.files)
    },
    addFiles (files) {
      if (this.isOverMaxFiles(files)) {
        // 最大枚数を超える場合は処理しない
        alert(this.maxFilesAlertMessage)
      } else if (this.isInValidFileType(files)) {
        alert(this.acceptAlertMessage)
      } else {
        this.generatePreviewImages(files)
      }
    },
    generatePreviewImages (files) {
      for (const file of files) {
        this.toDataURI(file).then((image) => {
          const imgObj = {
            url: image,
            file: file,
            _destroy: null,
          }
          this.previewImages.push(imgObj)
          this.emitUpdateImages()
        })
      }
    },
    emitUpdateImages () {
      this.$emit("update:images", this.previewImages)
      this.$emit('fileChanged', this.isFileChanged)
    },
    isOverMaxFiles (files) {
      const length = this.previewableImages.length + files.length
      return length > this.maxFiles
    },
    isInValidFileType (files) {
      let isInValid = false
      for (const file of files) {
        const isSome = this.accepts.some(
          a => a === file.type
        )

        if (!isSome) {
          isInValid = true
          break
        }
      }
      // true: 許可しないものが含まれている
      return isInValid
    },
    // File オブジェクトを DataURI に変換する
    toDataURI (file) {
      return new Promise(resolve => {
        const fileReader = new FileReader()

        fileReader.onload = () => {
          resolve(fileReader.result)
        }
        fileReader.readAsDataURL(file)
      })
    }
  }
}

</script>

<style scoped lang="scss">
</style>
