<!-- バックヤード > サイト管理 > 特定商取引 > 特定商取引タブ -->
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-text>
            <div class="text-right mb-2">
              <v-btn
                :disabled="!canAddCommercialLaw"
                small
                @click="addButtonClickEvent"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                :headers="tableHeaders"
                :items="commercialLaws"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.description="{ item }">
                  {{ item.description | truncate(30) }}
                </template>
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="cyan darken-2"
                    @click="editButtonClickEvent(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    text
                    color="cyan"
                    @click="editButtonClickEvent(item)"
                  >
                    編集する
                  </v-btn>

                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="error"
                    @click="deleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    text
                    color="error"
                    @click="deleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showCommercialLawDialog"
      width="800"
      persistent
      no-click-animation
    >
      <v-form>
        <alert-message />
        <validation-observer v-slot="{ invalid }">
          <v-card>
            <v-card-title>特定商取引</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:30"
                    name="タイトル"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="commercial_law.title"
                      :error-messages="errors"
                      counter="30"
                      dense
                      hide-details="auto"
                      label="タイトル"
                      outlined
                      placeholder=" "
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:5120"
                    name="詳細"
                    class="requiredMark"
                  >
<!--                    <v-textarea-->
<!--                      v-model="commercial_law.description"-->
<!--                      :error-messages="errors"-->
<!--                      counter="5120"-->
<!--                      dense-->
<!--                      hide-details="auto"-->
<!--                      label="詳細"-->
<!--                      outlined-->
<!--                      placeholder=" "-->
<!--                      required-->
<!--                    />-->
                    <vue-editor
                        v-model="commercial_law.description"
                        :error-messages="errors"
                        class="custom-quill-container"
                        dense
                        hide-details="auto"
                        label="詳細"
                        required
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|numeric|min_value:0|max_value:2147483647"
                    name="表示順"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="commercial_law.position"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="表示順"
                      outlined
                      placeholder=" "
                      required
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="showCommercialLawDialog = false">
                キャンセル
              </v-btn>
              <v-btn
                :disabled="invalid"
                color="primary"
                @click="dialogRegisterButtonClickEvent"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
  </v-dialog>
    <Confirm ref="confirm" />
  </div>
</template>

<script>

import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    AlertMessage,
    Confirm
  },
  mixins: [
  ],
  data () {
    return {
      showCommercialLawDialog: false,
      commercial_law: null,
      tableHeaders: [
        { text: '表示順', value: 'position', align: 'center' },
        { text: 'タイトル', value: 'title' },
        // { text: '詳細', value: 'description' },
        { text: '', value: 'editable', align: 'end' },
      ]
    }
  },
  computed: {
    ...mapState('backCommercialLaw', {
      commercialLaws: 'commercial_laws'
    }),
    ...mapGetters('backCommercialLaw', [
      'canAddCommercialLaw'
    ])
  },
  created() {
    this.fetchCommercialLaw()
  },
  methods: {
    ...mapActions('backCommercialLaw', {
      fetchCommercialLaw: 'fetchAll',
      newCommercialLaw: 'new',
      editCommercialLaw: 'edit',
      createCommercialLaw: 'create',
      updateCommercialLaw: 'update',
      deleteCommercialLaw: 'destroy'
    }),
    addButtonClickEvent () {
      this.newCommercialLaw().then( (content) => {
        this.commercial_law = content
        this.showCommercialLawDialog = true
      })
    },
    editButtonClickEvent (item) {
      this.editCommercialLaw({id: item.id}).then( ( content ) => {
        this.commercial_law = content
        this.showCommercialLawDialog = true
      })
    },
    dialogRegisterButtonClickEvent () {
      if (this.commercial_law.id) {
        this.updateCommercialLaw({id: this.commercial_law.id, params: this.commercial_law}).then(() => {
          this.showCommercialLawDialog = false
        })
      } else {
        this.createCommercialLaw({params: this.commercial_law}).then(() => {
          this.showCommercialLawDialog = false
        })
      }
    },
    deleteButtonClickEvent (item) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.deleteCommercialLaw({id: item.id})
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.wrap_text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-quill-container {
  /deep/ {
    /* 動画と画像の添付はなし */
    .ql-toolbar {
      .ql-formats{
        .ql-video{
          display: none;
        }
        .ql-image{
          display: none;
        }
      }
    }
  }
}
</style>
