import { render, staticRenderFns } from "./_Payment.vue?vue&type=template&id=2fa0ddce&scoped=true&"
import script from "./_Payment.vue?vue&type=script&lang=js&"
export * from "./_Payment.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fa0ddce",
  null
  
)

export default component.exports