/**
 * キャンペーン商品
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/campaigns`

const campaign_product = {
    namespaced: true,
    state: {
        pagination: {
            current_page: 1,
            total_count: 0
        },
        campaign_products: []
    },
    getters: {
        isCampaignProducts: ( state ) => {
            return (state.pagination.total_count > 0)
        },
    },
    actions: {
        fetchAll ({ commit, state },{ campaign_id, page }) {
            const api_path = `${API_PATH}/${campaign_id}/campaign_products`
            const params = { page: page }
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path, { params: params }).then(data => {
                    let content = data.content
                    commit(types.BACKYARD_CAMPAIGN_PRODUCT, {content})
                })
            })
        },
        create( { commit, state }, { campaign_id, params } ) {
            const api_path = `${API_PATH}/${campaign_id}/campaign_products`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(api_path, params).then( data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    let content = data.content
                    commit(types.BACKYARD_CAMPAIGN_PRODUCT, { content })
                } )
            })
        },
        destroy( { commit, state }, { campaign_id, params } ) {
            const remove_id = params.id
            const api_path = `${API_PATH}/${campaign_id}/campaign_products/${remove_id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    let content = data.content
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    commit(types.BACKYARD_CAMPAIGN_PRODUCT, { content })
                })
            })
        },
    },
    mutations: {
        [types.BACKYARD_CAMPAIGN_PRODUCT] (state, { content }) {
            state.campaign_products = content.campaign_products
            state.pagination = content.pagination
        },

    },
}

export default campaign_product