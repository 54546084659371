/**
 * 受注商品
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/orders`

const order = {
  namespaced: true,
  state: {
    undispatchedCount: 0,
    order: null,
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {
      order_number: null,
      utm_source: null,
      order_start_date: null,
      order_end_date: null,
      schedule_start_date: null,
      schedule_end_date: null,
      settle_start_date: null,
      settle_end_date: null,
      status: 'all',
      payment_type: 'all',
      has_order: null,
      has_shipping: null,
      has_checkout: null,
      has_extend_checkout: null,
      has_return: null,
      only_unshipped: false,
      only_local_stock_item: false,
      only_credit_card: false,
      only_bank_transfer: false,
      only_cash_on_delivery: false
    },
  },
  getters: {
    hasOrders: ( state ) => {
      return (state.pagination.total_count > 0)
    },
    orderDetail(state) {
      return state.order
    }
  },
  actions: {
    fetchAll({ dispatch, commit, state },
             {
               order_number,
               utm_source,
               order_start_date,
               order_end_date,
               schedule_start_date,
               schedule_end_date,
               settle_start_date,
               settle_end_date,
               payment_type,
               status,
               has_order,
               has_shipping,
               has_checkout,
               has_extend_checkout,
               has_return,
               only_unshipped,
               only_local_stock_item,
               only_credit_card,
               only_bank_transfer,
               only_cash_on_delivery,
               customer_id,
               page}) {
      const queries = {
        order_number: order_number,
        utm_source: utm_source,
        order_start_date: order_start_date,
        order_end_date: order_end_date,
        schedule_start_date: schedule_start_date,
        schedule_end_date: schedule_end_date,
        settle_start_date: settle_start_date,
        settle_end_date: settle_end_date,
        payment_type: payment_type,
        status: status,
        has_order: has_order,
        has_shipping: has_shipping,
        has_checkout: has_checkout,
        has_extend_checkout: has_extend_checkout,
        has_return: has_return,
        only_unshipped: only_unshipped,
        only_local_stock_item: only_local_stock_item,
        only_credit_card: only_credit_card,
        only_bank_transfer: only_bank_transfer,
        only_cash_on_delivery: only_cash_on_delivery,
        customer_id: customer_id
      }
      dispatch('fetchQuery', queries)
      const params = {
        order_number: state.query.order_number,
        utm_source: state.query.utm_source,
        order_start_date: state.query.order_start_date,
        order_end_date: state.query.order_end_date,
        schedule_start_date: state.query.schedule_start_date,
        schedule_end_date: state.query.schedule_end_date,
        settle_start_date: state.query.settle_start_date,
        settle_end_date: state.query.settle_end_date,
        payment_type: state.query.payment_type,
        status: state.query.status,
        has_order: state.query.has_order,
        has_shipping: state.query.has_shipping,
        has_checkout: state.query.has_checkout,
        has_extend_checkout: state.query.has_extend_checkout,
        has_return: state.query.has_return,
        only_unshipped: state.query.only_unshipped,
        only_local_stock_item: state.query.only_local_stock_item,
        only_credit_card: state.query.only_credit_card,
        only_bank_transfer: state.query.only_bank_transfer,
        only_cash_on_delivery: state.query.only_cash_on_delivery,
        customer_id: customer_id,
        page: page
      }

      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_ORDER, { content })
          resolve(data.content)
        })
      })
    },
    fetchQuery({ commit, state}, {
                 order_number,
                 utm_source,
                 order_start_date,
                 order_end_date,
                 schedule_start_date,
                 schedule_end_date,
                 settle_start_date,
                 settle_end_date,
                 payment_type,
                 status,
                 has_order,
                 has_shipping,
                 has_checkout,
                 has_extend_checkout,
                 has_return,
                 only_unshipped,
                 only_local_stock_item,
                 only_credit_card,
                 only_bank_transfer,
                 only_cash_on_delivery,
               }) {

      const orderNumber = (order_number !== undefined) ? order_number : state.query.order_number
      const utmSource = (utm_source !== undefined) ? utm_source : state.query.utm_source
      const orderStartDate = (order_start_date !== undefined) ? order_start_date : state.query.order_start_date
      const orderEndDate = (order_end_date !== undefined) ? order_end_date : state.query.order_end_date
      const scheduleStartDate = (schedule_start_date !== undefined) ? schedule_start_date : state.query.schedule_start_date
      const scheduleEndDate = (schedule_end_date !== undefined) ? schedule_end_date : state.query.schedule_end_date
      const settleStartDate = (settle_start_date !== undefined) ? settle_start_date : state.query.settle_start_date
      const settleEndDate = (settle_end_date !== undefined) ? settle_end_date : state.query.settle_end_date
      const paymentType = (payment_type !== undefined) ? payment_type : state.query.payment_type
      const statusType = (status !== undefined) ? status : state.query.status
      const hasOrder = (has_order !== undefined) ? has_order : state.query.has_order
      const hasShipping = (has_shipping !== undefined) ? has_shipping : state.query.has_shipping
      const hasCheckout = (has_checkout !== undefined) ? has_checkout : state.query.has_checkout
      const hasExtendCheckout = (has_extend_checkout !== undefined) ? has_extend_checkout : state.query.has_extend_checkout
      const hasReturn = (has_return !== undefined) ? has_return : state.query.has_return
      const onlyUnshipped = (only_unshipped !== undefined) ? only_unshipped : state.query.only_unshipped
      const onlyLocalStockItem = (only_local_stock_item !== undefined) ? only_local_stock_item : state.query.only_local_stock_item
      const onlyCreditCard  = (only_credit_card !== undefined) ? only_credit_card : state.query.only_credit_card
      const onlyBankTransfer  = (only_bank_transfer !== undefined) ? only_bank_transfer : state.query.only_bank_transfer
      const onlyCashOnDelivery= (only_cash_on_delivery !== undefined) ? only_cash_on_delivery : state.query.only_cash_on_delivery
      const params = {
        order_number: orderNumber,
        utm_source: utmSource,
        order_start_date: orderStartDate,
        order_end_date: orderEndDate,
        schedule_start_date: scheduleStartDate,
        schedule_end_date: scheduleEndDate,
        settle_start_date: settleStartDate,
        settle_end_date: settleEndDate,
        payment_type: paymentType,
        status: statusType,
        has_order: hasOrder,
        has_shipping: hasShipping,
        has_checkout: hasCheckout,
        has_extend_checkout: hasExtendCheckout,
        has_return: hasReturn,
        only_unshipped: onlyUnshipped,
        only_local_stock_item: onlyLocalStockItem,
        only_credit_card: onlyCreditCard,
        only_bank_transfer: onlyBankTransfer,
        only_cash_on_delivery: onlyCashOnDelivery,
      }
      commit(types.BACK_ORDER_QUERY, { params })
    },
    resetQueries ({ commit, state }) {

      const params = {
        order_number: null,
        utm_source: null,
        order_start_date: null,
        order_end_date: null,
        schedule_start_date: null,
        schedule_end_date: null,
        settle_start_date: null,
        payment_type: 'all',
        status: 'all',
        has_order: null,
        has_shipping: null,
        has_checkout: null,
        has_extend_checkout: null,
        has_return: null,
        only_unshipped: false,
        only_local_stock_item: false,
        only_credit_card: false,
        only_bank_transfer: false,
        only_cash_on_delivery: false,
      }
      commit(types.BACK_ORDER_QUERY, { params })
    },
    fetch ({ commit, state }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          commit(types.BACK_ORDER_DETAIL, { content })
          resolve(content)
        })
      })
    },
    create ({ commit, state }, { params }) {
      const api_path = `${API_PATH}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    update ({ commit, state }, { id }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    destroy ({ commit, state }, { order_id, params }) {
      const api_path = `${API_PATH}/${order_id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.delete(api_path, {params: params}).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '取消しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    fetchUndispatchedCount({ commit }) {
      const api_path = `${API_PATH}/undispatched_count`
      return new Promise((resolve, reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          commit(types.BACK_ORDER_UNDISPATCHED_COUNT, { count: content.undispatched_count })
          resolve(content.unclosed_count)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    fetchCSV({ commit, state })
    {

      const params = {
        order_number: state.query.order_number,
        utm_source: state.query.utm_source,
        order_start_date: state.query.order_start_date,
        order_end_date: state.query.order_end_date,
        schedule_start_date: state.query.schedule_start_date,
        schedule_end_date: state.query.schedule_end_date,
        settle_start_date: state.query.settle_start_date,
        settle_end_date: state.query.settle_end_date,
        status: state.query.status,
        has_order: state.query.has_order,
        has_shipping: state.query.has_shipping,
        has_checkout: state.query.has_checkout,
        has_extend_checkout: state.query.has_extend_checkout,
        has_return: state.query.has_return,
        only_unshipped: state.query.only_unshipped,
        only_local_stock_item: state.query.only_local_stock_item,
        only_credit_card: state.query.only_credit_card,
        only_bank_transfer: state.query.only_bank_transfer,
        only_cash_on_delivery: state.query.only_cash_on_delivery,
      }

      const api_path = `${API_PATH}/csv`
      return new Promise((resolve, reject) => {
        Vue.$http.get(api_path, {params: params}).then(data => {
          let content = data.content
          resolve(content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
  },
  mutations: {
    [types.BACK_ORDER] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_ORDER_QUERY] (state, { params }) {
      state.query.order_number = params.order_number
      state.query.utm_source = params.utm_source
      state.query.order_start_date = params.order_start_date
      state.query.order_end_date = params.order_end_date
      state.query.schedule_start_date = params.schedule_start_date
      state.query.schedule_end_date = params.schedule_end_date
      state.query.settle_start_date = params.settle_start_date
      state.query.settle_end_date = params.settle_end_date
      state.query.payment_type = params.payment_type
      state.query.status = params.status
      state.query.has_order = params.has_order
      state.query.has_shipping = params.has_shipping
      state.query.has_checkout = params.has_checkout
      state.query.has_extend_checkout = params.has_extend_checkout
      state.query.has_return = params.has_return
      state.query.only_unshipped = params.only_unshipped
      state.query.only_local_stock_item = params.only_local_stock_item
      state.query.only_credit_card = params.only_credit_card
      state.query.only_bank_transfer = params.only_bank_transfer
      state.query.only_cash_on_delivery = params.only_cash_on_delivery
    },
    [types.BACK_ORDER_DETAIL] (state, { content }) {
      state.order = content
    },
    [types.BACK_ORDER_UNDISPATCHED_COUNT] (state, { count }) {
      state.undispatchedCount = count
    },

  },
}

export default order