<!-- バックヤード > システムへの問い合わせ一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn
            color="primary"
            @click="systemInquiryCreate"
          >
            問い合わせ追加
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="キーワード"
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <v-select
                    :items="users"
                    dense
                    multiple
                    label="質問者"
                    hide-details="auto"
                    outlined
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-checkbox
                    label="CLOSEも表示する"
                    dense
                    hide-details="auto"
                    class="pa-0 ma-0"
                  />
                </v-col>
              </v-row>

              <v-btn color="primary">
                検索
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    内容
                  </th>
                  <th class="text-left">
                    質問者
                  </th>
                  <th class="text-center">
                    回答数
                  </th>
                  <th class="text-left">
                    最終更新日時
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="inquiry in inquiries"
                  :key="inquiry.id"
                >
                  <td>
                    <v-chip
                      v-if="inquiry.close"
                      class="ma-2"
                      label
                      small
                      :ripple="false"
                    >
                      CLOSE
                    </v-chip>
                    {{ inquiry.detail }}
                  </td>
                  <td>{{ inquiry.user }}</td>
                  <td class="text-center">
                    {{ inquiry.count }}
                  </td>
                  <td>{{ inquiry.lastDate }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="systemInquiryEdit"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-pagination
      v-model="page"
      circle
      :length="10"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      :total-visible="5"
    />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";

  export default {
    components: {
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        users: ['横浜 太郎','川崎 花子','品川 一郎'],
        page: 1,
        inquiries:[
          {id: 1, detail: '6/1リリースの機能追加について質問です。', user: '山田 太郎', count: 3, lastDate: '2020-06-16 10:00'},
          {id: 2, detail: '404エラーが表示されたのですが、なぜでしょう？', user: '鈴木 花子', count: 6, lastDate: '2020-06-03 16:35'},
          {id: 3, detail: 'パスワード忘れのメールが届かなくて困ってます。', user: '菊池 春江', count: 2, lastDate: '2020-06-02 10:00', close: 1},
        ],
      }
    },
    methods: {
      systemInquiryCreate: function () {
        this.$router.push({name: 'bySystemInquiryCreateIndex'})
      },
      systemInquiryEdit: function () {
        this.$router.push({name: 'bySystemInquiryEditIndex', params: {id: 1}})
      },
    }
  }
</script>

<style lang="scss">

</style>
