<!-- バックヤード > SHOPサイト画面設定 > 背景画像コンテンツ -->
<template>
  <div>
    <v-col>
      <!-- タイトル -->
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="max:100"
            name="タイトル"
          >
            <v-text-field
              v-model="backgroundImageContent.title"
              :error-messages="errors"
              dense
              label="タイトル"
              hide-details="auto"
              outlined
              counter="100"
              placeholder=" "
            />
          </validation-provider>
        </v-col>
      </v-row><!-- /タイトル -->

      <!-- サブタイトル -->
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="max:512"
            name="サブタイトル"
          >
            <v-textarea
              v-model="backgroundImageContent.sub_title"
              :error-messages="errors"
              dense
              label="サブタイトル"
              auto-grow
              row-height="15"
              hide-details="auto"
              outlined
              counter="512"
              placeholder=" "
            />
          </validation-provider>
        </v-col>
      </v-row><!-- /サブタイトル -->

      <!-- タイトル文字色 -->
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="backgroundImageContent.title_color"
            hide-details="auto"
            label="タイトル文字色"
            dense
            readonly
            outlined
            placeholder=" "
            @click="titleColorMenu = true"
          >
            <template v-slot:append>
              <v-menu
                v-model="titleColorMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div
                    :style="titleColorSwatchStyle"
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="backgroundImageContent.title_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
      </v-row><!-- /タイトル文字色 -->

      <!-- サブタイトル文字色 -->
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="backgroundImageContent.sub_title_color"
            hide-details="auto"
            label="サブタイトル文字色"
            dense
            readonly
            outlined
            placeholder=" "
            @click="subTitleColorMenu = true"
          >
            <template v-slot:append>
              <v-menu
                v-model="subTitleColorMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div
                    :style="subTitleColorSwatchStyle"
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="backgroundImageContent.sub_title_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
      </v-row><!-- /サブタイトル文字色 -->

      <!-- 背景色 -->
      <v-row>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="backgroundImageContent.background_color"
            hide-details="auto"
            label="背景色"
            dense
            readonly
            outlined
            placeholder=" "
            @click="backgroundColorMenu = true"
          >
            <template v-slot:append>
              <v-menu
                v-model="backgroundColorMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div
                    :style="backgroundColorSwatchStyle"
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="backgroundImageContent.background_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
      </v-row><!-- /背景色 -->

      <!-- 画像ファイル -->
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="mimes:image/jpeg,image/png"
            name="画像ファイル"
          >
            <v-row
              class="text-center"
              @dragover.prevent
              @dragenter="onDragEnter"
              @dragleave="onDragLeave"
              @drop="onDrop"
            >
              <v-col>
                <v-file-input
                  v-model="backgroundImageContent.file"
                  :background-color="isDragging ? 'blue' : 'null'"
                  :clearable="false"
                  :show-size="1000"
                  :error-messages="errors"
                  ref="backgroundImageFile"
                  accept="image/jpeg,image/png"
                  append-icon="mdi-folder"
                  counter
                  dense
                  label="画像ファイル"
                  hint="画像ファイルを選択して下さい"
                  outlined
                  persistent-hint
                  placeholder=" "
                  prepend-icon=""
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>
                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                    >+{{ files.length - 2 }} File(s)</span>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </validation-provider>
        </v-col>
      </v-row><!-- /画像ファイル -->

      <!-- ブロック内の左余白幅 -->
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            v-model="backgroundImageContent.left_padding"
            :items="leftPaddings"
            dense
            label="ブロック内の左余白幅"
            hide-details="auto"
            outlined
            placeholder=" "
            suffix="%"
          />
        </v-col>
      </v-row><!-- /ブロック内の左余白幅 -->

      <!-- ブロック内の水平位置 -->
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            v-model="backgroundImageContent.text_align"
            :items="textAligns"
            dense
            label="ブロック内の水平位置"
            hide-details="auto"
            outlined
            placeholder=" "
          />
        </v-col>
      </v-row><!-- /ブロック内の水平位置 -->

      <!-- プレビュー -->
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <div
            style="position: relative;border: 1px solid #ddd;"
          >
            <v-card style="background-color: #fafafa;">
              <v-card-subtitle>
                プレビュー
              </v-card-subtitle>
              <v-card-text
                class="justify-center"
              >
                <background-image
                  :background-image-content="backgroundImageContent"
                />
              </v-card-text>
              <v-card-actions
                v-if="backgroundImageContent.image_url"
              >
                <v-spacer />
                <v-btn

                  small
                  color="error"
                  @click="imageDeleteButtonClickEvent"
                >
                  削除
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row><!-- /プレビュー -->

      <!-- ボタンの利用 -->
      <v-row>
        <v-col>
          <p class="my-1">
            ボタン
          </p>
          <v-radio-group
            v-model="backgroundImageContent.use_button"
            dense
            row
            hide-details="auto"
            class="pa-0 ma-0"
          >
            <v-radio
              label="使用しない"
              :value="false"
            />
            <v-radio
              label="使用する"
              :value="true"
            />
          </v-radio-group>
        </v-col>
      </v-row><!-- /ボタンの利用 -->

      <!-- ボタン名 -->
      <v-row
        v-if="backgroundImageContent.use_button"
      >
        <v-col
          cols="12"
          md="3"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="max:10"
            name="ボタン名"
          >
            <v-text-field
              v-model="backgroundImageContent.button_title"
              :disabled="!backgroundImageContent.use_button"
              :error-messages="errors"
              dense
              label="ボタン名"
              hide-details="auto"
              outlined
              counter="10"
              placeholder=" "
            />
          </validation-provider>
        </v-col>
      </v-row><!-- /ボタン名 -->

      <!-- ボタン文字色 -->
      <v-row
        v-if="backgroundImageContent.use_button"
      >
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="backgroundImageContent.button_title_color"
            hide-details="auto"
            label="ボタン文字色"
            dense
            readonly
            outlined
            placeholder=" "
            @click="buttonTitleColorMenu = true"
          >
            <template v-slot:append>
              <v-menu
                v-model="buttonTitleColorMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div
                    :style="buttonTitleColorSwatchStyle"
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="backgroundImageContent.button_title_color"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
      </v-row><!-- /ボタン文字色 -->

      <!-- リンク先 -->
      <v-row>
        <v-col>
          <p class="my-1">
            リンク先
          </p>
          <banner-link-form
            :link="backgroundImageContent"
            :is-transition="true"
            :is-prefix="true"
          />
        </v-col>
      </v-row><!-- /リンク先 -->
    </v-col>
  </div>
</template>

<script>

  import BannerLinkForm from './_LinkSetting'
  import BackgroundImage from './../sample_layouts/_BackgroundImage'

  export default {
    name: 'BackgroundImageContentsForm',
    components: {
      BannerLinkForm,
      BackgroundImage
    },
    props: {
      /**
       * @typedef backgroundImageContent {Object}
       * @property id {Number} ID
       * @property option_type {String} オプション種別(slider news category new_arrivals new_arrivals_button campaign campaign_button)
       * @property image_url {String} 画像URL
       * @property title {String} タイトル
       * @property sub_title {String} サブタイトル
       * @property button_title {String} ボタンタイトル
       * @property use_button {Boolean} ボタン利用
       * @property title_color {String} タイトル文字色
       * @property sub_title_color {String} サブタイトル文字色
       * @property background_color {String} 背景色
       * @property button_title_color {String} ボタンタイトル文字色
       * @property transition_type {String} 遷移先種別(t_no_transition t_item_detail t_category t_tag t_my_page t_external)
       * @property product_id {Number} 遷移先_商品ID
       * @property product_name {String} 遷移先_商品名
       * @property category_id {Number} 遷移先_カテゴリーID
       * @property tag_id {Number} 遷移先_タグID
       * @property my_page_id {Number} マイページID
       * @property transition_url {String} 遷移先_URL
       * @property my_page_title {String}
       * @property my_page_post_name {String}
       * @property left_padding {Number}
       * @property text_align {String}
       */
      backgroundImageContent: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        titleColorMenu: false,
        subTitleColorMenu: false,
        backgroundColorMenu: false,
        buttonTitleColorMenu: false,
        isDragging: false,
        dragCount: 0,
        leftPaddings: [
          { value: 0, text: '0' },
          { value: 10, text: '10' },
          { value: 20, text: '20' },
          { value: 30, text: '30' },
          { value: 40, text: '40' },
          { value: 50, text: '50' },
        ],
        textAligns: [
          { value: 'text_align_left', text: '左揃え' },
          { value: 'text_align_center', text: '中央揃え' },
          { value: 'text_align_right', text: '右揃え' }
        ]
      }
    },
    computed: {
      titleColorSwatchStyle() {
        return this.colorChangeEvent(this.backgroundImageContent.title_color, this.titleColorMenu)
      },
      subTitleColorSwatchStyle () {
        return this.colorChangeEvent(this.backgroundImageContent.sub_title_color, this.subTitleColorMenu)
      },
      backgroundColorSwatchStyle () {
        return this.colorChangeEvent(this.backgroundImageContent.background_color, this.backgroundColorMenu)
      },
      buttonTitleColorSwatchStyle () {
        return this.colorChangeEvent(this.backgroundImageContent.button_title_color, this.buttonTitleColorMenu)
      }
    },
    watch: {
      'backgroundImageContent.file': function (newValue) {
        if (newValue) {
          this.getBase64(newValue).then((image) => {
            this.backgroundImageContent.image_url = image
            this.backgroundImageContent.remove_file = false
          })
        }

      },
    },
    methods: {
      imageDeleteButtonClickEvent () {
        // this.backgroundImageContent.file = null
        this.$refs.backgroundImageFile.reset();
        this.backgroundImageContent.image_url = null
        this.backgroundImageContent.remove_file = true
      },
      colorChangeEvent (color, menu) {
        return {
          backgroundColor: color,
          marginBottom: '7px',
          border: '1px solid #ddd',
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: menu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out'
        }
      },
      onDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        this.isDragging = false;
        const _files = e.dataTransfer.files;
        this.background_image_content.file = _files[0];
      },

      onDragEnter(e) {
        e.preventDefault();
        this.isDragging = true;
        this.dragCount++;
      },

      onDragLeave(e) {
        e.preventDefault();
        this.dragCount--;
        if (this.dragCount <= 0) {
          this.isDragging = false;
        }
      },
      getBase64 (file) {
        /* Promiseにアロー関数を渡して非同期処理を書く。*/
        return new Promise((resolve, reject) => {

          /* 組み込みオブジェクトを使い、Base64エンコードされた画像データを取得  */
          const reader = new FileReader()
          reader.readAsDataURL(file)

          /* 成功時と失敗時の処理。resolveの引数に結果を渡して、のちの.then(result => ...)で受け取る  */
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
    },
  }
</script>

<style lang="scss">
</style>
