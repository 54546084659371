<template>
  <div>
    <h3 class="text-center">
      お申し込み情報
      <div class="lang_lbl">{{$t('view.お申し込み情報')}}</div>
    </h3>
    <v-row v-if="isCarts">
      <v-col
        cols="12"
        md="9"
      >
        <v-row dense>
          <v-col
            v-for="item in content.carts"
            :key="item.id"
            cols="12"
            sm="6"
          >
            <order-product
              :item="item"
              :is-edit="true"
              @loadCart="loadCart"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          tile
          class="mt-2 grey lighten-3"
        >
          <v-card-title>
            注文概要
            <div class="lang_lbl ms-1">{{$t('view.注文概要')}}</div>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>
                    小計
                    <div class="lang_lbl">{{$t('view.小計')}}</div>
                  </th>
                  <td class="text-right">
                    {{ content.total_price | yen_format }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <div v-if="!loggedIn" style="margin-left:15px;font-size:14px;">
            購入にはログインが必要です
            <div class="lang_lbl">{{$t('view.購入にはログインが必要です')}}</div>
          </div>
          <v-card-actions v-if="content.use_settlement">
            <v-btn
                v-if="loggedIn"
                block
                color="primary"
                @click="toNext"
                class="lang_btn"
            >
              <v-icon>mdi-credit-card-outline</v-icon>支払いに進む
              <span class="lang_lbl">{{$t('view.支払いに進む')}}</span>
            </v-btn>
            <v-btn
                v-else
                block
                color="primary"
                @click="toLogin"
            >
              ログイン<v-icon>mdi-login</v-icon>
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        class="text-center"
      >
        商品が追加されていません。
        <div class="lang_lbl">{{$t('view.商品追加エラー_1')}}</div>
      </v-col>
      <v-col cols="12" align="center">
        <v-btn outlined color="primary" :block="($vuetify.breakpoint.mdAndDown) ? true : false" @click="moveToHome">
          <v-icon>mdi-home-outline</v-icon>ホームに戻る
          <div class="lang_lbl">{{$t('view.ホームに戻る')}}</div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import OrderProduct from './_OrderProduct'
  import CartStepperMixin from '../../../../mixins/frontend/cart-stepper-mixin'
  import store from "store";

  export default {
    name: 'OrderSummary',
    components: {
      OrderProduct,
    },
    mixins: [
      CartStepperMixin
    ],
    data () {
      return {
      }
    },
    computed: {
      ...mapState('frontAuth', {
        loggedIn: 'logged_in'
      }),
      ...mapState('frontCart', {
        content: 'content',
      }),
      ...mapGetters('frontCart', [
        'isCarts'
      ]),
    },
    created() {
      this.loadCart()
    },
    methods: {
      ...mapActions('frontCart', {
        fetchCarts: 'fetchCarts',
        fetchCartCount: 'fetchCount',
      }),
      toNext () {
        if (this.loggedIn) {
          // ログイン済みの場合、支払画面に遷移
          this.$emit('toNextStep')
        }else{
          // ログインしていない場合、ログイン画面に繊維(保険)
          store.set('prev_page', 'CartsIndex')
          this.$router.replace({name: 'SessionIndex'}, () => {})
        }
      },
      toLogin () {
        // ログインしていない場合、ログイン画面に繊維
        store.set('prev_page', 'CartsIndex')
        this.$router.replace({name: 'SessionIndex'}, () => {})
      },
      loadCart () {
        this.fetchCartCount()
        const params = {
          prefecture_code: null,
        }
        this.fetchCarts({ params: params })
      },
    },
  }
</script>

<style scoped lang="scss">
</style>