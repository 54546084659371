/**
 * 商品問い合わせ
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/product_questions`

const product_question = {
  namespaced: true,
  state: {
    unansweredCount: 0,
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {},
  },
  getters: {
    isProductQuestions: ( state ) => {
      return (state.pagination.total_count > 0)
    },
  },
  actions: {
    fetch({ commit }, { params: params }) {
      commit(types.BACK_PRODUCT_QUESTION_QUERY, { params })
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_PRODUCT_QUESTION, { content })
          resolve(data.content)
        })
      })
    },
    fetchUnansweredCount({ commit }) {
      const api_path = `${API_PATH}/unanswered_count`
      return new Promise((resolve, reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          commit(types.BACK_PRODUCT_QUESTION_COUNT, { count: content.unanswered_count })
          resolve(content.unanswered_count)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    show({ _commit, state }, { id }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    update( {commit, state}, { id, params } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    resetQueries ({ commit, state }) {

      const params = {}
      commit(types.BACK_PRODUCT_QUESTION_QUERY, { params })
    },
  },
  mutations: {
    [types.BACK_PRODUCT_QUESTION] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_PRODUCT_QUESTION_QUERY] (state, { params }) {
      state.query = params
    },
    [types.BACK_PRODUCT_QUESTION_COUNT] (state, { count }) {
      state.unansweredCount = count
    },
  },
}

export default product_question