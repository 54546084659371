<!-- バックヤード > 定期課金管理 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          定期課金管理
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="orderNo"
                  dense
                  hide-details="auto"
                  label="注文番号"
                  outlined
                  placeholder="部分一致"
                />
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="partNumber"
                  dense
                  hide-details="auto"
                  label="商品品番"
                  outlined
                  placeholder="部分一致"
                />
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="customerName"
                  dense
                  hide-details="auto"
                  label="顧客名"
                  outlined
                  placeholder="部分一致"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-checkbox
                  v-model="only_elapsed_scheduled"
                  class="pa-0 ma-0"
                  hide-details="auto"
                  label="決済遅れのみ"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="resetSubscriptions"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    商品名
                  </th>
                  <th class="text-center">
                    注文番号
                  </th>
                  <th class="text-center">
                    顧客名
                  </th>
                  <th class="text-center">
                    決済回数
                  </th>
                  <th class="text-center">
                    決済予定日
                  </th>
                  <th class="text-center">
                    決済エラー日時
                  </th>
                  <th class="text-center">
                    決済エラー内容
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="subscription in subscriptions"
                  :key="subscription.id"
                >
                  <td>{{ subscription.product_name }}</td>
                  <td>{{ subscription.order_no }}</td>
                  <td>{{ subscription.customer_name }}</td>
                  <td>{{ subscription.number_of_payments }}</td>
                  <td>{{ subscription.settlement_plan_at }}</td>
                  <td>{{ subscription.payment_failed_at }}</td>
                  <td>{{ subscription.payment_failed_message }}</td>
                  <td>
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="showOrderButtonClickEvent(subscription)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="hasSubscriptions"
        cols="12"
      >
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SubscriptionIndex',
  components: {},
  mixins: [],
  props: {},
  data () {
    return {
      page: 1,
      filterDialog: false,
      totalVisible: 7,
      orderNo: null,
      partNumber: null,
      customerName: null,
      only_elapsed_scheduled: false,
      subscriptions: [],
    }
  },
  computed: {
    ...mapState('backSubscription', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
    ...mapGetters('backSubscription', [
      'hasSubscriptions'
    ]),
  },
  watch: {
    orderNo (newValue) {
      this.fetchQuery({order_no: newValue})
    },
    partNumber (newValue) {
      this.fetchQuery({part_number: newValue})
    },
    customerName (newValue) {
      this.fetchQuery({customer_name: newValue})
    },
    only_elapsed_scheduled (newVal) {
      this.fetchQuery({ with_unpublished: newVal })
    }
  },
  created () {
    this.orderNo = this.searchQuery.order_no
    this.partNumber = this.searchQuery.part_number
    this.customerName = this.searchQuery.customer_name
    this.only_elapsed_scheduled = this.searchQuery.only_elapsed_scheduled
    this.page = this.pagination.page
    this.searchSubscriptions()
  },
  methods: {
    ...mapActions('backSubscription', {
      fetchSubscriptions: 'fetchAll',
      fetchQuery: 'fetchQuery',
      resetQuery: 'resetQuery'
    }),
    showOrderButtonClickEvent(subscription) {
      this.$router.push({name: 'byOrderShow', params: {id: subscription.order_id}})
    },
    resetSubscriptions() {
      this.page = 1
      this.searchSubscriptions()
    },
    changePage() {
      this.searchSubscriptions()
    },
    searchSubscriptions() {
      const params = {
        order_no: this.orderNo,
        part_number: this.partNumber,
        customer_name: this.customerName,
        only_elapsed_scheduled: this.only_elapsed_scheduled,
        page: this.page
      }
      this.fetchSubscriptions(params).then((content) => {
        this.subscriptions = content.subscriptions
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
