var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.category)?_c('div',[_c('alert-message'),_vm._v(" "),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:30","name":"カテゴリー名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"カテゴリー名","placeholder":" ","counter":"30","dense":"","hide-details":"auto","outlined":"","error-messages":errors,"required":""},model:{value:(_vm.category.name),callback:function ($$v) {_vm.$set(_vm.category, "name", $$v)},expression:"category.name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.category.home_display),expression:"category.home_display"}]},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"カテゴリー名 文字色","dense":"","readonly":"","outlined":"","placeholder":" "},on:{"click":function($event){_vm.defaultFontColorMenu = true}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.defaultFontColorSwatchStyle)},on))]}}],null,true),model:{value:(_vm.defaultFontColorMenu),callback:function ($$v) {_vm.defaultFontColorMenu=$$v},expression:"defaultFontColorMenu"}},[_vm._v(" "),_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":""},model:{value:(_vm.category.default_font_color),callback:function ($$v) {_vm.$set(_vm.category, "default_font_color", $$v)},expression:"category.default_font_color"}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.category.default_font_color),callback:function ($$v) {_vm.$set(_vm.category, "default_font_color", $$v)},expression:"category.default_font_color"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:30","name":"カテゴリー名(表示)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"30","dense":"","hide-details":"auto","label":"カテゴリー名(表示)","outlined":"","placeholder":" ","required":""},model:{value:(_vm.category.display_name),callback:function ($$v) {_vm.$set(_vm.category, "display_name", $$v)},expression:"category.display_name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.category.home_display),expression:"category.home_display"}]},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"hide-details":"auto","label":"カテゴリー名(表示) 文字色","dense":"","readonly":"","outlined":"","placeholder":" "},on:{"click":function($event){_vm.selectedFontColorMenu = true}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.selectedFontColorSwatchStyle)},on))]}}],null,true),model:{value:(_vm.selectedFontColorMenu),callback:function ($$v) {_vm.selectedFontColorMenu=$$v},expression:"selectedFontColorMenu"}},[_vm._v(" "),_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":""},model:{value:(_vm.category.selected_font_color),callback:function ($$v) {_vm.$set(_vm.category, "selected_font_color", $$v)},expression:"category.selected_font_color"}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.category.selected_font_color),callback:function ($$v) {_vm.$set(_vm.category, "selected_font_color", $$v)},expression:"category.selected_font_color"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"HOME画面に表示する(推奨サイズ : 250 * 250)","hide-details":"auto"},model:{value:(_vm.category.home_display),callback:function ($$v) {_vm.$set(_vm.category, "home_display", $$v)},expression:"category.home_display"}})],1)],1),_vm._v(" "),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.category.home_display),expression:"category.home_display"}]},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"mimes:image/jpeg,image/png","name":"画像ファイル"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{staticClass:"text-center",on:{"dragover":function($event){$event.preventDefault();},"dragenter":_vm.onDragEnter,"dragleave":_vm.onDragLeave,"drop":_vm.onDrop}},[_c('v-col',[_c('v-file-input',{attrs:{"background-color":_vm.isDragging ? 'blue' : 'null',"clearable":false,"show-size":1000,"error-messages":errors,"accept":"image/jpeg,image/png","append-icon":"mdi-folder","counter":"","dense":"","label":"画像ファイル","hint":"画像ファイルを選択して下さい","outlined":"","persistent-hint":"","placeholder":" ","prepend-icon":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v("\n                              "+_vm._s(text)+"\n                            ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v("+"+_vm._s(_vm.files.length - 2)+" File(s)")]):_vm._e()]}}],null,true),model:{value:(_vm.category.file),callback:function ($$v) {_vm.$set(_vm.category, "file", $$v)},expression:"category.file"}})],1)],1)]}}],null,true)})],1)],1),_vm._v(" "),(_vm.category.home_display)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticStyle:{"position":"relative","border":"1px solid #ddd"}},[_c('v-card',{staticStyle:{"background-color":"#fafafa"}},[_c('v-card-subtitle',[_vm._v("\n                        プレビュー\n                      ")]),_vm._v(" "),_c('v-card-text',{staticClass:"justify-center"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-img',{staticClass:"gray lighten-4 align-center",attrs:{"src":_vm._f("load_image_url")(_vm.category.image_url),"max-height":"300","contain":"","aspect-ratio":"1"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"font-weight-light title",style:({ color: _vm.category.selected_font_color })},[_vm._v("\n                                      "+_vm._s(_vm.category.display_name)+"\n                                    ")]),_vm._v(" "),_c('v-btn',{style:({ color: _vm.category.selected_font_color }),attrs:{"outlined":"","depressed":"","light":""}},[_vm._v("\n                                      検索\n                                    ")])],1)]):_vm._e()]),_vm._v(" "),_vm._v(" "),(!hover)?_c('div',[_c('h2',{staticClass:"text-center",style:({ color: _vm.category.default_font_color })},[_vm._v("\n                                  "+_vm._s(_vm.category.name)+"\n                                ")])]):_vm._e()],1)],1)]}}],null,true)})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":_vm.imageDeleteButtonClickEvent}},[_vm._v("\n                          削除\n                        ")])],1)],1)],1)])],1):_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"表示順","rules":"required|numeric|min_value:0|max_value:2147483647"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"表示順","outlined":"","placeholder":" ","required":"","reverse":""},model:{value:(_vm.category.position),callback:function ($$v) {_vm.$set(_vm.category, "position", $$v)},expression:"category.position"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"商品一覧にカテゴリーを表示する","hide-details":"auto"},model:{value:(_vm.category.display_product),callback:function ($$v) {_vm.$set(_vm.category, "display_product", $$v)},expression:"category.display_product"}})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[(_vm.category.id)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.categoryDelete}},[_vm._v("\n                削除\n              ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.categoryCancel}},[_vm._v("\n                キャンセル\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.submit}},[_vm._v("\n                保存\n              ")])],1)],1)],1)]}}],null,false,3284556053)})],1)],1),_vm._v(" "),(_vm.category.id)?_c('confirm',{ref:"confirm"}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }