<template>
  <div>
    <v-container
      class="grey lighten-5"
    >
      <v-breadcrumbs
        :items="breadcrumbs"
      />
      <v-card>
        <v-card-title>
          <h2>
            <v-icon class="mr-2">
              mdi-office-building
            </v-icon>
            会社情報作成
          </h2>
        </v-card-title>
        <v-card-text>
          <CompanyForm
            v-if="company"
            :is-edit="false"
            :company="company"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>

  import { mapActions } from 'vuex'
  import CompanyForm from './_Form'

  export default {
    name: 'CompanyNew',
    components: {
      CompanyForm
    },
    data () {
      return {
        company: null,
        breadcrumbs: [
          { text: '会社', disabled: false, to: '/admin/companies', exact: true },
          { text: '会社情報作成', disabled: false, href: '' }
        ],
      }
    },
    created() {
      this.newCompany().then((content) => {
        this.company = content
      })
    },
    methods: {
      ...mapActions('adminCompany', {
        newCompany: 'new'
      })
    }
  }
</script>

<style scoped>

</style>