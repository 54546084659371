<template xmlns="http://www.w3.org/1999/html">
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs
          class="px-0 py-2"
          :items="breadcrumbs"
        />
      </v-col>
      <v-col cols="12">
        <h2>
          <v-icon class="mr-2">
            mdi-cart
          </v-icon>
          {{$t('view.注文履歴')}}
          <span class="subtitle-2 ml-4">
            注文詳細
          </span>
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-for="item in orderDetails"
        :key="item.id"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card outlined>
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-avatar
                  tile
                  size="80"
                >
                  <v-img
                    :src="item.image_url | load_image_url"
                    :lazy-src="item.image_url | load_image_url"
                  />
                </v-avatar>
              </v-col>
              <v-col
                  cols="8"
                  class="pb-1"
              >
                <div
                    class="subtitle-1 pb-1"
                >
                  {{ item.product_name }}
                </div>
                <div
                    class="subtitle-2"
                >
                  注文日<span class="lang_lbl">{{ $t('view.注文日') }}</span>
                  ：{{ item.order_date | date_format }}
                </div>
                <div
                  v-if="item.payment_type !='sale_payment'"
                  class="subtitle-2"
                >
                  ご利用開始日<span class="lang_lbl">{{ $t('view.ご利用開始日') }}</span>
                  ： {{ item.schedule_date | date_format }}
                </div>
                <div
                  v-if="item.payment_type !='sale_payment'"
                  class="subtitle-2"
                >
                  ご利用終了日<span class="lang_lbl">{{ $t('view.ご利用終了日') }}</span>
                  ： {{ item.return_schedule_date | date_format }}
                </div>
                <div
                  v-if="item.payment_type !='sale_payment'"
                  class="subtitle-2"
                >
                  出荷日<span class="lang_lbl">{{ $t('view.出荷日') }}</span>
                  ：{{ item.shipping_date | date_format }}
                </div>
                <div
                  v-if="item.payment_type !='sale_payment'"
                  class="subtitle-2"
                >
                  返却日<span class="lang_lbl">{{ $t('view.返却日') }}</span>
                  ：{{ item.return_date | date_format }}
                </div>
                <div
                  v-if="item.payment_type !='sale_payment'"
                  class="subtitle-2">
                  レンタル金額<span class="lang_lbl">{{ $t('view.レンタル金額') }}</span>
                  ：{{ item.total_amount | yen_format }}
                </div>
                <div
                  v-else
                  class="subtitle-2">
                  注文金額<span class="lang_lbl">{{ $t('view.注文金額') }}</span>
                  ：{{ item.total_amount | yen_format }}
                </div>
                <div
                  v-if="!item.local_stock_item"
                  class="subtitle-2">
                  配送費<span class="lang_lbl">{{ $t('view.配送費') }}</span>
                  ：{{ item.postage | yen_format }}
                </div>
                <div class="subtitle-2">
                  <v-row>
                    <v-col cols="6" style="padding:0 0 0 12px;">
                      備考<span class="lang_lbl">{{ $t('view.備考') }}</span>：
                    </v-col>
                    <v-col cols="6" style="padding:0;">
                      <span class="line-break" >{{order.remarks}}</span>
                    </v-col>
                  </v-row>
                </div>
                <div class="caption" >
                  {{ item.status | statusFilter(item.local_stock_item) }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              small
              color="primary"
              @click="showProduct(item)"
            >
              <v-icon>mdi-cart-outline</v-icon>
              <template v-if="item.payment_type=='sale_payment'">
                再度購入
              </template>
              <template v-else>
                再度レンタル
              </template>
            </v-btn>
            <v-btn
              v-if="item.use_review"
              text
              small
              color="primary"
              @click="openReview(item)"
            >
              <v-icon>mdi-star-outline</v-icon>
              レビューを書く
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              v-if="item.can_stop_subscription"
              text
              small
              color="primary"
              @click="callSubscriptionCancelDialog(item)"
            >
              <v-icon>mdi-email-send-outline</v-icon>
              返却申請<span class="lang_lbl">{{ $t('view.返却申請') }}</span>
            </v-btn>
            <v-btn
              v-if="item.can_stop_subscription"
              text
              small
              color="primary"
              @click="callSubscriptionCancelDialog(item)"
            >
              <v-icon>mdi-email-send-outline</v-icon>
              返却申請<span class="lang_lbl">{{ $t('view.返却申請') }}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- 返却申請ダイアログ -->
    <v-dialog
      v-model="stopSubscriptionDialog"
      :fullscreen="($vuetify.breakpoint.xsOnly)"
      :transition="($vuetify.breakpoint.xsOnly) ? 'dialog-bottom-transition' : ''"
      max-width="600px"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-subtitle class="pa-0">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="closeSubscriptionCancelDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              返却申請<span class="lang_lbl">{{ $t('view.返却申請') }}</span>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <validation-observer
                  v-slot="{ invalid }"
                  ref="stopSubscriptionFormValidation"
                >
                  <v-card-text>
                    <h3>定期課金の停止申請を行います。<span class="lang_lbl">{{ $t('view.定期課金停止_1') }}</span></h3>
                    <p>ご利用終了日を選択し、申請するボタンをクリックしてください。<span class="lang_lbl">{{ $t('view.定期課金停止_2') }}</span></p>
                    <v-alert
                      text
                      dense
                      type="warning"
                    >
                      <p class="subtitle-2 stop_subscript_note">
                        次回の決済日は<span class="lang_lbl">{{ $t('view.次回の決済日') }}</span> {{ stopSubscriptionForm.next_payment_date }} です。
                      </p>
                    </v-alert>
                    <v-menu
                      v-model="showScheduleDatePicker"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          :name="'ご利用終了日(' + $t('view.ご利用終了日') +')'"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="stopSubscriptionForm.scheduled_date"
                            :label="'ご利用終了日(' + $t('view.ご利用終了日') +')'"
                            clearable
                            persistent-hint
                            readonly
                            v-bind="attrs"
                            :error-messages="errors"
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="stopSubscriptionForm.scheduled_date"
                        no-title
                        @input="showScheduleDatePicker = false"
                      />
                    </v-menu>

                    <v-alert
                      text
                      prominent
                      type="error"
                      icon="mdi-cloud-alert"
                    >
                      <p class="subtitle-2 stop_subscript_note">
                        返却の遅延による延滞料金は、弊社より別途ご請求いたします。
                        <span class="lang_lbl">{{ $t('view.延滞料金_1') }}</span>
                      </p>
                      <p class="subtitle-2 stop_subscript_note">
                        ご返却が次回決済日を越えた場合は、追加料金が発生致します。
                        <span class="lang_lbl">{{ $t('view.追加料金_1') }}</span>
                      </p>
                    </v-alert>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      block
                      outlined
                      color="primary"
                      :disabled="invalid"
                      @click="registerStopSubscriptionButtonClickEvent"
                    >
                      <v-icon>mdi-post-outline</v-icon>
                      申請する<span class="lang_lbl">{{ $t('view.申請する') }}</span>
                    </v-btn>
                  </v-card-actions>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- /返却申請ダイアログ -->

    <!-- レビューダイアログ -->
    <v-dialog
      v-model="reviewDialog"
      :fullscreen="($vuetify.breakpoint.xsOnly)"
      :transition="($vuetify.breakpoint.xsOnly) ? 'dialog-bottom-transition' : ''"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-subtitle class="pa-0">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="reviewDialog=false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              商品レビュー<span class="lang_lbl">{{ $t('view.商品レビュー') }}</span>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-subtitle>
        <v-card-text v-if="reviewItem !== null">
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <validation-observer
                  ref="observer"
                  v-slot="{ invalid }"
                >
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="4"
                        class="text-center"
                      >
                        <v-avatar
                          tile
                          size="80"
                        >
                          <v-img
                            :src="reviewItem.image_url | load_image_url"
                            :lazy-src="reviewItem.image_url | load_image_url"
                          />
                        </v-avatar>
                      </v-col>
                      <v-col cols="8">
                        <div class="subtitle-1">
                          {{ reviewItem.product_name }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-row>
                      <v-col cols="12">
                        <div class="text-center">
                          <v-rating
                            v-model="review.rating"
                            :large="(!$vuetify.breakpoint.xsOnly)"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="pt-4">
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:20"
                          :name="'公開名(' + $t('view.公開名') + ')'"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="review.bundle_name"
                            outlined
                            :label="'公開名(' + $t('view.公開名') + ')'"
                            placeholder="れんたる"
                            counter="20"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:50"
                          :name="'レビュータイトル(' + $t('view.レビュータイトル') + ')'"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="review.title"
                            outlined
                            :label="'レビュータイトル(' + $t('view.レビュータイトル') + ')'"
                            :placeholder="'タイトルを入力してください。(' + $t('multi.入力してください', { item: $t('view.レビュータイトル') }) + ')'"
                            counter="50"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:200"
                          :name="'レビュー内容(' + $t('view.レビュー内容') + ')'"
                          class="requiredMark"
                        >
                          <v-textarea
                            v-model="review.comment"
                            outlined
                            :label="'レビュー内容(' + $t('view.レビュー内容') + ')'"
                            :placeholder="'レビュー内容を入力してください。(' + $t('multi.入力してください', { item: $t('view.レビュー内容') })+ ')'"
                            value=""
                            counter="200"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      block
                      outlined
                      :disabled="invalid"
                      @click="submitReview"
                    >
                      <v-icon>mdi-post-outline</v-icon>
                      投稿する<span class="lang_lbl">{{ $t('view.投稿する') }}</span>
                    </v-btn>
                  </v-card-actions>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- /レビューダイアログ -->
  </v-container>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";

export default {
  name: 'MembershipHistoryShow',
  components: {
  },
  filters: {
    statusFilter(status, isLocalStockItem) {
      if (status === 'canceled') {
        // return 'キャンセル済(' + this.$i18n.t('view.キャンセル済')+')'
        return 'キャンセル済'
      } else if (status === 'shipped') {
        if (isLocalStockItem) {
          return ''
        } else {
          // return '出荷済(' + this.$i18n.t('view.出荷済')+')'
          return '出荷済'
        }
      } else {
        if (isLocalStockItem) {
          return ''
        } else {
          // return '出荷準備中(' + this.$i18n.t('view.出荷準備中')+')'
          return '出荷準備中'
        }
      }
    }
  },
  mixins: [
      PageHeaderMixin
  ],
  data () {
    return {
      pageTitle: null,
      order: null,
      orderDetails: null,
      stopSubscriptionDialog: false,
      reviewDialog: false,
      reviewItem: null,
      showScheduleDatePicker: false,
      breadcrumbs: [
        { text: "注文履歴", disabled: false, href: '/histories' },
        { text: "注文詳細", disabled: true, to: '' },
      ],
      review: {
        id: null,
        title: '',
        comment: '',
        rating: 1,
        bundle_name: '',
      },
      stopSubscriptionForm: {
        order_id: null,
        order_detail_id: null,
        next_payment_date: null,
        scheduled_date: null,
      },
      bundle_name: null,
    }
  },
  computed: {
    ...mapState('frontOrder', {
      pagination: 'pagination',
    }),
  },
  created() {
    this.descriptionContent = this.$route.meta.siteSubTitle
    this.pageTitle = this.$route.meta.siteSubTitle
    this.loadOrderDetails(this.$route.params.id)
    this.editCustomer().then((content) => {
      this.bundle_name = content.bundle_name
    })
  },
  methods: {
    ...mapActions('frontOrder', {
      find: 'find',
    }),
    ...mapActions('frontCustomer', {
      editCustomer: 'edit'
    }),
    ...mapActions('frontProductReview', {
      searchReview: 'searchReview',
      createReview: 'create',
      updateReview: 'update'
    }),
    ...mapActions('frontOrderSubscription', {
      stopSubscription: 'delete'
    }),
    showProduct (item) {
      // 商品詳細へ
      this.$router.push({name: 'ProductShow', params: { id: item.product_id }})
    },
    openReview (item) {
      this.reviewItem = item
      this.showReview(item)
    },
    loadOrderDetails (orderId) {
      this.find({ id: orderId }).then((content) => {
        this.orderDetails = content.order_details
        this.order = content.order
      })
    },
    showReview (item) {
      this.searchReview({product_id: item.product_id}).then((content) => {
        this.review = content
        this.reviewDialog = true
      })
    },
    submitReview () {
      const params = {
        id: this.review.id,
        title: this.review.title,
        comment: this.review.comment,
        evaluation: this.review.rating,
        bundle_name: this.review.bundle_name,
      }
      if (this.review.id) {
        this.updateReview({product_id: this.reviewItem.product_id, params: params}).then(() => {
          this.$refs.observer.reset()
          this.reviewDialog = false
        })
      } else {
        this.createReview({ product_id: this.reviewItem.product_id, params: params }).then( () => {
          this.$refs.observer.reset()
          this.reviewDialog = false
        })
      }
    },
    /**
     * 返却申請ダイアログの呼び出し
     * @param order_detail
     */
    callSubscriptionCancelDialog (order_detail) {
      Object.assign(this.$data.stopSubscriptionForm, this.$options.data().stopSubscriptionForm);
      this.stopSubscriptionForm.order_id = order_detail.order_id
      this.stopSubscriptionForm.order_detail_id = order_detail.id
      this.stopSubscriptionForm.next_payment_date = order_detail.next_payment_date
      this.stopSubscriptionDialog = true
    },
    /**
     * 返却申請ダイアログを閉じる
     */
    closeSubscriptionCancelDialog () {
      Object.assign(this.$data.stopSubscriptionForm, this.$options.data().stopSubscriptionForm);
      this.$nextTick(() => this.$refs.stopSubscriptionFormValidation.reset())
      this.stopSubscriptionDialog = false
    },
    /**
     * 返却申請の送信
     */
    registerStopSubscriptionButtonClickEvent () {
      const order_id = this.stopSubscriptionForm.order_id
      const order_detail_id = this.stopSubscriptionForm.order_detail_id
      const params = { return_schedule_date: this.stopSubscriptionForm.scheduled_date }
      this.stopSubscription({ order_id: order_id, order_detail_id: order_detail_id, params: params }).then(() => {
        this.closeSubscriptionCancelDialog()
        this.loadOrderDetails(order_id)
      })
    },
  },
}
</script>

<style scoped lang="scss">
  .stop_subscript_note {
    margin: 0;
  }
</style>