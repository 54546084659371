<template>
  <v-container
    class="grey lighten-5"
  >
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon
            class="mr-2"
          >
            mdi-office-building
          </v-icon>
          会社情報
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="company_code"
                dense
                label="コード"
                placeholder="部分一致"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="company_name"
                dense
                label="会社名"
                placeholder="部分一致"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label>契約種別</label>
              <v-radio-group
                v-model="contract_type"
                row
              >
                <v-radio
                  value="all"
                  label="すべて"
                />
                <v-radio
                  value="trial"
                  label="トライアル"
                />
                <v-radio
                  value="service"
                  label="本契約"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <div class="text-right">
            <v-btn
              color="primary"
              @click="resetCompanies"
            >
              検索
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <br>

    <div class="text-right">
      <v-btn
        color="primary"
        dark
        @click="newButtonClick"
      >
        新規作成
      </v-btn>
    </div>

    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th>ID</th>
              <th>コード</th>
              <th>会社名</th>
              <th>店舗名</th>
              <th>契約種別</th>
              <th>契約期間</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <template v-for="company in companies">
              <CompanyResult
                :key="company.id"
                :company="company"
              />
            </template>
          </tbody>
        </v-simple-table>

        <v-row>
          <v-col
            v-if="hasCompanies"
            cols="12"
          >
            <v-pagination
              v-model="page"
              :length="pagination.total_pages"
              :total-visible="totalVisible"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              circle
              @input="changePage"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex'
import CompanyResult from './_Company'
import ContractPlan from '../../components/ContractPlan'

export default {
  name: 'CompanyIndex',
  components: {CompanyResult},
  mixins: [ContractPlan],
  data () {
    return {
      company_code: null,
      company_name: null,
      contract_type: 'all',
      page: 1,
      totalVisible: 7,
      companies: []
    }
  },
  computed :{
    ...mapState('adminCompany', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
    ...mapGetters('adminCompany', [
      'hasCompanies'
    ])
  },
  watch: {
    company_code (newValue) {
      this.fetchQuery({ company_code: newValue })
    },
    company_name (newValue) {
      this.fetchQuery({ company_name: newValue })
    },
    contract_type (newValue) {
      this.fetchQuery({ contract_type: newValue })
    }
  },
  created() {
    this.company_code = this.searchQuery.company_code
    this.company_name = this.searchQuery.company_name
    this.contract_type = this.searchQuery.contract_type
    this.searchCompanies()
  },
  methods: {
    ...mapActions('adminCompany', {
      fetchCompanies: 'fetchAll',
      fetchQuery: 'fetchQuery',
    }),
    resetCompanies () {
      this.panel=[]
      this.page = 1
      this.searchCompanies()
    },
    changePage () {
      this.searchCompanies()
    },
    searchCompanies () {
      const params = {
        company_code: this.company_code,
        company_name: this.company_name,
        contract_type: this.contract_type,
        page: this.page
      }
      this.fetchCompanies(params).then((content) => {
        this.companies = content.companies
      })

    },
    newButtonClick () {
      this.$router.push( { name: 'adCompanyNew' } )
    },
  }
}
</script>

<style scoped>

</style>