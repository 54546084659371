/**
 * オプションアイテム
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/option_groups`

const option_item = {
    namespaced: true,
    state: {},
    actions: {
        fetchAll ({ commit, state },{ option_group_id, page }) {
            const api_path = `${API_PATH}/${option_group_id}/option_items`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        fetch ({ _commit }, { option_group_id, id}) {
            const api_path = `${API_PATH}/${option_group_id}/option_items/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        new ({ _commit }, { option_group_id }) {
            const api_path = `${API_PATH}/${option_group_id}/option_items/new`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        edit({ _commit, state }, { option_group_id, id }) {
            const api_path = `${API_PATH}/${option_group_id}/option_items/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        create( { commit, state }, { option_group_id, params } ) {

            const api_path = `${API_PATH}/${option_group_id}/option_items`

            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(api_path, params, { headers: { 'content-type': 'multipart/form-data' } }).then( data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    resolve(data.content)
                } )
            })
        },
        update( {commit, state}, { option_group_id, id, params } ) {

            const api_path = `${API_PATH}/${option_group_id}/option_items/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params, { headers: { 'content-type': 'multipart/form-data'}}).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(data.content)
                } )
            })
        },
        destroy( { commit, state }, { option_group_id, id } ) {
            const api_path = `${API_PATH}/${option_group_id}/option_items/${id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
    },
    mutations: {},
}
export default option_item