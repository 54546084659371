var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert-message'),_vm._v(" "),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"配送業者名","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"30","dense":"","hide-details":"auto","label":"配送業者名","outlined":"","placeholder":" ","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"追跡サイトURL","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"255","dense":"","hide-details":"auto","label":"追跡サイトURL","outlined":"","placeholder":" ","required":""},model:{value:(_vm.tracking_site_url),callback:function ($$v) {_vm.tracking_site_url=$$v},expression:"tracking_site_url"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"表示順","rules":"required|numeric|min_value:0|max_value:2147483647"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"表示順","outlined":"","placeholder":" ","required":"","reverse":""},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[(_vm.isEdit)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteButtonClickEvent}},[_vm._v("\n            削除\n          ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.deliveryCompanyCancel}},[_vm._v("\n            キャンセル\n          ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.submit}},[_vm._v("\n            保存\n          ")])],1)],1)],1)]}}])}),_vm._v(" "),(_vm.isEdit)?_c('confirm',{ref:"confirm"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }