<template>
  <v-container>
    <v-row v-if="isText">
      <v-col cols="12">
        {{ content.banner_text }}
      </v-col>
    </v-row>
    <v-row v-if="isBanner">
      <template v-for="index in columnCount">
        <v-col
          cols="12"
          :sm="12 / columnCount"
          :key="`banner_${index}`"
        >
          <v-card
            class="white lighten-4"
            flat
            tile
          >
            <v-img
              contain
              :src="content.banner_links[index-1].image_url | load_image_url"
              :lazy-src="content.banner_links[index-1].image_url | load_image_url"
              aspect-ratio="1"
              class="white lighten-2 animated banner_img"
              :style="{cursor: cursorPointerStyle(content.banner_links[index-1]), padding: 0}"
              @click="linkAction(content.banner_links[index-1])"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-row v-if="isYoutube">
      <v-col
        cols="12"
        align="center"
        class="pa-0"
      >
        <youtube
          :video-id="youtubeId"
          :fitParent="$vuetify.breakpoint.xsOnly"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from "vuex";

  /**
   * バナー差し込み用
   * <banner-nav :option_type="表示するオプション種別" />
   */
  export default {
    name: 'BannerNav',
    props: {
      optionType: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        content: {},
      }
    },
    computed: {
      youtubeId () {
        return this.$youtube.getIdFromUrl(this.content.youtube_url)
      },
      isText () {
        return this.content.banner_type === 'text'
      },
      isBanner () {
        return this.content.banner_type === 'banner'
      },
      isYoutube () {
        return this.content.banner_type === 'youtube'
      },
      columnCount () {
        if (this.content.column_type === 'column4') {
          return 4
        } else if (this.content.column_type === 'column3') {
          return 3
        } else if (this.content.column_type === 'column2') {
          return 2
        } else {
          return 1
        }
      }
    },
    mounted() {
      if (this.optionType === '') {
        return
      }
      const params = { option_type: this.optionType }
      this.fetch({ params }).then((content) => {
        this.content = content
      })

    },
    methods: {
      ...mapActions('frontBannerOption', {
        fetch: 'fetch'
      }),
      linkAction (bannerLink) {
        const isClearCondition = true
        switch (bannerLink.transition_type) {
          case 'item_detail':
            // 商品詳細
            this.$router.push({name: 'ProductShow', params: { id: bannerLink.product_id }})
            break
          case 'category':
            // 商品一覧(カテゴリーで絞り込み）
            this.$router.push({name: 'ProductIndex', query: {category_id: bannerLink.category_id}, params: { isClearCondition: isClearCondition }})
            break
          case 'tag':
            // 商品一覧(タグで絞り込み)
            this.$router.push({name: 'ProductIndex', query: {tag_id: bannerLink.tag_id}, params: { isClearCondition: isClearCondition }})
            break
          case 'external':
            // 外部
            window.open(bannerLink.transition_url, '_blank')
            break
          case 'my_page':
            this.$router.push({name: 'MyPageShow', params: {post_name: bannerLink.my_page_post_name, title: bannerLink.my_page_title}})
            break
          default:
            break
        }

      },
      cursorPointerStyle (bannerLink) {
        if (bannerLink.transition_type === 'no_transition') {
          return ''
        }
        return 'pointer'
      },
    },
  }

</script>

<style scoped lang="scss">

.animated:hover {
  animation-name: pulse;
  animation-duration: 1s;
}

/* バナー1枚 */
.col-sm-12 .banner_img{
  max-height:142px;
}

/* バナー2枚 */
.col-sm-6 .banner_img{
  max-height:110px;
}

/* バナー3枚 */
.col-sm-4 .banner_img{
  max-height:300px;
}

/* バナー4枚 */
.col-sm-3 .banner_img{
  //max-height:185px;
  max-height:270px;
}

</style>