/**
 * カテゴリー
 */
import Vue from 'vue'
import * as types from '../../mutation-types/backyard-types'
import * as httpConst from '../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/categories`

const category = {
    namespaced: true,
    state: {
        pagination: {
            current_page: 1,
            total_count: 0
        },
        query: {
            name: '',
            home_display: false,
        },
    },
    getters: {
        isCategories: ( state ) => {
            return (state.pagination.total_count > 0)
        },
    },
    actions: {
        find ({ _commit }, {id}) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        new () {
            const api_path = `${API_PATH}/new`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        edit ({ _commit }, {id}) {
            const api_path = `${API_PATH}/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        create ({ commit }, { params }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.post(API_PATH, params, { headers: { 'content-type': 'multipart/form-data' } }).then(data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
        update ({ commit }, { id, params }) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params,{ headers: { 'content-type': 'multipart/form-data' } }).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(data.content)
                } )
            })
        },
        destroy( { commit, state }, { id } ) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
        findCategories ({ _commit },{ params }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    resolve(data.content)
                })
            })
        },
        fetchCategories({ dispatch, commit, state }, {name, home_display, page}) {
            dispatch('fetchQuery', {name: name, home_display: home_display})
            const params = { name: state.query.name, home_display: state.query.home_display, page: page }

            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    commit(types.BACK_CATEGORY, { content })
                    resolve(data.content)
                })
            })
        },
        fetchQuery({ commit, state}, {name, home_display}) {
            const Name = (name !== undefined)? name : state.query.name
            const homeDisplay = (home_display !== undefined)? home_display : state.query.home_display
            const params = { name: Name, home_display: homeDisplay}
            commit(types.BACK_CATEGORY_QUERY, { params })
        },
    },
    mutations: {
        [types.BACK_CATEGORY] (state, { content }) {
            state.pagination = content.pagination
        },
        [types.BACK_CATEGORY_QUERY] (state, { params }) {
            state.query.name = params.name
            state.query.home_display = params.home_display
        },
    },
}

export default category