var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"small":""},on:{"click":_vm.openItemSearchDialog}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.productRelations)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.productRelations,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image_url!=null? item.image_url:'',"max-height":"40","max-width":"100","contain":""}})]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("comma_format")(item.price))+" 円\n              ")]}},{key:"item.display_period_date",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("from_to_date_format")(item.display_period_start_date,item.display_period_end_date))+"\n              ")]}},{key:"item.category_names",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"wrap_text",staticStyle:{"width":"240px"}},[_vm._v("\n                  "+_vm._s(item.category_names)+"\n                ")])]}},{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.relationDeleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.relationDeleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}],null,false,3500850721)}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-row',[(_vm.isProductRelations)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pagination.total_pages,"total-visible":_vm.totalVisible,"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right","circle":""},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)],1)],1),_vm._v(" "),_c('item-search-dialog',{ref:"ItemSearchDialog",on:{"itemSearchDialogCallback":_vm.itemSearchDialogCallback}}),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }