/**
 * 現地
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/local_places`

const local_place = {
    namespaced: true,
    state: {
        pagination: {
            current_page: 1,
            total_count: 0
        },
        query: {
            name: '',
            home_display: false,
        },
    },
    getters: {
    },
    actions: {
        new ({ _commit }) {
            const api_path = `${API_PATH}/new`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        edit ({ _commit }, {id}) {
            const api_path = `${API_PATH}/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        create ({ commit }, { params }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.post(API_PATH, params).then(data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
        update ({ commit }, { id, params }) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params ).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(data.content)
                } )
            })
        },
        destroy( { commit, state }, { id } ) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
        findLocalPlaces ({ _commit },{ params }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    resolve(data.content)
                })
            })
        },
        fetchLocalPlaces({ dispatch, commit, state }, {name, page}) {
            dispatch('fetchQuery', {name: name})
            const params = { name: state.query.name, page: page }

            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    commit(types.BACK_LOCAL_PLACE, { content })
                    resolve(data.content)
                })
            })
        },
        fetchQuery({ commit, state}, {name}) {
            const Name = (name !== undefined)? name : state.query.name
            const params = { name: Name}
            commit(types.BACK_LOCAL_PLACE_QUERY, { params })
        },
        find ({ _commit }, {id}) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        }
    },
    mutations: {
        [types.BACK_LOCAL_PLACE] (state, { content }) {
            state.pagination = content.pagination
        },
        [types.BACK_LOCAL_PLACE_QUERY] (state, { params }) {
            state.query.name = params.name
        },
    },
}

export default local_place