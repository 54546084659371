/**
 * 定期課金通知エラーメール
 */
import Vue from 'vue'
import * as httpConst from '../../../../../constants/httpConst'
const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/orders`
const send_failed_mail = {
  namespaced: true,
  actions: {
    create( { commit }, { order_id, id} ) {
      const api_path = `${API_PATH}/${order_id}/order_subscriptions/${id}/send_failed_mail`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.post(api_path).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '送信しました' }, { root: true })
          resolve(content)
        } )
      })
    }
  },
}

export default send_failed_mail