/**
 * 注文情報
 * 顧客情報、備考、カードトークン
 */
import * as types from "../../mutation-types/frontend-types"

const payment = {
  namespaced: true,
  state: {
    delivery: {
      name: null,
      zip_code: null,
      prefecture_code: null,
      payment_type: null,
      address: null,
      phone: null,
      remarks: null,
      card_token: null,
      desired_time_id: null,
      desired_time_value: null,
      utm_source: null,
      free_shipping_flag: null
    }
  },
  getters: {},
  actions: {
    fetch({ commit }, {delivery, card_token}) {
      commit(types.FRONT_PAYMENT_CUSTOMER, {delivery, card_token})
    },
  },
  mutations: {
    [types.FRONT_PAYMENT_CUSTOMER] (state, { delivery, card_token }) {
      state.delivery.name = delivery.name
      state.delivery.payment_type = delivery.payment_type
      state.delivery.zip_code = delivery.zip_code
      state.delivery.prefecture_code = delivery.prefecture_code
      state.delivery.address = delivery.address
      state.delivery.phone = delivery.phone
      state.delivery.remarks = delivery.remarks
      state.delivery.card_token = card_token
      state.delivery.desired_time_id = delivery.desired_time_id
      state.delivery.desired_time_value = delivery.desired_time_value
    },
  },
}

export default payment
