/**
 * 会社信用情報構成
 */
import Vue from 'vue'
import * as types from "../../mutation-types/frontend-types"
import * as httpConst from "../../../constants/httpConst";
import VueGtag from "vue-gtag";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/company/credence`

const credence = {
  namespaced: true,
  state: {
    content: {},
  },
  getters: {},
  actions: {
    fetch({ commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content

          // Vue.$analytics.config.id = content.tracking_id
          Vue.use(VueGtag, {
            config: { id: content.tracking_id }
          });

          commit(types.FRONT_CREDENCE, { content })
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.FRONT_CREDENCE] (state, { content }) {
      state.content = content
    },
  },
}

export default credence
