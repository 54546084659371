/**
 * 店舗からのお知らせ
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/frontend-types";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/customer/notices`

const customerNotice = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0
    },
  },
  getters: {
    isCustomerNotice: ( state ) => {
      return (state.pagination.total_count > 0)
    },
  },
  actions: {
    findCustomerNotices({ commit }, { params }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.FRONT_CUSTOMER_NOTICE, { content })
          resolve(content)
        })
      })
    },
    show ({ _commit }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
  },
  mutations: {
    [types.FRONT_CUSTOMER_NOTICE] (state, { content }) {
      state.pagination = content.pagination
    },
  },
}

export default customerNotice
