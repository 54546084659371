/**
 * 商品
 */
import Vue from "vue";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/configurations`

const configuration = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetch ({ commit, state }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    update( {commit, state}, { params } ) {
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(API_PATH, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(content)
        } )
      })
    }
  },
  mutations: {},
}

export default configuration