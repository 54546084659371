/**
 * ホーム構成
 */
import Vue from "vue";
import * as types from "../../../mutation-types/frontend-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/configurations/theme_option`

const config_theme_option = {
  namespaced: true,
  state: {
    slider: {
      use_display_area: false,
      interval: 3000,
      animation_type: 'slide',
      use_banner_option: false,
      banner_option_type: '',
      options: [],
    },
    news: {
      use_display_area: false,
      title: '',
      sub_title: '',
      use_banner_option: false,
      banner_option_type: '',
      use_background_image_contents: false,
      show_news_date: true
    },
    category: {
      use_display_area: false,
      title: '',
      sub_title: '',
      use_banner_option: false,
      banner_option_type: '',
      use_background_image_contents: false,
    },
    new_arrivals: {
      use_display_area: false,
      title: '',
      sub_title: '',
      use_banner_option: false,
      banner_option_type: '',
      use_background_image_contents: false,
    },
    new_arrivals_button: {
      use_display_area: false,
      title: '',
      use_banner_option: false,
      banner_option_type: '',
      use_background_image_contents: false,
    },
    campaign: {
      use_display_area: false,
      title: '',
      sub_title: '',
      use_banner_option: false,
      banner_option_type: '',
      use_background_image_contents: false,
    },
    campaign_button: {
      use_display_area: false,
      title: '',
      use_banner_option: false,
      banner_option_type: '',
      use_background_image_contents: false,
    },
    payment_note: {
      use_payment_note: false,
      payment_note_my_page: null,
      payment_note_title: null,
    }
  },
  getters: {
    isUseSlider: ( state ) => {
      return state.slider.use_display_area
    },
    isUseNews: ( state ) => {
      return state.news.use_display_area
    },
    isUseCategory: ( state ) => {
      return state.category.use_display_area
    },
    isUseNewArrivals: ( state ) => {
      return state.new_arrivals.use_display_area
    },
    isUseNewArrivalsButton: ( state ) => {
      return state.new_arrivals_button.use_display_area
    },
    isUseCampaign: ( state ) => {
      return state.campaign.use_display_area
    },
    isUseCampaignButton: ( state ) => {
      return state.campaign_button.use_display_area
    },
    isCycle: ( state ) => {
      return (state.slider.interval > 0)
    },
    interval: ( state ) => {
      return (state.slider.interval > 0) ? state.slider.interval : 6000
    },
    slideAnimation: ( state ) => {
      return (state.slider.animation_type === 'slide')
    },
    canShowNewsDate: (state) => {
      return state.news.show_news_date
    },
    showPaymentNote: (state) => {
      return state.payment_note.use_payment_note && state.payment_note.payment_note_my_page;
    }
  },
  actions: {
    fetch({ commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          const content = data.content;
          commit(types.FRONT_THEME, { content })
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.FRONT_THEME] (state, { content }) {
      state.slider = content.slider
      state.news = content.news
      state.category = content.category
      state.new_arrivals = content.new_arrivals
      state.new_arrivals_button = content.new_arrivals_button
      state.campaign = content.campaign
      state.campaign_button = content.campaign_button
      state.payment_note = content.payment_note
    },
  },
}

export default config_theme_option
