/**
 * カード
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/frontend-types";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/customer/cards`

const card = {
  namespaced: true,
  state: {
    cartCount: 0,
    content: {},
  },
  getters: {
    isCards: (state) => {
      return (state.content.cards && state.content.cards.length > 0)
    },
  },
  actions: {
    findCards({ _commit }) {
      return new Promise((resolve, reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          resolve(content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    create ({ commit }, { params }) {
      return new Promise((resolve, reject) => {
        Vue.$http.post(API_PATH, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    update ({ commit }, { params }) {
      return new Promise((resolve, reject) => {
        Vue.$http.put(API_PATH, params).then(data => {
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    delete ({ commit }, { params }) {
      return new Promise((resolve, reject) => {
        Vue.$http.delete(API_PATH, {data: params}).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
  },
  mutations: {
  },
}

export default card
