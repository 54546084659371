<!-- バックヤード > ユーザー一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          ユーザ管理
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              @click="newButtonClickEvent"
            >
              ユーザー追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="keyword"
                  dense
                  hide-details="auto"
                  label="キーワード"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-radio-group
                  v-model="role"
                  class="pa-0 ma-0"
                  dense
                  hide-details="auto"
                  required
                  row
                >
                  <v-radio
                    value="all"
                    label="全て"
                  />
                  <v-radio
                    value="administrator"
                    label="管理者"
                  />
                  <v-radio
                    value="manager"
                    label="マネージャー"
                  />
                  <v-radio
                    value="general"
                    label="一般"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="resetUsers"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" />
                  <th class="text-left">
                    ログインID
                  </th>
                  <th class="text-left">
                    ユーザー名
                  </th>
                  <th class="text-left">
                    メールアドレス
                  </th>
                  <th class="text-left">
                    権限
                  </th>
                  <th class="text-center">
                    表示順
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="user in users"
                  :key="user.id"
                >
                  <td>
                    <v-avatar>
                      <v-img
                        v-if="user.image_url"
                        :src="user.image_url"
                        :alt="user.name"
                      />
                    </v-avatar>
                  </td>
                  <td>{{ user.login_id }}</td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.role }}</td>
                  <td class="text-center">
                    {{ user.position }}
                  </td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="sendOpeningMailButtonClickEvent(user)"
                    >
                      <v-icon>mdi-email-send-outline</v-icon>
                    </v-btn>

                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="editButtonClickEvent(user)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="hasUsers"
        cols="12"
      >
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

  import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    components: {
    },
    mixins: [
    ],
    data () {
      return {
        page: 1,
        totalVisible: 7,
        keyword: null,
        role: 'all',
        users: [],
      }
    },
    computed: {
      ...mapState('backUser', {
        pagination: 'pagination',
        searchQuery: 'query',
      }),
      ...mapGetters('backUser', [
        'hasUsers'
      ])
    },
    watch: {
      keyword (newVal) {
        this.fetchQuery({ keyword: newVal })
      },
      user_type (newVal) {
        this.fetchQuery({ role: newVal })
      }
    },
    created() {
      this.keyword = this.searchQuery.keyword
      this.role = this.searchQuery.role
      this.page = this.pagination.current_page
      this.searchUsers()
    },
    methods: {
      ...mapActions('backUser', {
        fetchAll: 'fetchUsers',
        fetchQuery: 'fetchQuery',
        sendOpeningMail: 'sendOpeningMail'
      }),
      resetUsers () {
        this.page = 1
        this.searchUsers()

      },
      changePage () {
        this.searchUsers()
      },
      searchUsers () {
        const params = {
          keyword: this.keyword,
          role: this.role,
          page: this.page
        }
        this.fetchAll(params).then((content) => {
          this.users = content.users
        })

      },
      newButtonClickEvent () {
        this.$router.push({name: 'byUserNew'})
      },
      editButtonClickEvent (user) {
        this.$router.push({name: 'byUserEdit', params: {id: user.id}})
      },
      sendOpeningMailButtonClickEvent (user) {
        this.sendOpeningMail({ id: user.id })
      }
    }
  }
</script>

<style lang="scss">

</style>
