<template>
  <div>
    <v-container
      class="grey lighten-5"
    >
      <v-breadcrumbs
        :items="breadcrumbs"
      />
      <v-card>
        <v-card-title>
          <h2>
            <v-icon class="mr-2">
              mdi-bell
            </v-icon>
            システムお知らせ作成
          </h2>
        </v-card-title>

        <v-card-text>
          <Form
            v-if="announcement"
            :is-edit="false"
            :announcement="announcement"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Form from './_Form';

  export default {
    name: 'AnnouncementNew',
    components: {
      Form, // 共通フォーム参照
    },
    data () {
      return {
        announcement: null,
        breadcrumbs: [
          { text: 'お知らせ', disabled: false, to: '/admin/announcements', exact: true },
          { text: 'お知らせ作成', disabled: false, href: '' }
        ],
      }
    },

    created() {
      this.newAnnouncement().then((content) => {
        this.announcement = content
      })
    },
    methods: {
      ...mapActions('adminAnnouncement', {
        newAnnouncement: 'new'
      })
    }

  }
</script>

<style scoped>

</style>