<!-- バックヤード > ホーム -->
<template>
  <div>
    <v-row>
      <v-col>
        <payment-request />
        <v-alert
          v-for="announce in announces"
          :key="announce.id"
          border="left"
          colored-border
          dense
          elevation="2"
          dismissible
          @input="announce_close(announce.id)"
        >
          <a
            class="text-decoration-underline"
            @click="showAnnouncementLinkClickEvent(announce.id)"
          >{{ announce.notice_date | date_format }}&nbsp;{{ announce.title }}&nbsp;詳細はこちら</a>
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <!-- システムからのお知らせ -->
      <v-col cols="12">
        <h3>システムからのお知らせ</h3>
        <v-card
          outlined
          flat
        >
          <v-card-subtitle>
            <h3 v-if="announcement_pagination.total_count <= 0">
              システムからのお知らせはありません。
            </h3>
            <h3 v-else>
              <a
                class="text-decoration-underline red--text"
                @click="showAnnouncementsLinkButtonClickEvent"
              >
                システムからのお知らせが{{ announcement_pagination.total_count }}件あります。
              </a>
            </h3>
          </v-card-subtitle>
          <v-card-text v-if="announcement_pagination.total_count > 0">
            <v-list
              subheader
              two-line
            >
              <template v-for="(announcement, index) in announcements">
                <v-divider :key="`div-${index}`" />
                <v-list-item :key="announcement.id">
                  <v-list-item-content>
                    <v-list-item-title>{{ announcement.notice_date }}</v-list-item-title>
                    <v-list-item-subtitle v-text="announcement.title" />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="showAnnouncementButtonClickEvent(announcement)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- /システムからのお知らせ -->

      <!-- お客様からの問い合わせ -->
      <v-col cols="12">
        <h3>お客様からのお問い合わせ</h3>
        <v-card
          flat
          outlined
        >
          <v-card-subtitle>
            <h3 v-if="ticket_pagination.total_count <= 0">
              お客様からのお問い合わせはありません。
            </h3>
            <h3 v-else>
              <a
                class="text-decoration-underline red--text"
                @click="showTicketsButtonClickEvent"
              >
                お客様からのお問い合わせが{{ ticket_pagination.total_count }}件あります。
              </a>
            </h3>
          </v-card-subtitle>
          <v-card-text v-if="ticket_pagination.total_count > 0">
            <v-list
              subheader
              two-line
            >
              <template
                v-for="(ticket, index) in tickets"
              >
                <v-divider :key="`div-${index}`" />
                <v-list-item :key="ticket.id">
                  <v-list-item-action>
                    <v-chip
                      label
                      x-small
                      :ripple="false"
                      :class="changeChipColor(ticket)"
                    >
                      {{ ticket.status | ticketStatusFilter }}
                    </v-chip>
                  </v-list-item-action>
                  <v-list-item-avatar
                    v-if="ticket.my_posted"
                    color="primary"
                  >
                    <span class="white--text">{{ ticket.owner_name | avatarNameFilter }}</span>
                  </v-list-item-avatar>
                  <v-list-item-avatar
                    v-else
                    color="grey lighten-3"
                  >
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ ticket.created_at | unix_datetime_format }}&emsp;&emsp;{{ ticket.customer_name }}</v-list-item-title>
                    <v-list-item-subtitle v-text="ticket.first_comment" />
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="editTicketButtonClickEvent(ticket)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
          <v-card-actions v-if="ticket_pagination.total_count > 0">
            <v-btn
              color="teal lighten-3"
              block
              small
              @click="showTicketsButtonClickEvent"
            >
              問い合わせ一覧表示
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- /お客様からの問い合わせ -->

      <!-- 商品への問い合わせ -->
      <v-col cols="12">
        <h3>商品への問い合わせ</h3>
        <v-card
          flat
          outlined
        >
          <v-card-subtitle>
            <h3 v-if="product_question_pagination.total_count <= 0">
              商品への問い合わせはありません。
            </h3>
            <h3 v-else>
              <a
                class="text-decoration-underline red--text"
                @click="showProductQuestionsButtonClickEvent"
              >
                商品への問い合わせが{{ product_question_pagination.total_count }}件あります。
              </a>
            </h3>
          </v-card-subtitle>

          <v-card-text v-if="product_question_pagination.total_count > 0">
            <v-data-table
              :headers="productQuestionTableHeaders"
              :items="product_questions"
              :items-per-page="-1"
              disable-sort
              dense
              hide-default-footer
            >
              <template v-slot:item.question_at="{ item }">
                {{ item.question_at | unix_datetime_format }}
              </template>
              <template v-slot:item.question_content="{ item }">
                {{ item.question_content | truncate(30) }}
              </template>
              <template v-slot:item.editable="{ item }">
                <v-btn
                  v-if="$vuetify.breakpoint.mdAndUp"
                  color="cyan darken-2"
                  icon
                  @click="showProductQuestionButtonClickEvent(item)"
                >
                  <v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="info"
                  small
                  @click="showProductQuestionButtonClickEvent(item)"
                >
                  参照する
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions v-if="product_question_pagination.total_count > 0">
            <v-btn
              small
              block
              color="teal lighten-3"
              @click="showProductQuestionsButtonClickEvent"
            >
              商品への問い合わせ一覧表示
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- /商品への問い合わせ -->

      <!-- 未発送商品 -->
      <v-col cols="12">
        <h3>未発送商品</h3>
        <v-card outlined>
          <v-card-subtitle>
            <h3 v-if="unshipped_pagination.total_count <= 0">
              未発送商品はありません。
            </h3>
            <h3 v-else>
              <a
                class="text-decoration-underline red--text"
                @click="showUnshippedOrdersButtonClickEvent"
              >
                未発送商品が{{ unshipped_pagination.total_count }}件あります。
              </a>
            </h3>
          </v-card-subtitle>
          <v-card-text
            v-if="unshipped_pagination.total_count > 0"
          >
            <v-data-table
              :headers="unshippedTableHeaders"
              :items="unshipped_items"
              :items-per-page="-1"
              disable-sort
              dense
              hide-default-footer
            >
              <template v-slot:item.order_info="{ item }">
                {{ item.order_no }}/
                {{ item.customer_name }}
              </template>
              <template v-slot:item.product_info="{ item }">
                {{ item.part_number_1 }}/
                {{ item.product_name }}/
                {{ item.product_stock_no }}
              </template>
              <template v-slot:item.editable="{ item }">
                <v-btn
                  v-if="$vuetify.breakpoint.mdAndUp"
                  color="cyan darken-2"
                  icon
                  @click="showUnshippedOrderButtonClickEvent(item)"
                >
                  <v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="info"
                  small
                  @click="showUnshippedOrderButtonClickEvent(item)"
                >
                  参照する
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions
            v-if="unshipped_pagination.total_count > 0"
          >
            <v-btn
              small
              block
              color="teal lighten-3"
              @click="showUnshippedOrdersButtonClickEvent"
            >
              受注一覧表示
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- /未発送商品 -->

      <!-- 未返却商品 -->
      <v-col cols="12">
        <h3>未返却商品</h3>
        <v-card outlined>
          <v-card-text>
            <h3 v-if="unreturned_pagination.total_count <= 0">
              未返却商品はありません。
            </h3>
            <h3 v-else>
              <a
                class="text-decoration-underline red--text"
                @click="showUnreturnedItemsButtonClickEvent"
              >返却されていない商品が{{ unreturned_pagination.total_count }}件あります。</a>
            </h3>
          </v-card-text>
          <v-card-text v-if="unreturned_pagination.total_count > 0">
            <v-data-table
              :headers="unreturnedTableHeaders"
              :items="unreturned_items"
              :items-per-page="-1"
              disable-sort
              dense
              hide-default-footer
            >
              <template v-slot:item.order_info="{ item }">
                {{ item.order_no }}<span v-if="item.order_no !== ''">/</span>{{ item.customer_name }}
              </template>
              <template v-slot:item.product_info="{ item }">
                {{ item.part_number_1 }}/
                {{ item.product_name }}/
                {{ item.product_stock_no }}
              </template>
              <template v-slot:item.local_stock_item="{ item }" >
                {{ item.local_stock_item ? '◯' : '' }}
              </template>
              <template v-slot:item.editable="{ item }">
                <v-btn
                  v-if="$vuetify.breakpoint.mdAndUp"
                  color="cyan darken-2"
                  icon
                  @click="showUnreturnedItemButtonClickEvent(item)"
                >
                  <v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="info"
                  small
                  @click="showUnreturnedItemButtonClickEvent(item)"
                >
                  参照する
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions v-if="unreturned_pagination.total_count > 0">
            <v-btn
              small
              block
              color="teal lighten-3"
              @click="showUnreturnedItemsButtonClickEvent"
            >
              返却一覧表示
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- /未返却商品 -->

      <!-- 未検品商品 -->
      <v-col cols="12">
        <h3>未検品商品</h3>
        <v-card outlined>
          <v-card-subtitle>
            <h3 v-if="uninspected_pagination.total_count <= 0">
              未検品商品はありません。
            </h3>
            <h3 v-else>
              <a
                class="text-decoration-underline red--text"
                @click="showUninspectedItemsButtonClickEvent"
              >
                検品が終了していない商品が{{ uninspected_pagination.total_count }}件あります
              </a>
            </h3>
          </v-card-subtitle>
          <v-card-text v-if="uninspected_pagination.total_count > 0">
            <v-data-table
              :headers="uninspectedTableHeaders"
              :items="uninspected_items"
              :items-per-page="-1"
              disable-sort
              dense
              hide-default-footer
            >
              <template v-slot:item.product_info="{ item }">
                {{ item.part_number_1 }}/
                {{ item.product_name }}/
                {{ item.stock_no }}
              </template>
              <template v-slot:item.local_stock_item="{ item }" >
                {{ item.local_stock_item ? '◯' : '' }}
              </template>
              <template v-slot:item.editable="{ item }">
                <v-btn
                  v-if="$vuetify.breakpoint.mdAndUp"
                  color="cyan darken-2"
                  icon
                  @click="showUninspectedItemButtonClickEvent(item)"
                >
                  <v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="info"
                  small
                  @click="showUninspectedItemButtonClickEvent(item)"
                >
                  参照する
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions v-if="uninspected_pagination.total_count > 0">
            <v-btn
              small
              block
              color="teal lighten-3"
              @click="showUninspectedItemsButtonClickEvent"
            >
              商品一覧表示
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- /未検品商品 -->

      <!-- 未決済受注 -->
      <v-col cols="12">
        <h3>未決済受注</h3>
        <v-card outlined>
          <v-card-subtitle>
            <h3 v-if="unsettled_pagination.total_count <= 0">
              未決済の受注はありません。
            </h3>
            <h3 v-else>
              <a
                class="text-decoration-underline red--text"
                @click="showUnsettledOrdersButtonClickEvent"
              >
                未決済の受注が{{ unsettled_pagination.total_count }}件あります。
              </a>
            </h3>
          </v-card-subtitle>
          <v-card-text
            v-if="unsettled_pagination.total_count > 0"
          >
            <v-data-table
              :headers="unsettledTableHeaders"
              :items="unsettled_orders"
              :items-per-page="-1"
              dense
              disable-sort
              hide-default-footer
            >
              <template v-slot:item.editable="{ item }">
                <v-btn
                  v-if="$vuetify.breakpoint.mdAndUp"
                  color="cyan darken-2"
                  icon
                  @click="showUnsettledOrderButtonClickEvent(item)"
                >
                  <v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="info"
                  small
                  @click="showUnsettledOrderButtonClickEvent(item)"
                >
                  参照する
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions
            v-if="unsettled_pagination.total_count > 0"
          >
            <v-btn
              color="teal lighten-3"
              block
              small
              @click="showUnsettledOrdersButtonClickEvent"
            >
              受注一覧表示
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- /未決済商品 -->
    </v-row>
  </div>
</template>

<script>
import PaymentRequest from '../../components/PaymentRequest'
import TicketMixin from '../../../mixins/ticket-mixin'
import { mapActions } from 'vuex'
export default {
  components: {
    PaymentRequest
  },
  mixins: [
    TicketMixin
  ],

  data () {
    return {
      announces: [],
      announcements: [],
      announcement_pagination: {
        current_page: 1,
        total_pages: 0,
        total_count: 0,
        next_page: null
      },
      /**
       * @typedef tickets { Array }
       * @property owner_name { String }
       * @property my_posted { Boolean }
       */
      tickets: [],
      ticket_pagination: {
        current_page: 1,
        total_pages: 0,
        total_count: 0,
        next_page: null
      },
      productQuestionTableHeaders:[
        { value: 'part_number_1', text: '品番1' },
        { value: 'product_name', text: '商品名' },
        { value: 'customer_name', text: '質問者' },
        { value: 'question_at', text: '質問日時', align: 'center' },
        { value: 'question_content', text: '質問' },
        { value: 'editable', text: '', align: 'end' },
      ],
      /**
       * @typedef product_questions { Array }
       * @property question_at { String }
       */
      product_questions: [],
      product_question_pagination: {
        current_page: 1,
        total_pages: 0,
        total_count: 0,
        next_page: null
      },
      unshippedTableHeaders: [
        { value: 'order_date', text: '受注日', align: 'center' },
        { value: 'order_info', text: '注文番号/会員名' },
        { value: 'product_info', text: '品番1/商品名/在庫管理番号' },
        { value: 'schedule_date', text: 'ご利用開始日', align: 'center' },
        { value: 'editable', text: '', align: 'end' },
      ],
      unshipped_items: [],
      unshipped_pagination: {
        current_page: 1,
        total_pages: 0,
        total_count: 0,
        next_page: null
      },
      unreturnedTableHeaders: [
        { value: 'order_date', text: '受注日', align: 'center' },
        { value: 'order_info', text: '注文番号/会員名' },
        { value: 'product_info', text: '品番1/商品名/在庫管理番号' },
        { value: 'local_stock_item', text: '現地在庫品', align: 'center' },
        { value: 'schedule_date', text: 'ご利用開始日', align: 'center' },
        { value: 'rental_day', text: 'ご利用日数', align: 'right' },
        { value: 'return_schedule_date', text: 'ご利用終了日', align: 'center' },
        { value: 'editable', text: '', align: 'end' },
      ],
      unreturned_items: [],
      unreturned_pagination: {
        current_page: 1,
        total_pages: 0,
        total_count: 0,
        next_page: null
      },
      unreturned_search_keys: {
        returnScheduleEndDate: null
      },

      uninspectedTableHeaders: [
        { value: 'product_info', text: '品番1/商品名/在庫管理番号' },
        { value: 'local_stock_item', text: '現地在庫品', align: 'center' },
        { value: 'editable', text: '', align: 'end' },
      ],
      /**
       * @typedef uninspected_items { Array }
       * @property stock_no { String }
       */
      uninspected_items: [],
      uninspected_pagination: {
        current_page: 1,
        total_pages: 0,
        total_count: 0,
        next_page: null
      },
      unsettledTableHeaders: [
        { value: 'order_date', text: '受注日', align: 'center' },
        { value: 'order_no', text: '注文番号' },
        { value: 'customer_name', text: '会員名' },
        { value: 'local_stock_item', text: '現地在庫品', align: 'center' },
        { value: 'count_order', text: '受注件数', align: 'right' },
        { value: 'count_ship', text: '発送済件数', align: 'right' },
        { value: 'editable', text: '', align: 'end' },
      ],
      unsettled_orders: [],
      unsettled_pagination: {
        current_page: 1,
        total_pages: 0,
        total_count: 0,
        next_page: null
      },

    }
  },

  created() {
    this.searchAnnouncements()
    this.searchSystemAnnouncements()
    this.searchProductQuestions()
    this.searchTickets()
    this.searchUnshippedItems()
    this.searchUnreturnedItems()
    this.searchUninspectedItems()
    this.searchUnsettledOrders()
  },
  methods: {
    ...mapActions('backHome', {
      getHome: 'index'
    }),
    ...mapActions('backReadAnnouncement', {
      createRead: 'create'
    }),
    ...mapActions('backHomeSystemAnnouncement', {
      fetchSystemAnnouncements: 'fetchAll'
    }),
    ...mapActions('backHomeTicket', {
      fetchTickets: 'fetchAll'
    }),
    ...mapActions('backHomeUnshippedItem', {
      fetchUnshippedItems: 'fetchAll'
    }),
    ...mapActions('backHomeUnreturnedItem', {
      fetchUnreturnedItems: 'fetchAll'
    }),
    ...mapActions('backHomeUninspectedItem', {
      fetchUninspectedItems: 'fetchAll'
    }),
    ...mapActions('backHomeUnsettledOrder', {
      fetchUnsettledOrder: 'fetchAll'
    }),
    ...mapActions('backHomeProductQuestion', {
      fetchProductQuestions: 'fetchAll'
    }),
    changeChipColor (ticket) {
      if (ticket.status === 'opened') {
        return 'red'
      } else if (ticket.status === 'in_process') {
        return 'yellow'
      } else if (ticket.status === 'closed') {
        return ''
      }
    },
    // HOME 表示
    searchAnnouncements () {
      this.getHome().then((content) => {
        this.announces = content.announces
      })
    },
    /**
     *
     */
    searchSystemAnnouncements () {
      this.fetchSystemAnnouncements().then((content) => {
        this.announcements = content.announcements
        this.announcement_pagination = content.pagination
      })
    },
    searchTickets () {
      this.fetchTickets().then((content) => {
        this.tickets = content.tickets
        this.ticket_pagination = content.pagination
      })
    },
    searchProductQuestions () {
      this.fetchProductQuestions().then((content) => {
        this.product_questions = content.product_questions
        this.product_question_pagination = content.pagination
      })
    },
    searchUnshippedItems () {
      this.fetchUnshippedItems().then((content) => {
        this.unshipped_items = content.order_stocks
        this.unshipped_pagination = content.pagination
      })
    },
    searchUnreturnedItems () {
      this.fetchUnreturnedItems().then((content) => {
        this.unreturned_items = content.order_stocks
        this.unreturned_pagination = content.pagination
        this.unreturned_search_keys = content.search_keys
      })
    },
    searchUninspectedItems () {
      this.fetchUninspectedItems().then((content) => {
        this.uninspected_items = content.product_stocks
        this.uninspected_pagination = content.pagination
      })
    },
    searchUnsettledOrders () {
      this.fetchUnsettledOrder().then((content) => {
        this.unsettled_orders = content.orders
        this.unsettled_pagination = content.pagination
      })
    },
    // お知らせ通知非表示イベントで既読フラグを立てる
    announce_close(announcements_id) {
      this.createRead({announcement_id: announcements_id})
      // this.update_read_flag({ id: announcements_id}).then(() => {})
    },
    showAnnouncementsLinkButtonClickEvent () {
      this.$router.push({name: 'byAnnouncementIndex', query: {}, params: { isClearCondition: 'true' }})
    },
    showAnnouncementButtonClickEvent(announcement) {
      this.$router.push({name: 'byAnnouncementShow', params: {id: announcement.id}})
    },
    /**
     * 問い合わせ一覧表示ボタン クリックイベント
     */
    showTicketsButtonClickEvent () {
      this.$router.push({name: 'byInquiryIndex', query: { status_opened: 'true', status_in_process: 'true' }, params: { isClearCondition: 'true' }})
    },
    /**
     * 問い合わせ詳細表示ボタン クリックイベント
     * @param ticket
     */
    editTicketButtonClickEvent (ticket) {
      this.$router.push({name: 'byInquiryEdit', params: {id: ticket.id}})
    },
    /**
     * 商品への問い合わせ一覧表示ボタン クリックイベント
     */
    showProductQuestionsButtonClickEvent () {
      this.$router.push({name: 'byQaIndex', query: { status: 'unanswered' }, params: { isClearCondition: 'true' }})
    },
    /**
     * 商品への問い合わせ詳細表示ボタン クリックイベント
     */
    showProductQuestionButtonClickEvent (product_question) {
      this.$router.push({name: 'byQaEditIndex', params: {id: product_question.id}})
    },
    /**
     * 未発送商品一覧表示ボタン クリックイベント
     */
    showUnshippedOrdersButtonClickEvent () {
      this.$router.push({name: 'byOrderIndex', query: {only_unshipped: 'true'}, params: { isClearCondition: 'true' }})
    },
    /**
     * 未発送商品詳細ボタン クリックイベント
     * @param order_stock
     */
    showUnshippedOrderButtonClickEvent (order_stock) {
      this.$router.push({name: 'byOrderShow', params: {id: order_stock.order_id}})
    },
    /**
     * 未返却一覧表示ボタン クリックイベント
     */
    showUnreturnedItemsButtonClickEvent () {
      this.$router.push({name: 'bySendBackIndex', query: this.unreturned_search_keys, params: { isClearCondition: 'true' }})
    },
    /**
     * 未返却商品詳細ボタン クリックイベント
     * @param order_stock
     */
    showUnreturnedItemButtonClickEvent (order_stock) {
      this.$router.push({name: 'bySendBackIndex', query: { order_no: order_stock.order_no, part_number: order_stock.part_number_1, product_stock_no: order_stock.product_stock_no }, params: { isClearCondition: 'true' }})
    },
    /**
     * 未検品一覧表示ボタン クリックイベント
     */
    showUninspectedItemsButtonClickEvent () {
      this.$router.push({name: 'byProductIndex', query: { only_uninspected: 'true' }, params: { isClearCondition: 'true' }})
    },
    /**
     * 未検品商品詳細ボタン クリックイベント
     * @param product_stock
     */
    showUninspectedItemButtonClickEvent (product_stock) {
      // 商品登録詳細の在庫タブへ
      this.$router.push({name: 'byProductEdit', params: {id: product_stock.product_id, tab: 'tab-stocks'}})
    },
    showUnsettledOrdersButtonClickEvent () {
      this.$router.push({name: 'byOrderIndex', query: {status: 'ordered'}, params: { isClearCondition: 'true' }})
    },
    showUnsettledOrderButtonClickEvent (order) {
      this.$router.push({name: 'byOrderShow', params: {id: order.id}})
    },
    showAnnouncementLinkClickEvent (announce_id) {
      this.$router.push({name: 'byAnnouncementShow', params: { id: announce_id }})
    },
  },
}
</script>

<style scoped lang="scss">
</style>
