<template>
  <div>
    <h3 class="d-flex justify-space-between align-center">
      <v-btn
        icon
        color="grey"
        @click="toBack"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>
        注文確認
        <div class="lang_lbl">{{$t('view.注文確認')}}</div>
      </span>
      <span style="min-width: 36px" />
    </h3>
    <alert-message class="mb-0"/>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-row dense>
          <v-col
            v-for="item in content.carts"
            :key="item.id"
            cols="12"
            sm="6"
          >
            <order-product :item="item" :is-edit="false" />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          tile
          class="mt-2 grey lighten-3"
          v-if="delivery"
        >
          <v-card-title v-if="!contentCart.is_local_stock_item">
            お届け先
            <span class="lang_lbl ms-1">{{$t('view.お届け先')}}</span>
          </v-card-title>
          <v-card-text v-if="!contentCart.is_local_stock_item">
            <div>{{ delivery.name }}</div>
            <div>〒 {{ delivery.zip_code }}</div>
            <div>{{ addPrefectureName() }}</div>
            <div>TEL {{ delivery.phone }}</div>
            <div v-if="delivery.desired_time_value !== null">配送希望時間: {{ delivery.desired_time_value }}</div>
          </v-card-text>
          <v-card-title>
            お支払い方法
            <span class="lang_lbl ms-1">{{$t('view.お支払い方法')}}</span>
          </v-card-title>
          <v-card-text>
            {{ paymentTypeLabel() }}
          </v-card-text>
          <v-card-actions v-if="!contentCart.is_local_stock_item">
            <v-btn
              block
              outlined
              color="primary"
              @click="toBackPayment"
            >
              <v-icon class="mr-2">
                mdi-pencil-outline
              </v-icon>変更
              <span class="lang_lbl">{{$t('view.変更')}}</span>
            </v-btn>
          </v-card-actions>
          <v-divider v-if="!contentCart.is_local_stock_item" />
          <v-card-title>
            注文金額
            <span class="lang_lbl ms-1">{{$t('view.注文金額')}}</span>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>
                    小計
                    <div class="lang_lbl">{{$t('view.小計')}}</div>
                  </th>
                  <td align="end">{{ content.total_price | yen_format }}</td>
                </tr>
                <tr>
                  <th>
                    送料
                    <div class="lang_lbl">{{$t('view.送料')}}</div>
                  </th>
                  <td align="end" v-if="!contentCart.is_local_stock_item">{{ content.postage | yen_format }}</td>
                  <td align="end" v-else>0</td>

                </tr>
                <tr>
                  <th>
                    合計
                    <div class="lang_lbl">{{$t('view.合計')}}</div>
                  </th>
                  <td align="end" v-if="!contentCart.is_local_stock_item">{{ content.total_amount | yen_format }}</td>
                  <td align="end" v-else>{{ content.total_amount-content.postage | yen_format }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              dark
              color="red"
              @click="toNext"
              class="lang_btn"
            >

              <v-icon class="mr-2">
                mdi-credit-card-outline
              </v-icon>注文を確定する
              <div class="lang_lbl">{{$t('view.注文を確定する')}}</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import prefectureList from './../../../../mixins/PrefectureList'
  import OrderProduct from './_OrderProduct'
  import alertMessage from '../../../../components/AlertMessage'

  export default {
    name: 'OrderConfirm',
    components: {
      OrderProduct,
      alertMessage,
    },
    mixins: [
      prefectureList,
    ],
    data () {
      return {
      }
    },
    computed: {
      ...mapState('frontCart', {
        content: 'content',
        tmp_customer_id: 'tmp_customer_id'
      }),
      ...mapState('frontPayment', {
        delivery: 'delivery'
      }),
      ...mapState('frontCartInside', {
        contentCart: 'content',
      }),
    },
    mounted() {
    },
    created() {
      const params = {
        prefecture_code: this.delivery.prefecture_code,
      }
      this.fetchCarts({ params: params })
      this.fetchCartInside()
    },
    methods: {
      ...mapActions('frontCart', {
        fetchCarts: 'fetchCarts',
        fetchCartCount: 'fetchCount'
      }),
      ...mapActions('frontCustomer', {
        editCustomer: 'edit'
      }),
      ...mapActions('frontOrder', {
        createOrder: 'create'
      }),
      ...mapActions('frontCartInside', {
        fetchCartInside: 'fetch'
      }),
      toNext () {
        // 注文登録
        const params = this.buildParams()
        this.createOrder({ params }).then(() => {
          this.fetchCartCount()
          this.$emit('toNextStep')
        })
      },
      toBack () {
        this.$emit('toBackStep')
      },
      toBackPayment () {
        this.$emit('toBackPayment')
      },
      addPrefectureName () {
        return `${this.prefectureNameBy(this.delivery.prefecture_code)}${this.delivery.address}`
      },
      paymentTypeLabel () {
        if (this.delivery.payment_type === 0) {
          return 'カード決済'
        } else if (this.delivery.payment_type === 1) {
          return '銀行振り込み'
        } else if (this.delivery.payment_type === 2) {
          return '代引き'
        }
      },
      buildParams () {
        return {
          card_token: this.delivery.card_token,
          payment_type: this.delivery.payment_type,
          delivery_name: this.delivery.name,
          delivery_zip_code: this.delivery.zip_code,
          delivery_prefecture_code: this.delivery.prefecture_code,
          delivery_address: this.delivery.address,
          delivery_phone: this.delivery.phone,
          delivery_remarks: this.delivery.remarks,
          delivery_desired_time_value: this.delivery.desired_time_value,
          utm_source: this.content.utm_source,
          customer_id: this.tmp_customer_id,
          free_shipping_flag: this.content.free_shipping_flag,
        }
      },
    },
  }
</script>

<style scoped lang="scss">
</style>
