/**
 * マイページ
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/frontend-types";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/my_pages`

const myPage = {
  namespaced: true,
  state: {
    content: {}
  },
  getters: {},
  actions: {
    fetch ({ commit }, {post_name}) {
      const api_path = `${API_PATH}/${post_name}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          const content = data.content
          commit(types.FRONT_MY_PAGE, { content })
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.FRONT_MY_PAGE] (state, { content }) {
      state.content = content
    },
  },
}

export default myPage
