/**
 * 商品
 */
import Vue from "vue";
import * as types from "../../mutation-types/admin-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.ADMIN_ENDPOINT}/companies`

const company = {
    namespaced: true,
    state: {
        product: null,
        pagination: {
            current_page: 1,
            total_count: 0
        },
        query: {
            company_code: '',
            company_name: '',
            contract_type: 'all'
        },
    },
    getters: {
        hasCompanies: ( state ) => {
            return (state.pagination.total_count > 0)
        },
    },
    actions: {
        fetchAll({ dispatch, commit, state }, {company_code, company_name, contract_type, page}) {
            const queries = {
                company_code: company_code,
                company_name: company_name,
                contract_type: contract_type
            }
            dispatch('fetchQuery', queries)
            const params = {
                company_code: state.query.company_code,
                company_name: state.query.company_name,
                contract_type: state.query.contract_type,
                page: page
            }

            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    commit(types.ADMIN_COMPANY, { content })
                    resolve(data.content)
                })
            })
        },
        fetchQuery ({ commit, state}, {company_code, company_name, contract_type }) {
            const companyCode = (company_code !== undefined)? company_code : state.query.company_code
            const companyName = (company_name !== undefined)? company_name : state.query.company_name
            const contractType = (contract_type !== undefined) ? contract_type : state.query.contract_type
            const params = {
                company_code: companyCode,
                company_name: companyName,
                contract_type: contractType
            }
            commit(types.ADMIN_COMPANY_QUERY, { params })
        },
        fetch ({ commit, state }, {id}) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
        new ({ _commit }) {
            const api_path = `${API_PATH}/new`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        edit({ _commit, state }, { id }) {
            const api_path = `${API_PATH}/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        create( { commit, state }, { params } ) {
            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(API_PATH, params).then( data => {
                    let content = data.content
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    resolve(content)
                } )
            })
        },
        update( {commit, state}, { id, params } ) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params).then( data => {
                    let content = data.content
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(content)
                } )
            })
        },
        destroy( { commit, state }, { id } ) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
        sendOpeningMail( { commit, state }, { id } ) {
            const api_path = `${API_PATH}/${id}/send_mail`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.get(api_path).then( data => {
                    let content = data.content
                    commit('snackbar/showInfo', { message: '送信しました' }, { root: true })
                    resolve(content)
                } )
            })
        }
    },
    mutations: {
        [types.ADMIN_COMPANY] (state, { content }) {
            state.pagination = content.pagination
        },
        [types.ADMIN_COMPANY_QUERY] (state, { params }) {
            state.query.company_code = params.company_code
            state.query.company_name = params.company_name
            state.query.contract_type = params.contract_type
        },
    },
}

export default company