<!-- バックヤード > ニュース一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          ニュース登録
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              @click="addButtonClickEvent"
            >
              ニュース追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="keyword"
                        dense
                        hide-details="auto"
                        label="キーワード"
                        outlined
                        placeholder=" "
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-checkbox
                        v-model="enableDisplayDate"
                        class="pa-0 ma-0"
                        hide-details="auto"
                        label="表示日で絞り込む"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-menu
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        min-width="290px"
                        offset-y
                        :disabled="enableDisplayDate === false"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="displayDate"
                            :disabled="enableDisplayDate === false"
                            clearable
                            dense
                            hide-details="auto"
                            hint="時点表示されているもの"
                            label="表示日"
                            outlined
                            readonly
                            placeholder=" "
                            persistent-hint
                            v-on="on"
                            @click:clear="displayDate = null"
                          />
                        </template>
                        <v-date-picker
                          ref="picker"
                          v-model="displayDate"
                          @input="dateMenu = false"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="resetResult"
                  >
                    検索
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left"
                  >
                    タイトル
                  </th>
                  <th
                    class="text-left"
                  >
                    詳細
                  </th>
                  <th
                    class="text-left"
                  >
                    ニュース日
                  </th>
                  <th
                    class="text-left"
                  >
                    表示期間
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="news_detail in news"
                  :key="news_detail.id"
                >
                  <td>{{ news_detail.title }}</td>
                  <td>{{ news_detail.description | truncate(30) }}</td>
                  <td>{{ news_detail.notice_date | date_format }}</td>
                  <td>{{ news_detail.display_period_start_date | from_to_date_format(news_detail.display_period_end_date) }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="editButtonClickEvent(news_detail)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-pagination
      v-if="hasNews"
      v-model="page"
      :length="pagination.total_pages"
      :total-visible="totalVisible"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      circle
    />
  </div>
</template>

<script>

  import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    components: {

    },
    mixins: [
    ],
    data () {
      return {
        keyword: null,
        enableDisplayDate: false,
        displayDate: new Date().toISOString().substr(0, 10),
        dateMenu: false,
        page: 1,
        totalVisible: 7,
        news: [],
      }
    },
    computed: {
      ...mapState('backNews', {
        pagination: 'pagination',
        searchQuery: 'query',
      }),
      ...mapGetters('backNews', [
        'hasNews'
      ]),
    },
    watch: {
      keyword(newVal) {
        this.fetchQuery({ keyword: newVal })
      },
      enableDisplayDate(newVal) {
        this.fetchQuery({ enable_display_date: newVal })
      },
      displayDate(newVal) {
        this.fetchQuery({ display_date: newVal })
      }
    },
    created() {
      this.keyword = this.searchQuery.keyword
      this.enableDisplayDate = this.searchQuery.enable_display_date
      this.displayDate = this.searchQuery.display_date
      this.page = this.pagination.current_page
      this.searchNews()
    },
    methods: {
      ...mapActions('backNews', {
        fetchNews: 'fetchAll',
        fetchQuery: 'fetchQuery'
      }),
      resetResult () {
        this.page = 1
        this.searchNews()
      },
      searchNews() {
        const params = {
          keyword: this.inputtedKeyword,
          enable_display_date: this.enableDisplayDate,
          display_date: this.displayDate,
          page: this.page
        }
        this.fetchNews(params).then((content) => {
          this.news = content.news
        })

      },
      addButtonClickEvent () {
        this.$router.push({name: 'byNewsNew'})
      },
      editButtonClickEvent (news_detail) {
        this.$router.push({name: 'byNewsEdit', params: {id: news_detail.id}})
      }
    }
  }
</script>

<style lang="scss">

</style>
