<!-- バックヤード > システム設定 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          システム設定
        </h2>
        <v-tabs
          centered
          fixed-tabs
          icons-and-text
          background-color="blue lighten-4"
        >
          <v-tabs-slider />

          <v-tab href="#tab-basic">
            基本情報
            <v-icon>mdi-diamond-stone</v-icon>
          </v-tab>
          <v-tab-item value="tab-basic">
            <v-card flat>
              <v-card-text>
                <basic-form
                  v-if="configuration"
                  :configuration="configuration"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab href="#tab-versatile">
            会員汎用項目設定
            <v-icon>mdi-account-box-multiple-outline</v-icon>
          </v-tab>
          <v-tab-item value="tab-versatile">
            <customer-item />
          </v-tab-item>

          <v-tab href="#tab-return">
            返送先情報
            <v-icon>mdi-rotate-left-variant</v-icon>
          </v-tab>
          <v-tab-item value="tab-return">
            <return-address
              v-if="configuration"
              :configuration="configuration"
            />
          </v-tab-item>

          <v-tab href="#tab-ipmask">
            IP制限設定
            <v-icon>mdi-ip-network</v-icon>
          </v-tab>
          <v-tab-item value="tab-ipmask">
            <allow-ipmask />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AllowIpmask from './tabs/_AllowIpmask'
import BasicForm from './tabs/_Basic'
import CustomerItem from './tabs/_CustomerItem'
import ReturnAddress from './tabs/_ReturnAddress'
import { mapActions } from 'vuex'

export default {
  components: {
    AllowIpmask,
    BasicForm,
    CustomerItem,
    ReturnAddress,
  },
  mixins: [],
  data () {
    return {
      configuration: null
    }
  },
  created () {
    this.fetchConfiguration().then(( content ) => {
      this.configuration = content
    })
  },
  methods: {
    ...mapActions('backConfiguration', {
      fetchConfiguration: 'fetch',
    })
  },
}
</script>

<style lang="scss">

</style>
