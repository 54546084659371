<template>
  <div>
    <alert-message />
    <validation-observer v-slot="{ invalid }">
      <v-form v-if="customer">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|email|max:254"
                  name="メールアドレス"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="customer.email"
                    label="メールアドレス"
                    autocomplete="off"
                    placeholder=" "
                    counter="254"
                    :error-messages="errors"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:254"
                  name="ログインID"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="customer.login_id"
                    label="ログインID"
                    placeholder=" "
                    counter="254"
                    autocomplete="off"
                    :error-messages="errors"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row v-if="!customer.id">
              <v-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  vid="password"
                  name="パスワード"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="customer.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :error-messages="errors"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="new-password"
                    label="パスワード"
                    placeholder=" "
                    required
                    @click:append="showPassword = !showPassword"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|confirmed:password"
                  name="パスワード"
                >
                  <v-text-field
                    v-model="customer.password_confirmation"
                    type="password"
                    label="パスワード（確認用）"
                    autocomplete="new-password"
                    placeholder=" "
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:30"
                  name="氏名"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="customer.name"
                    label="氏名"
                    counter="30"
                    placeholder=" "
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:30"
                  name="フリガナ"
                  class=""
                >
                  <v-text-field
                    v-model="customer.name_kana"
                    label="フリガナ"
                    placeholder=" "
                    counter="30"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:20"
                  name="公開名"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="customer.bundle_name"
                    :error-messages="errors"
                    counter="20"
                    hint="商品レビューに表示されます"
                    label="公開名"
                    placeholder=" "
                    persistent-hint
                    required
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="生年月日"
                    >
                      <v-text-field
                        v-model="customer.birthday"
                        v-bind="attrs"
                        :error-messages="errors"
                        label="生年月日"
                        readonly
                        placeholder=" "
                        required
                        v-on="on"
                      />
                    </validation-provider>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="customer.birthday"
                    :max="date"
                    :day-format="date => new Date(date).getDate()"
                    min="1920-01-01"
                    @change="saveBirthday"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="customer.customer_items">
              <v-col
                v-for="item in customer.customer_items"
                :key="item.customer_item_id"
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="(item.required)? 'required|max:50' : 'max:50'"
                  :name="item.title"
                  :class="(item.required)? 'requiredMark' : ''"
                >
                  <v-text-field
                    v-model="item.value"
                    :label="item.title"
                    placeholder=" "
                    counter="50"
                    :error-messages="errors"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col
                cols="6"
                sm="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:7"
                  name="郵便番号"
                >
                  <v-text-field
                    v-model="customer.zip_code"
                    :error-messages="errors"
                    counter="7"
                    hint="ハイフン(-)無しで入力してください"
                    label="郵便番号"
                    persistent-hint
                    placeholder=" "
                    required
                  />
                </validation-provider>
              </v-col>
              <v-col cols="3">
                <zip-code-search
                  :zip-code="customer.zip_code"
                  @zipAddress="setZipAddress"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="都道府県"
                >
                  <v-select
                    v-model="customer.prefecture_code"
                    :error-messages="errors"
                    :items="prefList"
                    item-text="text"
                    item-value="value"
                    label="都道府県"
                    placeholder=" "
                    required
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:100"
                  name="市区町村・番地"
                >
                  <v-text-field
                    v-model="customer.address"
                    :error-messages="errors"
                    counter="100"
                    label="市区町村・番地"
                    placeholder=" "
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="numeric|max:11"
                  name="電話番号(自宅 または 携帯電話)"
                >
                  <v-text-field
                    v-model="customer.phone"
                    :error-messages="errors"
                    counter="11"
                    hint="ハイフン(-)無しで入力してください"
                    label="電話番号(自宅 または 携帯電話)"
                    persistent-hint
                    placeholder=" "
                    required
                  />
                </validation-provider>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
              >
                <validation-provider
                    v-slot="{ errors }"
                    rules="max:25"
                    name="海外の方の連絡先電話番号"
                >
                  <p :class="telClassName" style="font-size: 12px;" >
                    海外の方の連絡先電話番号
                  </p>
                  <vue-tel-input
                      v-model="customer.international_phone"
                      v-bind="vueTelProps"
                      name="海外の方の連絡先電話番号"
                      :error-messages="errors"
                  />
                  <v-input :error-messages="errors" v-show="errors[0] != null"/>

                </validation-provider>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col
                  cols="12"
                  sm="6"
              >
                <v-card-subtitle class="pl-0 pb-0 caption">
                  性別
                </v-card-subtitle>
                <v-radio-group
                    v-model="customer.gender"
                    row
                    class="mt-0"
                >
                  <v-radio
                      v-for="(item, index) in customer.gender_types"
                      :key="`gender_${index}`"
                      :label="item.text"
                      :value="item.value"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
          <h4
            v-if="!customer.id"
            class="text-center"
          >
            ご登録いただいたメールアドレスに認証メールが届きます。 認証メールに記載されたURLへアクセスすることで認証が完了しログインが可能となります。
          </h4>
          <v-card-actions>
            <v-row dense>
              <v-col
                cols="12"
                align="center"
              >
                <v-btn
                  color="primary"
                  :block="$vuetify.breakpoint.mdAndDown"
                  :disabled="invalid"
                  @click="submit"
                >
                  <v-icon class="mr-2">
                    mdi-account-edit-outline
                  </v-icon>登録
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import alertMessage from '../../../../components/AlertMessage'
import prefectureList from './../../../../mixins/PrefectureList'
import zipCodeSearch from '../../../../components/field/ZipCodeSearch'
import { mapActions } from 'vuex'
export default {
  name: 'MembershipProfileForm',
  components: {
    alertMessage,
    zipCodeSearch,
  },
  mixins: [
    prefectureList,
  ],
  props: {},
  data () {
    return {
      showPassword: false,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      customer: {},
      telClassName: "mt-n5 ma-0",

      // 以下、国際電話用
      vueTelProps: {
        mode: "international",
        defaultCountry: "",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        name : "international_phone",
        id : "international_phone",
        placeholder: "海外の方の連絡先電話番号",
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ["US", "CN"],   // ドロップダウンで上位に持ってくる国(利用頻度の高そうな国..とりあえずアメリカと中国)
        onlyCountries: [],
        // ignoredCountries: ["US", "CN"],
        autocomplete: "off",
        maxLen: 25,
        wrapperClasses: "country-phone-input",
        inputClasses: "",
        inputOptions: {
          showDialCode: true   // true:選択されている国のコードをinputにセットする
        },
        dropdownOptions: {
          disabledDialCode: false
        },
        // counter:"11",
        // hint:"ハイフン(-)無しで入力してください",
        // required:required
      },

    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  created() {
    if (this.$route.params.id) {
      this.editCustomer({id: this.$route.params.id}).then((content) => {
        this.customer = content
      })
    } else {
      this.newCustomer().then((content) => {
        this.customer = content
      })
    }
  },
  methods: {
    ...mapActions('backCustomer', {
      newCustomer: 'new',
      editCustomer: 'edit',
      createCustomer: 'create',
      updateCustomer: 'update',
    }),
    saveBirthday (date) {
      this.$refs.menu.save(date)
    },
    submit() {
      if (this.customer.id) {
        this.updateCustomer({ id: this.customer.id, params: this.customer }).then((content) => {
          // this.customer_items = content.customer_items
          this.$router.push({name: 'byMemberIndex'})
        })
      } else {
        this.createCustomer({ params: this.customer }).then((content) => {
          // this.customer_items = content.customer_items
          this.$router.push({name: 'byMemberIndex'})
        })
      }
    },
    setZipAddress (content) {
      this.customer.prefecture_code = `${content.pref_code}`
      this.customer.address = `${content.locality}${content.street}`
    }
  },
}
</script>
