<!-- バックヤード > SHOPサイト画面設定 > 新着商品ボタンー-->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-title>
                新着商品一覧ボタン
              </v-card-title>
              <v-card-subtitle>
                <v-radio-group
                  v-model="themeOption.use_new_arrivals_button"
                  row
                  dense
                  hide-details="auto"
                  class="pa-0 ma-0"
                >
                  <v-radio
                    label="表示しない"
                    :value="false"
                  />
                  <v-radio
                    label="表示する"
                    :value="true"
                  />
                </v-radio-group>
              </v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:20"
                      name="タイトル"
                    >
                      <v-text-field
                        v-model="themeOption.new_arrivals_button_title"
                        :disabled="!themeOption.use_new_arrivals_button"
                        :error-messages="errors"
                        counter="20"
                        dense
                        hide-details="auto"
                        label="タイトル"
                        outlined
                        placeholder=" "
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    バナー
                  </v-card-title>
                  <v-card-subtitle>
                    <v-radio-group
                      v-model="themeOption.use_new_arrivals_button_banner"
                      row
                      dense
                      hide-details="auto"
                      class="pa-0 ma-0"
                    >
                      <v-radio
                        label="表示しない"
                        :value="false"
                      />
                      <v-radio
                        label="表示する"
                        :value="true"
                      />
                    </v-radio-group>
                  </v-card-subtitle>
                  <v-card-text v-show="themeOption.use_new_arrivals_button_banner">
                    <banner-form
                      v-if="banner"
                      ref="banner"
                      :banner="banner"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    背景画像コンテンツ
                  </v-card-title>
                  <v-card-subtitle>
                    <v-radio-group
                      v-model="themeOption.use_new_arrivals_button_background_image_contents"
                      row
                      dense
                      hide-details="auto"
                      class="pa-0 ma-0"
                    >
                      <v-radio
                        label="表示しない"
                        :value="false"
                      />
                      <v-radio
                        label="表示する"
                        :value="true"
                      />
                    </v-radio-group>
                  </v-card-subtitle>
                  <v-card-text v-show="themeOption.use_new_arrivals_button_background_image_contents">
                    <background-image-contents-form
                      v-if="background_image_content"
                      ref="background_image_content"
                      :background-image-content="background_image_content"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  :block="$vuetify.breakpoint.mdAndDown"
                  :disabled="invalid"
                  @click="submit"
                >
                  保存
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import BannerForm from '../shared/_Banner'
import BackgroundImageContentsForm from '../shared/_BackgroundImageContents'
import AlertMessage from '../../../../components/AlertMessage'
import { mapActions } from 'vuex'

export default {
  name: 'NewArrivalsButtonForm',
  components: {
    AlertMessage,
    BannerForm,
    BackgroundImageContentsForm,
  },
  props: {
    themeOption: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      banner: null,
      background_image_content: null,
    }
  },
  created () {
    this.findBanner({option_type: 'new_arrivals_button'}).then((content) => {
      this.banner = content
    })
    this.findBackgroundImageContent({option_type: 'new_arrivals_button'}).then((content) => {
      this.background_image_content = content
    })
  },
  methods: {
    ...mapActions('backThemeOptionBanner', {
      findBanner: 'edit',
    }),
    ...mapActions('backThemeOptionBackgroundImageContent', {
      findBackgroundImageContent: 'edit',
    }),
    ...mapActions('backThemeOptionOther', {
      updateOther: 'update',
    }),
    buildParams () {
      let params = new FormData()
      params.append('use_new_arrivals_button', this.themeOption.use_new_arrivals_button)
      params.append('new_arrivals_button_title', this.themeOption.new_arrivals_button_title)
      params.append('use_new_arrivals_button_banner', this.themeOption.use_new_arrivals_button_banner)
      params.append('use_new_arrivals_button_background_image_contents', this.themeOption.use_new_arrivals_button_background_image_contents)

      params.append('banners_attributes[0]id', this.banner.id)
      params.append('banners_attributes[0]option_type', this.banner.option_type)
      params.append('banners_attributes[0]banner_type', this.banner.banner_type)
      params.append('banners_attributes[0]text', this.banner.text)
      params.append('banners_attributes[0]youtube_url', this.banner.youtube_url)
      params.append('banners_attributes[0]column_type', this.banner.column_type)

      params.append('background_image_contents_attributes[0]id', this.background_image_content.id)
      params.append('background_image_contents_attributes[0]title', this.background_image_content.title==null? '':this.background_image_content.title)
      params.append('background_image_contents_attributes[0]title_color', this.background_image_content.title_color==null? '#FFFFFF':this.background_image_content.title_color)
      params.append('background_image_contents_attributes[0]sub_title', this.background_image_content.sub_title==null? '':this.background_image_content.sub_title)
      params.append('background_image_contents_attributes[0]sub_title_color', this.background_image_content.sub_title_color==null? '#FFFFFF':this.background_image_content.sub_title_color)
      if (this.background_image_content.file!=null) params.append('background_image_contents_attributes[0]file', this.background_image_content.file)
      params.append('background_image_contents_attributes[0]remove_file', this.background_image_content.remove_file)
      params.append('background_image_contents_attributes[0]background_color', this.background_image_content.background_color==null? '#000000':this.background_image_content.background_color)
      params.append('background_image_contents_attributes[0]use_button', this.background_image_content.use_button)
      params.append('background_image_contents_attributes[0]button_title', this.background_image_content.button_title==null? '':this.background_image_content.button_title)
      params.append('background_image_contents_attributes[0]button_title_color', this.background_image_content.button_title_color==null? '#FFFFFF':this.background_image_content.button_title_color)
      params.append('background_image_contents_attributes[0]transition_type', this.background_image_content.transition_type)
      params.append('background_image_contents_attributes[0]product_id', this.background_image_content.product_id)
      params.append('background_image_contents_attributes[0]category_id', this.background_image_content.category_id)
      params.append('background_image_contents_attributes[0]tag_id', this.background_image_content.tag_id)
      params.append('background_image_contents_attributes[0]my_page_id', this.background_image_content.my_page_id)
      params.append('background_image_contents_attributes[0]transition_url', this.background_image_content.transition_url==null? '':this.background_image_content.transition_url)
      params.append('background_image_contents_attributes[0]left_padding', this.background_image_content.left_padding)
      params.append('background_image_contents_attributes[0]text_align', this.background_image_content.text_align)
      params = this.$refs.banner.buildParams(params)
      return params
    },
    submit() {
      const params = this.buildParams()
      /**
       * @typedef content {Object}
       * @property banners { Array }
       * @property background_image_contents {Array}
       */
      this.updateOther({params: params}).then((content) => {
        this.themeOption.use_new_arrivals_button = content.use_new_arrivals_button
        this.themeOption.new_arrivals_button_title = content.new_arrivals_button_title
        this.themeOption.use_new_arrivals_button_banner = content.use_new_arrivals_button_banner
        this.themeOption.use_new_arrivals_button_background_image_contents = content.use_new_arrivals_button_background_image_contents
        this.banner = content.banners
        this.background_image_content = content.background_image_contents
        this.$refs.banner.setParams(content.banners.banner_links)
      })
    },

  },
}
</script>

<style lang="scss">
</style>
