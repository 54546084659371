/**
 * システムお知らせ
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/announcements`

const announcement = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {
      keyword: '',
    },
  },

  getters: {
    hasAnnounces: ( state ) => {
      return (state.pagination.total_count > 0)
    },
  },

  actions: {

    // 一覧取得
    fetchAll({ dispatch, commit, state }, {keyword, page}) {
      // 検索条件コミット
      dispatch('fetchQuery', { keyword: keyword})

      const params = {
        keyword: state.query.keyword,
        page: page
      }

      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_SYSTEM_ANNOUNCES, { content })
          resolve(content)
        })
      })
    },

    fetchQuery({ commit, state}, {keyword}) {
      const keywordText = (keyword !== undefined)? keyword : state.query.keyword
      const params = { keyword: keywordText }
      commit(types.BACK_SYSTEM_ANNOUNCES_QUERY, { params })
    },

    show ({ commit, state }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.BACK_SYSTEM_ANNOUNCES] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_SYSTEM_ANNOUNCES_QUERY] (state, { params }) {
      state.query.keyword = params.keyword
    }
  },
}

export default announcement