<!-- バックヤード > カテゴリーform -->
<template>
  <div v-if="deliveryDesiredTime">
    <alert-message />

    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:30"
                        name="配送希望時間値"
                        class="requiredMark"
                    >
                      <v-text-field
                          v-model="desired_time_value"
                          :error-messages="errors"
                          counter="30"
                          dense
                          hide-details="auto"
                          label="配送希望時間値"
                          outlined
                          placeholder=" "
                          required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <validation-provider
                        v-slot="{ errors }"
                        class="requiredMark"
                        name="表示順"
                        rules="required|numeric|min_value:0|max_value:2147483647"
                    >
                      <v-text-field
                          v-model="position"
                          :error-messages="errors"
                          dense
                          hide-details="auto"
                          label="表示順"
                          outlined
                          placeholder=" "
                          required
                          reverse
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

              </v-card-text>
              <v-card-actions>
                <v-btn
                    v-if="isEdit"
                    color="error"
                    @click="onDelete"
                >
                  削除
                </v-btn>
                <v-spacer />
                <v-btn @click="onCancel">
                  キャンセル
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="invalid"
                    @click="submit"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <confirm
      v-if="isEdit"
      ref="confirm"
    />
  </div>
</template>

<script>

import {mapActions} from 'vuex'
import AlertMessage from '../../../components/AlertMessage'
import Confirm from '../../../components/Confirm'

export default {
  name: 'ByDeliveryDesiredTimeForm',
  components: {
    AlertMessage,
    Confirm,
  },
  mixins: [],
  props: {
    deliveryDesiredTime: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      id: this.deliveryDesiredTime.id,
      desired_time_value: this.deliveryDesiredTime.desired_time_value,
      position: this.deliveryDesiredTime.position,
    }
  },
  created () {
  },
  methods: {
    ...mapActions('backDeliveryDesiredTime', {
      create: 'create',
      update: 'update',
      destroy: 'destroy'
    }),
    buildParams () {
      let params = {
        desired_time_value: this.desired_time_value,
        position: this.position,
      }
      return params
    },
    onCancel: function () {
      this.$router.push({name: 'byDeliveryDesiredTimeIndex'})
    },
    onDelete: function () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroy({id: this.id }).then(() =>{
            this.$router.push({name: 'byDeliveryDesiredTimeIndex'})
          })
        }
      })
    },
    submit() {
      const params = this.buildParams()
      if (this.isEdit) {
        this.update({ id: this.id, params: params }).then(() =>{
          this.$router.push({name: 'byDeliveryDesiredTimeIndex'})
        })
      } else {
        this.create({ params: params }).then(() =>{
          this.$router.push({name: 'byDeliveryDesiredTimeIndex'})
        })
      }
    },
  }
}
</script>

<style lang="scss">
</style>
