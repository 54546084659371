<template>
  <div>
    <alert-message />
    <v-form v-if="company">
      <validation-observer v-slot="{ invalid }">
        <v-row>
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:30"
              name="コード"
              class="requiredMark"
            >
              <v-text-field
                v-model="company.code"
                label="コード"
                placeholder="半角英数字"
                counter="30"
                autocomplete="none"
                required
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules=""
              name="モード"
              class=""
            >
              <v-label>モード</v-label>
              <v-radio-group
                v-model="company.mode"
                row
                dense
                hide-details="auto"
                class="pa-0 ma-0"
                :error-messages="errors"
              >
                <template
                  v-for="(item, index) in company.mode_s"
                >
                  <v-radio
                    :key="item.value"
                    :value="item.value"
                    :label="item.text"
                    :disabled="index==1"
                  />
                </template>
              </v-radio-group>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules=""
              name="契約種別"
              class=""
            >
              <v-label>契約種別</v-label>
              <v-radio-group
                v-model="company.contract_type"
                row
                dense
                hide-details="auto"
                class="pa-0 ma-0"
                :error-messages="errors"
              >
                <template
                  v-for="(item, index) in company.contract_types"
                >
                  <v-radio
                    :key="item.value"
                    :value="item.value"
                    :label="item.text"
                  />
                </template>
              </v-radio-group>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:255"
              name="会社名"
              class="requiredMark"
            >
              <v-text-field
                id="name"
                v-model="company.name"
                label="会社名"
                placeholder=""
                required
                counter="255"
                autocomplete="none"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="max:255"
              name="会社名カナ"
            >
              <v-text-field
                id="kana_name"
                v-model="company.kana_name"
                label="会社名カナ"
                placeholder=""
                counter="255"
                autocomplete="none"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:25"
              name="担当者"
              class="requiredMark"
            >
              <v-text-field
                v-model="company.contractor"
                label="担当者"
                placeholder=""
                required
                counter="25"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-menu
              v-model="contract_start_date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="契約開始日"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="company.contract_start_date"
                    clearable
                    readonly
                    persistent-hint
                    label="契約開始日"
                    required
                    v-bind="attrs"
                    :error-messages="errors"
                    v-on="on"
                  />
                </validation-provider>
              </template>
              <v-date-picker
                v-model="company.contract_start_date"
                no-title
                @input="contract_start_date_menu = false"
              />
            </v-menu>
          </v-col>

          <v-col cols="3">
            <v-menu
              v-model="contract_end_date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  rules=""
                  name="契約終了日"
                  class=""
                >
                  <v-text-field
                    v-model="company.contract_end_date"
                    label="契約終了日"
                    clearable
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    :error-messages="errors"
                    v-on="on"
                  />
                </validation-provider>
              </template>
              <v-date-picker
                v-model="company.contract_end_date"
                no-title
                @input="contract_end_date_menu = false"
              />
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <validation-provider
              v-slot="{ errors }"
              :rules="{required: true, regex:/^[0-9]{3}[-][0-9]{4}$/}"
              name="郵便番号"
              class="requiredMark"
            >
              <v-text-field
                v-model="company.zip_code"
                label="郵便番号"
                placeholder="111-2222"
                required
                hint="半角数字とハイフン(-)で入力して下さい"
                counter="8"
                :error-messages="errors"
                autocomplete="none"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="都道府県"
              class="requiredMark"
            >
              <v-select
                v-model="company.prefecture_code"
                :items="company.prefectures"
                item-text="name"
                item-value="code"
                label="都道府県"
                required
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="9">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:100"
              name="住所"
              class="requiredMark"
            >
              <v-text-field
                v-model="company.address"
                label="住所"
                required
                counter="100"
                autocomplete="none"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              :rules="{ regex: /^[0-9\-]+$/, max:13, required: true}"
              name="電話番号"
              class="requiredMark"
            >
              <v-text-field
                v-model="company.phone"
                label="電話番号"
                placeholder="半角数字"
                hint="ハイフン(-)有りで入力してください"
                autocomplete="none"
                required
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              :rules="{ regex: /^[0-9\-]+$/, max:13}"
              name="FAX番号"
              class=""
            >
              <v-text-field
                v-model="company.fax"
                label="FAX番号"
                placeholder="半角数字"
                hint="ハイフン(-)有りで入力してください"
                autocomplete="none"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required|email|max:255"
              name="メールアドレス"
              class="requiredMark"
            >
              <v-text-field
                v-model="company.email"
                label="メールアドレス"
                counter="255"
                autocomplete="none"
                required
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules=""
              name="URL"
            >
              <v-text-field
                v-model="company.url"
                label="URL"
                autocomplete="none"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules=""
              name="フロントエンド認証"
              class=""
            >
              <v-checkbox
                v-model="company.use_frontend_auth"
                label="フロントエンド認証"
                hide-details="auto"
                class="pa-0 ma-0"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
                v-slot="{ errors }"
                rules=""
                name="会員登録方法"
                class=""
            >
              <v-label>会員登録方法</v-label>
              <v-radio-group
                  v-model="company.customer_reg_type"
                  row
                  dense
                  hide-details="auto"
                  class="pa-0 ma-0"
                  :error-messages="errors"
              >
                <template
                    v-for="(item, index) in company.customer_reg_types"
                >
                  <v-radio
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                  />
                </template>
              </v-radio-group>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
                v-slot="{ errors }"
                rules=""
                name="定期課金使用"
                class=""
            >
              <v-checkbox
                  v-model="company.use_subscription"
                  label="定期課金使用"
                  hide-details="auto"
                  class="pa-0 ma-0"
                  :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
                v-slot="{ errors }"
                rules=""
                name="販売使用"
                class=""
            >
              <v-checkbox
                  v-model="company.use_sale"
                  label="販売使用"
                  hide-details="auto"
                  class="pa-0 ma-0"
                  :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row >
          <v-col cols="6">
            <v-label>決済方法</v-label>
            <v-checkbox
                v-model="company.use_credit_card"
                label="クレジットカード使用有無"
                hide-details="auto"
                class="pa-0 ma-0"
                :error-messages="errors"
            />
            <v-checkbox
                v-model="company.use_bank_transfer"
                label="銀行振込使用有無"
                hide-details="auto"
                class="pa-0 ma-0"
                :error-messages="errors"
            />
            <v-checkbox
                v-model="company.use_cash_on_delivery"
                label="代引き使用有無"
                hide-details="auto"
                class="pa-0 ma-0"
                :error-messages="errors"
            />
          </v-col>
        </v-row>


        <h2>振込先</h2>
        <hr>
        <v-row>
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="銀行"
              class="requiredMark"
            >
              <v-select
                v-model="company.bank_id"
                :items="banks"
                item-text="text"
                item-value="id"
                label="銀行"
                hint="選択してください"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="支店"
              class="requiredMark"
            >
              <v-select
                v-model="company.branch_id"
                :items="branches"
                item-text="text"
                item-value="id"
                label="支店"
                hint="選択してください"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules=""
              name="預金種別"
              class=""
            >
              <v-radio-group
                v-model="company.bank_account_type"
                label="預金種別"
                row
                dense
                hide-details="auto"
                class="pa-0 ma-0"
                :error-messages="errors"
              >
                <template
                  v-for="(item, index) in company.bank_account_types"
                >
                  <v-radio
                    :key="item.value"
                    :value="item.value"
                    :label="item.text"
                  />
                </template>
              </v-radio-group>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="口座番号"
              class="requiredMark"
            >
              <v-text-field
                v-model="company.bank_account_number"
                label="口座番号"
                autocomplete="none"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="口座名義"
              class="requiredMark"
            >
              <v-text-field
                v-model="company.bank_account_holder_name"
                label="口座名義"
                autocomplete="none"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <h2>決済代行サービス</h2>
        <hr>
        <v-row>
          <v-col cols="6">
            <v-datetime-picker
              v-model="company.settlement_opening_at"
              label="決済開通日時"
              time-format="HH:mm"
            >
              <template slot="actions" slot-scope="{ parent }">
                <v-btn color="lighten-1" @click.native="parent.clearHandler">クリア</v-btn>
                <v-btn color="primary" @click="parent.okHandler">反映</v-btn>
              </template>
              <template slot="dateIcon">
                <v-icon>mdi-calendar-blank</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
          <v-col cols="4">
            <validation-provider
              v-slot="{ errors }"
              rules="required|min_value:0|max_value:95"
              name="プラットフォーム利用料率"
              class="requiredMark"
            >
              <v-text-field
                v-model="company.platform_usage_rate"
                label="プラットフォーム利用料率"
                placeholder=""
                type="number"
                step="0.1"
                max="95"
                autocomplete="none"
                :error-messages="errors"
              >
                <template slot="append">
                  ％
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <div>
          <h2>
            店舗&nbsp;
            <small v-if="company.id === null">初期登録時に作成する店舗情報を入力します</small>
          </h2>
          <hr>
        </div>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:30"
              name="店舗名"
              class="requiredMark"
            >
              <v-text-field
                v-model="company.shop_name"
                label="店舗名"
                counter="30"
                placeholder=""
                autocomplete="none"
                required
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <!-- |regex:^(?![-.])[a-zA-Z0-9.-]+(?<![-.])$  -->
            <validation-provider
              v-slot="{ errors }"
              rules="required|min:3|max:22|excluded:www,ftp,mail,pop,smtp,admin,ssl,sftp"
              name="サブドメイン"
              class="requiredMark"
            >
              <v-text-field
                id="store_url"
                v-model="company.sub_domain"
                label="サブドメイン"
                required
                counter="22"
                placeholder="3から22文字"
                :error-messages="errors"
              >
                <template slot="prepend">
                  https://
                </template>
                <template slot="append">
                  .bizrent.biz
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="独自ドメイン"
            >
              <v-text-field
                v-model="company.original_domain"
                label="独自ドメイン"
                placeholder="https://xxxxxx.com"
                counter
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="float-right">
              <v-btn
                @click="cancelButtonClickEvent"
              >
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="registerButtonClickEvent"
              >
                保存
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </validation-observer>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ContractPlan from '../../components/ContractPlan'
import alertMessage from '../../../components/AlertMessage'

export default {
  components: {
    alertMessage
  },
  mixins: [ ContractPlan ],
  props: {
    company: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      bank_page: 1,
      banks: [],
      branches: [],
      contract_start_date_menu: false,
      contract_end_date_menu: false,
    }
  },
  computed: {},
  watch: {
    'company.bank_id': function(val) {
      this.searchBranches()
    }
  },
  mounted() {},
  created () {
    this.fetchBanks({ page: this.bank_page }).then((content) => {
      this.banks = content.banks
    })

    if (this.company.bank_id) {
      this.searchBranches()
    }

  },
  methods: {
    ...mapActions('adminBank', {
      fetchBanks: 'fetchAll'
    }),
    ...mapActions('adminBankBranch', {
      fetchBranches: 'fetchAll'
    }),
    ...mapActions('adminCompany', {
      createCompany: 'create',
      updateCompany: 'update'
    }),
    searchBranches () {
      this.fetchBranches({bank_id: this.company.bank_id}).then((content) => {
        this.branches = content.branches
      })
    },
    cancelButtonClickEvent () {
      if (this.isEdit) {
        this.$router.push( { name: 'adCompanyShow', params: { id: this.company.id } } )
      } else {
        this.$router.push( { name: 'adCompanyIndex' } )
      }
    },
    registerButtonClickEvent () {
      if (this.isEdit) {
        this.updateCompany({id: this.company.id, params: this.company}).then(() => {
          this.$router.push( { name: 'adCompanyIndex' } )
        })
      } else {
        this.createCompany({params: this.company}).then(() => {
          this.$router.push( { name: 'adCompanyIndex' } )
        })
      }
    }

  },
}
</script>

<style lang="scss">
.v-picker__title { border-radius: 0 !important; }
</style>
