<!-- バックヤード > SHOPサイト画面設定 > スライダー設定-->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    スライドショー
                  </v-card-title>
                  <v-card-subtitle>
                    <v-radio-group
                      v-model="themeOption.use_slider"
                      row
                      dense
                      hide-details="auto"
                      class="pa-0 ma-0"
                    >
                      <v-radio
                        label="表示しない"
                        :value="false"
                      />
                      <v-radio
                        label="表示する"
                        :value="true"
                      />
                    </v-radio-group>
                  </v-card-subtitle>
                  <v-card-text
                    v-show="themeOption.use_slider"
                  >
                    <v-divider />
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-select
                          v-model="themeOption.slider_interval"
                          :items="slider_interval_selects"
                          dense
                          label="インターバル"
                          hide-details="auto"
                          outlined
                          placeholder=" "
                          suffix="秒"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <p class="my-1">
                          アニメーション
                        </p>
                        <v-radio-group
                          v-model="themeOption.slider_animation"
                          row
                          dense
                          hide-details="auto"
                          class="pa-0 ma-0"
                        >
                          <v-radio
                            label="スライド"
                            value="slide"
                          />
                          <v-radio
                            label="フェード"
                            value="fade"
                          />
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="text-right">
                        <v-btn
                          color="info"
                          small
                          @click="slide_add"
                        >
                          スライド追加
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-tabs
                          v-model="slide_tabs"
                          background-color="blue-grey lighten-4"
                        >
                          <v-tab
                            v-for="i in slides.length"
                            :key="i"
                          >
                            スライド{{ i }}
                          </v-tab>
                          <v-tab-item
                            v-for="i in slides.length"
                            :key="'tab-' + i"
                            :eager="true"
                          >
                            <slide-list-form
                              v-if="slides[i-1]"
                              :slide="slides[i-1]"
                            />
                            <v-row>
                              <v-col class="text-right">
                                <v-btn
                                  small
                                  @click="slide_delete(i-1)"
                                >
                                  スライド削除
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-tab-item>
                        </v-tabs>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    バナー
                  </v-card-title>
                  <v-card-subtitle>
                    <v-radio-group
                      v-model="themeOption.use_slider_banner"
                      row
                      dense
                      hide-details="auto"
                      class="pa-0 ma-0"
                    >
                      <v-radio
                        label="表示しない"
                        :value="false"
                      />
                      <v-radio
                        label="表示する"
                        :value="true"
                      />
                    </v-radio-group>
                  </v-card-subtitle>
                  <v-card-text
                    v-show="themeOption.use_slider_banner"
                  >
                    <v-divider />
                    <banner-form
                      v-if="banner"
                      ref="banner"
                      :banner="banner"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    背景画像コンテンツ
                  </v-card-title>
                  <v-card-subtitle>
                    <v-radio-group
                      v-model="themeOption.use_slider_background_image_contents"
                      row
                      dense
                      hide-details="auto"
                      class="pa-0 ma-0"
                    >
                      <v-radio
                        label="表示しない"
                        :value="false"
                      />
                      <v-radio
                        label="表示する"
                        :value="true"
                      />
                    </v-radio-group>
                  </v-card-subtitle>
                  <v-card-text
                    v-show="themeOption.use_slider_background_image_contents"
                  >
                    <v-divider />
                    <background-image-contents-form
                      v-if="background_image_content"
                      ref="background_image_content"
                      :background-image-content="background_image_content"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  :block="$vuetify.breakpoint.mdAndDown"
                  :disabled="invalid"
                  @click="submit"
                >
                  保存
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

  import SlideListForm from "../shared/_SlideList";
  import BannerForm from "../shared/_Banner";
  import {mapActions} from "vuex";
  import alertMessage from "../../../../components/AlertMessage";
  import BackgroundImageContentsForm from "../shared/_BackgroundImageContents";

  export default {
    name: 'SliderForm',
    components: {
      SlideListForm,
      BannerForm,
      BackgroundImageContentsForm,
      alertMessage
    },
    props: {
      themeOption: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        slides: [],
        tmp_slides: [],
        banner: null,
        background_image_content: null,
        slide_tabs: null,
        slider_interval_selects: [1,2,3,4,5,10,15,20,25,30,45,60],
      }
    },
    created () {
      this.findSlides().then((content) => {
        this.slides = content.slides
      })
      this.findBanner({option_type: 'slider'}).then((content) => {
        this.banner = content
      })
      this.findBackgroundImageContent({option_type: 'slider'}).then((content) => {
        this.background_image_content = content
      })
    },
    methods: {
      ...mapActions('backThemeOptionSlide', {
        findSlides: 'index',
        updateSlider: 'update'
      }),
      ...mapActions('backThemeOptionBanner', {
        findBanner: 'edit'
      }),
      ...mapActions('backThemeOptionBackgroundImageContent', {
        findBackgroundImageContent: 'edit',
      }),
      slide_add: function () {
        let obj = {
          id: "",
          file: null,
          title: "",
          sub_title: "",
          font_color: "#000",
          use_button: false,
          button_title: "詳細を見る",
          transition_type: "no_transition",
          product_id: null,
          category_id: null,
          tag_id: null,
          my_page_id: null,
          transition_url: "",
        }
        this.slides.push(obj)
      },
      slide_delete: function (index) {
        if (this.slides[index].id != "") {
          this.$set(this.slides[index], '_destroy', true)
          this.tmp_slides.push(this.slides[index])
        }
        this.slides.splice(index, 1)
      },
      buildParams () {
        let params = new FormData()
        params.append('use_slider', this.themeOption.use_slider)
        params.append('slider_interval', this.themeOption.slider_interval)
        params.append('slider_animation', this.themeOption.slider_animation)
        params.append('use_slider_banner', this.themeOption.use_slider_banner)
        params.append('use_slider_background_image_contents', this.themeOption.use_slider_background_image_contents)

        for (let i = 0; i < this.slides.length ; i++) {
          this.tmp_slides.push(this.slides[i])
        }

        for (let i = 0; i < this.tmp_slides.length ; i++) {
          params.append('slides_attributes[' + i +  ']id', this.tmp_slides[i].id)
          if (this.tmp_slides[i].file) {
            params.append('slides_attributes[' + i + ']file', this.tmp_slides[i].file)
          }
          params.append('slides_attributes[' + i + ']title', this.tmp_slides[i].title)
          params.append('slides_attributes[' + i + ']sub_title', this.tmp_slides[i].sub_title)
          params.append('slides_attributes[' + i + ']font_color', this.tmp_slides[i].font_color)
          params.append('slides_attributes[' + i + ']use_button', this.tmp_slides[i].use_button)
          params.append('slides_attributes[' + i + ']button_title', this.tmp_slides[i].button_title)
          params.append('slides_attributes[' + i + ']transition_type', this.tmp_slides[i].transition_type)
          params.append('slides_attributes[' + i + ']product_id', this.convertToEmptyString(this.tmp_slides[i].product_id))
          params.append('slides_attributes[' + i + ']category_id', this.convertToEmptyString(this.tmp_slides[i].category_id))
          params.append('slides_attributes[' + i + ']tag_id', this.convertToEmptyString(this.tmp_slides[i].tag_id))
          params.append('slides_attributes[' + i + ']my_page_id', this.convertToEmptyString(this.tmp_slides[i].my_page_id))
          params.append('slides_attributes[' + i + ']transition_url', this.tmp_slides[i].transition_url)
          if (this.tmp_slides[i]._destroy) {
            params.append('slides_attributes[' + i + ']_destroy', this.tmp_slides[i]._destroy)
          }
        }
        params.append('banners_attributes[0]id', this.banner.id)
        params.append('banners_attributes[0]option_type', this.banner.option_type)
        params.append('banners_attributes[0]banner_type', this.banner.banner_type)
        params.append('banners_attributes[0]text', this.banner.text)
        params.append('banners_attributes[0]youtube_url', this.banner.youtube_url)
        params.append('banners_attributes[0]column_type', this.banner.column_type)

        params.append('background_image_contents_attributes[0]id', this.background_image_content.id)
        params.append('background_image_contents_attributes[0]title', this.background_image_content.title==null? '':this.background_image_content.title)
        params.append('background_image_contents_attributes[0]title_color', this.background_image_content.title_color==null? '#FFFFFF':this.background_image_content.title_color)
        params.append('background_image_contents_attributes[0]sub_title', this.background_image_content.sub_title==null? '':this.background_image_content.sub_title)
        params.append('background_image_contents_attributes[0]sub_title_color', this.background_image_content.sub_title_color==null? '#FFFFFF':this.background_image_content.sub_title_color)
        if (this.background_image_content.file!=null) params.append('background_image_contents_attributes[0]file', this.background_image_content.file)
        params.append('background_image_contents_attributes[0]remove_file', this.background_image_content.remove_file)
        params.append('background_image_contents_attributes[0]background_color', this.background_image_content.background_color==null? '#000000':this.background_image_content.background_color)
        params.append('background_image_contents_attributes[0]use_button', this.background_image_content.use_button)
        params.append('background_image_contents_attributes[0]button_title', this.background_image_content.button_title==null? '':this.background_image_content.button_title)
        params.append('background_image_contents_attributes[0]button_title_color', this.background_image_content.button_title_color==null? '#FFFFFF':this.background_image_content.button_title_color)
        params.append('background_image_contents_attributes[0]transition_type', this.background_image_content.transition_type)
        params.append('background_image_contents_attributes[0]product_id', this.background_image_content.product_id)
        params.append('background_image_contents_attributes[0]category_id', this.background_image_content.category_id)
        params.append('background_image_contents_attributes[0]tag_id', this.background_image_content.tag_id)
        params.append('background_image_contents_attributes[0]my_page_id', this.background_image_content.my_page_id)
        params.append('background_image_contents_attributes[0]transition_url', this.background_image_content.transition_url==null? '':this.background_image_content.transition_url)
        params.append('background_image_contents_attributes[0]left_padding', this.background_image_content.left_padding)
        params.append('background_image_contents_attributes[0]text_align', this.background_image_content.text_align)
        params = this.$refs.banner.buildParams(params)
        return params
      },
      submit() {
        const params = this.buildParams()
        this.updateSlider({params: params}).then((content) => {
          this.themeOption.use_slider = content.use_slider
          this.themeOption.slider_interval = content.slider_interval
          this.themeOption.slider_animation = content.slider_animation
          this.themeOption.use_slider_banner = content.use_slider_banner
          this.slides = content.slides
          this.themeOption.use_slider_background_image_contents = content.use_slider_background_image_contents
          this.banner = content.banners
          this.background_image_content = content.background_image_contents
          this.$refs.banner.setParams(content.banners.banner_links)
        })
        this.tmp_slides.splice(-this.tmp_slides.length)
      },
      convertToEmptyString(value) {
        // int項目にnull文字列を送信すると0で登録されるので空文字に変換する
        return (value === null || value === 'null') ? '' : value
      }

    },
  }
</script>

<style lang="scss">
</style>
