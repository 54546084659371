<template>
  <div v-if="optionGroup">
    <alert-message />
    <validation-observer v-slot="{ invalid }">
      <v-form>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:30"
                  name="オプショングループ名"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="optionGroup.name"
                    :error-messages="errors"
                    counter="30"
                    dense
                    hide-details="auto"
                    label="オプショングループ名"
                    outlined
                    placeholder=" "
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="税設定"
                  class="requiredMark"
                >
                  <v-select
                    v-model="optionGroup.tax_type"
                    :items="optionGroup.tax_types"
                    :error-messages="errors"
                    dense
                    hide-details="auto"
                    label="税設定"
                    outlined
                    placeholder=" "
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules=""
                  name="必須有無"
                >
                  <v-checkbox
                    v-model="optionGroup.required"
                    :error-messages="errors"
                    class="pa-0 ma-0"
                    hide-details="auto"
                    label="選択を必須とする"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules=""
                  name="説明ボタンを表示する"
                  class=""
                >
                  <v-checkbox
                    v-model="optionGroup.enable_description_button"
                    :error-messages="errors"
                    class="pa-0 ma-0"
                    hide-details="auto"
                    label="説明ボタンを表示する"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row v-if="optionGroup.enable_description_button">
              <v-col
                cols="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:10"
                  name="説明ボタンのラベル"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="optionGroup.description_button_caption"
                    counter="10"
                    dense
                    hide-details="auto"
                    label="説明ボタンのラベル"
                    outlined
                    placeholder=" "
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules=""
                  name="説明内容"
                >
                  <v-textarea
                    v-model="optionGroup.description"
                    :error-messages="errors"
                    auto-grow
                    counter="512"
                    dense
                    hide-details="auto"
                    label="説明内容"
                    outlined
                    placeholder=" "
                    row-height="15"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="isEdit"
              color="error"
              @click="optionGroupDeleteButtonClickEvent"
            >
              削除
            </v-btn>
            <v-spacer />
            <v-btn
              @click="optionGroupCancelButtonClickEvent"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="primary"
              :disabled="invalid"
              @click="optionGroupRegisterButtonClickEvent"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
    <confirm ref="confirm" />
  </div>
</template>

<script>

import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import { mapActions } from 'vuex'

export default {
  name: "BasicForm",
  components: {
    AlertMessage,
    Confirm,
  },
  props: {
    /**
     * @typedef optionGroup { Object }
     * @property name { String }
     * @property tax_type { String }
     * @property required { Boolean }
     * @property enable_description_button { Boolean }
     * @property description_button_caption { String }
     * @property description { String }
     * @property tax_types { Array }
     */
    optionGroup: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    ...mapActions('backOptionGroup', {
      createOptionGroup: 'create',
      updateOptionGroup: 'update',
      deleteOptionGroup: 'destroy'
    }),
    optionGroupRegisterButtonClickEvent () {
      if (this.isEdit) {
        this.updateOptionGroup({ id: this.optionGroup.id, params: this.optionGroup })
      } else {
        this.createOptionGroup({ params: this.optionGroup }).then(() => {
          this.$router.push({name: 'byOptionGroupIndex'})
        })
      }
    },
    optionGroupCancelButtonClickEvent () {
      this.$router.push({name: 'byOptionGroupIndex'})
    },
    optionGroupDeleteButtonClickEvent () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.deleteOptionGroup({id: this.optionGroup.id }).then(() =>{
            this.$router.push({name: 'byOptionGroupIndex'})
          })
        }
      })
    },
  }

}
</script>

<style scoped>

</style>