<template>
  <div>
    <h2 class="text-center">
      <p>ありがとうございます</p>
      <p>注文を承りました</p>
    </h2>
    <v-row>
      <v-col
        cols="12"
        align="center"
        class="subtitle-1"
      >
        登録されているメールアドレスにご注文内容が記載されたメールをお送りいたします。
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <v-icon
          x-large
          color="primary"
        >
          mdi-truck-delivery-outline
        </v-icon>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <v-btn
          outlined
          color="primary"
          :block="$vuetify.breakpoint.mdAndDown"
          @click="moveToHome"
        >
          <v-icon>mdi-home-outline</v-icon>ホームに戻る
          <div class="lang_lbl">{{$t('view.ホームに戻る')}}</div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import CartStepperMixin from '../../../../../mixins/frontend/cart-stepper-mixin'

  export default {
    name: 'ExtendOrderComplete',
    mixins: [
      CartStepperMixin
    ],
    data () {
      return {
      }
    },
    methods: {},
  }
</script>

<style scoped lang="scss">
</style>