/**
 * 商品
 */
import Vue from "vue";
import * as types from "../../mutation-types/frontend-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/products`

const product = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0,
    },
    query: {
      category_ids: [],
      tag_ids: [],
      keyword_text: '',
      campaign_ids: [],
      only_new_arrival: false,
      limit: 20,
    },
    display_condition: {
      campaign: false,
      new_arrivals: false,
      category: false,
      tag: false
    },
  },
  getters: {
    isProducts: ( state ) => {
      return (state.pagination.total_count > 0)
    },
    isDisplayCondition: ( state ) => {
      return (state.display_condition.campaign || state.display_condition.new_arrivals || state.display_condition.category || state.display_condition.tag)
    },
  },
  actions: {
    findProducts ({ _commit },{ params }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          resolve(data.content)
        })
      })
    },
    fetchProducts({ dispatch, commit, state }, {category_ids, tag_ids, keyword_text, campaign_ids, only_new_arrival, page, limit}) {
      dispatch('fetchQuery', {category_ids: category_ids, tag_ids: tag_ids, keyword_text: keyword_text, campaign_ids: campaign_ids, only_new_arrival: only_new_arrival, limit: limit})
      const params = {
        keyword_text: state.query.keyword_text,
        category_ids: state.query.category_ids,
        tag_ids: state.query.tag_ids,
        campaign_ids: state.query.campaign_ids,
        only_new_arrival: state.query.only_new_arrival,
        page: page,
        limit: limit,
      }

      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.FRONT_PRODUCT, { content })
          resolve(data.content)
        })
      })
    },
    fetchQuery({ commit, state}, {category_ids, tag_ids, keyword_text, campaign_ids, only_new_arrival, limit}) {
      const categoryIds = (category_ids)? category_ids : state.query.category_ids
      const tagIds = (tag_ids)? tag_ids : state.query.tag_ids
      const keywordText = keyword_text
      const campaignIds = (campaign_ids)? campaign_ids : state.query.campaign_ids
      const onlyNewArrival = only_new_arrival
      const limitValue = (limit) ? limit : state.query.limit
      const params = { keyword_text: keywordText, category_ids: categoryIds, tag_ids: tagIds, campaign_ids: campaignIds, only_new_arrival: onlyNewArrival, limit: limitValue}
      commit(types.FRONT_PRODUCT_QUERY, { params })
    },
    resetQuery({ commit }) {
      const params = { keyword_text: '', category_ids: [], tag_ids: [], campaign_ids: [], only_new_arrival: false, limit: 20}
      commit(types.FRONT_PRODUCT_QUERY, { params })
    },
    find ({ _commit }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    findRelations ({ commit }, {id}) {
      const api_path = `${API_PATH}/${id}/product_relations`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    findRecommends ({ commit }, {id}) {
      const api_path = `${API_PATH}/${id}/recommends`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    findComponents ({ commit }, {id}) {
      const api_path = `${API_PATH}/${id}/product_components`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    }
  },
  mutations: {
    [types.FRONT_PRODUCT] (state, { content }) {
      state.pagination = content.pagination
      state.display_condition = content.display_condition
    },
    [types.FRONT_PRODUCT_QUERY] (state, { params }) {
      state.query.category_ids = params.category_ids
      state.query.tag_ids = params.tag_ids
      state.query.keyword_text = params.keyword_text
      state.query.campaign_ids = params.campaign_ids
      state.query.only_new_arrival = params.only_new_arrival
      state.query.limit = params.limit
    },
  },
}

export default product
