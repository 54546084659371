import moment from 'moment'
import ContractPlan from "../admin/components/ContractPlan";
import PrefectureList from "./PrefectureList";

export default {
    mixins: [ ContractPlan, PrefectureList ],
    data () {
      return {
          rules: {
              required: value => !!value || '入力して下さい。',
          },
      }
    },
    methods: {
        /**
         * yyyy/MM/dd形式の文字列に変換して返します.
         * @param date
         * @returns {string}
         * @constructor
         */
        DateFormatter: function (date) {
            if (date == null)
                return ''
            return moment(date).format('YYYY/MM/DD')
        },
        DateTimeFormatter: function (time) {
            if (time == null)
                return ''
            return moment(time).format('YYYY/MM/DD HH:mm')
        },
        DateTimeRangeFormatter: function (start_time, end_time) {
            return this.DateTimeFormatter(start_time) + '〜' + this.DateTimeFormatter(end_time)
        },
        ContractPlanNameBy: function (plan) {
            const contractList = this.contractPlanList.filter(word => word.value == plan);
            return contractList ? contractList[0].text : ''
        },
        PrefectureNameBy: function (code) {
            const prefecture = this.prefList.filter(word => word.value == code);
            let text = '';
            if (prefecture && prefecture[0]) {
                text = prefecture[0].text
            }
            return text
        }
    }
}