<!-- バックヤード > 商品レビュー -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          商品レビュー
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="keyword"
                  dense
                  hide-details="auto"
                  label="キーワード"
                  outlined
                  placeholder="部分一致"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="1"
              >
                <p class="mb-0">
                  投稿日
                </p>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableStartDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="start_date"
                      clearable
                      dense
                      hide-details="auto"
                      label="開始日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="start_date = null"
                    >
                      <template
                        v-if="!$vuetify.breakpoint.smAndDown"
                        v-slot:append-outer
                      >
                        &emsp;〜
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    @input="enableStartDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableEndDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="end_date"
                      clearable
                      dense
                      hide-details="auto"
                      label="終了日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="end_date = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    @input="enableEndDate = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="with_unpublished"
                  class="pa-0 ma-0"
                  hide-details="auto"
                  label="非表示を含める"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="resetProductReviews"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>投稿日</th>
                  <th />
                  <th>品番1/商品名</th>
                  <th>ニックネーム</th>
                  <th>タイトル</th>
                  <th>表示設定</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="product_review in product_reviews"
                  :key="product_review.id"
                >
                  <td>{{ product_review.created_at }}</td>
                  <td class="text-center">
                    <v-img
                      :src="product_review.image.url | load_image_url"
                      max-height="40"
                      max-width="60"
                      contain
                    />
                  </td>
                  <td>{{ product_review.part_number }}&nbsp;/&nbsp;{{ product_review.product_name }}</td>
                  <td>{{ product_review.bundle_name }}</td>
                  <td>{{ product_review.title }}</td>
                  <td>{{ product_review.published ? '表示' : '非表示' }}</td>
                  <td>
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="showProductReviewButtonClickEvent(product_review)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="hasProductReviews"
        cols="12"
      >
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'

  export default {
    name: 'ProductReviewIndex',
    components: {
    },
    mixins: [
    ],
    props: {
    },
    data () {
      return {
        page: 1,
        filterDialog: false,
        totalVisible: 7,
        enableStartDate: false,
        enableEndDate: false,
        keyword: null,
        start_date: null,
        end_date: null,
        with_unpublished: false,
        product_reviews: [],
      }
    },
    computed: {
      ...mapState('backProductReview', {
        pagination: 'pagination',
        searchQuery: 'query',
      }),
      /** @typedef hasProductReviews { Boolean } */
      ...mapGetters('backProductReview', [
        'hasProductReviews'
      ]),
    },
    watch: {
      keyword (newVal) {
        this.fetchQuery({keyword: newVal})
      },
      start_date (newVal) {
        this.fetchQuery({ start_date: newVal })
      },
      end_date (newVal) {
        this.fetchQuery({ end_date: newVal })
      },
      with_unpublished (newVal) {
        this.fetchQuery({ with_unpublished: newVal })
      }
    },
    created () {
      this.keyword = this.searchQuery.keyword
      this.start_date = this.searchQuery.start_date
      this.end_date = this.searchQuery.end_date
      this.with_unpublished = this.searchQuery.with_unpublished
      this.page = this.pagination.current_page
      this.searchProductReviews()
    },
    methods: {
      ...mapActions('backProductReview', {
        fetchProductReviews: 'fetchAll',
        fetchQuery: 'fetchQuery',
        resetQuery: 'resetQuery'
      }),
      showProductReviewButtonClickEvent(product_review) {
        this.$router.push({name: 'byProductReview', params: {id: product_review.id}})
      },
      resetProductReviews() {
        this.page = 1
        this.searchProductReviews()
      },
      changePage() {
        this.searchProductReviews()
      },
      searchProductReviews() {
        const params = {
          keyword: this.keyword,
          start_date: this.start_date,
          end_date: this.end_date,
          with_unpublished: this.with_unpublished,
          page: this.page
        }
        this.fetchProductReviews(params).then((content) => {
          this.product_reviews = content.product_reviews
        })
      }
    }
  }
</script>

<style scoped lang="scss">
</style>
