/**
 * admin用の認証
 */
import * as types from '../../mutation-types/admin-types'
import * as httpConst from '../../../constants/httpConst'
import Vue from 'vue'
import store from 'store'

const isAdminLoggedIn = () => {
  return (store.get('adminLoggedIn'))
}

const getCurrentUser = () => {
  const default_current_user = {
    name: null,
  }
  let current_user = null
  if (isAdminLoggedIn()) current_user = store.get('ad_current_user')
  return (current_user) ? current_user : default_current_user
}

const API_PATH = `${httpConst.ADMIN_BASE}/oauth`

const auth = {
    namespaced: true,
    state: {
        logged_in: isAdminLoggedIn(),
        name: getCurrentUser().name
    },
    getters: {
        avatarName: ( state ) => {
            return (state.name) ? state.name : ''
        },
    },
    actions: {
        signInAdmin ({ commit }, { params }) {
            const fixedParams = {
                grant_type: 'password',
                client_id: process.env.ADMIN_CLIENT_ID,
                client_secret: process.env.ADMIN_CLIENT_SECRET,
                scope: 'public admin'
            }
            const parameter = { ...params, ...fixedParams }
            const api_path = `${API_PATH}/token`
            return new Promise((resolve, _reject) => {
                Vue.$http.post(api_path, parameter).then( data => {
                    const user = data.user
                    commit(types.SIGN_IN_ADMIN, { user })
                    resolve(data)
                })
            })
        },
        signOutAdmin ({ commit }) {
            const api_path = `${API_PATH}/revoke`
            return new Promise((resolve) => {
                Vue.$http.post(api_path).then( data => {
                    commit(types.SIGN_OUT_ADMIN)
                    resolve(data)
                })
            })
        }
    },
    mutations: {
        [types.SIGN_IN_ADMIN] (state, { user }) {
            state.logged_in = true
            state.name = user.name
            // localStorageに保持
            store.set('adminLoggedIn', true)
            store.set('ad_current_user', user)
        },
        [types.SIGN_OUT_ADMIN] (state) {
            state.logged_in = false
            // localStorageに保持
            store.remove('adminLoggedIn')
            store.remove('ad_current_user')
        },
    },
}

export default auth
