<!-- バックヤード > 受注一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          受注管理
          <span
            class="float-right"
          >
            <v-btn
              @click="outputCSV"
            >
              CSV出力
            </v-btn>
            <v-btn
              :disabled="!hasChecked"
              color="orange darken-1"
              @click="settlementButtonClickEvent"
            >
              決済確定
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="orderNumber"
                  dense
                  hide-details="auto"
                  label="注文番号"
                  outlined
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="utmSource"
                  dense
                  hide-details="auto"
                  label="アフィリエイトコード"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <p class="mb-0">
                  受注日
                </p>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableOrderStartDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="orderStartDate"
                      clearable
                      dense
                      hide-details="auto"
                      label="開始日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="orderStartDate = null"
                    >
                      <template
                        v-if="!$vuetify.breakpoint.smAndDown"
                        v-slot:append-outer
                      >
                        &emsp;〜
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="orderStartDate"
                    @input="enableOrderStartDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableOrderEndDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="orderEndDate"
                      clearable
                      dense
                      hide-details="auto"
                      label="終了日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="orderEndDate = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="orderEndDate"
                    @input="enableOrderEndDate = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <p class="mb-0">
                  ご利用開始日
                </p>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableScheduleStartDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="scheduleStartDate"
                      clearable
                      dense
                      hide-details="auto"
                      label="開始日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="scheduleStartDate = null"
                    >
                      <template
                        v-if="!$vuetify.breakpoint.smAndDown"
                        v-slot:append-outer
                      >
                        &emsp;〜
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="scheduleStartDate"
                    @input="enableScheduleStartDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableScheduleEndDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="scheduleEndDate"
                      clearable
                      dense
                      hide-details="auto"
                      label="終了日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="scheduleEndDate = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="scheduleEndDate"
                    @input="enableScheduleEndDate = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <p class="mb-0">
                  決済/入金確認日
                </p>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableSettleStartDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="settleStartDate"
                      clearable
                      dense
                      hide-details="auto"
                      label="開始日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="settleStartDate = null"
                    >
                      <template
                        v-if="!$vuetify.breakpoint.smAndDown"
                        v-slot:append-outer
                      >
                        &emsp;〜
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="settleStartDate"
                    @input="enableSettleStartDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableSettleEndDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="settleEndDate"
                      clearable
                      dense
                      hide-details="auto"
                      label="終了日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="settleEndDate = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="settleEndDate"
                    @input="enableSettleEndDate = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <p class="mb-0">
                  受注ステータス
                </p>
              </v-col>
              <v-col
                cols="12"
                md="10"
              >
                <v-radio-group
                  v-model="status"
                  class="pa-0 ma-0"
                  dense
                  hide-details="auto"
                  row
                >
                  <v-radio
                    label="全て"
                    value="all"
                  />
                  <v-radio
                    label="受注済み"
                    value="ordered"
                  />
                  <v-radio
                    label="決済/入金済"
                    value="payment"
                  />
                  <v-radio
                    label="キャンセル"
                    value="canceled"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  cols="12"
                  md="2"
              >
                <p class="mb-0">
                  支払方法
                </p>
              </v-col>
              <v-col
                  v-if="useCreditCard"
                  md="1"
              >
                <v-checkbox
                    v-model="onlyCreditCard"
                    hide-details="auto"
                    label="クレジット"
                />
              </v-col>
              <v-col
                  v-if="useBankTransfer"
                  md="1"
              >
                <v-checkbox
                    v-model="onlyBankTransfer"
                    hide-details="auto"
                    label="現金振込"
                />
              </v-col>
              <v-col
                  v-if="useCashOnDelivery"
                  md="1"
              >
                <v-checkbox
                    v-model="onlyCashOnDelivery"
                    hide-details="auto"
                    label="代引き"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-checkbox
                  v-model="onlyUnshipped"
                  class="pa-0 ma-0"
                  hide-details="auto"
                  label="商品未発送のみ"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-checkbox
                  v-model="onlyLocalStockItem"
                  class="pa-0 ma-0"
                  hide-details="auto"
                  label="現地在庫品のみ"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="resetOrders"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    決済
                  </th>
                  <th class="text-center">
                    受注日
                  </th>
                  <th class="text-center">
                    注文番号
                  </th>
                  <th class="text-center">
                    会員名
                  </th>
                  <th class="text-center">
                    総額
                  </th>
                  <th class="text-center">
                    現地<br>在庫品
                  </th>
                  <th class="text-center">
                    ご利用開始日
                  </th>
                  <th class="text-center">
                    ご利用終了日
                  </th>
                  <th class="text-center">
                    受注<br>件数
                  </th>
                  <th class="text-center">
                    発送済<br>件数
                  </th>
                  <th class="text-center">
                    返却済<br>件数
                  </th>
                  <th class="text-center">
                    延長
                  </th>
                  <th class="text-center">
                    支払方法
                  </th>
                  <th class="text-center">
                    決済/入金確認日
                  </th>
                  <th class="text-center">
                    アフィリエイト<br>コード
                  </th>
                  <th class="text-center">
                    ステータス
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="order in orders"
                  :key="order.id"
                >
                  <td>
                    <v-checkbox
                      v-if="order.status === 'ordered' && order.settle_date === null"
                      dense
                      hide-details
                      class="pa-0 ma-0 text-center"
                      :value="order.id"
                      @change="settleCheckboxClickEvent($event, order)"
                    />
                  </td>
                  <td class="text-center">{{ order.order_at }}</td>
                  <td class="text-center">{{ order.order_no }}</td>
                  <td class="text-center">{{ order.customer_name }}</td>
                  <td class="text-right">
                    {{ order.total_payment | comma_format }} 円
                  </td>
                  <td class="text-center">
                    {{ order.local_stock_item ? '◯' : '' }}
                  </td>
                  <td class="text-center">
                    {{ order.schedule_date }}
                  </td>
                  <td class="text-center">
                    {{ order.return_schedule_date }}
                  </td>
                  <td class="text-center">
                    {{ order.count_order }}
                  </td>
                  <td class="text-center">
                    {{ order.local_stock_item ? '-' : order.count_ship }}
                  </td>
                  <td class="text-center">
                    {{ order.count_return }}
                  </td>
                  <td class="text-center">
                    {{ order.has_extend_order ? '延長' : '' }}
                  </td>
                  <td class="text-center">
                    {{ order.payment_type_name }}
                  </td>
                  <td class="text-center">
                    {{ order.settle_date }}
                  </td>
                  <td class="text-center">
                    {{ order.utm_source }}
                  </td>
                  <td class="text-center">
                    {{ order.status_name }}
                  </td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="showButtonClickEvent(order)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="hasOrders"
        cols="12"
      >
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

  import { mapActions, mapState, mapGetters } from 'vuex'

  export default {
    name: 'OrderIndex',
    components: {},
    mixins: [],
    props: {},
    data () {
      return {
        page: 1,
        filterDialog: false,
        totalVisible: 7,

        orderNumber: null,
        utmSource: null,
        orderStartDate: null,
        orderEndDate: null,
        enableOrderEndDate: false,
        enableOrderStartDate: false,

        scheduleStartDate: null,
        scheduleEndDate: null,
        enableScheduleStartDate: false,
        enableScheduleEndDate: false,

        settleStartDate: null,
        settleEndDate: null,

        enableSettleStartDate: false,
        enableSettleEndDate: false,

        paymentType: 'all',
        status: 'all',

        hasOrder: null,
        hasShipping: null,
        hasCheckout: null,
        hasExtendCheckout: null,
        hasReturn: null,
        onlyUnshipped: false,
        onlyLocalStockItem: false,
        checked_list: [],
        orders: [],

        useBankTransfer: false,       // クレジットカード使用有無
        useCreditCard: false,         // 銀行振込使用有無
        useCashOnDelivery: false,     // 代引き使用有無

        onlyCreditCard: false,
        onlyBankTransfer: false,
        onlyCashOnDelivery: false,

      }
    },
    computed: {
      ...mapState('backOrder', {
        pagination: 'pagination',
        searchQuery: 'query',
      }),
      ...mapGetters('backOrder', [
        'hasOrders'
      ]),
      hasChecked () {
        return this.checked_list.length > 0
      }
    },
    watch: {
      orderNumber (newValue) {
        this.fetchQuery({ order_number: newValue })
      },
      utmSource (newValue) {
        this.fetchQuery({ utm_source: newValue })
      },
      orderStartDate (newValue) {
        this.fetchQuery({ order_start_date: newValue })
      },
      orderEndDate (newValue) {
        this.fetchQuery({ order_end_date: newValue })
      },
      scheduleStartDate (newValue) {
        this.fetchQuery({ schedule_start_date: newValue })
      },
      scheduleEndDate (newValue) {
        this.fetchQuery({ schedule_end_date: newValue })
      },
      settleStartDate (newValue) {
        this.fetchQuery({ settle_start_date: newValue })
      },
      settleEndDate (newValue) {
        this.fetchQuery({ settle_end_date: newValue })
      },
      paymentType (newValue) {
        this.fetchQuery({ payment_type: newValue })
      },
      status (newValue) {
        this.fetchQuery({ status: newValue })
      },
      hasOrder (newValue) {
        this.fetchQuery({ has_order: newValue })
      },
      hasShipping (newValue) {
        this.fetchQuery({ has_shipping: newValue })
      },
      hasCheckout (newValue) {
        this.fetchQuery({ has_checkout: newValue })
      },
      hasExtendCheckout (newValue) {
        this.fetchQuery({ has_extend_checkout: newValue })
      },
      hasReturn (newValue) {
        this.fetchQuery({ has_return: newValue })
      },
      onlyUnshipped (newValue) {
        this.fetchQuery({only_unshipped: newValue})
      },
      onlyLocalStockItem (newValue) {
        this.fetchQuery({only_local_stock_item: newValue})
      },

    },
    created () {
      if (this.$route.params.isClearCondition) {
        this.resetQuery()
      }
      const searchOrderNumber = this.$route.query.order_no
      if (searchOrderNumber) {
        this.orderNumber = searchOrderNumber
      } else {
        this.orderNumber = this.searchQuery.order_number
      }

      const searchStatus = this.$route.query.status
      if (searchStatus) {
        this.status = searchStatus
      } else {
        this.status = this.searchQuery.status
      }
      const searchOnlyUnshipped = this.$route.query.only_unshipped
      if (searchOnlyUnshipped) {
        this.onlyUnshipped = searchOnlyUnshipped
      } else {
        this.onlyUnshipped = this.searchQuery.only_unshipped
      }
      const searchOnlyLocalStockItem = this.$route.query.only_local_stock_item
      if (searchOnlyLocalStockItem) {
        this.onlyLocalStockItem = searchOnlyLocalStockItem
      } else {
        this.onlyLocalStockItem = this.searchQuery.only_local_stock_item
      }

      this.orderStartDate = this.searchQuery.order_start_date
      this.orderEndDate = this.searchQuery.order_end_date
      this.scheduleStartDate = this.searchQuery.schedule_start_date
      this.scheduleEndDate = this.searchQuery.schedule_end_date
      this.settleStartDate = this.searchQuery.settle_start_date
      this.settleEndDate = this.searchQuery.settle_end_date
      this.paymentType = this.searchQuery.payment_type
      this.hasOrder = this.searchQuery.has_order
      this.hasShipping = this.searchQuery.has_shipping
      this.hasCheckout = this.searchQuery.has_checkout
      this.hasExtendCheckout = this.searchQuery.has_extend_checkout
      this.hasReturn = this.searchQuery.has_return
      this.onlyCreditCard = this.searchQuery.only_credit_card             // クレジットカード
      this.onlyBankTransfer = this.searchQuery.only_bank_transfer         // 銀行振込
      this.onlyCashOnDelivery = this.searchQuery.only_cash_on_delivery     // 代引き

      this.page = this.pagination.current_page

      this.searchOrders()
      this.showPayment()
    },
    methods: {
      ...mapActions('backOrder', {
        fetchOrders: 'fetchAll',
        fetchQuery: 'fetchQuery',
        settleOrders: 'create',
        resetQuery: 'resetQueries',
        fetchCSV: 'fetchCSV',
      }),
      ...mapActions('backPaymentRequest', {
        showPaymentRequest: 'fetch',
      }),
      outputCSV() {
        this.fetchCSV(this.getParam()).then((content) => {
          let orders = content.orders
          let csv = '\ufeff' + '受注日,注文番号,ご利用開始日,備考,小計,送料,支払い金額,支払方法,決済/入金確認日,延長注文番号,会員番号,会員名,住所,電話番号,メールアドレス,アフィリエイトコード,ステータス\n'
          orders.forEach(el => {
            let line = this.csvData(el['order_at']) +
                ',' + this.csvData(el['order_no']) +
                ',' + this.csvData(el['schedule_date']) +
                ',' + this.csvData(el['remarks']) +
                ',' + this.csvData(el['total_price']) +
                ',' + this.csvData(el['postage']) +
                ',' + this.csvData(el['total_payment']) +
                ',' + this.csvData(el['payment_type_name']) +
                ',' + this.csvData(el['settle_date']) +
                ',' + this.csvData(el['extend_order_no']) +
                ',' + this.csvData(el['customer_no']) +
                ',' + this.csvData(el['customer_name']) +
                ',' + this.csvData(el['customer_zip_code'] || '' + ' ' + el['customer_full_address'] || '') +
                ',' + this.csvData(el['customer_phone']) +
                ',' + this.csvData(el['customer_email']) +
                ',' + this.csvData(el['utm_source']) +
                ',' + this.csvData(el['status_name']) +
                '\n'
            csv += line
          })
          let blob = new Blob([csv], { type: 'text/csv' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)

          let today = new Date();
          let date = today.getFullYear().toString() + (today.getMonth()+1).toString() + today.getDate().toString()
                      + today.getHours().toString() + today.getMinutes().toString() + today.getSeconds().toString();
          link.download = '受注一覧_' + date + '.csv'
          link.click()
        })
      },
      csvData(value) {
        return '"' + (value!=null ? String(value).replace('"', '""').replace(/\r?\n/g, '') : "") + '"'
      },
      settlementButtonClickEvent () {
        let params = { capture_target_ids: this.checked_list }
        this.settleOrders({params: params}).then(() => {
          this.searchOrders()
        })
      },
      settleCheckboxClickEvent (event, item) {
        if (event) {
          this.checked_list.push(item.id)
        } else {
          this.checked_list = this.checked_list.filter(o => o !== item.id)
        }
      },
      showButtonClickEvent (order) {
        this.$router.push({name: 'byOrderShow', params: { id: order.id }})
      },
      resetOrders () {
        this.page = 1
        this.searchOrders()
      },
      changePage () {
        this.searchOrders()
      },
      searchOrders () {

        this.checked_list = []

        /**
         * @typedef {Object} content
         * @property {Array} orders
         * @property {string} order_at
         * @property {number} count_order
         * @property {number} count_ship
         * @property {number} count_return
         * @property {boolean} has_extend_order
         */
        this.fetchOrders(this.getParam()).then((content) => {
          this.orders = content.orders
        })
      },
      showPayment () {
        this.showPaymentRequest().then((content) => {
          /**
           * @property use_credit_card { Boolean }
           * @property use_bank_transfer { Boolean }
           * @property use_cash_on_delivery { Boolean }
           */
          this.useCreditCard = content.use_credit_card
          this.useBankTransfer = content.use_bank_transfer
          this.useCashOnDelivery = content.use_cash_on_delivery
        })
      },
      getParam() {
        return {
          order_number: this.orderNumber,
          order_start_date: this.orderStartDate,
          order_end_date: this.orderEndDate,
          schedule_start_date: this.scheduleStartDate,
          schedule_end_date: this.scheduleEndDate,
          settle_start_date: this.settleStartDate,
          settle_end_date: this.settleEndDate,
          payment_type: this.paymentType,
          status: this.status,
          has_order: this.hasOrder,
          has_shipping: this.hasShipping,
          has_checkout: this.hasCheckout,
          has_extend_checkout: this.hasExtendCheckout,
          has_return: this.hasReturn,
          only_unshipped: this.onlyUnshipped,
          only_credit_card: this.onlyCreditCard,             // クレジットカード
          only_bank_transfer: this.onlyBankTransfer,         // 銀行振込
          only_cash_on_delivery: this.onlyCashOnDelivery,     // 代引き

          page: this.page
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .text-center,.text-right,.text-left {
    padding: 3px !important;
  }
</style>
