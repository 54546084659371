// Frontend Auth
export const SIGN_IN_BACKYARD = 'SIGN_IN_BACKYARD'
export const SIGN_OUT_BACKYARD = 'SIGN_OUT_BACKYARD'

// シイステム設定
export const BACK_SYSTEM_SETTING = 'BACK_SYSTEM_SETTING'


// Backyard SHOPサイト画面設定
export const BACKYARD_FRONT_SETTING = 'FRONT_SETTING'
export const BACK_THEME_OPTION = 'THEME_OPTION'
export const BACK_SLIDE = 'BACK_SLIDE'
export const BACK_SLIDE_ADD = 'BACK_SLIDE_ADD'
export const BACK_SLIDE_DELETE = 'BACK_SLIDE_DELETE'

// Backyard キャンペーン
export const BACKYARD_CAMPAIGN = 'BACKYARD_CAMPAIGN'
export const BACKYARD_CAMPAIGN_DETAIL = 'BACKYARD_CAMPAIGN_DETAIL'
export const BACKYARD_CAMPAIGN_QUERY = 'BACKYARD_CAMPAIGN_QUERY'

export const BACKYARD_CAMPAIGN_PRODUCT = 'BACKYARD_CAMPAIGN_PRODUCT'

export const BACK_PRODUCT = 'BACK_PRODUCT'
export const BACK_PRODUCT_QUERY = 'BACK_PRODUCT_QUERY'
export const BACK_PRODUCT_DETAIL = 'BACK_PRODUCT_DETAIL'
export const BACK_PRODUCT_QUESTION = 'BACK_PRODUCT_QUESTION'
export const BACK_PRODUCT_QUESTION_QUERY = 'BACK_PRODUCT_QUESTION_QUERY'
export const BACK_PRODUCT_QUESTION_COUNT = 'BACK_PRODUCT_QUESTION_COUNT'
export const BACK_PRODUCT_REVIEW = 'BACK_PRODUCT_REVIEW'
export const BACK_PRODUCT_REVIEW_QUERY = 'BACK_PRODUCT_REVIEW_QUERY'

export const BACK_PRODUCT_DESCRIPTION = 'BACK_PRODUCT_DESCRIPTION'
export const BACK_PRODUCT_DESCRIPTION_UPDATE = 'BACK_PRODUCT_DESCRIPTION_UPDATE'


export const BACK_SUBSCRIPTION = 'BACK_SUBSCRIPTION'
export const BACK_SUBSCRIPTION_QUERY = 'BACK_SUBSCRIPTION_QUERY'

export const BACK_ORDER = 'BACK_ORDER'
export const BACK_ORDER_QUERY = 'BACK_ORDER_QUERY'
export const BACK_ORDER_DETAIL = 'BACK_ORDER_DETAIL'
export const BACK_ORDER_UNDISPATCHED_COUNT = 'BACK_ORDER_UNDISPATCHED_COUNT'

export const BACK_ORDER_STOCK_HISTORY = 'BACK_ORDER_STOCK_HISTORY'
export const BACK_ORDER_STOCK_HISTORY_QUERY = 'BACK_ORDER_STOCK_HISTORY_QUERY'

export const BACK_ORDER_SUBSCRIPTION = 'BACK_ORDER_SUBSCRIPTION'
export const BACK_ORDER_SUBSCRIPTION_QUERY = 'BACK_ORDER_SUBSCRIPTION_QUERY'

export const BACK_CATEGORY = 'BACK_CATEGORY'
export const BACK_CATEGORY_QUERY = 'BACK_CATEGORY_QUERY'

export const BACK_DELIVERY_COMPANY = 'BACK_DELIVERY_COMPANY'
export const BACK_DELIVERY_COMPANY_QUERY = 'BACK_DELIVERY_COMPANY_QUERY'

export const BACK_TAG = 'BACK_TAG'
export const BACK_TAG_QUERY = 'BACK_TAG_QUERY'

export const BACK_LOCAL_PLACE = 'BACK_LOCAL_PLACE'
export const BACK_LOCAL_PLACE_QUERY = 'BACK_LOCAL_PLACE_QUERY'

export const BACK_RENTAL_SCHEDULE = 'BACK_RENTAL_SCHEDULE'
export const BACK_RENTAL_SCHEDULE_QUERY = 'BACK_RENTAL_SCHEDULE_QUERY'

export const BACK_OPTION_GROUP = 'BACK_OPTION_GROUP'
export const BACK_OPTION_GROUP_QUERY = 'BACK_OPTION_GROUP_QUERY'

export const BACK_SEARCH_CATEGORY = 'BACK_SEARCH_CATEGORY'
export const BACK_SEARCH_TAG = 'BACK_SEARCH_TAG'
export const BACK_SEARCH_MY_PAGE = 'BACK_SEARCH_MY_PAGE'
export const BACK_SEARCH_DELIVERY_COMPANY = 'BACK_SEARCH_DELIVERY_COMPANY'
export const BACK_SEARCH_DELIVERY_COMPANY_TIME = 'BACK_SEARCH_DELIVERY_COMPANY_TIME'
export const BACK_SEARCH_LOCAL_PLACE = 'BACK_SEARCH_LOCAL_PLACE'

export const BACK_SEARCH_OPTION_GROUP = 'BACK_SEARCH_OPTION_GROUP'

export const BACK_DELIVERY_METHOD = 'BACK_DELIVERY_METHOD'
export const BACK_DELIVERY_METHOD_QUERY = 'BACK_DELIVERY_METHOD_QUERY'

export const BACK_PRODUCT_IMAGE = 'BACK_PRODUCT_IMAGE'
export const BACK_PRODUCT_IMAGE_ADD = 'BACK_PRODUCT_IMAGE_ADD'
export const BACK_PRODUCT_IMAGE_UPDATE = 'BACK_PRODUCT_IMAGE_UPDATE'
export const BACK_PRODUCT_IMAGE_DELETE = 'BACK_PRODUCT_IMAGE_DELETE'

export const BACK_PRODUCT_TAG = 'BACK_PRODUCT_TAG'
export const BACK_PRODUCT_TAG_ADD = 'BACK_PRODUCT_TAG_ADD'
export const BACK_PRODUCT_TAG_DELETE = 'BACK_PRODUCT_TAG_DELETE'

export const BACK_PRODUCT_KEYWORD = 'BACK_PRODUCT_KEYWORD'
export const BACK_PRODUCT_KEYWORD_ADD = 'BACK_PRODUCT_KEYWORD_ADD'
export const BACK_PRODUCT_KEYWORD_DELETE = 'BACK_PRODUCT_KEYWORD_DELETE'

export const BACK_PRODUCT_CATALOG = 'BACK_PRODUCT_CATALOG'

export const BACK_PRODUCT_VIDEO = 'BACK_PRODUCT_VIDEO'

export const BACK_PRODUCT_ITEM = 'BACK_PRODUCT_ITEM'

export const BACK_PRODUCT_RELATION = 'BACK_PRODUCT_RELATION'

export const BACK_PRODUCT_DOCUMENT = 'BACK_PRODUCT_DOCUMENT'

export const BACK_PRODUCT_STOCK = 'BACK_PRODUCT_STOCK'
export const BACK_PRODUCT_STOCK_ADD = 'BACK_PRODUCT_STOCK_ADD'
export const BACK_PRODUCT_STOCK_UPDATE = 'BACK_PRODUCT_STOCK_UPDATE'
export const BACK_PRODUCT_STOCK_DELETE = 'BACK_PRODUCT_STOCK_DELETE'

export const BACK_NEWS = 'BACK_NEWS'
export const BACK_NEWS_QUERY = 'BACK_NEWS_QUERY'

export const BACK_SYSTEM_ANNOUNCES = 'BACK_SYSTEM_ANNOUNCES'
export const BACK_SYSTEM_ANNOUNCES_QUERY = 'BACK_SYSTEM_ANNOUNCES_QUERY'

export const BACK_USER = 'BACK_USER'
export const BACK_USER_QUERY = 'BACK_USER_QUERY'

export const BACK_CUSTOMER_ITEM = 'BACK_CUSTOMER_ITEM'
export const BACK_CUSTOMER_ITEM_ADD = 'BACK_CUSTOMER_ITEM_ADD'
export const BACK_CUSTOMER_ITEM_UPDATE = 'BACK_CUSTOMER_ITEM_UPDATE'
export const BACK_CUSTOMER_ITEM_DELETE = 'BACK_CUSTOMER_ITEM_DELETE'

export const BACK_CUSTOMER = 'BACK_CUSTOMER'
export const BACK_CUSTOMER_QUERY = 'BACK_CUSTOMER_QUERY'

export const BACK_ALLOW_IPMASK = 'BACK_ALLOW_IPMASK'
export const BACK_ALLOW_IPMASK_ITEM_ADD = 'BACK_ALLOW_IPMASK_ITEM_ADD'
export const BACK_ALLOW_IPMASK_ITEM_UPDATE = 'BACK_ALLOW_IPMASK_ITEM_UPDATE'
export const BACK_ALLOW_IPMASK_ITEM_DELETE = 'BACK_ALLOW_IPMASK_ITEM_DELETE'

export const BACK_SEND_BACK = 'BACK_SEND_BACK'
export const BACK_SEND_BACK_QUERY = 'BACK_SEND_BACK_QUERY'

export const BACK_COMMERCIAL_LAW = 'BACK_COMMERCIAL_LAW'
export const BACK_COMMERCIAL_LAW_ADD = 'BACK_COMMERCIAL_LAW_ADD'
export const BACK_COMMERCIAL_LAW_UPDATE = 'BACK_COMMERCIAL_LAW_UPDATE'
export const BACK_COMMERCIAL_LAW_DELETE = 'BACK_COMMERCIAL_LAW_DELETE'

export const BACK_CUSTOMER_NOTICE = 'BACK_CUSTOMER_NOTICE'

export const BACK_MY_PAGE = 'BACK_MY_PAGE'
export const BACK_MY_PAGE_QUERY = 'BACK_MY_PAGE_QUERY'

export const BACK_TICKET = 'BACK_TICKET'
export const BACK_TICKET_QUERY = 'BACK_TICKET_QUERY'
export const BACK_TICKET_COMMENT = 'BACK_TICKET_COMMENT'
export const BACK_TICKET_UNCLOSED_COUNT = 'BACK_TICKET_UNCLOSED_COUNT'

export const BACK_STOCK = 'BACK_STOCK'
export const BACK_STOCK_QUERY = 'BACK_STOCK_QUERY'
export const BACK_STOCK_DETAIL = 'BACK_STOCK_DETAIL'
