<template>
  <v-overlay
    :value="isLoading"
    :z-index="zIndex"
  >
    <v-progress-circular
      indeterminate
      size="64"
    />
  </v-overlay>
</template>

<script>

  import { mapState } from 'vuex'

  /**
   * example
   * <loading />
   */
  export default {
    name: 'Loading',
    data () {
      return {
        zIndex: 1000
      }
    },
    computed: {
      ...mapState('loading', {
        isLoading: 'isLoading'
      })
    },
    methods: {
    }
  }

</script>

<style scoped lang="scss">
</style>
