/**
 * 会社情報
 */
import Vue from 'vue'
import * as types from "../../mutation-types/frontend-types"
import * as httpConst from "../../../constants/httpConst";
import VueGtag from "vue-gtag";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/company`

const company = {
    namespaced: true,
    state: {
        content: {
            use_credit_card: false,
            use_bank_transfer: false,
            use_cash_on_delivery: false,
        },
    },
    getters: {},
    actions: {
        fetch({ commit }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH).then(data => {
                    let content = data.content
                    commit(types.FRONT_COMPANY, { content })
                    resolve(content)
                })
            })
        },
    },
    mutations: {
        [types.FRONT_COMPANY] (state, { content }) {
            state.content = content
        },
    },
}

export default company
