var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{staticClass:"px-0 py-2",attrs:{"items":_vm.breadcrumbs}}),_vm._v(" "),_c('alert-message'),_vm._v(" "),_c('v-alert',{attrs:{"dense":"","outlined":""}},[_c('p',{staticClass:"mb-0"},[_vm._v("\n      「変動送料金額」を設定すると注文時の送料は、「変動送料」が適用されます。\n    ")])]),_vm._v(" "),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('h3',[_vm._v(_vm._s(_vm.prefectureName))]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"10","md":"3"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"基本送料","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"基本送料","outlined":"","placeholder":" ","prefix":"円","required":"","reverse":""},model:{value:(_vm.postage),callback:function ($$v) {_vm.postage=$$v},expression:"postage"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"10","md":"3"}},[_c('validation-provider',{attrs:{"name":"変動送料金額","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"変動送料金額","outlined":"","placeholder":" ","prefix":"円","reverse":""},model:{value:(_vm.changePostagePrice),callback:function ($$v) {_vm.changePostagePrice=$$v},expression:"changePostagePrice"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"2","md":"2","align-self":"center"}},[_c('div',[_vm._v("以上")])])],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"10","md":"3"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"変動送料","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"変動送料","outlined":"","placeholder":" ","prefix":"円","required":"","reverse":""},model:{value:(_vm.changePostage),callback:function ($$v) {_vm.changePostage=$$v},expression:"changePostage"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.onCancel}},[_vm._v("\n                キャンセル\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.submit}},[_vm._v("\n                保存\n              ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }