// シイステム設定
export const FRONT_SYSTEM_SETTING = 'FRONT_SYSTEM_SETTING'

// Frontend Auth
export const SIGN_IN_FRONT = 'SIGN_IN_FRONT'
export const SIGN_OUT_FRONT = 'SIGN_OUT_FRONT'

// Frontend ホーム
export const FRONT_THEME = 'FRONT_THEME'
export const FRONT_HEADER = 'FRONT_HEADER'
export const FRONT_FOOTER = 'FRONT_FOOTER'

// Frontend 会社信用情報
export const FRONT_CREDENCE = 'FRONT_CREDENCE'

export const FRONT_COMPANY = 'FRONT_COMPANY'

// Frontend カテゴリー
export const FRONT_CATEGORY = 'FRONT_CATEGORY'

// Frontend タグ
export const FRONT_TAG = 'FRONT_TAG'

// Frontend キャンペーン
export const FRONT_CAMPAIGN = 'FRONT_CAMPAIGN'

// Frontend 商品
export const FRONT_PRODUCT = 'FRONT_PRODUCT'
export const FRONT_PRODUCT_QUERY = 'FRONT_PRODUCT_QUERY'
export const FRONT_PRODUCT_QUESTION = 'FRONT_PRODUCT_QUESTION'
export const FRONT_PRODUCT_REVIEW = 'FRONT_PRODUCT_REVIEW'

// Frontend カート件数
export const FRONT_CART = 'FRONT_CART'
export const FRONT_CART_COUNT = 'FRONT_CART_COUNT'
export const FRONT_TMP_CUSTOMER_ID = 'FRONT_TMP_CUSTOMER_ID'

// Frontend カートの中身が現地在庫か
export const FRONT_CART_INSIDE = 'FRONT_CART_INSIDE'

// Frontend 休日
export const FRONT_HOLIDAY = 'FRONT_HOLIDAY'

// 顧客（支払いで使用）
export const FRONT_PAYMENT_CUSTOMER = 'FRONT_PAYMENT_CUSTOMER'

// 注文履歴
export const FRONT_ORDER_DETAIL = 'FRONT_ORDER_DETAIL'

// 店舗からのお知らせ
export const FRONT_CUSTOMER_NOTICE = 'FRONT_CUSTOMER_NOTICE'

// マイページ
export const FRONT_MY_PAGE = 'FRONT_MY_PAGE'

// 店舗への問い合わせ
export  const FRONT_TICKET = 'FRONT_TICKET'
export  const FRONT_TICKET_COMMENT = 'FRONT_TICKET_COMMENT'


// 延長注文
export const FRONT_EXTEND_ORDER = 'FRONT_EXTEND_ORDER'
export const FRONT_EXTEND_ORDER_RESET = 'FRONT_EXTEND_ORDER_RESET'
export const FRONT_EXTEND_ORDER_UPDATE = 'FRONT_EXTEND_ORDER_UPDATE'
export const FRONT_EXTEND_PAYMENT = 'FRONT_EXTEND_PAYMENT'




