<!-- バックヤード > 会員詳細 > 問い合わせ履歴 -->
<!-- バックヤード > 店舗問い合わせ一覧 -->
<template>
  <div>
    <v-card flat>
      <v-card-title>
        問い合わせ履歴
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="ticketTableHeaders"
                :items="tickets"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.created_at="{ item }">
                  {{ item.created_at | unix_datetime_format }}
                </template>
                <template v-slot:item.subject="{ item }">
                  {{ item.subject | truncate(30) }}
                </template>
                <template v-slot:item.status="{ item }">
                  {{ item.status | ticketStatusFilter }}
                </template>
                <template v-slot:item.updated_at="{ item }">
                  {{ item.updated_at | unix_datetime_format }}
                </template>
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="cyan darken-2"
                    @click="showEdit(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="primary"
                    small
                    @click="showEdit(item)"
                  >
                    参照する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>

          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-pagination
              v-model="page"
              :length="pagination.total_pages"
              :total-visible="totalVisible"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              circle
              @input="changePage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TicketMixin from '../../../../mixins/ticket-mixin'
export default {
  mixins: [
    TicketMixin
  ],
  data () {
    return {
      page: 1,
      totalVisible: 7,
      ticketTableHeaders: [
        { value: 'created_at', text: '問い合わせ日時' },
        { value: 'subject', text: '件名' },
        { value: 'status', text: 'ステータス' },
        { value: 'updated_at', text: '最終更新日時' },
        { value: 'editable', text: '', align: 'end' },
      ],
      query: {
        subject: null,
        customer_no: null,
        customer_id: null,
        customer_name: null,
        start_date: null,
        end_date: null,
        status_opened: false,
        status_in_process: false,
        status_closed: false,
      },
      tickets: [],
    }
  },
  computed: {
    ...mapState('backTicket', {
      pagination: 'pagination',
      searchQuery: 'query',
    })
  },
  created() {
    this.query.customer_id = this.$route.params.id
    this.page = this.pagination.current_page
    this.searchTickets()
  },
  methods: {
    ...mapActions('backTicket', {
      findTickets: 'findTickets',
    }),
    showEdit: function (item) {
      this.$router.push({name: 'byInquiryEdit', params: {id: item.id}})
    },
    searchTickets() {
      const params = {
        customer_id: this.query.customer_id,
        page: this.page
      }
      this.findTickets({params}).then((content) => {
        this.tickets = content.tickets
      })
    },
    changePage () {
      this.searchTickets()
    },
  }
}
</script>

<style lang="scss">

</style>
