/**
 * Home/システムからのお知らせ
 */
import Vue from 'vue';
import * as httpConst from '../../../../constants/httpConst';

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/announcements`

const announcements = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        fetchAll({ _commit }) {
            const parameter = { page: 1, limit: 10 }
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: parameter }).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
    },
    mutations: {},
}
export default announcements