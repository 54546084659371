/**
 * frontend 用のモジュール読み込み
 */
import frontAuth from './auth'
import frontSystemSetting from './system_setting'
import frontForget from './forget'
import frontHeaderOption from './configurations/header_option'
import frontFooterOption from './configurations/footer_option'
import frontThemeOption from './configurations/theme_option'
import frontBannerOption from './configurations/banner_option'
import frontNotice from './notice'
import frontCredence from './credence'
import frontCompany from './company'
import frontCategory from './category'
import frontTag from './tag'
import frontCampaign from './campaign'
import frontProduct from './product'
import frontCustomer from './customer'
import frontIdentification from './customer/identification'
import frontCart from './cart'
import frontCartInside from './cart_inside'
import frontCard from './card'
import frontPayment from './payment'
import frontOrder from './order'
import frontOrderSubscription from './orders/subscription'
import frontExtendOrder from './orders/extend_order'
import frontExtendPayment from './orders/extend_payment'
import frontProductQuestion from './product_question'
import frontProductReview from './product_review'
import frontCustomerNotice from './customer_notice'
import frontMyPage from './my_page'
import frontTicket from './ticket'
import frontTicketComment from './ticket_comment'
import frontOptionGroup from './option_group'
import frontCardSwitch from './card_switch'
import frontDeliveryDesiredTime from './delivery_desired_time'
import frontHoliday from './holiday'

const frontModules = {
  modules: {
    frontAuth,
    frontSystemSetting,
    frontForget,
    frontHeaderOption,
    frontFooterOption,
    frontThemeOption,
    frontBannerOption,
    frontNotice,
    frontCredence,
    frontCompany,
    frontCategory,
    frontTag,
    frontCampaign,
    frontProduct,
    frontCustomer,
    frontIdentification,
    frontCart,
    frontCartInside,
    frontCard,
    frontPayment,
    frontOrder,
    frontOrderSubscription,
    frontExtendOrder,
    frontExtendPayment,
    frontProductQuestion,
    frontProductReview,
    frontCustomerNotice,
    frontMyPage,
    frontTicket,
    frontTicketComment,
    frontOptionGroup,
    frontCardSwitch,
    frontDeliveryDesiredTime,
    frontHoliday,
  }
}

export default frontModules
