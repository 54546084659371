<!-- バックヤード > カテゴリー追加 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-row>
      <v-col>
        <category-form />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import CategoryForm from './_Form'

export default {
  name: 'CategoriesNew',
  components: {
    CategoryForm
  },
  mixins: [],
  data () {
    return {
      breadcrumbs: [
        { text: 'カテゴリー一覧', disabled: false, to: '/backyard/categories', exact: true },
        { text: 'カテゴリー新規', disabled: true, to: '' },
      ],
    }
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss">

</style>
