<!-- バックヤード > ログイン -->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          xs="12"
          sm="6"
          md="4"
          offset-sm="3"
          offset-md="4"
        >
          <v-form>
            <validation-observer v-slot="{ invalid }">
              <v-card outlined>
                <v-card-title>
                  <v-img
                    v-bind:src="logo_src"
                    max-height="24"
                    max-width="24"
                  />
                  <span
                    class="title font-weight-light"
                    style="margin-left: 10px;"
                  >
                    bizRENT
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="ログインID"
                        class="requiredMark"
                      >
                        <v-text-field
                          v-model="loginId"
                          label="ログインID"
                          dense
                          hide-details="auto"
                          outlined
                          placeholder=" "
                          required
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="パスワード"
                        class="requiredMark"
                      >
                        <v-text-field
                          v-model="password"
                          type="password"
                          label="パスワード"
                          dense
                          hide-details="auto"
                          outlined
                          placeholder=" "
                          required
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    block
                    color="primary"
                    :disabled="invalid"
                    @click="login"
                  >
                    Login
                  </v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
          </v-form>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-center">
          <v-btn
            text
            color="info"
            @click="forgetLink"
          >
            パスワードを忘れた方はこちら
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

  export default {
    components: {
    },
    mixins: [],
    data () {
      return {
        loginId: '',
        password: '',
        logo_src: require('../../../assets/logo.png'),
      }
    },
    computed: {
      ...mapState('backyardAuth', {
        loggedIn: 'logged_in'
      }),
      isLogin: function () {
        return this.loggedIn
      }
    },
    created() {
      if (this.loggedIn) {
        this.$router.push({name: 'byHomeIndex'})
      }
    },
    methods: {
      ...mapActions('backyardAuth', {
        signIn: 'signInBackyard',
      }),
      login () {
        const params = {
          login_id: this.loginId,
          password: this.password,
        }
        this.signIn({ params }).then(() => {
          this.$router.push({name: 'byHomeIndex'})
        })
      },
      forgetLink () {
        this.$router.push({name: 'byForgetIndex'})
      }
    },
  }
</script>

<style lang="scss">
</style>
