/**
 * 商品
 */
import Vue from "vue";
import * as httpConst from "../../../../../constants/httpConst";
import * as types from "../../../../mutation-types/backyard-types";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/orders`

const extend_order_cancel = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    create( { commit }, { order_id, params } ) {
      const api_path = `${API_PATH}/${order_id}/extend_orders/cancels`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(api_path, params).then( data => {
          commit('snackbar/showInfo', { message: '期間延長を取り消しました' }, { root: true })
          let content = data.content
          resolve(content)
        } )
      })
    },
  },
  mutations: {},
}

export default extend_order_cancel