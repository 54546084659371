<!-- バックヤード > カテゴリーform -->
<template>
  <div v-if="category">
    <alert-message />

    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|max:30"
                      name="カテゴリー名"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="category.name"
                        label="カテゴリー名"
                        placeholder=" "
                        counter="30"
                        dense
                        hide-details="auto"
                        outlined
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row v-show="category.home_display">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="category.default_font_color"
                      hide-details="auto"
                      label="カテゴリー名 文字色"
                      dense
                      readonly
                      outlined
                      placeholder=" "
                      @click="defaultFontColorMenu = true"
                    >
                      <template v-slot:append>
                        <v-menu
                          v-model="defaultFontColorMenu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on }">
                            <div
                              :style="defaultFontColorSwatchStyle"
                              v-on="on"
                            />
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker
                                v-model="category.default_font_color"
                                flat
                              />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|max:30"
                      name="カテゴリー名(表示)"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="category.display_name"
                        :error-messages="errors"
                        counter="30"
                        dense
                        hide-details="auto"
                        label="カテゴリー名(表示)"
                        outlined
                        placeholder=" "
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row v-show="category.home_display">
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="category.selected_font_color"
                      hide-details="auto"
                      label="カテゴリー名(表示) 文字色"
                      dense
                      readonly
                      outlined
                      placeholder=" "
                      @click="selectedFontColorMenu = true"
                    >
                      <template v-slot:append>
                        <v-menu
                          v-model="selectedFontColorMenu"
                          top
                          nudge-bottom="105"
                          nudge-left="16"
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on }">
                            <div
                              :style="selectedFontColorSwatchStyle"
                              v-on="on"
                            />
                          </template>
                          <v-card>
                            <v-card-text class="pa-0">
                              <v-color-picker
                                v-model="category.selected_font_color"
                                flat
                              />
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-checkbox
                      v-model="category.home_display"
                      label="HOME画面に表示する(推奨サイズ : 250 * 250)"
                      hide-details="auto"
                      class="pa-0 ma-0"
                    />
                  </v-col>
                </v-row>

                <v-row v-show="category.home_display">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="mimes:image/jpeg,image/png"
                      name="画像ファイル"
                    >
                      <v-row
                        class="text-center"
                        @dragover.prevent
                        @dragenter="onDragEnter"
                        @dragleave="onDragLeave"
                        @drop="onDrop"
                      >
                        <v-col>
                          <v-file-input
                            v-model="category.file"
                            :background-color="isDragging ? 'blue' : 'null'"
                            :clearable="false"
                            :show-size="1000"
                            :error-messages="errors"
                            accept="image/jpeg,image/png"
                            append-icon="mdi-folder"
                            counter
                            dense
                            label="画像ファイル"
                            hint="画像ファイルを選択して下さい"
                            outlined
                            persistent-hint
                            placeholder=" "
                            prepend-icon=""
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>
                              <span
                                v-else-if="index === 2"
                                class="overline grey--text text--darken-3 mx-2"
                              >+{{ files.length - 2 }} File(s)</span>
                            </template>
                          </v-file-input>
                        </v-col>
                      </v-row>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row v-if="category.home_display">
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <div
                      style="position: relative;border: 1px solid #ddd;"
                    >
                      <v-card style="background-color: #fafafa;">
                        <v-card-subtitle>
                          プレビュー
                        </v-card-subtitle>
                        <v-card-text
                          class="justify-center"
                        >
                          <v-hover v-slot:default="{ hover }">
                            <v-card
                              flat
                              tile
                            >
                              <v-img
                                :src="category.image_url | load_image_url"
                                max-height="300"
                                contain
                                aspect-ratio="1"
                                class="gray lighten-4 align-center"
                              >
                                <v-expand-transition>
                                  <div
                                    v-if="hover"
                                    class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text"
                                    style="height: 100%"
                                  >
                                    <div class="text-center">
                                      <h4
                                        class="font-weight-light title"
                                        :style="{ color: category.selected_font_color }"
                                      >
                                        {{ category.display_name }}
                                      </h4>
                                      <v-btn
                                        outlined
                                        depressed
                                        light
                                        :style="{ color: category.selected_font_color }"
                                      >
                                        検索
                                      </v-btn>
                                    </div>
                                  </div>
                                </v-expand-transition>
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="grey lighten-5"
                                    />
                                  </v-row>
                                </template>
                                <div v-if="!hover">
                                  <h2
                                    class="text-center"
                                    :style="{ color: category.default_font_color }"
                                  >
                                    {{ category.name }}
                                  </h2>
                                </div>
                              </v-img>
                            </v-card>
                          </v-hover>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <v-btn
                            small
                            color="error"
                            @click="imageDeleteButtonClickEvent"
                          >
                            削除
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="表示順"
                      rules="required|numeric|min_value:0|max_value:2147483647"
                    >
                      <v-text-field
                        v-model="category.position"
                        :error-messages="errors"
                        dense
                        hide-details="auto"
                        label="表示順"
                        outlined
                        placeholder=" "
                        required
                        reverse
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-checkbox
                      v-model="category.display_product"
                      label="商品一覧にカテゴリーを表示する"
                      hide-details="auto"
                      class="pa-0 ma-0"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="category.id"
                  color="error"
                  @click="categoryDelete"
                >
                  削除
                </v-btn>
                <v-spacer />
                <v-btn @click="categoryCancel">
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="submit"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <confirm
      v-if="category.id"
      ref="confirm"
    />
  </div>
</template>

<script>
import AlertMessage from '../../../components/AlertMessage'
import Confirm from '../../../components/Confirm'
import {mapActions} from 'vuex'

export default {
  name: 'CategoryForm',
  components: {
    AlertMessage,
    Confirm,
  },
  mixins: [],
  props: {
  },
  data () {
    return {
      breadcrumbs: [
        { text: 'カテゴリー一覧', disabled: false, to: '/backyard/categories', exact: true },
        { text: 'カテゴリー詳細', disabled: true, to: '' },
      ],
      defaultFontColorMenu: false,
      selectedFontColorMenu: false,
      isDragging: false,
      imageRemoved: false,
      dragCount: 0,
      /**
       * @typedef category {Object}
       * @property name {String}
       * @property display_name {String}
       * @property home_display {Boolean}
       * @property image_url {String}
       * @property file {Object}
       * @property position {Number}
       * @property default_font_color {String}
       * @property selected_font_color {String}
       * @property display_product {Boolean}
       */
      category: {},
    }
  },
  computed: {
    defaultFontColorSwatchStyle() {
      let _default_font_color = this.category.default_font_color == null ? '#FFFFFF' : this.category.default_font_color
      return this.colorChangeEvent(_default_font_color, this.defaultFontColorMenu)
    },
    selectedFontColorSwatchStyle () {
      let _selected_font_color = this.category.selected_font_color == null ? '#000000' : this.category.selected_font_color
      return this.colorChangeEvent(_selected_font_color, this.selectedFontColorMenu)
    }
  },
  watch: {
    'category.file': function(newValue) {
      if (newValue) {
        this.getBase64(newValue).then((image) => {
          this.category.image_url = image
          this.imageRemoved = false
        })
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.editCategory({id: this.$route.params.id}).then((content) => {
        this.category = content
      })
    } else {
      this.newCategory().then((content) => {
        this.category = content
      })
    }
  },
  methods: {
    ...mapActions('backCategory', {
      newCategory: 'new',
      editCategory: 'edit',
      createCategory: 'create',
      updateCategory: 'update',
      destroyCategory: 'destroy'
    }),
    colorChangeEvent (color, menu) {
      return {
        backgroundColor: color,
        marginBottom: '7px',
        border: '1px solid #ddd',
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    imageDeleteButtonClickEvent () {
      this.category.file = null
      this.category.image_url = null
      this.imageRemoved = true
    },
    buildParams () {
      const params = new FormData()


      params.append('name', this.category.name)
      params.append('display_name', this.category.display_name)
      params.append('home_display', this.category.home_display)
      params.append('position', this.category.position)
      params.append('display_product', this.category.display_product)

      if (this.category.home_display) {
        if (this.category.file != null)
          params.append('file', this.category.file)

        params.append('remove_image', this.imageRemoved)
        params.append('default_font_color', this.convertToEmptyString(this.category.default_font_color))
        params.append('selected_font_color', this.convertToEmptyString(this.category.selected_font_color))
      }
      return params
    },
    categoryCancel: function () {
      this.$router.push({name: 'CategoryIndex'})
    },
    categoryDelete: function () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyCategory({id: this.$route.params.id }).then(() =>{
            this.$router.push({name: 'CategoryIndex'})
          })
        }
      })
    },
    submit() {
      const params = this.buildParams()
      if (this.$route.params.id) {
        this.updateCategory({ id: this.$route.params.id, params: params }).then(() =>{
          this.$router.push({name: 'CategoryIndex'})
        })
      } else {
        this.createCategory({ params }).then(() =>{
          this.$router.push({name: 'CategoryIndex'})
        })
      }
    },
    convertToEmptyString(value) {
      // int項目にnull文字列を送信すると0で登録されるので空文字に変換する
      return (value === null || value === 'null') ? '' : value
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const _files = e.dataTransfer.files;
      this.file = _files[0];
      // for (const file in _files) {
      //   if (!isNaN(file)) {
      //     //filesはファイル以外のデータが入っており、ファイルの場合のみキー名が数字になるため
      //     this.file = _files[file];
      //     // this.files.push(_files[file]);
      //   }
    },
    getBase64 (file) {
      /* Promiseにアロー関数を渡して非同期処理を書く。*/
      return new Promise((resolve, reject) => {

        /* 組み込みオブジェクトを使い、Base64エンコードされた画像データを取得  */
        const reader = new FileReader()
        reader.readAsDataURL(file)

        /* 成功時と失敗時の処理。resolveの引数に結果を渡して、のちの.then(result => ...)で受け取る  */
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    onDragEnter(e) {
      e.preventDefault();
      this.isDragging = true;
      this.dragCount++;
    },

    onDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    }
  }
}
</script>

<style lang="scss">
</style>
