<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-img
      v-if="backgroundImageContent.image_url != null"
      :class="!backgroundImageContent.use_button && backgroundImageContent.transition_type != 't_no_transition' ? 'cursor_pointer' : ''"
      :src="backgroundImageContent.image_url | load_image_url"
      height="100%"
      @click="imageClickEvent"
    >
      <BackgroundImageContentItem
        :background-image-content="backgroundImageContent"
        @linkClickEventCallback="buttonClickEvent"
      />
    </v-img>
    <v-row
      v-else
      :style="{background: backgroundImageContent.background_color, height: '800px'}"
    >
      <BackgroundImageContentItem
        :background-image-content="backgroundImageContent"
        @linkClickEventCallback="buttonClickEvent"
      />
    </v-row>
  </v-row>
</template>

<script>
  import BackgroundImageContentItem from "./_BackgroundImageContentItem";
  export default {
    name: 'BackgroundImageContent',
    components: {
      BackgroundImageContentItem,
    },
      props: {
      backgroundImageContent: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
      }
    },
    methods: {

      imageClickEvent () {
        if (this.backgroundImageContent.use_button) {
          return false
        }
        this.buttonClickEvent()
      },
      buttonClickEvent () {

        // 各アクションを設定
        switch (this.backgroundImageContent.transition_type) {
          case 't_item_detail':
            // 商品詳細
              this.$router.push({name: 'ProductShow', params: { id: this.backgroundImageContent.product_id }})
            break
          case 't_category':
            // 商品一覧(カテゴリーで絞り込み）
            this.$router.push({name: 'ProductIndex', query: {category_id: this.backgroundImageContent.category_id}})
            break
          case 't_tag':
            // 商品一覧(タグで絞り込み)
            this.$router.push({name: 'ProductIndex', query: {tag_id: this.backgroundImageContent.tag_id}})
            break
          case 't_my_page':
            // マイページ
            this.$router.push({name: 'MyPageShow', params: {post_name: this.backgroundImageContent.my_page_post_name, title: this.backgroundImageContent.my_page_title}})
            break
          case 't_external':
            // 外部
            window.open(this.backgroundImageContent.transition_url, '_blank')
            break
          default:
            break
        }

      }


    }
  }
</script>

<style scoped lang="scss">
.cursor_pointer { cursor: pointer}
</style>