<!-- バックヤード > 受注詳細 > 同封書類ダウンロードダイアログ -->
<template>
  <v-dialog
    v-model="showDocumentDialog"
    width="800"
    @click:outside="closeDocumentDialog"
  >
    <v-card>
      <v-card-title>同封書類</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="selectedProductId"
              :items="products"
              outlined
              label="商品"
              placeholder=" "
            />
          </v-col>
        </v-row>

        <v-simple-table
          v-if="productDocuments.length"
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width:60%;">
                  ファイル名
                </th>
                <th
                  style="width:25%;"
                  class="text-right"
                />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="product_document in productDocuments"
                :key="product_document.id"
              >
                <td>
                  {{ product_document.name }}
                </td>
                <td class="text-right">
                  <v-btn
                    icon
                    small
                    color="info"
                    @click="viewFile(product_document)"
                  >
                    <v-icon>mdi-file-eye-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <h3 v-else>
          同封書類が登録されておりません
        </h3>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="closeDocumentDialog">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import { mapActions } from 'vuex'

  export default {
    components: {},
    mixins: [],
    data () {
      return {
        showDocumentDialog: false,
        orderDeliveryId: null,
        selectedProductId: null,
        products: [],
        productDocuments: []
      }
    },
    watch: {
      selectedProductId: function(newValue) {
        this.searchDocuments()
      }
    },
    methods: {
      ...mapActions('backOrderDeliveryProduct', {
        fetchProducts: 'fetchAll'
      }),
      ...mapActions('backOrderDeliveryDocument', {
        fetchDocuments: 'fetchAll'
      }),
      openDialog (params) {
        this.orderDeliveryId = params.id
        this.searchProducts()
        this.showDocumentDialog = true
      },
      searchProducts () {
        this.fetchProducts({order_delivery_id: this.orderDeliveryId}).then((content) => {
          if (this.selectedProductId) {
          } else {
            this.selectedProductId = content.products[0].value
          }
          this.products = content.products
        })
      },
      searchDocuments () {
        if (this.selectedProductId === null) {
          this.productDocuments = []
          return
        }
        this.fetchDocuments({product_id: this.selectedProductId}).then((content) => {
          this.productDocuments = content.product_documents
        })
      },
      viewFile (item) {
        let link = document.createElement('a')
        link.href = item.url
        link.target = '_blank'
        link.click()
      },
      closeDocumentDialog () {
        Object.assign(this.$data, this.$options.data())
      }

    },
  }
</script>