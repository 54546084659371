/**
 * システム設定
 */
import * as types from '../../mutation-types/frontend-types'
import * as httpConst from '../../../constants/httpConst'
import Vue from 'vue'
import store from 'store'

const getSystemMode = () => {
    return (store.get('system_mode'))
}

const getAuthMode = () => {
    return (store.get('use_frontend_auth'))
}

const getSiteTitle = () => {
    return (store.get('site_title'))
}

const getCustomerRegType= () => {
    return (store.get('customer_reg_type'))
}

const getIdentification = () => {
    return (store.get('use_identification'))
}

const hasAuctionMode = () => {
    return (getSystemMode() === 'auction')
}

const hasFrontendAuth = () => {
    return (getAuthMode() === true)
}

const hasFrontendCustomerInput = () => {
    return (getCustomerRegType() === 'front_input' || getCustomerRegType() === 'front_back_input')
}

const hasIdentification = () => {
    return (getIdentification() === true)
}

const canUseRefinedSearchArea = () => {
    return (store.get('use_refined_search_area'))
}

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/system_setting`

const system_setting = {
    namespaced: true,
    state: {
        site_title: getSiteTitle(),
        system_mode: getSystemMode(),
        use_frontend_auth: getAuthMode(),
        customer_reg_type: getCustomerRegType(),
        auction_mode: hasAuctionMode(),
        frontend_auth_mode: hasFrontendAuth(),
        frontend_customer_input: hasFrontendCustomerInput(),
        use_refined_search_area: canUseRefinedSearchArea(),
        use_identification: hasIdentification(),
    },
    getters: {
        isAuctionMode: ( state ) => {
            return state.auction_mode
        }
    },
    actions: {
        find ({ commit }) {
            return new Promise((resolve) => {
                Vue.$http.get(API_PATH).then( data => {
                    const system_setting = data.content.system_setting
                    commit(types.FRONT_SYSTEM_SETTING, { system_setting })
                    resolve(system_setting)
                })
            })
        },
    },
    mutations: {
        [types.FRONT_SYSTEM_SETTING] (state, { system_setting }) {
            state.site_title  = system_setting.site_title
            state.system_mode = system_setting.mode
            state.use_frontend_auth = system_setting.use_frontend_auth
            state.customer_reg_type = system_setting.customer_reg_type
            state.use_refined_search_area = system_setting.use_refined_search_area
            state.use_identification = system_setting.use_identification
            // localStorageに保持
            store.set('site_title', state.site_title)
            store.set('system_mode', state.system_mode)
            store.set('use_frontend_auth', state.use_frontend_auth)
            store.set('customer_reg_type', state.customer_reg_type)
            store.set('use_refined_search_area', state.use_refined_search_area)
            store.set('use_identification', state.use_identification)
        },
    },
}

export default system_setting
