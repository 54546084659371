/**
 * 店舗への問い合わせ
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/frontend-types";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/tickets`

const ticket = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0,
      limit_value: 20
    },
  },
  getters: {},
  actions: {
    findTickets({ commit }, { params: params }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.FRONT_TICKET, { content })
          resolve(content)
        })
      })
    },
    create ({ commit }, { params }) {
      return new Promise((resolve, reject) => {
        Vue.$http.post(API_PATH, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    findTicket ({ _commit }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
  },
  mutations: {
    [types.FRONT_TICKET] (state, { content }) {
      state.pagination = content.pagination
    },
  },
}

export default ticket
