<template>
  <div id="app">
    <v-app>
      <HeaderNav />
      <v-main class="blue-grey lighten-5">
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>
      <v-footer
        app
        absolute
        color="#4fd2c2"
      >
        <v-btn
          v-if="loggedIn"
          text
          color="white"
          @click="moveToPollicy"
        >
          プライバシーポリシー
        </v-btn>
        <v-btn
          v-if="loggedIn"
          text
          color="white"
          @click="moveToTearms"
        >
          利用規約
        </v-btn>
        <v-btn
          v-if="loggedIn"
          text
          color="white"
          @click="moveToLow"
        >
          特定商取引法に基づく表記
        </v-btn>

        <div class="flex-grow-1" />
        <div
          class="white--text"
          style="font-size: 14px"
        >
          &copy; 2019 - {{ new Date().getFullYear() }} TACT iNFORMATiON SystemS Co.,Ltd. All Rights Reserved.
        </div>
      </v-footer>
      <snackbar />
      <loading />
    </v-app>
  </div>
</template>

<script>
  import {mapActions, mapState} from "vuex";
  import Loading from '../components/Loading'
  import Snackbar from '../components/Snackbar'
  import HeaderNav from './components/HeaderNav'
  import FaviconMixin from '../mixins/favicon-mixin'

  export default {
    components: {
      HeaderNav,
      Loading,
      Snackbar,
    },
    mixins: [
      FaviconMixin
    ],
    data () {
      return {
        auth : true,
      }
    },
    computed: {
      ...mapState('backyardAuth', {
        loggedIn: 'logged_in'
      }),
      isLogin: function () {
        return this.loggedIn
      }
    },
    created() {
      this.find()
    },
    methods: {
      ...mapActions('backSystemSetting', {
        find: 'find',
      }),
      moveToPollicy () {
        window.open( 'https://tact-info.com/bizrent/privacy.html', "_blank" )
      },
      moveToTearms() {
        window.open( 'https://tact-info.com/bizrent/userpolicy.html', "_blank" )
      },
      moveToLow() {
        window.open( 'https://tact-info.com/bizrent/trade.html', "_blank" )
      }
    },
  }
</script>

<style scoped lang="scss">
  // グローバルスタイルを設定
  #app {
    min-height: 100vh;
  }
</style>
