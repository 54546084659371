var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"small":""},on:{"click":_vm.showNewModal}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.productImages)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.productImages,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image_url,"max-height":"40","max-width":"60","contain":""}})]}},{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"cyan darken-2"},on:{"click":function($event){return _vm.editButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1):_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.editButtonClickEvent(item)}}},[_vm._v("\n                  編集する\n                ")]),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}],null,false,1889598275)}):_vm._e()],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"450"},on:{"close":_vm.closeDialog},model:{value:(_vm.isShowProductImageDialog),callback:function ($$v) {_vm.isShowProductImageDialog=$$v},expression:"isShowProductImageDialog"}},[_c('validation-observer',{ref:"imageFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',[_c('v-card-title',[_vm._v("商品画像")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"画像ファイル","rules":"mimes:image/jpeg,image/png"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{staticClass:"text-center",on:{"dragover":function($event){$event.preventDefault();},"dragenter":_vm.onDragEnter,"dragleave":_vm.onDragLeave,"drop":_vm.onDrop}},[_c('v-col',[_c('v-file-input',{attrs:{"background-color":_vm.isDragging ? 'blue' : 'null',"error-messages":errors,"show-size":1000,"accept":"image/jpeg,image/png","append-icon":"mdi-folder","counter":"","dense":"","label":"画像ファイル","hint":"画像ファイルを選択して下さい","outlined":"","persistent-hint":"","placeholder":" ","prepend-icon":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v("\n                            "+_vm._s(text)+"\n                          ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v("+"+_vm._s(_vm.files.length - 2)+" File(s)")]):_vm._e()]}}],null,true),model:{value:(_vm.productImage.file),callback:function ($$v) {_vm.$set(_vm.productImage, "file", $$v)},expression:"productImage.file"}})],1)],1)]}}],null,true)})],1)],1),_vm._v(" "),(_vm.productImage.image_url)?_c('v-row',[_c('v-col',[_c('v-card',{staticStyle:{"background-color":"#fafafa"},attrs:{"flat":"","tile":""}},[_c('v-card-subtitle',[_vm._v("\n                    プレビュー\n                  ")]),_vm._v(" "),_c('v-card-text',{staticClass:"justify-center"},[_c('v-img',{staticClass:"gray lighten-4 align-center",attrs:{"src":_vm.productImage.image_url,"max-height":"200","contain":"","aspect-ratio":"1"}})],1)],1)],1)],1):_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"alt属性","rules":"max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"alt属性","outlined":"","placeholder":" "},model:{value:(_vm.productImage.alt_text),callback:function ($$v) {_vm.$set(_vm.productImage, "alt_text", $$v)},expression:"productImage.alt_text"}})]}}],null,true)})],1)],1),_vm._v(" "),(_vm.productImage.id != null)?_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"表示順","rules":"required|numeric|min_value:0|max_value:2147483647"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"background-color":_vm.isDragging ? 'blue' : 'null',"error-messages":errors,"show-size":1000,"dense":"","hide-details":"auto","label":"表示順","outlined":"","placeholder":" ","required":"","reverse":""},model:{value:(_vm.productImage.position),callback:function ($$v) {_vm.$set(_vm.productImage, "position", $$v)},expression:"productImage.position"}})]}}],null,true)})],1)],1):_vm._e()],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.closeDialog}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":_vm.dialogRegisterButtonClickEvent}},[_vm._v("\n              OK\n            ")])],1)],1)],1)]}}])})],1),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }