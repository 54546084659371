import {mapActions, mapState} from "vuex";
const PageHeaderMixin = {
    data () {
        return {
            pageTitle: null,
            separator: '|',
            meta_tags: [],
            descriptionContent: null
        }
    },
    watch: {
        descriptionContent (newValue) {
            this.meta_tags = [ { "name": 'description', "content": newValue, "id": 'description' } ]
        },
        pageTitle () {
            let self = this
            self.setHeaders()
            // setTimeout(function(){self.setHeaders()}, 100)
        },
        siteTitle () {
            this.setHeaders()
        },
    },
    computed: {
        ...mapState('frontSystemSetting', {
            siteTitle: 'site_title'
        })
    },
    created() {
        this.fetchSystemSetting().then(() => {})
    },
    methods: {
        ...mapActions('frontSystemSetting', {
            fetchSystemSetting: 'find',
        }),
        setHeaders () {
            if (!this.pageTitle) {
                this.pageTitle = ''
                this.separator = ' '
            } else {
                this.separator = '|'
            }
            let self = this
            self.$emit('updateHead')
        },
    },
    head: {
        title() {
            return {
                inner: (this.pageTitle || ''),
                separator: this.separator,
                complement: this.siteTitle,
            }
        },
        meta () {
            return this.meta_tags
        }
    },
}
export default PageHeaderMixin
