var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert-message'),_vm._v(" "),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"max:50","name":"店舗名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"50","dense":"","hide-details":"auto","label":"店舗名","outlined":"","placeholder":" "},model:{value:(_vm.credence.trader_name),callback:function ($$v) {_vm.$set(_vm.credence, "trader_name", $$v)},expression:"credence.trader_name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"rules":"max:20","name":"代表者名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"20","dense":"","hide-details":"auto","label":"代表者名","outlined":"","placeholder":" "},model:{value:(_vm.credence.representative_name),callback:function ($$v) {_vm.$set(_vm.credence, "representative_name", $$v)},expression:"credence.representative_name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"rules":"max:20","name":"販売責任者名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"20","dense":"","hide-details":"auto","label":"販売責任者名","outlined":"","placeholder":" "},model:{value:(_vm.credence.sales_responsible_name),callback:function ($$v) {_vm.$set(_vm.credence, "sales_responsible_name", $$v)},expression:"credence.sales_responsible_name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"rules":"numeric|max:7","name":"郵便番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"7","dense":"","hide-details":"auto","hint":"ハイフン(-)無しで入力してください","label":"郵便番号","persistent-hint":"","outlined":"","placeholder":" "},model:{value:(_vm.credence.zip_code),callback:function ($$v) {_vm.$set(_vm.credence, "zip_code", $$v)},expression:"credence.zip_code"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"items":_vm.prefList,"label":"都道府県","dense":"","hide-details":"auto","outlined":"","placeholder":" "},model:{value:(_vm.credence.prefecture_code),callback:function ($$v) {_vm.$set(_vm.credence, "prefecture_code", $$v)},expression:"credence.prefecture_code"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"rules":"max:100","name":"住所"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"100","dense":"","hide-details":"auto","label":"住所","outlined":"","placeholder":" "},model:{value:(_vm.credence.address),callback:function ($$v) {_vm.$set(_vm.credence, "address", $$v)},expression:"credence.address"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"rules":{ regex: /^[0-9\-]+$/, max:13},"name":"フリーダイヤル"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"13","dense":"","hide-details":"auto","hint":"ハイフン(-)有りで入力してください","label":"フリーダイヤル","outlined":"","persistent-hint":"","placeholder":" "},model:{value:(_vm.credence.free_dial),callback:function ($$v) {_vm.$set(_vm.credence, "free_dial", $$v)},expression:"credence.free_dial"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"rules":{ regex: /^[0-9\-]+$/, max:13},"name":"電話番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"13","dense":"","hide-details":"auto","hint":"ハイフン(-)有りで入力してください","label":"電話番号","outlined":"","persistent-hint":"","placeholder":" "},model:{value:(_vm.credence.tel),callback:function ($$v) {_vm.$set(_vm.credence, "tel", $$v)},expression:"credence.tel"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{attrs:{"rules":{ regex: /^[0-9\-]+$/, max:13},"name":"FAX番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"13","dense":"","hide-details":"auto","hint":"ハイフン(-)有りで入力してください","label":"FAX番号","outlined":"","persistent-hint":"","placeholder":" "},model:{value:(_vm.credence.fax),callback:function ($$v) {_vm.$set(_vm.credence, "fax", $$v)},expression:"credence.fax"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"rules":"email|max:255","name":"メールアドレス"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"autocomplete":"email","counter":"255","dense":"","hide-details":"auto","label":"メールアドレス","outlined":"","placeholder":" "},model:{value:(_vm.credence.mail_address),callback:function ($$v) {_vm.$set(_vm.credence, "mail_address", $$v)},expression:"credence.mail_address"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"rules":"max:255","name":"URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"255","dense":"","hide-details":"auto","label":"URL","outlined":"","placeholder":" "},model:{value:(_vm.credence.url),callback:function ($$v) {_vm.$set(_vm.credence, "url", $$v)},expression:"credence.url"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"max:255","name":"店舗営業時間"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"255","dense":"","hide-details":"auto","label":"店舗営業時間","outlined":"","placeholder":" "},model:{value:(_vm.credence.business_hours),callback:function ($$v) {_vm.$set(_vm.credence, "business_hours", $$v)},expression:"credence.business_hours"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"max:255","name":"店舗定休日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"255","dense":"","hide-details":"auto","label":"店舗定休日","outlined":"","placeholder":" "},model:{value:(_vm.credence.holiday),callback:function ($$v) {_vm.$set(_vm.credence, "holiday", $$v)},expression:"credence.holiday"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.mdAndDown,"disabled":invalid,"color":"primary"},on:{"click":_vm.submit}},[_vm._v("\n            保存\n          ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }