/**
 * 店舗への問い合わせ（コメント）
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/frontend-types";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/tickets`

const ticket_comment = {
  namespaced: true,
  state: {
    pagination: {
      total_count: 0,
    },
  },
  getters: {},
  actions: {
    findComments ({ commit }, { id, params}) {
      const api_path = `${API_PATH}/${id}/comments`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path, { params: params }).then(data => {
          let content = data.content
          commit(types.FRONT_TICKET_COMMENT, { content })
          resolve(content)
        })
      })
    },
    create ({ commit }, { id, params }) {
      const api_path = `${API_PATH}/${id}/comments`
      return new Promise((resolve, reject) => {
        Vue.$http.post(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
  },
  mutations: {
    [types.FRONT_TICKET_COMMENT] (state, { content }) {
      state.pagination = content.pagination
    },
  },
}

export default ticket_comment
