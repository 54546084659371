<template>
  <v-container
    fluid
    fill-height
  >
    <v-card
      outlined
      class="mx-auto"
      style="width: 40rem;"
    >
      <v-card-title
        class="justify-center"
      >
        <h3 class="text-center">
          クレジットカードの再設定が完了しました。
        </h3>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
export default {
name: "CardSwitchesCompleted"
}
</script>

<style scoped>

</style>