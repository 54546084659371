/**
 * vee-validate
 * https://logaretm.github.io/vee-validate/guide/rules.html#rules
 */
import { ValidationObserver, ValidationProvider, localize, extend } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import * as rules from 'vee-validate/dist/rules'
import Vue from 'vue'

// VeeValidateが用意している全てのルールを適用
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

// カスタムバリデーション

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
localize('ja', ja)
