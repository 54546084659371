<!-- バックヤード > メール送信ダイアログ -->
<template>
  <v-dialog
    v-model="$parent.mailSendDialog"
    width="600"
  >
    <v-card>
      <v-card-title>メール送信</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              dense
              label="件名"
              value="商品発送のお知らせ"
              hide-details="auto"
              outlined
              clearable
              placeholder=" "
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <span class="required">件名</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              outlined
              clearable
              counter
              label="本文"
              value="○○様
この度はご注文ありがとうございました。
商品を発送致しましたのでご連絡致します。

配送業者：ヤマト運輸
時間指定：14〜16時
伝票番号：123-456-7890
配送の詳細は配送業者のHPより追跡を行ってください。
"
              auto-grow
              row-height="15"
              dense
              hide-details="auto"
              placeholder=" "
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <span class="required">本文</span>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$parent.mailSendDialog = false">
          キャンセル
        </v-btn>
        <v-btn
          color="primary"
          @click="$parent.mailSendDialog = false"
        >
          送信
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    components: {
    },
    mixins: [
    ],
    data () {
      return {
      }
    },
    methods: {
    },
  }
</script>

<style scoped>

</style>