<template>
  <div>
    <h3 class="text-center">
      期間延長
      <span class="lang_lbl">{{$t('view.期間延長')}}</span>
    </h3>
    <v-row v-if="content.extend_orders">
      <v-col
        cols="12"
        md="9"
      >
        <v-row dense>
          <v-col
            v-for="item in content.extend_orders"
            :key="item.id"
            cols="12"
            sm="6"
          >
            <order-product
              :item="item"
              :is-edit="true"
              @reCalcTotalPrice="reCalcTotalPrice"
            />
          </v-col>
        </v-row>
      </v-col>


      <v-col
        cols="12"
        md="3"
      >
        <v-card
          tile
          class="mt-2 grey lighten-3"
        >
          <v-card-title>
            注文概要
            <div class="lang_lbl ms-1">{{$t('view.注文概要')}}</div>
          </v-card-title>
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>
                    小計
                    <span class="lang_lbl">{{$t('view.小計')}}</span>
                  </th>
                  <td class="text-right">
                    {{ totalPrice | yen_format }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled="totalPrice <= 0"
              block
              color="primary"
              @click="toNext"
              class="lang_btn"
            >
              <v-icon>mdi-credit-card-outline</v-icon>支払いに進む
              <span class="lang_lbl">{{$t('view.支払いに進む')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        class="text-center"
      >
        延長可能な商品がありません。
        <div class="lang_lbl">{{$t('view.期間延長エラー_1')}}</div>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <v-btn
          outlined
          color="primary"
          :block="$vuetify.breakpoint.mdAndDown"
          @click="moveToHome"
        >
          <v-icon>mdi-home-outline</v-icon>ホームに戻る
          <div class="lang_lbl">{{$t('view.ホームに戻る')}}</div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import OrderProduct from './_OrderProduct'
  import ProductMixin from "../../../../../mixins/product-mixin";
  import CartStepperMixin from '../../../../../mixins/frontend/cart-stepper-mixin'

  export default {
    name: 'ExtendOrderSummary',
    components: {
      OrderProduct,
    },
    mixins: [
      CartStepperMixin,
      ProductMixin
    ],
    data () {
      return {
      }
    },
    computed: {
      ...mapState('frontAuth', {
        loggedIn: 'logged_in'
      }),
      ...mapState('frontExtendOrder', {
        content: 'content',
      }),
      ...mapGetters('frontExtendOrder', [
        'hasExtendOrders',
        'totalPrice'
      ]),
    },
    watch: {},
    mounted() {},
    created() {
      if (!this.hasExtendOrders) {
        this.loadOrderDetails(this.$route.params.id)
      }
    },
    methods: {
      ...mapActions('frontExtendOrder', {
        fetchAll: 'fetchAll',
      }),
      toNext () {
        if (this.loggedIn) {
          // ログイン済みの場合、支払画面に遷移
          this.$emit('toNextStep')
        }else{
          // ログインしていない場合、ログイン画面に繊維
          this.$router.replace({name: 'SessionIndex'}, () => {})
          // this.moveToPage('SessionIndex')
        }
      },
      loadOrderDetails (orderId) {
        this.fetchAll({ order_id: orderId }).then(() => {
        })
      },
      reCalcTotalPrice () {
      },
    },
  }
</script>

<style scoped lang="scss">
</style>