<!-- バックヤード > パスワードを忘れた方 -->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          xs="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="3"
        >
          <v-card>
            <v-card-text>
              <h2
                class="text-center"
              >
                パスワードの再設定が完了しました。
              </h2>
              <p class="text-center">
                再設定したパスワードでログインしてください
              </p>
            </v-card-text>
            <v-card-text>
              <div class="text-center">
                <v-btn
                  @click="backButtonClickEvent"
                >
                  ログイン画面に戻る
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

  export default {
    name: 'ForgetCompleted',
    components: {
    },
    mixins: [
    ],
    data () {
      return {
      }
    },
    created () {
    },
    methods: {
      backButtonClickEvent () {
        this.$router.push({name: 'bySessionIndex'})
      }
    },
  }
</script>

<style lang="scss">
</style>
