<template>
  <v-btn
    small
    outlined
    @click="addressSearch"
  >
    郵便番号検索
  </v-btn>
</template>

<script>
  import { Core as YubinBangoCore } from 'yubinbango-core'

  /**
   * 郵便番号検索
   * <zip-code-search :zip-code="xxxxxxx" @zipAddress="setZipAddress" />
   * methods:
   * setZipAddress (content) {
   *   this.prefectureCode = `${content.pref_code}`
   *   this.address = `${content.locality}${content.street}`
   * }
   */
  export default {
    name: 'ZipCodeSearch',
    props: {
      zipCode: {
        type: String,
        default: ''
      },

    },
    data() {
      return {
      }
    },
    computed: {},
    methods: {
      addressSearch () {
        // 郵便番号検索
        if (!this.zipCode) return
        new YubinBangoCore(this.zipCode, (addr)=> {
          // addr.region_id: 都道府県id
          // addr.locality: 市区町村
          // addr.street: 町域
          const content = {
            pref_code: addr.region_id,
            locality: addr.locality,
            street:  addr.street,
          }

          this.$emit('zipAddress', content)
        })
      }
    }
  }
</script>


<style scoped lang="scss">
</style>