<template>
  <router-view />
</template>

<script>

  export default {
    name: 'App',
    data () {
      return {
      }
    },
  }

</script>

<style lang="scss">
// グローバルスタイルを設定
/* 英語表記用class */
.lang_lbl{
  font-size:10px !important;
  text-transform: none !important;
  /*
  text-transform: capitalize !important;
  */
  &::before{
    content:"（";
  }
  &::after{
    content:"）";
  }
}
/* ボタンに英語表記を追加して、デザインが崩れた場合に追加するv-btn用のクラス */
.lang_btn{
  font-size:13px !important;
  .lang_lbl{
    font-size:8px !important;
  }
}

// date-picker
.v-date-picker-table {
  &.v-date-picker-table--date {
    table {
      tbody {
        tr {
          td:nth-child(7) {
            .v-btn__content {
              color:blue
            }
          }
          td:nth-child(1) {
            .v-btn__content {
              color:red
            }
          }
        }
      }
    }
  }
}

// data-table width
.v-data-table__wrapper {
  width: 100%;
}

.line-break {
  white-space:pre-wrap;
  word-wrap:break-word;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}

.required {
  color: red;
}
.required:after {
  content: '※';
}

.requiredMark {
  .v-label:after {
    content: '※';
    color:red;
  }
}

.link-color {
  color: #007185;
}

// <========== 背景画像コンテンツで利用

.text_align_left {
  text-align: left;
}

.text_align_center {
  text-align: center;
}

.text_align_right {
  text-align: right;
}

// ==========>

.carouseBackGround {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
$position-size: -15px;
$filter-size: 15px;

.carouseBackGround::before {
  content: '';
  position: absolute;
  /* 輪郭がぼやけてしまうのでブラー範囲を広げる */
  top: $position-size;
  bottom: $position-size;
  left: $position-size;
  right: $position-size;
  background: inherit;
  filter: blur($filter-size) brightness(0.6);
}

.fitLabel label {
  display: inline-block !important;
  flex: none !important;
}


</style>
