<!-- バックヤード > 書類テンプレート編集 -->
<template>
  <div>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn @click="templateDocumentCancel">
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            @click="templateDocumentSave"
          >
            保存
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <Form />

    <v-row>
      <v-col cols="6" />
      <v-col cols="6">
        <div class="float-right">
          <v-btn @click="templateDocumentCancel">
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            @click="templateDocumentSave"
          >
            保存
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import Form from "./_Form";

  export default {
    components: {
      Form,
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
      }
    },
    methods: {
      templateDocumentCancel: function () {
        this.$router.push({name: 'byTemplateDocumentIndex'})
      },
      templateDocumentSave: function () {
        this.$router.push({name: 'byTemplateDocumentIndex'})
      },
    },
  }
</script>

<style lang="scss">

</style>
