/**
 * ユーザ管理
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/users`

const user = {
  namespaced: true,
  state: {
    user: null,
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {
      keyword: '',
      role: 'all'
    },
  },
  getters: {
    hasUsers: ( state ) => {
      return (state.pagination.total_count > 0)
    },
  },
  actions: {
    fetchUsers({ dispatch, commit, state }, { keyword, role, page}) {
      const queries = {
        keyword: keyword,
        role: role,
      }

      dispatch('fetchQuery', queries)

      const params = {
        keyword: state.query.keyword,
        role: state.query.role,
        page: page
      }

      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_USER, { content })
          resolve(data.content)
        })
      })
    },
    fetchQuery({ commit, state}, { keyword, role }) {
      const keywordText = (keyword !== undefined)? keyword : state.query.keyword
      const userRole = (role !== undefined) ? role: state.query.role
      const params = {
        keyword: keywordText,
        role: userRole
      }
      commit(types.BACK_USER_QUERY, { params })
    },
    fetch ({ commit, state }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    new ({ _commit }) {
      const api_path = `${API_PATH}/new`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    edit({ _commit, state }, { id }) {
      const api_path = `${API_PATH}/${id}/edit`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    create( { commit, state }, { params } ) {

      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(API_PATH, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    update( {commit, state}, { id, params } ) {

      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    destroy( { commit, state }, { id } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.delete(api_path).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
    sendOpeningMail( { commit, state }, { id } ) {
      const api_path = `${API_PATH}/${id}/send_opening_mail`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.post(api_path).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '送信しました' }, { root: true })
          resolve(content)
        } )
      })
    }
  },
  mutations: {
    [types.BACK_USER] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_USER_QUERY] (state, { params }) {
      state.query.keyword = params.keyword
      state.query.role = params.role
    },
  },
}

export default user