<!-- バックヤード > システムへの問い合わせ追加 -->
<template>
  <div>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn @click="systemInquiryCancel">
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            @click="systemInquirySave"
          >
            送信
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col>
            <v-textarea
              outlined
              clearable
              counter
              label="問い合わせ内容"
              auto-grow
              row-height="15"
              dense
              hide-details="auto"
              placeholder=" "
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn @click="systemInquiryCancel">
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            @click="systemInquirySave"
          >
            送信
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";

  export default {
    components: {
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
      }
    },
    methods: {
      systemInquiryCancel: function () {
        this.$router.push({name: 'bySystemInquiryIndex'})
      },
      systemInquirySave: function () {
        this.$router.push({name: 'bySystemInquiryIndex'})
      },
    },
  }
</script>

<style lang="scss">

</style>
