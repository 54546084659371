<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>Trade Law<span class="subtitle-2 ml-4">特定商取引法に基づく表示</span></h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <v-card flat>
      <v-card-text>
        <v-row
          v-for="(item, index) in tradeLaws"
          :key="index"
        >
          <v-col
            cols="12"
            sm="3"
            class="grey lighten-3"
          >
            <span class="font-weight-bold">{{ item.title }}</span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
          >
<!--            <p v-html="autoLink(item.description)"></p>-->
            <vue-editor
                :value="item.description"
                :disabled="true"
                class="custom-quill-container"
            />
            
          </v-col>
          <v-col
            cols="12"
            class="px-0 py-2"
          >
            <v-divider />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import _ from 'lodash'
  export default {
    name: 'TradeLaw',
    props: {
      tradeLaws: {
        type: Array,
        default: () => {},
      },
    },
    methods: {
      /**
       * @param {String} text
       */
      autoLink(text) {
        return _.isString(text) ? text.replace(/(https?:\/\/[^\s]*)/g, "<a href='$1'>$1</a>") : '';
      },
    }
  }
</script>

<style scoped lang="scss">
.custom-quill-container {
  /deep/ {
    .ql-toolbar {
      display: none;
    }
    .ql-snow {
      border: none !important;
    }
    .ql-editor{
      padding:0 !important;
      min-height:50px !important;
    }
  }
}
</style>