<!-- 会員登録メールアドレス認証URLを2回以上叩いた時用の画面 -->
<template>
  <v-container
    fluid
    fill-height
  >
    <v-card
      outlined
      class="mx-auto"
      style="width: 40rem;"
    >
      <v-card-title
        class="justify-center"
      >
        <h3 class="text-center">
          本処理は完了しております。
          <div class="lang_lbl">{{ $t('view.本処理は完了しております') }}</div>
        </h3>
      </v-card-title>
      <v-card-text>
        <div class="text-center">
          設定したパスワードでログインしてください
        </div>
        <div class="text-center lang_lbl">{{ $t('view.設定したパスワードでログインしてください') }}</div>
        <div class="text-center mt-5">
          <v-btn
            @click="backButtonClickEvent"
          >
            ログイン画面に戻る
            <div class="lang_lbl">{{ $t('view.ログイン画面に戻る') }}</div>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";

export default {
  name: 'ActivateCompletedAfter',
  components: {
  },
  mixins: [
      PageHeaderMixin
  ],
  data () {
    return {
      token: null,
    }
  },
  created () {
  },
  methods: {
    backButtonClickEvent () {
      this.$router.push({name: 'HomeIndex'})
    }
  },
}
</script>

<style scoped lang="scss">
</style>
