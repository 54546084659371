<!-- バックヤード > タグ編集 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />

    <tag-form
      v-if="tag"
      :tag="tag"
      :is-edit="true"
    />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import {mapActions} from "vuex";
  import TagForm from './_Form'

  export default {
    name: 'TagsEdit',
    components: {
      TagForm
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        breadcrumbs: [
          { text: 'タグ一覧', disabled: false, to: '/backyard/tags', exact: true },
          { text: 'タグ編集', disabled: true, to: '' },
        ],
        tag: null,
      }
    },
    created() {
      this.editTag({id: this.$route.params.id}).then((content) => {
        this.tag = content
      })
    },
    methods: {
      ...mapActions('backTag', {
        editTag: 'edit'
      })
    },
  }
</script>

<style lang="scss">

</style>
