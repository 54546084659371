<template>
  <v-container :style="btn_styles">
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />

    <template v-if="this.noHandling">
      <v-col
        cols="12"
        class="text-center"
      >
        こちらの商品は現在お取扱いしておりません。
        <div class="lang_lbl">{{ $t('view.こちらの商品は現在お取扱いしておりません') }}</div>
      </v-col>
    </template>

    <template v-else-if="product">
      <v-row>
        <v-col
          cols="12"
          md="5"
        >
          <v-carousel
            v-model="productImageIndex"
            hide-delimiters
          >
            <v-carousel-item
              v-for="(item,i) in product.images"
              :key="i"
            >
              <v-sheet
                color="white"
                height="100%"
              >
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-img
                    contain
                    height="100%"
                    :src="item.url | load_image_url"
                  />
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
          <v-divider />
          <v-row>
            <v-col
              v-for="(item, index) in product.images"
              :key="item.id"
              cols="3"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  tile
                  :elevation="hover ? 12 : 2"
                  class="pa-1 ma-1"
                  @click="changeProductImage(index)"
                >
                  <v-img
                    contain
                    :src="item.url | load_image_url"
                    :lazy-src="item.url | load_image_url"
                    aspect-ratio="1"
                    class="white lighten-2 align-center"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <v-row v-if="isCatalogs">
            <v-col
              cols="12"
              class="pb-0"
            >
              <h4>カタログ<span class="lang_lbl">{{ $t('view.カタログ') }}</span></h4>
              <v-divider class="mt-2" />
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-data-table
                :items="product.catalogs"
                hide-default-footer
                hide-default-header
              >
                <template v-slot:item="{ item }">
                  <tr
                    style="cursor: pointer"
                    @click="openCatalog(item)"
                  >
                    <td>{{ item.name }}</td>
                    <td
                      class="text-right"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="isVideos">
            <v-col cols="12">
              <h4>動画<span class="lang_lbl">{{ $t('view.動画') }}</span></h4>
              <v-divider class="mt-2" />
            </v-col>
            <v-col
              v-for="item in product.videos"
              :key="item.id"
              cols="12"
              class="py-0 text-center"
            >
              <div>
                <youtube
                  :fit-parent="true"
                  :video-id="youtubeId(item.url)"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <div>
            <h1 class="display-1 mb-0">
              {{ product.name }}
            </h1>

            <v-chip-group
              column
              multiple
            >
              <v-chip
                v-for="category in product.categories"
                :key="category.id"
                :value="category.id"
                class="ml-1"
                color="#db7093"
                label
                outlined
                small
              >
                {{ category.name }}
              </v-chip>
            </v-chip-group>

            <v-chip-group
              column
              multiple
            >
              <v-chip
                v-for="tag in product.tags"
                :key="tag.id"
                :color="tag.background_color"
                :text-color="tag.font_color"
                :value="tag.id"
                class="ml-1"
                label
                small
              >
                {{ tag.name }}
              </v-chip>
            </v-chip-group>

            <div class="pt-3">
              <v-row align="center">
                <v-col
                  cols="12"
                  md="7"
                  class="headline"
                >
                  <template v-if="displayDiscountPrice > 0">
                    <template v-if="paymentType == 'subscription_payment'">
                      {{menuTitle}} {{ displayDiscountPrice | in_tax_yen_format(product.tax_exempt) }} {{ this.cycleLabel() }} {{ this.cycleLabel() }}
                    </template>
                    <template v-else-if="product.price_type == 'daily_price' && paymentType != 'sale_payment'">
                      {{menuTitle}} {{ displayDiscountPrice | in_tax_yen_format(product.tax_exempt) }} {{ this.cycleLabel() }} / {{ rentalDays }}日
                    </template>
                    <template v-else>
                      {{menuTitle}} {{ displayDiscountPrice | in_tax_yen_format(product.tax_exempt) }} {{ this.cycleLabel() }}
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="paymentType == 'subscription_payment'">
                      {{menuTitle}} {{ displayPrice | in_tax_yen_format(product.tax_exempt) }} {{ this.cycleLabel() }}
                    </template>
                    <template v-else-if="product.price_type == 'daily_price' && paymentType != 'sale_payment'">
                      {{menuTitle}} {{ displayPrice | in_tax_yen_format(product.tax_exempt) }} {{ this.cycleLabel() }} / {{ rentalDays }}日
                    </template>
                    <template v-else>
                      {{menuTitle}} {{ displayPrice | in_tax_yen_format(product.tax_exempt) }} {{ this.cycleLabel() }}
                    </template>
                  </template>
                </v-col>
                <v-col
                  v-if="isProductReviews"
                  cols="12"
                  md="5"
                  class="text-right"
                >
                  <v-rating
                    v-model="product.average_evaluation"
                    readonly
                    dense
                  />
                  <v-btn
                    text
                    small
                    color="blue darken-1"
                    @click="$vuetify.goTo('#product_rating')"
                  >
                    レビュー<span class="lang_lbl">{{ $t('view.レビュー') }}</span>({{ product.review_count }}件)
                  </v-btn>
                </v-col>
              </v-row>
            </div>
<!--            <v-row>-->
<!--              <v-col cols="12">-->
<!--                <span class="body-2 mb-1 line-break">{{product.description}}</span>-->
<!--              </v-col>-->
<!--            </v-row>-->
            <v-row>
              <v-col cols="12">
                <span class="body-2 mb-1"><vue-editor
                        v-model="product.description"
                        :disabled="true"
                        class="custom-quill-container body-2"
                    /></span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="cart_btn"

                  :block="$vuetify.breakpoint.mdAndDown"
                  :disabled="(!product.in_stock || isStartDateHoliday || isEndDateHoliday) && !product.use_calendar"
                  @click="addCart"
                >
                  <v-icon>mdi-cart</v-icon>
                  <template v-if="product.local_stock_item && paymentType != 'sale_payment'">
                    レンタル申し込み
                    <div class="lang_lbl">{{$t('view.レンタル申し込み')}}</div>
<!--                    現地貸出申し込み-->
<!--                    <div class="lang_lbl">{{ $t('view.現地貸出申し込み') }}</div>-->
                  </template>
                  <template v-else-if="product.local_stock_item && paymentType == 'sale_payment'">
                    購入申し込み
                    <div class="lang_lbl">{{$t('view.購入申し込み')}}</div>
<!--                    現地購入申し込み-->
<!--                    <div class="lang_lbl">{{ $t('view.現地購入申し込み') }}</div>-->
                  </template>
                  <template v-else-if="paymentType != 'sale_payment'">
                    レンタル申し込み
                    <div class="lang_lbl">{{$t('view.レンタル申し込み')}}</div>
                  </template>
                  <template v-else>
                    購入申し込み
                    <div class="lang_lbl">{{$t('view.購入申し込み')}}</div>
                  </template>
                </v-btn>
              </v-col>
            </v-row>

            <v-row
              v-for="item in product.anyting_items"
              :key="item.id"
              class="mx-1"
            >
              <v-col
                cols="12"
                class="px-0 py-1"
              >
                <v-divider />
              </v-col>
              <v-col
                cols="12"
                sm="3"
                class="grey lighten-3"
              >
                <span class="body-2">{{ item.title }}</span>
              </v-col>
              <!--
              <v-col
                cols="12"
                sm="9"
                class="body-2 line-break"
              >{{ item.value }}</v-col>
              -->
              <v-col cols="12" sm="9" class="body-2 free-col">
                <vue-editor
                    :value="item.value"
                    :disabled="true"
                    class="custom-quill-container body-2"
                />
              </v-col>
            </v-row>

            <template v-if="paymentType == 'one_time_payment'">
              <template v-if="product.price_type=='daily_price'">
                <v-row class="mx-1">
                  <v-col
                    cols="12"
                    class="px-0 py-1"
                  >
                    <v-divider />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="3"
                    class="grey lighten-3"
                  >
                    <span class="body-2">
                      金額
                      <span class="lang_lbl">{{$t('view.金額')}}</span>
                    </span>

                  </v-col>
                  <v-col
                    cols="12"
                    sm="9"
                    class="body-2"
                  >
                    <template v-if="displayDiscountPrice > 0">
                      <s>
                        {{ displayPrice | in_tax_yen_format(product.tax_exempt) }}
                      </s>
                    </template>
                    <template v-else>
                      {{ displayPrice | in_tax_yen_format(product.tax_exempt) }}
                    </template>
                  </v-col>
                </v-row>
              </template>
            </template>

            <template v-else-if="paymentType == 'subscription_payment'">
              <v-row class="mx-1">
                <v-col
                  cols="12"
                  class="px-0 py-1"
                >
                  <v-divider />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="grey lighten-3"
                >
                  <span class="body-2">
                    金額
                    <span class="lang_lbl">{{$t('view.金額')}}</span>
                  </span>

                </v-col>
                <v-col
                  cols="12"
                  sm="9"
                  class="body-2"
                >
                  <template v-if="displayDiscountPrice > 0">
                    <s>
                      {{ displayPrice | in_tax_yen_format(product.tax_exempt) }} {{ this.cycleLabel() }}
                    </s>
                  </template>
                  <template v-else>
                    {{ displayPrice | in_tax_yen_format(product.tax_exempt) }} {{ this.cycleLabel() }}
                  </template>

                  <small style="margin-bottom: 0;">
                    <br>
                    ご利用日数経過後は自動継続されます。
                    <div class="lang_lbl">{{$t('view.ご利用日数経過後は自動継続されます')}}</div>
                  </small>
                </v-col>
              </v-row>
            </template>

            <template v-else-if="paymentType == 'sale_payment'">
              <v-row class="mx-1" v-if="displayPrice > 0">
                <v-col
                  cols="12"
                  class="px-0 py-1"
                >
                  <v-divider />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="grey lighten-3"
                >
                  <span class="body-2">
                    販売価格
                    <div class="lang_lbl">{{$t('view.販売価格')}}</div>
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  sm="9"
                  class="body-2"
                >
                  <template v-if="displayDiscountPrice > 0">
                    <s>
                      {{ displayPrice | in_tax_yen_format(product.tax_exempt) }}
                    </s>
                  </template>
                  <template v-else>
                    {{ displayPrice | in_tax_yen_format(product.tax_exempt) }}
                  </template>
                </v-col>
              </v-row>
            </template>
            <v-row class="mx-1" v-if="displayDiscountPrice > 0">
              <v-col
                cols="12"
                class="px-0 py-1"
              >
                <v-divider />
              </v-col>
              <v-col
                cols="12"
                sm="3"
                class="grey lighten-3"
              >
                <span class="body-2">{{product.discount_title}}</span>
              </v-col>
              <v-col
                cols="12"
                sm="9"
                class="body-2"
              >
                {{ displayDiscountPrice | in_tax_yen_format(product.tax_exempt) }} {{ this.cycleLabel() }}
              </v-col>
            </v-row>

            <validation-observer
              ref="orderFormValidation"
            >
              <v-row
                v-if="paymentType != 'sale_payment'"
                class="mx-1"
              >
                <v-col
                  cols="12"
                  class="px-0 py-1"
                >
                  <v-divider />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="grey lighten-3"
                >
                  <span class="body-2">
                    <template v-if="paymentType == 'subscription_payment'">
                      最低レンタル期間
                      <div class="lang_lbl">{{$t('view.最低レンタル期間')}}</div>
                    </template>
                    <template
                      v-else
                    >
                      ご利用日数
                      <div class="lang_lbl">{{$t('view.ご利用日数')}}</div>
                    </template>
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  sm="9"
                  class="body-2"
                >
                  <template v-if="paymentType == 'subscription_payment'">
                    <div class="body-2">
                      {{ subscriptionRentalMinimumPeriod }}
                      {{ subscriptionBillingCycle == 'cycle_month' ? 'ヶ月('  + $t('view.ヶ月') + ')' : '年(' + $t('view.年') + ')' }}
                    </div>
                  </template>
                  <template v-else-if="product.price_type == 'daily_price'">
                    <template v-if="product.changed_rental_period">
                      <v-row>
                        <v-col
                          cols="4"
                          md="3"
                          class="py-0"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|numeric"
                            :name="'ご利用日数(' + $t('view.ご利用日数') + ')'"
                          >
                            <v-text-field
                              v-model="rentalDays"
                              dense
                              hide-details="auto"
                              outlined
                              reverse
                              required
                              :prefix="product.rental_unit | rentalUnitFilter"
                              :error-messages="errors"
                              class="body-2"
                              @change="calcDisplayPrice"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col
                          cols="8"
                          md="9"
                          align-self="center"
                          v-if="!product.local_stock_item"
                        >
                          {{ rentalDays | rental_night_format }}
                        </v-col>
                        <v-col
                          cols="12"
                          class="pt-0"
                        >
                          {{ product.rental_minimum_days || 0 | rentalMinUnitFilter(product.rental_unit) }} / {{ product.rental_maximum_days || 0 | rentalMaxUnitFilter(product.rental_unit) }}
                        </v-col>
                      </v-row>
                    </template>
                    <template v-else>
                      {{ rentalDays }}{{ product.rental_unit | rentalUnitFilter }}
                      <template v-if="!product.local_stock_item">
                        <span>{{ rentalDays | rental_night_format }}</span>
                      </template>
                    </template>
                  </template>
                  <template v-else-if="product.price_type=='menu_price'">
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="productPriceId"
                          :items="product.prices"
                          item-value="id"
                          item-text="name"
                          dense
                          label=""
                          hide-details="auto"
                          outlined
                          placeholder=" "
                          class="body-2"
                          v-on:change="selectedPrice"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>


              <v-row class="mx-1">
                <v-col
                  cols="12"
                  class="px-0 py-1"
                >
                  <v-divider />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="grey lighten-3"
                >
                  <span class="body-2">
                    数量
                    <div class="lang_lbl">{{$t('view.数量')}}</div>
                  </span>
                </v-col>
                <v-col
                  v-if="paymentType == 'one_time_payment' || paymentType == 'sale_payment'"
                  cols="12"
                  sm="9"
                  class="body-2 py-0"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|numeric"
                    :name="'数量(' + $t('view.数量') + ')'"
                  >
                    <v-row>
                      <v-col
                        cols="4"
                        md="3"
                      >
                        <v-text-field
                          v-model="quantity"
                          dense
                          single-line
                          reverse
                          hide-details="auto"
                          outlined
                          required
                          :error-messages="errors"
                          class="body-2"
                          @change="calcDisplayPrice"
                        />
                      </v-col>
                      <v-col
                        v-if="!product.in_stock && !product.use_calendar"
                        cols="12"
                        class="pt-0 red--text"
                      >
                        在庫なし
                        <span class="lang_lbl">{{$t('view.在庫なし')}}</span>

                      </v-col>
                    </v-row>
                  </validation-provider>
                </v-col>

                <v-col
                  v-else
                  cols="12"
                  sm="9"
                  class="body-2"
                >
                  <div class="body-2">
                    {{ quantity }}
                  </div>
                </v-col>

                <v-col
                  v-if="paymentType == 'sale_payment'"
                  cols="12"
                  class="px-0 py-1"
                >
                  <v-divider />
                </v-col>

              </v-row>


              <v-row class="mx-1" v-if="paymentType != 'sale_payment'">
                <v-col
                  cols="12"
                  class="px-0 py-1"
                >
                  <v-divider />
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="grey lighten-3"
                >
                  <span class="body-2">
                    ご利用開始日
                    <div class="lang_lbl">{{$t('view.ご利用開始日')}}</div>
                  </span>
                </v-col>

                <validation-provider
                >

                  <v-col
                    cols="12"
                    sm="12"
                    class="body-2"
                  >
                    <v-menu
                      ref="menu"
                      v-model="scheduleDateMenu"
                      min-width="290px"
                      :close-on-content-click="false"
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="scheduleDate"
                          readonly
                          outlined
                          dense
                          hide-details="auto"
                          v-bind="attrs"
                          v-on="on"
                          class="body-2"
                          @click:clear="scheduleDate = null"
                        />
                      </template>
                      <v-date-picker
                          v-model="scheduleDate"
                          :min="minScheduleDate"
                          :max="maxScheduleDate"
                          @input="scheduleDateMenu = false"
                      >
<!--                      <v-date-picker-->
<!--                        v-model="scheduleDate"-->
<!--                        :min="minScheduleDate"-->
<!--                        :max="maxScheduleDate"-->
<!--                        :day-format="scheduleDate => new Date(scheduleDate).getDate()"-->
<!--                      >-->
<!--                        <v-spacer />-->
<!--                        <v-btn-->
<!--                          text-->
<!--                          color="primary"-->
<!--                          @click="scheduleDateMenu = false"-->
<!--                        >-->
<!--                          Cancel-->
<!--                        </v-btn>-->
<!--                        <v-btn-->
<!--                          text-->
<!--                          color="primary"-->
<!--                          @click="$refs.menu.save(scheduleDate)"-->
<!--                        >-->
<!--                          OK-->
<!--                        </v-btn>-->
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    v-if="isStartDateHoliday"
                    cols="12"
                    class="pt-0 red--text"
                  >
                    定休日の為、利用できません。
                    <div class="lang_lbl">{{$t('view.定休日の為、利用できません')}}</div>
                  </v-col>
                </validation-provider>

                <v-col
                  cols="12"
                  class="px-0 py-1"
                >
                  <v-divider />
                </v-col>

              </v-row>

              <v-row
                v-if="product.payment_type == 'one_time_payment'"
                class="mx-1"
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="grey lighten-3"
                >
                  <span class="body-2">
                    ご利用終了日
                    <div class="lang_lbl">{{$t('view.ご利用終了日')}}</div>
                  </span>
                </v-col>

                <validation-provider>
                  <v-col
                    cols="12"
                    sm="12"
                    class="body-2"
                  >
                    <div
                      class="body-2"
                    >
                      {{ returnScheduleDate }}
                    </div>
                  </v-col>

                  <v-col
                    v-if="isEndDateHoliday"
                    cols="12"
                    class="pt-0 red--text"
                  >
                    定休日の為、利用できません。
                    <div class="lang_lbl">{{$t('view.定休日の為、利用できません')}}</div>
                  </v-col>
                </validation-provider>

                <v-col
                  cols="12"
                  class="px-0 py-1"
                >
                  <v-divider />
                </v-col>

              </v-row>

              <v-row v-if="product.options && product.options.length > 0">
                <v-col
                  cols="12"
                  class="pt-0"
                >
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            class="text-left body-2 black--text"
                          >
                            オプション名
                            <span class="lang_lbl">{{$t('view.オプション名')}}</span>
                          </th>
                          <th
                            class="text-right body-2 black--text"
                          >
                            金額
                            <span class="lang_lbl">{{$t('view.金額')}}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in optionItems"
                          :key="item.id"
                        >
                          <td>
                            <v-checkbox
                              v-model="selectedOptionIds"
                              :label="item.name"
                              :value="item.id"
                              class="body-2"
                            >
                              <template v-slot:label>
                                <span class="body-2 black--text">{{ item.name }}</span>
                              </template>
                            </v-checkbox>
                          </td>
                          <td class="text-right">
                            {{ item.price | yen_format }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-list two-line>
                    <template v-for="product_option in productOptions">
                      <v-list-item
                        :key="product_option.option_group.id"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ product_option.option_group.name }}
                            <span
                              v-if="product_option.option_group.required"
                              class="red--text text-body-2"
                            >
                              必須
                              <span class="lang_lbl">{{$t('view.必須')}}</span>
                            </span>&nbsp;
                            <a
                              v-if="product_option.option_group.enable_button"
                              href="javascript: void(0);"
                              @click="showOptionGroupDialog(product_option.option_group)"
                            >
                              {{ product_option.option_group.button_caption }}
                            </a>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <validation-provider
                              v-slot="{ errors }"
                              v-bind:name="product_option.option_group.name"
                              :rules="{required: product_option.option_group.required }"
                            >
                              <v-select
                                v-model="product_option.option_item_id"
                                :items="product_option.option_group.option_items"
                                item-text="name"
                                item-value="id"
                                clearable
                                dense
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                class="body-2"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-col>
              </v-row>


              <v-row>
                <v-col cols="12">
                  <v-btn
                    class="cart_btn"
                    :block="$vuetify.breakpoint.mdAndDown"
                    :disabled="(!product.in_stock || isStartDateHoliday || isEndDateHoliday) && !product.use_calendar"
                    @click="addCart"
                  >
                    <v-icon>mdi-cart</v-icon>
                    <template v-if="product.local_stock_item && paymentType != 'sale_payment'">
                      レンタル申し込み
                      <div class="lang_lbl">{{$t('view.レンタル申し込み')}}</div>
                      <!--                    現地貸出申し込み-->
                      <!--                    <div class="lang_lbl">{{ $t('view.現地貸出申し込み') }}</div>-->
                    </template>
                    <template v-else-if="product.local_stock_item && paymentType == 'sale_payment'">
                      購入申し込み
                      <div class="lang_lbl">{{$t('view.購入申し込み')}}</div>
                      <!--                    現地購入申し込み-->
                      <!--                    <div class="lang_lbl">{{ $t('view.現地購入申し込み') }}</div>-->
                    </template>
                    <template v-else-if="paymentType != 'sale_payment'">
                      レンタル申し込み
                      <div class="lang_lbl">{{$t('view.レンタル申し込み')}}</div>
                    </template>
                    <template v-else>
                      購入申し込み
                      <div class="lang_lbl">{{$t('view.購入申し込み')}}</div>
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </validation-observer>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="isComponentProducts">
        <v-col cols="12">
          <h3>セット内容<span class="lang_lbl">{{$t('view.セット内容')}}</span></h3>
          <v-divider class="mt-2" />
        </v-col>
        <v-col cols="12">
          <v-row dense>
            <v-col
              v-for="item in componentProducts"
              :key="item.id"
              cols="6"
              md="2"
            >
              <v-card class="grey lighten-4">
                <v-img
                  height="200px"
                  contain
                  :src="item.image_url | load_image_url"
                  :lazy-src="item.image_url | load_image_url"
                  aspect-ratio="1"
                  class="white lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
                <v-card-subtitle align="center">
                  {{ item.name }}
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <other-product
        v-if="isRelationProducts"
        :title="'関連商品(' + $t('view.関連商品') + ')'"
        :other-products="relationProducts"
      />

      <v-row v-if="isProductQuestions">
        <v-col cols="12">
          <h3>
            Q&A
            <span class="float-right">
              <v-btn
                v-if="loggedIn"
                small
                outlined
                color="primary"
                @click="openQuestion"
              >
                この商品に関する質問を投稿する
                <span class="lang_lbl">{{$t('view.この商品に関する質問を投稿する')}}</span>
              </v-btn>
            </span>
          </h3>
          <v-divider class="mt-2" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <div align="end">
            <v-btn
              v-if="loggedIn"
              small
              outlined
              color="primary"
              :block="$vuetify.breakpoint.mdAndDown"
              @click="openQuestion"
            >
              この商品に関する質問を投稿する
              <span class="lang_lbl">{{$t('view.この商品に関する質問を投稿する')}}</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-list subheader>
        <template
          v-for="(item, index) in productQuestions"
        >
          <v-row :key="item.id">
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="1"
                  class="text-center"
                >
                  <v-icon>
                    mdi-account
                  </v-icon>
                  <p class="text-sm-caption">
                    お客様
                    <span class="lang_lbl">{{$t('view.お客様')}}</span>
                  </p>
                </v-col>
                <v-col cols="11">
                  <v-card
                    elevation="2"
                    color="#FAFAFA"
                  >
                    <v-card-text>
                      <span class="line-break">{{ item.question_content }}</span>
                    </v-card-text>
                  </v-card>
                  <p class="text-right caption">
                    {{ item.question_at }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="1"
                  class="text-center"
                >
                  <v-icon>
                    mdi-store
                  </v-icon>
                  <p class="text-sm-caption">
                    ストア
                      <span class="lang_lbl">{{$t('view.ストア')}}</span>
                  </p>
                </v-col>
                <v-col cols="11">
                  <v-card
                    elevation="2"
                  >
                    <v-card-text>
                      <span class="line-break">{{ item.answer_content }}</span>
                    </v-card-text>
                  </v-card>
                  <p class="text-right caption">
                    {{ item.answer_at }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider
            :key="`question-${index}`"
            class="ml-4"
          />
        </template>
        <div
          v-if="isProductQuestions"
          class="mt-4"
        >
          <v-btn
            v-if="!qaPagination.is_last_page"
            text
            color="primary"
            @click="moreProductQuestions"
          >
            さらに表示する<span class="lang_lbl">{{ $t('view.さらに表示する') }}</span>
          </v-btn>
        </div>
      </v-list>
      <template v-if="isProductReviews">
        <v-row
          id="product_rating"
        >
          <v-col cols="12">
            <h3>レビュー<span class="lang_lbl">{{ $t('view.レビュー') }}</span></h3>
            <v-divider class="mt-2" />
          </v-col>
        </v-row>
        <v-list
          subheader
          three-line
        >
          <template v-for="(item, index) in productReviews">
            <v-list-item :key="item.id">
              <v-list-item-avatar size="40">
                <v-icon large>
                  mdi-account-circle
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-rating
                  v-model="item.evaluation"
                  small
                  readonly
                  dense
                />
                <v-list-item-subtitle class="mb-2">
                  {{ item.review_date | date_format }}<div class="caption">
                    {{ item.bundle_name }}
                  </div>
                </v-list-item-subtitle>
                <div class="body-2">
                  <span class="line-break">{{ item.comment }}</span>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              :key="`review-${index}`"
              inset
            />
          </template>
          <div
            v-if="isProductReviews"
            class="mt-4"
          >
            <v-btn
              v-if="!reviewPagination.is_last_page"
              text
              color="primary"
              @click="moreProductReviews"
            >
              さらに表示する<span class="lang_lbl">{{ $t('view.さらに表示する') }}</span>
            </v-btn>
          </div>
        </v-list>
      </template>
    </template>
    <confirm ref="confirm" />

    <OptionGroupDialog
      ref="OptionGroupDialog"
      @optionGroupDialogCallback="optionGroupDialogCallback"
    />

    <!--  Q&Aダイアログ  -->
    <v-dialog
      v-model="questionDialog"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :transition="($vuetify.breakpoint.xsOnly) ? 'dialog-bottom-transition' : ''"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-subtitle class="pa-0">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="questionDialog=false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title align="center">
              商品<span class="lang_lbl">{{ $t('view.商品') }}</span>Q&A
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
        </v-card-subtitle>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <validation-observer
                  ref="observer"
                  v-slot="{ invalid }"
                >
                  <v-card-text>
                    <v-row align="center">
                      <v-col
                        v-if="product.images[0]"
                        cols="4"
                        align="center"
                      >
                        <v-avatar
                          tile
                          size="80"
                          color="white"
                        >
                          <v-img
                            :src="product.images[0].url | load_image_url"
                            :lazy-src="product.images[0].url | load_image_url"
                            contain
                          />
                        </v-avatar>
                      </v-col>
                      <v-col cols="8">
                        <div class="subtitle-1">
                          {{ product.name }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="pt-4">
                      <v-col
                        cols="12"
                        class="pb-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:200"
                          :name="'質問内容(' + $t('view.質問内容') + ')'"
                          class="requiredMark"
                        >
                          <v-textarea
                            v-model="questionContent"
                            outlined
                            :label="'質問内容(' + $t('view.質問内容') + ')'"
                            :placeholder="'質問内容を入力してください。(' + $t('multi.入力してください', { item: $t('view.質問内容') }) + ')'"
                            value=""
                            counter="200"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      block
                      outlined
                      :disabled="invalid"
                      @click="submitQuestion"
                    >
                      <v-icon>mdi-post-outline</v-icon>
                      投稿する<span class="lang_lbl">{{ $t('view.投稿する') }}</span>
                    </v-btn>
                  </v-card-actions>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import {mapState, mapActions, mapGetters} from 'vuex'
  import ProductMixin from '../../../mixins/product-mixin'
  import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";
  import Confirm from '../../../components/Confirm'
  import OtherProduct from './_OtherProduct'
  import moment from 'moment'
  import OptionGroupDialog from './_OptionGroupDialog'
  export default {
    name: 'ProductShow',
    components: {
      Confirm,
      OtherProduct,
      OptionGroupDialog
    },
    mixins: [
      ProductMixin,
      PageHeaderMixin,
    ],
    props: {
      cart: {
        type: Object,
        default: () => {},
      }
    },
    data () {
      return {
        pageTitle: null,
        companyName: null,
        scheduleDateMenu: false,
        scheduleDate: null,
        returnScheduleDate: null,
        minScheduleDate: null,
        maxScheduleDate: null,
        breadcrumbs: [
          { text: "ホーム", disabled: false, href: '/' },
          { text: "商品一覧", disabled: false, to: '/products', exact: true },
          { text: "商品詳細", disabled: true, to: '' },
        ],
        productImageIndex: 0,
        quantity: 1,
        rentalDays: null,
        displayPrice: null,
        displayDiscountPrice: null,
        productPriceId: null,
        questionDialog: false,
        questionContent: null,
        productQuestions: [],
        questionPage: 0,
        productReviews: [],
        reviewPage: 0,
        selectedOptionIds: [],
        optionItems: null,
        productOptions:[],
        menuTitle: null,
        menuPrice: 0,
        menuDiscountPrice: 0,
        product_option: {
          id: null,
          option_group_id: null,
          option_item_id: null,
          option_group: null
        },
        paymentType: null,
        subscriptionId: null,
        subscriptionBillingCycle: null,
        subscriptionRentalMinimumPeriod: null,
        isStartDateHoliday: false,
        isEndDateHoliday: false,
        noHandling: false,
      }
    },
    computed: {
      ...mapState('frontAuth', {
        loggedIn: 'logged_in'
      }),
      ...mapState('frontProductQuestion', {
        qaPagination: 'pagination',
      }),
      ...mapState('frontProductReview', {
        reviewPagination: 'pagination',
      }),
      ...mapGetters('frontHeaderOption', [
        'getButtonColor','getButtonFontColor'
      ]),
      btn_styles() {
        return {
          '--btn-color': this.getButtonColor,
          '--btn-font-color': this.getButtonFontColor,
        };
      },
      isProductReviews () {
        if (!this.product.use_review) {
          return false
        } else  {
          return (this.productReviews.length > 0)
        }
      },
      isProductQuestions () {
        return (this.productQuestions.length > 0)
      },
      isCatalogs () {
        return (this.product.catalogs.length > 0)
      },
      isVideos () {
        return (this.product.videos.length > 0)
      }
    },
    watch: {
      'rentalDays' (newVal) {
        if (newVal) {
          this.calcReturnScheduleDate(this.scheduleDate, newVal)
          if (this.product!=null && this.product.use_calendar) {
            this.findHoliday({end_date: this.returnScheduleDate, local_place_id: this.product.local_place_id}).then((content)=>{
              this.isEndDateHoliday = content.is_end_holiday
            })
          }
        }
      },
      'scheduleDate' (newVal) {
        if (newVal) {
          this.calcReturnScheduleDate(newVal, this.rentalDays)
          if (this.product!=null && this.product.use_calendar) {
            this.findHoliday({
              start_date: newVal,
              end_date: this.returnScheduleDate,
              local_place_id: this.product.local_place_id
            }).then((content) => {
              this.isStartDateHoliday = content.is_start_holiday
              this.isEndDateHoliday = content.is_end_holiday
            })
          }
        }
      },
    },
    created() {

      // 利用予定日のデフォルト
      // 5日(当日を含む6日後)から選択可能
      const nowDate = new Date().toISOString().substr(0, 10)
      let newDate = moment(nowDate).add(6, 'd')
      this.scheduleDate = newDate.toISOString().substr(0, 10)
      this.minScheduleDate = this.scheduleDate

      // 50日後(当日を含む51日後)まで
      this.maxScheduleDate = moment(nowDate).add(51, 'd').toISOString().substr(0, 10)

      this.loadProduct(this.$route.params.id)

    },
    methods: {
      ...mapActions('frontProduct', {
        findProduct: 'find',
        findRelations: 'findRelations',
        findComponents: 'findComponents',
      }),
      ...mapActions('frontCart', {
        createCart: 'create',
        updateCart: 'update',
        fetchCartCount: 'fetchCount',
      }),
      ...mapActions('frontProductQuestion', {
        findQuestions: 'findQuestions',
        createQuestion: 'create',
      }),
      ...mapActions('frontProductReview', {
        findReviews: 'findReviews'
      }),
      ...mapActions('frontHoliday', {
        findHoliday: 'fetch'
      }),

      changeProductImage (index) {
        this.productImageIndex = index
      },
      loadProduct (productId) {

        const params = {
          id: productId
        }
        this.productImageIndex = 0

        this.findProduct(params).then((content) => {

          this.noHandling = content.no_handling

          this.meta_tags = content.meta_tags
          this.pageTitle = content.name
          this.product = content
          this.optionItems = this.product.options
          const idComponentItem = this.product.component_item || false
          this.componentProducts = []
          if (idComponentItem) {
            this.findComponents(params).then((content) => {
              this.componentProducts = content.product_components
            })
          }

          // 利用予定日を再設定
          if (this.cart) {
            this.quantity = this.cart.quantity
            this.scheduleDate = this.cart.schedule_date
            this.rentalDays = this.cart.rental_days
            this.displayPrice = this.cart.price
            this.displayDiscountPrice = this.cart.discount_price
            this.productPriceId = this.cart.product_price_id
            this.$data.productOptions = JSON.parse(JSON.stringify(this.cart.cart_option_groups))
          } else {
            this.scheduleDate = content.schedule_start_date
            if (content.price_type == 'daily_price') {
              this.rentalDays = content.rental_days
              this.displayPrice = content.in_tax_price
            } else {
              this.rentalDays = null
              if (content.prices) {
                this.productPriceId = content.prices[0].id
                this.selectedPrice()
              }
            }
            this.$data.productOptions = []
            this.$nextTick(() => this.$refs.orderFormValidation.reset());
            content.option_groups.map((option_group) => {
              const params = Object.assign({}, this.$options.data().product_option)
              params.option_group_id = option_group.id
              params.option_group = option_group
              // params.option_item_id = option_group.option_items ? option_group.option_items[0].id : null
              this.productOptions.push(params)
            })
          }
          this.minScheduleDate = content.schedule_start_date
          this.paymentType = content.payment_type
          if (this.paymentType == 'subscription_payment') {
            this.rentalDays = content.rental_days
            this.displayPrice = content.in_tax_price
            this.quantity = content.subscription_quantity
            this.subscriptionId = content.subscription_id
            this.subscriptionBillingCycle = content.subscription_billing_cycle
            this.subscriptionRentalMinimumPeriod = content.subscription_rental_minimum_period
          }

          if (this.displayDiscountPrice == 0) {
            this.displayDiscountPrice = content.in_tax_discount_price
          }

          if (this.paymentType == 'sale_payment') {
            this.rentalDays = 1
          }

          if (!this.cart) {
            this.calcDisplayPrice()
          }
        })

        this.findRelations(params).then((content) => {
          this.relationProducts = content.products
        })
        this.loadProductQuestions(productId)
        this.loadProductReviews(productId)
      },
      cycleLabel () {
        if (this.paymentType == 'subscription_payment') {
          if (this.subscriptionBillingCycle == 'cycle_month') {
            return '（月額）'
          } else {
            return '（年額）'
          }
        }
      },
      loadProductQuestions (productId) {
        this.questionPage++
        this.findQuestions({product_id: productId, page: this.questionPage}).then((content) => {
          this.productQuestions = [...this.productQuestions, ...content.questions]
        })
      },
      moreProductQuestions () {
        this.loadProductQuestions(this.product.id)
      },
      loadProductReviews (productId) {
        this.reviewPage++
        this.findReviews({product_id: productId, page: this.reviewPage}).then((content) => {
          this.productReviews = [...this.productReviews, ...content.reviews]
        })
      },
      moreProductReviews () {
        this.loadProductReviews(this.product.id)
      },
      addCart () {
        // if (this.loggedIn) {

          // カート追加
          let params = {
            product_id: this.product.id,
            quantity: this.quantity,
            schedule_date: this.scheduleDate,
            rental_days: this.rentalDays,
            product_price_id: this.productPriceId,
            payment_type: this.paymentType,
            // subscription_id: this.subscriptionId,
            billing_cycle: this.subscriptionBillingCycle,
            rental_minimum_period: this.subscriptionRentalMinimumPeriod
          }

          if (this.productOptions.length > 0) {
            const options_attributes = this.productOptions.map(obj => (
                { id: null, option_group_id: obj.option_group_id, option_item_id: obj.option_item_id }
            ))
            params = { ...params, ...{ cart_product_options_attributes: options_attributes } }
          }

          if (this.cart) {
            this.updateCart({id: this.cart.id, params: params})
          } else {
            this.createCart({params}).then( () => {
              this.fetchCartCount()
            })
          }
        // } else {
        //   this.$refs.confirm.open({title: '確認', message: 'お申し込みにはログインが必要です。', options: {isCancelButton: false}}).then(() => {
        //     this.$refs.confirm.close()
        //   })
        // }
      },
      selectedPrice() {
        var tmp = this.product.prices.filter(e => e.id == this.productPriceId);
        this.rentalDays = tmp[0].rental_period
        this.menuTitle = tmp[0].title
        this.menuPrice = tmp[0].price
        this.menuDiscountPrice = tmp[0].discount_price
        this.calcDisplayPrice()
      },
      openQuestion () {
        this.questionDialog = true
      },
      submitQuestion () {

        const params = {
          product_id: this.product.id,
          question_content: this.questionContent
        }
        this.createQuestion(params).then( () => {
          this.questionContent = null
          this.$refs.observer.reset()
          this.questionDialog = false
        })
      },
      calcDisplayPrice () {

        const number = Number(this.rentalDays)
        if (!Number.isInteger(number)) return
        if (number <= 0) return

        const quantity = Number(this.quantity)
        if (!Number.isInteger(quantity)) return
        if (quantity <= 0) return

        let totalPrice = 0
        let totalDiscountPrice = 0
        if (this.product.price_type == 'daily_price') {
          totalPrice = this.product.price * this.rentalDays
          totalDiscountPrice = this.product.discount_price * this.rentalDays
        } else {
          totalPrice = this.menuPrice
          totalDiscountPrice = this.menuDiscountPrice
        }

        let in_tax_price = totalPrice
        let in_tax_discount_price = totalDiscountPrice
        if (!this.product.tax_exempt) {
          const tax = totalPrice * (this.product.tax_rate / 100)
          in_tax_price = totalPrice + tax
          const discount_tax = totalDiscountPrice * (this.product.tax_rate / 100)
          in_tax_discount_price = totalDiscountPrice + discount_tax
        }

        if (in_tax_price <= 0) in_tax_price = this.product.in_tax_price

        if (in_tax_discount_price <= 0) in_tax_discount_price = this.product.in_tax_discount_price

        this.displayPrice = this.calcRoundPrice(in_tax_price, this.product.tax_fraction) * this.quantity
        this.displayDiscountPrice = this.calcRoundPrice(in_tax_discount_price, this.product.tax_fraction) * this.quantity

      },
      calcReturnScheduleDate (date, day) {
        if (day) {
          // 現地在庫品の場合は当日返却
          if (this.product.local_stock_item) {
            day = Number(day)
          } else {
            day = Number(day) + 1
          }
          let newDate = moment(date).add(day,  'd')
          this.returnScheduleDate = newDate.toISOString().substr(0, 10)
        }
      },
      openCatalog (item) {
        window.open(item.url)
      },
      youtubeId (url) {
        return this.$youtube.getIdFromUrl(url)
      },
      showOptionGroupDialog (option_group){
        this.$refs.OptionGroupDialog.openDialog({target_id: option_group.id})
      },
      optionGroupDialogCallback (resp) {
        for (const option of this.productOptions) {
          if (option.option_group_id === resp.option_group_id) {
            option.option_item_id = resp.option_item_id
            break
          }
        }
      }
    },
    beforeRouteUpdate (to, from, next) {
      // created は 動かないので、otherProduct(関連商品、おすすめ商品)で再描画する前に、データを再取得する
      this.loadProduct(to.params.id)
      next()
    },
  }
</script>

<style scoped lang="scss">

button.cart_btn {
  background-color:var(--btn-color) !important;
  color:var(--btn-font-color) !important;
}

.free-col{
  padding: 0 12px !important;
}
.custom-quill-container {
  /deep/ {
    .ql-toolbar {
      display: none;
    }
    .ql-snow {
      border: none !important;
    }
    .ql-editor{
      padding:0 !important;
      min-height:50px !important;
      font-size:.875rem!important;  /* .body-2が効かないので、.body-2と同じサイズにする */
    }
  }
}
.v-chip--clickable{
  cursor:default;
}
</style>
