var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert-message'),_vm._v(" "),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:30","name":"タグ名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"30","dense":"","label":"タグ名","hide-details":"auto","outlined":"","placeholder":" ","required":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"フッターに表示する","hide-details":"auto"},model:{value:(_vm.displayFooter),callback:function ($$v) {_vm.displayFooter=$$v},expression:"displayFooter"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|numeric|min_value:0|max_value:2147483647","name":"表示順"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","label":"表示順","reverse":"","hide-details":"auto","outlined":"","placeholder":" ","required":""},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"商品一覧にタグを表示する","hide-details":"auto"},model:{value:(_vm.displayProduct),callback:function ($$v) {_vm.displayProduct=$$v},expression:"displayProduct"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"背景色"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"背景色","outlined":"","placeholder":" ","readonly":""},on:{"click":function($event){_vm.backgroundColorMenu = true}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.backgroundColorSwatchStyle)},on))]}}],null,true),model:{value:(_vm.backgroundColorMenu),callback:function ($$v) {_vm.backgroundColorMenu=$$v},expression:"backgroundColorMenu"}},[_vm._v(" "),_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":""},model:{value:(_vm.backgroundColor),callback:function ($$v) {_vm.backgroundColor=$$v},expression:"backgroundColor"}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.backgroundColor),callback:function ($$v) {_vm.backgroundColor=$$v},expression:"backgroundColor"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"文字色"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"文字色","outlined":"","placeholder":" ","readonly":""},on:{"click":function($event){_vm.fontColorMenu = true}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.fontColorSwatchStyle)},on))]}}],null,true),model:{value:(_vm.fontColorMenu),callback:function ($$v) {_vm.fontColorMenu=$$v},expression:"fontColorMenu"}},[_vm._v(" "),_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":""},model:{value:(_vm.fontColor),callback:function ($$v) {_vm.fontColor=$$v},expression:"fontColor"}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.fontColor),callback:function ($$v) {_vm.fontColor=$$v},expression:"fontColor"}})]}}],null,true)})],1)],1),_vm._v(" "),(_vm.name)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-subtitle',[_vm._v("\n                      プレビュー\n                    ")]),_vm._v(" "),_c('v-card-text',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.backgroundColor,"text-color":_vm.fontColor,"label":"","small":""}},[_vm._v("\n                        #"+_vm._s(_vm.name)+"\n                      ")])],1)],1)],1)],1):_vm._e()],1),_vm._v(" "),_c('v-card-actions',[(_vm.isEdit)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.tagDelete}},[_vm._v("\n                削除\n              ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.tagCancel}},[_vm._v("\n                キャンセル\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.submit}},[_vm._v("\n                保存\n              ")])],1)],1)],1)]}}])})],1)],1),_vm._v(" "),(_vm.isEdit)?_c('confirm',{ref:"confirm"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }