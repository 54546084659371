import Vue from 'vue'
import Vuex from 'vuex'

import loading from './modules/loading'
import snackbar from './modules/snackbar'
import alertMessage from './modules/alert-message'
import frontModules from './modules/frontend'
import backyardModules from './modules/backyard'
import adminModules from './modules/admin'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    loading,
    snackbar,
    alertMessage,
    frontModules,
    backyardModules,
    adminModules
  },
  // plugins: [
  //   createPersistedState()
  //   // createSharedMutations()
  // ],
  strict: debug
})

export default store
