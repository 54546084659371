<template>
  <span>
    <span class="text-h6">{{ title }}</span>
    <v-divider class="my-2" />
    <v-list-item
      v-for="item in items"
      :key="item.id"
      link
      dense
      @click="moveToProductList(item)"
    >
      <v-list-item-content>
        <v-list-item-title>
          <v-icon small>mdi-chevron-right</v-icon>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </span>
</template>

<script>

  import FooterActionMixin from '../../mixins/frontend/footer-action-mixin'

  /**
   * フッターリストアイテム
   */
  export default {
    name: 'FooterSearchListItemNav',
    mixins: [
      FooterActionMixin,
    ],
    props: {
      items: {
        type: Array,
        default: () => {
          return {}
        },
      },
      title: {
        type: String,
        default: ''
      },
      searchType: {
        type: String,
        default: 'category'
      }
    },
    data() {
      return {
      }
    },
    methods: {
      moveToProductList (item) {
        this.moveToProduct(item, this.searchType)
      }
    },
  }

</script>

<style scoped lang="scss">
</style>