<template>
  <v-alert
    outlined
    dismissible
    :value="isAlert"
    :color="color"
  >
    <div
      v-for="(item, index) in messages"
      :key="`message_${index}`"
    >
<!--      {{ item }}-->
      <div v-html="item"></div>
    </div>
  </v-alert>
</template>

<script>

  import { mapState } from 'vuex'

  /**
   * example
   * <result-message />
   */
  export default {
    name: 'AlertMessage',
    data () {
      return {
        isAlert: false,
        messages: [],
      }
    },
    computed: {
      ...mapState('alertMessage', {
        isShow: 'isShow',
        color: 'color',
        message: 'message'
      }),
    },
    watch: {
      isShow (newVal) {
        this.isAlert = newVal
      },
      message (newVal) {
        this.messages = (newVal === null) ? [] : newVal.split(',')
      }
    },
    methods: {}
  }

</script>

<style scoped lang="scss">
</style>
