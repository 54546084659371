var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert-message'),_vm._v(" "),(_vm.announcement)?_c('v-form',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:50","name":"タイトル"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"タイトル","dense":"","hide-details":"auto","clearable":"","required":"","counter":"50","placeholder":" ","error-messages":errors},model:{value:(_vm.announcement.title),callback:function ($$v) {_vm.$set(_vm.announcement, "title", $$v)},expression:"announcement.title"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"max:255","name":"本文"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"本文","clearable":"","counter":"255","auto-grow":"","row-height":"15","dense":"","hide-details":"auto","placeholder":" ","error-messages":errors},model:{value:(_vm.announcement.description),callback:function ($$v) {_vm.$set(_vm.announcement, "description", $$v)},expression:"announcement.description"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"お知らせ日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","readonly":"","persistent-hint":"","label":"お知らせ日","required":"","error-messages":errors},model:{value:(_vm.announcement.notice_date),callback:function ($$v) {_vm.$set(_vm.announcement, "notice_date", $$v)},expression:"announcement.notice_date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.announcement.notice_date_menu),callback:function ($$v) {_vm.$set(_vm.announcement, "notice_date_menu", $$v)},expression:"announcement.notice_date_menu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.notice_date_menu = false}},model:{value:(_vm.announcement.notice_date),callback:function ($$v) {_vm.$set(_vm.announcement, "notice_date", $$v)},expression:"announcement.notice_date"}})],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"表示開始日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"表示開始日","clearable":"","persistent-hint":"","readonly":"","error-messages":errors},model:{value:(_vm.announcement.display_start_date),callback:function ($$v) {_vm.$set(_vm.announcement, "display_start_date", $$v)},expression:"announcement.display_start_date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.display_start_date_menu),callback:function ($$v) {_vm.display_start_date_menu=$$v},expression:"display_start_date_menu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.display_start_date_menu = false}},model:{value:(_vm.announcement.display_start_date),callback:function ($$v) {_vm.$set(_vm.announcement, "display_start_date", $$v)},expression:"announcement.display_start_date"}})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"表示終了日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"表示終了日","clearable":"","persistent-hint":"","readonly":"","error-messages":errors},model:{value:(_vm.announcement.display_end_date),callback:function ($$v) {_vm.$set(_vm.announcement, "display_end_date", $$v)},expression:"announcement.display_end_date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.display_end_date_menu),callback:function ($$v) {_vm.display_end_date_menu=$$v},expression:"display_end_date_menu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.display_end_date_menu = false}},model:{value:(_vm.announcement.display_end_date),callback:function ($$v) {_vm.$set(_vm.announcement, "display_end_date", $$v)},expression:"announcement.display_end_date"}})],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"全社配信"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"pa-0 ma-0",attrs:{"label":"全社配信","row":"","dense":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.announcement.announce_all),callback:function ($$v) {_vm.$set(_vm.announcement, "announce_all", $$v)},expression:"announcement.announce_all"}},[_c('v-radio',{attrs:{"label":"しない","value":false}}),_vm._v(" "),_c('v-radio',{attrs:{"label":"する","value":true}})],1)]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.announcement.announce_all === false),expression:"announcement.announce_all === false"}],attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"配信先会社"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","items":_vm.announcement.company_all,"item-text":"name","item-value":"id","label":"配信先会社","hint":"選択してください","error-messages":errors},model:{value:(_vm.announcement.company_ids),callback:function ($$v) {_vm.$set(_vm.announcement, "company_ids", $$v)},expression:"announcement.company_ids"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('div',{staticClass:"float-right"},[_c('v-btn',{on:{"click":_vm.cancelButtonClickEvent}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.submit}},[_vm._v("\n              保存\n            ")])],1)])],1)]}}],null,false,82459847)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }