/**
 * 商品
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";
import * as types from "../../../mutation-types/backyard-types";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/orders`

const order_delivery = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetchAll({ _commit }, { order_id, params }) {
      const api_path = `${API_PATH}/${order_id}/order_deliveries`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path, { params: params }).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    create( { commit, state }, { order_id, params } ) {
      const api_path = `${API_PATH}/${order_id}/order_deliveries`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    update( { commit }, { order_id, params } ) {
      const api_path = `${API_PATH}/${order_id}/order_deliveries/${params.id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    destroy( { commit, state }, { order_id, order_delivery_id } ) {
      const api_path = `${API_PATH}/${order_id}/order_deliveries/${order_delivery_id}`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.delete(api_path).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
  },
  mutations: {},
}

export default order_delivery