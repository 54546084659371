<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    @input="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title>{{ optionGroup.name }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <span class="body-2 mb-1 line-break">{{ optionGroup.description }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-simple-table
              fixed-header
              dense
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" />
                    <th
                      class="text-left"
                    >
                      オプション名
                    </th>
                    <th
                      class="text-center"
                    >
                      金額
                    </th>
                    <th v-if="false" />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="option_item in optionItems"
                    :key="option_item.id"
                  >
                    <td>
                      <v-img
                        v-if="option_item.image_url !== null"
                        :src="option_item.image_url"
                        max-height="40"
                        max-width="100"
                        contain
                      />
                    </td>
                    <td>{{ option_item.name }}</td>
                    <td
                      class="text-right"
                    >
                      {{ option_item.price | comma_format }} 円
                    </td>
                    <td
                      v-if="false"
                      class="text-right"
                    >
                      <v-btn
                        small
                        color="primary"
                        @click="rowButtonClickEvent(option_item)"
                      >
                        選択
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancelButtonClickEvent">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "OptionGroupDialog",
  components: {},
  mixins: [],
  props: {},
  data () {
    return {
      dialog: false,
      target_id: null,
      optionGroup: {
        name: null,
        description: null,
      },
      optionItems:[],
    }
  },
  watch : {},
  created() {
  },
  methods: {
    ...mapActions('frontOptionGroup', {
      fetchOptionGroup: 'fetch',
    }),
    /**
     * 本モーダルを表示する.
     * @param { Object } params - 表示オプション
     * @param { Number } params.target_id - 検索対象となるオプショングループID
     */
    openDialog (params) {
      this.target_id = params['target_id']
      this.searchOptionGroup()
      this.dialog = true
    },
    rowButtonClickEvent (option_item) {
      const resp = { option_group_id: this.target_id, option_item_id: option_item.id }
      this.$emit('optionGroupDialogCallback', resp)
      this.closeDialog()
    },
    cancelButtonClickEvent () {
      this.closeDialog()
    },
    closeDialog () {
      // すべて初期化
      Object.assign(this.$data, this.$options.data());
    },
    searchOptionGroup () {
      this.fetchOptionGroup({ id: this.target_id }).then((content) => {
        this.optionGroup = content
        this.optionItems = content.option_items
      })
    }
  }
}
</script>

<style scoped>

</style>