<!-- バックヤード > 商品詳細 > 商品詳細 -->
<template>
  <div>
    <v-card flat>
      <v-card-text>
        <!-- カタログ -->
        <v-row>
          <v-col
            cols="12"
          >
            <h3>カタログ</h3>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="showNewCatalogModal"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="productCatalogs"
                :headers="catalogTableHeaders"
                :items="productCatalogs"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="cyan darken-2"
                    icon
                    @click="showEditCatalogModal(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="info"
                    small
                    @click="showEditCatalogModal(item)"
                  >
                    編集する
                  </v-btn>
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="error"
                    icon
                    @click="catalogDeleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="catalogDeleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <!-- /カタログ -->

        <v-divider />

        <!-- 動画URL -->
        <v-row>
          <v-col cols="12">
            <h3>動画URL</h3>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="showNewVideoModal"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="productVideos"
                :headers="videoTableHeaders"
                :items="productVideos"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="cyan darken-2"
                    icon
                    @click="showEditVideoModal(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="info"
                    small
                    @click="showEditVideoModal(item)"
                  >
                    編集する
                  </v-btn>
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="error"
                    icon
                    @click="videoDeleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="videoDeleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <!-- /動画URL -->

        <v-divider />

        <!-- フリー項目 -->
        <v-row>
          <v-col>
            <h3>フリー項目</h3>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="showNewItemModal"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="productItems"
                :headers="itemTableHeaders"
                :items="productItems"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.value="{ item }">
                  <span
                    :title="item.value"
                    class="wrap_text"
                    style="width: 180px;"
                  >
                    {{ item.value }}
                  </span>
                </template>
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="cyan darken-2"
                    icon
                    @click="showEditItemModal(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="info"
                    small
                    @click="showEditItemModal(item)"
                  >
                    編集する
                  </v-btn>
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="error"
                    @click="itemDeleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="itemDeleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <!-- /フリー項目 -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="isShowCatalogDialog"
      width="450"
      @close="closeCatalogDialog"
    >
      <v-form>
        <validation-observer
          v-slot="{ invalid }"
          ref="catalogFormValidation"
        >
          <v-card>
            <v-card-title>カタログ</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="PDFファイル"
                    rules="mimes:application/pdf"
                  >
                    <v-row
                      class="text-center"
                      @dragover.prevent
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    >
                      <v-col>
                        <v-file-input
                          v-model="productCatalog.file"
                          :background-color="isDragging ? 'blue' : 'null'"
                          :error-messages="errors"
                          :show-size="1000"
                          accept="application/pdf"
                          append-icon="mdi-folder"
                          counter
                          dense
                          label="PDFファイルを選択して下さい"
                          outlined
                          placeholder=" "
                          prepend-icon=""
                          required
                          @change="catalogFileChangeEvent"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>
                            <span
                              v-else-if="index === 2"
                              class="overline grey--text text--darken-3 mx-2"
                            >+{{ files.length - 2 }} File(s)</span>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row v-if="productCatalog.id != null">
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="表示順"
                    rules="required|numeric|min_value:0|max_value:2147483647"
                  >
                    <v-text-field
                      v-model="productCatalog.position"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="表示順"
                      outlined
                      placeholder=" "
                      required
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="ファイル名"
                    rules="required|max:30"
                  >
                    <v-text-field
                      v-model="productCatalog.name"
                      :error-messages="errors"
                      counter="30"
                      dense
                      hide-details="auto"
                      label="ファイル名"
                      outlined
                      placeholder=" "
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeCatalogDialog">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="catalogDialogRegisterButtonClickEvent"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="isShowVideoDialog"
      width="400"
    >
      <v-form>
        <validation-observer
          v-slot="{ invalid }"
          ref="videoFormValidation"
        >
          <v-card>
            <v-card-title>動画URL登録</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="タイトル"
                    rules="required|max:30"
                  >
                    <v-text-field
                      v-model="productVideo.title"
                      :error-messages="errors"
                      counter="30"
                      dense
                      hide-details="auto"
                      label="タイトル"
                      outlined
                      placeholder=" "
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="productVideo.id != null">
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="表示順"
                    rules="required|numeric|min_value:0|max_value:2147483647"
                  >
                    <v-text-field
                      v-model="productVideo.position"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="表示順"
                      outlined
                      placeholder=" "
                      required
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="動画URL"
                    rules="required|max:255"
                  >
                    <v-text-field
                      v-model="productVideo.url"
                      :error-messages="errors"
                      counter="255"
                      dense
                      hide-details="auto"
                      label="動画URL"
                      outlined
                      placeholder=" "
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeVideoDialog">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="videoDialogRegisterButtonClickEvent"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-dialog>
    <v-dialog
      v-model="isShowItemDialog"
      width="600"
    >
      <v-form>
        <validation-observer
          v-slot="{ invalid }"
          ref="freeFormValidation"
        >
          <v-card>
            <v-card-title>フリー項目設定</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="項目名"
                    rules="required|max:30"
                  >
                    <v-text-field
                      v-model="productItem.title"
                      :error-messages="errors"
                      counter="30"
                      dense
                      hide-details="auto"
                      label="項目名"
                      outlined
                      placeholder=" "
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row v-if="productItem.id != null">
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="表示順"
                    rules="required|numeric|min_value:0|max_value:2147483647"
                  >
                    <v-text-field
                      v-model="productItem.position"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="表示順"
                      outlined
                      placeholder=" "
                      required
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="内容"
                    rules="required|max:255"
                  >
                    <!--
                    <v-textarea
                      v-model="productItem.value"
                      :error-messages="errors"
                      auto-grow
                      counter="255"
                      hide-details="auto"
                      label="内容"
                      outlined
                      placeholder=" "
                      required
                    />
                    -->

                    <vue-editor
                        v-model="productItem.value"
                        :error-messages="errors"
                        class="custom-quill-container"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeItemDialog">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="itemDialogRegisterButtonClickEvent"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-dialog>

    <confirm ref="confirm" />
  </div>
</template>

<script>
import Confirm from '../../../../components/Confirm'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    Confirm
  },
  mixins: [
  ],
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      catalogTableHeaders: [
        { value: 'position', text: '表示順', align: 'start' },
        { value: 'name', text: 'ファイル名' },
        { value: 'editable', text: '', align: 'end' }
      ],
      videoTableHeaders: [
        { value: 'position', text: '表示順', align: 'start' },
        { value: 'title', text: 'タイトル' },
        { value: 'url', text: 'URL' },
        { value: 'editable', text: '', align: 'end' }
      ],
      itemTableHeaders: [
        { value: 'position', text: '表示順', align: 'start' },
        { value: 'title', text: '項目名' },
        // { value: 'value', text: '内容' },
        { value: 'editable', text: '', align: 'end' }
      ],
      isShowCatalogDialog: false,
      isShowVideoDialog: false,
      isShowItemDialog: false,
      productCatalog: {
        id: null,
        file: null,
        position: null,
        name: null
      },
      productVideo: {
        id: null,
        title: null,
        position: null,
        url: null,
      },
      productItem: {
        id: null,
        title: null,
        position: null,
        value: null,
      },
      isDragging: false,
      dragCount: 0,
    }
  },
  computed: {
    ...mapState('backProductCatalog', {
      productCatalogs: 'product_catalogs',
    }),
    ...mapState('backProductVideo', {
      productVideos: 'product_videos'
    }),
    ...mapState('backProductItem', {
      productItems: 'product_items'
    })
  },
  created() {
    this.fetchProductCatalogs( { product_id: this.product.id })
    this.fetchProductVideos({ product_id: this.product.id })
    this.fetchProductItems({ product_id: this.product.id })
  },
  methods: {
    ...mapActions('backProductCatalog', {
      fetchProductCatalogs: 'fetchAll',
      createProductCatalog: 'create',
      updateProductCatalog: 'update',
      destroyProductCatalog: 'destroy'
    }),
    ...mapActions('backProductVideo', {
      fetchProductVideos: 'fetchAll',
      createProductVideo: 'create',
      updateProductVideo: 'update',
      destroyProductVideo: 'destroy'
    }),
    ...mapActions('backProductItem', {
      fetchProductItems: 'fetchAll',
      createProductItem: 'create',
      updateProductItem: 'update',
      destroyProductItem: 'destroy'
    }),

    showNewCatalogModal () {
      Object.assign(this.$data.productCatalog, this.$options.data().productCatalog)
      this.isShowCatalogDialog = true
    },

    showEditCatalogModal (product_catalog) {
      Object.assign(this.$data.productCatalog, product_catalog)
      this.isShowCatalogDialog = true
    },

    showNewVideoModal () {
      Object.assign(this.$data.productVideo, this.$options.data().productVideo)
      this.isShowVideoDialog = true
    },
    showEditVideoModal (product_video) {
      Object.assign(this.$data.productVideo, product_video)
      this.isShowVideoDialog = true
    },

    showNewItemModal () {
      Object.assign(this.$data.productItem, this.$options.data().productItem)
      this.isShowItemDialog = true
    },
    showEditItemModal (product_item) {
      Object.assign(this.$data.productItem, product_item)
      this.isShowItemDialog = true
    },
    catalogDialogRegisterButtonClickEvent () {
      const params = new FormData()

      if (this.productCatalog.id) {
        if (this.productCatalog.file) params.append('file', this.productCatalog.file)
        params.append('name', this.productCatalog.name)
        params.append('position', this.productCatalog.position)
        this.updateProductCatalog({product_id: this.product.id, id: this.productCatalog.id, params}).then(() => {
          this.fetchProductCatalogs({ product_id: this.product.id })
          this.closeCatalogDialog()
        })

      } else {
        params.append('file', this.productCatalog.file)
        params.append('name', this.productCatalog.name)
        this.createProductCatalog({product_id: this.product.id, params}).then(() => {
          this.fetchProductCatalogs({ product_id: this.product.id })
          this.closeCatalogDialog()
        })
      }

    },
    videoDialogRegisterButtonClickEvent () {
      if (this.productVideo.id) {
        this.updateProductVideo({product_id: this.product.id, params: this.productVideo}).then(() => {
          this.fetchProductVideos({ product_id: this.product.id })
          this.closeVideoDialog()
        })
      } else {
        this.createProductVideo({ product_id: this.product.id, params: this.productVideo }).then(() => {
          this.fetchProductVideos({ product_id: this.product.id })
          this.closeVideoDialog()
        })
      }
    },
    itemDialogRegisterButtonClickEvent () {
      if (this.productItem.id) {
        this.updateProductItem({product_id: this.product.id, params: this.productItem}).then(() => {
          this.fetchProductItems({ product_id: this.product.id })
          this.closeItemDialog()
        })
      } else {
        this.createProductItem({product_id: this.product.id, params: this.productItem}).then(() => {
          this.fetchProductItems({ product_id: this.product.id })
          this.closeItemDialog()
        })
      }
    },
    catalogDeleteButtonClickEvent (product_catalog) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyProductCatalog({ product_id: this.product.id, params: product_catalog }).then(() => {
            this.fetchProductCatalogs({ product_id: this.product.id })
          })
        }
      })
    },
    videoDeleteButtonClickEvent (product_video) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyProductVideo({ product_id: this.product.id, params: product_video }).then(() => {
            this.fetchProductVideos({ product_id: this.product.id })
          })
        }
      })
    },
    itemDeleteButtonClickEvent (product_item) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyProductItem({ product_id: this.product.id, params: product_item }).then(() => {
            this.fetchProductItems({ product_id: this.product.id })
          })
        }
      })
    },
    catalogFileChangeEvent () {
      if (this.productCatalog.file) {
        this.productCatalog.name = this.productCatalog.file.name
      }
    },
    closeCatalogDialog () {
      Object.assign(this.$data.productCatalog, this.$options.data().productCatalog)
      this.$nextTick(() => this.$refs.catalogFormValidation.reset())
      this.isShowCatalogDialog = false
    },
    closeVideoDialog () {
      Object.assign(this.$data.productVideo, this.$options.data().productVideo)
      this.$nextTick(() => this.$refs.videoFormValidation.reset())
      this.isShowVideoDialog = false

    },
    closeItemDialog () {
      Object.assign(this.$data.productItem, this.$options.data().productItem)
      this.$nextTick(() => this.$refs.freeFormValidation.reset())
      this.isShowItemDialog = false
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const _files = e.dataTransfer.files;
      this.productCatalog.file = _files[0];
    },

    onDragEnter(e) {
      e.preventDefault();
      this.isDragging = true;
      this.dragCount++;
    },

    onDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    }
  },
}

</script>

<style scoped lang="scss">
.wrap_text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custom-quill-container {
  /deep/ {
    /* 動画と画像の添付はなし */
    .ql-toolbar {
      .ql-formats{
        .ql-video{
          display: none;
        }
        .ql-image{
          display: none;
        }
      }
    }
  }
}
</style>
