<!-- バックヤード > 店舗問い合わせ編集 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-alert
      dense
      elevation="2"
      class="mb-0"
    >
      <div class="ticket">
        <div class="heading">
          ステータス：{{ ticket.status | ticketStatusFilter }} 会員番号：{{ ticket.customer_no }} 会員名：{{ ticket.customer_name }}
        </div>
        <div class="heading">
          問い合わせ日時：{{ ticket.created_at | unix_datetime_format }}
        </div>
        <div style="margin-top: 15px;">
          件名：{{ ticket.subject }}
        </div>
        <v-divider class="my-2" />
        <div class="line-break">{{ ticket.first_comment }}
        </div>
      </div>
    </v-alert>
    <v-row v-if="!isClose">
      <v-col
        cols="12"
        align="end"
      >
        <v-btn
          class="mr-2"
          @click="commentDialog=true"
        >
          書込
        </v-btn>
        <v-btn
          color="error"
          @click="submitClose"
        >
          解決済にする
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-row
      v-for="item in ticket_comments"
      :key="item.id"
    >
      <v-col cols="12">
        <v-card
          :elevation="item.my_posted ? '2' : '4'"
          :color="!item.published ? '#FFFDE7' : ''"
          flat
        >
          <v-list-item>
            <v-list-item-avatar
              v-if="item.made_by_staff"
              color="grey lighten-3"
            >
              <v-icon>mdi-storefront-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-avatar
              v-else
              color="grey lighten-3"
            >
              <v-icon>mdi-account</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ item.created_at | unix_datetime_format }} {{ item.owner_name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <span
              v-if="!item.published"
              class="body-2"
            >
              会員へ非通知&nbsp;
            </span>
            <v-btn
              v-if="item.made_by_staff && !isClose"
              small
              color="error"
              @click="removeTicketComment(item)"
            >
              削除する
            </v-btn>
          </v-list-item>
          <v-divider class="mx-4" />
          <v-card-text class="line-break">{{ item.comment }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!pagination.is_last_page">
      <v-col cols="12">
        <div
          class="mt-4"
        >
          <v-btn
            text
            color="primary"
            @click="moreComments"
          >
            さらに表示する
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="commentDialog"
      width="500"
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-card>
          <v-card-title>コメント登録</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="published"
                  label="質問者の会員ページへ通知する"
                  hide-details="auto"
                  class="pa-0 ma-0"
                />
              </v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:10000"
                  name="コメント"
                  class="requiredMark"
                >
                  <v-textarea
                    v-model="comment"
                    outlined
                    label="コメント"
                    placeholder="コメントを入力してください。"
                    value=""
                    counter="10000"
                    :error-messages="errors"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="commentDialog=false">
              キャンセル
            </v-btn>
            <v-btn
              :disabled="invalid"
              color="primary"
              @click="submitTicketComment"
            >
              書込
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
    <Confirm ref="confirm" />
  </div>
</template>

<script>
import Confirm from './../../../components/Confirm'
import TicketMixin from '../../../mixins/ticket-mixin'
import {mapActions, mapState} from 'vuex'

  export default {
    components: {
      Confirm,
    },
    mixins: [
      TicketMixin
    ],
    data () {
      return {
        page: 1,
        breadcrumbs: [
          { text: '店舗への問い合わせ一覧', disabled: false, to: '/backyard/inquiries', exact: true },
          { text: '店舗への問い合わせ詳細', disabled: true, to: '' },
        ],
        commentDialog: false,
        ticketId: this.$route.params.id,
        ticket: {
          status: 'open',
          subject: null,
          first_comment: null,
          customer_no: null,
          customer_name: null,
          created_at: null,
        },
        published: true,
        comment: null,
        ticket_comments: [],
      }
    },
    computed: {
      ...mapState('backTicketComment', {
        pagination: 'pagination',
      }),
      isClose() {
        return (this.ticket.status === 'closed')
      }
    },
    created() {
      this.loadTicket()
      this.loadTicketComments()
    },
    methods: {
      ...mapActions('backTicket', {
        findTicket: 'findTicket',
        closeTicket: 'close',
        fetchUnclosedCount: 'fetchUnclosedCount',
      }),
      ...mapActions('backTicketComment', {
        findComments: 'findComments',
        createComment: 'create',
        destroyComment: 'destroy'
      }),
      resetData () {
        this.page = 1
        this.ticket_comments = []
        this.loadTicket()
        this.loadTicketComments()
      },
      loadTicket() {
        this.findTicket({ id: this.ticketId }).then((content) => {
          this.ticket.status = content.status
          this.ticket.subject = content.subject
          this.ticket.first_comment = content.first_comment
          this.ticket.customer_no = content.customer_no
          this.ticket.customer_name = content.customer_name
          this.ticket.created_at = content.created_at
        })
      },
      loadTicketComments() {
        const params = {
          page: this.page
        }
        this.findComments({id: this.ticketId, params: params}).then((content) => {
          this.ticket_comments = [...this.ticket_comments, ...content.ticket_comments]
        })
      },
      moreComments() {
        this.page++
        this.loadTicketComments()
      },
      submitTicketComment () {
        const params = {
          comment: this.comment,
          published: this.published
        }
        this.createComment({ id: this.ticketId, params: params}).then(() => {
          this.commentDialog = false
          this.comment = null
          this.published = true
          this.$refs.observer.reset()
          this.resetData()
        })
      },
      /**
       * チケットを削除する
       * @param ticket_comment
       */
      removeTicketComment(ticket_comment) {
        this.$refs.confirm.open({title: '確認', message: '削除してよろしいですか？', options: { titleColor: 'red' }}).then((confirm) => {
          this.$refs.confirm.close()
          if (confirm) {
            this.destroyComment({ticket_id: this.ticketId, id: ticket_comment.id}).then(() => {
              this.resetData()
            })
          }
        })
      },
      submitClose () {
        this.$refs.confirm.open({title: '確認', message: '解決済にしてよろしいですか？', options: { titleColor: 'red' }}).then((confirm) => {
          this.$refs.confirm.close()
          if (confirm) {
            // 解決済に更新
            this.closeTicket({ id: this.ticketId }).then(() => {
              this.fetchUnclosedCount()
              this.resetData()
            })
          }
        })
      },
    },
  }
</script>

<style scoped lang="scss">

.ticket {
  .heading {
    font-size: 90%;
    font-weight: 700;
  }
}


</style>
