<!-- バックヤード > キャンペーン追加 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <basic-form
      v-if="campaign"
      :is-edit="false"
      :campaign="campaign"
      :company-mode-caption-name="company_mode_caption_name"
    />
  </div>
</template>

<script>
import BasicForm from './forms/_Basic'
import {mapActions} from 'vuex'
export default {
  name: 'CampaignNew',
  components: {
    BasicForm,
  },
  mixins: [],
  data () {
    return {
      campaign: null,
      company_mode_caption_name: '',
      breadcrumbs: [],
    }
  },
  computed: {},
  created() {
    this.newCampaign().then(content => {
      this.breadcrumbs =
          [
            { text: content.company_mode_caption_name + '一覧', disabled: false, to: '/backyard/campaigns', exact: true },
            { text: content.company_mode_caption_name + '詳細', disabled: true, to: '' },
          ]
      this.company_mode_caption_name = content.company_mode_caption_name
      this.campaign = content
    })
  },
  methods: {
    ...mapActions('backCampaign', {
      newCampaign: 'new',
    })
  },
}
</script>

<style lang="scss">

</style>
