var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.news)?_c('div',[_c('alert-message'),_vm._v(" "),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:50","name":"タイトル"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"50","dense":"","hide-details":"auto","label":"タイトル","outlined":"","placeholder":" ","required":""},model:{value:(_vm.news.title),callback:function ($$v) {_vm.$set(_vm.news, "title", $$v)},expression:"news.title"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:255","name":"詳細"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"auto-grow":"","counter":"255","dense":"","hide-details":"auto","label":"詳細","outlined":"","placeholder":" ","row-height":"15"},model:{value:(_vm.news.description),callback:function ($$v) {_vm.$set(_vm.news, "description", $$v)},expression:"news.description"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","transition":"scale-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"ニュース日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"error-messages":errors,"clearable":"","dense":"","hide-details":"auto","label":"ニュース日","outlined":"","placeholder":" ","readonly":""},on:{"click:clear":function($event){_vm.news.notice_date = null}},model:{value:(_vm.news.notice_date),callback:function ($$v) {_vm.$set(_vm.news, "notice_date", $$v)},expression:"news.notice_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.noticeDateMenu),callback:function ($$v) {_vm.noticeDateMenu=$$v},expression:"noticeDateMenu"}},[_vm._v(" "),_c('v-date-picker',{on:{"input":function($event){_vm.noticeDateMenu = false}},model:{value:(_vm.news.notice_date),callback:function ($$v) {_vm.$set(_vm.news, "notice_date", $$v)},expression:"news.notice_date"}})],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","transition":"scale-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"開始日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"error-messages":errors,"clearable":"","dense":"","hide-details":"auto","label":"開始日","outlined":"","placeholder":" ","readonly":""},on:{"click:clear":function($event){_vm.news.display_period_start_date = null}},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.smAndDown)?{key:"append-outer",fn:function(){return [_vm._v("\n                             〜\n                          ")]},proxy:true}:null],null,true),model:{value:(_vm.news.display_period_start_date),callback:function ($$v) {_vm.$set(_vm.news, "display_period_start_date", $$v)},expression:"news.display_period_start_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_vm._v(" "),_c('v-date-picker',{on:{"input":function($event){_vm.startDateMenu = false}},model:{value:(_vm.news.display_period_start_date),callback:function ($$v) {_vm.$set(_vm.news, "display_period_start_date", $$v)},expression:"news.display_period_start_date"}})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","transition":"scale-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"終了日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"error-messages":errors,"clearable":"","dense":"","hide-details":"auto","label":"終了日","outlined":"","placeholder":" ","readonly":""},on:{"click:clear":function($event){_vm.news.display_period_end_date = null}},model:{value:(_vm.news.display_period_end_date),callback:function ($$v) {_vm.$set(_vm.news, "display_period_end_date", $$v)},expression:"news.display_period_end_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.endDateMenu),callback:function ($$v) {_vm.endDateMenu=$$v},expression:"endDateMenu"}},[_vm._v(" "),_c('v-date-picker',{on:{"input":function($event){_vm.endDateMenu = false}},model:{value:(_vm.news.display_period_end_date),callback:function ($$v) {_vm.$set(_vm.news, "display_period_end_date", $$v)},expression:"news.display_period_end_date"}})],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[(_vm.news.id)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteButtonClickEvent}},[_vm._v("\n                削除\n              ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.cancelButtonClickEvent}},[_vm._v("\n                キャンセル\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":_vm.registerButtonClickEvent}},[_vm._v("\n                保存\n              ")])],1)],1)],1)]}}],null,false,136386169)})],1)],1),_vm._v(" "),_c('confirm',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }