/**
 * 配送業者
 */
import Vue from "vue";
import * as types from "../../../../mutation-types/backyard-types";
import * as httpConst from "../../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/search/delivery_companies`

const search_delivery_company_time = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        fetchAll({ commit }, { delivery_company_id }) {
            const api_path = `${API_PATH}/${delivery_company_id}/delivery_company_times`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        }
    },
    mutations: {
        [types.BACK_SEARCH_DELIVERY_COMPANY_TIME] (state, { content }) {
            state.content = content.delivery_company_times
        },
    },
}

export default search_delivery_company_time
