<!-- バックヤード > 商品一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          オプショングループ
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              @click="addOptionGroupButtonClickEvent"
            >
              オプショングループ追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels
          v-model="panel"
          accordion
        >
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="inputtedKeyword"
                      dense
                      hide-details="auto"
                      label="キーワード"
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="resetOptionGroup"
                  >
                    検索
                  </v-btn>
                </v-card-actions>
              </v-card>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>No</th>
                  <th
                    class="text-left"
                  >
                    名称
                  </th>
                  <th
                    class="text-left"
                  >
                    非課税
                  </th>
                  <th
                    class="text-left"
                  >
                    必須
                  </th>
                  <th
                    class="text-left"
                  >
                    説明ボタン表示設定
                  </th>
                  <th
                    class="text-left"
                  >
                    アイテム件数
                  </th>
                  <th
                    class="text-right"
                  >
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="option_group in optionGroups"
                  :key="option_group.id"
                >
                  <td>{{ option_group.no }}</td>
                  <td>{{ option_group.name }}</td>
                  <td>{{ option_group.tax_type }}</td>
                  <td>{{ option_group.required ? '◯' : '' }}</td>
                  <td>{{ option_group.enable_description_button ? '◯' : '' }}</td>
                  <td>{{ option_group.option_item_count }}</td>
                  <td class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          icon
                          color="cyan darken-2"
                          @click="editOptionGroupButtonClickEvent(option_group)"
                          v-on="on"
                        >
                          <v-icon>mdi-file-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>編集</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="hasOptionGroups"
      class="mb-3"
    >
      <v-col>
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'OptionGroupIndex',
  components: {
  },
  mixins: [
  ],
  data () {
    return {
      page: 1,
      filterDialog: false,
      totalVisible: 7,
      panel:[],
      inputtedKeyword: null,
      optionGroups: [],
    }
  },
  computed: {
    ...mapState('backOptionGroup', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
    ...mapGetters('backOptionGroup', [
      'hasOptionGroups'
    ])
  },
  /**
   * 状態監視
   */
  watch: {
    inputtedKeyword(newVal) {
      this.fetchQuery({ keyword: newVal })
    },
  },
  created() {
    this.inputtedKeyword = this.searchQuery.keyword
    this.page = this.pagination.current_page
    this.searchOptionGroups()
  },
  methods: {
    ...mapActions('backOptionGroup', {
      fetchOptionGroups: 'fetchAll',
      fetchQuery: 'fetchQuery'
    }),
    addOptionGroupButtonClickEvent () {
      this.$router.push({name: 'byOptionGroupNew'})
    },
    editOptionGroupButtonClickEvent(element) {
      this.$router.push({name: 'byOptionGroupEdit', params: {id: element.id}})
    },
    resetOptionGroup () {
      this.panel=[]
      this.page = 1
      this.searchOptionGroups()
    },
    changePage () {
      this.searchOptionGroups()
    },
    searchOptionGroups () {
      const params = {
        keyword: this.inputtedKeyword,
        page: this.page
      }
      this.fetchOptionGroups(params).then((content) => {
        this.optionGroups = content.option_groups
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
