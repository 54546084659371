<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon class="mr-2">
            mdi-card-account-details
          </v-icon>Profile<span class="subtitle-2 ml-4">プロフィール編集</span>
          <span class="lang_lbl">{{ $t('view.編集') }}</span>
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <v-card>
      <v-card-text>
        <membership-profile-form
          v-if="customer"
          :customer="customer"
          :is-edit="true"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import { mapActions } from 'vuex'
  import MembershipProfileForm from './_Form'
  import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";

  export default {
    name: 'MembershipProfileEdit',
    components: {
      MembershipProfileForm
    },
    mixins: [
      PageHeaderMixin
    ],
    data () {
      return {
        customer: null,
      }
    },
    created() {
      this.descriptionContent = this.$route.meta.siteSubTitle
      this.pageTitle = this.$route.meta.siteSubTitle
      this.editCustomer().then((content) => {
        this.customer = content
      })
    },
    methods: {
      ...mapActions('frontCustomer', {
        editCustomer: 'edit'
      })
    },
  }
</script>

<style scoped lang="scss">
</style>