/**
 * 在庫
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/product_stocks`

const stock = {
  namespaced: true,
  state: {
    stock: null,
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {
      status: '',
      category_ids: [],
      tag_ids: [],
      keyword: '',
      part_no: null,
      local_stock_item: false,
    },
  },
  getters: {
    hasStocks: ( state ) => {
      return (state.pagination.total_count > 0)
    },
    stockDetail(state) {
      return state.stock
    }
  },
  actions: {
    findStocks ({ _commit },{ params }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          resolve(data.content)
        })
      })
    },
    fetchStocks({ dispatch, commit, state }, { status, category_ids, tag_ids, part_no, keyword, local_stock_item, page }) {
      const queries = {
        status: status,
        category_ids: category_ids,
        tag_ids: tag_ids,
        part_no: part_no,
        keyword: keyword,
        local_stock_item: local_stock_item
      }
      dispatch('fetchQuery', queries)
      const params = {
        status: state.query.status,
        part_no: state.query.part_no,
        keyword: state.query.keyword,
        category_ids: state.query.category_ids,
        tag_ids: state.query.tag_ids,
        local_stock_item: state.query.local_stock_item,
        page: page
      }

      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_STOCK, { content })
          resolve(data.content)
        })
      })
    },
    fetchQuery({ commit, state}, { _status, category_ids, tag_ids, part_no, keyword, local_stock_item }) {
      const status = (_status !== undefined)? _status : state.query.status
      const categoryIds = (category_ids !== undefined)? category_ids : state.query.category_ids
      const tagIds = (tag_ids !== undefined)? tag_ids : state.query.tag_ids
      const partNo = (part_no !== undefined) ? part_no : state.query.part_no
      const keywordText = (keyword !== undefined)? keyword : state.query.keyword
      const localStockItem = (local_stock_item !== undefined)? local_stock_item : state.query.local_stock_item
      const params = {
        status: status,
        category_ids: categoryIds,
        tag_ids: tagIds,
        part_no: partNo,
        keyword: keywordText,
        local_stock_item: localStockItem,
      }
      commit(types.BACK_STOCK_QUERY, { params })
    },
    resetQuery({ commit, state }) {
      const params = {
        status: '',
        category_ids: [],
        tag_ids: [],
        part_no: null,
        keyword: '',
        local_stock_item: false,
      }
      commit(types.BACK_STOCK_QUERY, { params })
    },
    fetch ({ commit, state }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          commit(types.BACK_STOCK_DETAIL, { content })
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.BACK_STOCK] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_STOCK_QUERY] (state, { params }) {
      state.query.status = params.status
      state.query.category_ids = params.category_ids
      state.query.tag_ids = params.tag_ids
      state.query.part_no = params.part_no
      state.query.keyword = params.keyword
      state.query.local_stock_item = params.local_stock_item
    },
    [types.BACK_STOCK_DETAIL] (state, { content }) {
      state.stock = content
    }
  },
}

export default stock