<!-- バックヤード > システム設定 > 返品先情報タブ -->
<template>
  <div>
    <alert-message />
    <validation-observer v-slot="{ invalid }">
      <v-form>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="名称"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="configuration.name"
                    label="名称"
                    dense
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{required: true, regex:/^[0-9]{3}[-][0-9]{4}$/}"
                  name="郵便番号"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="configuration.zip_code"
                    label="郵便番号"
                    dense
                    hint="半角数字とハイフン(-)で入力して下さい"
                    persistent-hint
                    outlined
                    clearable
                    placeholder=" "
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="都道府県"
                  class="requiredMark"
                >
                  <v-select
                    v-model="configuration.prefecture_code"
                    label="都道府県"
                    :items="prefList"
                    dense
                    hide-details="auto"
                    outlined
                    placeholder=" "
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="8"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="住所"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="configuration.address"
                    label="住所"
                    dense
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="電話番号"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="configuration.tel"
                    label="電話番号"
                    dense
                    hide-details="auto"
                    hint="半角数字とハイフン(-)で入力して下さい"
                    persistent-hint
                    outlined
                    clearable
                    placeholder=" "
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="invalid"
              @click="registerButtonClickEvent"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import AlertMessage from '../../../../components/AlertMessage'
import PrefectureList from '../../../../mixins/PrefectureList'
import { mapActions } from 'vuex'
export default {
  components: {
    AlertMessage
  },
  mixins: [
    PrefectureList,
  ],
  props: {
    configuration: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
    }
  },
  created() {
  },
  methods: {
    ...mapActions('backConfiguration', {
      updateConfiguration: 'update'
    }),
    registerButtonClickEvent () {
      let params = {
        name: this.configuration.name,
        zip_code: this.configuration.zip_code,
        prefecture_code: this.configuration.prefecture_code,
        address: this.configuration.address,
        tel: this.configuration.tel
      }
      this.updateConfiguration({ params: params })
    }
  },
}
</script>

<style lang="scss">

</style>
