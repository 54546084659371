<template>
  <v-form>
    <alert-message />
    <validation-observer v-slot="{ invalid }">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col
              cols="12"
              sm="12"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required|max:255"
                :name="'ログインID（' + $t('view.ログインID') + '）'"
                class="requiredMark"
              >
                <v-text-field
                  v-model="loginId"
                  :label="'ログインID（' + $t('view.ログインID') + '）'"
                  placeholder=" "
                  counter="255"
                  autocomplete="off"
                  :error-messages="errors"
                  required
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row v-if="!isEdit">
            <v-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                vid="password"
                :name="'パスワード（' + $t('view.パスワード') + '）'"
                class="requiredMark"
              >
                <v-text-field
                  v-model="password"
                  append-icon="mdi-eye-off"
                  :label="'パスワード（' + $t('view.パスワード') + '）'"
                  autocomplete="new-password"
                  placeholder=" "
                  required
                  :error-messages="errors"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required|confirmed:password"
                :name="'パスワード（' + $t('view.パスワード') + '）'"
              >
                <v-text-field
                  v-model="passwordConfirmation"
                  :label="'パスワード(確認用)（' + $t('view.パスワード_確認用') + '）'"
                  autocomplete="new-password"
                  placeholder=" "
                  type="password"
                  required
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                rules="required|email|max:255"
                :name="'メールアドレス（' + $t('view.メールアドレス') + '）'"
                class="requiredMark"
              >
                <v-text-field
                  v-model="email"
                  :label="'メールアドレス（'+  $t('view.メールアドレス')  + '）'"
                  autocomplete="off"
                  placeholder=" "
                  counter="255"
                  :error-messages="errors"
                  required
                />
              </validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required|max:30"
                :name="'氏名（' + $t('view.氏名') + '）'"
                class="requiredMark"
              >
                <v-text-field
                  v-model="name"
                  :label="'氏名（' + $t('view.氏名') + '）'"
                  counter="30"
                  placeholder=" "
                  required
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="max:30"
                name="フリガナ"
                class=""
              >
                <v-text-field
                  v-model="nameKana"
                  label="フリガナ"
                  placeholder=" "
                  counter="30"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required|max:20"
                :name="'公開名（' + $t('view.公開名') + '）'"
                class="requiredMark"
              >
                <v-text-field
                  v-model="bundleName"
                  :label="'公開名（' + $t('view.公開名') + '）'"
                  placeholder=" "
                  :hint="'商品レビューに表示されます（' + $t('view.商品レビューに表示されます') + '）'"
                  persistent-hint
                  counter="20"
                  required
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="'生年月日（' + $t('view.生年月日') + '）'"
                  >
                    <v-text-field
                      v-model="birthday"
                      :label="'生年月日（' + $t('view.生年月日') + '）'"
                      readonly
                      placeholder=" "
                      required
                      :error-messages="errors"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="birthday"
                  :max="date"
                  min="1920-01-01"
                  :day-format="date => new Date(date).getDate()"
                  @change="saveBirthday"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-if="customer_items">
            <v-col
              v-for="item in customer_items"
              :key="item.customer_item_id"
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                :rules="(item.required)? 'required|max:50' : 'max:50'"
                :name="item.title"
                :class="(item.required)? 'requiredMark' : ''"
              >
                <v-text-field
                  v-model="item.value"
                  :label="item.title"
                  placeholder=" "
                  counter="50"
                  required
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="6"
              sm="3"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="numeric|max:7"
                :name="'郵便番号（' + $t('view.郵便番号') + '）'"
              >
                <v-text-field
                  v-model="zipCode"
                  :label="'郵便番号（' + $t('view.郵便番号') + '）'"
                  placeholder=" "
                  counter="7"
                  hint="ハイフン(-)無しで入力してください"
                  required
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="3">
              <zip-code-search
                :zip-code="zipCode"
                @zipAddress="setZipAddress"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="3"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="'都道府県（' + $t('view.都道府県') + '）'"
              >
                <v-select
                  v-model="prefectureCode"
                  item-text="text"
                  item-value="value"
                  :items="prefList"
                  :label="'都道府県（' + $t('view.都道府県') + '）'"
                  placeholder=" "
                  required
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                rules="max:100"
                :name="'市区町村・番地（' + $t('view.市区町村_番地') + '）'"
              >
                <v-text-field
                  v-model="address"
                  :label="'市区町村・番地（' + $t('view.市区町村_番地') + '）'"
                  placeholder=" "
                  counter="100"
                  required
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="numeric|max:11"
                :name="'電話番号（自宅 または 携帯電話）（' + $t('view.電話番号') + '）'"
              >
                <v-text-field
                  v-model="phone"
                  :label="'電話番号（自宅 または 携帯電話）（' + $t('view.電話番号') + '）'"
                  placeholder=" "
                  counter="11"
                  hint="ハイフン(-)無しで入力してください"
                  required
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="max:25"
                :name="'海外の方の連絡先電話番号（' + $t('view.国際電話番号') + '）'"
              >
                <p :class="telClassName" style="font-size: 12px;" >
                  海外の方の連絡先電話番号<span class="lang_lbl">{{ $t('view.国際電話番号') }}</span>
                </p>
                <vue-tel-input
                  v-model="internationalPhone"
                  v-bind="vueTelProps"
                  name="international_phone"
                  :error-messages="errors"
                />
                <v-input :error-messages="errors" v-show="errors[0] != null"/>

              </validation-provider>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col
              cols="12"
              sm="6"
            >
              <v-card-subtitle class="pl-0 pb-0 caption">
                性別（{{ $t('view.性別') }}）
              </v-card-subtitle>
              <v-radio-group
                v-model="gender"
                row
                class="mt-0"
              >
                <v-radio
                  v-for="(item, index) in customer.gender_types"
                  :key="`gender_${index}`"
                  :label="item.text + '(' +$t('view.'+item.text) + ')'"
                  :value="item.value"
                />
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
              class="text-center"
            >
              <h4 v-if="!isEdit">
                ご登録いただいたメールアドレスに認証メールが届きます。 認証メールに記載されたURLへアクセスすることで認証が完了しログインが可能となります。
                <br>{{ $t('view.会員登録注意書き_1') }}
                <br>{{ $t('view.会員登録注意書き_2') }}
              </h4>
              <br>
              <v-btn
                color="primary"
                :block="$vuetify.breakpoint.mdAndDown"
                :disabled="invalid"
                @click="submit"
              >
                <v-icon class="mr-2">
                  mdi-account-edit-outline
                </v-icon>
                登録
                <div class="lang_lbl">{{$t('view.登録')}}</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </validation-observer>
  </v-form>
</template>

<style>
.v-radio .v-icon,
.v-radio .v-label{
  font-size:14px !important;
}
.v-radio .v-input--selection-controls__input{
  margin-right:0px !important;
}
</style>

<script>

  import { mapActions } from 'vuex'
  import prefectureList from './../../../../mixins/PrefectureList'
  import alertMessage from '../../../../components/AlertMessage'
  import zipCodeSearch from '../../../components/field/ZipCodeSearch'

  export default {
    name: 'MembershipProfileForm',
    components: {
      alertMessage,
      zipCodeSearch,
    },
    mixins: [
      prefectureList,
    ],
    props: {
      customer: {
        type: Object,
        default: () => {
          return {}
        },
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        showPassword: false,
        date: new Date().toISOString().substr(0, 10),
        menu: false,
        name: this.customer.name,
        nameKana: this.customer.name_kana,
        bundleName: this.customer.bundle_name,
        gender: this.customer.gender,
        birthday: this.customer.birthday,
        zipCode: this.customer.zip_code,
        prefectureCode: this.customer.prefecture_code,
        address: this.customer.address,
        phone: this.customer.phone,
        internationalPhone: this.customer.international_phone,
        email: this.customer.email,
        loginId: this.customer.login_id,
        password: '',
        passwordConfirmation: '',
        customer_items: this.customer.customer_items,
        telClassName: "mt-n5 ma-0",

        // 以下、国際電話用
        vueTelProps: {
          mode: "international",
          defaultCountry: "",
          disabledFetchingCountry: false,
          disabled: false,
          disabledFormatting: false,
          name : "international_phone",
          id : "international_phone",
          placeholder: "海外の方の連絡先電話番号(" + this.$i18n.t('view.国際電話番号') + ")",
          required: false,
          enabledCountryCode: false,
          enabledFlags: true,
          preferredCountries: ["US", "CN"],   // ドロップダウンで上位に持ってくる国(利用頻度の高そうな国..とりあえずアメリカと中国)
          onlyCountries: [],
          // ignoredCountries: ["US", "CN"],
          autocomplete: "off",
          maxLen: 25,
          wrapperClasses: "country-phone-input",
          inputClasses: "",
          inputOptions: {
           showDialCode: true   // true:選択されている国のコードをinputにセットする
          },
          dropdownOptions: {
            disabledDialCode: false
          },
          // counter:"11",
          // hint:"ハイフン(-)無しで入力してください",
          // required:required
        },
      }
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    methods: {
      ...mapActions('frontCustomer', {
        createCustomer: 'create',
        updateCustomer: 'update',
      }),
      phoneObject: function(errors) {
        console.log('phoneObject *******************' + errors);
        // console.log(object);
        // if (object.valid === true) {
        //   this.isValid = true;
        // } else {
        //   this.isValid = false;
        // }
      },

      saveBirthday (date) {
        this.$refs.menu.save(date)
      },
      buildParams () {
        let params = {
          name: this.name,
          name_kana: this.nameKana,
          bundle_name: this.bundleName,
          gender: this.gender,
          zip_code: this.zipCode,
          prefecture_code: this.prefectureCode,
          address: this.address,
          phone: this.phone,
          international_phone:this.internationalPhone,
          email: this.email,
          birthday: this.birthday,
          login_id: this.loginId,
        }
        if (!this.isEdit) {
          params = { ...params, ...{password: this.password} }
        }
        if (this.customer_items) {
          params = { ...params, ...{customer_values_attributes: this.customer_items} }
        }
        return params
      },
      submit() {
        let params = this.buildParams()
        if (this.isEdit) {
          this.updateCustomer({ params }).then((content) => {
            //  汎用項目のみ再設定
            this.customer_items = content.customer_items
          })
        } else {
          this.createCustomer({ params }).then((content) => {
            //  汎用項目のみ再設定
            this.customer_items = content.customer_items
            this.$router.push({name: 'MembershipProfileCompleted'})
          })
        }
      },
      setZipAddress (content) {
        this.prefectureCode = `${content.pref_code}`
        this.address = `${content.locality}${content.street}`
      },
    },
  }
</script>

<style scoped lang="scss">
</style>