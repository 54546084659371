<!-- バックヤード > 商品詳細 > 基本情報 -->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form v-if="product">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules=""
                  name="公開設定"
                  class=""
                >
                  <v-checkbox
                    v-model="product.component_item"
                    label="セット商品"
                    hide-details="auto"
                    class="pa-0 ma-0"
                    :disabled="product.local_stock_item"
                    :error-messages="errors"
                  />
                </validation-provider>
                <p>セット商品として利用する場合はチェックをいれてください。(現地在庫品はセット商品にはできません。)</p>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <div class="float-right">
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                    name="公開設定"
                    class=""
                  >
                    <v-checkbox
                      v-model="product.published"
                      label="公開する"
                      hide-details="auto"
                      class="pa-0 ma-0"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </div>
              </v-col>
            </v-row>

            <!-- カテゴリー-->
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules=""
                  name="カテゴリー"
                  class=""
                >
                  <v-select
                    v-model="product.category_ids"
                    :items="categoryContent.categories"
                    item-text="name"
                    item-value="id"
                    multiple
                    dense
                    label="カテゴリー(複数選択可)"
                    hide-details="auto"
                    outlined
                    placeholder=" "
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row><!-- /カテゴリー-->

            <!-- 商品名, 商品名かな -->
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:30"
                  name="商品名"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="product.name"
                    label="商品名"
                    dense
                    hide-details="auto"
                    outlined
                    placeholder=" "
                    required
                    counter="30"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="商品名カナ"
                  rules="max:30"
                >
                  <v-text-field
                    v-model="product.kana_name"
                    label="商品名カナ"
                    dense
                    hide-details="auto"
                    outlined
                    placeholder=" "
                    counter="30"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row><!-- /商品名, 商品名かな -->

            <!-- 品番1,2,3 -->
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:30"
                  name="品番1"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="product.part_number_1"
                    label="品番1"
                    dense
                    hide-details="auto"
                    outlined
                    placeholder=" "
                    required
                    counter="30"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:30"
                  name="品番2"
                  class=""
                >
                  <v-text-field
                    v-model="product.part_number_2"
                    dense
                    label="品番2"
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                    counter="30"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:30"
                  name="品番3"
                  class=""
                >
                  <v-text-field
                    v-model="product.part_number_3"
                    dense
                    label="品番3"
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                    counter="30"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row><!-- /品番1,2,3 -->

            <!-- 現地在庫品 -->
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-checkbox
                  v-model="product.local_stock_item"
                  label="現地在庫品"
                  hide-details="auto"
                  class="pa-0 ma-0"
                  @change="localStockItemChanged(product)"
                  :disabled="isEdit"
                />
                <p>発送を伴わない、現地での受取／現地での在庫管理を行う商品</p>
              </v-col>

              <v-col
                cols="12"
                md="6"
                style="padding-bottom:0px"
              >
                <validation-provider
                  v-slot="{ errors }"
                  class="requiredMark"
                  name="現地名"
                  :rules="product.local_stock_item ? 'required' : ''"
                >

                  <v-select
                    v-model="product.local_place_id"
                    :items="product.local_places"
                    :disabled="!product.local_stock_item"
                    label="現地名"
                    item-text="name"
                    item-value="id"
                    dense
                    hide-details="auto"
                    outlined
                    placeholder=" "
                    :error-messages="errors"
                  />

                </validation-provider>

              </v-col>

            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
              </v-col>

              <v-col
                cols="12"
                md="6"
                style="padding-bottom:0px"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="受取場所(コメント)"
                  rules="max:255"
                >
                  <v-text-field
                    v-model="product.receive_comment"
                    :disabled="!product.local_stock_item"
                    label="受取場所(コメント)"
                    dense
                    hide-details="auto"
                    outlined
                    placeholder=" "
                    counter="255"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
              </v-col>

              <v-col
                cols="12"
                md="4"
                style="padding-bottom:0px"
              >
                <v-checkbox
                  v-model="product.use_calendar"
                  :disabled="!product.local_stock_item || product.payment_type == 'sale_payment' || isEdit"
                  label="スケジュール使用"
                  hide-details="auto"
                  class="pa-0 ma-0"
                  @change="useCalendarChange(product)"
                />
                <p>一つの商品に対しレンタル期間の重複がなく、<br>複数のレンタル予約を可能とする場合チェックをいれてください。<br>※在庫状態は変更されなくなります。</p>
              </v-col>

            </v-row>

            <!-- 金額, 税設定, ポイント付与 -->
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-checkbox
                  v-model="product.tax_exempt"
                  label="非課税商品"
                  hide-details="auto"
                  class="ma-1"
                />
              </v-col>
            </v-row><!-- /金額, 税設定, ポイント付与 -->

            <!-- 送料なしフラグ -->
            <v-row>
              <v-col
                  cols="12"
                  md="2"
              >
                <v-checkbox
                    v-model="product.free_shipping_flag"
                    label="送料なし"
                    hide-details="auto"
                    class="ma-1"
                />
              </v-col>
            </v-row><!-- /金額, 税設定, ポイント付与 -->

            <!-- 表示期間 -->
            <v-row>
              <v-col
                cols="12"
              >
                <div>表示期間</div>
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-menu
                          v-model="startDateMenu"
                          :close-on-content-click="false"
                          offset-y
                          transition="scale-transition"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <validation-provider
                              v-slot="{ errors }"
                              rules=""
                              name="開始日"
                              class=""
                            >
                              <v-text-field
                                v-model="product.display_period_start_date"
                                :error-messages="errors"
                                label="開始日"
                                dense
                                readonly
                                hide-details="auto"
                                outlined
                                clearable
                                placeholder=" "
                                v-on="on"
                                @click:clear="product.display_period_start_date = null"
                              >
                                <template
                                  v-if="!$vuetify.breakpoint.smAndDown"
                                  v-slot:append-outer
                                >
                                  〜
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </template>
                          <v-date-picker
                            v-model="product.display_period_start_date"
                            @input="startDateMenu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-menu
                          v-model="endDateMenu"
                          :close-on-content-click="false"
                          offset-y
                          transition="scale-transition"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <validation-provider
                              v-slot="{ errors }"
                              rules=""
                              name="終了日"
                              class=""
                            >
                              <v-text-field
                                v-model="product.display_period_end_date"
                                dense
                                label="終了日"
                                readonly
                                hide-details="auto"
                                outlined
                                clearable
                                placeholder=" "
                                :error-messages="errors"
                                v-on="on"
                                @click:clear="product.display_period_end_date = null"
                              />
                            </validation-provider>
                          </template>
                          <v-date-picker
                            v-model="product.display_period_end_date"
                            @input="endDateMenu = false"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row><!-- /表示期間 -->

            <!-- 新着表示期間 -->
            <v-row>
              <v-col>
                <div>新着表示期間</div>
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-menu
                          v-model="new_arrival_period_start_menu"
                          :close-on-content-click="false"
                          offset-y
                          transition="scale-transition"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <validation-provider
                              v-slot="{ errors }"
                              rules=""
                              name="開始日"
                              class=""
                            >
                              <v-text-field
                                v-model="product.new_arrival_period_start_date"
                                label="開始日"
                                dense
                                readonly
                                hide-details="auto"
                                outlined
                                clearable
                                placeholder=" "
                                :error-messages="errors"
                                v-on="on"
                                @click:clear="product.new_arrival_period_start_date = null"
                              >
                                <template
                                  v-if="!$vuetify.breakpoint.smAndDown"
                                  v-slot:append-outer
                                >
                                  〜
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </template>
                          <v-date-picker
                            v-model="product.new_arrival_period_start_date"
                            @input="new_arrival_period_start_menu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-menu
                          v-model="new_arrival_period_end_menu"
                          :close-on-content-click="false"
                          offset-y
                          transition="scale-transition"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <validation-provider
                              v-slot="{ errors }"
                              rules=""
                              name="終了日"
                              class=""
                            >
                              <v-text-field
                                v-model="product.new_arrival_period_end_date"
                                label="終了日"
                                dense
                                readonly
                                hide-details="auto"
                                outlined
                                clearable
                                placeholder=" "
                                :error-messages="errors"
                                v-on="on"
                                @click:clear="product.new_arrival_period_end_date = null"
                              />
                            </validation-provider>
                          </template>
                          <v-date-picker
                            v-model="product.new_arrival_period_end_date"
                            @input="new_arrival_period_end_menu = false"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row><!-- /新着表示期間 -->

            <!-- 表示順 -->
            <v-row v-if="isEdit">
              <v-col cols="2">
                <validation-provider
                  v-slot="{ errors }"
                  class="requiredMark"
                  name="表示順"
                  rules="required|numeric|min_value:0|max_value:2147483647"
                >
                  <v-text-field
                    v-model="product.position"
                    dense
                    label="表示順"
                    hide-details="auto"
                    reverse
                    outlined
                    placeholder=" "
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row><!-- /表示順 -->

            <!-- 備考 -->
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules=""
                  name="備考"
                  class=""
                >
                  <v-textarea
                    v-model="product.note"
                    outlined
                    clearable
                    counter="512"
                    label="備考（本内容はサイトには反映されません。メモ情報となります）"
                    auto-grow
                    row-height="15"
                    dense
                    hide-details="auto"
                    placeholder=" "
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row><!-- 備考 -->

            <!-- メタタグ -->
            <v-row>
              <v-col cols="12">
                <h4>独自メタ情報</h4>
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          rules="max:140"
                          name="説明"
                        >
                          <v-text-field
                            v-model="product.description_meta_tag_attributes.content"
                            outlined
                            counter="140"
                            label=""
                            dense
                            hide-details="auto"
                            placeholder=" "
                            :error-messages="errors"
                          >
                            <template v-slot:prepend>
                              {{ product.description_meta_tag_attributes.meta_name }}
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row><!-- /メタタグ -->

            <template v-if="companyMode==='rental'">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <h4 v-if="product.use_subscription || product.use_sale">
                    支払方法
                  </h4>
                  <v-radio-group
                    v-if="product.use_subscription || product.use_sale"
                    v-model="product.payment_type"
                    row
                    dense
                    hide-details="auto"
                    class="pa-0 ma-0"
                    :disabled="isEdit"
                  >
                    <v-radio
                      label="レンタル"
                      value="one_time_payment"
                    />
                    <v-radio
                      v-if="product.use_subscription"
                      label="定期課金"
                      value="subscription_payment"
                      :disabled = "product.local_stock_item || product.use_calendar"
                    />
                    <v-radio
                      v-if="product.use_sale"
                      label="販売"
                      value="sale_payment"
                      :disabled = "product.use_calendar"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row
                v-if="companyMode === 'rental' && product.payment_type != 'sale_payment'"
              >
                <v-col
                  cols="12"
                  md="2"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|numeric|max_value:2147483647"
                    name="貸出開始日（何日後）"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="product.schedule_start_day"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="貸出開始日（何日後）"
                      outlined
                      placeholder=" "
                      required
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <template v-if="product.payment_type === 'one_time_payment'">
                <v-row>
                  <v-col
                      cols="12"
                      class="pb-0"
                  >
                    <v-checkbox
                        v-model="product.use_extension"
                        label="延長可能商品"
                        hide-details="auto"
                        class="pa-0 ma-0"
                        :disabled = "product.local_stock_item"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <h4>料金設定</h4>
                    <v-radio-group
                      v-model="product.price_type"
                      row
                      dense
                      hide-details="auto"
                      class="pa-0 ma-0"
                    >
                      <v-radio
                        label="1日あたり利用料金による加算料金"
                        value="daily_price"
                      />
                      <v-radio
                        label="選択肢による料金"
                        value="menu_price"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div>1日あたり利用料金による加算料金</div>
                    <v-card outlined>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required|numeric|max_value:2147483647"
                              name="金額(税抜)"
                              class="requiredMark"
                            >
                              <v-text-field
                                v-model="product.price"
                                dense
                                label="金額(税抜)"
                                reverse
                                prefix="円"
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                required
                                :error-messages="errors"
                              />
                              <p>一日あたりの利用料金を入力してください</p>
                            </validation-provider>
                          </v-col>
                        </v-row><!-- /金額, 税設定, ポイント付与 -->

                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="ディスカウント価格(税抜)"
                              rules="numeric|max_value:2147483647"
                            >
                              <v-text-field
                                v-model="product.discount_price"
                                dense
                                label="ディスカウント価格(税抜)"
                                reverse
                                prefix="円"
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="max:20"
                              name="ディスカウントタイトル"
                            >
                              <v-text-field
                                v-model="product.discount_title"
                                label="ディスカウントタイトル"
                                dense
                                hide-details="auto"
                                outlined
                                placeholder="ディスカウント価格"
                                required
                                counter="20"
                                :error-messages="errors"
                              />
                              <p>※未入力の場合は「ディスカウント価格」となります。</p>
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="7"
                            md="2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required|numeric|max_value:2147483647"
                              name="レンタル期間（初期値）"
                              class="requiredMark"
                            >
                              <v-text-field
                                v-model="product.rental_period"
                                label="レンタル期間（初期値）"
                                dense
                                reverse
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                required
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="4"
                            md="2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="単位"
                              class="requiredMark"
                            >
                              <v-select
                                v-model="product.rental_period_type"
                                :items="product.rental_period_types"
                                label="単位"
                                dense
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                required
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required|numeric|max_value:2147483647"
                              name="貸出可能最低日数"
                              class="requiredMark"
                            >
                              <v-text-field
                                v-model="product.rental_minimum_days"
                                label="貸出可能最低日数"
                                dense
                                reverse
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                required
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>

                          <v-col
                            cols="12"
                            md="2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required|numeric|max_value:2147483647"
                              name="貸出可能最大日数"
                              class="requiredMark"
                            >
                              <v-text-field
                                v-model="product.rental_maximum_days"
                                label="貸出可能最大日数"
                                dense
                                reverse
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                required
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            class="pb-0"
                          >
                            <v-checkbox
                              v-model="product.changed_rental_period"
                              label="レンタル期間の変更を許可"
                              hide-details="auto"
                              class="pa-0 ma-0"
                            />
                            <p>申込時にレンタル期間の変更を許可する場合はチェックをいれてください。</p>
                          </v-col>
                        </v-row>
<!--                        <v-row>-->
<!--                          <v-col-->
<!--                            cols="12"-->
<!--                            class="pb-0"-->
<!--                          >-->
<!--                            <v-checkbox-->
<!--                              v-model="product.use_extension"-->
<!--                              label="延長可能商品"-->
<!--                              hide-details="auto"-->
<!--                              class="pa-0 ma-0"-->
<!--                              :disabled = "product.local_stock_item"-->
<!--                            />-->
<!--                          </v-col>-->
<!--                        </v-row>-->
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12">
                    <div class="float-right">
                      <v-btn
                        small
                        @click="price_add"
                      >
                        料金追加
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <div>選択肢による料金</div>
                    <v-tabs
                      v-model="price_tabs"
                      background-color="blue-grey lighten-4"
                    >
                      <template
                        v-for="(product_price, i) in product.product_prices_attributes"
                      >
                        <v-tab
                          v-if="!product_price._destroy"
                          :key="i"
                        >
                          {{ product_price.name }} ({{ product_price.price | comma_format }}円)
                        </v-tab>
                      </template>
                      <template
                        v-for="(product_price, i) in product.product_prices_attributes"
                      >
                        <v-tab-item
                          v-if="!product_price._destroy"
                          :key="'tab-' + i"
                          :eager="true"
                        >
                          <price-form
                            :price="product_price"
                          />
                          <v-col cols="12">
                            <div class="float-right">
                              <v-btn
                                small
                                @click="price_delete(product_price)"
                              >
                                料金削除
                              </v-btn>
                            </div>
                          </v-col>
                        </v-tab-item>
                      </template>
                    </v-tabs>
                  </v-col>
                </v-row>
              </template>

              <template v-if="product.payment_type === 'subscription_payment'">
                <v-row>
                  <v-col>
                    <v-card outlined>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required|numeric|max_value:2147483647"
                              name="金額(税抜)"
                              class="requiredMark"
                            >
                              <v-text-field
                                v-model="product.product_subscription_attributes.price"
                                :error-messages="errors"
                                dense
                                label="金額(税抜)"
                                reverse
                                prefix="円"
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                required
                              />
                              <p>定額一回あたりの利用料金を入力してください</p>
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="numeric|max_value:2147483647"
                              name="ディスカウント価格(税抜)"
                            >
                              <v-text-field
                                v-model="product.product_subscription_attributes.discount_price"
                                dense
                                label="ディスカウント価格(税抜)"
                                reverse
                                prefix="円"
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="max:20"
                              name="ディスカウントタイトル"
                            >
                              <v-text-field
                                v-model="product.discount_title"
                                label="ディスカウントタイトル"
                                dense
                                hide-details="auto"
                                outlined
                                placeholder="ディスカウント価格"
                                required
                                counter="20"
                                :error-messages="errors"
                              />
                              <p>※未入力の場合は「ディスカウント価格」となります。</p>
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="numeric|min_value:1|max_value:1000"
                              name="数量"
                              class="requiredMark"
                            >
                              <v-text-field
                                v-model="product.product_subscription_attributes.quantity"
                                dense
                                label="数量"
                                hide-details="auto"
                                reverse
                                outlined
                                placeholder=" "
                                required
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="4"
                            md="1"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required"
                              name="単位"
                              class="requiredMark"
                            >
                              <v-select
                                v-model="product.product_subscription_attributes.billing_cycle"
                                :items="product.billing_cycles"
                                label="単位"
                                dense
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                required
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>

                          <v-col
                            cols="8"
                            md="2"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="required|numeric|min_value:2|max_value:2147483647"
                              name="貸出可能最大期間"
                              class="requiredMark"
                            >
                              <v-text-field
                                v-model="product.product_subscription_attributes.rental_minimum_period"
                                label="貸出可能最大期間"
                                dense
                                reverse
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                required
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>

              <template v-if="product.payment_type === 'sale_payment'">
                <v-row>
                  <v-col>
                    <v-card outlined>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="numeric|max_value:2147483647"
                              name="販売価格(税抜)"
                            >
                              <v-text-field
                                v-model="product.price"
                                dense
                                label="販売価格(税抜)"
                                reverse
                                prefix="円"
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                required
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="numeric|max_value:2147483647"
                              name="ディスカウント価格(税抜)"
                            >
                              <v-text-field
                                v-model="product.discount_price"
                                dense
                                label="ディスカウント価格(税抜)"
                                reverse
                                prefix="円"
                                hide-details="auto"
                                outlined
                                placeholder=" "
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="max:20"
                              name="ディスカウントタイトル"
                            >
                              <v-text-field
                                v-model="product.discount_title"
                                label="ディスカウントタイトル"
                                dense
                                hide-details="auto"
                                outlined
                                placeholder="ディスカウント価格"
                                required
                                counter="20"
                                :error-messages="errors"
                              />
                              <p>※未入力の場合は「ディスカウント価格」となります。</p>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </template>

            <template v-else-if="companyMode==='auction'">
              <v-row>
                <v-col cols="2">
                  <h4>料金設定</h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|numeric|max_value:2147483647"
                    name="最低落札額"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="product.price"
                      dense
                      label="最低落札額"
                      reverse
                      prefix="円"
                      hide-details="auto"
                      outlined
                      clearable
                      placeholder=" "
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="2">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|numeric|max_value:2147483647"
                    name="入札単位額(税抜)"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="product.unit_bid_amount"
                      dense
                      label="入札単位額(税抜)"
                      reverse
                      prefix="円"
                      hide-details="auto"
                      outlined
                      clearable
                      placeholder=" "
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </template>

            <v-card flat>
              <v-card-actions>
                <v-btn
                  v-if="isEdit"
                  color="error"
                  @click="productDeleteButtonClick"
                >
                  削除
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="cancelButtonClick"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="registerButtonClick"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <confirm ref="confirm" />
  </div>
</template>

<script>

import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import PriceForm from '../shared/_Price'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    AlertMessage,
    Confirm,
    PriceForm
  },
  mixins: [
  ],
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    companyMode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      startDateMenu: false,
      endDateMenu: false,
      new_arrival_period_start_menu: false,
      new_arrival_period_end_menu: false,
      price_tabs: null,
      prices: [],
      tmp_prices: [],
    }
  },
  computed: {
    ...mapState('backSearchCategory', {
      categoryContent: 'content'
    }),
  },
  methods: {
    ...mapActions('backProduct', {
      createProduction: 'create',
      updateProduction: 'update',
      destroyProduct: 'destroy'
    }),
    ...mapActions('backLocalPlace', {
      fetchLocalPlaces: 'fetchLocalPlaces',
      fetchQuery: 'fetchQuery',
    }),
    price_add: function () {
      let obj = {
        id: null,
        name: null,
        rental_period: 1,
        price: 0,
        discount_price: 0,
        _destroy: false
      }
      this.product.product_prices_attributes.push(obj)
      // this.prices.push(obj)
    },
    price_delete: function (product_price) {
      product_price._destroy = true
      // if (this.prices[index].id != "") {
      //   this.$set(this.prices[index], '_destroy', true)
      //   this.tmp_prices.push(this.prices[index])
      // }
      // this.prices.splice(index, 1)
    },
    cancelButtonClick () {
      this.$router.push({name: 'byProductIndex'})
    },
    registerButtonClick () {
      // for (let i = 0; i < this.prices.length ; i++) {
      //   this.product.product_prices_attributes.push(this.prices[i])
      // }
      if (this.isEdit) {
        // for (let i = 0; i < this.tmp_prices.length ; i++) {
        //   this.product.product_prices_attributes.push(this.tmp_prices[i])
        // }
        this.updateProduction({ id: this.product.id, params: this.product }).then(() => {
          this.$emit('updateCallback')
        })
      } else {
        this.createProduction({ params: this.product }).then(() => {
          this.$router.push({name: 'byProductIndex'})
        })
      }
    },
    productDeleteButtonClick () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyProduct({id: this.product.id }).then(() =>{
            this.$router.push({name: 'byProductIndex'})
          })
        }
      })
    },
    localStockItemChanged(product) {
      if (!product.local_stock_item) {
        product.receive_comment = ''
        product.local_place_id = null
        product.use_calendar = false
      } else {
        product.component_item = false
        product.use_extension = false
        // 現地在庫品チェック時、支払い方法が定期課金ならレンタルを選択する
        if (product.payment_type == 'subscription_payment') {
          product.payment_type = 'one_time_payment'
        }
      }
    },
    useCalendarChange(product) {
      // 支払い方法が定期課金ならレンタルを選択する
      if (product.payment_type == 'sale_payment') {
        product.payment_type = 'one_time_payment'
      }
    }
  },
}

</script>

<style scoped lang="scss">

</style>
