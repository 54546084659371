<template>
  <v-container
    fluid
    fill-height
  >
    <v-card
      outlined
      class="mx-auto"
      style="width: 24rem;"
    >
      <v-card-title
        color="primary"
        class="justify-center"
      >
        <h1 class="title">
          ログイン
        </h1>
      </v-card-title>
      <v-card-text>
        <alert-message />
        <ValidationObserver v-slot="{ invalid }">
          <v-form class="mt-10">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :name="'ログインID（' + $t('view.ログインID') + '）'"
            >
              <v-text-field
                v-model="loginId"
                :label="'ログインID（' + $t('view.ログインID') + '）'"
                placeholder="LoginId"
                autocomplete="username"
                :error-messages="errors"
                required
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :name="'パスワード（' + $t('view.パスワード') + '）'"
            >
              <v-text-field
                v-model="password"
                class="pt-8"
                :label="'パスワード（' + $t('view.パスワード') + '）'"
                autocomplete="current-password"
                placeholder="******"
                required
                :error-messages="errors"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              />
            </validation-provider>

            <!-- パスワード忘れた方リンク -->
            <v-btn
              text
              color="info"
              @click="moveToForget"
              style="text-align:left"
            >
              <div style="white-space: pre-wrap;" v-html="passwordResetBtn" />
            </v-btn>

            <!-- 初めての方リンク -->
            <v-btn
              v-if="frontendCustomerInput"
              text
              color="info"
              @click="moveToMembership"
              class="mt-3"
              style="text-align:left"
            >
              <div v-html="firstBtn" />
            </v-btn>

            <v-btn
              color="primary"
              block
              outlined
              class="mt-4"
              :disabled="invalid"
              @click="login"
            >
              ログイン
              <span class="lang_lbl">{{$t('view.ログイン')}}</span>
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </v-form>
        </ValidationObserver>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  /**
   * ログイン
   */
  import {mapActions, mapState} from 'vuex'
  import alertMessage from '../../../components/AlertMessage'
  import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";
  import store from "store";

  export default {
    name: 'SessionIndex',
    components: {
      alertMessage,
    },
    mixins: [
      PageHeaderMixin
    ],
    data () {
      return {
        loginId: null,
        password: null,
        showPassword: false,
        passwordResetBtn:"パスワードをお忘れの方／パスワード変更 <div class='lang_lbl'>" + this.$i18n.t('view.パスワード忘れた方') + "</div>",
        firstBtn:"初めての方・会員登録されていない方<div class='lang_lbl'>" + this.$i18n.t('view.初めての方') + "</div>"
      }
    },
    computed: {
      ...mapState('frontSystemSetting', {
        auctionMode: 'auction_mode',
        frontendAuthMode: 'frontend_auth_mode',
        frontendCustomerInput: 'frontend_customer_input'
      }),
    },
    created() {
      this.descriptionContent = this.$route.meta.siteSubTitle
      this.pageTitle = this.$route.meta.siteSubTitle
    },
    methods: {
      ...mapActions('frontAuth', {
        signIn: 'signInFrontend',
      }),
      login () {
        const params = {
          login_id: this.loginId,
          password: this.password,
        }
        this.signIn({ params }).then(() => {

          if (this.frontendAuthMode) {
            this.$router.replace({name: 'HomeIndex'}, () => {})
          } else {
            let prev_page = store.get('prev_page');
            if(prev_page){
              // 戻りページがある場合はそちらに遷移する
              store.set('prev_page', '')
              this.$router.replace({name: prev_page}, () => {})
            }else{
              // 戻りページがない場合はログイン画面前に見ていたページに遷移
              this.$router.go(-1)
            }
          }
        })
      },
      moveToMembership () {
        this.$router.push({ name: 'MembershipProfileNew' })
      },
      moveToForget () {
        this.$router.push( { name: 'ForgetIndex' } )
      }
    }
  }

</script>

<style scoped lang="scss">
</style>