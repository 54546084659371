<template>
  <div>
    <dropzone-image
      :key="refreshKey"
      :images.sync="images"
      @fileChanged="fileChanged"
      @removeFile="removeFile"
    />
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <v-btn
          color="primary"
          :block="$vuetify.breakpoint.mdAndDown"
          :disabled="submitDisabled"
          @click="submit"
        >
          登録
        </v-btn>
      </v-col>
    </v-row>
    <confirm ref="confirm" />
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import DropzoneImage from "../../../../components/DropzoneImage"
import Confirm from '../../../../components/Confirm'

export default {
  name: 'MembershipIdentificationForm',
  components: {
    DropzoneImage,
    Confirm
  },
  props: {
    identifications: {
      type: Array,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      refreshKey: 0,
      images: this.identifications,
      submitDisabled: true,
    }
  },
  methods: {
    ...mapActions('frontIdentification', {
      update: 'update',
      delete: 'delete',
    }),
    fileChanged () {
      this.submitDisabled = false
    },
    removeFile (remove_image, remove_index) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          const target_id = remove_image.id
          // 既存ファイル
          if (target_id) {
            // 削除API
            this.delete({ id: target_id }).then((content) => {
              this.images = content.identifications
              this.refreshDropzoneImage()
            })
          } else {
            this.images.splice(remove_index, 1)
            this.refreshDropzoneImage()
          }
        }
      })
    },
    buildFormData () {
      const params = new FormData()
      for (const image of this.images) {
        if (image.id) {
          params.append('identifications_attributes[][id]', image.id)
        }
        params.append('identifications_attributes[][file]', image.file)
        if (image._destroy) {
          params.append('identifications_attributes[][_destroy]', image._destroy)
        }
      }
      return params
    },
    submit () {
      const params = this.buildFormData()
      this.update({ params }).then((content) => {
        this.images = content.identifications
        this.refreshDropzoneImage()
      })
    },
    refreshDropzoneImage () {
      this.submitDisabled = true
      this.refreshKey++
    }
  },
}
</script>

<style scoped lang="scss">
</style>
