/**
 * 商品カタログ
 */
import Vue from 'vue'
import * as types from '../../../mutation-types/backyard-types'
import * as httpConst from '../../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const product_document = {
    namespaced: true,
    state: {
        product_documents: []
    },
    actions: {
        fetchAll ({ commit },{ product_id }) {
            const api_path = `${API_PATH}/${product_id}/product_documents`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    commit(types.BACK_PRODUCT_DOCUMENT, {content})
                })
            })
        },
        create( { commit }, { product_id, params } ) {
            const api_path = `${API_PATH}/${product_id}/product_documents`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(api_path, params, { headers: { 'content-type': 'multipart/form-data' } }).then( data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    let content = data.content
                    resolve(content)
                } )
            })
        },
        update( { commit }, { product_id, params } ) {
            const api_path = `${API_PATH}/${product_id}/product_documents/${params.id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params, { headers: { 'content-type': 'multipart/form-data'}}).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    let content = data.content
                    resolve(content)
                } )
            })
        },
        destroy( { commit }, { product_id, params } ) {
            const remove_id = params.id
            const api_path = `${API_PATH}/${product_id}/product_documents/${remove_id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    let content = data.content
                    resolve(content)
                })
            })
        },
    },
    mutations: {
        [types.BACK_PRODUCT_DOCUMENT] (state, { content }) {
            state.product_documents = content.product_documents
        }
    },
}

export default product_document