<!-- フロントエンド > パスワードを忘れた方 -->
<template>
  <v-container
    fluid
    fill-height
  >
    <v-card
      outlined
      class="mx-auto"
      style="width: 40rem;"
    >
      <v-card-title
        color="primary"
        class="justify-center"
      >
        <h1 class="title">
          パスワードを忘れた方
          <span class="lang_lbl">{{$t('view.パスワードを忘れた方')}}</span>
        </h1>
      </v-card-title>
      <v-card-subtitle class="mt-2">
        <div>登録されているメールアドレスに再発行の案内を送信します。</div>
        <div class="lang_lbl">{{$t('view.パスワード忘れ_1')}}</div>
        <div class="mt-2">ログインIDまたはご登録のメールアドレスを入力し送信ボタンを押して下さい。</div>
        <div class="lang_lbl">{{$t('view.パスワード忘れ_2')}}</div>

      </v-card-subtitle>
      <v-card-text>
        <alert-message />
        <validation-observer v-slot="{ invalid }">
          <v-form class="mt-5">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :name="'ログインIDまたはメールアドレス（' + $t('view.ログインIDまたはメールアドレス') + '）'"
              class="requiredMark"
            >
              <v-text-field
                v-model="login_id"
                :label="'ログインIDまたはメールアドレス（' + $t('view.ログインIDまたはメールアドレス') + '）'"
                placeholder=" "
                required
                :error-messages="errors"
              />
            </validation-provider>
            <v-spacer class="clearfix mb-5" />
            <v-btn
                class="lang_btn"
              @click="cancelButtonClickEvent"
            >
              キャンセル
              <div class="lang_lbl">{{$t('view.キャンセル')}}</div>
            </v-btn>
            <v-btn
              color="primary"
              :disabled="invalid"
              class="float-right lang_btn"
              @click="sendButtonClickEvent"
            >
              送信
              <div class="lang_lbl">{{$t('view.送信')}}</div>
            </v-btn>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import { mapActions } from 'vuex'
  import alertMessage from '../../../components/AlertMessage'
  import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";

  export default {
    name: 'ForgetIndex',
    components: {
      alertMessage
    },
    mixins: [
        PageHeaderMixin
    ],
    data () {
      return {
        login_id: null
      }
    },
    created() {
      this.descriptionContent = 'パスワードを忘れた方'
      this.pageTitle = 'パスワードを忘れた方'
    },
    methods: {
      ...mapActions('frontForget', {
        regist: 'create'
      }),
      cancelButtonClickEvent: function () {
        this.$router.go(-1)
      },
      sendButtonClickEvent: function () {
        let params = {  login_id: this.login_id }
        this.regist({ params: params }).then((  ) => {
          this.$router.push({name: 'ForgetSent'})
        })
      },
    },
  }
</script>

<style lang="scss">
</style>
