var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert-message'),_vm._v(" "),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"ログインID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"254","dense":"","hide-details":"auto","label":"ログインID","outlined":"","placeholder":" ","required":""},model:{value:(_vm.user.login_id),callback:function ($$v) {_vm.$set(_vm.user, "login_id", $$v)},expression:"user.login_id"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"ユーザー名","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"25","dense":"","hide-details":"auto","label":"ユーザー名","outlined":"","placeholder":" ","required":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"メールアドレス","rules":"required|max:254"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"254","dense":"","hide-details":"auto","label":"メールアドレス","outlined":"","placeholder":" ","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{staticClass:"pa-0 ma-0",attrs:{"mandatory":true,"dense":"","hide-details":"auto","label":"権限","row":""},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}},[_vm._l((_vm.user.roles),function(item){return [_c('v-radio',{key:item.value,attrs:{"value":item.value,"label":item.text}})]})],2)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"表示順","rules":"required|numeric|min_value:0|max_value:2147483647"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"表示順","outlined":"","placeholder":" ","required":"","reverse":""},model:{value:(_vm.user.position),callback:function ($$v) {_vm.$set(_vm.user, "position", $$v)},expression:"user.position"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[(_vm.isEdit&&!_vm.user.initial_user)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteButtonClickEvent}},[_vm._v("\n                削除\n              ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.cancelButtonClickEvent}},[_vm._v("\n                キャンセル\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.registerButtonClickEvent}},[_vm._v("\n                保存\n              ")])],1)],1)],1)]}}])})],1)],1),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }