/**
 * パスワード変更
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/users/password`

const user_password = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    update( {commit, state}, { params } ) {
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(API_PATH, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(content)
        } )
      })
    },
  },
  mutations: {},
}

export default user_password