<template>
  <v-container>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <div>
      <v-card flat>
        <v-card-text>
          <vue-editor
            v-model="content.description"
            :disabled="true"
            class="custom-quill-container"
          />
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>

  import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'MyPageShow',
    mixins: [
      PageHeaderMixin
    ],
    data () {
      return {
        pageTitle: null,
        breadcrumbs: [
          { text: "ホーム", disabled: false, href: '/' },
          { text: '', disabled: true, to: '' },
        ],
      }
    },
    computed: {
      ...mapState('frontMyPage', {
        content: 'content',
      }),
    },
    watch : {
      content (newContent) {
        this.meta_tags = newContent.meta_tags
        this.pageTitle = newContent.title
        this.breadcrumbs[1].text = newContent.title
      }
    },
    beforeRouteUpdate (to, from, next) {
      // 同一ページの２回目の表示
      this.fetchMyPage({post_name: to.params.post_name})
      next()
    },
    created() {
      this.fetchMyPage({post_name: this.$route.params.post_name})
    },
    methods: {
      ...mapActions('frontMyPage', {
        fetchMyPage: 'fetch'
      }),
    }
  }

</script>

<style scoped lang="scss">
.custom-quill-container {
  /deep/ {
    .ql-toolbar {
      display: none;
    }
    .ql-snow {
      border: none !important;
    }
  }
}
</style>