/**
 * 定期課金
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/subscriptions`

const subscription = {

    namespaced: true,
    state: {
        product: null,
        pagination: {
            current_page: 1,
            total_count: 0
        },
        query: {
            order_no: null,
            part_number: null,
            customer_name: null,
            only_elapsed_scheduled: false,
        },
    },
    getters: {
        hasSubscriptions: ( state ) => {
            return (state.pagination.total_count > 0)
        },
    },
    actions: {
        fetchAll({ dispatch, commit, state }, {order_no, part_number, customer_name, only_elapsed_scheduled, page}) {
            const queries = {
                order_no: order_no,
                part_number: part_number,
                customer_name: customer_name,
                only_elapsed_scheduled: only_elapsed_scheduled,
            }
            dispatch('fetchQuery', queries)
            const params = {
                order_no: state.query.order_no,
                part_number: state.query.part_number,
                customer_name: state.query.customer_name,
                only_elapsed_scheduled: state.query.only_elapsed_scheduled,
                page: page
            }
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    commit(types.BACK_SUBSCRIPTION, { content })
                    resolve(data.content)
                })
            })
        },
        fetchQuery({ commit, state}, {order_no, part_number, product_stock_no, customer_name, only_elapsed_scheduled }) {
            const orderNo = (order_no !== undefined)? order_no : state.query.order_no
            const partNumber = (part_number !== undefined) ? part_number : state.query.part_number
            const customerName = (customer_name !== undefined)? customer_name : state.query.customer_name
            const onlyElapsedScheduled = (only_elapsed_scheduled !== undefined) ? only_elapsed_scheduled: state.query.only_elapsed_scheduled
            const params = {
                order_no: orderNo,
                part_number: partNumber,
                customer_name: customerName,
                only_elapsed_scheduled: onlyElapsedScheduled
            }
            commit(types.BACK_SUBSCRIPTION_QUERY, { params })
        },
        resetQuery({ commit, state }) {
            const params = {
                order_no: null,
                part_number: null,
                customer_name: null,
                only_elapsed_scheduled: false
            }
            commit(types.BACK_SUBSCRIPTION_QUERY, { params })
        },
        fetch ({ commit, state }, {id}) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
    },
    mutations: {
        [types.BACK_SUBSCRIPTION] (state, { content }) {
            state.pagination = content.pagination
        },
        [types.BACK_SUBSCRIPTION_QUERY] (state, { params }) {
            state.query.order_no = params.order_no
            state.query.part_number = params.part_number
            state.query.customer_name = params.customer_name
            state.query.only_elapsed_scheduled = params.only_elapsed_scheduled
        },
    },
}

export default subscription

