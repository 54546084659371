/**
 * 商品タグ
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const product_tag = {
    namespaced: true,
    state: {
        product_tags: []
    },
    actions: {
        fetchAll ({ commit, state },{ product_id }) {
            const api_path = `${API_PATH}/${product_id}/product_tags`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    commit(types.BACK_PRODUCT_TAG, { content })
                    // resolve(data.content)
                })
            })
        },
        create( { commit, state }, { product_id, params } ) {

            const api_path = `${API_PATH}/${product_id}/product_tags`

            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(api_path, params ).then( data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    let content = data.content
                    if (content !== null) {
                        commit(types.BACK_PRODUCT_TAG_ADD, { content })
                    }
                    resolve(content)
                } )
            })
        },
        destroy( { commit, state }, { product_id, params } ) {

            const remove_id = params.id

            const api_path = `${API_PATH}/${product_id}/product_tags/${remove_id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    commit(types.BACK_PRODUCT_TAG_DELETE, { remove_id })
                    // resolve(data.content)
                })
            })
        },
    },
    mutations: {
        [types.BACK_PRODUCT_TAG] (state, { content }) {
            state.product_tags = content.product_tags
        },
        [types.BACK_PRODUCT_TAG_ADD] (state, { content }) {
            state.product_tags.push(content)
        },
        [types.BACK_PRODUCT_TAG_DELETE] (state, { remove_id }) {
            state.product_tags = state.product_tags.filter(o => o.id !== remove_id)
        }
    },
}

export default product_tag