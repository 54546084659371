<template>
  <div>
    <h3 class="d-flex justify-space-between align-center">
      <v-btn
        icon
        color="grey"
        @click="toBackPayment"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>延長申込確認</span>
      <span style="min-width: 36px" />
    </h3>
    <alert-message class="mb-0" />
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-row dense>
          <v-col
            v-for="item in content.extend_orders"
            :key="item.id"
            cols="12"
            sm="6"
          >
            <order-product
              :item="item"
              :is-edit="false"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card
          v-if="extendPayment"
          tile
          class="mt-2 grey lighten-3"
        >
          <v-divider />
          <v-card-title>
            お支払い方法
            <span class="lang_lbl ms-1">{{$t('view.お支払い方法')}}</span>
          </v-card-title>
          <v-card-text>
            {{ paymentTypeLabel() }}
          </v-card-text>
          <v-card-title>延長金額</v-card-title>
          <v-card-text>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>小計</th>
                  <td class="text-right">
                    {{ totalPrice | yen_format }}
                  </td>
                </tr>
                <tr>
                  <th>合計</th>
                  <td class="text-right">
                    {{ totalPrice | yen_format }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              dark
              color="red"
              @click="toNext"
            >
              <v-icon class="mr-2">
                mdi-credit-card-outline
              </v-icon>延長申込を確定する
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex"
  import prefectureList from './../../../../../mixins/PrefectureList'
  import OrderProduct from './_OrderProduct'
  import alertMessage from '../../../../../components/AlertMessage'

  export default {
    name: 'ExtendOrderConfirm',
    components: {
      OrderProduct,
      alertMessage,
    },
    mixins: [
      prefectureList,
    ],
    data () {
      return {
      }
    },
    computed: {
      ...mapState('frontExtendOrder', {
        content: 'content'
      }),
      ...mapState('frontExtendPayment', {
        extendPayment: 'delivery'
      }),
      ...mapGetters('frontExtendOrder', [
        'totalPrice'
      ])

    },
    mounted() {
    },
    created() {
    },
    methods: {
      ...mapActions('frontExtendOrder', {
        createExtend: 'create'
      }),
      toNext () {
        const params = this.buildParams()
        this.createExtend({ id: this.$route.params.id, params: params }).then(() => {
          this.$emit('toNextStep')
        })
      },
      toBack () {
        this.$emit('toBackStep')
      },
      toBackPayment () {
        this.$emit('toBackPayment')
      },
      paymentTypeLabel () {
        console.log(this.extendPayment)
        if (this.extendPayment.payment_type === 0) {
          return 'カード決済'
        } else if (this.extendPayment.payment_type === 1) {
          return '銀行振り込み'
        } else if (this.extendPayment.payment_type === 2) {
          return '代引き'
        }
      },
      buildParams () {
        let items = []
        for( const extend_order of this.content.extend_orders ) {
          if (extend_order.extend_days > 0) {
            let product = extend_order.product
            let extend = {
              order_detail_id: extend_order.id,
              product_id: product.id,
              quantity: extend_order.quantity,
              tax_rate: product.tax_rate,
              extend_days: extend_order.extend_days,
              extend_total_amount: extend_order.extend_total_amount,
              tax_amount: extend_order.extend_tax_amount,
              extend_return_date: extend_order.extend_return_date
            }
            items.push(extend)
          }
        }
        const params = {
          payment_type: this.extendPayment.payment_type,
          card_token: this.extendPayment.card_token,
          extend_order_details: items
        }
        return params
      },
    },
  }
</script>

<style scoped lang="scss">
</style>