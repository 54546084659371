/**
 * 商品
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";
import * as types from "../../../mutation-types/backyard-types";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/orders`

const order_stock_history = {
  namespaced: true,
  state: {
    page: 1,
    pagination: {
      current_page: 1,
      total_count: 0
    },
    can_cancel_extend: false,
    order_stocks: [],
  },
  getters: {
    viewPage(state) {
      return state.page
    },
    canCancelExtend (state) {
      return state.can_cancel_extend
    }
  },
  actions: {
    fetchAll({ commit, state }, { order_id, page }) {
      const _page = (page !== undefined) ? page : state.page
      const params = { page: _page }
      commit(types.BACK_ORDER_STOCK_HISTORY_QUERY, { params })
      const api_path = `${API_PATH}/${order_id}/order_stocks`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_ORDER_STOCK_HISTORY, { content })
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.BACK_ORDER_STOCK_HISTORY] (state, { content }) {
      state.can_cancel_extend = content.can_cancel_extend
      state.order_stocks = content.order_stocks
      state.pagination = content.pagination
    },
    [types.BACK_ORDER_STOCK_HISTORY_QUERY] (state, { params }) {
      state.page = params.page
    },
  },
}

export default order_stock_history