/**
 * マイページ
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/my_pages`

const myPage = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {
      title: null,
      post_name: null,
      only_footer: false
    },
  },
  getters: {
    isMyPages: ( state ) => {
      return (state.pagination.total_count > 0)
    },
  },
  actions: {
    findMyPages({ dispatch, commit, state }, { title, post_name, only_footer, page}) {

      const queries = { title: title, post_name: post_name, only_footer: only_footer }

      dispatch('fetchQuery', queries)
      const params = {
        title: state.query.title,
        post_name: state.query.post_name,
        only_footer: state.query.only_footer,
        page: page
      }

      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_MY_PAGE, { content })
          resolve(content)
        })
      })
    },
    fetchQuery({ commit, state}, { title, post_name, only_footer }) {
      const _title = (title !== undefined)? title : state.query.title
      const postName = (post_name !== undefined)? post_name : state.query.post_name
      const onlyFooter = (only_footer !== undefined) ? only_footer : state.query.only_footer
      const params = { title: _title, post_name: postName, only_footer: onlyFooter }
      commit(types.BACK_MY_PAGE_QUERY, { params })
    },
    show ({ commit }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    new ({ _commit }) {
      const api_path = `${API_PATH}/new`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    create( { commit, state }, { params } ) {
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(API_PATH, params).then( data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          let content = data.content
          resolve(content)
        } )
      })
    },
    update( {commit, state}, { id, params } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          let content = data.content
          resolve(content)
        } )
      })
    },
    destroy( { commit, state }, { id } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.delete(api_path).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          let content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.BACK_MY_PAGE] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_MY_PAGE_QUERY] (state, { params }) {
      state.query.title = params.title
      state.query.post_name = params.post_name
      state.query.only_footer = params.only_footer
    }
  },
}

export default myPage