<template>
  <tr>
    <td>{{ company.id }}</td>
    <td>{{ company.code }}</td>
    <td>{{ company.name }}</td>
    <td>{{ company.shop_name }}</td>
    <td>{{ company.contract_type_name }}</td>
    <td>{{ company.contract_start_date | from_to_date_format(company.contract_end_date) }}</td>
    <td>
      <v-btn
        icon
        color="cyan darken-2"
        @click="showButtonClick"
      >
        <v-icon>mdi-file-edit-outline</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>

export default {
  mixins: [],
  props: {
    /**
     * @typedef company { Object }
     * @property id { Number }
     * @property code { String }
     * @property name { String }
     * @property shop_name { String }
     * @property contract_type_name { String }
     * @property contract_start_date { String }
     * @property contract_end_date { String }
     */
    company: {
      type: Object,
      default: () => {
        return {}
      },
    }
  },
  methods: {
    showButtonClick: function() {
      this.$router.push( { name: 'adCompanyShow', params: { id: this.company.id } } )
    }
  }
}
</script>

<style scoped>

</style>