<!-- バックヤード > 商品一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          返却管理
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              :disabled="checked_list.length <= 0"
              @click="sendBackButtonClickEvent"
            >
              返却する
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="orderNo"
                  dense
                  hide-details="auto"
                  label="注文番号"
                  outlined
                  placeholder="部分一致"
                />
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="partNumber"
                  dense
                  hide-details="auto"
                  label="商品品番"
                  outlined
                  placeholder="部分一致"
                />
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="productStockNo"
                  dense
                  hide-details="auto"
                  label="在庫番号"
                  outlined
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                md="5"
              >
                <v-text-field
                  v-model="customerName"
                  dense
                  hide-details="auto"
                  label="顧客名"
                  outlined
                  placeholder="部分一致"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <p class="mb-0">
                  受注日
                </p>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableOrderStartDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="orderStartDate"
                      clearable
                      dense
                      hide-details="auto"
                      label="開始日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="orderStartDate = null"
                    >
                      <template
                        v-if="!$vuetify.breakpoint.smAndDown"
                        v-slot:append-outer
                      >
                        &emsp;〜
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="orderStartDate"
                    @input="enableOrderStartDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableOrderEndDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="orderEndDate"
                      clearable
                      dense
                      hide-details="auto"
                      label="終了日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="orderEndDate = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="orderEndDate"
                    @input="enableOrderEndDate = false"
                  />
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <p class="mb-0">
                  ご利用終了日
                </p>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableReturnScheduleStartDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="returnScheduleStartDate"
                      clearable
                      dense
                      hide-details="auto"
                      label="開始日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="returnScheduleStartDate = null"
                    >
                      <template
                        v-if="!$vuetify.breakpoint.smAndDown"
                        v-slot:append-outer
                      >
                        &emsp;〜
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="returnScheduleStartDate"
                    @input="enableReturnScheduleStartDate = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="enableReturnScheduleEndDate"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="returnScheduleEndDate"
                      clearable
                      dense
                      hide-details="auto"
                      label="終了日"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="returnScheduleEndDate = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="returnScheduleEndDate"
                    @input="enableReturnScheduleEndDate = false"
                  />
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-checkbox
                  v-model="includeReturned"
                  class="pa-0 ma-0"
                  hide-details="auto"
                  label="返却済を含める"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="searchButtonClickEvent"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th />
                  <th class="text-center">
                    品番1
                  </th>
                  <th class="text-center">
                    商品名
                  </th>
                  <th class="text-center">
                    在庫番号
                  </th>
                  <th class="text-center">
                    在庫状態
                  </th>
                  <th class="text-center">
                    顧客名
                  </th>
                  <th class="text-center">
                    注文番号
                  </th>
                  <th class="text-center">
                    受注日
                  </th>
                  <th class="text-center">
                    出荷日
                  </th>
                  <th class="text-center">
                    ご利用終了日
                  </th>
                  <th class="text-center">
                    返却確認日
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="send_back in sendBacks"
                  :key="send_back.id"
                >
                  <td>
                    <v-checkbox
                      v-if="send_back.return_confirmed_date === null"
                      v-model="send_back.checkable"
                      dense
                      hide-details
                      class="pa-0 ma-0"
                      @change="sendBackCheckboxChangeEvent($event, send_back)"
                    />
                  </td>
                  <td>{{ send_back.part_number_1 }}</td>
                  <td>{{ send_back.product_name }}</td>
                  <td>{{ send_back.product_stock_no }}</td>
                  <td>{{ send_back.product_stock_status }}</td>
                  <td>{{ send_back.name }}</td>
                  <td>{{ send_back.order_no }}</td>
                  <td>{{ send_back.order_date }}</td>
                  <td>{{ send_back.shipping_date }}</td>
                  <td>{{ send_back.return_schedule_date }}</td>
                  <td>{{ send_back.return_confirmed_date }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="hasSendBacks"
        cols="12"
      >
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="pageButtonClickEvent"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'SendBackIndex',
  components: {},
  mixins: [],
  data () {
    return {
      page: 1,
      filterDialog: false,
      totalVisible: 7,
      sendBacks: [],
      checked_list: [],
      orderNo: null,
      productStockNo: null,
      customerName: null,
      partNumber: null,
      enableOrderStartDate: false,
      orderStartDate: null,
      enableOrderEndDate: false,
      orderEndDate: null,
      returnScheduleStartDate: null,
      enableReturnScheduleStartDate: false,
      returnScheduleEndDate: null,
      enableReturnScheduleEndDate: false,
      includeReturned: false,
    }
  },
  computed: {
    ...mapState('backSendBack', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
    ...mapGetters('backSendBack', [
      'hasSendBacks'
    ])
  },
  /**
   * 状態監視
   */
  watch: {
    /**
     * ページ遷移時のチェック状態の復元
     */
    sendBacks () {
      for (const elm of this.sendBacks) {
        elm.checkable = (this.checked_list.some( b => b === elm.id))
      }
    },
    orderNo (newValue) {
      this.fetchQuery({order_no: newValue})
    },
    partNumber (newValue) {
      this.fetchQuery({part_number: newValue})
    },
    productStockNo (newValue) {
      this.fetchQuery({product_stock_no: newValue})
    },
    customerName (newValue) {
      this.fetchQuery({customer_name: newValue})
    },
    orderStartDate (newValue) {
      this.fetchQuery({order_start_date: newValue})
    },
    orderEndDate (newValue) {
      this.fetchQuery({order_end_date: newValue})
    },
    returnScheduleStartDate (newValue) {
      this.fetchQuery({return_schedule_start_date: newValue})
    },
    returnScheduleEndDate (newValue) {
      this.fetchQuery({return_schedule_end_date: newValue})
    },
    includeReturned (newValue) {
      this.fetchQuery({include_returned: newValue})
    }
  },
  created() {
    if (this.$route.params.isClearCondition) {
      this.resetQuery()
    }

    const searchOrderNumber = this.$route.query.order_no
    if (searchOrderNumber) {
      this.orderNo = searchOrderNumber
    }

    const searchPartNumber = this.$route.query.part_number
    if (searchPartNumber) {
      this.partNumber = searchPartNumber
    }

    const searchProductStockNo = this.$route.query.product_stock_no
    if (searchProductStockNo) {
      this.productStockNo = searchProductStockNo
    }

    if (!this.searchQuery.return_schedule_end_date) {
      this.returnScheduleEndDate = new Date().toISOString().substr(0, 10)
    }
    this.searchSendBacks()
  },
  methods: {
    ...mapActions('backSendBack', {
      fetchSendBacks: 'fetchAll',
      bulkRegisterSendBacks: 'create',
      fetchQuery: 'fetchQuery',
      resetQuery: 'resetQuery'
    }),
    searchButtonClickEvent () {
      this.checked_list = []
      this.searchSendBacks()
    },
    pageButtonClickEvent () {
      this.searchSendBacks()
    },
    searchSendBacks () {
      const params = {
        order_no: this.orderNo,
        part_number: this.partNumber,
        product_stock_no: this.productStockNo,
        customer_name: this.customerName,
        order_start_date: this.orderStartDate,
        order_end_date: this.orderEndDate,
        return_schedule_start_date: this.returnScheduleStartDate,
        return_schedule_end_date: this.returnScheduleEndDate,
        include_returned: this.includeReturned,
        page: this.page
      }
      this.fetchSendBacks(params).then((content) => {
        this.sendBacks = content.send_backs
      })
    },
    sendBackCheckboxChangeEvent (event, item) {
      if (event) {
        this.checked_list.push(item.id)
      } else {
        this.checked_list = this.checked_list.filter(o => o !== item.id)
      }
    },
    sendBackButtonClickEvent () {
      const params = { send_back_target_ids: this.checked_list }
      this.bulkRegisterSendBacks({params: params}).then(() => {
        this.page = 1
        this.searchSendBacks()
      })
    },
  }
}
</script>

<style lang="scss">

</style>
