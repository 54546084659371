/**
 * 商品 セット商品
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const product_component = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        fetchAll ({ commit, state },{ product_id }) {
            const api_path = `${API_PATH}/${product_id}/product_components`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
        create( { commit, state }, { product_id, params } ) {
            const api_path = `${API_PATH}/${product_id}/product_components`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(api_path, params).then( data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    let content = data.content
                    resolve(content)
                } )
            })
        },
        destroy( { commit, state }, { product_id, params } ) {
            const remove_id = params.id
            const api_path = `${API_PATH}/${product_id}/product_components/${remove_id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    let content = data.content
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    resolve(content)
                })
            })
        },
    },
    mutations: {},
}

export default product_component