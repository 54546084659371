/**
 * カテゴリー
 */
import Vue from "vue";
import * as types from "../../mutation-types/frontend-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/categories`

const category = {
  namespaced: true,
  state: {
    content: [],
  },
  getters: {},
  actions: {
    fetch({ commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          commit(types.FRONT_CATEGORY, { content })
          resolve(content)
        })
      })
    },
    findCategories({ commit }, {limit}) {
      const params = {
        limit: limit
      }
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, {params: params}).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    fetchDisplayHome({ _commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: { display_home: true } }).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    }
  },
  mutations: {
    [types.FRONT_CATEGORY] (state, { content }) {
      state.content = content
    },
  },
}

export default category
