/**
 * タグ
 */
import Vue from "vue";
import * as types from "../../mutation-types/frontend-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/tags`

const tag = {
  namespaced: true,
  state: {
    content: [],
  },
  getters: {},
  actions: {
    fetch({ commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          commit(types.FRONT_TAG, { content })
          resolve(content)
        })
      })
    },
    findFooterTags({ commit }) {
      const params = {
        display_footer: true
      }
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, {params: params}).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.FRONT_TAG] (state, { content }) {
      state.content = content
    },
  },
}

export default tag
