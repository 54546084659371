<!-- バックヤード > システム設定 > IP制限タブ -->
<template>
  <div>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-text>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="addButtonClickEvent"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="allowIpmasks"
                :headers="tableHeaders"
                :items="allowIpmasks"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="cyan darken-2"
                    @click="editButtonClickEvent(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="info"
                    small
                    @click="editButtonClickEvent(item)"
                  >
                    編集する
                  </v-btn>

                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="error"
                    icon
                    @click="deleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="deleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showAllowIpmaskDialog"
      width="450"
      persistent
      no-click-animation
    >
      <v-form>
        <alert-message />
        <validation-observer v-slot="{ invalid }">
          <v-card>
            <v-card-title>IPアドレス設定</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:30"
                    name="IPアドレス"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="allow_ipmask.ip_address"
                      label="IPアドレス"
                      dense
                      hide-details="auto"
                      outlined
                      clearable
                      placeholder="192.168.0.1/32"
                      required
                      counter="30"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="max:50"
                    name="備考"
                  >
                    <v-text-field
                      v-model="allow_ipmask.remarks"
                      label="備考"
                      dense
                      hide-details="auto"
                      outlined
                      clearable
                      placeholder=" "
                      required
                      counter="50"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="showAllowIpmaskDialog = false">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="dialogRegisterButtonClickEvent"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-dialog>
    <confirm ref="confirm" />
  </div>
</template>

<script>
import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    AlertMessage,
    Confirm,
  },
  mixins: [],
  data () {
    return {
      tableHeaders: [
        { value: 'ip_address', text: 'IPアドレス' },
        { value: 'remarks', text: '備考' },
        { value: 'editable', text: '', align: 'end' },
      ],
      showAllowIpmaskDialog: false,
      allow_ipmask: null
    }
  },
  computed: {
    ...mapState('backAllowIpmask', {
      allowIpmasks: 'allow_ipmasks',
      pagination: 'pagination',
    }),
    ...mapGetters('backAllowIpmask', [
      'isAllowIpmask'
    ])
  },
  created() {
    this.page = this.pagination.current_page
    this.fetchAllowIpmasks(this.page)
  },
  methods: {
    ...mapActions('backAllowIpmask', {
      fetchAllowIpmasks: 'fetchAll',
      newAllowIpmask: 'new',
      editAllowIpmask: 'edit',
      createAllowIpmask: 'create',
      updateAllowIpmask: 'update',
      deleteAllowIpmask: 'destroy'
    }),
    addButtonClickEvent () {
      this.newAllowIpmask().then( (content) => {
        this.allow_ipmask = content
        this.showAllowIpmaskDialog = true
      })
    },
    editButtonClickEvent (allow_ipmask) {
      this.editAllowIpmask({id: allow_ipmask.id}).then( ( content ) => {
        this.allow_ipmask = content
        this.showAllowIpmaskDialog = true
      })
    },
    dialogRegisterButtonClickEvent () {
      if (this.allow_ipmask.id) {
        this.updateAllowIpmask({id: this.allow_ipmask.id, params: this.allow_ipmask}).then(() => {
          this.showAllowIpmaskDialog = false
        })
      } else {
        this.createAllowIpmask({params: this.allow_ipmask}).then(() => {
          this.showAllowIpmaskDialog = false
        })
      }
    },
    deleteButtonClickEvent (allow_ipmask) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.deleteAllowIpmask({id: allow_ipmask.id})
        }
      })
    },
  },
}
</script>

<style lang="scss">

</style>
