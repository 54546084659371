<template>
  <div>
    <search-nav />
    <v-container>
      <v-breadcrumbs
        class="px-0 py-2"
        :items="breadcrumbs"
      />
      <!-- 絞り込みボタン -->
      <v-row
        v-if="isDisplayCondition"
        :class="showRefinedSearchArea ? 'hidden-md-and-up' : ''"
      >
        <v-col cols="12">
          <v-btn
            outlined
            color="primary"
            @click="filterDialog=true"
          >
            <v-icon>mdi-filter-variant-plus</v-icon>絞り込み
          </v-btn>
        </v-col>
      </v-row>
      <!-- /絞り込みボタン -->

      <v-row>
        <!-- 左ペイン検索エリア -->
        <v-col
          v-if="isDisplayCondition && (willShowSideCondition) || (filterDialog && !willShowSideCondition)"
          cols="3"
          class="hidden-sm-and-down"
        >
          <v-card class="mb-2">
            <v-card-actions>
              <v-row dense>
                <v-col
                  cols="12"
                  sm="5"
                  class="text-center"
                >
                  <v-btn
                    block
                    outlined
                    color="grey darken-1"
                    @click="resetFilters"
                    class="lang_btn"
                  >
                    リセット
                    <div class="lang_lbl">{{ $t('view.リセット') }}</div>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="text-center offset-sm-4"
                >
                  <v-btn
                    block
                    outlined
                    color="primary"
                    @click="resetProducts"
                    style="padding:0 6px !important;"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>

          <v-expansion-panels
            v-model="conditionPanel"
            accordion
            multiple
          >
            <v-expansion-panel v-if="displayCondition.category">
              <v-expansion-panel-header>
                カテゴリー
                <span class="lang_lbl">{{ $t('view.カテゴリー') }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <chip-condition
                  ref="categoryRef"
                  v-model="selectedCategories"
                  :items="categoryContent.categories"
                  :selected-items="selectedCategories"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="displayCondition.tag">
              <v-expansion-panel-header>
                タグ
                <span class="lang_lbl">{{ $t('view.タグ') }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <chip-condition
                  ref="tagRef"
                  v-model="selectedTags"
                  :items="tagContent.tags"
                  :selected-items="selectedTags"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="displayCondition.campaign">
              <v-expansion-panel-header
                v-if="auctionMode"
              >
                オークション
              </v-expansion-panel-header>
              <v-expansion-panel-header
                v-else
              >
                キャンペーン
                <span class="lang_lbl">{{ $t('view.キャンペーン') }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <chip-condition
                  ref="campaignRef"
                  v-model="selectedCampaigns"
                  :items="campaignContent.campaigns"
                  :selected-items="selectedCampaigns"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="displayCondition.new_arrivals">
              <v-expansion-panel-header>
                新着商品
                <span class="lang_lbl">{{ $t('view.新着商品') }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <chip-condition
                  ref="newArrivalsRef"
                  v-model="selectOnlyNewArrivals"
                  :items="newArrivals"
                  :selected-items="selectOnlyNewArrivals"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card class="mt-2">
            <v-card-actions>
              <v-row dense>
                <v-col
                  cols="12"
                  sm="5"
                  class="text-center"
                >
                  <v-btn
                    block
                    outlined
                    color="grey darken-1"
                    @click="resetFilters"
                    class="lang_btn"
                  >
                    リセット
                    <span class="lang_lbl">{{ $t('view.リセット') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="text-center offset-sm-4"
                >
                  <v-btn
                    block
                    outlined
                    color="primary"
                    @click="resetProducts"
                    style="padding:0 6px !important;"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- /左ペイン検索エリア -->

        <!-- 商品一覧エリア -->
        <v-col
          cols="12"
          :md="productListColumn"
        >
          <v-row dense>
            <v-col
              cols="12"
              class="text-right"
            >
              <div class="subtitle-2">
                {{ pagination.first_number }} - {{ pagination.last_number }} 件目（{{ pagination.total_count | comma_format }} 件中）
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              offset="7"
              offset-md="10"
              cols="5"
              md="2"
            >
              <v-select
                v-model="limit"
                :items="limitValues"
                dense
                outlined
                class="limitDropdown"
                @change="limitChangeEvent"
              />
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col
              v-for="item in products"
              :key="item.id"
              cols="6"
              md="3"
            >
              <product-card
                :product="item"
                :mode="mode"
              />
            </v-col>
            <v-col
              v-if="isProducts"
              cols="12"
            >
              <v-divider />
              <div class="text-center mt-6">
                <v-pagination
                  v-model="page"
                  :length="pagination.total_pages"
                  :total-visible="totalVisible"
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
                  @input="changePage"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <!-- /商品一覧エリア -->
      </v-row>
    </v-container>
    <!--  絞り込み用のダイアログ  -->
    <v-dialog
      v-if="!canShowSideCondition"
      v-model="filterDialog"
      fullscreen
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-subtitle class="pa-0">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="filterDialog=false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>絞り込み条件</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
        </v-card-subtitle>
        <v-expansion-panels
          v-model="conditionPanel"
          accordion
          multiple
        >
          <v-expansion-panel>
            <v-row dense>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  outlined
                  color="grey darken-1"
                  @click="resetFilters"
                  class="lang_btn"
                >
                  リセット
                  <span class="lang_lbl">{{ $t('view.リセット') }}</span>
                </v-btn>
                <v-btn
                  outlined
                  color="primary"
                  @click="resetProducts"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                &nbsp;
              </v-col>
            </v-row>
          </v-expansion-panel>
          <v-expansion-panel v-if="displayCondition.category">
            <v-expansion-panel-header>
              カテゴリー
              <span class="lang_lbl">{{ $t('view.カテゴリー') }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <chip-condition
                ref="categoryRef"
                v-model="selectedCategories"
                :items="categoryContent.categories"
                :selected-items="selectedCategories"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="displayCondition.tag">
            <v-expansion-panel-header>
              タグ
              <span class="lang_lbl">{{ $t('view.タグ') }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <chip-condition
                ref="tagRef"
                v-model="selectedTags"
                :items="tagContent.tags"
                :selected-items="selectedTags"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="displayCondition.campaign">
            <v-expansion-panel-header
              v-if="auctionMode"
            >
              オークション
            </v-expansion-panel-header>
            <v-expansion-panel-header
              v-else
            >
              キャンペーン
              <span class="lang_lbl">{{ $t('view.キャンペーン') }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <chip-condition
                ref="campaignRef"
                v-model="selectedCampaigns"
                :items="campaignContent.campaigns"
                :selected-items="selectedCampaigns"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="displayCondition.new_arrivals">
            <v-expansion-panel-header>
              新着商品
              <span class="lang_lbl">{{ $t('view.新着商品') }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <chip-condition
                ref="newArrivalsRef"
                v-model="selectOnlyNewArrivals"
                :items="newArrivals"
                :selected-items="selectOnlyNewArrivals"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import { mapState, mapActions, mapGetters } from 'vuex'

  import SearchNav from '../../components/SearchNav'
  import ProductCard from '../../components/ProductCard'
  import ChipCondition from './_ChipCondition'
  import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";

  export default {
    name: 'ProductIndex',
    components: {
      SearchNav,
      ChipCondition,
      ProductCard,
    },
    mixins: [
      PageHeaderMixin
    ],
    props: {
      isAllCampaign: {
        type: Boolean,
        default: false,
      },
      isNewArrivals: {
        type: Boolean,
        default: false,
      },
      isClearCondition: {
        type: Boolean,
        default: false,
      }
    },
    data () {
      return {
        page: 1,
        limit: 20,
        productListColumn: 9,
        totalVisible: 7,
        conditionPanel: [0, 1, 2, 3],
        filterDialog: false,
        selectedTags: [],
        selectedCategories: [],
        selectedCampaigns: [],
        selectOnlyNewArrivals: [],
        newArrivals: [{id: 1, name: '新着商品'}],
        keywordText: null,
        products: [],
        mode: '',
        limitValues: [
          { value: 10, text: '10件' },
          { value: 20, text: '20件' },
          { value: 50, text: '50件' },
          { value: 100, text: '100件' },
        ],
        breadcrumbs: [
          { text: "ホーム", disabled: false, href: '/' },
          { text: "商品一覧", disabled: true, to: '' },
        ],
      }
    },
    computed: {
      ...mapState('frontCategory', {
        categoryContent: 'content'
      }),
      ...mapState('frontTag', {
        tagContent: 'content'
      }),
      ...mapState('frontProduct', {
        pagination: 'pagination',
        searchQuery: 'query',
        displayCondition: 'display_condition'
      }),
      ...mapState('frontCampaign', {
        campaignContent: 'content'
      }),
      ...mapGetters('frontProduct', [
        'isProducts', 'isDisplayCondition'
      ]),
      ...mapState('frontSystemSetting', {
        auctionMode: 'auction_mode',
        showRefinedSearchArea: 'use_refined_search_area'
      }),
      canShowSideCondition () {
        let value = false
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            value = false
            break;
          case 'sm':
            value = false
            break;
          case 'md':
            value = true
            break;
          case 'lg':
            value = true
            break;
          case 'xl':
            value = true
            break;
        }
        return value
      },
      willShowSideCondition () {
        let value = this.canShowSideCondition
        if (value) {
          if (this.showRefinedSearchArea) {
            value = true
          } else {
            value = false
          }
        }
        return value
      },
    },
    watch: {
      filterDialog (newVal) {
        if (this.canShowSideCondition) {
          if (this.showRefinedSearchArea) {
            this.productListColumn = 9
          } else {
            if (newVal) {
              this.productListColumn = 9
            } else {
              this.productListColumn = 12
            }
          }
        } else {
          this.productListColumn = 12
        }
      }
    },
    created() {
      this.descriptionContent = this.$route.meta.siteSubTitle
      this.pageTitle = this.$route.meta.siteSubTitle

      // ホームのバナーなどからは検索条件をクリアする
      // 商品詳細のパンくずからはクリアしない
      if (this.isClearCondition) {
        this.resetQuery()
        this.resetCondition()
      }

      const searchCategoryId = this.$route.query.category_id
      if (searchCategoryId) {
        this.selectedCategories.push(searchCategoryId)
      } else {
        this.selectedCategories = this.searchQuery.category_ids
      }
      const searchKeyword = this.$route.query.keyword
      if (searchKeyword) this.keywordText = searchKeyword

      const searchTagid = this.$route.query.tag_id
      if (searchTagid) {
        this.selectedTags.push(searchTagid)
      } else {
        this.selectedTags = this.searchQuery.tag_ids
      }

      this.limit = this.searchQuery.limit

      if (this.isNewArrivals) this.selectOnlyNewArrivals.push(1)
      this.fetchCategory()
      this.fetchTag()
      this.page = this.pagination.current_page
      if (this.isAllCampaign) {
        this.fetchCampaigns().then((content) => {
          content.campaigns.forEach(obj => {
            this.selectedCampaigns.push(obj.id)
          })
          this.searchProducts()
        })
      } else {
        const campaignId = this.$route.query.campaign_id
        if (campaignId) {
          this.selectedCampaigns.push(campaignId)
        } else {
          this.selectedCampaigns = this.searchQuery.campaign_ids
        }
        this.fetchCampaigns()
        this.searchProducts()
      }

      if (this.willShowSideCondition) {
        if (this.showRefinedSearchArea) {
          this.productListColumn = 9
        } else {
            this.productListColumn = 12
        }
      } else {
        this.productListColumn = 12
      }

    },
    beforeRouteUpdate (to, from, next) {
      if (to.params.isClearCondition) {
        // フッターからの検索の場合は、条件を一旦クリア
        this.resetCondition()
      }
      // キーワード検索からの２回前の検索は、再描画されないので、再取得する
      this.keywordText = to.query.keyword

      const categoryId = to.query.category_id
      if (categoryId) this.selectedCategories = [categoryId]

      const campaignId = to.query.campaign_id
      if (campaignId) this.selectedCampaigns = [campaignId]

      const searchTagid = to.query.tag_id
      if (searchTagid) this.selectedTags = [searchTagid]

      next()

      // 子コンポーネントを再描画
      this.reloadComponent()
      this.searchProducts()
    },
    methods: {
      ...mapActions('frontCategory', {
        fetchCategory: 'fetch'
      }),
      ...mapActions('frontTag', {
        fetchTag: 'fetch'
      }),
      ...mapActions('frontProduct', {
        fetchProducts: 'fetchProducts',
        resetQuery: 'resetQuery',
        fetchQuery: 'fetchQuery',
      }),
      ...mapActions('frontCampaign', {
        fetchCampaigns: 'fetch'
      }),
      resetFilters () {
        this.resetCondition()
        this.reloadComponent()
        this.resetProducts()
      },
      reloadComponent () {
        // 子コンポーネントを再描画
        if (this.$refs.categoryRef) this.$refs.categoryRef.updateSelectedItems(this.selectedCategories)
        if (this.$refs.tagRef) this.$refs.tagRef.updateSelectedItems(this.selectedTags)
        if (this.$refs.campaignRef) this.$refs.campaignRef.updateSelectedItems(this.selectedCampaigns)
        if (this.$refs.newArrivalsRef) this.$refs.newArrivalsRef.updateSelectedItems(this.selectOnlyNewArrivals)
      },
      resetCondition () {
        this.selectedTags = []
        this.selectedCategories = []
        this.selectedCampaigns = []
        this.selectOnlyNewArrivals = []
        this.keywordText = null
      },
      resetProducts () {
        this.filterDialog = false
        this.page = 1
        this.searchProducts()
      },
      changePage () {
        this.searchProducts()
      },
      limitChangeEvent() {
        this.fetchQuery({limit: this.limit})
        this.searchProducts()
      },
      searchProducts () {

        let onlyNewArrivals = this.isNewArrivals
        onlyNewArrivals = (this.selectOnlyNewArrivals.length > 0)
        const params = {
          category_ids: this.selectedCategories,
          tag_ids: this.selectedTags,
          keyword_text: this.keywordText,
          campaign_ids: this.selectedCampaigns,
          only_new_arrival: onlyNewArrivals,
          limit: this.limit,
          page: this.page,
        }
        this.fetchProducts(params).then((content) => {
          this.products = content.products
          this.mode = content.mode
        })
      },

    },
  }
</script>

<style scoped lang="scss">
.limitDropdown {
  font-size: .875rem!important;
  letter-spacing: .00625rem!important;
}
</style>