var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("タグ")]),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"small":""},on:{"click":_vm.showNewTagModal}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.productTags)?_c('v-data-table',{attrs:{"headers":_vm.tagTableHeaders,"items":_vm.productTags,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.tagDeleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.tagDeleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}],null,false,2671064568)}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("キーワード")]),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"small":""},on:{"click":_vm.showNewKeywordModal}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.productKeywords)?_c('v-data-table',{attrs:{"headers":_vm.keywordTableHeaders,"items":_vm.productKeywords,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.keywordDeleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.keywordDeleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}],null,false,3373251256)}):_vm._e()],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"400"},on:{"close":_vm.closeTagDialog},model:{value:(_vm.isShowTagDialog),callback:function ($$v) {_vm.isShowTagDialog=$$v},expression:"isShowTagDialog"}},[_c('validation-observer',{ref:"tagFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',[_c('v-card-title',[_vm._v("タグ登録")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"タグ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.tagContent.tags,"error-messages":errors,"dense":"","item-text":"name","item-value":"id","hide-details":"auto","label":"タグ","outlined":"","placeholder":" ","required":""},model:{value:(_vm.productTag.tag_id),callback:function ($$v) {_vm.$set(_vm.productTag, "tag_id", $$v)},expression:"productTag.tag_id"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.closeTagDialog}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.tagDialogRegisterButtonClickEvent}},[_vm._v("\n              保存\n            ")])],1)],1)],1)]}}])})],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"600"},on:{"close":_vm.closeKeywordDialog},model:{value:(_vm.isShowKeywordDialog),callback:function ($$v) {_vm.isShowKeywordDialog=$$v},expression:"isShowKeywordDialog"}},[_c('validation-observer',{ref:"keywordFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',[_c('v-card-title',[_vm._v("キーワード登録")]),_vm._v(" "),_c('v-card-text',{staticStyle:{"max-height":"70vh","overflow-y":"auto","padding-top":"5px"}},[_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:1500","name":"キーワード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"auto-grow":"","counter":"1500","dense":"","hide-details":"auto","label":"キーワード","outlined":"","persistent-placeholder":"","required":""},model:{value:(_vm.productKeyword.keyword),callback:function ($$v) {_vm.$set(_vm.productKeyword, "keyword", $$v)},expression:"productKeyword.keyword"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.closeKeywordDialog}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.keywordDialogRegisterButtonClickEvent}},[_vm._v("\n              保存\n            ")])],1)],1)],1)]}}])})],1),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }