/**
 * 配送方法
 */
import Vue from "vue";
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/backyard-types";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/delivery_methods`

const deliveryMethod = {
    namespaced: true,

    // アプリケーションの状態（情報）
    state: {
        pagination: {
            current_page: 1,
            total_count: 0
        },
        query: {
            prefecture_codes: '',
        },
    },

    // state 内の状態を元に算出した値を返す関数
    getters: {
    },

    actions: {
        fetchAll({ dispatch, commit, state }, {prefecture_codes, page}) {
            const queries = {
                prefecture_codes: prefecture_codes,
            }
            dispatch('fetchQuery', queries)
            const params = {
                prefecture_codes: state.query.prefecture_codes,
                page: page
            }

            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    commit(types.BACK_DELIVERY_METHOD, { content })
                    resolve(data.content)
                })
            })
        },

        fetchQuery({ commit, state }, { prefecture_codes }) {
            const prefectureCodes = (prefecture_codes !== undefined)? prefecture_codes : state.query.prefecture_codes
            const params = {
                prefecture_codes: prefectureCodes,
            }
            commit(types.BACK_DELIVERY_METHOD_QUERY, { params })
        },

        // 配送方法取得
        show ({ _commit }, { id }) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },

        // 配送方法
        update ({ commit }, { id, params }) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.put(api_path, params).then(data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
    },

    // stateのデータを更新
    mutations: {
        [types.BACK_DELIVERY_METHOD] (state, { content }) {
            state.pagination = content.pagination
        },
        [types.BACK_DELIVERY_METHOD_QUERY] (state, { params }) {
            state.query.prefecture_codes = params.prefecture_codes
        },
    },
}

export default deliveryMethod