/**
 * テーマオプション/スライダー
 */
import Vue from 'vue'
import * as httpConst from '../../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/theme_option`

const theme_option_other = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        update ({ commit }, { params }) {
            const api_path = `${API_PATH}/form_other`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params,{ headers: { 'content-type': 'multipart/form-data' } }).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(data.content)
                } )
            })
        },
    },
    mutations: {},
}

export default theme_option_other