<!-- バックヤード > SHOPサイト画面設定 > スライドショー設定 -->
<template>
  <div>
    <v-card
      outlined
      tile
    >
      <v-card-text>
        <input
          v-model="slide.id"
          type="hidden"
        >
        <!-- タイトル -->
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="max:30"
              name="タイトル"
            >
              <v-text-field
                v-model="slide.title"
                :error-messages="errors"
                counter="30"
                dense
                hide-details="auto"
                label="タイトル"
                outlined
                placeholder=" "
              />
            </validation-provider>
          </v-col>
        </v-row><!-- /タイトル -->

        <!-- サブタイトル -->
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="max:20"
              name="サブタイトル"
            >
              <v-text-field
                v-model="slide.sub_title"
                dense
                label="サブタイトル"
                hide-details="auto"
                outlined
                counter="20"
                placeholder=" "
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row><!-- /サブタイトル -->

        <!-- タイトル文字色 -->
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="slide.font_color"
              hide-details="auto"
              label="タイトル文字色"
              dense
              readonly
              outlined
              placeholder=" "
              @click="titleColorMenu = true"
            >
              <template v-slot:append>
                <v-menu
                  v-model="titleColorMenu"
                  top
                  nudge-bottom="105"
                  nudge-left="16"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      :style="titleColorSwatchStyle"
                      v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                        v-model="slide.font_color"
                        flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </v-col>
        </v-row><!-- /タイトル文字色 -->

        <!-- 画像ファイル -->
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="mimes:image/jpeg,image/png"
              name="画像ファイル"
              class="requiredMark"
            >
              <v-row
                @dragover.prevent
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @drop="onDrop"
              >
                <v-col>
                  <v-file-input
                    v-model="slide.file"
                    :background-color="isDragging ? 'blue' : 'null'"
                    :clearable="false"
                    :error-messages="errors"
                    :show-size="1000"
                    accept="image/jpeg,image/png"
                    append-icon="mdi-folder"
                    dense
                    label="画像ファイル"
                    hint="画像ファイルを選択して下さい"
                    outlined
                    persistent-hint
                    placeholder=" "
                    prepend-icon=""
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="overline grey--text text--darken-3 mx-2"
                      >+{{ files.length - 2 }} File(s)</span>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </validation-provider>
          </v-col>
        </v-row><!-- /画像ファイル -->

        <!-- プレビュー -->
        <v-row>
          <v-col
            cols="12"
            md="5"
          >
            <div
              style="position: relative;border: 1px solid #ddd;"
            >
              <v-card style="background-color: #fafafa;">
                <v-card-subtitle>
                  プレビュー
                </v-card-subtitle>
                <div style="overflow: hidden">
                  <v-sheet
                    height="100%"
                    class="carouseBackGround"
                    :style="`background-image: url('${slide.image_url}');position: relative;max-height: 300px;`"
                  >
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                    >
                      <v-col
                        style="max-width: 80%;max-height: 300px;padding: 0;"
                      >
                        <v-img
                          :src="slide.image_url"
                          contain
                          height="100%"
                          style="max-height: 300px;"
                        />
                      </v-col>
                    </v-row>
                    <div
                      class="previewLayout"
                      :class="$vuetify.breakpoint.xs ? 'small' : 'normal'"
                      style=""
                    >
                      <v-row
                        class="fill-height mx-1"
                        align="start"
                      >
                        <v-col
                          cols="12"
                          class="previewTexts"
                        >
                          <v-row>
                            <v-col
                              cols="12"
                              style="padding-bottom: 0"
                            >
                              <div
                                class="text-h6"
                                :style="{ color: slide.font_color }"
                              >
                                {{ slide.title }}
                              </div>
                            </v-col>
                            <v-col
                              cols="12"
                              style="padding-bottom: 0"
                            >
                              <div
                                class="body-2"
                                :style="{ color: slide.font_color }"
                              >
                                {{ slide.sub_title }}
                              </div>
                            </v-col>
                            <v-col
                              v-if="slide.use_button"
                              cols="12"
                              style="padding-bottom: 0"
                            >
                              <v-btn
                                outlined
                                x-small
                                :style="{ color: slide.font_color }"
                              >
                                {{ slide.button_title }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </v-sheet>
                </div>
                <v-divider />
              </v-card>
            </div>
          </v-col>
        </v-row><!-- /プレビュー -->

        <!-- ボタンの利用 -->
        <v-row>
          <v-col>
            <p class="my-1">
              ボタン
            </p>
            <v-radio-group
              v-model="slide.use_button"
              dense
              row
              hide-details="auto"
              class="pa-0 ma-0"
            >
              <v-radio
                label="使用しない"
                :value="false"
              />
              <v-radio
                label="使用する"
                :value="true"
              />
            </v-radio-group>
          </v-col>
        </v-row><!-- /ボタンの利用 -->

        <!-- ボタン名 -->
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required_if:use_button,true|max:10"
              name="ボタン名"
            >
              <v-text-field
                v-model="slide.button_title"
                dense
                label="ボタン名"
                hide-details="auto"
                outlined
                counter="10"
                placeholder=" "
                :error-messages="errors"
                :disabled="slide.use_button !== true"
              />
            </validation-provider>
          </v-col>
        </v-row><!-- /ボタン名 -->

        <!-- リンク先 -->
        <v-row>
          <v-col>
            <p class="my-1">
              リンク先
            </p>
            <slide-link-form
              v-if="slide"
              :link="slide"
            />
          </v-col>
        </v-row><!-- /リンク先 -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import SlideLinkForm from './_LinkSetting'

  export default {
    components: {
      SlideLinkForm,
    },
    props: {
      slide: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        titleColorMenu: false,
        isDragging: false,
        imageDeleted: false,
        dragCount: 0,
      }
    },
    computed: {
      titleColorSwatchStyle() {
        return this.colorChangeEvent(this.slide.font_color, this.titleColorMenu)
      },
    },
    watch: {
      'slide.file': function(newVal) {
        if (newVal) {
          this.getBase64(newVal).then((image) => {
            this.slide.image_url = image
          })
        }
      },
    },
    methods: {
      colorChangeEvent (color, menu) {
        return {
          backgroundColor: color,
          marginBottom: '7px',
          border: '1px solid #ddd',
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: menu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out'
        }
      },
      onDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        this.isDragging = false;
        const _files = e.dataTransfer.files;
        this.slide.file = _files[0];
      },
      onDragEnter(e) {
        e.preventDefault();
        this.isDragging = true;
        this.dragCount++;
      },

      onDragLeave(e) {
        e.preventDefault();
        this.dragCount--;
        if (this.dragCount <= 0) {
          this.isDragging = false;
        }
      },
      getBase64 (file) {
        /* Promiseにアロー関数を渡して非同期処理を書く。*/
        return new Promise((resolve, reject) => {

          /* 組み込みオブジェクトを使い、Base64エンコードされた画像データを取得  */
          const reader = new FileReader()
          reader.readAsDataURL(file)

          /* 成功時と失敗時の処理。resolveの引数に結果を渡して、のちの.then(result => ...)で受け取る  */
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },

    },
  }
</script>
<style scoped lang="scss">
.previewLayout {
  position: absolute;
  left: 0;

  &.normal {
    top: 30%;
    & .previewTexts {
      & .col {
        padding-top: 9px;
      }
    }
    & .text-h6 {
      font-size: 1em !important;
      font-weight: bold;
    }
    & .body-2 {
      font-size: 0.25em !important;
    }
  }
  &.small {
    top: 10%;
    & .previewTexts {
      padding-left: initial;
      & .col {
        padding-top: 5px;
      }
    }
    & .text-h6 {
      font-size: 0.75em !important;
      font-weight: bold;
    }
    & .body-2 {
      font-size: 0.1em !important;
    }
  }
}

</style>
