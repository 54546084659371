<!-- バックヤード > SHOPサイト画面設定 > バナー設定 -->
<template>
  <div>
    <v-row>
      <v-col>
        <v-radio-group
          v-model="banner.banner_type"
          row
          dense
          hide-details="auto"
          class="pa-0 ma-0"
        >
          <v-radio
            label="テキスト表示"
            value="text"
          />
          <v-radio
            label="動画(youtube)表示"
            value="youtube"
          />
          <v-radio
            label="画像表示"
            value="banner"
          />
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-show="banner.banner_type === 'text'">
        <v-text-field
          v-model="banner.text"
          dense
          hide-details="auto"
          outlined
          counter="50"
          placeholder=" "
        />
      </v-col>
      <v-col
        v-show="banner.banner_type === 'youtube'"
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="banner.youtube_url"
          dense
          label="URL"
          hide-details="auto"
          outlined
          counter="255"
          placeholder=" "
        />
      </v-col>
      <v-col
        v-show="banner.banner_type === 'banner'"
      >
        <v-radio-group
          v-model="banner.column_type"
          row
          dense
          hide-details="auto"
          class="pa-0 ma-0"
          @change="banner_type_change"
        >
          <v-radio
            label="1枚(推奨サイズ：728 * 90)"
            value="column1"
          />
          <v-radio
            label="2枚(推奨サイズ：512 * 100)"
            value="column2"
          />
          <v-radio
            label="3枚(推奨サイズ：300 * 250)"
            value="column3"
          />
          <v-radio
              label="4枚(推奨サイズ：250 * 250)"
              value="column4"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-show="banner.banner_type === 'banner'">
      <v-col>
        <v-tabs
          background-color="grey lighten-4"
        >
          <v-tab
            v-for="i in banner_links.length"
            :key="i"
          >
            {{ i }}
          </v-tab>
          <v-tab-item
            v-for="i in banner_links.length"
            :key="'tab-' + i"
            :ref="'link'+i"
            :eager="true"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-row
                  class="text-center"
                  @dragover.prevent
                  @dragenter="onDragEnter"
                  @dragleave="onDragLeave"
                  @drop="onDrop"
                >
                  <v-col>
                    <v-file-input
                      v-model="banner_links[i-1].file"
                      :background-color="isDragging ? 'blue' : 'null'"
                      :clearable="false"
                      :show-size="1000"
                      v-bind:data-index="i-1"
                      accept="image/jpeg,image/png"
                      append-icon="mdi-folder"
                      counter
                      dense
                      label="画像ファイル"
                      hint="画像ファイルを選択して下さい"
                      outlined
                      persistent-hint
                      placeholder=" "
                      prepend-icon=""
                      @change="imageChangeEvent(i)"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>
                        <span
                          v-else-if="index === 2"
                          class="overline grey--text text--darken-3 mx-2"
                        >+{{ files.length - 2 }} File(s)</span>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row
              v-if="banner_links[i-1].image_url"
            >
              <v-col
                cols="12"
                md="4"
              >
                <div
                  style="position: relative;border: 1px solid #ddd;"
                >
                  <v-card style="background-color: #fafafa;">
                    <v-card-subtitle>
                      プレビュー
                    </v-card-subtitle>
                    <v-card-text
                      class="justify-center"
                    >
                      <v-card
                        flat
                        tile
                      >
                        <v-img
                          :src="banner_links[i-1].image_url"
                          max-height="200"
                          contain
                          aspect-ratio="1"
                          class="gray lighten-4 align-center"
                        />
                      </v-card>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        small
                        color="error"
                        @click="imageDeleteButtonClickEvent(i)"
                      >
                        削除
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="my-1">
                  リンク先
                </p>
                <banner-link-form
                  v-if="banner_links[i-1]"
                  :link="banner_links[i-1]"
                  :is-transition="true"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import BannerLinkForm from './_LinkSetting'
import { mapActions } from 'vuex'
export default {
  name: 'BannerForm',
  components: {
    BannerLinkForm,
  },
  props: {
    /**
     * @typedef banner {Object}
     * @property banner_type {String}
     * @property youtube_url {String}
     * @property column_type {String}
     */
    banner: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      banner_links: [],
      isDragging: false,
      dragCount: 0,
    }
  },
  watch: {
    // 'banner': function(newValue) {
    //   if (newValue) {
    //     this.getBase64(newValue).then((image) => {
    //       this.category.image_url = image
    //       this.imageRemoved = false
    //     })
    //   }
    // }
  },
  created () {
    this.findBannerLinks({id: this.banner.id}).then((content) => {
      this.banner_links = content.banner_links
    })
  },
  methods: {
    ...mapActions('backThemeOptionBannerLink', {
      findBannerLinks: 'index',
    }),
    imageChangeEvent (index) {
      let object = this.banner_links[index - 1]
      this.getBase64(object.file).then((image) => {
        object.image_url = image
        object.remove_file = false
      })
    },
    imageDeleteButtonClickEvent (index) {
      let object = this.banner_links[index - 1]
      object.file = null
      object.image_url = null
      object.remove_file = true
    },
    banner_type_change: function (value) {
      this.image_count = parseInt(value.replace('column',''))
    },
    buildParams: function(params) {
      for (let i = 0; i < this.banner_links.length; i++) {
        params.append('banners_attributes[0]banner_links_attributes[' + i + ']id', this.banner_links[i].id)
        if (this.banner_links[i].file) {
          params.append('banners_attributes[0]banner_links_attributes[' + i + ']file', this.banner_links[i].file)
        }
        params.append('banners_attributes[0]banner_links_attributes[' + i + ']remove_file', this.banner_links[i].remove_file)
        params.append('banners_attributes[0]banner_links_attributes[' + i + ']transition_type', this.banner_links[i].transition_type)
        params.append('banners_attributes[0]banner_links_attributes[' + i + ']product_id', this.convertToEmptyString(this.banner_links[i].product_id))
        params.append('banners_attributes[0]banner_links_attributes[' + i + ']category_id', this.convertToEmptyString(this.banner_links[i].category_id))
        params.append('banners_attributes[0]banner_links_attributes[' + i + ']tag_id', this.convertToEmptyString(this.banner_links[i].tag_id))
        params.append('banners_attributes[0]banner_links_attributes[' + i + ']my_page_id', this.convertToEmptyString(this.banner_links[i].my_page_id))
        params.append('banners_attributes[0]banner_links_attributes[' + i + ']transition_url', this.banner_links[i].transition_url)

      }
      return params
    },
    setParams: function(bannerLinks) {
      this.banner_links = bannerLinks
    },
    convertToEmptyString(value) {
      // int項目にnull文字列を送信すると0で登録されるので空文字に変換する
      return (value === null || value === 'null') ? '' : value
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      this.banner_links[parseInt(e.target.nextSibling.dataset.index)].file =e.dataTransfer.files[0]
    },

    onDragEnter(e) {
      e.preventDefault();
      this.isDragging = true;
      this.dragCount++;
    },

    onDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    },
    getBase64 (file) {
      /* Promiseにアロー関数を渡して非同期処理を書く。*/
      return new Promise((resolve, reject) => {

        /* 組み込みオブジェクトを使い、Base64エンコードされた画像データを取得  */
        const reader = new FileReader()
        reader.readAsDataURL(file)

        /* 成功時と失敗時の処理。resolveの引数に結果を渡して、のちの.then(result => ...)で受け取る  */
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
  },
}

</script>

<style lang="scss">
</style>
