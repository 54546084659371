/**
 * 商品オプション
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const product_option_group = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetchAll({ dispatch, commit, state }, { product_id }) {
      const api_path = `${API_PATH}/${product_id}/product_option_groups`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    new( { commit, state }, { product_id } ) {
      const api_path = `${API_PATH}/${product_id}/product_option_groups`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.get(api_path).then( data => {
          let content = data.content
          resolve(content)
        } )
      })
    },
    edit( { commit, state }, { product_id, product_option_group_id } ) {
      const api_path = `${API_PATH}/${product_id}/product_option_groups/${product_option_group_id}/edit`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.get(api_path).then( data => {
          let content = data.content
          resolve(content)
        } )
      })
    },
    create( { commit, state }, { product_id, params } ) {
      const api_path = `${API_PATH}/${product_id}/product_option_groups`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    update( { commit, state }, { product_id, params } ) {
      const api_path = `${API_PATH}/${product_id}/product_option_groups/${params.id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    destroy( { commit, state }, { product_id, product_option_group_id } ) {
      const api_path = `${API_PATH}/${product_id}/product_option_groups/${product_option_group_id}`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.delete(api_path).then(data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          resolve(content)
        })
      })
    },
  },
  mutations: {},
}

export default product_option_group