<template>
  <v-alert
    v-if="showRequestLink"
    border="left"
    colored-border
    type="error"
    dense
    elevation="2"
  >
    <a
      class="text-decoration-underline"
      @click="applyCardBrandLinkClickEvent"
    >
      カード決済の申請を行ってください。(別タブで開きます)
    </a>
  </v-alert>
</template>

<script>

  import { mapActions } from 'vuex'

  export default {
  name: "PaymentRequest",
  components: {},
  mixins: [],
  data () {
    return {
      showRequestLink: false
    }
  },
  created() {
    /**
     * @typedef {Object} content
     * @property {string} show_request_link
     */
    this.showPaymentRequest().then((content) => {
      this.showRequestLink = content.show_request_link
    })
  },
  methods: {
    /**
     * クレジットカード申請状況取得
     */
    ...mapActions('backPaymentRequest', {
      showPaymentRequest: 'fetch'
    }),
    /**
     * PAY.JP申請URL発行
     */
    ...mapActions('backHomeGenerateApplyUrl', {
      buildUrl: 'create'
    }),
    applyCardBrandLinkClickEvent () {
      this.buildUrl().then((content) => {
        if (content.url) {
          window.open(content.url, '_blank')
        }
      })
    },
  },
  }
</script>

<style scoped>

</style>