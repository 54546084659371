<!-- バックヤード > パスワードを忘れた方 -->
<template>
  <v-container
    fluid
    fill-height
  >
    <v-card
      outlined
      class="mx-auto"
      style="width: 40rem;"
    >
      <v-card-title
        class="justify-center"
      >
        <h3
          class="text-center"
        >
          パスワードの変更
        </h3>
      </v-card-title>
      <v-card-subtitle>
        パスワードの再設定を行います。他人に推測されやすいものは避けてください。
      </v-card-subtitle>
      <v-card-text>
        <alert-message />
        <validation-observer v-slot="{ invalid }">
          <v-form class="mt-5">
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  vid="password"
                  rules="required|max:72"
                  name="パスワード"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="password"
                    label="パスワード"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder=" "
                    counter="72"
                    required
                    :error-messages="errors"
                    @click:append="showPassword = !showPassword"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  vid="confirmation"
                  rules="required|max:72|confirmed:password"
                  name="パスワード(確認用)"
                  class="requiredMark"
                  mode="lazy"
                >
                  <v-text-field
                    v-model="password_confirmation"
                    label="パスワード(確認用)"
                    :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    placeholder=" "
                    counter="72"
                    required
                    :error-messages="errors"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-spacer class="clearfix mb-5" />

            <v-btn
              @click="cancelButtonClickEvent"
            >
              キャンセル
            </v-btn>
            <v-btn
              color="primary"
              :disabled="invalid"
              class="float-right"
              @click="registerButtonClickEvent"
            >
              変更
            </v-btn>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import { mapActions } from 'vuex'
  import alertMessage from '../../../components/AlertMessage'

  import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";

  export default {
    name: 'ForgetEdit',
    components: {
      alertMessage
    },
    mixins: [
      PageHeaderMixin
    ],
    data () {
      return {
        password: null,
        password_confirmation: null,
        token: null,
        showPassword: false,
        showConfirmPassword: false,
      }
    },
    created () {
      this.pageTitle = this.$route.meta.siteSubTitle
      this.signOut()
      this.token = this.$route.params.token
    },
    methods: {
      ...mapActions('frontAuth', {
        signOut: 'signOutFrontend',
      }),
      ...mapActions('frontForget', {
        resetPassword: 'update'
      }),
      cancelButtonClickEvent () {
        this.$router.push({name: 'SessionIndex'})
      },
      registerButtonClickEvent () {
        let params = {
          password: this.password,
          password_confirmation: this.password_confirmation
        }
        this.resetPassword( { token: this.token, params: params }).then(() => {
          this.$router.push({name: 'ForgetCompleted'})
        })
      }
    },
  }
</script>

<style lang="scss">
</style>
