<template>
  <div>
    <alert-message />
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-text>
            <div class="text-right mb-2">
              <v-btn
                small
                @click="addOptionItemButtonClickEvent"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                :headers="itemTableHeaders"
                :items="optionItems"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template
                  v-slot:item.image_url="{ item }"
                  class="py-3"
                >
                  <div>
                    <v-img
                      v-if="item.image_url !== null"
                      :src="item.image_url"
                      max-height="45"
                      max-width="60"
                    />
                  </div>
                </template>
                <template v-slot:item.price="{ item }">
                  {{ item.price | comma_format }} 円
                </template>
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="cyan darken-2"
                    @click="editOptionItemButtonClickEvent(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="info"
                    small
                    @click="editOptionItemButtonClickEvent(item)"
                  >
                    編集する
                  </v-btn>
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="error"
                    @click="deleteOptionItemButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    small
                    color="error"
                    @click="deleteOptionItemButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="hasOptionItem"
      class="mb-3"
    >
      <v-col>
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="enableOptionItemDialog"
      width="450"
      @close="closeOptionItemDialogClickEvent"
    >
      <v-form>
        <validation-observer
          v-slot="{ invalid }"
          ref="optionItemFormValidation"
        >
          <v-card>
            <v-card-title>オプションアイテム</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:30"
                    name="オプション名"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="optionItem.name"
                      :error-messages="errors"
                      counter="30"
                      dense
                      hide-details="auto"
                      label="オプション名"
                      outlined
                      placeholder=" "
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="mimes:image/jpeg,image/png"
                    name="画像ファイル"
                  >
                    <v-file-input
                      v-model="optionItem.image"
                      :error-messages="errors"
                      accept="image/jpeg,image/png"
                      append-icon="mdi-folder"
                      dense
                      label="画像ファイル"
                      hint="画像ファイルを選択して下さい"
                      outlined
                      persistent-hint
                      placeholder=" "
                      prepend-icon=""
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="optionItem.image_url">
                <v-col>
                  <v-card style="background-color: #fafafa;">
                    <v-card-subtitle>
                      プレビュー
                    </v-card-subtitle>
                    <v-card-text
                      class="justify-center"
                    >
                      <v-card
                        flat
                        tile
                      >
                        <v-img
                          :src="optionItem.image_url "
                          max-height="200"
                          contain
                          aspect-ratio="1"
                          class="gray lighten-4 align-center"
                        />
                      </v-card>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        small
                        color="error"
                        @click="imageDeleteButtonClickEvent"
                      >
                        削除
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="numeric|max_value:999999"
                    name="金額"
                    class=""
                  >
                    <v-text-field
                      v-model="optionItem.price"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="金額"
                      outlined
                      placeholder=" "
                      prefix="円"
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row v-if="optionItem.id">
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="表示順"
                    rules="required|numeric|min_value:0|max_value:2147483647"
                  >
                    <v-text-field
                      v-model="optionItem.position"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="表示順"
                      outlined
                      placeholder=" "
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeOptionItemDialogClickEvent">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="registerOptionItemDialogClickEvent"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-dialog>
    <confirm ref="confirm" />
  </div>
</template>

<script>

import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import { mapActions } from 'vuex'

export default {
  name: 'OptionItems',
  components: {
    AlertMessage,
    Confirm,
  },
  props: {
    optionGroup: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      enableOptionItemDialog: false,
      hasOptionItem: false,
      page: 0,
      totalVisible: 7,
      itemTableHeaders: [
        { value: 'image_url', text: '' },
        { value: 'no', text: 'No' },
        { value: 'name', text: 'オプション名' },
        { value: 'price', text: '金額', align: 'right' },
        { value: 'position', text: '表示順', align: 'right' },
        { value: 'editable', text: '', align: 'end' },

      ],
      optionItems: [],
      optionItem: {
        id: null,
        no: null,
        name: null,
        image: null,
        image_url: null,
        remove_image: false,
        image_file_name: null,
        price: null,
        position: null
      },
      pagination: {
        total_pages: 0,
        current_page: 1,
      }
    }
  },
  computed : {
  },
  watch: {
    'optionItem.image': function(newVal) {
      if (newVal) {
        this.getBase64(newVal).then((image) => {
          this.optionItem.image_url = image
          this.optionItem.remove_image = false
        })
      }
    }
  },
  created () {
    this.searchOptionItems()
  },
  methods: {
    ...mapActions('backOptionItem', {
      fetchOptionItems: 'fetchAll',
      newOptionItem: 'new',
      editOptionItem: 'edit',
      createOptionItem: 'create',
      updateOptionItem: 'update',
      deleteOptionItem: 'destroy'
    }),
    changePage () {
    },
    searchOptionItems () {
      /**
       * @typedef content { Object }
       * @property option_items { Array }
       * @property pagination { Object }
       */
      this.fetchOptionItems({option_group_id: this.optionGroup.id}).then((content) => {
        this.optionItems = content.option_items
        this.pagination = content.pagination
      })
    },
    addOptionItemButtonClickEvent () {
      this.newOptionItem({ option_group_id: this.optionGroup.id }).then((content) => {
        this.optionItem = content
        this.enableOptionItemDialog = true
      })
    },
    editOptionItemButtonClickEvent (option_item) {
      this.editOptionItem({ option_group_id: this.optionGroup.id, id: option_item.id }).then((content) => {
        Object.assign(this.$data.optionItem, content);
        this.enableOptionItemDialog = true
      })
    },
    deleteOptionItemButtonClickEvent (option_item) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.deleteOptionItem({ option_group_id: this.optionGroup.id, id: option_item.id }).then(() => {
            this.searchOptionItems()
          })
        }
      })
    },
    imageDeleteButtonClickEvent () {
      this.optionItem.image = null
      this.optionItem.image_url = null
      this.optionItem.image_file_name = null
      this.optionItem.remove_image = true
    },
    closeOptionItemDialogClickEvent () {
      Object.assign(this.$data.optionItem, this.$options.data().optionItem);
      this.$nextTick(() => this.$refs.optionItemFormValidation.reset())
      this.enableOptionItemDialog = false
    },
    registerOptionItemDialogClickEvent () {
      const params = new FormData()
      params.append('name', this.optionItem.name)
      params.append('remove_image', this.optionItem.remove_image)
      if (this.optionItem.image) {
        params.append('image', this.optionItem.image)
        params.append('image_file_name', this.optionItem.image_file_name)
      } else if (this.optionItem.remove_image) {
        params.append('image_file_name', null)
      }
      params.append('price', this.optionItem.price)
      if (this.optionItem.id) {
        params.append('position', this.optionItem.position)
        this.updateOptionItem({ option_group_id: this.optionGroup.id, id: this.optionItem.id, params: params }).then(() => {
          this.closeOptionItemDialogClickEvent()
          this.searchOptionItems()
        })

      } else {
        this.createOptionItem({ option_group_id: this.optionGroup.id, params: params }).then(() => {
          this.closeOptionItemDialogClickEvent()
          this.searchOptionItems()
        })
      }
    },
    imageFileChangeEvent () {
      if (this.optionItem.image) {
        this.optionItem.image_file_name = this.optionItem.image.name
      }
    },
    getBase64 (file) {
      /* Promiseにアロー関数を渡して非同期処理を書く。*/
      return new Promise((resolve, reject) => {

        /* 組み込みオブジェクトを使い、Base64エンコードされた画像データを取得  */
        const reader = new FileReader()
        reader.readAsDataURL(file)

        /* 成功時と失敗時の処理。resolveの引数に結果を渡して、のちの.then(result => ...)で受け取る  */
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
  }
}
</script>

<style scoped>

</style>