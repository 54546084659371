/**
 * 商品
 */
import Vue from "vue";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.ADMIN_BASE}/api/banks`

const bank = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        fetchAll({ _commit }, {text, page}) {
            const params = {
                text: text,
                page: page
            }
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
    },
    mutations: {},
}

export default bank