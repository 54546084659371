<!-- バックヤード > 商品詳細 > オプション/付属品 -->
<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col>
            <h3>オプショングループ</h3>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="addOptionGroupButtonClickEvent"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="productOptionGroups"
                :headers="optionTableHeaders"
                :items="productOptionGroups"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="cyan darken-2"
                    icon
                    @click="editOptionGroupButtonClickEvent(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="info"
                    small
                    @click="editOptionGroupButtonClickEvent(item)"
                  >
                    編集する
                  </v-btn>

                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="error"
                    icon
                    @click="deleteOptionGroupButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="deleteOptionGroupButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="product.component_item">
          <v-col>
            <h3>セット商品</h3>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="openItemSearchDialog"
              >
                追加
              </v-btn>
            </div>

            <v-card outlined>
              <v-data-table
                v-if="productComponents"
                :headers="componentsTableHeaders"
                :items="productComponents"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.image_url="{ item }">
                  <v-img
                    :src="item.image_url!=null? item.image_url:''"
                    max-height="40"
                    max-width="100"
                    contain
                  />
                </template>
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="error"
                    @click="componentDeleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="componentDeleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="enableProductOptionDialog"
      width="400"
      @close="closeOptionGroupDialogClickEvent"
    >
      <v-form>
        <validation-observer
          v-slot="{ invalid }"
          ref="productOptionGroupFormValidation"
        >
          <v-card>
            <v-card-title>オプション登録</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="オプショングループ"
                  >
                    <v-select
                      v-model="productOptionGroup.option_group_id"
                      :items="optionGroups"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="id"
                      label="オプショングループ"
                      outlined
                      placeholder=" "
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row v-if="productOptionGroup.id">
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="表示順"
                    rules="required|numeric|min_value:0|max_value:2147483647"
                  >
                    <v-text-field
                      v-model="productOptionGroup.position"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="表示順"
                      outlined
                      placeholder=" "
                      required
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeOptionGroupDialogClickEvent">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="registerOptionGroupDialogClickEvent"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-dialog>

    <item-search-dialog
      ref="ItemSearchDialog"
      @itemSearchDialogCallback="itemSearchDialogCallback"
    />
    <confirm ref="confirm" />
  </div>
</template>

<script>
import Confirm from '../../../../components/Confirm'
import ItemSearchDialog from '../../../dialogs/ItemSearchDialog'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    ItemSearchDialog,
    Confirm,
  },
  mixins: [
  ],
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      optionTableHeaders: [
        { value: 'no', text: 'NO', align: 'start' },
        { value: 'name', text: 'オプショングループ名' },
        { value: 'tax_type', text: '税設定' },
        { value: 'position', text: '表示順' },
        { value: 'editable', text: '', align: 'end' },
      ],
      componentsTableHeaders: [
        { value: 'image_url', text: '', align: 'center' },
        { value: 'part_number_1', text: '品番' },
        { value: 'name', text: '商品名' },
        { value: 'category_names', text: 'カテゴリー' },
        { value: 'editable', text: '', align: 'end' },
      ],
      enableProductOptionDialog: false,
      productOptionGroups: [],
      productOptionGroup: {
        id: null,
        option_group_id: null,
        position: null
      },
      productComponents: [],
    }
  },
  computed: {
    ...mapState('backSearchOptionGroup', {
      optionGroups: 'content'
    })
  },
  created() {
    // this.fetchOptionGroups()
    this.searchProductOptionGroups()
    this.searchProductComponents()
  },
  methods: {
    ...mapActions('backSearchOptionGroup', {
      fetchOptionGroups: 'fetchAll'
    }),
    ...mapActions('backProductOptionGroup', {
      fetchProductOptionGroups: 'fetchAll',
      newProductOptionGroup: 'new',
      editProductOptionGroup: 'edit',
      createProductOptionGroup: 'create',
      updateProductionOptionGroup: 'update',
      deleteProductOptionGroup: 'destroy'
    }),
    ...mapActions('backProductComponent', {
      fetchProductComponents: 'fetchAll',
      createProductComponents: 'create',
      deleteProductComponents: 'destroy'
    }),
    addOptionGroupButtonClickEvent () {
      this.fetchOptionGroups()
      this.newProductOptionGroup({product_id: this.product.id}).then((content) => {
        Object.assign(this.productOptionGroup, content);
        this.enableProductOptionDialog = true
      })
    },
    editOptionGroupButtonClickEvent (product_option_group) {
      this.fetchOptionGroups()
      this.editProductOptionGroup({product_id: this.product.id, product_option_group_id: product_option_group.id}).then((content) => {
        Object.assign(this.productOptionGroup, content);
        this.enableProductOptionDialog = true
      })
    },
    deleteOptionGroupButtonClickEvent (product_option_group) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.deleteProductOptionGroup({ product_id: this.product.id, product_option_group_id: product_option_group.id }).then(() => {
            this.closeOptionGroupDialogClickEvent()
            this.searchProductOptionGroups()
          })
        }
      })
    },
    registerOptionGroupDialogClickEvent() {
      if (this.productOptionGroup.id) {
        this.updateProductionOptionGroup({ product_id: this.product.id, params: this.productOptionGroup }).then(() => {
          this.closeOptionGroupDialogClickEvent()
          this.searchProductOptionGroups()
        })
      } else {
        this.createProductOptionGroup({ product_id: this.product.id, params: this.productOptionGroup }).then(() => {
          this.closeOptionGroupDialogClickEvent()
          this.searchProductOptionGroups()
        })
      }
    },
    closeOptionGroupDialogClickEvent () {
      Object.assign(this.$data.productOptionGroup, this.$options.data().productOptionGroup);
      this.$nextTick(() => this.$refs.productOptionGroupFormValidation.reset())
      this.enableProductOptionDialog = false
    },
    searchProductOptionGroups () {
      this.fetchProductOptionGroups({ product_id: this.product.id }).then((content) => {
        this.productOptionGroups = content.product_option_groups
      })
    },
    openItemSearchDialog () {
      this.$refs.ItemSearchDialog.openDialog({exclude_product_id: this.product.id, local_stock_item: false, withoutComponent: true})
    },
    itemSearchDialogCallback (items) {
      const parameter = { component_ids: items }
      this.createProductComponents({ product_id: this.product.id, params: parameter }).then(() => {
        this.searchProductComponents()
      })
    },
    searchProductComponents () {
      this.fetchProductComponents({ product_id: this.product.id }).then((content) => {
        this.productComponents = content.product_components
      })
    },
    componentDeleteButtonClickEvent (product_component) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.deleteProductComponents({ product_id: this.product.id, params: product_component }).then(() => {
            this.searchProductComponents()
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">

</style>
