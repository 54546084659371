<!-- バックヤード > 現地マスタform -->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|max:30"
                      name="現地名"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="name"
                        :error-messages="errors"
                        counter="30"
                        dense
                        label="現地名"
                        hide-details="auto"
                        outlined
                        placeholder=" "
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:80"
                      name="コメント"
                    >
                      <v-textarea
                        v-model="comment"
                        :error-messages="errors"
                        auto-grow
                        counter="80"
                        dense
                        hide-details="auto"
                        label="コメント"
                        outlined
                        placeholder=" "
                        row-height="15"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="表示順"
                      rules="required|numeric|min_value:0|max_value:2147483647"
                    >
                      <v-text-field
                        v-model="position"
                        :error-messages="errors"
                        dense
                        hide-details="auto"
                        label="表示順"
                        outlined
                        placeholder=" "
                        required
                        reverse
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  v-if="isEdit"
                  color="error"
                  @click="localPlaceDelete"
                >
                  削除
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="localPlaceCancel"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="submit"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>

    <confirm
      v-if="isEdit"
      ref="confirm"
    />
  </div>
</template>

<script>

import AlertMessage from '../../../components/AlertMessage'
import Confirm from '../../../components/Confirm'
import { mapActions } from 'vuex'

export default {
  name: 'LocalPlaceForm',
  components: {
    AlertMessage,
    Confirm,
  },
  mixins: [],
  props: {
    local_place: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      name: this.local_place.name,
      comment: this.local_place.comment,
      position: this.local_place.position
    }
  },
  methods: {
    ...mapActions('backLocalPlace', {
      createLocalPlace: 'create',
      updateLocalPlace: 'update',
      destroyLocalPlace: 'destroy'
    }),
    buildParams () {
      return {
        code: this.code,
        name: this.name,
        comment: this.comment,
        position: this.position,
      }
    },
    localPlaceCancel: function () {
      this.$router.push({name: 'LocalPlaceIndex'})
    },
    localPlaceDelete: function () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyLocalPlace({id: this.$route.params.id }).then(() =>{
            this.$router.push({name: 'LocalPlaceIndex'})
          })
        }
      })
    },
    submit() {
      const params = this.buildParams()
      if (this.isEdit) {
        this.updateLocalPlace({ id: this.$route.params.id, params: params }).then(() =>{
          this.$router.push({name: 'LocalPlaceIndex'})
        })
      } else {
        this.createLocalPlace({ params }).then(() =>{
          this.$router.push({name: 'LocalPlaceIndex'})
        })
      }
    }
  }
}
</script>

<style lang="scss">
</style>
