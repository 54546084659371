/**
 * カートの中身が現地在庫か
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/frontend-types";
import store from "store";


const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/cart_insides`

const cart_inside = {
  namespaced: true,
  state: {
    content: {},
  },
  actions: {
    fetch({ commit }) {
      let params = {
        tmp_customer_id : store.get('tmpCustomerId'),
        is_front_logged_in : store.get('frontLoggedIn')
      }
      return new Promise((resolve, reject) => {
        Vue.$http.get(API_PATH, { params: params}).then(data => {
          let content = data.content
          commit(types.FRONT_CART_INSIDE, { content })
          resolve(content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
  },
  mutations: {
    [types.FRONT_CART_INSIDE] (state, { content }) {
      state.content = content
    },
  },
}

export default cart_inside
