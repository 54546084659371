<!-- バックヤード > カテゴリー一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          カテゴリーマスタ
          <span class="float-right">
            <v-btn
              color="primary"
              @click="newCategory"
            >
              カテゴリー追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col md="6">
                  <v-text-field
                    v-model="inputName"
                    dense
                    label="カテゴリー名"
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-radio-group
                    v-model="inputHomeDisplay"
                    row
                    dense
                    hide-details="auto"
                    label="HOME表示"
                    class="pa-0 ma-0"
                  >
                    <v-radio
                      key="all"
                      label="全て"
                      value="all"
                    />
                    <v-radio
                      key="use_home"
                      label="する"
                      value="use_home"
                    />
                    <v-radio
                      key="not_home"
                      label="しない"
                      value="not_home"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-btn
                color="primary"
                @click="resetCategories"
              >
                検索
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    カテゴリー名
                  </th>
                  <th class="text-left">
                    カテゴリー名(表示)
                  </th>
                  <th class="text-center">
                    HOME表示
                  </th>
                  <th class="text-left" />
                  <th class="text-center">
                    表示順
                  </th>
                  <th class="text-center">
                    商品一覧に表示
                  </th>
                  <th class="text-left">
                    作成日時
                  </th>
                  <th class="text-left">
                    更新日時
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="category in categories"
                  :key="category.id"
                >
                  <td>{{ category.name }}</td>
                  <td>{{ category.display_name }}</td>
                  <td class="text-center">
                    {{ category.home_display ? '表示' : '非表示' }}
                  </td>
                  <td>
                    <v-img
                      v-if="category.image_url !== null"
                      :src="category.image_url"
                      max-height="40"
                      max-width="100"
                      contain
                    />
                  </td>
                  <td class="text-center">
                    {{ category.position }}
                  </td>
                  <td class="text-center">
                    {{ category.display_product ? '表示' : '非表示' }}
                  </td>
                  <td>{{ category.created_at | unix_datetime_format }}</td>
                  <td>{{ category.updated_at | unix_datetime_format }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="editCategory(category)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="isCategories"
      cols="12"
    >
      <v-col>
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>

  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'CategoryIndex',
  components: {
  },
  mixins: [],
  data () {
    return {
      page: 1,
      totalVisible: 7,
      inputName: '',
      inputHomeDisplay: 'all',
      categories: []
    }

  },
  computed: {
    ...mapState('backCategory', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
    ...mapGetters('backCategory', [
      'isCategories'
    ])
  },
  created() {
    this.page = this.pagination.current_page
    this.searchCategories()
  },
  methods: {
    ...mapActions('backCategory', {
      fetchCategories: 'fetchCategories',
      fetchQuery: 'fetchQuery',
    }),
    resetFilters () {
      this.inputName = ''
      this.inputHomeDisplay = ''
      this.resetCategories()
    },
    newCategory () {
      // カテゴリー新規追加へ
      this.$router.push({name: 'CategoryNew'})
    },
    editCategory (category) {
      // カテゴリー編集へ
      this.$router.push({name: 'CategoryEdit', params: { id: category.id }})
    },
    resetCategories () {
      this.filterDialog = false
      this.page = 1
      this.searchCategories()
    },
    changePage () {
      this.searchCategories()
    },
    searchCategories () {
      const params = { name: this.inputName, home_display: this.inputHomeDisplay, page: this.page }
      this.fetchCategories(params).then((content) => {
        this.categories = content.categories
      })
    }
  }
}
</script>

<style lang="scss">

</style>
