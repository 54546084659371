<template>
  <v-container>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />

    <v-tabs
      v-model="tab"
      show-arrows="mobile"
      center-active
    >
      <v-tab>
        会社情報
      </v-tab>
      <v-tab>
        特定商取引法に基づく表示
      </v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <information :information="information" />
        </v-tab-item>
        <v-tab-item>
          <trade-law :trade-laws="content.commercial_laws" />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>

  import {mapState, mapActions} from 'vuex'
  import Information from './tabs/_Information'
  import TradeLaw from './tabs/_TradeLaw'
  import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";

  export default {
    name: 'CompanyShow',
    components: {
      TradeLaw,
      Information,
    },
    mixins: [
      PageHeaderMixin
    ],
    data () {
      return {
        tab: null,
        information: null,
        breadcrumbs: [
          { text: "ホーム", disabled: false, href: '/' },
          { text: "会社情報", disabled: true, to: '' },
        ],
      }
    },
    computed: {
      ...mapState('frontCredence', {
        content: 'content',
      }),
    },
    watch: {
      content(newVal) {
        let zipCode = (newVal.zip_code !== null)? `〒 ${newVal.zip_code}` : ''
        let address = (newVal.address !== null)? newVal.address : ''

        this.information = []
        if (newVal.name) this.information.push({ title: '業者名', text: newVal.name })
        if (newVal.representative_name) this.information.push({ title: '代表者名', text: newVal.representative_name })
        if (newVal.sales_responsible_name) this.information.push({ title: '販売責任者名', text: newVal.sales_responsible_name })
        if (zipCode || address) this.information.push({ title: '住所', text: `${zipCode} ${address}` })
        if (newVal.phone_number) this.information.push({ title: '電話番号', text: newVal.phone_number })
        if (newVal.business_hour) this.information.push({ title: '営業時間', text: newVal.business_hour })
        if (newVal.mail_address) this.information.push({ title: 'メールアドレス', text: newVal.mail_address })
        if (newVal.url) this.information.push({ title: 'URL', text: newVal.url })
      },
      tab (newVal) {
        let title = (newVal === 0) ? '会社情報' : '特定商取引法に基づく表示'
        this.descriptionContent = title
        this.pageTitle = title
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.setupTabIndex(to.params.tab_name)
      next()
    },
    created() {
      this.fetch()
      this.setupTabIndex(this.$route.params.tab_name)
    },
    methods: {
      ...mapActions('frontCredence', {
        fetch: 'fetch'
      }),
      setupTabIndex (tabName) {
        this.tab = (tabName === 'information')? 0 : 1
      },
    }
  }
</script>

<style scoped lang="scss">
</style>