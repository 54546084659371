<!-- バックヤード > ニュースform -->
<template>
  <div v-if="news">
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|max:50"
                      name="タイトル"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="news.title"
                        :error-messages="errors"
                        counter="50"
                        dense
                        hide-details="auto"
                        label="タイトル"
                        outlined
                        placeholder=" "
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:255"
                      name="詳細"
                      class=""
                    >
                      <v-textarea
                        v-model="news.description"
                        :error-messages="errors"
                        auto-grow
                        counter="255"
                        dense
                        hide-details="auto"
                        label="詳細"
                        outlined
                        placeholder=" "
                        row-height="15"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-menu
                      v-model="noticeDateMenu"
                      :close-on-content-click="false"
                      offset-y
                      transition="scale-transition"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="ニュース日"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="news.notice_date"
                            :error-messages="errors"
                            clearable
                            dense
                            hide-details="auto"
                            label="ニュース日"
                            outlined
                            placeholder=" "
                            readonly
                            v-on="on"
                            @click:clear="news.notice_date = null"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="news.notice_date"
                        @input="noticeDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      offset-y
                      transition="scale-transition"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="開始日"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="news.display_period_start_date"
                            :error-messages="errors"
                            clearable
                            dense
                            hide-details="auto"
                            label="開始日"
                            outlined
                            placeholder=" "
                            readonly
                            v-on="on"
                            @click:clear="news.display_period_start_date = null"
                          >
                            <template
                              v-if="!$vuetify.breakpoint.smAndDown"
                              v-slot:append-outer
                            >
                              &emsp;〜
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="news.display_period_start_date"
                        @input="startDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      offset-y
                      transition="scale-transition"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="終了日"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="news.display_period_end_date"
                            :error-messages="errors"
                            clearable
                            dense
                            hide-details="auto"
                            label="終了日"
                            outlined
                            placeholder=" "
                            readonly
                            v-on="on"
                            @click:clear="news.display_period_end_date = null"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="news.display_period_end_date"
                        @input="endDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="news.id"
                  color="error"
                  @click="deleteButtonClickEvent"
                >
                  削除
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="cancelButtonClickEvent"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  :disabled="invalid"
                  color="primary"
                  @click="registerButtonClickEvent"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <confirm ref="confirm" />
  </div>
</template>

<script>

  import { mapActions } from 'vuex'
  import Confirm from "../../../components/Confirm";
  import alertMessage from "../../../components/AlertMessage";

  export default {
    components: {
      alertMessage, Confirm
    },
    mixins: [],
    props: {
    },
    data () {
      return {
        news: {},
        noticeDateMenu: false,
        startDateMenu: false,
        endDateMenu: false,
      }
    },
    created() {
      if (this.$route.params.id) {
        this.editNews({ id: this.$route.params.id }).then((content) => {
          this.news = content
        })
      } else {
        this.newNews().then((content) => {
          this.news = content
        })
      }
    },
    methods: {
      ...mapActions('backNews', {
        newNews: 'new',
        editNews: 'edit',
        createNews: 'create',
        updateNews: 'update',
        deleteNews: 'destroy'
      }),
      cancelButtonClickEvent () {
        this.$router.push({name: 'byNewsIndex'})
      },
      registerButtonClickEvent () {
        if (this.$route.params.id) {
          this.updateNews({ id: this.news.id, params: this.news }).then(() => {
            this.$router.push({name: 'byNewsIndex'})
          })
        } else {
          this.createNews({ params: this.news }).then(() => {
            this.$router.push({name: 'byNewsIndex'})
          })
        }
      },
      deleteButtonClickEvent () {
        this.$refs.confirm.showDeleteConfirm().then((confirm) => {
          this.$refs.confirm.close()
          if (confirm) {
            this.deleteNews( { id: this.news.id }).then(() => {
              this.$router.push({name: 'byNewsIndex'})
            })
          }
        })
      }
    },
  }
</script>

<style lang="scss">
</style>
