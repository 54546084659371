/**
 * 注文
 */
import Vue from "vue";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/card_switches`

const card_switch = {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
    fetch ({ _commit }, {token}) {
      const api_path = `${API_PATH}/${token}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    update ({ commit }, { token, params }) {
      const api_path = `${API_PATH}/${token}`
      return new Promise((resolve, _reject) => {
        Vue.$http.patch(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
  },
  mutations: {},
}

export default card_switch
