/**
 * 返却
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/send_backs`

const send_back = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {
      order_no: null,
      part_number: null,
      product_stock_no: null,
      customer_name: null,
      order_start_date: null,
      order_end_date: null,
      return_schedule_start_date: null,
      return_schedule_end_date: null,
      include_returned: false,
    },
  },
  getters: {
    hasSendBacks: ( state ) => {
      return (state.pagination.total_count > 0)
    }
  },
  actions: {
    fetchAll({ dispatch, commit, state }, { order_no, part_number, product_stock_no, customer_name, order_start_date, order_end_date, return_schedule_start_date, return_schedule_end_date, include_returned, page}) {
      const queries = {
        order_no: order_no,
        part_number: part_number,
        product_stock_no: product_stock_no,
        customer_name: customer_name,
        order_start_date: order_start_date,
        order_end_date: order_end_date,
        return_schedule_start_date: return_schedule_start_date,
        return_schedule_end_date: return_schedule_end_date,
        include_returned: include_returned
      }
      dispatch('fetchQuery', queries)
      const params = {
        order_no: state.query.order_no,
        part_number: state.query.part_number,
        product_stock_no: state.query.product_stock_no,
        customer_name: state.query.customer_name,
        order_start_date: state.query.order_start_date,
        order_end_date: state.query.order_end_date,
        return_schedule_start_date: state.query.return_schedule_start_date,
        return_schedule_end_date: state.query.return_schedule_end_date,
        include_returned: state.query.include_returned,
        page: page
      }
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_SEND_BACK, { content })
          resolve(data.content)
        })
      })
    },
    fetchQuery({ commit, state}, { order_no, part_number, product_stock_no, customer_name, order_start_date, order_end_date, return_schedule_start_date, return_schedule_end_date, include_returned }) {
      const orderNo = (order_no !== undefined)? order_no : state.query.order_no
      const partNumber = (part_number !== undefined) ? part_number : state.query.part_number
      const productStockNo = (product_stock_no !== undefined) ? product_stock_no : state.product_stock_no
      const customerName = (customer_name !== undefined)? customer_name : state.query.customer_name
      const orderStartDate = (order_start_date !== undefined)? order_start_date : state.query.order_start_date
      const orderEndDate = (order_end_date !== undefined)? order_end_date : state.query.order_end_date
      const returnScheduleStartDate = (return_schedule_start_date !== undefined)? return_schedule_start_date : state.query.return_schedule_start_date
      const returnScheduleEndDate = (return_schedule_end_date !== undefined) ? return_schedule_end_date : state.query.return_schedule_end_date
      const includeReturned = (include_returned !== undefined) ? include_returned: state.query.include_returned
      const params = {
        order_no: orderNo,
        part_number: partNumber,
        product_stock_no: productStockNo,
        customer_name: customerName,
        order_start_date: orderStartDate,
        order_end_date: orderEndDate,
        return_schedule_start_date: returnScheduleStartDate,
        return_schedule_end_date: returnScheduleEndDate,
        include_returned: includeReturned
      }
      commit(types.BACK_SEND_BACK_QUERY, { params })
    },
    resetQuery({ commit, state }) {
      const params = {
        order_no: null,
        part_number: null,
        product_stock_no: null,
        customer_name: null,
        order_start_date: null,
        order_end_date: null,
        return_schedule_start_date: null,
        return_schedule_end_date: null,
        include_returned: false
      }
      commit(types.BACK_SEND_BACK_QUERY, { params })
    },
    create( { commit, state }, { params } ) {
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(API_PATH, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(content)
        } )
      })
    },
  },
  mutations: {
    [types.BACK_SEND_BACK] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_SEND_BACK_QUERY] (state, { params }) {
      state.query.order_no = params.order_no
      state.query.part_number = params.part_number
      state.query.product_stock_no = params.product_stock_no
      state.query.customer_name = params.customer_name
      state.query.order_start_date = params.order_start_date
      state.query.order_end_date = params.order_end_date
      state.query.return_schedule_start_date = params.return_schedule_start_date
      state.query.return_schedule_end_date = params.return_schedule_end_date
      state.query.include_returned = params.include_returned
    },
  },
}

export default send_back