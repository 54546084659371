<!-- バックヤード > 商品編集 -->
<template>
  <div v-if="productDetail">
    <v-breadcrumbs
        class="px-0 py-2"
        :items="breadcrumbs"
    />

    <v-alert
      outlined
      dense
      color="light-blue darken-4"
    >
      No. {{ productDetail.no }}<br>
      品番1：{{ productDetail.part_number_1 }}&emsp;&emsp;品番2：{{ productDetail.part_number_2 }}&emsp;&emsp;品番3：{{ productDetail.part_number_3 }}&emsp;&emsp;商品名：{{ productDetail.name }}
    </v-alert>

    <Form
      v-if="product"
      :product="product"
      :company-mode="company_mode"
      @updateCallback="loadProduct"
    />

    <Confirm ref="confirm" />
  </div>
</template>

<script>
  import Confirm from "../../../components/Confirm";
  import Form from "./_Form"
  import {mapActions, mapState} from "vuex";

  export default {
    components: {
      Confirm,
      Form,
    },
    mixins: [
    ],
    data () {
      return {
        breadcrumbs: [
          { text: '商品一覧', disabled: false, to: '/backyard/products', exact: true },
          { text: '商品編集', disabled: true, to: '' },
        ],
        product: null,
        company_mode: '',
      }
    },
    computed: {
      ...mapState('backProduct', {
        productDetail: 'product'
      }),
    },
    created() {
      this.showProduct({ id: this.$route.params.id })
      this.loadProduct()
    },
    methods: {
      ...mapActions('backProduct', {
        showProduct: 'fetch',
        editProduct: 'edit',
      }),
      loadProduct () {
        this.editProduct({ id: this.$route.params.id }).then((content) => {
          this.product = content
          this.company_mode = content.company_mode
        })
      }

    },
  }
</script>

<style lang="scss">

</style>
