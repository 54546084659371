/**
 * 商品
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const product = {
  namespaced: true,
  state: {
    product: null,
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {
      category_ids: [],
      tag_ids: [],
      keyword: '',
      receive_comment: '',
      local_place_id: null,
      part_no: null,
      new_arrival: false,
      enable_display_date: false,
      display_date: null,
      only_published: false,
      only_uninspected: false,
      local_stock_item: false,
    },
  },
  getters: {
    isProducts: ( state ) => {
      return (state.pagination.total_count > 0)
    },
    productDetail(state) {
      return state.product
    }
  },
  actions: {
    findProducts ({ _commit },{ params }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          resolve(data.content)
        })
      })
    },
    fetchProducts({ dispatch, commit, state }, {category_ids, tag_ids, part_no, keyword, receive_comment, new_arrival, enable_display_date, display_date, only_published, only_uninspected, local_stock_item, page}) {
      const queries = {
        category_ids: category_ids,
        tag_ids: tag_ids,
        part_no: part_no,
        keyword: keyword,
        receive_comment: receive_comment,
        new_arrival: new_arrival,
        enable_display_date: enable_display_date,
        display_date: display_date,
        only_published: only_published,
        only_uninspected: only_uninspected,
        local_stock_item: local_stock_item,
      }
      dispatch('fetchQuery', queries)
      const params = {
        part_no: state.query.part_no,
        keyword: state.query.keyword,
        receive_comment: state.query.receive_comment,
        category_ids: state.query.category_ids,
        tag_ids: state.query.tag_ids,
        new_arrival: state.query.new_arrival,
        enable_display_date: state.query.enable_display_date,
        display_date: state.query.display_date,
        only_published: state.query.only_published,
        only_uninspected: state.query.only_uninspected,
        local_stock_item: state.query.local_stock_item,
        use_calendar: state.query.use_calendar,
        page: page
      }

      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_PRODUCT, { content })
          resolve(data.content)
        })
      })
    },
    fetchQuery({ commit, state}, {category_ids, tag_ids, part_no, keyword, receive_comment, local_place_id, new_arrival, enable_display_date, display_date, only_published, only_uninspected, local_stock_item, use_calendar }) {

      const categoryIds = (category_ids !== undefined)? category_ids : state.query.category_ids
      const tagIds = (tag_ids !== undefined)? tag_ids : state.query.tag_ids
      const partNo = (part_no !== undefined) ? part_no : state.query.part_no
      const keywordText = (keyword !== undefined)? keyword : state.query.keyword
      const receiveComment = (receive_comment !== undefined)? receive_comment : state.query.receive_comment
      const localPlaceId = (local_place_id !== undefined)? local_place_id : state.query.local_place_id
      const newArrival = (new_arrival !== undefined)? new_arrival : state.query.new_arrival
      const enableDisplayDate = (enable_display_date !== undefined) ? enable_display_date : state.query.enable_display_date
      const displayDate = (display_date !== undefined) ? display_date: state.query.display_date
      const onlyPublished = (only_published !== undefined) ? only_published: state.query.only_published
      const onlyUninspected = (only_uninspected !== undefined) ? only_uninspected : state.query.only_uninspected
      const localStockItem = (local_stock_item !== undefined) ? local_stock_item : state.query.local_stock_item
      const useCalendar = (use_calendar !== undefined) ? use_calendar : state.query.use_calendar

      const params = {
        category_ids: categoryIds,
        tag_ids: tagIds,
        part_no: partNo,
        keyword: keywordText,
        receive_comment: receiveComment,
        local_place_id: localPlaceId,
        new_arrival: newArrival,
        enable_display_date: enableDisplayDate,
        display_date: displayDate,
        only_published: onlyPublished,
        only_uninspected: onlyUninspected,
        local_stock_item: localStockItem,
        use_calendar: useCalendar,
      }
      commit(types.BACK_PRODUCT_QUERY, { params })
    },
    resetQuery({ commit, state }) {
      const params = {
        category_ids: [],
        tag_ids: [],
        part_no: null,
        keyword: '',
        receive_comment: '',
        new_arrival: false,
        enable_display_date: false,
        display_date: null,
        only_published: false,
        only_uninspected: false,
        local_stock_item: false,
      }
      commit(types.BACK_PRODUCT_QUERY, { params })
    },
    fetch ({ commit, state }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          commit(types.BACK_PRODUCT_DETAIL, { content })
          resolve(content)
        })
      })
    },
    new ({ _commit }) {
      const api_path = `${API_PATH}/new`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    edit({ _commit, state }, { id }) {
      const api_path = `${API_PATH}/${id}/edit`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    create( { commit, state }, { params } ) {
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(API_PATH, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          commit(types.BACK_PRODUCT_DETAIL, { content })
          resolve(content)
        } )
      })
    },
    update( {commit, state}, { id, params } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          commit(types.BACK_PRODUCT_DETAIL, { content })
          resolve(content)
        } )
      })
    },
    destroy( { commit, state }, { id } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.delete(api_path).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
    duplicate( { commit, state }, { id } ) {
      const api_path = `${API_PATH}/${id}/duplicate`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.post(api_path).then(data => {
          commit('snackbar/showInfo', { message: '複写しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
  },
  mutations: {
    [types.BACK_PRODUCT] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_PRODUCT_QUERY] (state, { params }) {
      state.query.category_ids = params.category_ids
      state.query.tag_ids = params.tag_ids
      state.query.part_no = params.part_no
      state.query.keyword = params.keyword
      state.query.receive_comment = params.receive_comment
      state.query.new_arrival = params.new_arrival
      state.query.enable_display_date = params.enable_display_date
      state.query.display_date = params.display_date
      state.query.only_published = params.only_published
      state.query.only_uninspected = params.only_uninspected
      state.query.local_stock_item = params.local_stock_item
      state.query.use_calendar = params.use_calendar
    },
    [types.BACK_PRODUCT_DETAIL] (state, { content }) {
      state.product = content
    }
  },
}

export default product