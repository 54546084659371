<!-- バックヤード > メールテンプレート編集 -->
<template>
  <div>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn @click="templateMailCancel">
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            @click="templateMailSave"
          >
            保存
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <Form />

    <v-row>
      <v-col cols="6">
        <v-btn
          color="error"
          @click="templateMailDelete"
        >
          削除
        </v-btn>
      </v-col>
      <v-col cols="6">
        <div class="float-right">
          <v-btn @click="templateMailCancel">
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            @click="templateMailSave"
          >
            保存
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <Confirm ref="confirm" />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import Confirm from "../../../components/Confirm";
  import Form from "./_Form"

  export default {
    components: {
      Confirm,
      Form,
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
      }
    },
    methods: {
      templateMailCancel: function () {
        this.$router.push({name: 'byTemplateMailIndex'})
      },
      templateMailDelete: function () {
        this.$refs.confirm.showDeleteConfirm().then((confirm) => {
          this.$refs.confirm.close()
          if (confirm) {
            this.$router.push({name: 'byTemplateMailIndex'})
          }
        })
      },
      templateMailSave: function () {
        this.$router.push({name: 'byTemplateMailIndex'})
      },
    },
  }
</script>

<style lang="scss">

</style>
