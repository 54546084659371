<!-- バックヤード > 問い合わせ内容登録ダイアログ -->
<template>
  <v-dialog
    v-model="$parent.inquiryRegistDialog"
    width="500"
  >
    <v-card>
      <v-card-text class="pb-0">
        <v-row>
          <v-col>
            <v-textarea
              outlined
              clearable
              counter
              label="内容"
              auto-grow
              row-height="15"
              dense
              hide-details="auto"
              placeholder=" "
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <span class="required">内容</span>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$parent.inquiryRegistDialog = false">
          キャンセル
        </v-btn>
        <v-btn
          color="primary"
          @click="$parent.inquiryRegistDialog = false"
        >
          書込
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
  },
  mixins: [
  ],
  data () {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>