import FrontApp from '../frontend/App'

import CardSwitchShow from '../frontend/pages/card_switches/Show'
import CardSwitchCompleted from '../frontend/pages/card_switches/Completed'

import HomeIndex from '../frontend/pages/home/Index'
import ForgetIndex from '../frontend/pages/forget/Index'
import ForgetSent from '../frontend/pages/forget/Sent'
import ForgetEdit from '../frontend/pages/forget/Edit'
import ForgetCompleted from '../frontend/pages/forget/Completed'
import ProductIndex from '../frontend/pages/products/Index'
import ProductShow from '../frontend/pages/products/Show'
import ProductShowAuction from '../frontend/pages/products/ShowAuction'
import CartIndex from '../frontend/pages/carts/Index'
import CartIndexAuction from '../frontend/pages/carts/IndexAuction'
import CompanyShow from '../frontend/pages/company/Show'
import MembershipProfileNew from '../frontend/pages/membership/profile/New'
import MembershipProfileCompleted from '../frontend/pages/membership/profile/Completed'
import MembershipProfileEdit from '../frontend/pages/membership/profile/Edit'
import ActivateCompleted from '../frontend/pages/membership/activate/Completed'
import ActivateCompletedAfter from '../frontend/pages/membership/activate/CompletedAfter'
import MembershipHistoryIndex from '../frontend/pages/membership/histories/Index'
import MembershipHistoryIndexAuction from '../frontend/pages/membership/histories/IndexAuction'
import MembershipHistoryShow from '../frontend/pages/membership/histories/Show'
import MembershipExtendIndex from '../frontend/pages/membership/extend/Index'
import MembershipNotificationIndex from '../frontend/pages/membership/notifications/Index'
import MembershipPaymentEdit from '../frontend/pages/membership/payment/Edit'
import SessionIndex from '../frontend/pages/sessioins/Index'
import MyPageShow from '../frontend/pages/mypages/Show'
import MembershipTicketIndex from '../frontend/pages/membership/tickets/Index'
import MembershipTicketEdit from '../frontend/pages/membership/tickets/Edit'
import MembershipIdentificationEdit from '../frontend/pages/membership/identification/Edit'

import NotFound from "../components/pages/NotFound";

export default {
    path: '/',
    component: FrontApp,
    children: [
        { path: '/', name: 'HomeIndex', component: HomeIndex, meta: { siteSubTitle: '' } },

        { path: '/card_switches/:token', name: 'CardSwitchShow', component: CardSwitchShow, meta: {isPublic: true} },
        { path: '/card_switches/Completed', name: 'CardSwitchCompleted', component: CardSwitchCompleted, meta: {isPublic: true} },

        { path: '/forget', name: 'ForgetIndex', component: ForgetIndex, meta: { siteSubTitle: 'パスワードを忘れた方' } },
        { path: '/forget/sent', name: 'ForgetSent', component: ForgetSent, meta: { siteSubTitle: 'メール送信完了' } },
        { path: '/forget/completed', name: 'ForgetCompleted', component: ForgetCompleted, meta: { siteSubTitle: 'パスワード再設定完了' }},
        { path: '/forget/:token', name: 'ForgetEdit', component: ForgetEdit, meta: { siteSubTitle: 'パスワード再設定' }},
        { path: '/products', name: 'ProductIndex', component: ProductIndex, props: true, meta: { siteSubTitle: '商品一覧' } },
        { path: '/products/:id', name: 'ProductShow', component: ProductShow, props: true, meta: { siteSubTitle: '商品詳細' } },
        { path: '/product_auctions/:id', name: 'ProductShowAuction', component: ProductShowAuction, props: true, meta: { siteSubTitle: '商品詳細' } },
        { path: '/carts', name: 'CartsIndex', component: CartIndex, meta: { siteSubTitle: 'お申し込み' } },
        { path: '/cart_auctions', name: 'CartsIndexAuction', component: CartIndexAuction, meta: { siteSubTitle: 'お気に入り' } },
        { path: '/company/:tab_name', name: 'CompanyShow', component: CompanyShow, props: true, meta: { siteSubTitle: '会社情報' } },
        { path: '/profile/new', name: 'MembershipProfileNew', component: MembershipProfileNew, meta: { siteSubTitle: 'プロフィール作成' } },
        { path: '/profile/completed', name: 'MembershipProfileCompleted', component: MembershipProfileCompleted, meta: { siteSubTitle: 'プロフィール完了' } },
        { path: '/profile/edit', name: 'MembershipProfileEdit', component: MembershipProfileEdit, meta: { siteSubTitle: 'プロフィール編集' } },
        { path: '/payment/edit', name: 'MembershipPaymentEdit', component: MembershipPaymentEdit, meta: { siteSubTitle: 'お支払い方法' } },
        { path: '/identification/edit', name: 'MembershipIdentificationEdit', component: MembershipIdentificationEdit, meta: { siteSubTitle: '身分証明書' } },
        { path: '/activate/completed', name: 'ActivateCompleted', component: ActivateCompleted, meta: { siteSubTitle: 'メールアドレス認証完了' } },
        { path: '/activate/completedAfter', name: 'ActivateCompletedAfter', component: ActivateCompletedAfter, meta: { siteSubTitle: 'メールアドレス認証完了' } },
        { path: '/histories', name: 'MembershipHistoryIndex', component: MembershipHistoryIndex, meta: { siteSubTitle: '注文履歴' } },
        { path: '/histories/:id', name: 'MembershipHistoryShow', component: MembershipHistoryShow, props: true, meta: { siteSubTitle: '注文詳細' } },
        { path: '/histories/:id/extends', name: 'MembershipExtendIndex', component: MembershipExtendIndex, props: true, meta: { siteSubTitle: '期間延長' } },
        { path: '/history_auctions', name: 'MembershipHistoryIndexAuction', component: MembershipHistoryIndexAuction, meta: { siteSubTitle: '取引履歴' } },
        { path: '/notifications', name: 'MembershipNotificationIndex', component: MembershipNotificationIndex, meta: { siteSubTitle: '店舗からのお知らせ' } },
        { path: '/tickets', name: 'MembershipTicketIndex', component: MembershipTicketIndex, meta: { siteSubTitle: '店舗への問い合わせ' } },
        { path: '/tickets/:id', name: 'MembershipTicketEdit', component: MembershipTicketEdit, meta: { siteSubTitle: '店舗への問い合わせ' } },
        { path: '/sessions', name: 'SessionIndex', component: SessionIndex, meta: { siteSubTitle: 'ログイン' } },
        { path: '/my_page/:post_name', name: 'MyPageShow', component: MyPageShow, props: true, meta: { siteSubTitle: 'マイページ' } },
        { path: '*', name: 'notFound', component: NotFound, meta: { title: 'お探しのページは見つかりませんでした' } }
    ]
}
