/**
 * 身分証明書
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/customer/identifications`

const identification = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    findIdentifications ({ _commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          resolve(data.content)
        })
      })
    },
    update ({ commit }, { params }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.post(API_PATH, params, { headers: { 'content-type': 'multipart/form-data'}}).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
    delete ({ commit }, { id }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, reject) => {
        Vue.$http.delete(api_path).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
  },
  mutations: {},
}

export default identification
