/**
 * snackbar
 */

const snackbar = {
  namespaced: true,
  state: {
    isShow: false,
    message: null,
    color: 'info',
    isShowLoginBtn: false,
  },
  getters: {},
  actions: {
    showInfo ({ commit }, { message }) {
      commit('showInfo', { message: message })
    },
    hide ({ commit }) {
      commit('hide')
    },
  },
  mutations: {
    showInfo (state, { message }) {
      state.isShow = true
      state.message = message
      state.color = 'info'
      state.isShowLoginBtn = false
    },
    showError (state, { message,isShowLoginBtn }) {
      state.isShow = true
      state.message = message
      state.color = 'error'
      state.isShowLoginBtn = (isShowLoginBtn) ? isShowLoginBtn : false
      state.isShowLoginBtn = (isShowLoginBtn) ? isShowLoginBtn : false
    },
    hide (state) {
      state.isShow = false
      state.message = null
      state.color = 'info'
      state.isShowLoginBtn = false
    }
  },
}

export default snackbar
