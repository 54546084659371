/**
 * ヘッダー構成
 */
import Vue from "vue";
import * as types from "../../../mutation-types/frontend-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/configurations/header_option`

const config_header_option = {
  namespaced: true,
  state: {
    theme: [],
    cart_count: 0,

  },
  getters: {
    isHeaderImage: (state) => {
      return (state.theme.image_logo === 'image')
    },
    isHeaderText: (state) => {
      return (state.theme.image_logo === 'text')
    },
    getHeaderColor: (state) => {
      return state.theme.header_color
    },
    getIconColor: (state) => {
      return state.theme.icon_color
    },
    getButtonColor: (state) => {
      return state.theme.button_color
    },
    getButtonFontColor: (state) => {
      return state.theme.button_font_color
    },
  },
  actions: {
    fetch({ commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          commit(types.FRONT_HEADER, { content })
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.FRONT_HEADER] (state, { content }) {
      state.cart_count = content.cart_count
      state.theme = content.theme
    },
  },
}

export default config_header_option
