<!-- バックヤード > キャンペーンform -->
<template>
  <div>
    <alert-message />
    <v-row>
      <v-col>
        <v-tabs
          v-model="campaignForm"
          background-color="blue lighten-4"
          centered
          icons-and-text
          fixed-tabs
        >
          <v-tabs-slider />

          <v-tab href="#tab-basic">
            基本情報
            <v-icon>mdi-diamond-stone</v-icon>
          </v-tab>

          <v-tab href="#tab-relations">
            商品登録
            <v-icon>mdi-flower-poppy</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="campaignForm">
          <v-tab-item value="tab-basic">
            <basic-form
              :is-edit="true"
              :campaign="campaign"
              :company-mode-caption-name="companyModeCaptionName"
            />
          </v-tab-item>

          <v-tab-item value="tab-relations">
            <relations-form :campaign="campaign" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AlertMessage from '../../../components/AlertMessage'
import BasicForm from './forms/_Basic'
import RelationsForm from './forms/_Relations'
export default {
  components: {
    AlertMessage,
    BasicForm,
    RelationsForm,
  },
  mixins: [
  ],
  props: {
    campaign: {
      type: Object,
      default: () => {
        return {}
      },
    },
    companyModeCaptionName:{
      type: String,
      default: '',
    },
  },
  data () {
    return {
      campaignForm: 'tab-basic',
    }
  },
  computed: {
  },
}
</script>

<style lang="scss">
</style>
