<!-- バックヤード > ニュース編集 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-row>
      <v-col>
        <news-form />
      </v-col>
    </v-row>
    <confirm ref="confirm" />
  </div>
</template>

<script>
import Confirm from '../../../components/Confirm'
import NewsForm from './_Form'

export default {
  components: {
    Confirm,
    NewsForm,
  },
  mixins: [],
  data () {
    return {
      news: {},
      breadcrumbs: [
        { text: 'ニュース一覧', disabled: false, to: '/backyard/news', exact: true },
        { text: 'ニュース詳細', disabled: true, to: '' },
      ],
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss">

</style>
