/**
 * 商品
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.ADMIN_BASE}/api/banks`

const branch = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        fetchAll({ _commit }, {bank_id, page}) {
            const api_path = `${API_PATH}/${bank_id}/branches`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
    },
    mutations: {},
}

export default branch