<template>
  <div>
    <h2 align="center">
      <p>
        ありがとうございます<br>
        <span class="lang_lbl">{{$t('view.ありがとうございます')}}</span>
      </p>
      <p>
        注文を承りました<br>
        <span class="lang_lbl">{{$t('view.注文完了メッセージ_1')}}</span>
      </p>
    </h2>
    <v-row>
      <v-col cols="12" align="center" class="subtitle-1">
        登録されているメールアドレスにご注文内容が記載されたメールをお送りいたします。
        <div class="lang_lbl">{{$t('view.注文完了メッセージ_2')}}</div>
      </v-col>
      <v-col cols="12" align="center">
        <v-icon x-large color="primary">mdi-truck-delivery-outline</v-icon>
      </v-col>
      <v-col cols="12" align="center">
        <v-btn
          :block="$vuetify.breakpoint.mdAndDown"
          color="primary"
          outlined
          @click="moveToHome"
        >
          <v-icon>mdi-home-outline</v-icon>ホームに戻る
          <div class="lang_lbl">{{$t('view.ホームに戻る')}}</div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CartStepperMixin from '../../../../mixins/frontend/cart-stepper-mixin'

export default {
  name: 'OrderComplete',
  mixins: [
    CartStepperMixin
  ],
  data () {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped lang="scss">
</style>