<template>
  <div>
    <side-nav ref="sideNav" />
    <v-app-bar
      color="header"
      :style="icon_styles"
      class="header_colors"
      dark
      app
      fixed
    >
      <v-app-bar-nav-icon @click="drawSideNav" class="header_icon" />
      <router-link to="/">
        <h1>
          <v-img
            v-if="isHeaderImage"
            :src="theme.logo_url | load_image_url"
            class="header_logo"
            alt="logo"
            max-height="50"
            max-width="300"
          />
          <v-sheet
            v-if="isHeaderText"
            color="header"
          >
            <v-toolbar-title >{{ theme.logo_title }}</v-toolbar-title>
          </v-sheet>
        </h1>
      </router-link>
      <v-spacer />
      <span
        class="hidden-sm-and-down"
        style="width: 100vh;"
      >
        <keyword-search />
      </span>
      <v-spacer />
      <template v-if="logged_in" >        
        <!-- <select name="changeLanguage" id="changeLanguage"  @change="handleClick_changeLanguage(langType)" v-model="langType">
          <option v-for="item in langOptions" :value="item.value">
            {{item.text}}
          </option>
        </select> -->
        <v-btn
          text
          class="header_icon"
          @click="moveToPage('MembershipHistoryIndex')"
        >
          <h4
            v-if="companyContent.mode=='rental'"
          >
            注文履歴
            <div class="lang_lbl">{{$t("view.注文履歴")}}</div>
          </h4>
          <h4
            v-if="companyContent.mode=='auction'"
          >
            取引履歴
            <div class="lang_lbl">{{$t("view.取引履歴")}}</div>
          </h4>
        </v-btn>
        <v-btn
          icon
          @click="showCart"
        >
          <v-badge
            color="green"
            :content="cartCount"
            :value="cartCount"
            overlap
          >
            <v-icon
              v-if="companyContent.mode == 'rental'"
              class="header_icon"
            >
              mdi-cart
            </v-icon>
            <v-icon
              v-if="companyContent.mode=='auction'"
              class="header_icon"
            >
              mdi-star
            </v-icon>
          </v-badge>
        </v-btn>
        <v-toolbar-items>
          <v-menu
            ref="menu"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                style="align-self: center"
                v-on="on"
              >
                <v-list-item-avatar class="hidden-md-and-up">
                  <span class="white--text header_icon">
                    {{ avatarName }}
                  </span>
                </v-list-item-avatar>
                <v-btn
                  text
                  class="hidden-sm-and-down header_icon"
                >
                  {{ honorificName }}<v-icon class="header_icon">mdi-menu-down</v-icon>
                </v-btn>
              </span>
            </template>


            <v-list
              dense
              class="py-0"
            >
              <v-list-item-group
                v-model="listItem"
                mandatory
                color="header"
              >
                <v-list-group
                  v-for="groupItem in listGroupItems"
                  :key="groupItem.title"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-icon class="mr-2">
                      <v-icon class="header_icon">{{ groupItem.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title  v-html="groupItem.title"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="item in groupItem.items"
                    :key="item.title"
                    class="pl-10"
                    link
                    @click="moveToPage(item.pathName)"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon class="header_icon">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-html="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

                <v-list-item
                  v-for="item in listItems"
                  :key="item.title"
                  link
                  @click="moveToPage(item.pathName)"
                >
                  <v-list-item-icon class="mr-2">
                    <v-icon class="header_icon">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-toolbar-items>
      </template>
      <template v-else>
        <v-btn
            icon
            @click="showCart"
        >
          <v-badge
              color="green"
              :content="cartCount"
              :value="cartCount"
              overlap
          >
            <v-icon
                v-if="companyContent.mode == 'rental'"
                class="header_icon"
            >
              mdi-cart
            </v-icon>
            <div class="lang_lbl header_icon">
              {{$t("view.カート")}}
            </div>
            <v-icon
                v-if="companyContent.mode=='auction'"
                class="header_icon"
            >
              mdi-star
            </v-icon>
          </v-badge>
        </v-btn>
        <v-btn
          text
          @click="moveToNewMembership"
        >
          <div>
            <v-icon small class="header_icon">
              mdi-account-circle
            </v-icon>
            <div>
              <small class="header_icon">
                新規会員登録
              </small>
              <div class="lang_lbl header_icon">{{$t("view.新規会員登録")}}</div>
            </div>
          </div>
        </v-btn>
        <v-btn
          text
          @click="showLogin"
        >
          <div>
            <v-icon small class="header_icon">
              mdi-login
            </v-icon>
            <div>
              <small class="header_icon">ログイン</small>
              <div class="lang_lbl header_icon">{{$t("view.ログイン")}}</div>
            </div>
          </div>
        </v-btn>
      </template>
    </v-app-bar>
  </div>
</template>

<script>

import {mapState, mapActions, mapGetters} from 'vuex'
  import SideNav from './SideNav'
  import KeywordSearch from './field/KeywardSearch'
  import ThemeOptionMixin from '../../mixins/frontend/theme-option-mixin'
  import store from 'store'
  import * as appConst from '../../constants/appConst'

  // const getSelectLang = () => {
  //   return (store.get('selectLang'))
  // }
  export default {
    name: 'HeaderNav',
    components: {
      SideNav,
      KeywordSearch,
    },
    mixins: [
      ThemeOptionMixin,
    ],
    data () {
        return {
          listItem: null,
          header_color: null,
          icon_color: null,
          button_color: null,
          button_font_color: null,
          listGroupItems: [
            { title: "会員情報<div class='lang_lbl'>" + this.$i18n.t('view.会員情報') + "</div>", icon: 'mdi-account-outline', items:
              [
                { title: "プロフィール<div class='lang_lbl'>" + this.$i18n.t('view.プロフィール') + "</div>", icon: 'mdi-card-account-details-outline', pathName: 'MembershipProfileEdit' },
                { title: "お支払い方法<div class='lang_lbl'>" + this.$i18n.t('view.お支払い方法') + "</div>", icon: 'mdi-credit-card-outline', pathName: 'MembershipPaymentEdit' },
              ]
            },
          ],
          listItems: [
            { title: "店舗からのお知らせ<div class='lang_lbl'>" + this.$i18n.t('view.店舗からのお知らせ') + "</div>", icon: 'mdi-bell-outline', pathName: 'MembershipNotificationIndex' },
            { title: "店舗への問い合わせ<div class='lang_lbl'>" + this.$i18n.t('view.店舗への問い合わせ') + "</div>", icon: 'mdi-comment-alert-outline', pathName: 'MembershipTicketIndex' },
            { title: "ログアウト<div class='lang_lbl'>" + this.$i18n.t('view.ログアウト') + "</div>", icon: 'mdi-logout', pathName: 'logout' },
          ],
          // langType: 'ja',
          // langOptions: [
          //   { text: '日本語', value: 'ja' },
          //   { text: '英語', value: 'en' },
          // ]
        }
    },
    computed: {
      ...mapState('frontAuth', {
        loggedIn: 'logged_in',
      }),
      ...mapState('frontCart', {
        cartCount: 'cartCount'
      }),
      ...mapState('frontCredence', {
        companyContent: 'content',
      }),
      ...mapState('frontSystemSetting', {
        frontendAuthMode: 'frontend_auth_mode',
        auctionMode: 'auction_mode',
        useIdentification: 'use_identification',
      }),
      ...mapGetters('frontAuth', [
        'avatarName', 'honorificName'
      ]),
      ...mapGetters('frontHeaderOption', [
        'getIconColor','getHeaderColor'
      ]),
      logged_in () {
        return this.loggedIn
      },
      xsOnly () {
        return this.$vuetify.breakpoint.xsOnly
      },
      icon_styles() {
        return {
          '--header-color': this.getHeaderColor,
          '--icon-color': this.getIconColor,
        };
      },
    },
    created() {
      this.fetchCartCount()
      if (this.logged_in) {
        // this.fetchCartCount()
        this.fetchCompany()
      }
      if (this.auctionMode) {
        this.changeListGroup()
      }
      if (this.logged_in && this.useIdentification) {
        this.listGroupItems[0].items.push(
            { title: "身分証明書<div class='lang_lbl'>" + this.$i18n.t('view.身分証明書') + "</div>", icon: 'mdi-certificate-outline', pathName: 'MembershipIdentificationEdit' }
        )
      }
    },
    methods: {
      ...mapActions('frontCart', {
        fetchCartCount: 'fetchCount',
        resetCartCount: 'resetCount',
      }),
      ...mapActions('frontAuth', {
        signOut: 'signOutFrontend',
      }),
      ...mapActions('frontCredence', {
        fetchCompany: 'fetch'
      }),
      ...mapActions('frontExtendOrder', {
        resetExtendState: 'resetExtendState'
      }),
      changeListGroup () {
        this.listGroupItems = [
          { title: "会員情報<div class='lang_lbl'>" + this.$i18n.t('view.会員情報') + "</div>", icon: 'mdi-account-outline', items:
                [
                  { title: "プロフィール<div class='lang_lbl'>" + this.$i18n.t('view.プロフィール') + "</div>", icon: 'mdi-card-account-details-outline', pathName: 'MembershipProfileEdit' }
                ]
          },
        ]
      },
      showLogin () {
        this.moveToPage('SessionIndex')
      },
      showCart () {
        store.set(appConst.FRONT_STORE_CART_STEPPER, 1)
        this.resetExtendState()

        if (this.auctionMode) {
          this.moveToPage('CartsIndexAuction')
        } else {
          this.moveToPage('CartsIndex')
        }
      },
      drawSideNav () {
        this.$refs.sideNav.showSideNav()
      },
      moveToPage (name) {
        let routName = this.$route.name
        if (name === 'logout') {
          this.signOut().then(() => {
            this.resetCartCount()
            if (this.frontendAuthMode) {
              this.$router.replace({name: 'SessionIndex'}, () => {})
            } else {
              this.$router.replace({name: 'HomeIndex'}, () => {})
            }
          })
          this.$refs.menu.save()
        } else {
          if (routName !== name) {
            if (this.auctionMode) {
              if (name === 'MembershipHistoryIndex') {
                name = 'MembershipHistoryIndexAuction'
              }
            }
            this.$router.push({ name: name })
            if (this.$refs.menu) this.$refs.menu.save()
          }
        }
      },
      moveToNewMembership () {
        this.moveToPage('MembershipProfileNew')
        // this.$refs.menu.save()
      },
      handleClick_changeLanguage(lang) {
        this.$i18n.locale = lang;
      }
    }
  }

</script>

<style lang="scss">
header.header_colors {
  a.router-link-active{
    text-decoration: none !important;
    color:var(--icon-color) !important;
  }
  .theme--dark.v-sheet{
    color:var(--icon-color) !important;
  }
  .header_logo {
    max-width: 330px;
  }
  .header_icon,.header_icon .v-icon{
    color:var(--icon-color) !important;
  }
}
@media only screen and (max-width: 750px) {
  .header_logo { max-width: 140px !important; }
}
</style>
