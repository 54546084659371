const TicketMixin = {
  data() {
    return {
    }
  },
  computed: {
  },
  filters: {
    avatarNameFilter(name) {
      return (name) ? name.slice(0, 2) : ''
    },
    ticketPublishFilter(published) {
      if (published) {
        return 'あり'
      } else {
        return 'なし'
      }
    },
    ticketStatusFilter(status) {
      let status_text = ''
      switch (status) {
        case 'opened':
          status_text = 'オープン'
          break
        case 'in_process':
          status_text = '対応中'
          break
        case 'closed':
          status_text = '解決済'
          break
        default:
          status_text = ''
          break
      }
      return status_text
    },
  },
}

export default TicketMixin
