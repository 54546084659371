<template>
  <div>
    <v-container class="grey lighten-5">
      <v-breadcrumbs :items="breadcrumbs" />
      <v-card>
        <v-card-title>
          <h2>
            <v-icon class="mr-2">
              mdi-bell
            </v-icon>
            システムお知らせ編集
          </h2>
        </v-card-title>

        <v-card-text>
          <Form
            v-if="announcement"
            :is-edit="true"
            :announcement="announcement"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>

import Form from './_Form';
import {mapActions} from "vuex";

export default {
  name: 'AnnouncementEdit',
  components: {
    Form, // 共通フォーム参照
  },

  data () {
    return {
      announcement: null,
      breadcrumbs: [
        { text: 'システムお知らせ', disabled: false, to: '/admin/announcements', exact: true },
        { text: 'システムお知らせ詳細', disabled: false, to: '/admin/announcements/' + this.$route.params.id + '/show', exact: true },
        { text: 'システムお知らせ編集', disabled: false, href: '' }
      ],
    }
  },
  mounted() {
    this.$nextTick(function () {
      // ビュー全体がレンダリングされた後にのみ実行されるコード
    })
  },
  created() {
    // 編集取得
    this.editAnnouncement({id: this.$route.params.id}).then(( content ) => {
    this.announcement = content
    })
  },


  methods: {
    ...mapActions('adminAnnouncement', {
      editAnnouncement: 'edit',
    }),

    // キャンセルボタン
    deleteButtonClickEvent () {
      // delete dialog
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()

        if (confirm) {
          this.destroyAnnouncement({ id: this.$route.params.id }).then(() => {
            this.$router.push({ name: 'adAnnouncementIndex' })})
        }
      })
    },
    // 更新ボタン
    editButtonClickEvent () {
      this.$router.push( { name: 'adAnnouncementEdit', params: { id: 1 } } )
    }
  }

}
</script>

<style scoped>

</style>