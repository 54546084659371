import {mapActions, mapState} from "vuex";

/**
 * フッター、サイドナビのアクションを管理
 */
const FooterActionMixin = {
  data() {
    return {
      categories: [],
      campaigns: [],
      tags: [],
      conditionCount: 0,
      colsNumber: this.gridNumber()
    }
  },
  computed: {
    ...mapState('frontFooterOption', {
      theme: 'theme',
    }),
    isCategories () {
      return this.categories.length > 0
    },
    isCampaigns () {
      return this.campaigns.length > 0
    },
    isTags () {
      return this.tags.length > 0
    },
  },
  filters: {},
  created() {
    this.fetchFooterOption()
    this.fetchDisplayHome().then((content) => {
      this.categories = content.categories
      if (this.categories.length > 0) this.conditionCount = this.conditionCount + 1
    })
    this.fetchCampaigns().then((content) => {
      this.campaigns = content.campaigns
      if (this.campaigns.length > 0) this.conditionCount = this.conditionCount + 1
    })
    this.findFooterTags().then((content) => {
      this.tags = content.tags
      if (this.tags.length > 0) this.conditionCount = this.conditionCount + 1
    })
  },
  methods: {
    ...mapActions('frontFooterOption', {
      fetchFooterOption: 'fetch',
    }),
    ...mapActions('frontCategory', {
      fetchDisplayHome: 'fetchDisplayHome'
    }),
    ...mapActions('frontCampaign', {
      fetchCampaigns: 'fetch',
    }),
    ...mapActions('frontTag', {
      findFooterTags: 'findFooterTags',
    }),
    moveToPage (name) {
      let routName = this.$route.name
      if (routName !== name) {
        this.$router.push({ name: name })
      }
    },
    moveToCompany (tabName) {
      this.$router.replace({ name: 'CompanyShow', params: { tab_name: tabName } }, () => {})
    },
    moveToMyPage (myPage) {
      // historyを上書き NavigationDuplicated を出さないために callback を指定
      this.$router.replace({name: 'MyPageShow', params: {post_name: myPage.post_name, title: myPage.title}}, () => {})
    },
    moveToProduct (item, searchType) {
      const isClearCondition = true
      switch (searchType) {
        case 'category':
          this.$router.replace({name: 'ProductIndex', query: {category_id: item.id}, params: { isClearCondition: isClearCondition }}, () => {})
          break
        case 'campaign':
          this.$router.replace({name: 'ProductIndex', query: {campaign_id: item.id}, params: { isClearCondition: isClearCondition }}, () => {})
          break
        case 'tag':
          this.$router.replace({name: 'ProductIndex', query: {tag_id: item.id}, params: { isClearCondition: isClearCondition }}, () => {})
          break
      }
    },
    gridNumber () {
      return 12/this.conditionCount
    }
  }
}

export default FooterActionMixin
