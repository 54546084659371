<template>
  <div>
    <v-carousel
      v-if="isUseSlider"
      :cycle="isCycle"
      hide-delimiter-background
      :interval="interval"
      :height="$vuetify.breakpoint.smAndDown ? 250 : 500"
    >
      <template v-if="slideAnimation">
        <v-carousel-item
          v-for="(option,i) in slider.options"
          :key="i"
        >
          <carousel-item :option="option" />
        </v-carousel-item>
      </template>
      <template v-else>
        <v-carousel-item
          v-for="(option,i) in slider.options"
          :key="`fade_${i}`"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <carousel-item :option="option" />
        </v-carousel-item>
      </template>
    </v-carousel>
    <search-nav />

    <v-expand-transition>
      <banner-nav
        v-if="slider.use_banner_option"
        :option-type="slider.banner_option_type"
      />
    </v-expand-transition>

    <BackgroundImageContent
      v-if="slider.use_background_image_contents"
      :background-image-content="slider.background_image_content"
    />

    <v-container>
      <v-expand-transition>
        <v-row v-if="isUseNews">
          <v-col cols="12">
            <h2>
              {{ news.title }}
              <span class="subtitle-2 ml-4">
                {{ news.sub_title }}
              </span>
            </h2>
            <v-divider class="mt-2" />
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="newsHeaders"
              :items="newsItems"
              loading-text="読込中"
              hide-default-footer
              hide-default-header
              no-data-text=""
            >
              <template v-slot:item="{ item }">
                <tr
                  style="cursor: pointer"
                  @click="showNoticeDialog(item)"
                >
                  <td class="subtitle-1">
                    <span v-if="canShowNewsDate">{{ item.notice_date | date_format }}</span>
                    <span class="ml-2">{{ item.title }}</span>
                  </td>
                  <td class="text-right">
                    <v-icon>mdi-chevron-right</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-expand-transition>
        <banner-nav
          v-if="news.use_banner_option"
          :option-type="news.banner_option_type"
        />
      </v-expand-transition>
    </v-container>

    <BackgroundImageContent
      v-if="news.use_background_image_contents"
      :background-image-content="news.background_image_content"
    />

    <v-container>
      <v-expand-transition>
        <v-row v-if="isUseCategory">
          <v-col cols="12">
            <h2>
              {{ category.title }}
              <span class="subtitle-2 ml-4">
                {{ category.sub_title }}
              </span>
            </h2>
            <v-divider class="mt-2" />
          </v-col>
          <v-col
            v-for="item in categoryItems"
            :key="item.id"
            cols="12"
            sm="4"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                flat
                tile
              >
                <v-img
                  max-height="300"
                  contain
                  :src="item.image_url | load_image_url"
                  :lazy-src="item.image_url | load_image_url"
                  aspect-ratio="1"
                  class="white lighten-4 align-center"
                >
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-3 white--text"
                      style="height: 100%"
                    >
                      <div class="text-center">
                        <h4
                          class="font-weight-light title"
                          :style="{ color: item.selected_font_color }"
                        >
                          {{ item.display_name }}
                        </h4>
                        <v-btn
                          outlined
                          depressed
                          light
                          :style="{ color: item.selected_font_color }"
                          @click="showProductWithCategory(item)"
                        >
                          検索
                        </v-btn>
                      </div>
                    </div>
                  </v-expand-transition>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                  <div v-if="!hover">
                    <h2
                      class="text-center"
                      :style="{ color: item.default_font_color }"
                    >
                      {{ item.name }}
                    </h2>
                  </div>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-expand-transition>
        <banner-nav
          v-if="category.use_banner_option"
          :option-type="category.banner_option_type"
        />
      </v-expand-transition>
    </v-container>

    <BackgroundImageContent
      v-if="category.use_background_image_contents"
      :background-image-content="category.background_image_content"
    />

    <v-container>
      <v-row v-if="isUseNewArrivals">
        <v-col cols="12">
          <h2>
            {{ new_arrivals.title }}
            <span class="subtitle-2 ml-4">
              {{ new_arrivals.sub_title }}
            </span>
          </h2>
          <v-divider class="mt-2" />
        </v-col>
        <v-col
          cols="12"
          md="10"
          offset-md="1"
        >
          <v-row>
            <v-col
              v-for="item in newProducts"
              :key="item.id"
              cols="6"
              md="3"
            >
              <product-card :product="item" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <banner-nav
        v-if="new_arrivals.use_banner_option"
        :option-type="new_arrivals.banner_option_type"
      />
    </v-container>

    <BackgroundImageContent
      v-if="new_arrivals.use_background_image_contents"
      :background-image-content="new_arrivals.background_image_content"
    />

    <v-container>
      <v-expand-transition>
        <v-row v-if="isUseNewArrivalsButton">
          <v-col
            cols="12"
            md="4"
            offset-md="4"
            justify="center"
            class="text-center"
          >
            <v-btn
              block
              outlined
              color="black"
              @click="showProductWithNewArrivals"
            >
              {{ new_arrivals_button.title }}
            </v-btn>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-expand-transition>
        <banner-nav
          v-if="new_arrivals_button.use_banner_option"
          :option-type="new_arrivals_button.banner_option_type"
        />
      </v-expand-transition>
    </v-container>
    <BackgroundImageContent
      v-if="new_arrivals_button.use_background_image_contents"
      :background-image-content="new_arrivals_button.background_image_content"
    />
    <v-container>
      <v-expand-transition>
        <v-row v-if="isUseCampaign">
          <v-col cols="12">
            <h2>
              {{ campaign.title }}
              <span class="subtitle-2 ml-4">
                {{ campaign.sub_title }}
              </span>
            </h2>
            <v-divider class="mt-2" />
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="campaignHeaders"
              :items="campaignItems"
              loading-text="読込中"
              no-data-text=""
              hide-default-footer
              hide-default-header
            >
              <template v-slot:item="{ item }">
                <tr
                  style="cursor: pointer"
                  @click="showProductWithCampaign(item)"
                >
                  <td class="subtitle-1">
                    <span class="ml-2">{{ item.name }}</span>
                  </td>
                  <td class="text-right">
                    <v-icon>mdi-chevron-right</v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-expand-transition>
        <banner-nav
          v-if="campaign.use_banner_option"
          :option-type="campaign.banner_option_type"
        />
      </v-expand-transition>
    </v-container>

    <BackgroundImageContent
      v-if="campaign.use_background_image_contents"
      :background-image-content="campaign.background_image_content"
    />

    <v-container>
      <v-expand-transition>
        <v-row v-if="isUseCampaignButton">
          <v-col
            cols="12"
            md="4"
            offset-md="4"
            justify="center"
            class="text-center"
          >
            <v-btn
              block
              outlined
              color="black"
              @click="showProductWithAllCampaign"
            >
              {{ campaign_button.title }}
            </v-btn>
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-expand-transition>
        <banner-nav
          v-if="campaign_button.use_banner_option"
          :option-type="campaign_button.banner_option_type"
        />
      </v-expand-transition>
    </v-container>
    <BackgroundImageContent
      v-if="campaign_button.use_background_image_contents"
      :background-image-content="campaign_button.background_image_content"
    />
    <notice-dialog ref="noticeDialog" />
  </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex'
    import SearchNav from '../../components/SearchNav'
    import BannerNav from './_BannerNav'
    import CarouselItem from './_CarouselItem'
    import NoticeDialog from '../../components/NoticeDialog'
    import ProductCard from '../../components/ProductCard'
    import BackgroundImageContent from './_BackgroundImageContent'
    import PageHeaderMixin from '../../../mixins/frontend/page-header-mixin'

    export default {
      name: 'HomeIndex',
      components: {
        BackgroundImageContent,
        SearchNav,
        BannerNav,
        CarouselItem,
        NoticeDialog,
        ProductCard,
      },
      mixins: [
        PageHeaderMixin
      ],
      data() {
        return {
          newsHeaders: [
            {text: 'title', align: 'start', value: 'title'},
          ],
          campaignHeaders: [
            {text: 'name', align: 'start', value: 'name'},
          ],
          newProducts: [],
          newsItems: [],
          categoryItems: [],
          campaignItems: [],
          dialog: {
            headerTitle: '',
            title: '',
            content: '',
            showDialog: false
          },
        }
      },
      computed: {
        ...mapState('frontThemeOption', {
          slider: 'slider',
          news: 'news',
          category: 'category',
          new_arrivals: 'new_arrivals',
          new_arrivals_button: 'new_arrivals_button',
          campaign: 'campaign',
          campaign_button: 'campaign_button',
        }),
        ...mapGetters('frontThemeOption',
          [
            'isUseSlider',
            'isCycle',
            'interval',
            'slideAnimation',
            'isUseNews',
            'isUseCategory',
            'isUseNewArrivals',
            'isUseNewArrivalsButton',
            'isUseCampaign',
            'isUseCampaignButton',
            'canShowNewsDate',
          ]
        ),
        xsOnly () {
          return this.$vuetify.breakpoint.xsOnly
        },
      },
      created() {
        this.fetchThemeOption().then((content) => {
          this.meta_tags = content.meta_tags
          this.pageTitle = this.$route.meta.siteSubTitle
        })
        this.fetchDisplayHome().then((content) => {
          this.categoryItems = content.categories
        })
        const newArrivalParams = { only_new_arrival: true }
        this.findProducts({ params: newArrivalParams }).then((content) => {
          this.newProducts = content.products
        })
        this.findNotices().then((content) => {
          this.newsItems = content.notices
        })
        this.fetchCampaigns().then((content) => {
          this.campaignItems = content.campaigns
        })
      },
      methods: {
        ...mapActions('frontThemeOption', {
          fetchThemeOption: 'fetch',
        }),
        ...mapActions('frontCategory', {
          fetchDisplayHome: 'fetchDisplayHome'
        }),
        ...mapActions('frontProduct', {
          findProducts: 'findProducts',
        }),
        ...mapActions('frontCampaign', {
          fetchCampaigns: 'fetch'
        }),
        ...mapActions('frontNotice', {
          findNotices: 'findNotices',
          findNotice: 'find',
        }),
        showProductWithNewArrivals () {
          this.$router.push({name: 'ProductIndex', params: { isNewArrivals: true }})
        },
        showProductWithAllCampaign() {
          this.$router.push({name: 'ProductIndex', params: { isAllCampaign: true }})
        },
        showProductWithCampaign (item) {
          this.$router.push({name: 'ProductIndex', query: {campaign_id: item.id}})
        },
        showProductWithCategory (item) {
          this.$router.push({name: 'ProductIndex', query: {category_id: item.id}})
        },
        showNoticeDialog (newsItem) {
          this.findNotice({id: newsItem.id}).then((content) => {
            this.$refs.noticeDialog.open({headerTitle: this.news.title, title: content.title, description: content.description, noticeDate: content.notice_date, canShowNewsDate: this.canShowNewsDate})
          })
        },
      },
    }

</script>

<style scoped lang="scss">
.carousel-cell {
  position: relative;
  width: 850px;
  height: 400px;
  //background-color: #62caaa;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  font-size: 30px;
  border-radius: 10px;
}

</style>