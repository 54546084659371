/**
 * 配送業者 配送時間
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/delivery_companies`

const deliveryCompany = {
    namespaced: true,

    // アプリケーションの状態（情報）
    state: {
        pagination: {
            // 表示するページ
            current_page: 1,
            // ページ件数
            total_count: 0
        },
        query: {
            // 保持する検索条件
            name: '',
        },
    },

    // state 内の状態を元に算出した値を返す関数
    getters: {
        // 件数
        isDeliveryCompanies: ( state ) => {
            return (state.pagination.total_count > 0)
        },

    },

    actions: {
        // 運送業者新規
        new ({ _commit }) {
            const api_path = `${API_PATH}/new`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        // 運送業者取得
        edit ({ _commit }, {id}) {
            const api_path = `${API_PATH}/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        // 運送業者新規
        create ({ commit }, { params }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.post(API_PATH, params).then(data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
        // 運送業者更新
        update ({ commit }, { id, params }) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.put(api_path, params).then(data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },

        // 運送業者&紐づく時間削除
        destroy( { commit, state }, { id } ) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
        // 運送業者一覧
        fetchDeliveryCompanies({ dispatch, commit, state }, {name, page}) {
            // fetchQuery内でコミット
            dispatch('fetchQuery', {name: name})
            // パラメータセット
            const params = { name: state.query.name, page: page }
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    // stateを更新するためにmutationsをcommit
                    commit(types.BACK_DELIVERY_COMPANY, { content })
                    resolve(data.content)
                })
            })
        },

        // コミット
        fetchQuery({commit, state}, {name}) {
            const Name = (name !== undefined)? name : state.query.name
            const params = { name: Name }
            commit(types.BACK_DELIVERY_COMPANY_QUERY, { params })
        },
    },

    // stateのデータを更新
    mutations: {

        [types.BACK_DELIVERY_COMPANY] (state, { content }) {
            state.pagination = content.pagination
        },

        [types.BACK_DELIVERY_COMPANY_QUERY] (state, { params }) {
            // 検索条件更新
            state.query.name = params.name
        },
    },
}

export default deliveryCompany