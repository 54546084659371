<!-- バックヤード > マイページ編集 -->
<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="px-0 py-2"
    />
    <v-row>
      <v-col>
        <my-page-form />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import myPageForm from './_Form'

export default {
  components: {
    myPageForm,
  },
  mixins: [
  ],
  data () {
    return {
      myPage: {},
      breadcrumbs: [
        { text: 'フリーページ一覧', disabled: false, to: '/backyard/myPages', exact: true },
        { text: 'フリーページ詳細', disabled: true, to: '' },
      ],
    }
  },
  created() {},
  methods: {},
}
</script>

<style lang="scss">

</style>
