/**
 * テーマオプション/スライダー
 */
import Vue from 'vue'
import * as httpConst from '../../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/theme_option`

const theme_option_background_image_content = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        edit ({ _commit }, {option_type}) {
            const api_path = `${API_PATH}/background_image_contents/${option_type}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
    },
    mutations: {},
}

export default theme_option_background_image_content