<!-- バックヤード > 書類テンプレート一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="キーワード"
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-btn color="primary">
                検索
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    テンプレート名
                  </th>
                  <th class="text-center">
                    表示順
                  </th>
                  <th class="text-left">
                    更新日時
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="templateDocument in templateDocuments"
                  :key="templateDocument.id"
                >
                  <td>{{ templateDocument.name }}</td>
                  <td class="text-center">
                    {{ templateDocument.id }}
                  </td>
                  <td>{{ templateDocument.update }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="templateDocumentEdit"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-pagination
      v-model="page"
      circle
      :length="10"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      :total-visible="5"
    />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";

  export default {
    components: {
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        page: 1,
        templateDocuments:[
          {id: 1, name: '納品書', update: '2020-06-30 12:00'},
          {id: 2, name: 'チェックリスト', update: '2020-06-30 12:00'},
          {id: 3, name: '配送伝票', update: '2020-06-30 12:00'},
        ],
      }
    },
    methods: {
      templateDocumentEdit: function () {
        this.$router.push({name: 'byTemplateDocumentEditIndex', params: {id: 1}})
      },
    }
  }
</script>

<style lang="scss">

</style>
