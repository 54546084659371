/**
 * 申請情報
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/companies/payment_requests`

const payment_request = {
  namespaced: true,
  actions: {
    fetch ({ _commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    create ({ commit, state }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.post(API_PATH).then(data => {
          commit('snackbar/showInfo', { message: '送信しました' }, { root: true })
          const content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {},
}
export default payment_request