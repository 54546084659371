<!-- バックヤード >  キャンペン詳細 > 基本情報 -->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|max:30"
                      :name="`${companyModeCaptionName}名`"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="campaign.title"
                        :label="`${companyModeCaptionName}名`"
                        dense
                        hide-details="auto"
                        outlined
                        counter="30"
                        clearable
                        required
                        placeholder=" "
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      offset-y
                      transition="scale-transition"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="開始日"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="campaign.display_period_start_date"
                            label="開始日"
                            dense
                            readonly
                            hide-details="auto"
                            clearable
                            outlined
                            placeholder="選択してください"
                            required
                            :error-messages="errors"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template
                              v-if="$vuetify.breakpoint.mdAndUp"
                              v-slot:append-outer
                            >
                              &emsp;〜
                            </template>
                          </v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="campaign.display_period_start_date"
                        @input="startDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      offset-y
                      transition="scale-transition"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="終了日"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="campaign.display_period_end_date"
                            label="終了日"
                            dense
                            readonly
                            hide-details="auto"
                            clearable
                            outlined
                            placeholder="選択してください"
                            required
                            :error-messages="errors"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="campaign.display_period_end_date"
                        @input="endDateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="isEdit"
                  color="error"
                  @click="deleteButtonClickEvent"
                >
                  削除
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="cancelButtonClickEvent"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="submit"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <confirm ref="confirm" />
  </div>
</template>

<script>
import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import { mapActions } from 'vuex'
export default {
  components: {
    AlertMessage,
    Confirm,
  },
  mixins: [],
  props: {
    campaign: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    companyModeCaptionName:{
      type: String,
      default: '',
    }
  },
  data () {
    return {
      startDateMenu: false,
      endDateMenu: false,
    }
  },
  computed: {},
  created() {},
  methods: {
    ...mapActions('backCampaign', {
      createCampaign: 'create',
      updateCampaign: 'update',
      destroyCampaign: 'destroy'
    }),
    cancelButtonClickEvent: function() {
      this.$router.push({name: 'CampaignIndex'})
    },
    submit: function() {
      if (this.isEdit) {
        this.updateCampaign({ id: this.$route.params.id, params: this.campaign })
      } else {
        this.createCampaign({ params: this.campaign }).then(() => {
          this.$router.push({name: 'CampaignIndex'})
        })
      }
    },
    deleteButtonClickEvent () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyCampaign({ id: this.campaign.id }).then(() => {
            this.$router.push({name: 'CampaignIndex'})
          })
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">

</style>
