/**
 * 商品フリー項目
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const product_item = {
    namespaced: true,
    state: {
        product_items: []
    },
    actions: {
        fetchAll ({ commit, state },{ product_id }) {
            const api_path = `${API_PATH}/${product_id}/product_items`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    commit(types.BACK_PRODUCT_ITEM, {content})
                })
            })
        },
        create( { commit, state }, { product_id, params } ) {

            const api_path = `${API_PATH}/${product_id}/product_items`

            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(api_path, params).then( data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    let content = data.content
                    resolve(content)
                } )
            })
        },
        update( {commit, state}, { product_id, params } ) {
            const api_path = `${API_PATH}/${product_id}/product_items/${params.id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    let content = data.content
                    resolve(content)
                } )
            })
        },
        destroy( { commit, state }, { product_id, params } ) {

            const remove_id = params.id

            const api_path = `${API_PATH}/${product_id}/product_items/${remove_id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    let content = data.content
                    resolve(content)
                })
            })
        },
    },
    mutations: {
        [types.BACK_PRODUCT_ITEM] (state, { content }) {
            state.product_items = content.product_items
        }
    },
}

export default product_item