var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-input',{attrs:{"type":"hidden","dense":""},model:{value:(_vm.price.id),callback:function ($$v) {_vm.$set(_vm.price, "id", $$v)},expression:"price.id"}}),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"タイトル","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"20","dense":"","hide-details":"auto","label":"タイトル","outlined":"","placeholder":" "},model:{value:(_vm.price.name),callback:function ($$v) {_vm.$set(_vm.price, "name", $$v)},expression:"price.name"}})]}}])})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"ご利用日数","rules":"required|numeric|max_value:2147483647"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"ご利用日数","outlined":"","placeholder":" ","required":"","reverse":""},model:{value:(_vm.price.rental_period),callback:function ($$v) {_vm.$set(_vm.price, "rental_period", $$v)},expression:"price.rental_period"}})]}}])})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_vm._v("\n      ※現地在庫品の場合はご利用日数をそのままご入力ください。"),_c('br'),_vm._v("\n      また、それ以外は○泊○日の泊数をご入力ください。\n    ")])],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|numeric|max_value:2147483647","name":"金額(税抜)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"金額(税抜)","outlined":"","placeholder":" ","prefix":"円","required":"","reverse":""},model:{value:(_vm.price.price),callback:function ($$v) {_vm.$set(_vm.price, "price", $$v)},expression:"price.price"}})]}}])})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"ディスカウント価格(税抜)","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"ディスカウント価格(税抜)","outlined":"","placeholder":" ","prefix":"円","reverse":""},model:{value:(_vm.price.discount_price),callback:function ($$v) {_vm.$set(_vm.price, "discount_price", $$v)},expression:"price.discount_price"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }