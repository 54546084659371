<!-- バックヤード > 商品詳細 > 商品画像設定 -->
<template>
  <div>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-text>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="showNewModal"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="productImages"
                :headers="tableHeaders"
                :items="productImages"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.image_url="{ item }">
                  <v-img
                    :src="item.image_url"
                    max-height="40"
                    max-width="60"
                    contain
                  />
                </template>
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="cyan darken-2"
                    @click="editButtonClickEvent(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="info"
                    small
                    @click="editButtonClickEvent(item)"
                  >
                    編集する
                  </v-btn>

                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="error"
                    @click="deleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="deleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isShowProductImageDialog"
      width="450"
      @close="closeDialog"
    >
      <validation-observer
        v-slot="{ invalid }"
        ref="imageFormValidation"
      >
        <v-form>
          <v-card>
            <v-card-title>商品画像</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="画像ファイル"
                    rules="mimes:image/jpeg,image/png"
                  >
                    <v-row
                      class="text-center"
                      @dragover.prevent
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    >
                      <v-col>
                        <v-file-input
                          v-model="productImage.file"
                          :background-color="isDragging ? 'blue' : 'null'"
                          :error-messages="errors"
                          :show-size="1000"
                          accept="image/jpeg,image/png"
                          append-icon="mdi-folder"
                          counter
                          dense
                          label="画像ファイル"
                          hint="画像ファイルを選択して下さい"
                          outlined
                          persistent-hint
                          placeholder=" "
                          prepend-icon=""
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>
                            <span
                              v-else-if="index === 2"
                              class="overline grey--text text--darken-3 mx-2"
                            >+{{ files.length - 2 }} File(s)</span>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="productImage.image_url">
                <v-col>
                  <v-card
                    style="background-color: #fafafa;"
                    flat
                    tile
                  >
                    <v-card-subtitle>
                      プレビュー
                    </v-card-subtitle>
                    <v-card-text
                      class="justify-center"
                    >
                      <v-img
                        :src="productImage.image_url"
                        max-height="200"
                        contain
                        aspect-ratio="1"
                        class="gray lighten-4 align-center"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="alt属性"
                    rules="max:30"
                  >
                    <v-text-field
                      v-model="productImage.alt_text"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="alt属性"
                      outlined
                      placeholder=" "
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row v-if="productImage.id != null">
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="表示順"
                    rules="required|numeric|min_value:0|max_value:2147483647"
                  >
                    <v-text-field
                      v-model="productImage.position"
                      :background-color="isDragging ? 'blue' : 'null'"
                      :error-messages="errors"
                      :show-size="1000"
                      dense
                      hide-details="auto"
                      label="表示順"
                      outlined
                      placeholder=" "
                      required
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeDialog">
                キャンセル
              </v-btn>
              <v-btn
                :disabled="invalid"
                color="primary"
                @click="dialogRegisterButtonClickEvent"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
    <confirm ref="confirm" />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import Confirm from '../../../../components/Confirm'

  export default {
    components: {
      Confirm,
    },
    mixins: [
    ],
    props: {
      product: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {

      /**
       表示順
       画像
       画像ファイル名
       alt属性
       ボタン
       */

      return {
        tableHeaders: [
          { value: 'position', text: '表示順', align: 'start' },
          { value: 'image_url', text: '', align: 'center' },
          { value: 'name', text: '画像ファイル名' },
          { value: 'alt_text', text: 'alt属性' },
          { value: 'editable', text: '', align: 'end' },
        ],
        isShowProductImageDialog: false,
        productImage: {
          id: null,
          file: null,
          position: null,
          image_url: null,
          alt_text: '',
          remove_file: false,
        },
        isDragging: false,
        dragCount: 0,
      }
    },
    computed: {
      ...mapState('backProductImage', {
        productImages: 'product_images'
      })
    },
    watch: {
      'productImage.file': function(newValue) {
        if (newValue) {
          this.getBase64(newValue).then((image) => {
            this.productImage.image_url = image
            this.productImage.remove_file = false
          })
        }
      }
    },
    created() {
      this.indexProductImage( { product_id: this.product.id })
    },
    methods: {
      ...mapActions('backProductImage', {
        indexProductImage: 'fetchAll',
        createProductImage: 'create',
        updateProductImage: 'update',
        destroyProductImage: 'destroy'
      }),
      deleteButtonClickEvent (item) {
        this.$refs.confirm.showDeleteConfirm().then((confirm) => {
          this.$refs.confirm.close()
          if (confirm) {
            this.destroyProductImage({ product_id: this.product.id, params: item })
          }
        })

      },
      dialogRegisterButtonClickEvent () {
        if (this.productImage.id) {
          const params = new FormData()
          if (this.productImage.file)
          params.append('file', this.productImage.file)
          params.append('position', this.productImage.position)
          params.append('alt_text', this.productImage.alt_text)

          this.updateProductImage( { product_id: this.product.id, id: this.productImage.id, params: params } ).then(() => {

            this.indexProductImage( { product_id: this.product.id })
            this.closeDialog()
          })
        } else {

          const params = new FormData()
          params.append('file', this.productImage.file)
          params.append('alt_text', this.productImage.alt_text)
          this.createProductImage({ product_id: this.product.id, params: params}).then(() => {
            this.closeDialog()
          })
        }
      },
      showNewModal () {
        this.isShowProductImageDialog = true
      },
      editButtonClickEvent (item) {
        Object.assign(this.productImage, item);
        this.productImage.file = null
        this.isShowProductImageDialog = true
      },
      closeDialog () {
        Object.assign(this.$data.productImage, this.$options.data().productImage);
        this.$nextTick(() => this.$refs.imageFormValidation.reset())
        this.isShowProductImageDialog = false
      },
      onDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        this.isDragging = false;
        const _files = e.dataTransfer.files;
        this.productImage.file = _files[0];
      },

      onDragEnter(e) {
        e.preventDefault();
        this.isDragging = true;
        this.dragCount++;
      },

      onDragLeave(e) {
        e.preventDefault();
        this.dragCount--;
        if (this.dragCount <= 0) {
          this.isDragging = false;
        }
      },
      getBase64 (file) {
        /* Promiseにアロー関数を渡して非同期処理を書く。*/
        return new Promise((resolve, reject) => {

          /* 組み込みオブジェクトを使い、Base64エンコードされた画像データを取得  */
          const reader = new FileReader()
          reader.readAsDataURL(file)

          /* 成功時と失敗時の処理。resolveの引数に結果を渡して、のちの.then(result => ...)で受け取る  */
          reader.onload = () => resolve(reader.result)
          reader.onerror = error => reject(error)
        })
      },
    },
  }
</script>

<style lang="scss">

</style>
