/**
 * カート
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/frontend-types";
import store from "store";

const getTmpCustomerId = () => {
  return (store.get('tmpCustomerId'))
}

const isFrontLoggedIn = () => {
  return (store.get('frontLoggedIn'))
}

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/carts`

const cart = {
  namespaced: true,
  state: {
    cartCount: 0,
    content: {},
    tmp_customer_id: getTmpCustomerId(),
  },
  getters: {
    isCarts: (state) => {
      return (state.content.carts && state.content.carts.length > 0)
    },
  },
  actions: {
    resetCount({ commit }) {
      commit(types.FRONT_CART_COUNT, { count: 0 })
    },
    fetchCarts({ commit }, { params }) {
      params.tmp_customer_id = getTmpCustomerId()   // 一時顧客ID
      params.is_front_logged_in = isFrontLoggedIn() // ログインチェック
      return new Promise((resolve, reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.FRONT_CART, { content })
          resolve(content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    fetchCount({ commit }) {
      let params = {
        tmp_customer_id : getTmpCustomerId(),
        is_front_logged_in : isFrontLoggedIn()
      }
      const api_path = `${API_PATH}/count`
      return new Promise((resolve, reject) => {
        Vue.$http.get(api_path, { params: params }).then(data => {
          let content = data.content
          commit(types.FRONT_CART_COUNT, { count: content.cart_count })
          resolve(content.cart_count)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    create ({ commit }, { params }) {
      commit(types.FRONT_TMP_CUSTOMER_ID)
      params.tmp_customer_id = getTmpCustomerId()   // 一時顧客ID
      params.is_front_logged_in = isFrontLoggedIn() // ログインチェック
      return new Promise((resolve, reject) => {
        Vue.$http.post(API_PATH, params).then(data => {
          commit('snackbar/showInfo', { message: '追加されました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    update ({ commit }, { id, params }) {
      const api_path = `${API_PATH}/${id}`
      params.tmp_customer_id = getTmpCustomerId()   // 一時顧客ID
      params.is_front_logged_in = isFrontLoggedIn() // ログインチェック
      return new Promise((resolve, reject) => {
        Vue.$http.put(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    delete ({ commit }, { id }) {
      const api_path = `${API_PATH}/${id}`
      let params = {
        tmp_customer_id : getTmpCustomerId(),
        is_front_logged_in : isFrontLoggedIn()
      }
      // DELETEの場合、オプションのdataにパラメータをセットする.
      let option = {
        data : params
      }
      return new Promise((resolve, reject) => {
        Vue.$http.delete(api_path,option).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
  },
  mutations: {
    [types.FRONT_CART_COUNT] (state, { count }) {
      state.cartCount = count
    },
    [types.FRONT_CART] (state, { content }) {
      state.content = content
    },
    [types.FRONT_TMP_CUSTOMER_ID] (state) {
      // 未ログイン、かつ一時的な顧客IDがない場合は一時顧客IDを発行
      if(!isFrontLoggedIn() && !getTmpCustomerId()){
        let tmp_id = Date.now() + Math.floor(Math.random() * 1000 )
        store.set('tmpCustomerId', tmp_id)
      }
    },
  },
}

export default cart
