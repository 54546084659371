<!-- バックヤード > SHOPサイト画面設定 > リンク設定 -->
<template>
  <div>
    <v-radio-group
      v-model="link.transition_type"
      dense
      hide-details="auto"
      class="pa-0 ma-0"
    >
      <v-radio
        class="fitLabel"
        label="遷移なし"
        :value="isPrefix?'t_no_transition':'no_transition'"
      />
      <v-sheet
        v-show="link.transition_type === 't_no_transition' || link.transition_type === 'no_transition'"
      />
      <v-radio
        class="fitLabel"
        label="商品詳細"
        :value="isPrefix?'t_item_detail':'item_detail'"
      />
      <v-sheet
        v-show="link.transition_type === 't_item_detail' || link.transition_type === 'item_detail'"
        class="ml-8 mb-4"
      >
        <v-row>
          <v-col>
            <v-input
              v-model="link.product_id"
              :disabled="link.transition_type !== (isPrefix?'t_item_detail':'item_detail')"
              :hide-details="true"
              type="hidden"
              dense
            />
            <p v-if="link.product_name">
              商品: {{ link.product_name }}
            </p>
            <v-btn
              color="info"
              small
              @click="openItemSearchDialog"
            >
              商品選択
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
      <v-radio
        class="fitLabel"
        label="カテゴリー"
        :value="isPrefix?'t_category':'category'"
      />
      <v-sheet
        v-show="link.transition_type === 't_category' || link.transition_type === 'category'"
        class="ml-8 mb-4"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="link.category_id"
              :items="categories"
              item-value="id"
              item-text="name"
              dense
              label="カテゴリー"
              hide-details="auto"
              outlined
              placeholder=" "
              :disabled="link.transition_type !== (isPrefix?'t_category':'category')"
            />
          </v-col>
        </v-row>
      </v-sheet>
      <v-radio
        class="fitLabel"
        label="タグ"
        :value="isPrefix?'t_tag':'tag'"
      />
      <v-sheet
        v-show="link.transition_type === 't_tag' || link.transition_type === 'tag'"
        class="ml-8 mb-4"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="link.tag_id"
              :items="tags"
              item-value="id"
              item-text="name"
              dense
              label="タグ"
              hide-details="auto"
              outlined
              placeholder=" "
              :disabled="link.transition_type !== (isPrefix?'t_tag':'tag')"
            />
          </v-col>
        </v-row>
      </v-sheet>
      <v-radio
        class="fitLabel"
        label="フリーページ"
        :value="isPrefix?'t_my_page':'my_page'"
      />
      <v-sheet
        v-show="link.transition_type === 't_my_page' || link.transition_type === 'my_page'"
        class="ml-8 mb-4"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="link.my_page_id"
              :items="my_pages"
              item-value="id"
              item-text="title"
              dense
              label="フリーページ"
              hide-details="auto"
              outlined
              placeholder=" "
              :disabled="link.transition_type !== (isPrefix?'t_my_page':'my_page')"
            />
          </v-col>
        </v-row>
      </v-sheet>
      <v-radio
        class="fitLabel"
        label="外部"
        :value="isPrefix?'t_external':'external'"
      />
      <v-sheet
        v-show="link.transition_type === 't_external' || link.transition_type === 'external'"
        class="ml-8 mb-4"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="max:255"
              name="外部サイトURL"
            >
              <v-text-field
                v-model="link.transition_url"
                dense
                label="外部サイトURL"
                hide-details="auto"
                outlined
                clearable
                counter="255"
                placeholder=" "
                :error-messages="errors"
                :disabled="link.transition_type !== (isPrefix?'t_external':'external')"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-sheet>
    </v-radio-group>
    <item-search-dialog
      ref="ItemSearchDialog"
      @itemSearchDialogCallback="itemSearchDialogCallback"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ItemSearchDialog from '../../../dialogs/ItemSearchDialog'

export default {
  components: {
    ItemSearchDialog
  },
  props: {
    link: {
      type: Object,
      default: () => {
        return {
        }
      },
    },
    isTransition: {
      type: Boolean,
      default: false,
    },
    isPrefix: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      categories: null,
      tags: null,
      my_pages: null,
    }
  },
  created() {
    this.searchCategories().then((content) => {
      this.categories = content.categories
    })
    this.searchTags().then((content) => {
      this.tags = content.tags
    })
    this.searchMyPage().then((content) => {
      this.my_pages = content.my_pages
    })
  },
  methods: {
    ...mapActions('backSearchCategory', {
      searchCategories: 'fetch',
    }),
    ...mapActions('backSearchTag', {
      searchTags: 'fetch',
    }),
    ...mapActions('backProduct', {
      fetchProduct: 'fetch',
    }),
    ...mapActions('backSearchMyPage', {
      searchMyPage: 'fetch',
    }),
    openItemSearchDialog () {
      this.$refs.ItemSearchDialog.openDialog({exclude_product_id: null, singleSelect: true})
    },
    itemSearchDialogCallback (product_id) {
      const parameter = product_id
      this.link.product_id = parameter
      this.fetchProduct({id: parameter}).then((content) => {
        this.link.product_name = content.name
      })
    },
  },
}
</script>

<style lang="scss">
</style>
