<!-- バックヤード > 店舗システム設定 > 基本情報タブ -->
<template>
  <div v-if="configuration">
    <v-form>
      <validation-observer v-slot="{ invalid }">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-text>
                <h3>サイト</h3>

                <!-- メタタグ -->
                <v-row>
                  <v-col cols="12">
                    <h4>独自メタ情報</h4>
                    <v-card outlined>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="max:140"
                              name="説明"
                            >
                              <v-text-field
                                v-model="configuration.description_meta_tag_attributes.content"
                                outlined
                                counter="140"
                                label=""
                                dense
                                hide-details="auto"
                                placeholder=" "
                                :error-messages="errors"
                              >
                                <template v-slot:prepend>
                                  {{ configuration.description_meta_tag_attributes.meta_name }}
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row><!-- /メタタグ -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-text>
                <h3>金額設定</h3>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    税金端数処理
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <v-radio-group
                      v-model="configuration.tax_fraction"
                      row
                      dense
                      hide-details="auto"
                      class="pa-0 ma-0"
                    >
                      <template
                        v-for="item in configuration.tax_fractions"
                      >
                        <v-radio
                          :key="item.value"
                          :value="item.value"
                          :label="item.text"
                        />
                      </template>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    本人確認書類
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <v-radio-group
                      v-model="configuration.use_identification"
                      row
                      dense
                      hide-details="auto"
                      class="pa-0 ma-0"
                    >
                      <v-radio
                        label="必須"
                        :value="true"
                      />
                      <v-radio
                        label="不要"
                        :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    商品レビュー
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <v-radio-group
                      v-model="configuration.use_review"
                      row
                      dense
                      hide-details="auto"
                      class="pa-0 ma-0"
                    >
                      <v-radio
                        label="使用する"
                        :value="true"
                      />
                      <v-radio
                        label="使用しない"
                        :value="false"
                      />
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-text>
                <h3>法人番号</h3>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:13"
                      name="法人番号"
                    >
                      <v-text-field
                        v-model="configuration.corporate_number"
                        dense
                        hide-details="auto"
                        outlined
                        clearable
                        required
                        counter="13"
                        placeholder=" "
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-text>
                <h3>SNS</h3>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:20"
                      name="Twitterサイト"
                    >
                      <v-text-field
                        v-model="configuration.twitter_site"
                        dense
                        label="Twitterサイト"
                        prepend-icon="mdi-twitter"
                        hide-details="auto"
                        prefix="@"
                        outlined
                        clearable
                        required
                        counter="20"
                        placeholder=" "
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="numeric|max:15"
                      name="Facebook App ID"
                    >
                      <v-text-field
                        v-model="configuration.facebook_app_id"
                        dense
                        label="Facebook App ID"
                        prepend-icon="mdi-facebook"
                        hide-details="auto"
                        outlined
                        clearable
                        required
                        counter="15"
                        placeholder=" "
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:255"
                      name="Facebook URL"
                    >
                      <v-text-field
                        v-model="configuration.facebook_url"
                        dense
                        label="Facebook URL"
                        prepend-icon="mdi-facebook"
                        hide-details="auto"
                        outlined
                        clearable
                        required
                        counter="255"
                        placeholder=" "
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-text>
                <h3>その他設定</h3>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:20"
                      name="Google トラッキングID"
                    >
                      <v-text-field
                        v-model="configuration.tracking_id"
                        dense
                        label="Google トラッキングID"
                        prepend-icon="mdi-google-analytics"
                        hide-details="auto"
                        outlined
                        clearable
                        required
                        counter="20"
                        placeholder=" "
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-select
                      v-model="configuration.page_limit"
                      :items="configuration.page_limits"
                      dense
                      label="一覧表示件数"
                      hide-details="auto"
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="float-right">
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="registerButtonClickEvent"
              >
                保存
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </validation-observer>
    </v-form>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  components: {},
  mixins: [],
  props: {
    configuration: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
    }
  },
  created() {
  },
  methods: {
    ...mapActions('backConfiguration', {
      fetchConfiguration: 'fetch',
      updateConfiguration: 'update'
    }),
    registerButtonClickEvent () {
      let params = {
        tax_fraction: this.configuration.tax_fraction,
        use_identification: this.configuration.use_identification,
        identification_price: this.configuration.identification_price,
        use_review: this.configuration.use_review,
        page_limit: this.configuration.page_limit,
        tracking_id: this.configuration.tracking_id,
        description: this.configuration.description,
        twitter_site: this.configuration.twitter_site,
        facebook_app_id: this.configuration.facebook_app_id,
        facebook_url: this.configuration.facebook_url,
        mail_signature: this.configuration.mail_signature,
        description_meta_tag_attributes: this.configuration.description_meta_tag_attributes,
        corporate_number: this.configuration.corporate_number,
      }

      this.updateConfiguration({params})
    }
  },
}
</script>

<style lang="scss">

</style>
