/**
 * 商品キーワード
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const product_keyword = {
    namespaced: true,
    state: {
        product_keywords: []
    },
    actions: {
        fetchAll ({ commit, state },{ product_id }) {
            const api_path = `${API_PATH}/${product_id}/product_keywords`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    commit(types.BACK_PRODUCT_KEYWORD, { content })
                })
            })
        },
        create( { commit, state }, { product_id, params } ) {
            const api_path = `${API_PATH}/${product_id}/product_keywords`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(api_path, params ).then( data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    let content = data.content
                    commit(types.BACK_PRODUCT_KEYWORD_ADD, { content })
                    resolve(content)
                } )

            })
        },
        destroy( { commit, state }, { product_id, params } ) {

            const remove_id = params.id

            const api_path = `${API_PATH}/${product_id}/product_keywords/${remove_id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    commit(types.BACK_PRODUCT_KEYWORD_DELETE, { remove_id })
                })
            })
        },
    },
    mutations: {
        [types.BACK_PRODUCT_KEYWORD] (state, { content }) {
            state.product_keywords = content.product_keywords
        },
        [types.BACK_PRODUCT_KEYWORD_ADD] (state, { content }) {
            state.product_keywords.push(content)
        },
        [types.BACK_PRODUCT_KEYWORD_DELETE] (state, { remove_id }) {
            state.product_keywords = state.product_keywords.filter(o => o.id !== remove_id)
        }
    },
}
export default product_keyword