/**
 * 商品Q&A
 */
import Vue from "vue";
import * as types from "../../mutation-types/frontend-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/products`

const product_question = {
  namespaced: true,
  state: {
    pagination: {
      is_last_page: true,
    },
  },
  getters: {
  },
  actions: {
    findQuestions ({ commit}, {product_id, page}) {
      const params = {
        page: page
      }
      const api_path = `${API_PATH}/${product_id}/questions`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path, { params: params }).then(data => {
          const content = data.content
          commit(types.FRONT_PRODUCT_QUESTION, { content })
          resolve(content)
        })
      })
    },
    create({ commit }, {product_id, question_content}) {
      const api_path = `${API_PATH}/${product_id}/questions`

      const params = {
        question_content: question_content
      }

      return new Promise((resolve, reject) => {
        Vue.$http.post(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          const content = data.content
          resolve(content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
  },
  mutations: {
    [types.FRONT_PRODUCT_QUESTION] (state, { content }) {
      state.pagination = content.pagination
    },
  },
}

export default product_question
