<template>
  <v-dialog
    v-model="show"
    persistent
    :max-width="options.width"
  >
    <v-card>
      <v-toolbar
        :color="options.titleColor"
        dark
        dense
        flat
      >
        <v-toolbar-title class="white--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pa-4"
      >
        {{ message }}
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          v-if="options.isCancelButton"
          color="grey"
          text
          @click="onCancel"
        >
          {{ options.cancelTitle }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="onSuccess"
        >
          {{ options.successTitle }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  /**
   * example
   * <Confirm ref="confirm" />
   * methods: {
   *   showDialog () {
   *     // open
   *     this.$refs.confirm.open({title: 'title', message: 'message', options: null}).then((confirm) => {
   *       // confirm true: ok false: cancel
   *       this.$refs.confirm.close()
   *     })
   *     // delete dialog
   *     this.$refs.confirm.showDeleteConfirm().then((confirm) => {
   *       // confirm true: ok false: cancel
   *       this.$refs.confirm.close()
   *     })
   *   }
   * }
   */
  export default {
    name: 'Confirm',
    data () {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
          titleColor: 'primary',
          width: 290,
          successTitle: 'OK',
          cancelTitle: 'Cancel',
          isCancelButton: true,
        }
      }
    },
    computed: {
      show: {
        get() {
          return this.dialog
        },
        set(value) {
          this.dialog = value
          if (value === false) {
            this.cancel()
          }
        }
      },
    },
    methods: {
      open ({title, message, options}) {
        this.title = title
        this.message = message
        this.options = Object.assign(this.options, options)
        this.dialog = true

        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      close () {
        this.dialog = false
      },
      showDeleteConfirm () {
        return this.open({ title: '削除', message: '削除してよろしいですか？', options: { titleColor: 'red' } })
      },
      showDuplicateConfirm () {
        return this.open({ title: '複写', message: '複写してよろしいですか？', options: { titleColor: 'blue' } })
      },
      onSuccess () {
        this.resolve(true)
      },
      onCancel () {
        this.resolve(false)
      },
    }
  }

</script>

<style scoped lang="scss">
</style>