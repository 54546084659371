/**
 * Home会社情報
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/home/company`

const company = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        find ({ _commit }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH).then(data => {
                    const content = data.content
                    resolve(content)
                })
            })
        },
        update( { commit } ) {
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(API_PATH).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    let content = data.content
                    resolve(content)
                } )
            })
        }
    },
    mutations: {},
}
export default company