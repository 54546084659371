<!-- バックヤード > 商品管理 > 商品登録 > 基本情報タブ > 料金設定 -->
<template>
  <div>
    <v-input
      v-model="price.id"
      type="hidden"
      dense
    />

    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          class="requiredMark"
          name="タイトル"
          rules="required|max:20"
        >
          <v-text-field
            v-model="price.name"
            :error-messages="errors"
            counter="20"
            dense
            hide-details="auto"
            label="タイトル"
            outlined
            placeholder=" "
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="2"
      >
        <validation-provider
          v-slot="{ errors }"
          class="requiredMark"
          name="ご利用日数"
          rules="required|numeric|max_value:2147483647"
        >
          <v-text-field
            v-model="price.rental_period"
            :error-messages="errors"
            dense
            hide-details="auto"
            label="ご利用日数"
            outlined
            placeholder=" "
            required
            reverse
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        md="10"
      >
        ※現地在庫品の場合はご利用日数をそのままご入力ください。<br>
        また、それ以外は○泊○日の泊数をご入力ください。
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="2"
      >
        <validation-provider
          v-slot="{ errors }"
          rules="required|numeric|max_value:2147483647"
          name="金額(税抜)"
          class="requiredMark"
        >
          <v-text-field
            v-model="price.price"
            :error-messages="errors"
            dense
            hide-details="auto"
            label="金額(税抜)"
            outlined
            placeholder=" "
            prefix="円"
            required
            reverse
          />
        </validation-provider>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="2"
      >
        <validation-provider
          v-slot="{ errors }"
          name="ディスカウント価格(税抜)"
          rules="numeric"
        >
          <v-text-field
            v-model="price.discount_price"
            :error-messages="errors"
            dense
            hide-details="auto"
            label="ディスカウント価格(税抜)"
            outlined
            placeholder=" "
            prefix="円"
            reverse
          />
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  export default {
    components: {
    },
    props: {
      price: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
      }
    },
    methods: {
    },
  }

</script>