/**
 * 商品画像
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const product_image = {
    namespaced: true,
    state: {
        product_images: []
    },
    actions: {
        fetchAll ({ commit, state },{ product_id }) {
            const api_path = `${API_PATH}/${product_id}/product_images`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    commit(types.BACK_PRODUCT_IMAGE, {content})
                    resolve(data.content)
                })
            })
        },
        fetch ({ _commit }, { product_id, id}) {
            const api_path = `${API_PATH}/${product_id}/product_images/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        new ({ _commit }) {
            const api_path = `${API_PATH}/new`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        edit({ _commit, state }, { id }) {
            const api_path = `${API_PATH}/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        create( { commit, state }, { product_id, params } ) {

            const api_path = `${API_PATH}/${product_id}/product_images`

            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(api_path, params, { headers: { 'content-type': 'multipart/form-data' } }).then( data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    let content = data.content
                    commit(types.BACK_PRODUCT_IMAGE_ADD, { content })
                    resolve(content)
                } )
            })
        },
        update( {commit, state}, { product_id, id, params } ) {

            const api_path = `${API_PATH}/${product_id}/product_images/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params, { headers: { 'content-type': 'multipart/form-data'}}).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    let content = data.content
                    // commit(types.BACK_PRODUCT_IMAGE_UPDATE, { content })
                    resolve(content)
                } )
            })
        },
        destroy( { commit, state }, { product_id, params } ) {

            const remove_id = params.id

            const api_path = `${API_PATH}/${product_id}/product_images/${remove_id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    commit(types.BACK_PRODUCT_IMAGE_DELETE, { remove_id })
                    resolve(data.content)
                })
            })
        },
    },
    mutations: {
        [types.BACK_PRODUCT_IMAGE] (state, { content }) {
            state.product_images = content.product_images
        },
        [types.BACK_PRODUCT_IMAGE_ADD] (state, { content }) {
            state.product_images.push(content)
        },

        [types.BACK_PRODUCT_IMAGE_UPDATE] (state, { content }) {
            state.product_images = content.product_images
        },
        [types.BACK_PRODUCT_IMAGE_DELETE] (state,  { remove_id }) {
            state.product_images = state.product_images.filter( o => o.id !== remove_id )
        }

    },
}

export default product_image