import axios from 'axios'

// 末尾の/を削除
let host = process.env.APP_BASE_URL.replace(/\/$/, '')
// let host = window.location.hostname

// サブドメインを取得して動的にホストを組み立てる
// 最初の.の前の文字列をサブドメインとする
const subdomain = window.location.hostname.split('.')[0]
host = host.replace('[SUBDOMAIN]', subdomain)

host = window.location.origin

let client = axios.create({
  baseURL: host,
  // 500系以外は正常として扱う（thenで処理できるようにする）
  // validateStatus: function (status) {
  //   return status < 500
  // }
})

export default {
  install (Vue, { store }) {
    client.interceptors.request.use(function (config) {
      store.commit('alertMessage/reset')
      store.commit('loading/started')
      return config
    }, function (error) {
      store.commit('loading/finished')
      return Promise.reject(error)
    })

    client.interceptors.response.use(function (response) {
      store.commit('loading/finished')
      return response.data
    }, function (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log('API error', error)
        console.log('API error response', error.response)
        console.log('API error message', error.message)
        console.log('API error message', error.response.status)
      }

      const message = (error.response.data.result === undefined) ? 'エラーが発生しました' : error.response.data.result.message
      const error_code = (error.response.data.result === undefined) ? null : error.response.data.result.code
      if (error.response.config.url.indexOf('frontend') > 0) {
        if (error.response.status == 401 && error_code != 401001) {
          store.commit('frontAuth/forceSignOut')
          store.commit('loading/finished')
          return Promise.reject(error)
        }
      } else if (error.response.config.url.indexOf('backyard') > 0) {
        if (error.response.status == 401 && error_code != 401001) {
          store.commit('backyardAuth/forceSignOut')
          store.commit('snackbar/showError', { message: '再度ログインしてください',isShowLoginBtn:true })
          store.commit('loading/finished')
          return Promise.reject(error)
        }
      }

      store.commit('snackbar/showError', { message: message })
      store.commit('alertMessage/showError', { message: message })
      store.commit('loading/finished')

      return Promise.reject(error)
    })

    Vue.$http = Vue.prototype.$http = client
  }
}
