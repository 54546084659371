<!-- バックヤード > 受注編集 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-card
      v-if="product_review"
      flat
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-img
              class="img-fluid"
              height="150px"
              contain
              :src="product_review.image.url | load_image_url"
            />
          </v-col>
          <v-col
            cols="12"
            md="7"
          >
            <h3>
              {{ product_review.product_name }}<br>
              <small>品番1：{{ product_review.part_number }}</small>
            </h3>
            <p>
              公開名: {{ product_review.bundle_name }}<br>
              会員名: {{ product_review.customer_name }}
            </p>
            <p>表示設定: {{ product_review.published ? '表示' : '非表示' }}</p>
            <v-rating
              v-model="product_review.evaluation"
              readonly
              dense
            />
            <h4>タイトル: {{ product_review.title }}</h4>
            <p>投稿日: {{ product_review.created_at }}</p>
            <p class="body-2 mb-1 line-break">{{ product_review.comment }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :color="product_review.published ? 'error' : 'info'"
          @click="removeProductReviewClickEvent"
        >
          <template v-if="product_review.published">
            非表示にする
          </template>
          <template v-else>
            表示する
          </template>
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm ref="confirm" />
  </div>
</template>

<script>
import Confirm from './../../../components/Confirm'
import { mapActions } from 'vuex'
export default {
  components: {
    Confirm
  },
  mixins: [
  ],
  data () {
    return {
      product_review: null,
      breadcrumbs: [
        { text: '商品レビュー一覧', disabled: false, to: '/backyard/product_reviews', exact: true },
        { text: '商品レビュー詳細', disabled: true, to: '' },
      ]
    }
  },
  created() {
    this.fetchProductReview({ id: this.$route.params.id }).then((content) => {
      this.product_review = content
    })
  },
  methods: {
    ...mapActions('backProductReview', {
      fetchProductReview: 'fetch',
      toggleProductReview: 'toggle'
    }),
    removeProductReviewClickEvent () {
      let message = null
      let color = 'info'
      if (this.product_review.published) {
        message = '非表示にしてよろしいですか？'
        color = 'red'
      } else {
        message = '表示にしてよろしいですか？'
      }
      this.$refs.confirm.open({title: '確認', message: message, options: { titleColor: color }}).then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.toggleProductReview({id: this.product_review.id}).then(() => {
            this.$router.push({name: 'byProductReviewIndex'})
          })
        }
      })
    }
  },
}
</script>

<style lang="scss">

</style>
