<template>
  <v-container
    class="grey lighten-5"
  >
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon
            class="mr-2"
          >
            mdi-bell
          </v-icon>
          システムお知らせ
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="keyword"
                label="キーワード"
                placeholder="タイトル、本文部分一致"
                clearable
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-checkbox
                v-model="enableDisplayDate"
                label="表示日で絞り込む"
                hide-details="auto"
                class="pa-0 ma-0"
              />
            </v-col>

            <v-col cols="2">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="290px"
                offset-y
                :disabled="enableDisplayDate === false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="displayDate"
                    dense
                    label="表示日"
                    readonly
                    hide-details="auto"
                    clearable
                    placeholder=" "
                    :disabled="enableDisplayDate === false"
                    v-on="on"
                    @click:clear="displayDate = null"
                  />
                </template>

                <v-date-picker
                  ref="picker"
                  v-model="displayDate"
                  @input="dateMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="4">
              <div>時点表示されているもの</div>
            </v-col>
          </v-row>

          <div class="text-right">
            <v-btn
              color="primary"
              @click="resetResult"
            >
              検索
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <br>

    <div class="text-right">
      <v-btn
        color="primary"
        @click="addButtonClickEvent"
      >
        新規作成
      </v-btn>
    </div>

    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <thead>
            <tr>
              <th>タイトル</th>
              <th>お知らせ日</th>
              <th>表示期間</th>
              <th>全社配信</th>
              <th />
            </tr>
          </thead>


          <tbody>
            <tr
              v-for="announcement in announcements"
              :key="announcement.id"
            >
              <td>{{ announcement.title }}</td>
              <td>{{ announcement.notice_date | date_format }}</td>
              <td>{{ announcement.display_start_date | from_to_date_format(announcement.display_end_date) }}</td>
              <td>{{ announcement.announce_all }}</td>
              <td class="text-right">
                <v-btn
                  icon
                  color="cyan darken-2"
                  @click="editButtonClickEvent(announcement)"
                >
                  <v-icon>mdi-file-edit-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-row>
          <v-col
            cols="12"
          >
            <v-pagination
              v-if="hasAnnouncements"
              v-model="page"
              :length="pagination.total_pages"
              :total-visible="totalVisible"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              circle
              @input="changePage"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: 'AnnouncementIndex',
  data () {
    return {

      keyword: null,
      enableDisplayDate: false,
      displayDate: new Date().toISOString().substr(0, 10),
      dateMenu: false,
      page: 1,
      totalVisible: 7,
      announcements: []

    }
  },

  computed: {
    ...mapState('adminAnnouncement', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
    ...mapGetters('adminAnnouncement', [
      'hasAnnouncements'
    ]),
  },

  created() {
    // 検索条件
    this.keyword = this.searchQuery.keyword
    this.enableDisplayDate = this.searchQuery.enable_display_date
    this.displayDate = this.searchQuery.display_date
    this.page = this.pagination.current_page
    this.searchAnnouncements()
  },

  methods: {

    ...mapActions('adminAnnouncement', {
      // 一覧取得
      fetchAnnouncements: 'fetchAll',
      fetchQuery: 'fetchQuery',
    }),

    // 検索
    resetResult () {
      this.page = 1
      this.searchAnnouncements()
    },

    // ページャー
    changePage () {
      this.searchAnnouncements()
    },

    // 一覧取得
    searchAnnouncements () {

      const params = {
        keyword: this.keyword,
        enable_display_date: this.enableDisplayDate,
        display_date: this.displayDate,
        page: this.page
      }

      this.fetchAnnouncements(params).then((content) => {
        this.announcements = content.announcements
      })
    },

    // 新規画面遷移
    addButtonClickEvent () {
      this.$router.push({name: 'adAnnouncementNew'})
    },

    // 詳細画面遷移
    editButtonClickEvent (announcement) {
      this.$router.push({name: 'adAnnouncementShow', params: { id: announcement.id, fromIndex: true }})
    }

  }

}
</script>

<style scoped>

</style>