<!-- バックヤード > メールテンプレートform -->
<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              hide-details="auto"
              outlined
              clearable
              placeholder=" "
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <span class="required">テンプレート名</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              value="商品発送のお知らせ"
              hide-details="auto"
              outlined
              clearable
              placeholder=" "
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <span class="required">件名</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              outlined
              clearable
              counter
              value="○○様
この度はご注文ありがとうございました。
商品を発送致しましたのでご連絡致します。

配送業者：ヤマト運輸
時間指定：14〜16時
伝票番号：123-456-7890
配送の詳細は配送業者のHPより追跡を行ってください。
"
              auto-grow
              row-height="15"
              dense
              hide-details="auto"
              placeholder=" "
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <span class="required">本文</span>
              </template>
            </v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <v-text-field
              dense
              label="表示順"
              reverse
              hide-details="auto"
              outlined
              clearable
              placeholder=" "
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";

  export default {
    components: {
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
      }
    },
    methods: {
    },
  }
</script>

<style lang="scss">
</style>
