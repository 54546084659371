<template>
  <div>
    <h3 class="d-flex justify-space-between align-center">
      <v-btn
        icon
        color="grey"
        @click="toBack"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span v-if="contentCart.is_local_stock_item">
        お支払い方法
        <span class="lang_lbl mt-1" style="display: block;">{{$t('view.お支払い方法')}}</span>
      </span>
      <span v-else>
        お支払いと配送方法
        <span class="lang_lbl mt-1" style="display: block;">{{$t('view.お支払いと配送方法')}}</span>
      </span>
      <span style="min-width: 36px" />
    </h3>
    <v-form>
      <validation-observer v-slot="{ invalid }">
        <v-row>
          <v-col cols="12">
            <v-card class="ma-1" :flat="true">
              <v-card-title>
                <v-icon class="mr-2">
                  mdi-credit-card-outline
                </v-icon>お支払い方法
                  <div class="lang_lbl ms-1">{{$t('view.お支払い方法')}}</div>
              </v-card-title>
              <v-card-text>
                <v-radio-group v-model="delivery.payment_type">
                  <v-expansion-panels v-model="selectedPaymentType" accordion mandatory class="payment">
                    <v-expansion-panel
                      v-if="companyContent.use_credit_card"
                    >
                      <v-expansion-panel-header>
                        <v-radio :value="0" label="カード決済" />
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <validation-provider>

                        </validation-provider>
                        <v-row>
                          <v-col
                              cols="12"
                              align="end"
                          >
                            <payjp-checkout
                                :api-key="public_key"
                                :text="'カードを追加 (' + $t('view.カードを追加') + ')' "
                                submit-text="カードを追加"
                                name-placeholder="TARO RENTAL"
                                @created="onTokenCreated"
                                v-on:created="onTokenCreated"
                                @failed="onTokenFailed"
                                v-on:failed="onTokenFailed"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                                :headers="cardHeaders"
                                :items="cards"
                                :loading-text="'読込中(' + $t('view.読込中') + ')' "
                                :no-data-text="'カード情報はありません。(' + $t('view.カード情報はありません') + ')' "
                                hide-default-footer
                            >
                              <template v-slot:item.default_card="{ item }">
                                <validation-provider :rules="delivery.payment_type === 0 ? 'required' : ''">
                                  <v-radio-group
                                      v-model="selectedCardIndex"
                                      @change="changeCard(item)"
                                  >
                                    <v-radio label="" :value="item.index"></v-radio>
                                  </v-radio-group>
                                </validation-provider>
                              </template>
                              <template v-slot:item.last4="{ item }">
                                {{ item.last4 | card_number_padding }}
                              </template>
                              <template v-slot:item.expiration_date="{ item }">
                                {{ item.exp_month | card_expiration_date(item.exp_year) }}
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                      v-if="companyContent.use_bank_transfer && !hasSubscription"
                      :readonly="true"
                    >
                      <v-expansion-panel-header :hide-actions="true">
                        <v-radio :value="1" label="銀行振り込み" />
                      </v-expansion-panel-header>
                    </v-expansion-panel>
                    <v-expansion-panel
                      v-if="companyContent.use_cash_on_delivery && !hasSubscription"
                    >
                      <v-expansion-panel-header :hide-actions="true">
                        <v-radio :value="2" label="代引き" />
                      </v-expansion-panel-header>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-radio-group>
                <v-row v-if="showPaymentNote" :dense="true">
                  <v-col>
                    <payment-note-dialog :title="paymentContent.payment_note_title" :post-name="paymentContent.payment_note_my_page" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-divider class="mb-2" />
            <v-card>
              <span v-if="!contentCart.is_local_stock_item">
                <v-card-title>
                  <v-icon class="mr-2">
                    mdi-truck-delivery-outline
                  </v-icon>お届け先
                  <div class="lang_lbl ms-1">{{$t('view.お届け先')}}</div>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mb-2" />
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:30"
                          :name="'氏名（' + $t('view.氏名') + '）'"
                          class="requiredMark"
                      >
                        <v-text-field
                          v-model="delivery.name"
                          :error-messages="errors"
                          required
                          :label="'氏名（' + $t('view.氏名') + '）'"
                          placeholder=" "
                          counter="30"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                      cols="6"
                      sm="3"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          rules="numeric|max:7"
                          :name="'郵便番号（' + $t('view.郵便番号') + '）'"
                          class=""
                      >
                        <v-text-field
                          v-model="delivery.zip_code"
                          :error-messages="errors"
                          required
                          :label="'郵便番号（' + $t('view.郵便番号') + '）'"
                          placeholder=" "
                          counter="7"
                          hint="ハイフン(-)無しで入力してください"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="3">
                      <zip-code-search :zip-code="delivery.zip_code" @zipAddress="setZipAddress" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          :name="'都道府県（' + $t('view.都道府県') + '）'"
                          class="requiredMark"
                      >
                        <v-select
                          v-model="delivery.prefecture_code"
                          :error-messages="errors"
                          required
                          item-text="text"
                          item-value="value"
                          :items="prefList"
                          :label="'都道府県（' + $t('view.都道府県') + '）'"
                          placeholder="選択してください"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12">
                      <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:100"
                          :name="'市区町村・番地（' + $t('view.市区町村_番地') + '）'"
                          class="requiredMark"
                      >
                        <v-text-field
                          v-model="delivery.address"
                          :error-messages="errors"
                          required
                          :label="'市区町村・番地（' + $t('view.市区町村_番地') + '）'"
                          placeholder=" "
                          counter="100"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                          v-slot="{ errors }"
                          rules="required|numeric|max:11"
                          :name="'電話番号(自宅 または 携帯電話)（' + $t('view.電話番号') + '）'"
                          class="requiredMark"
                      >
                        <v-text-field
                          v-model="delivery.phone"
                          :error-messages="errors"
                          required
                          :label="'電話番号(自宅 または 携帯電話)（' + $t('view.電話番号') + '）'"
                          placeholder=" "
                          counter="11"
                          hint="ハイフン(-)無しで入力してください"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row v-if="delivery_desired_times.length > 0">
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="delivery.desired_time_id"
                        :items="delivery_desired_times"
                        item-value="id"
                        item-text="desired_time_value"
                        :label="'配送希望時間（' + $t('view.配送希望時間') + '）'"
                        hide-details="auto"
                        placeholder=" "
                        v-on:change="selectedDesiredTime"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <validation-provider v-slot="{ errors }" rules="max:300" name="備考">
                        <v-textarea
                          v-model="delivery.remarks"
                          :error-messages="errors"
                          :label="'備考（' + $t('view.備考') + '）'"
                          placeholder="セット商品のカラー・サイズ等、コメントを入力してください"
                          value=""
                          counter="300"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
              </span>
              <v-row dense>
                <v-col
                  v-if="showNextButton"
                  cols="12"
                  align="center"
                >
                  <v-btn
                    color="primary"
                    :block="($vuetify.breakpoint.mdAndDown) ? true : false"
                    @click="toNext"
                    :disabled="invalid"
                  >
                    <v-icon class="mr-2">
                      mdi-note-text-outline
                    </v-icon>
                    <template v-if="credenceContent.use_agree_terms">
                      利用規約同意へ
                      <div class="lang_lbl">{{$t('view.利用規約同意へ')}}</div>
                    </template>
                    <template v-else>
                      注文確認へ
                      <div class="lang_lbl">{{$t('view.注文確認へ')}}</div>
                    </template>
                  </v-btn>
                </v-col>
                <v-col
                  v-else
                  cols="12"
                  align="center"
                >
                  <p class="text-md-h6">
                    身分証明書が登録されていません。<br />「会員情報」の<router-link to @click.native="moveToPage">「身分証明書」</router-link>から登録してください。
                  </p>
                  <div class="lang_lbl">
                    {{$t('view.身分証明書エラー_1')}}<br>
                    {{$t('view.身分証明書エラー_2')}}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </validation-observer>
    </v-form>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import prefectureList from './../../../../mixins/PrefectureList'
import zipCodeSearch from '../../../components/field/ZipCodeSearch'
import ZipCodeSearch from "../../../components/field/ZipCodeSearch";
import PaymentNoteDialog from "../../../../components/_PaymentNoteDialog.vue";

  export default {
    name: 'OrderPayment',
    components: {ZipCodeSearch, PaymentNoteDialog},
    mixins: [
      prefectureList, zipCodeSearch,
    ],
    data () {
      return {
        public_key: process.env.PAY_JP_PUBLIC_KEY,
        selectedPaymentType: 0,
        cardHeaders: [
          { text: "お支払いカード（" + this.$i18n.t('view.お支払いカード') +"）" , align: 'start', sortable: false, value: 'default_card' },
          { text: "ブランド（" + this.$i18n.t('view.ブランド') +"）", align: 'start', sortable: false, value: 'brand' },
          { text: "カード番号の下四桁（" + this.$i18n.t('view.カード番号の下４桁') +"）", align: 'center', sortable: false, value: 'last4' },
          { text: "有効期限（" + this.$i18n.t('view.有効期限') +"）", align: 'center', sortable: false, value: 'expiration_date' },
          { text: "保有者名（" + this.$i18n.t('view.保有者名') +"）", align: 'start', sortable: false, value: 'name' },
        ],
        delivery: {
          payment_type: null,
          name: null,
          zip_code: null,
          prefecture_code: null,
          address: null,
          phone: null,
          remarks: null,
          desired_time_id: null,
          desired_time_value: null,
        },
        cards: [],
        paymentType: null,
        selectedCardIndex: null,
        selectedCard: null,
        showNextButton: false,
        delivery_desired_times: [],
      }
    },
    computed: {
      ...mapState('frontAuth', {
        loggedIn: 'logged_in'
      }),
      ...mapState('frontCredence', {
        credenceContent: 'content',
      }),
      ...mapState('frontCartInside', {
        contentCart: 'content',
      }),
      ...mapState("frontCompany", {
        companyContent: "content"
      }),
      ...mapState("frontThemeOption", {
        paymentContent: "payment_note"
      }),
      ...mapState('frontCart', {
        cartItem: 'content',
      }),
      ...mapGetters("frontThemeOption", [
          "showPaymentNote"
      ]),
      hasSubscription() {
        if (this.cartItem.carts) {
          return this.cartItem.carts.some(item => item.payment_type === "subscription_payment")
        } else {
          return false;
        }
      },
    },
    watch: {
      "delivery.payment_type": function(a) {
        if (a) {
          this.switchPaymentTypes();
        }
      },
      hasSubscription: function(a) {
        this.switchPaymentTypes();
      },
      companyContent: {
        handler: function(a, b) {
          if (a) {
            if (a.use_credit_card) {
              this.delivery.payment_type = 0;
            } else if (a.use_bank_transfer) {
              this.delivery.payment_type = 1;
            } else if (a.use_cash_on_delivery) {
              this.delivery.payment_type = 2;
            }

          }
        },
        deep: true
      }
    },
    created() {
      if (this.loggedIn) {
        this.editCustomer().then((content) => {
          this.delivery.name = content.name
          this.delivery.zip_code = content.zip_code
          this.delivery.prefecture_code = content.prefecture_code
          this.delivery.address = content.address
          this.delivery.phone = content.phone
          this.showNextButton = content.can_order
        })
        this.findCards().then( (content) => {
          // 選択用にindexを付与
          let indexCards = content.cards.map((item, index) => ({
            index: index,
            ...item
          }))
          // default_card を選択状態
          if (indexCards.length > 0) {
            const default_card = indexCards.find(card => card.default_card == true)
            this.selectedCard = default_card
            this.selectedCardIndex = default_card.index
            this.cards = indexCards
          }
        })
      }
      this.fetchDeliveryDesiredTimes().then( (content) => {
        this.delivery.desired_time_id = content.default_delivery_desired_time_id
        this.delivery_desired_times = content.delivery_desired_times
        this.selectedDesiredTime()
      })
      this.fetchCartInside().then ((content) => {
        if (content.is_local_stock_item) {
          this.delivery.name = ''
          this.delivery.zip_code = ''
          this.delivery.prefecture_code = ''
          this.delivery.address = ''
          this.delivery.phone = ''
        }
      })
    },
    beforeDestroy () {
      window.PayjpCheckout = null
    },
    methods: {
      ...mapActions('frontCustomer', {
        editCustomer: 'edit'
      }),
      ...mapActions('frontPayment', {
        fetchPaymentCustomer: 'fetch'
      }),
      ...mapActions('frontDeliveryDesiredTime', {
        fetchDeliveryDesiredTimes: 'fetchAll'
      }),
      ...mapActions('frontCard', {
        createCard: 'create',
        findCards: 'findCards'
      }),
      ...mapActions('frontCartInside', {
        fetchCartInside: 'fetch'
      }),
      switchPaymentTypes () {
        const payment_type = this.delivery.payment_type;
        const has_subscription = this.hasSubscription;
        if (payment_type === 0) {
          this.selectedPaymentType = 0
        } else if (payment_type === 1 && !has_subscription) {
          this.selectedPaymentType = 1
        } else if (payment_type === 2 && !has_subscription) {
          this.selectedPaymentType = 2
        }
      },

      selectedDesiredTime () {
        if (this.delivery.desired_time_id !== null) {
          const default_time = this.delivery_desired_times.find(desired_time => desired_time.id == this.delivery.desired_time_id)
          this.delivery.desired_time_value = default_time.desired_time_value
        }
      },
      toNext () {
        this.fetchPaymentCustomer({delivery: this.delivery, card_token: this.selectedCard?.card_token})
        this.$emit('toNextStep')
      },
      toBack () {
        this.$emit('toBackStep')
      },
      onTokenCreated (response) {
        // カードのToken化に成功
        const params = {
          card_token: response.id,
        }
        if (this.loggedIn) {
          this.createCard({params}).then(() => {
            this.loadCards()
          })
        }
      },
      onTokenFailed (status, err) {
        // Token化に失敗
        console.log(status)
        console.log(err)
      },
      changeCard (item) {
        this.selectedCard = item
      },
      setZipAddress (content) {
        this.delivery.prefecture_code = `${content.pref_code}`
        this.delivery.address = `${content.locality}${content.street}`
      },
      moveToPage () {
        this.$router.push({ name: 'MembershipIdentificationEdit' }, () => {})

      }
    },
  }
</script>

<style scoped lang="scss">
.payment {
  /deep/ {
    .v-expansion-panel-header {
      padding: 0 !important;
      padding-left: 16px !important;
    }
    & label {
      flex: none !important;
      padding: 16px 10px;
      width: 100%;
    }
  }
}
</style>
