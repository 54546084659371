<!-- バックヤード > レンタルスケジュール一覧 -->
<template>
  <div>

    <v-row>
      <v-col>
        <h2>
          スケジュール
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              @click="scheduleCreate"
            >
              スケジュール追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-model="selectedLocalPlace"
                      :items="localPlaceContent.local_places"
                      dense
                      clearable
                      hide-details="auto"
                      item-text="name"
                      item-value="id"
                      label="現地名"
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="inputtedPartNo"
                      dense
                      hide-details="auto"
                      label="品番１"
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-menu
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="startDate"
                          clearable
                          dense
                          hide-details="auto"
                          label="対象期間開始日"
                          outlined
                          placeholder=" "
                          readonly
                          v-on="on"
                          @click:clear="startDate = null"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn color="primary" @click="resetRentalSchedules"> 検索 </v-btn>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    現地名
                  </th>
                  <th class="text-left">
                    ご利用開始日
                  </th>
                  <th class="text-left">
                    ご利用終了日
                  </th>
                  <th class="text-left">
                    注文番号
                  </th>
                  <th class="text-left">
                    氏名
                  </th>
                  <th class="text-left">
                    電話番号
                  </th>
                  <th class="text-left">
                    品番1
                  </th>
                  <th class="text-left">
                    商品名
                  </th>
                  <th class="text-left">

                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="rental_schedule in rental_schedules"
                  :key="rental_schedule.id"
                >
                  <td>{{ rental_schedule.local_place_name }}</td>
                  <td>{{ rental_schedule.from_date | date_format }}</td>
                  <td>{{ rental_schedule.to_date | date_format }}</td>
                  <td>{{ rental_schedule.order_no }}</td>
                  <td>{{ rental_schedule.name }}</td>
                  <td>{{ rental_schedule.phone }}</td>
                  <td>
                    <a
                      class="text-decoration-underline red--text"
                      @click="showPartNumberButtonClickEvent(rental_schedule.part_number)"
                    >
                      {{ rental_schedule.part_number }}
                    </a>
                  </td>
                  <td>{{ rental_schedule.part_name }}</td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          icon
                          color="cyan darken-2"
                          @click="scheduleEdit(rental_schedule.id)"
                          v-on="on"
                        >
                          <v-icon>mdi-file-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>編集</span>
                    </v-tooltip>

                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-3">
      <v-col>
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>
    <rental-schedule-dialog
      ref="RentalScheduleDialog"
      @rentalScheduleDialogCallback="rentalScheduleDialogCallback"
    />
    <confirm ref="confirm" />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import { mapState, mapActions } from 'vuex'
  import RentalScheduleDialog from "../../dialogs/RentalScheduleRegistDialog";
  import Confirm from "../../../components/Confirm";

  export default {
    name: 'rentalScheduleIndex',
    components: {
      RentalScheduleDialog,
      Confirm,
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        page: 1,
        totalVisible: 7,
        selectedLocalPlace: '',
        inputtedPartNo: '',
        startDate: '',
        rental_schedules:[],
      }
    },
    computed: {
      ...mapState('backRentalSchedule', {
        pagination: 'pagination',
        searchQuery: 'query',
      }),
      ...mapState('backSearchLocalPlace', {
        localPlaceContent: 'content'
      }),
    },
    created() {
      this.startDate = new Date().toISOString().substr(0, 10)
      this.page = this.pagination.current_page
      this.fetchSearchLocalPlace()
      this.searchRentalSchedules()
    },
    methods: {
      ...mapActions('backRentalSchedule', {
        fetchRentalSchedules: 'fetchRentalSchedules',
        fetchQuery: 'fetchQuery',
        editRentalSchedule: 'edit',
      }),
      ...mapActions('backSearchLocalPlace', {
        fetchSearchLocalPlace: 'fetch'
      }),
      resetRentalSchedules () {
        this.filterDialog = false
        this.page = 1
        this.searchRentalSchedules()
      },
      changePage () {
        this.searchRentalSchedules()
      },
      rentalScheduleDialogCallback () {
        this.searchRentalSchedules();
      },
      /**
       * 品番 クリックイベント
       * @param part_number
       */
      showPartNumberButtonClickEvent (part_number) {
        this.$router.push({name: 'byProductStockIndex', query: { part_no: part_number }, params: { isClearCondition: 'true' }})
      },
      searchRentalSchedules () {
        const params = { local_place_id: this.selectedLocalPlace,
                        part_number: this.inputtedPartNo,
                        start_date: this.startDate,
                        page: this.page }
        this.fetchRentalSchedules(params).then((content) => {
          this.rental_schedules = content.rental_schedules
        })
      },
      scheduleCreate() {
        this.$refs.RentalScheduleDialog.openDialog({})
      },
      scheduleEdit(id) {
        this.editRentalSchedule({id}).then((content)=> {
          this.$refs.RentalScheduleDialog.openDialog({rental_schedule: content})
        })
      }
    }
  }
</script>

<style lang="scss">

</style>
