<!-- バックヤード > 商品詳細 > 基本情報 -->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form v-if="product">

            <!-- 商品説明 -->
            <v-row>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    rules="max:65535"
                    name="商品説明"
                >
                  <div>
                    <vue-editor
                        v-model="product.description"
                        :error-messages="errors"
                        class="custom-quill-container"
                    />
                  </div>
                </validation-provider>
              </v-col>
            </v-row><!-- 商品説明 -->

            <v-card flat>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  @click="cancelButtonClick"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="registerButtonClick"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <confirm ref="confirm" />
  </div>
</template>

<script>

import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import {mapActions} from 'vuex'
export default {
  components: {
    AlertMessage,
    Confirm
  },
  mixins: [
  ],
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    companyMode: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      product_description: null,
    }
  },
  methods: {
    ...mapActions('backProductDescription', {
      updateDescription: 'update',
    }),
    cancelButtonClick () {
      this.$router.push({name: 'byProductIndex'})
    },
    registerButtonClick () {
        this.updateDescription({ id: this.product.id, params: this.product }).then(() => {
        this.$emit('updateCallback')
      })
    },
  },
}

</script>

<style scoped lang="scss">
.custom-quill-container {
  /deep/ {
    /* 動画と画像の添付はなし */
    .ql-toolbar {
      .ql-formats{
        .ql-video{
          display: none;
        }
        .ql-image{
          display: none;
        }
      }
    }
  }
}

</style>
