<template>
  <div>
    <h3 class="d-flex justify-space-between align-center">
      <v-btn
        icon
        color="grey"
        @click="toBack"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>
        お支払い方法
        <div class="lang_lbl mt-1">{{$t('view.お支払い方法')}}</div>
      </span>
      <span style="min-width: 36px" />
    </h3>
    <v-form>
      <validation-observer v-slot="{ invalid }">
        <v-row>
          <v-col cols="12">
            <v-card class="ma-1" :flat="true">
              <v-card-title>
                <v-icon class="mr-2">
                  mdi-credit-card-outline
                </v-icon>お支払い方法
                <div class="lang_lbl ms-1">{{$t('view.お支払い方法')}}</div>
              </v-card-title>
              <v-card-text>
                <v-radio-group v-model="delivery.payment_type">
                  <v-expansion-panels v-model="selectedPaymentType" accordion mandatory class="payment">
                    <v-expansion-panel
                        v-if="companyContent.use_credit_card"
                    >
                      <v-expansion-panel-header>
                        <v-radio :value="0" label="カード決済" />
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <validation-provider>

                        </validation-provider>
                        <v-row>
                          <v-col
                              cols="12"
                              align="end"
                          >
                            <payjp-checkout
                                :api-key="public_key"
                                :text="'カードを追加 (' + $t('view.カードを追加') + ')' "
                                submit-text="カードを追加"
                                name-placeholder="TARO RENTAL"
                                @created="onTokenCreated"
                                v-on:created="onTokenCreated"
                                @failed="onTokenFailed"
                                v-on:failed="onTokenFailed"
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-data-table
                                :headers="cardHeaders"
                                :items="cards"
                                :loading-text="'読込中(' + $t('view.読込中') + ')' "
                                :no-data-text="'カード情報はありません。(' + $t('view.カード情報はありません') + ')' "
                                hide-default-footer
                            >
                              <template v-slot:item.default_card="{ item }">
                                <validation-provider :rules="delivery.payment_type === 0 ? 'required' : ''">
                                  <v-radio-group
                                      v-model="selectedCardIndex"
                                      @change="changeCard(item)"
                                  >
                                    <v-radio label="" :value="item.index"></v-radio>
                                  </v-radio-group>
                                </validation-provider>
                              </template>
                              <template v-slot:item.last4="{ item }">
                                {{ item.last4 | card_number_padding }}
                              </template>
                              <template v-slot:item.expiration_date="{ item }">
                                {{ item.exp_month | card_expiration_date(item.exp_year) }}
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                        v-if="companyContent.use_bank_transfer && !hasSubscription"
                        :readonly="true"
                    >
                      <v-expansion-panel-header :hide-actions="true">
                        <v-radio :value="1" label="銀行振り込み" />
                      </v-expansion-panel-header>
                    </v-expansion-panel>
                    <v-expansion-panel
                        v-if="companyContent.use_cash_on_delivery && !hasSubscription"
                    >
                      <v-expansion-panel-header :hide-actions="true">
                        <v-radio :value="2" label="代引き" />
                      </v-expansion-panel-header>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-radio-group>
                <v-row v-if="showPaymentNote" :dense="true">
                  <v-col>
                    <payment-note-dialog :title="paymentContent.payment_note_title" :post-name="paymentContent.payment_note_my_page" />
                  </v-col>
                </v-row>

              </v-card-text>
              <v-card-text>
                <v-row dense>
                  <v-col
                      cols="12"
                      class="text-center"
                  >
                    <v-btn
                        color="primary"
                        :block="$vuetify.breakpoint.mdAndDown"
                        :disabled="invalid"
                        @click="toNext"
                    >
                      <v-icon class="mr-2">
                        mdi-note-text-outline
                      </v-icon>注文確認へ
                      <div class="lang_lbl">（{{$t('view.注文確認へ')}}）</div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="ma-1" v-if="false">
              <v-card-title>
                <v-icon class="mr-2">
                  mdi-credit-card-outline
                </v-icon>お支払いカード
                <div class="lang_lbl ms-1">{{$t('view.お支払いカード')}}</div>
              </v-card-title>
              <v-card-text>
                <v-divider class="mb-2" />
                <v-row>
                  <v-col
                    cols="12"
                    align="end"
                  >
                    <payjp-checkout
                      :api-key="public_key"
                      :text="'カードを追加 (' + $t('view.カードを追加') + ')' "
                      submit-text="カードを追加"
                      name-placeholder="TARO RENTAL"
                      v-on:created="onTokenCreated"
                      v-on:failed="onTokenFailed"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="cardHeaders"
                      :items="cards"
                      loading-text="読込中"
                      no-data-text="カード情報はありません。"
                      hide-default-footer
                    >
                      <template v-slot:item.default_card="{ item }">
                        <validation-provider rules="required">
                          <v-radio-group v-model="selectedCardIndex" @change="changeCard(item)">
                            <v-radio label="" :value="item.index"></v-radio>
                          </v-radio-group>
                        </validation-provider>
                      </template>
                      <template v-slot:item.last4="{ item }">
                        {{ item.last4 | card_number_padding }}
                      </template>
                      <template v-slot:item.expiration_date="{ item }">
                        {{ item.exp_month | card_expiration_date(item.exp_year) }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    class="text-center"
                  >
                    <v-btn
                      color="primary"
                      :block="$vuetify.breakpoint.mdAndDown"
                      :disabled="invalid"
                      @click="toNext"
                    >
                      <v-icon class="mr-2">
                        mdi-note-text-outline
                      </v-icon>注文確認へ
                      <div class="lang_lbl">（{{$t('view.注文確認へ')}}）</div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </validation-observer>
    </v-form>
  </div>
</template>

<script>
import PaymentNoteDialog from "../../../../../components/_PaymentNoteDialog.vue"
import {mapActions, mapGetters, mapState} from 'vuex'

  export default {
    name: 'ExtendOrderPayment',
    components: {PaymentNoteDialog},
    mixins: [],
    data () {
      return {
        public_key: process.env.PAY_JP_PUBLIC_KEY,
        selectedPaymentType: 0,
        cardHeaders: [
          { text: "お支払いカード（" + this.$i18n.t('view.お支払いカード') +"）" , align: 'start', sortable: false, value: 'default_card' },
          { text: "ブランド（" + this.$i18n.t('view.ブランド') +"）", align: 'start', sortable: false, value: 'brand' },
          { text: "カード番号の下四桁（" + this.$i18n.t('view.カード番号の下４桁') +"）", align: 'center', sortable: false, value: 'last4' },
          { text: "有効期限（" + this.$i18n.t('view.有効期限') +"）", align: 'center', sortable: false, value: 'expiration_date' },
          { text: "保有者名（" + this.$i18n.t('view.保有者名') +"）", align: 'start', sortable: false, value: 'name' },
        ],
        delivery: {
          payment_type: null,
        },
        cards: [],
        selectedCardIndex: null,
        selectedCard: null,

      }
    },
    computed: {
      ...mapState('frontExtendOrder', {
        extendOrder: 'content',
      }),
      ...mapState("frontCompany", {
        companyContent: "content"
      }),
      ...mapState("frontThemeOption", {
        paymentContent: "payment_note"
      }),
      ...mapGetters("frontThemeOption", [
        "showPaymentNote"
      ]),
      hasSubscription() {

        if (this.extendOrder?.extend_orders) {
          return this.extendOrder.extend_orders.some(item => item.payment_type === "subscription_payment")
        } else {
          return false;
        }
      },

    },
    watch: {
      "delivery.payment_type": function(a) {
        if (a) {
          this.switchPaymentTypes();
        }
      },
      hasSubscription: function(a) {
        this.switchPaymentTypes();
      },
      companyContent: {
        handler: function(a, b) {
          if (a) {
            if (a.use_credit_card) {
              this.delivery.payment_type = 0;
            } else if (a.use_bank_transfer) {
              this.delivery.payment_type = 1;
            } else if (a.use_cash_on_delivery) {
              this.delivery.payment_type = 2;
            }

          }
        },
        deep: true
      }

    },
    created() {
      this.findCards().then( (content) => {
        // 選択用にindexを付与
        let indexCards = content.cards.map((item, index) => ({
          index: index,
          ...item
        }))
        // default_card を選択状態
        if (indexCards.length > 0) {
          const default_card = indexCards.find(card => card.default_card == true)
          this.selectedCard = default_card
          this.selectedCardIndex = default_card.index
          this.cards = indexCards
        }
      })
    },
    beforeDestroy () {
      window.PayjpCheckout = null
    },
    methods: {
      ...mapActions('frontExtendPayment', {
        fetchExtendPayment: 'fetch'
      }),
      ...mapActions('frontCard', {
        createCard: 'create',
        findCards: 'findCards'
      }),
      switchPaymentTypes () {
        const payment_type = this.delivery.payment_type;
        const has_subscription = this.hasSubscription;
        if (payment_type === 0) {
          this.selectedPaymentType = 0
        } else if (payment_type === 1 && !has_subscription) {
          this.selectedPaymentType = 1
        } else if (payment_type === 2 && !has_subscription) {
          this.selectedPaymentType = 2
        }
      },
      toNext () {
        this.fetchExtendPayment({ payment_type: this.delivery.payment_type, card_token: this.selectedCard.card_token})
        this.$emit('toNextStep')
      },
      toBack () {
        this.$emit('toBackStep')
      },
      onTokenCreated (response) {
        // カードのToken化に成功
        const params = {
          card_token: response.id,
        }
        this.createCard({params})
      },
      onTokenFailed (status, err) {
        // Token化に失敗
        console.log(status)
        console.log(err)
      },
      changeCard (item) {
        this.selectedCard = item
      },
    },
  }
</script>

<style scoped lang="scss">
</style>