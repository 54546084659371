/**
 * Home/お客様からのお問い合わせ
 */
import Vue from 'vue';
import * as httpConst from '../../../../constants/httpConst';

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/tickets`

const ticket = {
    namespaced: true,
    state: {
    },
    getters: {
    },
    actions: {
        fetchAll({ _commit }) {
            const parameter = { page: 1, limit: 10, status_opened: true, status_in_process: true }
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: parameter }).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
    },
    mutations: {
    },
}

export default ticket