/**
 * 顧客確認書類
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/customers`

const identification = {
    namespaced: true,
    actions: {
        show ({ commit, state }, {id}) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    const content = data.content
                    resolve(content)
                })
            })
        },
        update ({ commit }, { id, params }) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(data.content)
                } )
            })
        },
        findIdentifications ({ _commit }, { id }) {
            const api_path = `${API_PATH}/${id}/identifications`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
    },
}

export default identification

