<!-- バックヤード > 会員一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          会員管理
          <span
            class="float-right"
          >
            <v-btn
              v-if="backendCustomerInput"
              color="primary"
              @click="moveToMembership"
            >
              会員追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="inputNo"
                  dense
                  hide-details
                  label="会員番号"
                  outlined
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="inputName"
                  dense
                  hide-details
                  label="会員名"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="inputAddress"
                  dense
                  hide-details
                  label="住所"
                  outlined
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="inputPhone"
                  dense
                  hide-details
                  label="電話番号"
                  outlined
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="inputEmail"
                  dense
                  hide-details
                  label="メールアドレス"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-checkbox
                  v-model="withDeleted"
                  class="pa-0 ma-0"
                  hide-details="auto"
                  label="退会者を含む"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="resetCustomers"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" />
                  <th class="text-left">
                    会員番号
                  </th>
                  <th class="text-left">
                    会員名
                  </th>
                  <th class="text-left">
                    住所
                  </th>
                  <th class="text-left">
                    電話番号
                  </th>
                  <th class="text-left">
                    国際電話番号
                  </th>
                  <th class="text-left">
                    メールアドレス
                  </th>
                  <th class="text-left">
                    会員登録日時
                  </th>
                  <th class="text-left">
                    退会日時
                  </th>
                  <th
                    v-if="backendCustomerInput"
                  >
                    編集
                  </th>
                  <th>
                    参照
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="customer in customers"
                  :key="customer.id"
                >
                  <td>
                    <v-list-item-avatar color="primary">
                      <span
                        class="white--text"
                      >
                        {{ customer.name.slice(0, 2) }}
                      </span>
                    </v-list-item-avatar>
                  </td>
                  <td class="text-right">
                    {{ customer.no }}
                  </td>
                  <td>{{ customer.name }}</td>
                  <td><span v-if="customer.zip_code">〒</span>{{ customer.zip_code }}<br>{{ customer.address }}</td>
                  <td>{{ customer.phone }}</td>
                  <td>{{ customer.international_phone }}</td>
                  <td>{{ customer.email }}</td>
                  <td>{{ customer.created_at | unix_datetime_format }}</td>
                  <td>{{ customer.deleted_at | unix_datetime_format }}</td>
                  <td
                    v-if="backendCustomerInput"
                  >
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="editButtonClickEvent(customer)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="showButtonClickEvent(customer)"
                    >
                      <v-icon>mdi-file-search-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row>
            <v-col
              v-if="isCustomers"
              cols="12"
            >
              <div class="text-center mt-6">
                <v-pagination
                  v-model="page"
                  :length="pagination.total_pages"
                  :total-visible="totalVisible"
                  circle
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
                  @input="changePage"
                />
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  name: 'CustomerIndex',
  data () {
    return {
      page: 1,
      totalVisible: 7,
      inputNo: '',
      inputName: '',
      inputAddress: '',
      inputPhone: '',
      inputInternationalPhone:'',
      inputEmail: '',
      withDeleted: false,
      customers: [],
    }
  },
  computed: {
    ...mapState('backCustomer', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
    ...mapGetters('backCustomer', [
      'isCustomers'
    ]),
    ...mapState('backSystemSetting', {
      backendCustomerInput: 'backend_customer_input'
    }),
  },
  created() {
    this.page = this.pagination.current_page
    this.searchCustomers()
  },
  methods: {
    ...mapActions('backCustomer', {
      fetchCustomers: 'fetchCustomers',
      fetchQuery: 'fetchQuery',
    }),
    moveToMembership () {
      this.$router.push({ name: 'byMemberNew' })
    },
    editButtonClickEvent (item) {
      this.$router.push({name: 'byMemberEdit', params: { id: item.id }})
    },
    showButtonClickEvent(item) {
      this.$router.push({name: 'byMemberShow', params: { id: item.id }})
    },
    resetCustomers () {
      this.page = 1
      this.searchCustomers()
    },
    changePage () {
      this.searchCustomers()
    },
    searchCustomers () {
      const params = {
        no: this.inputNo,
        name: this.inputName,
        address: this.inputAddress,
        phone: this.inputPhone,
        international_phone: this.inputInternationalPhone,
        email: this.inputEmail,
        withDeleted: this.withDeleted,
        page: this.page,
      }
      this.fetchCustomers(params).then((content) => {
        this.customers = content.customers
      })
    }
  },
}
</script>

<style lang="scss">

</style>
