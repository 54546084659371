/**
 * 商品
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/order_deliveries`

const order_delivery_product = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetchAll({ _commit }, { order_delivery_id }) {
      const api_path = `${API_PATH}/${order_delivery_id}/products`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {},
}

export default order_delivery_product