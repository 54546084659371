<!-- バックヤード > 新規会員登録完了 -->
<template>
  <v-container
    fluid
    fill-height
  >
    <v-card
      outlined
      class="mx-auto"
      style="width: 40rem;"
    >
      <v-card-title
        class="justify-center"
      >
        <h3 class="text-center">
          認証メールを送信しました
          <div class="lang_lbl">{{ $t('view.認証メールを送信しました') }}</div>
        </h3>
      </v-card-title>
      <v-card-text>
        <p class="text-center">
          認証メールに記載されたURLへアクセスすることで認証が完了しログインが可能となります。
        </p>
        <div class="lang_lbl">{{ $t('view.認証メール送信完了_1') }}</div>
        <div class="text-center">
          <v-btn
            @click="backButtonClickEvent"
          >
            ログイン画面に戻る
            <div class="lang_lbl">{{ $t('view.ログイン画面に戻る') }}</div>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import {mapState} from "vuex";

  export default {
    name: 'ForgetCompleted',
    components: {
    },
    mixins: [
    ],
    data () {
      return {
      }
    },
    computed: {
      ...mapState('frontSystemSetting', {
        frontendAuthMode: 'frontend_auth_mode'
      }),
    },
    created () {},
    methods: {
      backButtonClickEvent () {
        if (this.frontendAuthMode) {
          this.$router.push({name: 'SessionIndex'})
        } else {
          this.$router.push({name: 'HomeIndex'})
        }
      }
    },
  }
</script>

<style scoped lang="scss">
</style>
