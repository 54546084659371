<template>
  <div>
    <v-app-bar
        color="header"
      :style="icon_styles"
      class="header_colors"
      dark
      app
      fixed
    >
      <router-link
        to
        @click.native="moveToPage"
      >
        <v-img
          v-if="isHeaderImage"
          :src="this.theme.logo_url | load_image_url"
          class="header_logo"
          alt="logo"
          max-height="50"
          max-width="300"
        />
        <v-sheet
          v-if="isHeaderText"
          color="header"
          class="header_icon"
        >
          <v-toolbar-title>
            {{ theme.logo_title }}
          </v-toolbar-title>
        </v-sheet>
      </router-link>
      <v-spacer />
    </v-app-bar>
  </div>
</template>

<script>

import {mapState, mapActions, mapGetters} from 'vuex'
  import ThemeOptionMixin from '../../mixins/frontend/theme-option-mixin'

  /**
   * セッション用ヘッダー
   */
  export default {
    name: 'SessionHeaderNav',
    mixins: [
      ThemeOptionMixin,
    ],
    data () {
      return {
        loginDialog: false,
        cartCount: 0,
      }
    },
    computed: {
      ...mapState('frontHeaderOption', {
        theme: 'theme',
      }),
      ...mapState('frontSystemSetting', {
        frontendAuthMode: 'frontend_auth_mode'
      }),
      ...mapGetters('frontHeaderOption', [
        'getIconColor','getHeaderColor'
      ]),
      logged_in () {
        return this.loggedIn
      },
      icon_styles() {
        return {
          '--header-color': this.getHeaderColor,
          '--icon-color': this.getIconColor,
        };
      },
    },
    watch: {
      theme (newVal) {
        this.$vuetify.theme.themes.light.header = newVal.header_color
        this.$vuetify.theme.themes.light.primary = newVal.site_color
      },
    },
    created() {
      this.fetch()
    },
    methods: {
      ...mapActions('frontHeaderOption', {
        fetch: 'fetch'
      }),
      moveToPage () {
        if (this.frontendAuthMode) {
          this.$router.replace({name: 'SessionIndex'}, () => {})
        } else {
          this.$router.replace({name: 'HomeIndex'}, () => {})
        }
      },
    }
  }

</script>

<style lang="scss">

header.header_colors {
  a.router-link-active{
    text-decoration: none !important;
    color:var(--icon-color) !important;
  }
  .theme--dark.v-sheet{
    color:var(--icon-color) !important;
  }
  .header_logo {
    max-width: 330px;
  }
  .header_icon,.header_icon .v-icon{
    color:var(--icon-color) !important;
  }
}

@media only screen and (max-width: 750px) {
  .header_logo { max-width: 140px !important; }
}
</style>