/**
 * backyard 用のモジュール読み込み
 */
import backyardAuth from './auth'
import backSystemSetting from './system_setting'

import backHome from './home'
import backHomeGenerateApplyUrl from './home/generate_apply_url'
import backHomeCompany from './home/company'
import backHomeSystemAnnouncement from './home/system_announcement'
import backHomeTicket from './home/ticket'
import backHomeUnshippedItem from './home/unshipped_item'
import backHomeUnreturnedItem from './home/unreturned_item'
import backHomeUninspectedItem from './home/uninspected_item'
import backHomeUnsettledOrder from './home/unsettled_order'
import backHomeProductQuestion from './home/product_question'

import backPaymentRequest from './companies/payment_request'

import backCampaign from './campaign'
import backCampaignProduct from './campaigns/campaign_product'
import backThemeOption from './theme_option'
import backThemeOptionSlide from './theme_option/slide'
import backThemeOptionBanner from './theme_option/banner'
import backThemeOptionBannerLink from './theme_option/banners/banner_link'
import backThemeOptionBackgroundImageContent from './theme_option/background_image_content'
import backThemeOptionOther from './theme_option/other'

import backCategory from './category'
import backTag from './tag'
import backLocalPlace from './local_place'
import backOptionGroup from './option_group'
import backOptionItem from './option_groups/option_item'
import backDeliveryCompany from './delivery_company'
import backDeliveryCompanyTime from './delivery_companies/delivery_company_time'
import backDeliveryMethod from './delivery_method'

import backDeliveryDesiredTime from './delivery_desired_time'

import backProduct from './product'
import backProductDescription from './product_description'
import backProductImage from './products/product_image'
import backProductTag from './products/product_tag'
import backProductKeyword from './products/product_keyword'
import backProductCatalog from './products/product_catalog'
import backProductVideo from './products/product_video'
import backProductItem from './products/product_item'
import backProductRelation from './products/product_relation'
import backProductDocument from './products/product_document'
import backProductStock from './products/product_stock'
import backProductQuestion from './product_question'
import backProductComponent from './products/product_component'
import backProductOption from './products/product_option'
import backProductOptionGroup from './products/product_option_group'
import backProductReview from './product_review'

import backSearchCategory from './search/category'
import backSearchTag from './search/tag'
import backSearchMyPage from './search/my_page'
import backSearchProduct from './search/product'
import backSearchDeliveryCompany from './search/delivery_company'
import backSearchDeliveryCompanyTime from './search/delivery_companies/delivery_company_time'
import backSearchOptionGroup from './search/option_group'
import backSearchLocalPlace from './search/local_place'

import backCredence from './credence'
import backCommercialLaw from './commercial_law'
import backContract from './contract'

import backNews from './news'
import backUser from './user'

import backUserPassword from './users/password'
import backForgetPassword from './forget'

import backConfiguration from './configuration'
import backCustomerItem from './customer_item'
import backAllowIpmask from './allow_ipmask'

import backActivate from './activate'
import backCustomer from './customer'
import backCustomerIdentification from './customers/identification'
import backCustomerRemark from './customers/remark'

import backOrder from './order'
import backOrderDetail from './orders/order_detail'
import backOrderStock from './orders/order_stock'
import backOrderStockHistory from './orders/order_stock_histories'
import backOrderDelivery from './orders/order_delivery'
import backOrderSubscription from './orders/order_subscription'
import backOrderDeliveryProduct from './order_deliveries/product'
import backOrderDeliveryDocument from './order_deliveries/product_document'
import backOrderExtendOrderCancel from './orders/extend_orders/cancel'
import backOrderSubscriptionFailedMail from './orders/order_subscriptions/send_failed_mail'

import backRentalSchedule from './rental_schedule'

import backSendBack from './send_back'

import backCustomerNotice from './customers/notice'

import backMyPage from './my_page'
import backMyPageImage from './my_pages/my_page_image'

import backAnnouncement from './announcement'
import backReadAnnouncement from './announcements/read'

import backTicket from './ticket'
import backTicketComment from './ticket_comment'

import backSubscription from './subscription'

import backStock from './stock'

const backyardModules = {
  modules: {
    backyardAuth,
    backSystemSetting,
    backHomeGenerateApplyUrl,
    backHomeCompany,
    backHomeSystemAnnouncement,
    backHomeTicket,
    backHomeUnshippedItem,
    backHomeUnreturnedItem,
    backHomeUninspectedItem,
    backHomeUnsettledOrder,
    backHomeProductQuestion,
    backPaymentRequest,
    backThemeOption,
    backThemeOptionSlide,
    backThemeOptionBanner,
    backThemeOptionBannerLink,
    backThemeOptionBackgroundImageContent,
    backThemeOptionOther,

    backCampaign,
    backCampaignProduct,
    backProduct,
    backProductDescription,
    backProductQuestion,
    backProductImage,
    backProductTag,
    backCategory,
    backTag,
    backLocalPlace,
    backOptionGroup,
    backOptionItem,
    backDeliveryCompany,
    backDeliveryCompanyTime,
    backDeliveryMethod,
    backDeliveryDesiredTime,
    backProductKeyword,
    backProductCatalog,
    backProductVideo,
    backProductItem,
    backProductRelation,
    backProductDocument,
    backProductStock,
    backProductComponent,
    backProductOption,
    backProductOptionGroup,
    backProductReview,
    backSearchCategory,
    backSearchTag,
    backSearchMyPage,
    backSearchProduct,
    backSearchLocalPlace,
    backSearchDeliveryCompany,
    backSearchDeliveryCompanyTime,
    backSearchOptionGroup,
    backCredence,
    backCommercialLaw,
    backContract,
    backNews,
    backUser,
    backUserPassword,
    backForgetPassword,
    backConfiguration,
    backCustomerItem,
    backAllowIpmask,
    backActivate,
    backCustomer,
    backCustomerRemark,
    backCustomerIdentification,
    backOrder,
    backOrderDetail,
    backOrderStock,
    backOrderStockHistory,
    backOrderDelivery,
    backOrderSubscription,
    backOrderDeliveryProduct,
    backOrderDeliveryDocument,
    backOrderExtendOrderCancel,
    backOrderSubscriptionFailedMail,
    backRentalSchedule,
    backSendBack,
    backCustomerNotice,
    backMyPage,
    backMyPageImage,
    backAnnouncement,
    backReadAnnouncement,
    backHome,
    backTicket,
    backTicketComment,
    backSubscription,
    backStock
  }
}

export default backyardModules
