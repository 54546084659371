/**
 * 注文詳細 返却申請
 */
import Vue from 'vue'
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/orders`

const subscriptions = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    delete ({ commit }, { order_id, order_detail_id, params }) {
      const api_path = `${API_PATH}/${order_id}/order_details/${order_detail_id}/subscriptions`
      return new Promise((resolve, reject) => {
        Vue.$http.delete(api_path, {params: params}).then(data => {
          commit('snackbar/showInfo', { message: '返却申請を提出しました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
  },
  mutations: {},
}

export default subscriptions
