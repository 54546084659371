/**
 * 休日
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/frontend-types";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/holidays`

const holiday = {
  namespaced: true,
  state: {
    content: {},
  },
  actions: {
    fetch({ commit }, { start_date, end_date, local_place_id }) {
      const params = {
        start_date: start_date,
        end_date: end_date,
        local_place_id: local_place_id,
      }
      return new Promise((resolve, reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.FRONT_HOLIDAY, { content })
          resolve(content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
  },
  mutations: {
    [types.FRONT_HOLIDAY] (state, { content }) {
      state.content = content
    },
  },
}

export default holiday
