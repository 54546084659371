/**
 * システムお知らせ
 */
import Vue from "vue";
import * as types from "../../mutation-types/admin-types";
import * as httpConst from "../../../constants/httpConst";
// import adAnnouncementIndex from "../../../admin/pages/announcements/Index";

const API_PATH = `${httpConst.ADMIN_ENDPOINT}/announcements`

const announcement = {
    namespaced: true,
    state: {
        // ページ情報
        pagination: {
            current_page: 1,
            total_count: 0
        },
        // 検索条件
        query: {
            keyword: '',
            enable_display_date: false,
            display_date: null
        },
    },

    getters: {

        hasAnnouncements: ( state ) => {
            return (state.pagination.total_count > 0)
        },
    },
    actions: {
        // 一覧検索
        fetchAll({ dispatch, commit, state }, {keyword, enable_display_date, display_date, page}) {
            const queries = { keyword: keyword, enable_display_date: enable_display_date, display_date: display_date }
            // コミット
            dispatch('fetchQuery', queries)

            // state参照
            const params = {
                keyword: state.query.keyword,
                enable_display_date: state.query.enable_display_date,
                display_date: state.query.display_date,
                page: page
            }

            // index 取得
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    commit(types.ADMIN_ANNOUNCEMENT, { content })
                    resolve(content)
                })
            })
        },


        fetchQuery ({ commit, state}, {keyword, enable_display_date, display_date }) {

            const keywordText = (keyword !== undefined)? keyword : state.query.keyword
            const enableDisplayDate = (enable_display_date !== undefined) ? enable_display_date : state.query.enable_display_date
            const displayDate = (display_date !== undefined) ? display_date: state.query.display_date
            const params = { keyword: keywordText,
                             enable_display_date: enableDisplayDate,
                             display_date: displayDate
            }
            // mutaion に保持
            commit(types.ADMIN_ANNOUNCEMENT_QUERY, { params })
        },

        fetch({ _commit, state }, { id }) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },

        edit({ _commit, state }, { id }) {
            const api_path = `${API_PATH}/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },

        new ({ _commit }) {

            const api_path = `${API_PATH}/new`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },

        create( { commit, state }, { params } ) {
            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(API_PATH, params).then( data => {
                    let content = data.content
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    resolve(content)
                } )
            })
        },
        update( {commit, state}, { id, params } ) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params).then( data => {
                    let content = data.content
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(content)
                } )
            })
        },
        destroy( { commit, state }, { id } ) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },

    },
    mutations: {

        [types.ADMIN_ANNOUNCEMENT] (state, { content }) {
            state.pagination = content.pagination
        },

        [types.ADMIN_ANNOUNCEMENT_QUERY] (state, { params }) {
            state.query.keyword = params.keyword
            state.query.enable_display_date = params.enable_display_date
            state.query.display_date = params.display_date
        },

    },
}

export default announcement