/**
 * 商品レビュー
 */
import Vue from "vue";
import * as types from "../../mutation-types/frontend-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/products`

const product_review = {
  namespaced: true,
  state: {
    pagination: {
      is_last_page: true,
    },
  },
  getters: {
  },
  actions: {
    findReviews ({ commit }, {product_id, page}) {
      const params = {
        page: page
      }
      const api_path = `${API_PATH}/${product_id}/reviews`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path, { params: params }).then(data => {
          const content = data.content
          commit(types.FRONT_PRODUCT_REVIEW, { content })
          resolve(content)
        })
      })
    },
    searchReview ({ commit }, {product_id}) {
      const api_path = `${API_PATH}/${product_id}/search_review`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          const content = data.content
          resolve(content)
        })
      })
    },
    create({ commit }, {product_id, params}) {
      const api_path = `${API_PATH}/${product_id}/reviews`
      return new Promise((resolve, reject) => {
        Vue.$http.post(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          const content = data.content
          resolve(content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    update({ commit }, { product_id, params }) {
      const api_path = `${API_PATH}/${product_id}/reviews/${params['id']}`
      return new Promise((resolve, reject) => {
        Vue.$http.patch(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          const content = data.content
          resolve(content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    }
  },
  mutations: {
    [types.FRONT_PRODUCT_REVIEW] (state, { content }) {
      state.pagination = content.pagination
    },
  },
}

export default product_review
