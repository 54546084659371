<template>
  <div>
    <h3 class="text-center">
      お気に入り情報
    </h3>
    <v-row v-if="isCarts">
      <v-col
        cols="12"
        md="9"
      >
        <v-row dense>
          <v-col
            v-for="item in content.carts"
            :key="item.id"
            cols="12"
            sm="6"
          >
            <order-product-auction
              :item="item"
              :is-edit="true"
              @loadCart="loadCart"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        class="text-center"
      >
        商品が追加されていません。
      </v-col>
      <v-col cols="12" align="center">
        <v-btn outlined color="primary" :block="($vuetify.breakpoint.mdAndDown) ? true : false" @click="moveToHome">
          <v-icon>mdi-home-outline</v-icon>ホームに戻る
          <div class="lang_lbl">{{$t('view.ホームに戻る')}}</div>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import OrderProductAuction from './_OrderProductAuction'
import CartStepperMixin from '../../../../mixins/frontend/cart-stepper-mixin'

export default {
  name: 'OrderSummaryAuction',
  components: {
    OrderProductAuction,
  },
  mixins: [
    CartStepperMixin
  ],
  data () {
    return {
    }
  },
  computed: {
    ...mapState('frontCart', {
      content: 'content',
    }),
    ...mapGetters('frontCart', [
      'isCarts'
    ]),
  },
  created() {
    this.loadCart()
  },
  methods: {
    ...mapActions('frontCart', {
      fetchCarts: 'fetchCarts',
      fetchCartCount: 'fetchCount',
    }),
    toNext () {
      this.$emit('toNextStep')
    },
    loadCart () {
      this.fetchCartCount()
      const params = {
        prefecture_code: null,
      }
      this.fetchCarts({ params: params })
    },
  },
}
</script>

<style scoped lang="scss">
</style>