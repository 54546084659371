<!-- バックヤード > メールテンプレート一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn
            color="primary"
            @click="templateMailCreate"
          >
            メールテンプレート追加
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    dense
                    label="キーワード"
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-btn color="primary">
                検索
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    テンプレート名
                  </th>
                  <th class="text-left">
                    件名
                  </th>
                  <th class="text-left">
                    本文
                  </th>
                  <th class="text-center">
                    表示順
                  </th>
                  <th class="text-left">
                    作成日時
                  </th>
                  <th class="text-left">
                    更新日時
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="templateMail in templateMails"
                  :key="templateMail.id"
                >
                  <td>{{ templateMail.name }}</td>
                  <td>{{ templateMail.title }}</td>
                  <td
                    class="text-truncate"
                    style="max-width: 350px;"
                  >{{ templateMail.message }}
                  </td>
                  <td class="text-center">
                    {{ templateMail.id }}
                  </td>
                  <td>{{ templateMail.create }}</td>
                  <td>{{ templateMail.update }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="templateMailEdit"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-pagination
      v-model="page"
      circle
      :length="10"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      :total-visible="5"
    />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";

  export default {
    components: {
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        page: 1,
        templateMails:[
          {id: 1, name: '注文サンクスメール', title: 'ご注文内容いただきありがとうございました。', message: '', create: '2020-06-01 10:00', update: '2020-06-30 12:00'},
          {id: 2, name: '発送完了メール', title: '商品発送のお知らせ', message: '○○様\nこの度はご注文ありがとうございました。\n商品を発送致しましたのでご連絡致します。\n\n配送業者：ヤマト運輸\n時間指定：14〜16時\n伝票番号：123-456-7890\n配送の詳細は配送業者のHPより追跡を行ってください。', create: '2020-06-01 10:00', update: '2020-06-30 12:00'},
          {id: 3, name: '利用予定後(返却案内)メール', title: 'ご利用終了日のご案内', message: '', create: '2020-06-01 10:00', update: '2020-06-30 12:00'},
          {id: 4, name: 'その他', title: 'その他', message: 'その他', create: '2020-06-01 10:00', update: '2020-06-30 12:00'},
        ],
      }
    },
    methods: {
      templateMailCreate: function () {
        this.$router.push({name: 'byTemplateMailCreateIndex'})
      },
      templateMailEdit: function () {
        this.$router.push({name: 'byTemplateMailEditIndex', params: {id: 1}})
      },
    }
  }
</script>

<style lang="scss">

</style>
