<!-- バックヤード > 書類テンプレートform -->
<template>
  <div>
    <v-card outlined>
      <v-card-text>

        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              hide-details="auto"
              outlined
              clearable
              placeholder=" "
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <span class="required">テンプレート名</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <v-text-field
              dense
              label="表示順"
              reverse
              hide-details="auto"
              outlined
              clearable
              placeholder=" "
            ></v-text-field>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-card>
      <v-card-text>
        <h3>書類イメージ</h3>
        <v-row>
          <v-col cols="6">
            <v-card outlined>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="4"
                    offset="4"
                  >
                    <h2 class=titlePosition>{{ documentTitle }}</h2>
                    <v-divider />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <h3 class="text-right">{{ honorTitle }}</h3>
                    <v-divider />
                  </v-col>
                  <v-col
                    cols="4"
                    offset="4"
                  >
                    <h3>作成日</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                  </v-col>
                </v-row>



              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">

            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="documentTitle"
                  dense
                  hide-details="auto"
                  outlined
                  clearable
                  placeholder=" "
                  :rules="[rules.required]"
                >
                  <template v-slot:label>
                    <span class="required">書類タイトル</span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-radio-group
                  v-model="titlePosition"
                  row
                  dense
                  hide-details="auto"
                  class="pa-0 ma-0"
                >
                  <v-radio
                    label="左寄せ"
                    value="text-left"
                  ></v-radio>
                  <v-radio
                    label="中央"
                    value="text-center"
                  ></v-radio>
                  <v-radio
                    label="右寄せ"
                    value="text-right"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-radio-group
                  v-model="honorTitle"
                  row
                  dense
                  hide-details="auto"
                  class="pa-0 ma-0"
                >
                  <v-radio
                    label="様"
                    value="様"
                  ></v-radio>
                  <v-radio
                    label="御中"
                    value="御中"
                  ></v-radio>
                  <v-radio
                    label="なし"
                    value="　"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>


          </v-col>
        </v-row>

      </v-card-text>
    </v-card>


  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";

  export default {
    components: {
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        documentTitle: '納品書',
        titlePosition: 'text-center',
        honorTitle: '様',
      }
    },
    methods: {
    },
  }
</script>

<style lang="scss">
</style>
