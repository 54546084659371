<!-- バックヤード > 配送業者・時間追加 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <delivery-company-form
      v-if="deliveryCompany"
      :delivery-company="deliveryCompany"
      :is-edit="false"
    />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import DeliveryCompanyForm from './forms/_DeliveryCompany'

  export default {
    name: 'ByDeliveryCompanyNew', // 画面名
    components: {
      DeliveryCompanyForm, // 共通フォーム参照
    },
    mixins: [],
    data () {
      return {
        deliveryCompany: null,
        // パンくず
        breadcrumbs: [
          { text: '配送業者一覧', disabled: false, to: '/backyard/deliveryCompanies', exact: true },
          { text: '配送業者追加', disabled: true, to: '' },
        ],
      }
    },

    created () {
      this.newDeliveryCompany().then((content) => {
        this.deliveryCompany = content
      })
    },
    methods: {
      // actions 参照
      ...mapActions('backDeliveryCompany', {
        newDeliveryCompany: 'new'
      }),
    },
  }
</script>

<style scoped lang="scss">
</style>
