/**
 * キャンペーン
 */
import Vue from "vue";
import * as types from "../../mutation-types/frontend-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/campaigns`

const campaign = {
  namespaced: true,
  state: {
    content: [],
  },
  getters: {},
  actions: {
    fetch({ commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          commit(types.FRONT_CAMPAIGN, { content })
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.FRONT_CAMPAIGN] (state, { content }) {
      state.content = content
    },
  },
}

export default campaign
