/**
 * admin 用のモジュール読み込み
 */
import adminAuth from './auth'
import adminCompany from './company'
import adminBank from './bank'
import adminBankBranch from './banks/branch'
import adminAnnouncement from './announcement'


const adminModules = {
  modules: {
    adminAuth,
    adminCompany,
    adminBank,
    adminBankBranch,
    adminAnnouncement
  }
}

export default adminModules