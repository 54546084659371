<!-- バックヤード > パスワードを忘れた方 -->
<template>
  <v-container
    fluid
    fill-height
  >
    <v-card
      outlined
      class="mx-auto"
      style="width: 40rem;"
    >
      <v-card-title
        class="justify-center"
      >
        <h3 class="text-center">
          パスワードの再設定が完了しました。
        </h3>
      </v-card-title>
      <v-card-text>
        <p class="text-center">
          再設定したパスワードでログインしてください
        </p>
        <div class="text-center">
          <v-btn
            @click="backButtonClickEvent"
          >
            ログイン画面に戻る
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import {mapState} from "vuex";
  import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";

  export default {
    name: 'ForgetCompleted',
    components: {
    },
    mixins: [
      PageHeaderMixin
    ],
    data () {
      return {
      }
    },
    computed: {
      ...mapState('frontSystemSetting', {
        frontendAuthMode: 'frontend_auth_mode'
      }),
    },
    created () {
      this.pageTitle = this.$route.meta.siteSubTitle
    },
    methods: {
      backButtonClickEvent () {
        if (this.frontendAuthMode) {
          this.$router.push({name: 'SessionIndex'})
        } else {
          this.$router.push({name: 'HomeIndex'})
        }
      }
    },
  }
</script>

<style scoped lang="scss">
</style>
