<!-- バックヤード > SHOPサイト画面設定 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          SHOPサイト画面設定
        </h2>
        <v-form>
          <v-tabs
            v-model="themeOptionForm"
            centered
            fixed-tabs
            icons-and-text
            background-color="blue lighten-4"
          >
            <v-tabs-slider />

            <v-tab href="#tab-basic">
              基本設定
              <v-icon>mdi-diamond-stone</v-icon>
            </v-tab>

            <v-tab href="#tab-slider">
              スライダー
              <v-icon>mdi-monitor-screenshot</v-icon>
            </v-tab>

            <v-tab href="#tab-news">
              ニュース
              <v-icon>mdi-newspaper-variant-multiple</v-icon>
            </v-tab>

            <v-tab href="#tab-category">
              カテゴリー
              <v-icon>mdi-select-group</v-icon>
            </v-tab>

            <v-tab href="#tab-new-arrivals">
              新着商品
              <v-icon>mdi-new-box</v-icon>
            </v-tab>

            <v-tab href="#tab-new-arrivals-button">
              新着商品ボタン
              <v-icon>mdi-new-box</v-icon>
            </v-tab>

            <v-tab href="#tab-campaign">
              {{ company_mode_caption_name }}
              <v-icon>mdi-trophy-award</v-icon>
            </v-tab>

            <v-tab href="#tab-campaign-button">
              {{ company_mode_caption_name }}ボタン
              <v-icon>mdi-trophy-award</v-icon>
            </v-tab>

            <v-tabs-items v-model="themeOptionForm">
              <v-tab-item value="tab-basic">
                <basic-form
                  v-if="theme_option"
                  :theme-option="theme_option"
                  :company-mode-caption-name="company_mode_caption_name"
                />
              </v-tab-item>

              <v-tab-item value="tab-slider">
                <slider-form
                  v-if="theme_option"
                  :theme-option="theme_option"
                />
              </v-tab-item>

              <v-tab-item value="tab-news">
                <news-form
                  v-if="theme_option"
                  :theme-option="theme_option"
                />
              </v-tab-item>

              <v-tab-item value="tab-category">
                <category-form
                  v-if="theme_option"
                  :theme-option="theme_option"
                />
              </v-tab-item>

              <v-tab-item value="tab-new-arrivals">
                <new-arrivals-form
                  v-if="theme_option"
                  :theme-option="theme_option"
                />
              </v-tab-item>

              <v-tab-item value="tab-new-arrivals-button">
                <new-arrivals-button-form
                  v-if="theme_option"
                  :theme-option="theme_option"
                />
              </v-tab-item>

              <v-tab-item value="tab-campaign">
                <campaign-form
                  v-if="theme_option"
                  :theme-option="theme_option"
                  :company-mode-caption-name="company_mode_caption_name"
                />
              </v-tab-item>

              <v-tab-item value="tab-campaign-button">
                <campaign-button-form
                  v-if="theme_option"
                  :theme-option="theme_option"
                  :company-mode-caption-name="company_mode_caption_name"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import BasicForm from "./forms/_Basic";
  import SliderForm from "./forms/_Slider";
  import NewsForm from "./forms/_News";
  import CategoryForm from "./forms/_Category";
  import NewArrivalsForm from "./forms/_NewArrivals";
  import NewArrivalsButtonForm from "./forms/_NewArrivalsButton";
  import CampaignForm from "./forms/_Campaign";
  import CampaignButtonForm from "./forms/_CampaignButton";
  import {mapActions} from "vuex";

  export default {
    name: 'ThemeOptionEdit',
    components: {
      BasicForm,
      SliderForm,
      NewsForm,
      CategoryForm,
      NewArrivalsForm,
      NewArrivalsButtonForm,
      CampaignForm,
      CampaignButtonForm,
    },
    data () {
      return {
        themeOptionForm: 'tab-basic',
        theme_option: null,
        company_mode_caption_name: '',
      }
    },
    created() {
      this.editThemeOption().then((content) => {
        this.company_mode_caption_name = content.company_mode_caption_name
        this.theme_option = content
      })
    },
    methods: {
      ...mapActions('backThemeOption', {
        editThemeOption: 'edit',
      }),
    },
  }
</script>

<style scoped lang="scss">

</style>
