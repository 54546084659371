<template>
  <div>
    <v-card class="ma-1">
      <v-img
        height="200px"
        contain
        :src="item.image_url | load_image_url"
        :lazy-src="item.image_url | load_image_url"
        aspect-ratio="1"
        class="white lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
      </v-img>
      <v-card-title class="pb-0">
        {{ item.name }}
      </v-card-title>
      <v-card-text class="text--primary py-2">
        <v-row dense>
          <v-col
            v-if="item.payment_type == 'one_time_payment' || item.payment_type == 'subscription_payment'"
            cols="12"
            class="py-0"
          >
            <template v-if="item.discount_price > 0">
              金額<span class="lang_lbl">{{ $t('view.金額') }}</span>
              ：<s>{{ item.price | in_tax_yen_format(item.tax_exempt) }}</s>
            </template>
            <template v-else>
              金額<span class="lang_lbl">{{ $t('view.金額') }}</span>
              ：{{ item.price | in_tax_yen_format(item.tax_exempt) }}
            </template>
          </v-col>
          <v-col
            v-if="item.payment_type == 'sale_payment' && item.price > 0"
            cols="12"
            class="py-0"
          >
            <template v-if="item.discount_price > 0">
              販売価格<span class="lang_lbl">{{ $t('view.販売価格') }}</span>
              ：<s>{{ item.price | in_tax_yen_format(item.tax_exempt) }}</s>
            </template>
            <template v-else>
              販売価格<span class="lang_lbl">{{ $t('view.販売価格') }}</span>
              ：{{ item.price | in_tax_yen_format(item.tax_exempt) }}
            </template>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
            v-if="item.discount_price > 0"
          >
            {{ item.discount_title }}：{{ item.discount_price | in_tax_yen_format(item.tax_exempt) }}
          </v-col>
          <v-col
            v-if="item.product_options.length > 0"
            cols="12"
            class="py-0"
            align-self="center"
          >
            オプション合計金額<span class="lang_lbl">{{ $t('view.オプション合計金額') }}</span>
            ：{{ item.option_total_price | in_tax_yen_format(item.option_tax_exempt) }}
          </v-col>
          <v-col
            v-if="item.product_options.length > 0"
            align="end"
            cols="12"
            class="py-0"
          >
            <v-btn
              text
              small
              @click="showOption = !showOption"
            >
              オプション詳細<span class="lang_lbl">{{ $t('view.オプション詳細') }}</span>
              <v-icon>{{ showOption ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-col>
          <v-expand-transition>
            <v-row v-show="showOption">
              <v-col cols="12">
                <v-divider />
                <v-card-text>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th>
                          オプション名
                          <span class="lang_lbl">{{ $t('view.オプション名') }}</span>
                        </th>
                        <th
                          class="text-right"
                        >
                          金額
                          <span class="lang_lbl">{{ $t('view.金額') }}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="option in item.product_options"
                        :key="option.id"
                      >
                        <td>
                          <strong>{{ option.label }}</strong>
                          <br>
                          {{ option.name }}
                        </td>
                        <td style="text-align: end">
                          {{ option.price * item.quantity | in_tax_yen_format(option.tax_exempt) }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <v-divider />
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-col
            cols="12"
            class="py-0"
          >
            <template v-if="item.payment_type == 'one_time_payment'">
              ご利用日数<span class="lang_lbl">{{ $t('view.ご利用日数') }}</span>
              ：{{ item.rental_days_label }}
            </template>
            <template v-else-if="item.payment_type == 'subscription_payment'">
              最低レンタル期間<span class="lang_lbl">{{ $t('view.最低レンタル期間') }}</span>
              ：{{ item.rental_days_label }}
            </template>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            数量<span class="lang_lbl">{{ $t('view.数量') }}</span>
            ：{{ item.quantity }}
          </v-col>
          <v-col
            cols="12"
            class="py-0"
            v-if="item.is_local_stock_item"
          >
            受取場所<span class="lang_lbl">{{ $t('view.受取場所') }}</span>
            ：{{ item.receive_comment }}
          </v-col>
          <v-col
            v-if="item.payment_type == 'one_time_payment' || item.payment_type == 'subscription_payment'"
            cols="12"
            class="py-0"
          >
            <div v-if="item.is_apply">
              ご利用開始日<span class="lang_lbl">{{ $t('view.ご利用開始日') }}</span>
              ：{{ item.schedule_date | date_format }}
            </div>
            <v-tooltip
              v-else
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  ご利用開始日<span class="lang_lbl">{{ $t('view.ご利用開始日') }}</span>
                  ：<span style="color: red">{{ item.schedule_date | date_format }} <v-icon small color="red">mdi-information-outline</v-icon></span></span>
              </template>
              <span>
                利用できない日が設定されています。
                <div class="lang_lbl">{{ $t('view.利用日エラー_1') }}</div>
                <br>
                ご利用開始日を変更してください。
                <div class="lang_lbl">{{ $t('view.利用日エラー_2') }}</div>
              </span>

            </v-tooltip>
          </v-col>
          <v-col
            v-if="item.payment_type == 'one_time_payment'"
            cols="12"
            class="py-0"
          >
            ご利用終了日<span class="lang_lbl">{{ $t('view.ご利用終了日') }}</span>
            ：{{ item.return_schedule_date | date_format }}
          </v-col>
          <v-col
            v-if="!item.is_stock"
            cols="12"
          >
            在庫状況<span class="lang_lbl">{{ $t('view.ご利用終了日') }}</span>
            ：<span style="color: red">在庫なし<span class="lang_lbl">{{ $t('view.在庫なし') }}</span></span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions v-if="isEdit">
        <v-btn
          text
          color="primary"
          @click="moveToProduct(item)"
        >
          <v-icon>mdi-pencil-outline</v-icon>編集
          <div class="lang_lbl">{{$t('view.編集')}}</div>
        </v-btn>
        <v-spacer />
        <v-btn
          text
          color="red"
          @click="onDelete(item)"
        >
          <v-icon>mdi-trash-can-outline</v-icon>削除
          <div class="lang_lbl">{{$t('view.削除')}}</div>
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm ref="confirm" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Confirm from './../../../../components/Confirm'
  import CartStepperMixin from '../../../../mixins/frontend/cart-stepper-mixin'

  export default {
    name: 'OrderProduct',
    components: {
      Confirm,
    },
    mixins: [
      CartStepperMixin
    ],
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        },
      },
      isEdit: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        showOption: false,
      }
    },
    created() {
      // console.log(this.item)
    },
    methods: {
      ...mapActions('frontCart', {
        deleteCart: 'delete'
      }),
      rental_day () {
        if (this.item.product_id) {
          return this.item.rental_days
        } else {
          return this.item.rental_days + '日'
        }
      },
      toNext () {
        this.$emit('toNextStep')
      },
      moveToProduct (cart) {
        this.resetStep()
        this.$router.push({name: 'ProductShow', params: { id: cart.product_id, cart: cart }})
      },
      onDelete (cart) {
        this.$refs.confirm.showDeleteConfirm().then((confirm) => {
          this.$refs.confirm.close()
          if (confirm) {
            // カート削除
            this.deleteCart({id: cart.id}).then(() => {
              // 金額計算、削除があるので再検索
              this.$emit('loadCart')
            })
          }
        })
      }
    },
  }
</script>

<style scoped lang="scss">
</style>
