/**
 * 通信系の定数管理
 */
// 接続先
export const FRONTEND_BASE = '/frontend'
export const FRONTEND_ENDPOINT = `${FRONTEND_BASE}/api/v1`
export const BACKYARD_BASE = '/backyard'
export const BACKYARD_ENDPOINT = `${BACKYARD_BASE}/api/v1`
export const ADMIN_BASE = '/admin'
export const ADMIN_ENDPOINT = `${ADMIN_BASE}/api/v1`
