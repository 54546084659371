/**
 * カテゴリー
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/search/option_groups`

const option_group = {
    namespaced: true,
    state: {
        content: [],
    },
    getters: {},
    actions: {
        fetchAll({ commit }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH).then(data => {
                    let content = data.content
                    commit(types.BACK_SEARCH_OPTION_GROUP, { content })
                })
            })
        }
    },
    mutations: {
        [types.BACK_SEARCH_OPTION_GROUP] (state, { content }) {
            console.log(content)
            state.content = content.option_groups
        },

    },
}

export default option_group
