<template>
  <div>
    <v-card flat>
      <v-card-text>
        <div class="text-right mb-3">
          <v-btn
            color="error"
            :disabled="!canCancelExtend"
            @click="cancelExtendButtonClickEvent"
          >
            期間延長を取り消す
          </v-btn>
        </div>

        <v-card outlined>
          <v-simple-table
            dense
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    品番1/<br v-if="$vuetify.breakpoint.smAndDown">
                    商品名<br v-if="$vuetify.breakpoint.smAndDown">
                    <template v-if="!local_stock_item">/在庫管理番号</template>
                  </th>
                  <th
                    class="text-center"
                  >
                    ご利用開始日<br>
                    ご利用日数
                  </th>
                  <th
                    class="text-center"
                  >
                    出荷日
                  </th>
                  <th
                    class="text-center"
                  >
                    延長日数
                  </th>
                  <th
                    class="text-center"
                  >
                    ご利用終了日
                  </th>
                  <th
                    class="text-center"
                  >
                    返却確認日
                  </th>
                </tr>
              </thead>
              <tbody
                v-for="detail in orderStocks"
                :key="detail.id"
              >

                <tr>
                  <td>
                    {{ detail.part_number_1 }}/
                    <template v-if="$vuetify.breakpoint.smAndDown">
                      <br>
                    </template>
                    {{ detail.product_name }}
                    <template v-if="$vuetify.breakpoint.smAndDown">
                      <br>
                    </template>
                    <template v-if="!detail.is_local_stock_item">
                      /{{ detail.product_stock_no }}
                    </template>
                  </td>
                  <td
                    class="text-center"
                  >
                    {{ detail.schedule_date }}
                    <br>
                    {{ detail.rental_days }}
                  </td>
                  <td
                    class="text-center"
                  >
                    {{ detail.shipping_date || '-' }}
                  </td>
                  <td
                    class="text-center"
                  >
                    {{ detail.extend_rental_days }}
                  </td>
                  <td
                    class="text-center"
                  >
                    {{ detail.return_schedule_date || '-' }}
                  </td>
                  <td
                    class="text-center"
                  >
                    {{ detail.return_confirmed_date || '-' }}
                  </td>
                </tr>
                <tr
                  v-for="orderProductOption in detail.order_product_options"
                  :key="orderProductOption.id"
                >
                  <td>&emsp;{{ orderProductOption.label }}: {{ orderProductOption.name }}</td>
                  <td colspan="5" />
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
        <v-row>
          <v-col>
            <v-pagination
              v-model="page"
              :length="pagination.total_pages"
              :total-visible="totalVisible"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              circle
              @input="changePage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="showExtendCancelDialog"
      width="600"
    >
      <v-form>
        <validation-observer
          v-slot="{ invalid }"
          ref="cancelExtendFormValidation"
        >
          <v-card>
            <v-card-title>期間延長取消</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:512"
                    name="備考"
                    class="requiredMark"
                  >
                    <v-textarea
                      v-model="extendCancelForm.cancelNote"
                      outlined
                      clearable
                      counter="512"
                      label="備考"
                      auto-grow
                      row-height="15"
                      dense
                      hide-details="auto"
                      placeholder=" "
                      required
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="showExtendCancelDialog = false">
                キャンセル
              </v-btn>
              <v-btn
                color="error"
                :disabled="invalid"
                @click="extendCancelRegisterButtonClickEvent"
              >
                期間延長取消
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-dialog>
    <confirm ref="confirm" />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import Confirm from '../../../../components/Confirm'

/**
 * @typedef {Object} orderStocks
 * @property {string} product_name
 * @property {string} product_stock_no
 * @property {string} part_number_1
 * @property {string} shipping_date
 * @property {string} extend_rental_days
 * @property {string} return_confirmed_date
 * @property {array} order_product_options
 */
export default {
  name: 'OrderItemized',
  components: {
    Confirm
  },
  data() {
    return {
      showExtendCancelDialog: false,
      page: 1,
      totalVisible: 7,
      extendCancelForm: {
        cancelNote: null,
      },
      local_stock_item: false,
    }
  },
  computed: {
    ...mapState('backOrderStockHistory', {
      orderStocks: 'order_stocks',
      pagination: 'pagination'
    }),
    ...mapGetters('backOrderStockHistory', [
      'viewPage',
      /** @typedef {boolean} canCancelExtend */
      'canCancelExtend'
    ]),
    hasChecked () {
      return this.checked_list.length > 0
    }
  },
  watch: {
    viewPage (nv) {
      this.page = nv
    }
  },
  created() {
    this.searchOrderDetails()
  },
  methods: {
    ...mapActions('backOrderStockHistory',{
      fetchOrderStocks: 'fetchAll'
    }),
    ...mapActions('backOrderExtendOrderCancel', {
      cancel: 'create'
    }),
    ...mapActions('backOrder', {
      fetchOrder: 'fetch',
    }),
    changePage () {
      this.searchOrderDetails()
    },
    searchOrderDetails () {
      this.fetchOrderStocks({order_id: this.$route.params.id, page: this.page}).then((content) => {
        this.local_stock_item = content.order_stocks[0].is_local_stock_item
      })
    },
    cancelExtendButtonClickEvent () {
      Object.assign(this.$data.extendCancelForm, this.$options.data().extendCancelForm)
      if (this.$refs.cancelExtendFormValidation !== undefined) {
        this.$nextTick(() => this.$refs.cancelExtendFormValidation.reset())
      }
      this.showExtendCancelDialog = true
    },
    extendCancelRegisterButtonClickEvent () {
      let params = { cancel_note: this.extendCancelForm.cancelNote }
      this.cancel({ order_id: this.$route.params.id, params: params }).then(() => {
        this.searchOrderDetails()
        this.fetchOrder({id: this.$route.params.id})
        this.showExtendCancelDialog = false
      })
    }
  }
}
</script>

<style scoped>


</style>