<!-- バックヤード > 会員検索ダイアログ -->
<template>
  <v-dialog
    v-model="$parent.memberSearchDialog"
    width="960"
  >
    <v-card>
      <v-card-title>会員検索</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-expansion-panels
              v-model="memberSearchPanel"
              accordion
              multiple
            >
              <v-expansion-panel>
                <v-expansion-panel-header>検索</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        label="会員番号、氏名、電話番号"
                        hide-details="auto"
                        outlined
                        clearable
                        placeholder=" "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-checkbox
                          v-model="orderDetailFlags"
                          label="受注商品がある"
                          hide-details="auto"
                          value="1"
                          dense
                        ></v-checkbox>
                        &emsp;
                        <v-checkbox
                          v-model="orderDetailFlags"
                          label="発送済商品がある"
                          hide-details="auto"
                          value="2"
                          dense
                        ></v-checkbox>
                        &emsp;
                        <v-checkbox
                          v-model="orderDetailFlags"
                          label="貸出中商品がある"
                          hide-details="auto"
                          value="3"
                          dense
                        ></v-checkbox>
                        &emsp;
                        <v-checkbox
                          v-model="orderDetailFlags"
                          label="貸出延長中商品がある"
                          hide-details="auto"
                          value="4"
                          dense
                        ></v-checkbox>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        class="primary"
                        @click="memberSearchPanel = []"
                      >
                        検索
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-simple-table
              fixed-header
              dense
              height="450px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th class="text-left">会員番号</th>
                    <th class="text-left">会員名</th>
                    <th class="text-left">電話番号</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="member in members"
                    :key="member.id"
                  >
                    <td>
                      <v-checkbox
                        dense
                        hide-details
                        class="pa-0 ma-0"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-avatar>
                        <img
                          :src="member.image"
                          :alt="member.name"
                        />
                      </v-avatar>
                    </td>
                    <td>{{ member.no }}</td>
                    <td>{{ member.name }}</td>
                    <td>{{ member.tel }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="$parent.memberSearchDialog = false">
          キャンセル
        </v-btn>
        <v-btn
          color="primary"
          @click="$parent.memberSearchDialog = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    components: {
    },
    mixins: [
    ],
    data () {
      return {
        memberSearchPanel: [0],
        orderDetailFlags: [],
        members: [
          {id: 1, no: '0001', name: '横浜 太郎', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-1741', mail: 'yokohama@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 2, no: '0002', name: '神奈川 花子', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-2584', mail: 'kanagawa@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 3, no: '0003', name: '川崎 花江', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-1741', mail: 'kawasaki@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 4, no: '0004', name: '湘南 三郎', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '080-1234-5678', mail: 'shonan@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 5, no: '0001', name: '横浜 太郎', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-1741', mail: 'yokohama@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 6, no: '0002', name: '神奈川 花子', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-2584', mail: 'kanagawa@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 7, no: '0003', name: '川崎 花江', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-1741', mail: 'kawasaki@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 8, no: '0004', name: '湘南 三郎', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '080-1234-5678', mail: 'shonan@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 9, no: '0001', name: '横浜 太郎', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-1741', mail: 'yokohama@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 10, no: '0002', name: '神奈川 花子', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-2584', mail: 'kanagawa@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 11, no: '0003', name: '川崎 花江', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-1741', mail: 'kawasaki@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 12, no: '0004', name: '湘南 三郎', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '080-1234-5678', mail: 'shonan@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
        ],
      }
    },
    methods: {
    },
  }
</script>

<style scoped>

</style>