/**
 * HOME 表示
 */
import Vue from 'vue'
import * as httpConst from '../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/home`

const home = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        index ({ _commit }) {
            const api_path = `${API_PATH}`

            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
    },
    mutations: {},
}

export default home