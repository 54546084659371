<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon class="mr-2">
            mdi-comment-alert-outline
          </v-icon>Ticket<span class="subtitle-2 ml-4">店舗への問い合わせ</span>
        </h2>
        <v-divider class="mt-2" />
      </v-col>
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          outlined
          small
          color="primary"
          @click="ticketDialog=true"
        >
          新規お問い合わせ
        </v-btn>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :items="tickets"
          :items-per-page="pagination.limit_value"
          loading-text="読込中"
          no-data-text="店舗への問い合わせはありません。"
          hide-default-footer
          hide-default-header
        >
          <template v-slot:item="{ item }">
            <tr
              style="cursor: pointer"
              @click="showEdit(item)"
            >
              <td>{{ item.created_at | unix_datetime_format }}<div>{{ item.subject }}</div></td>
              <td class="text-right">
                <v-icon>mdi-chevron-right</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-divider class="my-6" />
    <v-pagination
      v-model="page"
      :length="pagination.total_pages"
      :total-visible="totalVisible"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      @input="changePage"
    />

    <!--  チケットダイアログ  -->
    <v-dialog
      v-model="ticketDialog"
      :fullscreen="($vuetify.breakpoint.xsOnly)"
      :transition="($vuetify.breakpoint.xsOnly) ? 'dialog-bottom-transition' : ''"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-subtitle class="pa-0">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="ticketDialog=false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="text-center">
              店舗への問い合わせ
            </v-toolbar-title>
          </v-toolbar>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <validation-observer
                  ref="observer"
                  v-slot="{ invalid }"
                >
                  <v-card-text>
                    <v-row class="pt-4">
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:100"
                          name="件名"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="ticket.subject"
                            outlined
                            label="件名"
                            placeholder="件名を入力してください"
                            counter="100"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:10000"
                          name="問い合わせ内容"
                          class="requiredMark"
                        >
                          <v-textarea
                            v-model="ticket.comment"
                            outlined
                            label="問い合わせ内容"
                            placeholder="問い合わせ内容を入力してください。"
                            value=""
                            counter="10000"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      block
                      outlined
                      :disabled="invalid"
                      @click="submitTicket"
                    >
                      <v-icon>mdi-post-outline</v-icon>
                      投稿する
                    </v-btn>
                  </v-card-actions>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import { mapActions, mapState } from 'vuex'

import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";

export default {
  name: 'MembershipTicketIndex',
  mixins: [
    PageHeaderMixin
  ],
  data () {
    return {
      page: 1,
      totalVisible: 7,
      tickets: [],
      ticket: {
        subject: '',
        comment: '',
      },
      ticketDialog: false,
    }
  },
  computed: {
    ...mapState('frontTicket', {
      pagination: 'pagination',
    })
  },
  created() {
    this.descriptionContent = this.$route.meta.siteSubTitle
    this.pageTitle = this.$route.meta.siteSubTitle
    this.page = this.pagination.current_page
    this.searchCustomerTicket()
  },
  methods: {
    ...mapActions('frontTicket', {
      findTickets: 'findTickets',
      createTicket: 'create',
    }),
    searchCustomerTicket () {
      const params = {
        page: this.page
      }
      this.findTickets({params}).then((content) => {
        this.tickets = content.tickets
      })
    },
    changePage() {
      this.searchCustomerTicket()
    },
    submitTicket() {
      const params = {
        subject: this.ticket.subject,
        comment: this.ticket.comment,
      }
      this.createTicket({ params: params }).then( () => {
        this.ticketDialog = false
        this.searchCustomerTicket()
        this.ticket.subject = null
        this.ticket.comment = null
        this.$refs.observer.reset()
      })
    },
    showEdit(item) {
      this.$router.push({name: 'MembershipTicketEdit', params: { id: item.id }})
    }
  }
}
</script>

<style scoped lang="scss">
</style>