<!-- バックヤード > 商品編集 -->
<template>
  <div v-if="option_group">
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <template>
      <div>
        <v-row>
          <v-col>
            <v-tabs
              v-model="selectedTab"
              background-color="blue lighten-4"
              centered
              icons-and-text
              fixed-tabs
            >
              <v-tabs-slider />

              <v-tab href="#tab-basic">
                基本情報
                <v-icon>mdi-diamond-stone</v-icon>
              </v-tab>

              <v-tab href="#tab-items">
                オプションアイテム
                <v-icon>mdi-image-multiple</v-icon>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab">
              <v-tab-item value="tab-basic">
                <basic-form
                  :is-edit="true"
                  :option-group="option_group"
                />
              </v-tab-item>

              <v-tab-item value="tab-items">
                <option-items
                  :option-group="option_group"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
    </template>
    <confirm ref="confirm" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Confirm from "../../../components/Confirm";
import BasicForm from "./forms/_Basic"
import OptionItems from './forms/_OptionItems'

export default {
  name: "OptionGroupEdit",
  components: {
    Confirm,
    BasicForm,
    OptionItems,
  },
  mixins: [
  ],
  data () {
    return {
      breadcrumbs: [
        { text: 'オプショングループ一覧', disabled: false, to: '/backyard/option_groups', exact: true },
        { text: 'オプショングループ編集', disabled: true, to: '' },
      ],
      option_group: null,
      selectedTab: 'basicTab',
    }
  },
  computed: {
  },
  created() {
    this.editOptionGroup({ id: this.$route.params.id }).then((content) => {
      this.option_group = content
    })
  },
  methods: {
    ...mapActions('backOptionGroup', {
      editOptionGroup: 'edit',
    })
  },
}
</script>

<style lang="scss">

</style>
