<!-- バックヤード > 受注詳細form -->
<template>
  <div v-if="orderDetail">
    <alert-message />
    <v-row>
      <v-col cols="12">
        <div class="text-right">
          <v-btn
            depressed
            :disabled="!orderDetail.can_cancel"
            color="error"
            @click="orderCancelButtonClickEvent"
          >
            受注取消
          </v-btn>
          <v-btn
            depressed
            :disabled="!orderDetail.can_settle"
            color="primary"
            @click="settlementButtonClickEvent"
          >
            決済確定
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-alert
      v-if="orderDetail.can_cancel || orderDetail.can_settle"
      dense
      prominent
      type="warning"
    >
      <ul>
        <li
          v-if="orderDetail.can_settle"
        >
          決済確定は受注日より60日以内に必ず実行してください。
        </li>
        <li
          v-if="orderDetail.can_cancel"
        >
          受注取消は、受注日より180日以内であれば実行可能です。
        </li>
      </ul>
    </v-alert>

    <v-alert
      v-if="orderDetail.status === 'canceled'"
      dense
      prominent
      type="error"
    >
      <h3>キャンセル済みのため編集できません。</h3>
    </v-alert>

    <v-alert
      outlined
      dense
      color="light-blue darken-4"
    >
      受注日：{{ orderDetail.order_date }}&emsp;&emsp;<br v-if="$vuetify.breakpoint.smAndDown">
      注文番号：{{ orderDetail.order_no }}&emsp;&emsp;<br v-if="$vuetify.breakpoint.smAndDown">
      支払方法：{{orderDetail.payment_type_name }}&emsp;&emsp;<br v-if="$vuetify.breakpoint.smAndDown">
      会員名：{{ orderDetail.customer_no }}/{{ orderDetail.customer_name }}&emsp;&emsp;<br v-if="$vuetify.breakpoint.smAndDown">
      <template v-if="orderDetail.delivery_desired_time_value && !orderDetail.is_local_stock_item">
        配送希望時間：{{ orderDetail.delivery_desired_time_value }}&emsp;&emsp;<br v-if="$vuetify.breakpoint.smAndDown">
      </template>
      支払総額：{{ orderDetail.total_payment }}円&emsp;&emsp;<br v-if="$vuetify.breakpoint.smAndDown">
      <template v-if="!orderDetail.is_local_stock_item">
        延長支払総額：{{ orderDetail.extend_total_payment }}円
      </template>
    </v-alert>

    <v-row>
      <v-col>
        <v-tabs
          v-model="orderForm"
          background-color="blue lighten-4"
          centered
          icons-and-text
          fixed-tabs
        >
          <v-tabs-slider />

          <v-tab href="#tab-basic">
            受注情報
            <v-icon>mdi-diamond-stone</v-icon>
          </v-tab>

          <v-tab v-if="!orderDetail.is_local_stock_item" href="#tab-detail">
            商品・配送情報
            <v-icon>mdi-truck</v-icon>
          </v-tab>

          <v-tab href="#tab-itemized">
            受注詳細
            <v-icon>mdi-layers</v-icon>
          </v-tab>

          <v-tab
            v-if="orderDetail.has_subscription"
            href="#tab-subscription"
          >
            定期課金
            <v-icon>mdi-refresh</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="orderForm">
          <v-tab-item value="tab-basic">
            <basic />
          </v-tab-item>

          <v-tab-item value="tab-detail">
            <detail />
          </v-tab-item>

          <v-tab-item
            value="tab-itemized"
            eager
          >
            <itemized />
          </v-tab-item>

          <v-tab-item
            value="tab-subscription"
          >
            <v-card>
              <v-card-text>
                <Subscriptions />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <template>
      <v-dialog
        v-model="showOrderCancelDialog"
        width="600"
      >
        <v-form>
          <validation-observer
            v-slot="{ invalid }"
            ref="cancelFormValidation"
          >
            <v-card>
              <v-card-title>受注取消</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|max:512"
                      name="備考"
                      class="requiredMark"
                    >
                      <v-textarea
                        v-model="orderCancelFrom.cancelNote"
                        outlined
                        clearable
                        counter="512"
                        label="備考"
                        auto-grow
                        row-height="15"
                        dense
                        hide-details="auto"
                        placeholder=" "
                        required
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="showOrderCancelDialog = false">
                  キャンセル
                </v-btn>
                <v-btn
                  color="error"
                  :disabled="invalid"
                  @click="orderCancelRegisterButtonClickEvent"
                >
                  受注取消
                </v-btn>
              </v-card-actions>
            </v-card>
          </validation-observer>
        </v-form>
      </v-dialog>
    </template>
    <Confirm ref="confirm" />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import AlertMessage from '../../../components/AlertMessage'
import Basic from './forms/_Basic'
import Confirm from '../../../components/Confirm'
import Detail from './forms/_Detail'
import Itemized from './forms/_Itemized'
import Subscriptions from './forms/_Subscriptions'
export default {
  components: {
    AlertMessage,
    Confirm,
    Basic,
    Detail,
    Itemized,
    Subscriptions,
  },
  mixins: [],
  data () {
    return {
      orderCancelFrom: {
        cancelNote: null,
      },
      showOrderCancelDialog: false,
      orderForm: 'basicTab',
    }
  },
  computed: {
    /**
     * @typedef orderDetail { Object }
     */
    ...mapGetters('backOrder', [
      'orderDetail'
    ])
  },
  created() {
    this.fetchOrder({id: this.$route.params.id})
    const params = { only_subscription: true }
    /**
     * @typedef {Object} content
     * @property {Array} order_details 受注詳細
     * @property {boolean} can_cancel
     * @property {boolean} can_settle
     * @property {string} order_date
     * @property {number} total_payment
     * @property {number} extend_total_payment
     * @property {boolean} has_subscription
     */
    this.fetchOrderDetail({order_id: this.$route.params.id, params: params}).then((content) => {
      if (content.order_details.length > 0) {
        const first_detail = content.order_details[0]
        this.fetchOrderSubscriptionQuery({order_detail_id: first_detail.id})
        this.fetchOrderSubscriptions({order_id: this.$route.params.id, page: 1})
      }
    })
  },
  methods: {
    ...mapActions('backOrderSubscription', {
      fetchOrderSubscriptions: 'fetchAll',
      fetchOrderSubscriptionQuery: 'fetchQuery'
    }),
    ...mapActions('backOrder', {
      fetchOrder: 'fetch',
      cancelOrder: 'destroy',
      settleOrder: 'update'
    }),
    ...mapActions('backOrderDetail', {
      fetchOrderDetail: 'fetchAll'
    }),
    orderCancelButtonClickEvent () {
      Object.assign(this.$data.orderCancelFrom, this.$options.data().orderCancelFrom)

      if (this.$refs.cancelFormValidation !== undefined) {
        this.$nextTick(() => this.$refs.cancelFormValidation.reset())
      }
      this.showOrderCancelDialog = true
    },
    orderCancelRegisterButtonClickEvent () {
      let params = { cancel_note: this.orderCancelFrom.cancelNote }
      this.cancelOrder({ order_id: this.orderDetail.id, params: params }).then(() => {
        if (this.$route.params.memberId) {
          this.$router.push({name: 'byMemberOrderIndex', params: {memberId: this.$route.params.memberId}})
        } else {
          this.$router.push({name: 'byOrderIndex'})
        }
      })
    },
    settlementButtonClickEvent () {
      this.$refs.confirm.open({ title: '確認', message: '決済確定してもよろしいですか？' }).then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.settleOrder({ id: this.orderDetail.id }).then(() => {
            this.fetchOrder({ id: this.orderDetail.id })
            this.fetchOrderSubscriptions({order_id: this.$route.params.id, page: 1})
          })
        }
      })
    }
  },
}
</script>

<style lang="scss">
  .v-window-item > .v-card
  {
    min-height:300px;
  }
</style>
