var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert-message'),_vm._v(" "),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.myPage)?_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:50","name":"タイトル"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"タイトル","dense":"","hide-details":"auto","outlined":"","required":"","counter":"50","placeholder":" ","error-messages":errors},model:{value:(_vm.myPage.title),callback:function ($$v) {_vm.$set(_vm.myPage, "title", $$v)},expression:"myPage.title"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|alpha_dash|max:50","name":"パーマリンク（URL）"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"パーマリンク（URL）","dense":"","hide-details":"auto","outlined":"","required":"","counter":"50","placeholder":" ","error-messages":errors},model:{value:(_vm.myPage.post_name),callback:function ($$v) {_vm.$set(_vm.myPage, "post_name", $$v)},expression:"myPage.post_name"}})]}}],null,true)})],1)],1),_vm._v(" "),(_vm.myPage.description_meta_tag_attributes)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("独自メタ情報")]),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'text-right',attrs:{"cols":"12","md":"1"}},[_vm._v("\n                          "+_vm._s(_vm.myPage.description_meta_tag_attributes.meta_name)+"\n                        ")]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('validation-provider',{attrs:{"rules":"max:140","name":"説明"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","counter":"140","label":"","dense":"","hide-details":"auto","placeholder":" ","error-messages":errors},model:{value:(_vm.myPage.description_meta_tag_attributes.content),callback:function ($$v) {_vm.$set(_vm.myPage.description_meta_tag_attributes, "content", $$v)},expression:"myPage.description_meta_tag_attributes.content"}})]}}],null,true)})],1)],1)],1)],1)],1)],1):_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"表示順","rules":"required|numeric|min_value:0|max_value:2147483647"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"表示順","dense":"","reverse":"","hide-details":"auto","outlined":"","placeholder":" ","required":"","error-messages":errors},model:{value:(_vm.myPage.position),callback:function ($$v) {_vm.$set(_vm.myPage, "position", $$v)},expression:"myPage.position"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{staticClass:"pt-2 ma-0",attrs:{"label":"フッターに表示する","hide-details":"auto"},model:{value:(_vm.myPage.display_footer),callback:function ($$v) {_vm.$set(_vm.myPage, "display_footer", $$v)},expression:"myPage.display_footer"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"max:65535","name":"詳細"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('vue-editor',{staticClass:"custom-quill-container",attrs:{"error-messages":errors,"useCustomImageHandler":""},on:{"image-added":_vm.handleImageAdded},model:{value:(_vm.myPage.description),callback:function ($$v) {_vm.$set(_vm.myPage, "description", $$v)},expression:"myPage.description"}})],1)]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteButtonClickEvent}},[_vm._v("\n                削除\n              ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.cancelButtonClickEvent}},[_vm._v("\n                キャンセル\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.registerButtonClickEvent}},[_vm._v("\n                保存\n              ")])],1)],1)],1):_vm._e()]}}])})],1)],1),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }