/**
 * フッター構成
 */
import Vue from "vue";
import * as types from "../../../mutation-types/frontend-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/configurations/footer_option`

const config_footer_option = {
    namespaced: true,
    state: {
        theme: [],

    },
    getters: {
    },
    actions: {
        fetch({ commit }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH).then(data => {
                    let content = data.content
                    commit(types.FRONT_FOOTER, { content })
                    resolve(content)
                })
            })
        },
    },
    mutations: {
        [types.FRONT_FOOTER] (state, { content }) {
            state.theme = content.theme
        },
    },
}

export default config_footer_option
