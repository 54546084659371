<!-- バックヤード > 現地マスタ編集 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />

    <local-place-form
      v-if="local_place"
      :local_place="local_place"
      :is-edit="true"
    />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import {mapActions} from "vuex";
  import LocalPlaceForm from './_Form'

  export default {
    name: 'LocalPlaceEdit',
    components: {
      LocalPlaceForm
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        breadcrumbs: [
          { text: '現地一覧', disabled: false, to: '/backyard/local_places', exact: true },
          { text: '現地編集', disabled: true, to: '' },
        ],
        local_place: null,
      }
    },
    created() {
      this.editLocalPlace({id: this.$route.params.id}).then((content) => {
        this.local_place = content
      })
    },
    methods: {
      ...mapActions('backLocalPlace', {
        editLocalPlace: 'edit'
      })
    },
  }
</script>

<style lang="scss">

</style>
