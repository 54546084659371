<!-- バックヤード > 配送業者・時間編集 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />

    <tab
      v-if="deliveryCompany"
      :delivery-company="deliveryCompany"
    />
    <confirm ref="confirm" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Confirm from '../../../components/Confirm'
  import Tab from './forms/_Tab'

  export default {
    components: {
      Confirm,
      Tab,
    },
    mixins: [],
    data () {
      return {
        // パンくず
        breadcrumbs: [
          { text: '配送業者一覧', disabled: false, to: '/backyard/deliveryCompanies', exact: true },
          { text: '配送業者・配送時間編集', disabled: true, to: '' },
        ],
        deliveryCompany: null,
      }
    },
    created() {
      // 配送業者情報取得
      this.editDeliveryCompany({id: this.$route.params.id}).then((content) => {
        this.deliveryCompany = content
      })
    },
    methods: {
      ...mapActions('backDeliveryCompany', {
        editDeliveryCompany: 'edit'
      })
    },
  }
</script>

<style scoped lang="scss">
</style>
