var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("\n          mdi-comment-alert-outline\n        ")]),_vm._v("Ticket"),_c('span',{staticClass:"subtitle-2 ml-4"},[_vm._v("店舗への問い合わせ")])],1),_vm._v(" "),_c('v-divider',{staticClass:"mt-2"})],1),_vm._v(" "),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){_vm.ticketDialog=true}}},[_vm._v("\n        新規お問い合わせ\n      ")])],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.tickets,"items-per-page":_vm.pagination.limit_value,"loading-text":"読込中","no-data-text":"店舗への問い合わせはありません。","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showEdit(item)}}},[_c('td',[_vm._v(_vm._s(_vm._f("unix_datetime_format")(item.created_at))),_c('div',[_vm._v(_vm._s(item.subject))])]),_vm._v(" "),_c('td',{staticClass:"text-right"},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)])]}}])})],1)],1),_vm._v(" "),_c('v-divider',{staticClass:"my-6"}),_vm._v(" "),_c('v-pagination',{attrs:{"length":_vm.pagination.total_pages,"total-visible":_vm.totalVisible,"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right"},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_vm._v(" "),_c('v-dialog',{attrs:{"fullscreen":(_vm.$vuetify.breakpoint.xsOnly),"transition":(_vm.$vuetify.breakpoint.xsOnly) ? 'dialog-bottom-transition' : '',"scrollable":"","max-width":"600px"},model:{value:(_vm.ticketDialog),callback:function ($$v) {_vm.ticketDialog=$$v},expression:"ticketDialog"}},[_c('v-card',[_c('v-card-subtitle',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.ticketDialog=false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "),_c('v-toolbar-title',{staticClass:"text-center"},[_vm._v("\n            店舗への問い合わせ\n          ")])],1)],1),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',[_c('v-row',{staticClass:"pt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:100","name":"件名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"件名","placeholder":"件名を入力してください","counter":"100","error-messages":errors,"required":""},model:{value:(_vm.ticket.subject),callback:function ($$v) {_vm.$set(_vm.ticket, "subject", $$v)},expression:"ticket.subject"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:10000","name":"問い合わせ内容"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"問い合わせ内容","placeholder":"問い合わせ内容を入力してください。","value":"","counter":"10000","error-messages":errors,"required":""},model:{value:(_vm.ticket.comment),callback:function ($$v) {_vm.$set(_vm.ticket, "comment", $$v)},expression:"ticket.comment"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","block":"","outlined":"","disabled":invalid},on:{"click":_vm.submitTicket}},[_c('v-icon',[_vm._v("mdi-post-outline")]),_vm._v("\n                    投稿する\n                  ")],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }