<!-- バックヤード > 商品詳細 > 発送時同封書類 -->
<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="showNewModal"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="productDocuments"
                :headers="tableHeaders"
                :items="productDocuments"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="error"
                    @click="documentDeleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="documentDeleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="isShowDocumentDialog"
      width="450"
      persistent
      no-click-animation
      @close="closeDocumentDialog"
    >
      <v-form>
        <validation-observer
          v-slot="{ invalid }"
          ref="documentFormValidation"
        >
          <v-card>
            <v-card-title>同封書類</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="PDFファイル"
                    rules="required|mimes:application/pdf"
                  >
                    <v-row
                      class="text-center"
                      @dragover.prevent
                      @dragenter="onDragEnter"
                      @dragleave="onDragLeave"
                      @drop="onDrop"
                    >
                      <v-col>
                        <v-file-input
                          v-model="productDocument.file"
                          :background-color="isDragging ? 'blue' : 'null'"
                          :error-messages="errors"
                          :show-size="1000"
                          accept="application/pdf"
                          append-icon="mdi-folder"
                          counter
                          dense
                          label="PDFファイル"
                          outlined
                          persistent-hint
                          placeholder=" "
                          prepend-icon=""
                          required
                          @change="documentFileChangeEvent"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>
                            <span
                              v-else-if="index === 2"
                              class="overline grey--text text--darken-3 mx-2"
                            >+{{ files.length - 2 }} File(s)</span>
                          </template>
                        </v-file-input>
                      </v-col>
                    </v-row>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="ファイル名"
                    rules="required|max:30"
                  >
                    <v-text-field
                      v-model="productDocument.name"
                      :error-messages="errors"
                      counter="30"
                      dense
                      hide-details="auto"
                      label="ファイル名"
                      outlined
                      placeholder=" "
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeDocumentDialog">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="documentDialogRegisterButtonClickEvent"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-dialog>
    <confirm ref="confirm" />
  </div>
</template>

<script>

import Confirm from '../../../../components/Confirm'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    Confirm
  },
  mixins: [
  ],
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      tableHeaders: [
        { value: 'id', text: 'ID', align: 'start' },
        { value: 'name', text: 'ファイル名' },
        { value: 'editable', text: '', align: 'end' },
      ],
      isShowDocumentDialog: false,
      productDocument: {
        file: null,
        name: null
      },
      isDragging: false,
      dragCount: 0,
    }
  },
  computed: {
    ...mapState('backProductDocument', {
      productDocuments: 'product_documents'
    })
  },
  created () {
    this.fetchProductDocuments({product_id: this.product.id})
  },
  methods: {
    ...mapActions('backProductDocument', {
      fetchProductDocuments: 'fetchAll',
      createProductDocument: 'create',
      updateProductDocument: 'update',
      destroyProductDocument: 'destroy'
    }),
    showNewModal () {
      Object.assign(this.$data.productDocument, this.$options.data().productDocument)
      this.isShowDocumentDialog = true
    },
    showEditModal (product_document) {
      Object.assign(this.productDocument, product_document)
      this.isShowDocumentDialog = true
    },
    documentDialogRegisterButtonClickEvent () {
      const params = new FormData()

      if (this.productDocument.file) {
        params.append('file', this.productDocument.file)
      }
      params.append('name', this.productDocument.name)

      if (this.productDocument.id) {
        params.append('id', this.productDocument.id)
        this.updateProductDocument({ product_id: this.product.id, params: params }).then(() => {
          this.fetchProductDocuments({product_id: this.product.id})
        })
      } else {
        this.createProductDocument({product_id: this.product.id, params: params}).then(() => {
          this.fetchProductDocuments({product_id: this.product.id})
        })
      }
      this.isShowDocumentDialog = false
    },
    documentDeleteButtonClickEvent (product_document) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyProductDocument({ product_id: this.product.id, params: product_document }).then(() => {
            this.fetchProductDocuments({product_id: this.product.id})
          })
        }
      })
    },
    documentFileChangeEvent () {
      if (this.productDocument.file) {
        this.productDocument.name = this.productDocument.file.name
      }
    },
    closeDocumentDialog () {
      Object.assign(this.$data.productDocument, this.$options.data().productDocument);
      this.$nextTick(() => this.$refs.documentFormValidation.reset())
      this.isShowDocumentDialog = false
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const _files = e.dataTransfer.files;
      this.productDocument.file = _files[0];
    },

    onDragEnter(e) {
      e.preventDefault();
      this.isDragging = true;
      this.dragCount++;
    },

    onDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) {
        this.isDragging = false;
      }
    }
  },
}
</script>

<style lang="scss">

</style>
