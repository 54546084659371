<template>
  <v-container>
    <v-stepper v-model="stepper">
      <v-stepper-header>
        <v-stepper-step
          step="1"
          :complete="stepper > 1"
        >
          お申し込み情報
          <div class="lang_lbl mt-1">{{$t('view.お申し込み情報')}}</div>
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          step="2"
          :complete="stepper > 2"
          v-if="contentCart.is_local_stock_item"
        >
          お支払い方法
          <div class="lang_lbl mt-1">{{$t('view.お支払い方法')}}</div>
        </v-stepper-step>
        <v-stepper-step
          step="2"
          :complete="stepper > 2"
          v-else
        >
          お支払いと配送方法
          <div class="lang_lbl mt-1">{{$t('view.お支払いと配送方法')}}</div>
        </v-stepper-step>
        <v-divider />
        <template v-if="content.use_agree_terms">
          <v-stepper-step
            step="3"
            :complete="stepper > 3"
          >
            利用規約同意
            <div class="lang_lbl mt-1">{{$t('view.利用規約同意')}}</div>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            step="4"
            :complete="stepper > 4"
          >
            注文確認
            <div class="lang_lbl mt-1">{{$t('view.注文確認')}}</div>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            step="5"
            :complete="stepper > 4"
          >
            注文完了
            <div class="lang_lbl mt-1">{{$t('view.注文完了')}}</div>
          </v-stepper-step>
        </template>
        <template v-else>
          <v-stepper-step
            step="3"
            :complete="stepper > 3"
          >
            注文確認
            <div class="lang_lbl mt-1">{{$t('view.注文確認')}}</div>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            step="4"
            :complete="stepper > 3"
          >
            注文完了
            <div class="lang_lbl mt-1">{{$t('view.注文完了')}}</div>
          </v-stepper-step>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <order-summary @toNextStep="nextStep" />
        </v-stepper-content>
        <v-stepper-content step="2">
          <order-payment
            @toNextStep="nextStep"
            @toBackStep="backStep"
          />
        </v-stepper-content>
        <template v-if="content.use_agree_terms">
          <v-stepper-content step="3">
            <order-terms
              v-if="stepper===3"
              @toNextStep="nextStep"
              @toBackStep="backStep"
              @toBackPayment="toOrderPayment"
            />
          </v-stepper-content>
          <v-stepper-content step="4">
            <order-confirm
              v-if="stepper===4"
              @toNextStep="nextStep"
              @toBackStep="backStep"
              @toBackPayment="toOrderPayment"
            />
          </v-stepper-content>
          <v-stepper-content step="5">
            <order-complete />
          </v-stepper-content>
        </template>
        <template v-else>
          <v-stepper-content step="3">
            <order-confirm
              v-if="stepper===3"
              @toNextStep="nextStep"
              @toBackStep="backStep"
              @toBackPayment="toOrderPayment"
            />
          </v-stepper-content>
          <v-stepper-content step="4">
            <order-complete />
          </v-stepper-content>
        </template>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
  import OrderSummary from './forms/_Summary'
  import OrderPayment from './forms/_Payment'
  import OrderConfirm from './forms/_Confirm'
  import OrderComplete from './forms/_Complete'
  import OrderTerms from './forms/_Terms'
  import CartStepperMixin from '../../../mixins/frontend/cart-stepper-mixin'
  import {mapActions, mapState} from "vuex";
  import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";

  export default {
    name: 'CartsIndex',
    components: {
      OrderSummary,
      OrderPayment,
      OrderConfirm,
      OrderComplete,
      OrderTerms,
    },
    mixins: [
      CartStepperMixin,
      PageHeaderMixin
    ],
    data () {
      return {
        pageTitle: null,
        delivery: null,
      }
    },
    computed: {
      ...mapState('frontCredence', {
        content: 'content',
      }),
      ...mapState('frontCartInside', {
        contentCart: 'content',
      }),
    },
    created() {
      this.descriptionContent = this.$route.meta.siteSubTitle
      this.pageTitle = this.$route.meta.siteSubTitle
      this.fetch()
      this.fetchCartInside()
      this.fetchCompany();
      this.fetchThemeOption();
    },
    methods: {
      ...mapActions('frontCredence', {
        fetch: 'fetch'
      }),
      ...mapActions('frontCartInside', {
        fetchCartInside: 'fetch'
      }),
      ...mapActions("frontCompany", {
        fetchCompany: "fetch",
      }),
      ...mapActions("frontThemeOption",{
        fetchThemeOption: "fetch",
      })
    },
  }
</script>

<style scoped lang="scss">
</style>