<!-- バックヤード > 会員詳細 > 受注履歴一覧 -->
<!-- バックヤード > 受注一覧 -->
<template>
  <div>
    <v-card flat>
      <v-card-title>
        受注履歴
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="orderTableHeaders"
                :items="orders"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:header.count_order>
                  受注<br>件数
                </template>
                <template v-slot:header.count_ship>
                  発送済<br>件数
                </template>
                <template v-slot:header.count_return>
                  返却済<br>件数
                </template>
                <template v-slot:item.total_payment="{ item }">
                  {{ item.total_payment | comma_format }} 円
                </template>
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="cyan darken-2"
                    @click="showButtonClickEvent(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="primary"
                    small
                    @click="showButtonClickEvent(item)"
                  >
                    参照する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
            <v-row>
              <v-col>
                <v-pagination
                  v-model="page"
                  :length="pagination.total_pages"
                  :total-visible="totalVisible"
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
                  circle
                  @input="changePage"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import { mapActions, mapState} from 'vuex'

export default {
  name: 'OrderIndex',
  components: {},
  mixins: [],
  props: {},
  data () {
    return {
      page: 1,
      totalVisible: 7,
      orderTableHeaders: [
        { value: 'order_at', text: '受注日', align: 'center' },
        { value: 'order_no', text: '注文番号' },
        { value: 'total_payment', text: '総額', align: 'right' },
        { value: 'schedule_date', text: 'ご利用開始日', align: 'center' },
        { value: 'count_order', text: '受注件数', align: 'right' },
        { value: 'count_ship', text: '発送済件数', align: 'right' },
        { value: 'count_return', text: '返却済件数', align: 'right' },
        { value: 'settle_date', text: '決済/入金確認日', align: 'center' },
        { value: 'status_name', text: 'ステータス' },
        { value: 'editable', text: '', align: 'end' },
      ],
      customer_id: null,
      orders: [],
    }
  },
  computed: {
    ...mapState('backOrder', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
  },
  created () {
    const searchCustomerId = this.$route.params.id
    if (searchCustomerId) {
      this.customer_id = searchCustomerId
    }
    this.page = this.pagination.current_page
    this.searchOrders()
  },
  methods: {
    ...mapActions('backOrder', {
      fetchOrders: 'fetchAll',
      fetchQuery: 'fetchQuery',
      settleOrders: 'create',
      resetQuery: 'resetQueries'
    }),
    showButtonClickEvent (order) {
      this.$router.push({name: 'byOrderShow', params: { id: order.id }})
    },
    resetOrders () {
      this.page = 1
      this.searchOrders()
    },
    changePage () {
      this.searchOrders()
    },
    searchOrders () {
      const params = {
        customer_id: this.customer_id,
        page: this.page
      }
      this.fetchOrders(params).then((content) => {
        this.orders = content.orders
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
