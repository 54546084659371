<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon class="mr-2">
            mdi-credit-card
          </v-icon>Payment<span class="subtitle-2 ml-4">お支払い方法</span>
          <span class="lang_lbl">{{ $t('view.お支払い方法') }}</span>
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            align="end"
          >
            <payjp-checkout
              :api-key="public_key"
              :text="'カードを追加 (' + $t('view.カードを追加') + ')' "
              submit-text="カードを追加"
              name-placeholder="TARO RENTAL"
              @created="onTokenCreated"
              @failed="onTokenFailed"
            />
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="cardHeaders"
              :items="cards"
              :loading-text="'読込中(' + $t('view.読込中') + ')' "
              :no-data-text="'カード情報はありません。(' + $t('view.カード情報はありません') + ')' "

              hide-default-footer
            >
              <template v-slot:item.default_card="{ item }">
                <v-icon v-if="item.default_card == true">
                  mdi-check-outline
                </v-icon>
              </template>
              <template v-slot:item.last4="{ item }">
                {{ item.last4 | card_number_padding }}
              </template>
              <template v-slot:item.expiration_date="{ item }">
                {{ item.exp_month | card_expiration_date(item.exp_year) }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  small
                  outlined
                  @click="showCardEditDialog(item)"
                >
                  編集
                  <span class="lang_lbl">{{ $t('view.編集') }}</span>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <template>
      <v-dialog
        v-model="isCardEditDialog"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        :transition="($vuetify.breakpoint.xsOnly) ? 'dialog-bottom-transition' : ''"
        scrollable
        width="600"
      >
        <v-form>
          <v-card>
            <v-card-subtitle class="pa-0">
              <v-toolbar
                dark
                color="primary"
              >
                <v-btn
                  icon
                  dark
                  @click="isCardEditDialog=false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title align="center">
                  お支払いカード編集
                  <span class="lang_lbl">{{ $t('view.お支払いカード') + $t('view.編集') }}</span>
                </v-toolbar-title>
              </v-toolbar>
            </v-card-subtitle>
            <v-card-text>
              <v-divider />
              <v-simple-table v-if="selectedCard">
                <tbody>
                  <tr>
                    <th>
                      通常使うカード
                      <span class="lang_lbl">{{ $t('view.通常使うカード') }}</span>
                    </th>
                    <td>
                      <v-icon v-if="selectedCard.default_card == true">
                        mdi-check-outline
                      </v-icon>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      ブランド
                      <span class="lang_lbl">{{ $t('view.カード番号の下４桁') }}</span>
                    </th>
                    <td>{{ selectedCard.brand }}</td>
                  </tr>
                  <tr>
                    <th>
                      カード番号の下四桁
                      <span class="lang_lbl">{{ $t('view.ブランド') }}</span>
                    </th>
                    <td>{{ selectedCard.last4 }}</td>
                  </tr>
                  <tr>
                    <th>
                      有効期限
                      <span class="lang_lbl">{{ $t('view.有効期限') }}</span>
                    </th>
                    <td>{{ selectedCard.exp_month | card_expiration_date(selectedCard.exp_year) }}</td>
                  </tr>
                  <tr>
                    <th>
                      保有者名
                      <span class="lang_lbl">{{ $t('view.保有者名') }}</span>
                    </th>
                    <td>{{ selectedCard.name }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-divider />
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    outlined
                    block
                    @click="onEditDefaultCard"
                  >
                    通常使うカードに設定
                    <span class="lang_lbl">{{ $t('view.通常使うカードに設定') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-btn
                    color="red"
                    outlined
                    block
                    @click="onDeleteCard"
                  >
                    このカードを削除する
                    <span class="lang_lbl">{{ $t('view.このカードを削除する') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-dialog>
    </template>
    <confirm ref="confirm" />
  </v-container>
</template>

<script>

import { mapActions } from 'vuex'
import Confirm from './../../../../components/Confirm'

import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";

export default {
  name: 'MembershipPaymentEdit',
  components: {
    Confirm,
  },
  mixins: [
    PageHeaderMixin
  ],
  data () {
    return {
      public_key: process.env.PAY_JP_PUBLIC_KEY,
      cardHeaders: [
        { text: "通常使うカード（" + this.$i18n.t('view.通常使うカード') +"）" , align: 'center', sortable: false, value: 'default_card' },
        { text: "ブランド（" + this.$i18n.t('view.ブランド') +"）", align: 'start', sortable: false, value: 'brand' },
        { text: "カード番号の下四桁（" + this.$i18n.t('view.カード番号の下４桁') +"）", align: 'center', sortable: false, value: 'last4' },
        { text: "有効期限（" + this.$i18n.t('view.有効期限') +"）", align: 'center', sortable: false, value: 'expiration_date' },
        { text: "保有者名（" + this.$i18n.t('view.保有者名') +"）", align: 'start', sortable: false, value: 'name' },
        { text: '', align: 'end', sortable: false, value: 'action' },
      ],
      cards: [],
      selectedCard: null,
      isCardEditDialog: false,
    }
  },
  created() {
    this.descriptionContent = this.$route.meta.siteSubTitle
    this.pageTitle = this.$route.meta.siteSubTitle
    this.loadCards()
  },
  beforeDestroy () {
    window.PayjpCheckout = null
  },
  methods: {
    ...mapActions('frontCard', {
      createCard: 'create',
      updateCard: 'update',
      deleteCard: 'delete',
      findCards: 'findCards'
    }),
    onTokenCreated (response) {
      // カードのToken化に成功
      const params = {
        card_token: response.id,
      }
      this.createCard({params}).then(() => {
        this.loadCards()
      })
    },
    onTokenFailed (status, err) {
      // Token化に失敗
      console.log(status)
      console.log(err)
    },
    loadCards() {
      this.findCards().then( (content) => {
        // 選択用にindexを付与
        let indexCards = content.cards.map((item) => ({
          action: '',
          ...item
        }))
        this.cards = indexCards
      })
    },
    showCardEditDialog (item) {
      this.selectedCard = item
      this.isCardEditDialog = true
    },
    onEditDefaultCard () {
      const params = this.buildSelectedCardParams()
      this.updateCard({params}).then(() => {
        // 一覧データ再取得
        this.loadCards()
        this.isCardEditDialog = false
      })
    },
    onDeleteCard () {

      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          const params = this.buildSelectedCardParams()
          // 削除
          this.deleteCard({params}).then(() => {
            // 一覧データ再取得
            this.loadCards()
            this.isCardEditDialog = false
          })
        }
      })
    },
    buildSelectedCardParams () {
      return {
        card_token: this.selectedCard.card_token
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>