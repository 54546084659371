<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon class="mr-2">
            mdi-cart
          </v-icon>
          {{$t('view.注文履歴')}}
          <span class="subtitle-2 ml-4">注文履歴</span>
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <v-row
      v-if="isOrder"
      dense
    >
      <v-col
        v-for="item in orders"
        :key="item.id"
        cols="12"
      >
        <v-card outlined>
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="4"
                class="text-center"
              >
                <v-avatar
                  tile
                  size="80"
                >
                  <v-img
                    :src="item.image_url | load_image_url"
                    :lazy-src="item.image_url | load_image_url"
                  />
                </v-avatar>
              </v-col>
              <v-col
                cols="8"
                class="pb-1"
              >
                <div class="subtitle-2">
                  注文番号<span class="lang_lbl">{{$t('view.注文番号')}}</span>
                  ：{{ item.order_no }}
                </div>
                <div class="subtitle-2">
                  注文日<span class="lang_lbl">{{$t('view.注文日')}}</span>
                  ：{{ item.order_date | date_format }}
                </div>
                <div class="subtitle-2">
                  注文金額<span class="lang_lbl">{{$t('view.注文金額')}}</span>
                  ：{{ item.total_amount | yen_format }}
                </div>
                <div class="caption">
                  {{ item.status | statusFilter(item.local_stock_item) }}
                </div>
                <br>
                <div class="subtitle-2" v-if="!item.local_stock_item">
                  お届け先住所<span class="lang_lbl">{{$t('view.お届け先住所')}}</span>
                  <br>
                  <span style="margin-left:14px">{{ item.delivery_name }}</span><br>
                  <span style="margin-left:14px">〒 {{ item.delivery_zip_code }}</span><br>
                  <span style="margin-left:14px">{{ item.delivery_prefecture_name }}{{ item.delivery_address }}</span><br>
                </div>
                <div class="subtitle-2" v-else>
                  <span v-if="item.payment_type=='sale_payment'">
                    ※現地販売<span class="lang_lbl">{{$t('view.現地販売')}}</span>
                  </span>
                  <span v-else>
                    ※現地貸出し<span class="lang_lbl">{{$t('view.現地貸出し')}}</span>
                  </span>
                  <br>
                  <span style="margin-left:14px">{{ item.receive_comment }}</span><br>
                </div>

              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="item.can_extend"
              text
              small
              color="primary"
              @click="indexExtendOrders(item.id)"
            >
              <v-icon>mdi-shape-rectangle-plus</v-icon>
              期間延長<span class="lang_lbl">{{$t('view.期間延長')}}</span>
            </v-btn>
            <v-btn
              v-if="item.can_show_extend_receipt"
              text
              small
              color="primary"
              @click="outputReceiptButtonClickEvent(item, 'extend')"
            >
              <v-icon>mdi-cloud-print-outline</v-icon>
              期間延長領収書<span class="lang_lbl">{{$t('view.期間延長領収書')}}</span>
            </v-btn>
            <v-btn
              v-if="((item.status==='shipped' && !item.include_subscription) || item.local_stock_item) && item.status !== 'canceled'"
              text
              small
              color="primary"
              @click="outputReceiptButtonClickEvent(item, 'order')"
            >
              <v-icon>mdi-cloud-print-outline</v-icon>
              領収書<span class="lang_lbl">{{$t('view.領収書')}}</span>
            </v-btn>
            <v-btn
              text
              small
              color="primary"
              @click="showOrderDetails(item.id)"
            >
              <v-icon>mdi-cart-outline</v-icon>
              注文詳細<span class="lang_lbl">{{$t('view.注文詳細')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        v-if="isOrder"
        cols="12"
      >
        <v-divider />
        <div class="text-center mt-6">
          <v-pagination
            v-model="page"
            :length="pagination.total_pages"
            :total-visible="totalVisible"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            @input="changePage"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        class="text-center"
      >
        注文履歴がありません。
        <span class="lang_lbl">{{$t('view.注文履歴がありません')}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";
import store from 'store'
import * as appConst from "../../../../constants/appConst";

  export default {
    name: 'MembershipHistoryIndex',
    components: {
    },
    filters: {
      statusFilter(status, isLocalStockItem) {
        if (status === 'canceled') {
          return 'キャンセル済'
        } else if (status === 'shipped') {
          if (isLocalStockItem) {
            return ''
          } else {
            return '出荷済'
          }
        } else {
          if (isLocalStockItem) {
            return ''
          } else {
            return '出荷準備中'
          }
        }
      }
    },
    mixins: [
      PageHeaderMixin
    ],
    data () {
      return {
        page: 1,
        totalVisible: 7,
        orders: null,
      }
    },
    computed: {
      ...mapState('frontOrder', {
        pagination: 'pagination',
      }),
      ...mapGetters('frontOrder', [
        'isOrder'
      ])
    },
    created() {
      store.set(appConst.FRONT_STORE_CART_STEPPER, 1)
      this.resetExtendState()
      this.descriptionContent = this.$route.meta.siteSubTitle
      this.pageTitle = this.$route.meta.siteSubTitle
      this.loadOrders()
    },
    methods: {
      ...mapActions('frontOrder', {
        findHistories: 'findHistories',
      }),
      ...mapActions('frontExtendOrder', {
        resetExtendState: 'resetExtendState'
      }),
      indexExtendOrders(item) {
        // 期間延長
        this.$router.push({name: 'MembershipExtendIndex', params: { id: item }})
      },
      showOrderDetails (item) {
        // 注文詳細へ
        this.$router.push({name: 'MembershipHistoryShow', params: { id: item }})
      },
      loadOrders () {
        const params = {
          page: this.page
        }
        this.findHistories({ params }).then((content) => {
          this.orders = content.orders
        })
      },
      /**
       * 領収書 クリックイベント
       */
      outputReceiptButtonClickEvent (item, type) {
        let link = document.createElement('a')
        if (type == 'extend') {
          link.href = item.extend_receipt_url
        } else {
          link.href = item.receipt_file_url
        }

        // link.download = 'ファイル名.pdf'  ←これを指定するとファイルダウンロードになる.
        link.target = '_blank'
        link.click()
      },
      changePage () {
        this.loadOrders()
      },
    },
  }
</script>

<style scoped lang="scss">
</style>