<!-- バックヤード > 会員お知らせ追加 -->
<template>
  <div>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn @click="noticeMemberCancel">
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            @click="noticeMemberSave"
          >
            保存
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <Form />

    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn @click="noticeMemberCancel">
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            @click="noticeMemberSave"
          >
            保存
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import Form from "./_Form";

  export default {
    components: {
      Form,
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
      }
    },
    methods: {
      noticeMemberCancel: function () {
        this.$router.push({name: 'byNoticeMemberIndex'})
      },
      noticeMemberSave: function () {
        this.$router.push({name: 'byNoticeMemberIndex'})
      },
    },
  }
</script>

<style lang="scss">

</style>
