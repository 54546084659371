asw<template>
  <v-container
    fill-height
  >
    <v-card
      v-if="permitted"
      outlined
      class="mx-auto"
      style="width: 40rem;"
    >
      <v-card-text>
        <p>
          定期課金に使用されているクレジットカードが使用できませんでした。<br>
          お支払い日にお引き落としができませんでした。<br>
          引き落とし可能なクレジットカードを再度登録ください。
        </p>

        <v-row v-if="has_card">
          <v-col cols="12">
            <h4>ご利用中のカード</h4>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  ブランド
                </div>
                <h4>{{ card.brand }}</h4>
                <v-divider />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  有効期限
                </div>
                <h4>{{ card.exp }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  カード番号
                </div>
                <h4>{{ card.num }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  名義人
                </div>
                <h4>{{ card.name }}</h4>
                <v-divider />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            align="end"
          >
            <payjp-checkout
              :api-key="public_key"
              text="カードを差し替え"
              submit-text="カードを差し替え"
              name-placeholder="TARO RENTAL"
              v-on:created="onTokenCreated"
              v-on:failed="onTokenFailed"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <template
      v-else
    >
      <v-row>
        <v-col
          style="width: 40rem;"
          class="mx-auto"
        >
          <alert-message />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import alertMessage from '../../../components/AlertMessage'
export default {
  name: 'CardSwitchShow',
  components: {
    alertMessage
  },
  mixins: [],
  data () {
    return {
      public_key: process.env.PAY_JP_PUBLIC_KEY,
      token: null,
      permitted: false,
      has_card: false,
      card: {
        brand: null,
        exp: null,
        num: null,
        name: null,
      }
    }
  },
  created () {
    this.signOut()
    this.token = this.$route.params.token
    this.fetchCard({token: this.token}).then((content) => {
      this.permitted = true
      this.has_card = content.has_card
      this.card = content.card
    })
  },
  methods: {
    ...mapActions('frontCardSwitch', {
      fetchCard: 'fetch',
      updateCard: 'update'
    }),
    ...mapActions('frontAuth', {
      signOut: 'signOutFrontend',
    }),
    onTokenCreated (response) {
      // カードのToken化に成功
      const params = {
        card_token: response.id,
      }
      this.updateCard({ token: this.token, params }).then(() => {
        this.$router.push({name: 'CardSwitchCompleted'})
      })
    },
    onTokenFailed (status, err) {
      // Token化に失敗
      console.log(status)
      console.log(err)
    },
  },


}
</script>

<style scoped>

</style>