<!-- バックヤード > システム問い合わせform -->
<template>
  <div>
    <v-alert
      color="blue-grey"
      dark
    >
      この問い合わせはCLOSEされました。
    </v-alert>

    <v-row>
      <v-col cols="6">
        <h3 class="mb-4">
          6/1リリースの機能追加について質問です。
        </h3>
      </v-col>
      <v-col cols="6">
        <div class="float-right">
          <v-btn color="primary">
            CLOSEする
          </v-btn>
          <v-btn @click="inquiryRegistDialog = true">
            書込
          </v-btn>
          <v-btn @click="systemInquiryCancel">
            キャンセル
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon>mdi-account-tie</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>2020-06-02 10:00 システム</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            いつもご利用ありがとうございます。<br>6/1の機能追加についてですが…。<br>ああああああああああ
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-list-item>
            <v-list-item-avatar>
              <v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>2020-06-01 10:00 山田 太郎</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            6/1リリースの機能追加について質問です。
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              color="error"
            >
              削除
            </v-btn>
            <v-btn
              small
              @click="inquiryRegistDialog = true"
            >
              編集
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <InquiryRegistDialog />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import InquiryRegistDialog from "../../dialogs/InquiryRegistDialog";

  export default {
    components: {
      InquiryRegistDialog,
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        inquiryRegistDialog: false,
      }
    },
    methods: {
      systemInquiryCancel: function () {
        this.$parent.systemInquiryCancel()
      },
    },
  }
</script>

<style lang="scss">

</style>
