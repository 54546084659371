<!-- バックヤード > 受注詳細 > 配送情報 -->
<template>
  <div v-if="orderDetail">
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col>
            <h2>
              <v-icon class="mr-2">
                mdi-truck
              </v-icon>
              配送情報一覧
              <span
                class="float-right"
              >
                <v-btn
                  :disabled="orderDetail.status === 'canceled'"
                  small
                  @click="outputDeliveryNoteButtonClickEvent(orderDetail)"
                >
                  納品書印刷
                </v-btn>
                <v-btn
                  :disabled="orderDetail.status === 'canceled'"
                  small
                  @click="showDeliveryDialogButtonClickEvent"
                >
                  配送情報追加
                </v-btn>
              </span>
            </h2>
          </v-col>
        </v-row>
        <v-card outlined>
          <v-data-table
            :headers="deliverTableHeaders"
            :items="orderDeliveries"
            :items-per-page="-1"
            disable-sort
            hide-default-footer
          >
            <template v-slot:item.editable="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="item.status === 'canceled'"
                    color="light-green lighten-3"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-if="item.delivery_note_file_url"
                    @click="outputChecklistButtonClickEvent(item)"
                  >
                    <v-list-item-title>納品書</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="showDocumentModal(item)">
                    <v-list-item-title>同封書類</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                :disabled="item.status === 'canceled'"
                small
                @click="showSendMailFormClickEvent(item)"
              >
                <v-icon>mdi-email-outline</v-icon>発送メール送信
              </v-btn>
              <v-btn
                v-else
                color="info"
                small
                :disabled="item.status === 'canceled'"
                @click="showSendMailFormClickEvent(item)"
              >
                発送メール送信
              </v-btn>
              <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                :disabled="item.status === 'canceled'"
                small
                color="error"
                @click="orderDeliveryDeleteButtonClickEvent(item)"
              >
                削除
              </v-btn>
              <v-btn
                v-else
                :disabled="item.status === 'canceled'"
                small
                color="error"
                @click="orderDeliveryDeleteButtonClickEvent(item)"
              >
                削除
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>

      <v-row>
        <v-col cols="12">
          <v-pagination
            v-model="deliveryPage"
            :length="deliveryPagination.total_pages"
            :total-visible="deliveryTotalVisible"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            circle
            @input="orderDeliveryChangePage"
          />
        </v-col>
      </v-row>
    </v-card>

    <!-- 発送メール送信ダイアログ -->
    <v-dialog
      v-model="showMailSendDialog"
      width="600"
    >
      <validation-observer v-slot="{ invalid }">
        <v-form>
          <v-card>
            <v-card-title>メール送信</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="件名"
                    rules="required"
                  >
                    <v-text-field
                      v-model="mail_title"
                      dense
                      hide-details="auto"
                      label="件名"
                      outlined
                      placeholder=" "
                      value=""
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="本文"
                    class="requiredMark"
                  >
                    <v-textarea
                      v-model="mail_body"
                      outlined
                      counter
                      label="本文"
                      value=""
                      auto-grow
                      row-height="15"
                      dense
                      hide-details="auto"
                      placeholder=" "
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="showMailSendDialog = false">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="sendMailButtonClickEvent"
              >
                送信
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
    <!-- /発送メール送信ダイアログ -->

    <!-- 配送情報登録ダイアログ -->
    <v-dialog
      v-model="showDeliveryDialog"
      width="960"
    >
      <validation-observer
        v-slot="{ invalid }"
        ref="deliveryFormValidation"
      >
        <v-form>
          <v-card>
            <v-card-title>配送情報登録</v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="配送業者"
                    rules="required"
                  >
                    <v-select
                      v-model="deliveryForm.delivery_company_id"
                      :error-messages="errors"
                      :items="deliveryCompanies"
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="id"
                      label="配送業者"
                      outlined
                      placeholder=" "
                      required
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="時間指定"
                  >
                    <v-select
                      v-model="deliveryForm.delivery_company_time_id"
                      :items="deliveryCompanyTimes"
                      clearable
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="id"
                      label="時間指定"
                      outlined
                      placeholder=" "
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-menu
                    v-model="showPicker"
                    :close-on-content-click="false"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <validation-provider
                        v-slot="{ errors }"
                        class="requiredMark"
                        name="到着予定日"
                        rules="required"
                      >
                        <v-text-field
                          v-model="deliveryForm.arrival_schedule_date"
                          :error-messages="errors"
                          dense
                          label="到着予定日"
                          hide-details="auto"
                          outlined
                          placeholder=" "
                          readonly
                          required
                          v-on="on"
                          @click:clear="deliveryForm.arrival_schedule_date = null"
                        />
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="deliveryForm.arrival_schedule_date"
                      @input="showPicker = false"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="伝票番号"
                    rules="required|max:20"
                  >
                    <v-text-field
                      v-model="deliveryForm.slip_number"
                      dense
                      hide-details="auto"
                      label="伝票番号"
                      outlined
                      placeholder=" "
                      required
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="max:512"
                    name="備考"
                    class=""
                  >
                    <v-textarea
                      v-model="deliveryForm.note"
                      outlined
                      counter="512"
                      hide-details="auto"
                      label="備考"
                      rows="2"
                      placeholder=" "
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <div style="display: none;">
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="min_value:1"
                      name=""
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="deliveryForm.selected_count"
                        hidden
                        required
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>

              <v-card outlined>
                <v-simple-table
                  fixed-header
                  dense
                  height="150px"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th />
                        <th class="text-left">
                          品番1
                        </th>
                        <th class="text-left">
                          商品名
                        </th>
                        <th class="text-left">
                          在庫管理番号
                        </th>
                        <th>
                          金額
                        </th>
                        <th class="text-center">
                          ご利用開始日
                        </th>
                        <th class="text-center">
                          ご利用日数
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="order_stock in orderStocks"
                        :key="order_stock.id"
                      >
                        <td>
                          <v-checkbox
                            v-model="order_stock.checkable"
                            dense
                            hide-details
                            class="pa-0 ma-0"
                            @change="orderStockCheckboxChangeEvent($event, order_stock)"
                          />
                        </td>
                        <td>
                          {{ order_stock.part_number_1 }}
                        </td>
                        <td>
                          {{ order_stock.product_name }}
                        </td>
                        <td>
                          {{ order_stock.product_stock_no }}
                        </td>
                        <td>
                          {{ order_stock.price | comma_format }} 円
                        </td>
                        <td class="text-center">
                          {{ order_stock.schedule_date }}
                        </td>
                        <td class="text-center">
                          {{ order_stock.rental_days }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-pagination
                      v-model="orderStockPage"
                      :length="orderStockPagination.total_pages"
                      :total-visible="orderStockTotalVisible"
                      prev-icon="mdi-menu-left"
                      next-icon="mdi-menu-right"
                      circle
                      @input="deliveryOrderStockChangePage"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="deliveryDialogCloseButtonClickEvent">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="deliveryDialogRegisterButtonClickEvent"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
    <!-- /配送情報登録ダイアログ -->

    <confirm ref="confirm" />

    <!-- 同封書類ダウンロードダイアログ -->
    <document-library-dialog ref="documentDialog" />
    <!-- /同封書類ダウンロードダイアログ -->
  </div>
</template>

<script>
  import { mapActions, mapState, mapGetters} from "vuex";
  import Confirm from "../../../../components/Confirm";
  import DocumentLibraryDialog from "../dialogs/DocumentLibraryDialog";

  /**
   * @typedef {Object} orderDeliveries
   * @property {string} delivery_company_name
   * @property {string} delivery_slip_number
   * @property {string} delivery_company_time_name
   * @property {string} shipped_date
   * @property {string} arrival_schedule_date
   * @property {string} invoice_number
   * @property {string} send_mail_at
   * @property {string} delivery_note_file_url
   * @property {string} delivery_file_url
   * @property {string} checklist_file_url
   */
  export default {
    components: {
      Confirm,
      DocumentLibraryDialog,
    },
    mixins: [
    ],
    data () {
      return {
        deliverTableHeaders: [
          { value: 'delivery_company_name', text: '配送業者' },
          { value: 'delivery_company_time_name', text: '配送時間' },
          { value: 'delivery_slip_number', text: '配送伝票番号' },
          { value: 'shipped_date', text: '出荷日' },
          { value: 'arrival_schedule_date', text: '到着予定日' },
          { value: 'invoice_number', text: '納品書番号' },
          { value: 'send_mail_at', text: 'メール送信日時' },
          { value: 'editable', text: '', align: 'end' },
        ],
        /* 配送ダイアログ 受注詳細一覧 */
        orderStockPage: 1,
        orderStockTotalVisible: 7,
        orderStockPagination: {
          current_page: 1,
          total_count: 0,
          total_pages: 0,
        },
        orderStocks: [],
        deliveryCompanyTimes: [],
        checked_list: [],
        deliveryForm: {
          delivery_company_id: null,
          delivery_company_time_id: null,
          arrival_schedule_date: null,
          slip_number: '',
          note: null,
          selected_count: 0,
        },

        /* 配送一覧 */
        deliveryPage: 1,
        deliveryTotalVisible: 7,
        deliveryPagination: {
          current_page: 1,
          total_count: 0,
          total_pages: 0,
        },
        orderDeliveries: [],

        mail_dialog_id: null,
        mail_title: null,
        mail_body: null,
        showMailSendDialog: false,
        showDeliveryDialog: false,
        showPicker: false,
        orderDeliveryRegistDialog: false,
        orderReturnConfirmRegistDialog: false,
        orderReturnRegistDialog: false,
      }
    },
    computed: {
      ...mapState('backSearchDeliveryCompany', {
        deliveryCompanies: 'content'
      }),
      /**
       * @typedef orderDetail { Object }
       */
      ...mapGetters('backOrder', [
        'orderDetail'
      ]),
    },
    watch: {
      orderStocks () {
        for (const elm of this.orderStocks) {
          elm.checkable = (this.checked_list.some( b => b === elm.id))
        }
        this.deliveryForm.selected_count = this.checked_list.length
      },
      'deliveryForm.delivery_company_id': function() {
        this.searchDeliveryCompanyTime()
      }
    },
    created() {
      this.searchOrderDeliveries()
      this.fetchDeliveryCompanies()
      if (this.deliveryForm.delivery_company_id) {
        this.searchDeliveryCompanyTime()
      }
    },
    methods: {
      ...mapActions('backSearchDeliveryCompany', {
        fetchDeliveryCompanies: 'fetchAll'
      }),
      ...mapActions('backSearchDeliveryCompanyTime', {
        fetchDeliveryCompanyTimes: 'fetchAll'
      }),
      ...mapActions('backOrderStock', {
        fetchOrderStocks: 'fetchAll'
      }),
      ...mapActions('backOrderStockHistory', {
        fetchOrderStockHistories: 'fetchAll'
      }),
      ...mapActions('backOrderDelivery', {
        fetchOrderDeliveries: 'fetchAll',
        createOrderDelivery: 'create',
        updateOrderDelivery: 'update',
        destroyOrderDelivery: 'destroy'
      }),
      /**
       * 納品書ボタン クリックイベント
       */
      outputDeliveryNoteButtonClickEvent (item) {
        let link = document.createElement('a')
        link.href = item.delivery_file_url
        // link.download = 'ファイル名.pdf'  ←これを指定するとファイルダウンロードになる.
        link.target = '_blank'
        link.click()
      },
      /**
       * 発送メール送信ボタン クリックイベント
       */
      showSendMailFormClickEvent (item) {
        this.mail_dialog_id = item.id
        this.mail_title = item.mail_title
        this.mail_body = item.mail_body
        this.showMailSendDialog = true
      },
      /**
       * 発送メールモーダル送信ボタン クリックイベント
       */
      sendMailButtonClickEvent () {
        const params = {
          id: this.mail_dialog_id,
          mail_title: this.mail_title,
          mail_body: this.mail_body,
        }
        this.updateOrderDelivery({order_id: this.orderDetail.id, params: params}).then(() =>{
          this.deliveryPage = 1
          this.searchOrderDeliveries()
          this.searchOrderStockHistories()
        })
        this.showMailSendDialog = false
      },
      /**
       * チェックリストボタン クリックイベント
       */
      outputChecklistButtonClickEvent (item) {
        let link = document.createElement('a')
        link.href = item.checklist_file_url
        // link.download = 'ファイル名.pdf'  ←これを指定するとファイルダウンロードになる.
        link.target = '_blank'
        link.click()
      },
      /**
       * 同封書類ボタン クリックイベント
       */
      showDocumentModal (item) {
        this.$refs.documentDialog.openDialog(item)
      },
      /**
       * 発送情報削除ボタン クリックイベント
       */
      orderDeliveryDeleteButtonClickEvent (item) {
        this.$refs.confirm.showDeleteConfirm().then((confirm) => {
          this.$refs.confirm.close()
          if (confirm) {
            this.destroyOrderDelivery({order_id: this.orderDetail.id, order_delivery_id: item.id }).then(() =>{
              this.deliveryPage = 1
              this.searchOrderDeliveries()
              this.searchOrderStockHistories()
            })
          }
        })
      },
      orderDeliveryChangePage () {
        this.searchOrderDeliveries()
      },
      searchOrderDeliveries () {
        const params = { page: this.deliveryPage }
        this.fetchOrderDeliveries({order_id: this.orderDetail.id, params: params}).then((content) => {
          this.orderDeliveries = content.order_deliveries
          this.deliveryPagination = content.pagination
        })
        this.fetchOrderStocks({ order_id: this.orderDetail.id, params: {page: 1} })

      },
      /** 配送追加ボタン クリックイベント **/
      showDeliveryDialogButtonClickEvent () {
        this._resetDeliveryFormAndCloseDialog()
        this.showDeliveryDialog = true
        this.searchDeliveryOrderStocks()
      },
      /** 配送登録ダイアログチェックボック チェンジイベント */
      orderStockCheckboxChangeEvent (event, item) {
        if (event) {
          this.checked_list.push(item.id)
        } else {
          this.checked_list = this.checked_list.filter(o => o !== item.id)
        }
        this.deliveryForm.selected_count = this.checked_list.length
      },
      /** 配送登録ダイアログキャンセルボタン クリックイベント */
      deliveryDialogCloseButtonClickEvent () {
        this._resetDeliveryFormAndCloseDialog()
      },
      /** 配送登録ダイアログ登録ボタン クリックイベント */
      deliveryDialogRegisterButtonClickEvent () {
        const params = {
          delivery_company_id: this.deliveryForm.delivery_company_id,
          delivery_company_time_id: this.deliveryForm.delivery_company_time_id,
          arrival_schedule_date: this.deliveryForm.arrival_schedule_date,
          delivery_slip_number: this.deliveryForm.slip_number,
          note: this.deliveryForm.note,
          order_stock_ids: this.checked_list
        }
        this.createOrderDelivery({ order_id: this.orderDetail.id, params: params }).then(() => {
          this.deliveryPage = 1
          this.searchOrderDeliveries()
          this._resetDeliveryFormAndCloseDialog()
        })

      },
      /**
       * 配送登録ダイアログのフォームリセットとダイアログを閉じる処理
       * @private
       */
      _resetDeliveryFormAndCloseDialog () {
        this.deliveryCompanyTimes = []
        this.orderStocks = []
        this.checked_list = []
        this.orderStockPage = 1
        Object.assign(this.$data.deliveryForm, this.$options.data().deliveryForm)
        this.$nextTick(() => this.$refs.deliveryFormValidation.reset())
        this.showDeliveryDialog = false
      },
      /** 配送時間取得 */
      searchDeliveryCompanyTime () {
        if (this.deliveryForm.delivery_company_id) {
          this.fetchDeliveryCompanyTimes({ delivery_company_id: this.deliveryForm.delivery_company_id }).then((content) => {
            this.deliveryCompanyTimes = content.delivery_company_times
          })
        }
      },
      /** 配送登録ダイアログ 受注詳細一覧ページ移動 */
      deliveryOrderStockChangePage () {
        this.searchDeliveryOrderStocks()
      },
      /** 配送登録ダイアログ 受注詳細一覧検索 */
      searchDeliveryOrderStocks () {
        const params = { page: this.orderStockPage, without_delivered: true }
        this.fetchOrderStocks({ order_id: this.orderDetail.id, params: params }).then((content) => {
          this.orderStocks = content.order_stocks
          this.orderStockPagination = content.pagination
        })
      },
      /**
       * 発送メール送信、削除で出荷日が変化するため受注詳細の一覧情報を更新する.
       * ページは表示時点のページ番号を維持
       */
      searchOrderStockHistories() {
        this.fetchOrderStockHistories({ order_id: this.orderDetail.id })
      }
    },
  }
</script>

<style lang="scss">
</style>
