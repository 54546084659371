<!-- バックヤード > 現地マスタ一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          現地マスタ
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              @click="newLocalPlace"
            >
              現地追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="inputName"
                      dense
                      label="現地名"
                      hide-details="auto"
                      outlined
                      clearable
                      placeholder=" "
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn color="primary" @click="resetLocalPlaces"> 検索 </v-btn>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    現地名
                  </th>
                  <th class="text-left">
                    コメント
                  </th>
                  <th class="text-center">
                    表示順
                  </th>
                  <th class="text-left">
                    作成日時
                  </th>
                  <th class="text-left">
                    更新日時
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="local_place in local_places"
                  :key="local_place.id"
                >
                  <td>{{ local_place.name }}</td>
                  <td>{{ local_place.comment }}</td>
                  <td class="text-center">{{ local_place.position }}</td>
                  <td>{{ local_place.created_at | unix_datetime_format }}</td>
                  <td>{{ local_place.updated_at | unix_datetime_format }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="editLocalPlace(local_place)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-3">
      <v-col>
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'LocalPlaceIndex',
    components: {
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        page: 1,
        totalVisible: 7,
        inputName: '',
        local_places:[],
      }
    },
    computed: {
      ...mapState('backLocalPlace', {
        pagination: 'pagination',
        searchQuery: 'query',
      }),
    },
    created() {
      this.page = this.pagination.current_page
      this.searchLocalPlaces()
    },
    methods: {
      ...mapActions('backLocalPlace', {
        fetchLocalPlaces: 'fetchLocalPlaces',
        fetchQuery: 'fetchQuery',
      }),
      resetFilters () {
        this.inputName = ''
        this.resetLocalPlaces()
      },
      newLocalPlace () {
        // 現地マスタ新規追加へ
        this.$router.push({name: 'LocalPlaceNew'})
      },
      editLocalPlace (localPlace) {
        // 現地マスタ編集へ
        this.$router.push({name: 'LocalPlaceEdit', params: { id: localPlace.id }})
      },
      resetLocalPlaces () {
        this.filterDialog = false
        this.page = 1
        this.searchLocalPlaces()
      },
      changePage () {
        this.searchLocalPlaces()
      },
      searchLocalPlaces () {
        const params = { name: this.inputName, page: this.page }
        this.fetchLocalPlaces(params).then((content) => {
          this.local_places = content.local_places
        })
      }
    }
  }
</script>

<style lang="scss">

</style>
