/**
 * 申請URL
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/home/generate_apply_url`

const generate_apply_url = {
    namespaced: true,
    actions: {
        create( { _commit } ) {
            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(API_PATH).then( data => {
                    let content = data.content
                    resolve(content)
                } )
            })
        },
    },
    mutations: {},
}
export default generate_apply_url