<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>CompanyInformation<span class="subtitle-2 ml-4">会社情報</span></h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <v-card flat>
      <v-card-text>
        <v-row
          v-for="(item, index) in information"
          :key="index"
        >
          <v-col
            cols="12"
            sm="3"
            class="grey lighten-3"
          >
            <span class="font-weight-bold">{{ item.title }}</span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            class="line-break"
          >{{ item.text }}
          </v-col>
          <v-col
            cols="12"
            class="px-0 py-2"
          >
            <v-divider />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

  export default {
    name: 'CompanyInformation',
    props: {
      information: {
        type: Array,
        default: () => {},
      },
    }
  }

</script>

<style scoped lang="scss">
</style>