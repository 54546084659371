<template>
  <v-container
    class="grey lighten-5"
  >
    <v-breadcrumbs
      :items="breadcrumbs"
    />
    <v-card v-if="company">
      <v-card-title>
        <h2>
          <v-icon
            class="mr-2"
          >
            mdi-office-building
          </v-icon>
          会社詳細
        </h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-btn
              color="error"
              @click="deleteButtonClickEvent"
            >
              削除
            </v-btn>
          </v-col>
          <v-col cols="9">
            <div class="text-right">
              <v-btn
                color=""
                @click="launchMailButtonClick"
              >
                開通メールを送信
              </v-btn>
              <v-btn
                color="primary"
                @click="editButtonClickEvent"
              >
                編集
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <br>

        <v-row>
          <v-col cols="12">
            <h2>
              基本情報
            </h2>
            <v-divider class="mt-2" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  ID
                </div>
                <h4>{{ company.id }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  コード
                </div>
                <h4>{{ company.code }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  会社名
                </div>
                <h4>{{ company.name }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  会社名カナ
                </div>
                <h4>{{ company.kana_name }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  モード
                </div>
                <h4>{{ company.mode_name }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  契約種別
                </div>
                <h4>{{ company.contract_type_name }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  契約日
                </div>
                <h4>{{ company.contract_start_date | from_to_date_format(company.contract_end_date) }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  契約者名
                </div>
                <h4>{{ company.contractor }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  住所
                </div>
                <h4>{{ company.prefecture_name }}{{ company.address }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  電話番号
                </div>
                <h4>{{ company.phone }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  FAX番号
                </div>
                <h4>{{ company.fax }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  メールアドレス
                </div>
                <h4>{{ company.email }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  URL
                </div>
                <h4>{{ company.url }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  フロントエンド認証
                </div>
                <h4>{{ company.use_frontend_auth_name }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  会員登録方法
                </div>
                <h4>{{ company.customer_reg_type_name }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <div class="overline">
                  定期課金使用
                </div>
                <h4>{{ company.use_subscription_decorate }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <div class="overline">
                  販売使用
                </div>
                <h4>{{ company.use_sale_decorate }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <div class="overline">
                  クレジットカード使用有無
                </div>
                <h4>{{ company.use_credit_card_decorate }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <div class="overline">
                  銀行振込使用有無
                </div>
                <h4>{{ company.use_bank_transfer_decorate }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
              >
                <div class="overline">
                  代引き使用有無
                </div>
                <h4>{{ company.use_cash_on_delivery_decorate }}&nbsp;</h4>
                <v-divider />
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="12">
                <h2>
                  店舗
                </h2>
                <v-divider class="mt-2" />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  店舗名
                </div>
                <h4>{{ company.shop_name }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  サブドメイン
                </div>
                <h4>https://<b>{{ company.sub_domain }}</b>.bizrent.biz</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  独自ドメイン
                </div>
                <h4>{{ company.original_domain }}&nbsp;</h4>
                <v-divider />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h2>
                  振込先
                </h2>
                <v-divider class="mt-2" />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  銀行名
                </div>
                <h4>{{ company.bank_name }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  支店名
                </div>
                <h4>{{ company.branch_name }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  預金種別
                </div>
                <h4>{{ company.bank_account_type_name }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  口座番号
                </div>
                <h4>{{ company.bank_account_number }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  口座名義
                </div>
                <h4>{{ company.bank_account_holder_name }}</h4>
                <v-divider />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <h2>
                  決済代行サービス
                </h2>
                <v-divider class="mt-2" />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  決済開通日時
                </div>
                <h4>{{ company.formatted_settlement_opening_at }}&nbsp;</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  テナントID
                </div>
                <h4>{{ company.payment_agency_id }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  プラットフォーム利用料率
                </div>
                <h4>{{ company.platform_usage_rate }}</h4>
                <v-divider />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h3>
              審査ステータス
            </h3>
            <v-divider class="mt-2" />
          </v-col>
        </v-row>
        <div v-if="company.apply_result">
          <v-simple-table>
            <thead>
              <tr>
                <th>カードブランド</th>
                <th>審査ステータス</th>
                <th>利用可能開始日時</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="result in company.apply_result"
                :key="result.brand"
              >
                <td>{{ result.brand }}</td>
                <td>{{ result.status_name }}</td>
                <td>{{ result.available_at }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <v-divider />
      </v-card-text>
    </v-card>
    <Confirm ref="confirm" />
  </v-container>
</template>

<script>

  import Confirm from '../../../components/Confirm'
  import { mapActions } from 'vuex'

  export default {
    name: 'CompanyShow',
    components: {
      Confirm
    },
    mixins: [],
    data(){
      return {
        company: null,
        breadcrumbs: [
          { text: '会社', disabled: false, to: '/admin/companies', exact: true },
          { text: '会社詳細', disabled: true, href: '' },
        ],
      }
    },
    computed: {
    },
    mounted() {
      this.$nextTick(function () {
        // ビュー全体がレンダリングされた後にのみ実行されるコード
      })
    },
    created() {
      this.fetchCompany({id: this.$route.params.id}).then(( content ) => {
        this.company = content
      })
    },
    methods: {
      ...mapActions('adminCompany', {
        fetchCompany: 'fetch',
        sendMail: 'sendOpeningMail',
        destroyCompany: 'destroy'

      }),
      launchMailButtonClick () {
        this.sendMail({id: this.company.id})
      },
      deleteButtonClickEvent () {
        // delete dialog
        this.$refs.confirm.showDeleteConfirm().then((confirm) => {
          this.$refs.confirm.close()
          if (confirm) {
            this.destroyCompany({ id: this.$route.params.id }).then(() => {
              this.$router.push({ name: 'adCompanyIndex' })})
          }
        })
      },
      editButtonClickEvent () {
        this.$router.push( { name: 'adCompanyEdit', params: { id: this.company.id } } )
      }
    }
  }
</script>

<style scoped>

</style>