const ProductMixin = {
  data() {
    return {
      product: null,
      relationProducts: [],
      recommendProducts: [],
      componentProducts: [],
    }
  },
  computed: {
    isRelationProducts () {
      return (this.relationProducts.length > 0)
    },
    isRecommendProducts () {
      return (this.recommendProducts.length > 0)
    },
    isComponentProducts () {
      return (this.componentProducts.length > 0)
    }
  },
  filters: {
    rentalUnitFilter: (unit) => {
      let unit_text = ''
      switch (unit) {
        case 'daily_rental':
          unit_text = '日'
          break
        case 'weekly_rental':
          unit_text = '週'
          break
        case 'monthly_rental':
          unit_text = '月'
          break
        case 'yearly_rental':
          unit_text = '年'
          break
        default:
          unit_text = ''
          break
      }
      return unit_text
    },
    rentalMaxUnitFilter: (days, unit) => {
      let unit_text = ''
      switch (unit) {
        case 'daily_rental':
          unit_text = '日'
          break
        case 'weekly_rental':
          unit_text = '週'
          break
        case 'monthly_rental':
          unit_text = '月'
          break
        case 'yearly_rental':
          unit_text = '年'
          break
        default:
          unit_text = ''
          break
      }
      return `最大：${days}${unit_text}`
    },
    rentalMinUnitFilter: (days, unit) => {
      let unit_text = ''
      switch (unit) {
        case 'daily_rental':
          unit_text = '日'
          break
        case 'weekly_rental':
          unit_text = '週'
          break
        case 'monthly_rental':
          unit_text = '月'
          break
        case 'yearly_rental':
          unit_text = '年'
          break
        default:
          unit_text = ''
          break
      }
      return `最小：${days}${unit_text}`
    },

  },
  methods: {
    /**
     * 端数の取り扱い
     * @param price
     * @param fraction
     * @returns {*}
     */
    calcRoundPrice: (price, fraction) => {
      let rental_price = 0
      switch (fraction) {
        case 'cutup':
          // 切上
          rental_price = Math.ceil( price )
          break
        case 'rounddown':
          // 切捨
          rental_price = Math.floor( price )
          break
        case 'roundup':
          // 四捨五入
          rental_price = Math.round(price)
          break
        default:
          rental_price = price
      }
      return rental_price
    }
  }
}

export default ProductMixin
