var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.optionGroup)?_c('div',[_c('alert-message'),_vm._v(" "),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:30","name":"オプショングループ名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"30","dense":"","hide-details":"auto","label":"オプショングループ名","outlined":"","placeholder":" ","required":""},model:{value:(_vm.optionGroup.name),callback:function ($$v) {_vm.$set(_vm.optionGroup, "name", $$v)},expression:"optionGroup.name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"税設定"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.optionGroup.tax_types,"error-messages":errors,"dense":"","hide-details":"auto","label":"税設定","outlined":"","placeholder":" ","required":""},model:{value:(_vm.optionGroup.tax_type),callback:function ($$v) {_vm.$set(_vm.optionGroup, "tax_type", $$v)},expression:"optionGroup.tax_type"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"必須有無"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"error-messages":errors,"hide-details":"auto","label":"選択を必須とする"},model:{value:(_vm.optionGroup.required),callback:function ($$v) {_vm.$set(_vm.optionGroup, "required", $$v)},expression:"optionGroup.required"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"説明ボタンを表示する"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"error-messages":errors,"hide-details":"auto","label":"説明ボタンを表示する"},model:{value:(_vm.optionGroup.enable_description_button),callback:function ($$v) {_vm.$set(_vm.optionGroup, "enable_description_button", $$v)},expression:"optionGroup.enable_description_button"}})]}}],null,true)})],1)],1),_vm._v(" "),(_vm.optionGroup.enable_description_button)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:10","name":"説明ボタンのラベル"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":"10","dense":"","hide-details":"auto","label":"説明ボタンのラベル","outlined":"","placeholder":" ","required":"","error-messages":errors},model:{value:(_vm.optionGroup.description_button_caption),callback:function ($$v) {_vm.$set(_vm.optionGroup, "description_button_caption", $$v)},expression:"optionGroup.description_button_caption"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"","name":"説明内容"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"auto-grow":"","counter":"512","dense":"","hide-details":"auto","label":"説明内容","outlined":"","placeholder":" ","row-height":"15"},model:{value:(_vm.optionGroup.description),callback:function ($$v) {_vm.$set(_vm.optionGroup, "description", $$v)},expression:"optionGroup.description"}})]}}],null,true)})],1)],1):_vm._e()],1),_vm._v(" "),_c('v-card-actions',[(_vm.isEdit)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.optionGroupDeleteButtonClickEvent}},[_vm._v("\n            削除\n          ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.optionGroupCancelButtonClickEvent}},[_vm._v("\n            キャンセル\n          ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.optionGroupRegisterButtonClickEvent}},[_vm._v("\n            保存\n          ")])],1)],1)],1)]}}],null,false,1085696277)}),_vm._v(" "),_c('confirm',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }