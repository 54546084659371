/**
 * ニュース
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/news`

const news = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {
      keyword: '',
      enable_display_date: false,
      display_date: null
    },
  },
  getters: {
    hasNews: ( state ) => {
      return (state.pagination.total_count > 0)
    },
  },
  actions: {
    fetchAll({ dispatch, commit, state }, {keyword, enable_display_date, display_date, page}) {
      const queries = { keyword: keyword, enable_display_date: enable_display_date, display_date: display_date }
      dispatch('fetchQuery', queries)
      const params = {
        keyword: state.query.keyword,
        enable_display_date: state.query.enable_display_date,
        display_date: state.query.display_date,
        page: page
      }

      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_NEWS, { content })
          resolve(content)
        })
      })
    },
    fetchQuery({ commit, state}, {keyword, enable_display_date, display_date }) {
      const keywordText = (keyword !== undefined)? keyword : state.query.keyword
      const enableDisplayDate = (enable_display_date !== undefined) ? enable_display_date : state.query.enable_display_date
      const displayDate = (display_date !== undefined) ? display_date: state.query.display_date
      const params = { keyword: keywordText, enable_display_date: enableDisplayDate, display_date: displayDate }
      commit(types.BACK_NEWS_QUERY, { params })
    },
    show ({ commit, state }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    new ({ _commit }) {
      const api_path = `${API_PATH}/new`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    edit({ _commit, state }, { id }) {
      const api_path = `${API_PATH}/${id}/edit`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    create( { commit, state }, { params } ) {
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(API_PATH, params).then( data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          let content = data.content
          resolve(content)
        } )
      })
    },
    update( {commit, state}, { id, params } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          let content = data.content
          resolve(content)
        } )
      })
    },
    destroy( { commit, state }, { id } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.delete(api_path).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          let content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.BACK_NEWS] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_NEWS_QUERY] (state, { params }) {
      state.query.keyword = params.keyword
      state.query.enable_display_date = params.enable_display_date
      state.query.display_date = params.display_date
    }
  },
}

export default news