<!-- バックヤード > タブ form -->
<template>
  <div>
    <v-row>
      <v-col>
        <v-tabs
          v-model="Form"
          background-color="blue lighten-4"
          centered
          icons-and-text
          fixed-tabs
        >
          <v-tabs-slider />
          <v-tab href="#tab-deliveryCompany">
            配送業者
            <v-icon>mdi-truck-outline</v-icon>
          </v-tab>
          <v-tab href="#tab-deliveryCompanyTime">
            配送時間設定
            <v-icon>mdi-clock-time-four-outline</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="Form">
          <v-tab-item value="tab-deliveryCompany">
            <delivery-company
              :is-edit="true"
              :delivery-company="deliveryCompany"
            />
          </v-tab-item>

          <v-tab-item value="tab-deliveryCompanyTime">
            <delivery-company-time
              :delivery-company="deliveryCompany"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// 配送業者
import DeliveryCompany from './_DeliveryCompany'
// 配送時間
import DeliveryCompanyTime from './_DeliveryCompanyTime'

export default {
  components: {
    DeliveryCompany,
    DeliveryCompanyTime,
  },
  mixins: [
  ],
  props: {
    deliveryCompany: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      Form: 'deliveryCompanyTab',
    }
  },
  methods: {
  },
}
</script>

<style scoped lang="scss">
  .v-window-item > .v-card
  {
    min-height:300px;
  }
</style>
