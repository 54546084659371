<!-- バックヤード > パスワード変更ダイアログ -->
<template>
  <v-dialog
    v-model="dialog"
    width="600"
    @input="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-form>
      <validation-observer v-slot="{ invalid }">
        <v-card>
          <v-card-title>パスワード変更</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  vid="password"
                  rules="required|max:72"
                  name="パスワード"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="password"
                    label="パスワード"
                    type="password"
                    dense
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                    counter="72"
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  vid="confirmation"
                  rules="required|max:72|confirmed:password"
                  name="パスワード(確認用)"
                  class="requiredMark"
                  mode="lazy"
                >
                  <v-text-field
                    v-model="password_confirmation"
                    label="パスワード(確認用)"
                    type="password"
                    dense
                    hide-details="auto"
                    outlined
                    clearable
                    placeholder=" "
                    counter="72"
                    required
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="cancelButtonClickEvent">
              キャンセル
            </v-btn>
            <v-btn
              color="primary"
              :disabled="invalid"
              @click="registerButtonClickEvent"
            >
              変更
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-form>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

export default {
  components: {
  },
  mixins: [],
  props: {},
  data () {
    return {
      dialog: false,
      password: null,
      password_confirmation: null
    }
  },
  computed: {},
  watch : {},
  created() {},
  methods: {
    ...mapActions('backUserPassword', {
      updatePassword: 'update'
    }),
    openDialog (params) {
      this.dialog = true
    },
    cancelButtonClickEvent () {
      this.closeDialog()
    },
    /**
     * OKボタンクリック イベント
     * 一覧で選択した値を呼び出し元に受け渡す
     */
    registerButtonClickEvent () {

      let params = {
        password: this.password,
        password_confirmation: this.password_confirmation
      }
      this.updatePassword({ params: params }).then( (  ) => {
        this.closeDialog()
      } )
    },
    closeDialog () {
      // すべて初期化
      Object.assign(this.$data, this.$options.data());
    }
  }
}
</script>

<style scoped>
</style>