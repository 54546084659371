import Vue from 'vue'
import Router from 'vue-router'
import FrontendRoutes from './frontend-routes'
import BackyardRoutes from './backyard-routes'
import AdminRoutes from './admin-routes'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {...AdminRoutes},
        {...BackyardRoutes},
        {...FrontendRoutes},
    ],
    scrollBehavior: (to, from, savedPosition) => {
        // const separator = ' | '
        // let titles = document.title.split(separator)
        // console.log(titles.length)
        // let title = document.title.split(separator)[0]
        // if (to.meta.siteSubTitle) {
        //     if (to.name === 'ProductShow') {
        //         // 商品詳細は商品名を表示する
        //         // document.title = `${title}${separator}${to.params.productName}`
        //     } else if (to.name === 'MyPageShow'){
        //         // マイページはタイトルを表示する
        //         document.title = `${to.params.title}${separator}${title}`
        //     } else {
        //         document.title = `${to.meta.siteSubTitle}${separator}${title}`
        //     }
        // } else {
        //     document.title = title
        // }

        // if (process.env.NODE_ENV !== 'production') {
        //     console.log('========= scrollBehavior ========== to', to)
        //     console.log('========= scrollBehavior ========== from', from)
        //     console.log('scrollBehavior ======== ', savedPosition)
        // }
        return { x: 0, y: 0 }
    }
})

export default router
