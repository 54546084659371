<template>
  <div>
    <v-text-field
      v-model="searchKeyword"
      :placeholder="'キーワード検索(' + $t('view.キーワード検索') + ')' "
      solo-inverted
      single-line
      hide-details
      dense
      class="custom-placeholder-color"
    >
      <template v-slot:append>
        <v-btn
          small
          :color="buttonColor"
          @click="searchProduct"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  /**
   * キーワード検索
   * <keyward-search button-color="ボタンの色"/>
   */
  export default {
    name: 'KeywardSearch',
    props: {
      buttonColor: {
        type: String,
        default: 'grey darken-1'
      },
    },
    data() {
      return {
        searchKeyword: ''
      }
    },
    computed: {},
    methods: {
      ...mapActions('frontProduct', {
        fetchProducts: 'fetchProducts',
      }),
      searchProduct () {
        // 商品検索
        if (this.$route.name === 'ProductIndex') {
          // 検索条件を変更し再検索(historyを上書き）NavigationDuplicated を出さないために callback を指定
          this.$router.replace({name: 'ProductIndex', query: {keyword: this.searchKeyword}}, () => {})
        } else {
          this.$router.push({name: 'ProductIndex', query: {keyword: this.searchKeyword}})
        }
      }
    }
  }

</script>

<style scoped lang="scss">
.custom-placeholder-color {
  /deep/ {
    input::placeholder {
      color: #757575 !important;
      opacity: 1;
    }
    .v-input__slot {
      background: white !important;
    }
    input {
      color: black !important;
      background: white !important;
    }
  }
  &.v-text-field {
    background: white !important;
  }
}
</style>