<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs
          class="px-0 py-2"
          :items="breadcrumbs"
        />
      </v-col>
    </v-row>
    <v-stepper v-model="stepper">
      <v-stepper-header>
        <v-stepper-step
          step="1"
          :complete="stepper > 1"
        >
          期間延長
          <div class="lang_lbl">{{ $t('view.期間延長') }}</div>
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          step="2"
          :complete="stepper > 2"
        >
          お支払方法
          <div class="lang_lbl">{{ $t('view.お支払い方法') }}</div>
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          step="3"
          :complete="stepper > 3"
        >
          注文確認
          <div class="lang_lbl">{{ $t('view.注文確認') }}</div>
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          step="4"
          :complete="stepper > 3"
        >
          注文完了
          <div class="lang_lbl">{{ $t('view.注文完了') }}</div>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <order-summary @toNextStep="nextStep" />
        </v-stepper-content>
        <v-stepper-content step="2">
          <order-payment
            @toNextStep="nextStep"
            @toBackStep="backStep"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <order-confirm
            v-if="stepper===3"
            @toNextStep="nextStep"
            @toBackStep="backStep"
            @toBackPayment="toOrderPayment"
          />
        </v-stepper-content>
        <v-stepper-content step="4">
          <order-complete />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import OrderSummary from './forms/_Summary'
import OrderPayment from './forms/_Payment'
import OrderConfirm from './forms/_Confirm'
import OrderComplete from './forms/_Complete'
import CartStepperMixin from '../../../../mixins/frontend/cart-stepper-mixin'
import PageHeaderMixin from '../../../../mixins/frontend/page-header-mixin'
import {mapActions} from "vuex";

export default {
  name: 'CartsIndex',
  components: {
    OrderSummary,
    OrderPayment,
    OrderConfirm,
    OrderComplete
  },
  mixins: [
    CartStepperMixin,
    PageHeaderMixin
  ],
  data () {
    return {
      delivery: null,
      breadcrumbs: [
        { text: "注文履歴", disabled: false, href: '/histories' },
        { text: "期間延長", disabled: true, to: '' },
      ],
    }
  },
  computed: {},
  created() {
    this.descriptionContent = this.$route.meta.siteSubTitle
    this.pageTitle = this.$route.meta.siteSubTitle
    this.fetchCompany();
    this.fetchThemeOption();
  },
  methods: {
    ...mapActions("frontCompany", {
      fetchCompany: "fetch",
    }),
    ...mapActions("frontThemeOption",{
      fetchThemeOption: "fetch",
    })

  },
}
</script>

<style scoped lang="scss">
</style>