<!-- バックヤード > 配送時間 form -->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-right mb-3">
              <v-btn
                small
                @click="addDeliveryTimeButtonClickEvent"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                :headers="tableHeaders"
                :items="deliveryCompanyTimes"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="cyan darken-2"
                    @click="editDeliveryTimeButtonClickEvent(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="info"
                    small
                    @click="editDeliveryTimeButtonClickEvent(item)"
                  >
                    編集する
                  </v-btn>
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="error"
                    @click="removeDeliveryTimeButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="removeDeliveryTimeButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showDeliveryTimeDialog"
      width="450"
      persistent
      no-click-animation
      @close="closeDeliveryTimeDialogClickEvent"
    >
      <v-form>
        <validation-observer
          v-slot="{ invalid }"
          ref="deliveryTimeFormValidation"
        >
          <v-card>
            <v-card-title>配送時間設定</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="配送時間名"
                    rules="required|max:30"
                  >
                    <v-text-field
                      v-model="deliveryCompanyTime.name"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="配送時間名"
                      outlined
                      placeholder=" "
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    name="表示順"
                    rules="required|numeric|min_value:0|max_value:2147483647"
                  >
                    <v-text-field
                      v-model="deliveryCompanyTime.position"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="表示順"
                      outlined
                      placeholder=" "
                      required
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeDeliveryTimeDialogClickEvent">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="deliveryTimeRegisterButtonClickEvent"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-dialog>
    <confirm ref="confirm" />
  </div>
</template>

<script>

import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import { mapActions } from 'vuex'

export default {
  name: 'DeliveryCompanyTimeForm',
  components: {
    AlertMessage,
    Confirm,
  },
  mixins: [],
  props: {
    deliveryCompany: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data () {
    return {
      tableHeaders: [
        { value: 'position', text: '表示順' },
        { value: 'name', text: '配送時間名' },
        { value: 'editable', text: '', align: 'end' }
      ],
      deliveryCompanyTimes: [],
      showDeliveryTimeDialog: false,
      deliveryCompanyTime: {
        id: null,
        name: null,
        position: 0,
      }
    }
  },
  created() {
    this.searchDeliveryCompanyTimes()
  },
  methods: {
    ...mapActions('backDeliveryCompanyTime', {
      fetchAll: 'fetchAll',
      newCompanyTime: 'new',
      editCompanyTime: 'edit',
      createCompanyTime: 'create',
      updateCompanyTime: 'update',
      deleteCompanyTime: 'destroy'
    }),
    searchDeliveryCompanyTimes () {
      /**
       * @typedef {Object} content
       * @property {Array} delivery_company_times
       */
        this.fetchAll({ delivery_company_id: this.deliveryCompany.id }).then((content) => {
          this.deliveryCompanyTimes = content.delivery_company_times
      })
    },

    /**
     * 追加ボタン クリックイベント
     */
    addDeliveryTimeButtonClickEvent () {
      this.newCompanyTime({delivery_company_id: this.deliveryCompany.id}).then((content) => {
        this.deliveryCompanyTime = content
        this.showDeliveryTimeDialog = true
      })
    },
    /**
     * 編集ボタン クリックイベント
     * @param delivery_company_time {Object}
     */
    editDeliveryTimeButtonClickEvent (delivery_company_time) {
      this.editCompanyTime({ delivery_company_id: this.deliveryCompany.id, id: delivery_company_time.id}).then((content) => {
        Object.assign(this.deliveryCompanyTime, content);
        this.showDeliveryTimeDialog = true
      })
    },
    /**
     * 閉じるボタン クリックイベント
     */
    closeDeliveryTimeDialogClickEvent () {
      Object.assign(this.$data.deliveryCompanyTime, this.$options.data().deliveryCompanyTime);
      this.$nextTick(() => this.$refs.deliveryTimeFormValidation.reset())
      this.showDeliveryTimeDialog = false
    },
    /**
     * 登録ボタン クリックイベント
     */
    deliveryTimeRegisterButtonClickEvent () {
      if (this.deliveryCompanyTime.id) {
        this.updateCompanyTime({ delivery_company_id: this.deliveryCompany.id, id: this.deliveryCompanyTime.id, params: this.deliveryCompanyTime }).then(() => {
          this.searchDeliveryCompanyTimes()
          this.closeDeliveryTimeDialogClickEvent()
        })
      } else {
        this.createCompanyTime({delivery_company_id: this.deliveryCompany.id, params: this.deliveryCompanyTime}).then(() => {
          this.searchDeliveryCompanyTimes()
          this.closeDeliveryTimeDialogClickEvent()
        })
      }
    },
    /**
     * 削除ボタン クリックイベント
     * @param delivery_company_time {Object}
     */
    removeDeliveryTimeButtonClickEvent: function(delivery_company_time) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.deleteCompanyTime({ delivery_company_id: this.deliveryCompany.id, id: delivery_company_time.id }).then(() => {
            this.searchDeliveryCompanyTimes()
          })
        }
      })
    },

  },
}
</script>

<style lang="scss">
.hide {
  display: none;
}
</style>
