/**
 * レンタルスケジュール
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/rental_schedules`

const rental_schedule = {
    namespaced: true,
    state: {
        pagination: {
            current_page: 1,
            total_count: 0
        },
        query: {
            part_number: null,
            start_date: null,
        },
    },
    getters: {
    },
    actions: {
        fetchRentalSchedules({ dispatch, commit, state }, {local_place_id, part_number, start_date, page}) {
            dispatch('fetchQuery', {local_place_id: local_place_id, part_number: part_number, start_date: start_date})
            const params = { local_place_id: state.query.local_place_id,
                            part_number: state.query.part_number,
                            start_date: state.query.start_date,
                            page: page }

            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    commit(types.BACK_RENTAL_SCHEDULE, { content })
                    resolve(data.content)
                })
            })
        },
        fetchQuery({ commit, state}, {local_place_id, part_number, start_date}) {
            const localPlaceId = (local_place_id !== undefined)? local_place_id : state.query.local_place_id
            const partNumber = (part_number !== undefined)? part_number : state.query.part_number
            const startDate = (start_date !== undefined)? start_date : state.query.startDate
            const params = { local_place_id: localPlaceId, part_number: partNumber, start_date: startDate,}
            commit(types.BACK_RENTAL_SCHEDULE_QUERY, { params })
        },
        create( { commit, state }, { params } ) {
            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(API_PATH, params).then( data => {
                    let content = data.content
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    commit(types.BACK_PRODUCT_DETAIL, { content })
                    resolve(content)
                } )
            })
        },
        edit({ commit, state }, { id }) {
            const api_path = `${API_PATH}/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        update( {commit, state}, { id, params } ) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params).then( data => {
                    let content = data.content
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    commit(types.BACK_PRODUCT_DETAIL, { content })
                    resolve(content)
                } )
            })
        },
        destroy( { commit, state }, { id } ) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },

    },
    mutations: {
        [types.BACK_RENTAL_SCHEDULE] (state, { content }) {
            state.pagination = content.pagination
        },
        [types.BACK_RENTAL_SCHEDULE_QUERY] (state, { params }) {
            state.query.local_place_id = params.local_place_id
            state.query.part_number = params.part_number
            state.query.start_date = params.start_date
        },
    },
}

export default rental_schedule