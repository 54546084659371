// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import Vue from 'vue'
// import Vuex from 'vuex'
import router from './router'
import store from './store'
import App from '../App'

import './plugins/filter'
import './plugins/vee-validate'
import httpClient from './plugins/http-client'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import ja from 'vuetify/es5/locale/ja'
import '@mdi/font/css/materialdesignicons.css'
import VueYoutube from 'vue-youtube'
import Vue2Editor from 'vue2-editor'
import PayjpCheckout from 'vue-payjp-checkout'
import DatetimePicker from 'vuetify-datetime-picker'
import VAnimateCss from 'animate.css';
import VueHead from 'vue-head'
import VueI18n from 'vue-i18n'
import VueTelInput from 'vue-tel-input'

Vue.use(VueTelInput);
Vue.use(VueYoutube)
Vue.use(Vue2Editor)
Vue.use(PayjpCheckout)
Vue.use(DatetimePicker)
Vue.use(VAnimateCss);
Vue.use(VueHead)

Vue.use(Vuetify)
const vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi',  // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
    lang: {
        locales: {ja},
        current: 'ja'
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: "#2d3753",
                header: "#2d3753",
//     secondary: "#b0bec5",
//     accent: "#8c9eff",
//     error: "#b71c1c",
            },
        },
    },
})

Vue.config.productionTip = false
Vue.use(httpClient, { store })

// router.beforeEach((to, from, next) => {
//
//     if (to.path == '/admin' || to.path == '/admin/') {
//         // TODO: admin ログイン判定
//         next({ name: 'AdminSessionIndex'})
//     } else {
//         next()
//     }
// //     // isPublic でない場合(=認証が必要な場合)、かつ、ログインしていない場合
// //     if (to.matched.some(record => !record.meta.isPublic)) {
// //         if (!store.state.auth.logged_in) {
// //             next({ path: '/SignIn', query: { redirect: to.fullPath }});
// //         } else {
// //             next();
// //         }
// //
// //     } else {
// //         next();
// //     }
// });


Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en',
  messages: {
    ja : require('./i18n/ja.json'),
    en : require('./i18n/en.json'),
  }
});

document.addEventListener('DOMContentLoaded', () => {
    new Vue({
        el: '#app',
        store: store,
        router,
        i18n: i18n,
        vuetify: vuetify,
        render: (h) => h(App)
    }).$mount("#app");
})
  

