<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon class="mr-2">
            mdi-certificate-outline
          </v-icon>Identification<span class="subtitle-2 ml-4">身分証明書</span>
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <v-card>
      <v-card-text>
        <membership-identification-form
          v-if="identifications"
          :identifications="identifications"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import { mapActions } from 'vuex'
import MembershipIdentificationForm from './_Form'
import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";

export default {
  name: 'MembershipIdentificationEdit',
  components: {
    MembershipIdentificationForm
  },
  mixins: [
    PageHeaderMixin
  ],
  data () {
    return {
      identifications: null,
    }
  },
  created() {
    this.descriptionContent = this.$route.meta.siteSubTitle
    this.pageTitle = this.$route.meta.siteSubTitle
    this.findIdentifications().then((content) => {
      this.identifications = content.identifications
    })
  },
  methods: {
    ...mapActions('frontIdentification', {
      findIdentifications: 'findIdentifications'
    })
  },
}
</script>

<style scoped lang="scss">
</style>
