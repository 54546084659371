/**
 * 顧客へのお知らせ
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/customers`

const customerNotice = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0
    },
  },
  getters: {
    isCustomerNotice: ( state ) => {
      return (state.pagination.total_count > 0)
    },
  },
  actions: {
    findCustomerNotices({ commit }, { customer_id, page } ) {
      const api_path = `${API_PATH}/${customer_id}/notices`
      const params = {
        page: page
      }
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path, { params: params }).then(data => {
          const content = data.content
          commit(types.BACK_CUSTOMER_NOTICE, {content})
          resolve(content)
        })
      })
    },
    new ({ commit }, { customer_id }) {
      const api_path = `${API_PATH}/${customer_id}/notices/new`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          const content = data.content
          resolve(content)
        })
      })
    },
    show ({ commit }, { customer_id, id }) {
      const api_path = `${API_PATH}/${customer_id}/notices/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          const content = data.content
          resolve(content)
        })
      })
    },
    create ({ commit }, { customer_id, params }) {
      const api_path = `${API_PATH}/${customer_id}/notices`
      return new Promise((resolve, _reject) => {
        Vue.$http.post(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          const content = data.content
          resolve(content)
        })
      })
    },
    update ({ commit }, { customer_id, id, params }) {
      const api_path = `${API_PATH}/${customer_id}/notices/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.put(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          const content = data.content
          resolve(content)
        })
      })
    },
    destroy({ commit }, { customer_id, id }) {
      const api_path = `${API_PATH}/${customer_id}/notices/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.delete(api_path).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          const content = data.content
          resolve(content)
        })
      })
    }
  },
  mutations: {
    [types.BACK_CUSTOMER_NOTICE] (state, { content }) {
      state.pagination = content.pagination
    },
  },
}

export default customerNotice

