/**
 * 商品問い合わせ
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/product_questions`

const product_question = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetchAll({ _commit }) {
      const parameter = { page: 1, limit: 10, status: 'unanswered' }
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: parameter }).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {},
}

export default product_question