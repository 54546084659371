<template>
  <v-footer
    padless
    color="grey lighten-5"
  >
    <v-card
      flat
      tile
      style="width: 100%"
      color="grey lighten-5"
    >
      <v-container>
        <v-card-text align="center">
          {{ content.copy_right }}
        </v-card-text>
      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
  import {mapActions, mapState} from 'vuex'

  /**
   * セッション用フッター
   */
  export default {
    name: 'SessionFooterNav',
    data() {
      return {
      }
    },
    computed: {
      ...mapState('frontCredence', {
        content: 'content',
      }),
    },
    created() {
      this.fetch()
    },
    methods: {
      ...mapActions('frontCredence', {
        fetch: 'fetch'
      }),
    },
  }

</script>

<style scoped lang="scss">
</style>