<!-- バックヤード > 商品問い合わせ編集 -->
<template>
  <div v-if="productQuestion">
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-img
                      :src="productQuestion.image.url | load_image_url"
                      class="img-fluid"
                      contain
                      height="150px"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="7"
                  >
                    <h3>
                      {{ productQuestion.product_name }}<br>
                      <small>品番1：{{ productQuestion.part_number_1 }}</small>
                    </h3>
                    <br>
                    <p>
                      質問者：{{ productQuestion.customer_name }}<br>質問日時：{{ productQuestion.question_at | unix_datetime_format }}
                    </p>
                    <h4>質問内容</h4>
                    <p><span class="line-break">{{ productQuestion.question_content }}</span></p>
                  </v-col>
                </v-row>
                <hr>
              </v-card-text>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    回答日時：{{ productQuestion.answer_at | unix_datetime_format }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="回答内容"
                      rules="required|max:65535"
                    >
                      <v-textarea
                        v-model="answerContent"
                        :error-messages="errors"
                        counter="65535"
                        label="回答"
                        outlined
                        placeholder="回答を入力してください。"
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-checkbox
                      v-model="displayAnswer"
                      class="pa-0 ma-0"
                      hide-details="auto"
                      label="商品Q&Aに回答を表示する"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="onCancel">
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="submit"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AlertMessage from '../../../components/AlertMessage'
import { mapActions } from 'vuex'
export default {
  components: {
    AlertMessage
  },
  data () {
    return {
      breadcrumbs: [
        { text: '商品問い合わせ一覧', disabled: false, to: '/backyard/qas', exact: true },
        { text: '商品問い合わせ詳細', disabled: true, to: '' },
      ],
      productQuestion: null,
      answerContent: '',
      displayAnswer: false,
      productQuestionId: null,
    }
  },
  created() {
    this.productQuestionId = this.$route.params.id
    const params = {
      id: this.productQuestionId
    }
    this.showProductQuestion(params).then((content) => {
      this.productQuestion = content
      this.answerContent = this.productQuestion.answer_content
      this.displayAnswer = this.productQuestion.display_answer
    })
  },
  methods: {
    ...mapActions('backProductQuestion', {
      showProductQuestion: 'show',
      updateProductQuestion: 'update',
      fetchUnansweredCount: 'fetchUnansweredCount',
    }),
    onCancel: function () {
      this.$router.push({name: 'byQaIndex'})
    },
    submit: function () {
      const params = {
        answer_content: this.answerContent,
        display_answer: this.displayAnswer
      }
      this.updateProductQuestion({id: this.productQuestionId, params}).then(() => {
        this.fetchUnansweredCount()
        this.$router.push({name: 'byQaIndex'})
      })
    },
  },
}
</script>

<style lang="scss">

</style>
