<!-- バックヤード > 商品お知らせform -->
<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              hide-details="auto"
              outlined
              clearable
              placeholder=" "
              :rules="[rules.required]"
            >
              <template v-slot:label>
                <span class="required">タイトル</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              outlined
              clearable
              counter
              label="詳細"
              auto-grow
              row-height="15"
              dense
              hide-details="auto"
              placeholder=" "
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-select
              :items="templates"
              dense
              label="メールテンプレート"
              hide-details="auto"
              outlined
              placeholder=" "
            />
          </v-col>
          <v-col cols="6">
            <v-btn @click="mailSendDialog = true">
              メール送信
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="2">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              offset-y
              transition="scale-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="startDate"
                  dense
                  readonly
                  hide-details="auto"
                  outlined
                  clearable
                  placeholder=" "
                  :rules="[rules.required]"
                  v-on="on"
                  @click:clear="startDate = null"
                >
                  <template v-slot:label>
                    <span class="required">開始日</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="startDate"
                @input="startDateMenu = false"
              />
            </v-menu>
          </v-col>
          <v-col
            cols="1"
            class="text-center"
          >
            〜
          </v-col>
          <v-col cols="2">
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              offset-y
              transition="scale-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="endDate"
                  dense
                  readonly
                  hide-details="auto"
                  outlined
                  clearable
                  placeholder=" "
                  :rules="[rules.required]"
                  v-on="on"
                  @click:clear="endDate = null"
                >
                  <template v-slot:label>
                    <span class="required">終了日</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="endDate"
                @input="endDateMenu = false"
              />
            </v-menu>
          </v-col>
        </v-row>

        <v-divider v-if="this.$route.name !== 'byMemberNoticeEditIndex'" />

        <v-row v-if="this.$route.name !== 'byMemberNoticeEditIndex'">
          <v-col>
            <div class="float-right">
              {{ memberCnt }}件
            </div>
          </v-col>
        </v-row>

        <v-row v-if="this.$route.name !== 'byMemberNoticeEditIndex'">
          <v-col>
            <v-card outlined>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" />
                      <th class="text-left">
                        会員番号
                      </th>
                      <th class="text-left">
                        会員名
                      </th>
                      <th class="text-left">
                        住所
                      </th>
                      <th class="text-left">
                        電話番号
                      </th>
                      <th class="text-left">
                        メールアドレス
                      </th>
                      <th class="text-right">
                        <v-btn
                          small
                          @click="memberSearchDialog = true"
                        >
                          追加
                        </v-btn>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="member in members"
                      :key="member.id"
                    >
                      <td>
                        <v-avatar>
                          <img
                            :src="member.image"
                            :alt="member.name"
                          />
                        </v-avatar>
                      </td>
                      <td>{{ member.no }}</td>
                      <td>{{ member.name }}</td>
                      <td>〒{{ member.post }}<br>{{ member.address }}</td>
                      <td>{{ member.tel }}</td>
                      <td>{{ member.mail }}</td>
                      <td class="text-right">
                        <v-btn
                          icon
                          text
                          small
                          color="error"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <MailSendDialog />
    <MemberSearchDialog />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import MailSendDialog from "../../dialogs/MailSendDialog";
  import MemberSearchDialog from "../../dialogs/MemberSearchDialog";

  export default {
    components: {
      MailSendDialog,
      MemberSearchDialog,
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        templates: ['メールテンプレート1','メールテンプレート2'],
        mailSendDialog: false,
        startDate: null,
        startDateMenu: false,
        endDate: null,
        endDateMenu: false,
        memberSearchDialog: false,
        memberCnt: 6,
        members: [
          {id: 1, no: '0001', name: '横浜 太郎', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-1741', mail: 'yokohama@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 2, no: '0002', name: '神奈川 花子', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-2584', mail: 'kanagawa@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 3, no: '0003', name: '川崎 花江', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '045-262-1741', mail: 'kawasaki@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
          {id: 4, no: '0004', name: '湘南 三郎', post: '231-0033', address: '神奈川県横浜市中区長者町4-11-11', tel: '080-1234-5678', mail: 'shonan@tact-info.co.jp', image: 'https://cdn.vuetifyjs.com/images/john.jpg'},
        ],
      }
    },
    methods: {
    },
  }
</script>

<style lang="scss">
</style>
