/**
 * 商品 関連商品
 */
import Vue from 'vue'
import * as types from '../../../mutation-types/backyard-types'
import * as httpConst from '../../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const product_relation = {
    namespaced: true,
    state: {
        pagination: {
            current_page: 1,
            total_count: 0
        },
        product_relations: []
    },
    getters: {
        isProductRelations: ( state ) => {
            return (state.pagination.total_count > 0)
        },
    },
    actions: {
        fetchAll ({ commit, state },{ product_id, page }) {
            const api_path = `${API_PATH}/${product_id}/product_relations`
            const params = { page: page }
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path, { params: params }).then(data => {
                    let content = data.content
                    commit(types.BACK_PRODUCT_RELATION, {content})
                    resolve(data.content)
                })
            })
        },
        create( { commit, state }, { product_id, params } ) {
            const api_path = `${API_PATH}/${product_id}/product_relations`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(api_path, params).then( data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    resolve(data.content)
                } )
            })
        },
        destroy( { commit, state }, { product_id, params } ) {
            const remove_id = params.id
            const api_path = `${API_PATH}/${product_id}/product_relations/${remove_id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    // let content = data.content
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
    },
    mutations: {
        [types.BACK_PRODUCT_RELATION] (state, { content }) {
            state.product_relations = content.product_relations
            state.pagination = content.pagination
        }
    },
}

export default product_relation