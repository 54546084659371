<!-- バックヤード > 店舗への問い合わせ一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          店舗への問い合わせ
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="query.subject"
                  dense
                  hide-details="auto"
                  label="件名"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="query.customer_no"
                  dense
                  hide-details="auto"
                  label="会員番号"
                  outlined
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="query.customer_name"
                  dense
                  hide-details="auto"
                  label="会員名"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="startDateMenu"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="query.start_date"
                      clearable
                      dense
                      hide-details="auto"
                      label="問い合わせ日（開始日）"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="query.start_date = null"
                    >
                      <template
                        v-if="!$vuetify.breakpoint.smAndDown"
                        v-slot:append-outer
                      >
                        &emsp;〜
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="query.start_date"
                    @input="startDateMenu = false"
                  />
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-menu
                  v-model="endDateMenu"
                  :close-on-content-click="false"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="query.end_date"
                      clearable
                      dense
                      hide-details="auto"
                      label="問い合わせ日（終了日）"
                      outlined
                      placeholder=" "
                      readonly
                      v-on="on"
                      @click:clear="query.end_date = null"
                    />
                  </template>
                  <v-date-picker
                    v-model="query.end_date"
                    @input="endDateMenu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                ステータス
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <v-checkbox
                  v-model="query.status_opened"
                  class="pa-0 ma-0"
                  hide-details="auto"
                  label="オープン"
                />
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <v-checkbox
                  v-model="query.status_in_process"
                  class="pa-0 ma-0"
                  hide-details="auto"
                  label="対応中"
                />
              </v-col>
              <v-col
                cols="12"
                md="1"
              >
                <v-checkbox
                  v-model="query.status_closed"
                  class="pa-0 ma-0"
                  hide-details="auto"
                  label="解決済"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="searchTickets"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    問い合わせ日時
                  </th>
                  <th class="text-left">
                    件名
                  </th>
                  <th class="text-left">
                    会員番号
                  </th>
                  <th class="text-left">
                    会員名
                  </th>
                  <th class="text-center">
                    ステータス
                  </th>
                  <th class="text-center">
                    公開中のコメント
                  </th>
                  <th class="text-left">
                    最終更新日時
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in tickets"
                  :key="item.id"
                >
                  <td>{{ item.created_at | unix_datetime_format }}</td>
                  <td>{{ item.subject | truncate(30) }}</td>
                  <td>{{ item.customer_no }}</td>
                  <td>{{ item.customer_name }}</td>
                  <td class="text-center">
                    {{ item.status | ticketStatusFilter }}
                  </td>
                  <td class="text-center">
                    {{ item.published | ticketPublishFilter }}
                  </td>
                  <td>{{ item.updated_at | unix_datetime_format }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="showEdit(item)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-pagination
      v-model="page"
      :length="pagination.total_pages"
      :total-visible="totalVisible"
      circle
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      @input="changePage"
    />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import TicketMixin from '../../../mixins/ticket-mixin'
  export default {
    mixins: [
      TicketMixin
    ],
    data () {
      return {
        page: 1,
        totalVisible: 7,
        query: {
          subject: null,
          customer_no: null,
          customer_name: null,
          start_date: null,
          end_date: null,
          status_opened: false,
          status_in_process: false,
          status_closed: false,
        },
        startDateMenu: false,
        endDateMenu: false,
        tickets: [],
      }
    },
    computed: {
      ...mapState('backTicket', {
        pagination: 'pagination',
        searchQuery: 'query',
      })
    },
    created() {
      if (this.$route.params.isClearCondition) {
        this.resetQueries()
      }
      Object.assign(this.$data.query, this.searchQuery)
      const searchStatusOpen = this.$route.query.status_opened
      if (searchStatusOpen) {
        this.query.status_opened = searchStatusOpen
      }

      const searchStatusInProcess = this.$route.query.status_in_process
      if (searchStatusInProcess) {
        this.query.status_in_process = searchStatusInProcess
      }
      this.searchTickets()
    },
    methods: {
      ...mapActions('backTicket', {
        findTickets: 'findTickets',
        resetQueries: 'resetQueries'
      }),
      showEdit: function (item) {
        this.$router.push({name: 'byInquiryEdit', params: {id: item.id}})
      },
      searchTickets() {
        const params = {
          subject: this.query.subject,
          customer_no: this.query.customer_no,
          customer_name: this.query.customer_name,
          start_date: this.query.start_date,
          end_date: this.query.end_date,
          status_opened: this.query.status_opened,
          status_in_process: this.query.status_in_process,
          status_closed: this.query.status_closed,
          page: this.page
        }
        this.findTickets({params}).then((content) => {
          this.tickets = content.tickets
        })
      },
      changePage () {
        this.searchTickets()
      },
    }
  }
</script>

<style lang="scss">

</style>
