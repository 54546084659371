<!-- バックヤード > 受注詳細 > 受注情報 -->
<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h2>
              <v-icon class="mr-2">
                mdi-diamond-stone
              </v-icon>
              受注情報
            </h2>
            <v-divider class="mt-2" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  ステータス
                </div>
                <h4>{{ order.status_name }}</h4>
                <v-divider />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  受注日
                </div>
                <h4>{{ order.order_date || '-' }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  注文番号
                </div>
                <h4>{{ order.order_no }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  ご利用開始日
                </div>
                <h4>{{ order.schedule_date || '-' }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  備考
                </div>
                <h4>{{ order.remarks }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="3"
              >
                <div class="overline">
                  小計
                </div>
                <h4>{{ order.total_price }} 円</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="3"
              >
                <div class="overline">
                  送料
                </div>
                <h4>{{ order.postage }} 円</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  支払総額
                </div>
                <h4>{{ order.total_payment }} 円</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  決済/入金確認日
                </div>
                <h4>{{ order.settle_date || '-' }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  注文取消日
                </div>
                <h4>{{ order.cancel_date || '-' }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  注文取消者
                </div>
                <h4>{{ order.cancel_user_name || '-' }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  注文取消理由
                </div>
                <h4>{{ order.cancel_note }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  延長注文番号
                </div>
                <h4>{{ order.extend_order_no || '-' }}</h4>
                <v-divider />
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <div class="overline">
                  延長受注日
                </div>
                <h4>{{ order.extend_order_date || '-' }}</h4>
                <v-divider />
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <div class="overline">
                  延長支払総額
                </div>
                <h4>{{ order.extend_total_payment }} 円</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  延長注文取消日
                </div>
                <h4>{{ order.extend_cancel_date || '-' }}</h4>
                <v-divider />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  延長注文取消者
                </div>
                <h4>{{ order.extend_cancel_user_name || '-' }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  延長注文取消理由
                </div>
                <h4>{{ order.extend_cancel_note }}</h4>
                <v-divider />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h2>
              <v-icon class="mr-2">
                mdi-human-male
              </v-icon>
              会員情報
            </h2>
            <v-divider class="mt-2" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  会員番号
                </div>
                <h4>{{ order.customer_no }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  会員名
                </div>
                <h4>{{ order.customer_name }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  住所
                </div>
                <h4>〒{{ order.customer_zip_code }}<br>{{ order.customer_full_address }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  電話番号
                </div>
                <h4>{{ order.customer_phone }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  メールアドレス
                </div>
                <h4>{{ order.customer_email }}</h4>
                <v-divider />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div v-if="!order.is_local_stock_item">
          <v-row>
            <v-col cols="12">
              <h2>
                <v-icon class="mr-2">
                  mdi-truck
                </v-icon>
                配送情報
              </h2>
              <v-divider class="mt-2" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="12"
                >
                  <div class="overline">
                    宛名
                  </div>
                  <h4>{{ order.delivery_name }}</h4>
                  <v-divider />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <div class="overline">
                    配送先住所
                  </div>
                  <h4>〒{{ order.delivery_zip_code }}<br>{{ order.delivery_full_address }}</h4>
                  <v-divider />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <div class="overline">
                    配送先電話番号
                  </div>
                  <h4>{{ order.delivery_phone }}</h4>
                  <v-divider />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <div class="overline">
                    配送希望時間
                  </div>
                  <h4>{{ order.delivery_desired_time_value }}</h4>
                  <v-divider />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

  import { mapActions } from 'vuex'

  export default {
    components: {
    },
    mixins: [
    ],
    data () {
      return {
        page: 1,
        totalVisible: 7,
        order: {},
        orderStocks: [],
        pagination: {
          total_pages: 0
        }
      }
    },
    created() {
      /**
       * @typedef {Object} content
       * @property {string} status_name
       * @property {string} settle_date
       * @property {string} cancel_date
       * @property {string} cancel_user_name
       * @property {string} extend_order_no
       * @property {string} extend_order_date
       * @property {string} extend_cancel_date
       * @property {string} extend_cancel_user_name
       * @property {string} extend_cancel_note
       * @property {string} customer_zip_code
       * @property {string} customer_full_address
       * @property {string} customer_phone
       * @property {string} customer_email
       * @property {string} delivery_full_address
       */
      this.fetchOrder({id: this.$route.params.id}).then((content) => {
        this.order = content
      })
    },
    methods: {
      ...mapActions('backOrder', {
        fetchOrder: 'fetch'
      }),
    },

  }
</script>

<style lang="scss">
</style>
