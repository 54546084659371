<template>
  <v-sheet
    height="100%"
    class="carouseBackGround"
    :style="`background-image: url('${option.image_url}');`"
  >
    <v-row
      class="fill-height"
      align="center"
      justify="center"
    >
      <v-img
        :src="option.image_url | load_image_url"
        contain
        height="100%"
        @click="imageClickEvent"
        :class="!option.use_button && option.transition_type != 'no_transition' ? 'cursor_pointer' : ''"
      >
        <v-row
          v-if="!xsOnly"
          class="fill-height mx-16"
          align="start"
        >
          <v-col
            cols="12"
            class="mt-16 pt-16"
          >
            <v-row class="pt-16">
              <v-col cols="12">
                <div
                  :class=" smAndDown ? 'text-h4' : 'text-h3'"
                  :style="{ color: option.font_color }"
                >
                  {{ option.title }}
                </div>
              </v-col>
              <v-col
                cols="12"
                class="mt-3"
              >
                <div
                  :class=" smAndDown ? 'text-h5' : 'text-h4'"
                  :style="{ color: option.font_color }"
                >
                  {{ option.sub_title }}
                </div>
              </v-col>
              <v-col
                v-if="option.use_button"
                cols="12"
              >
                <v-btn
                  v-if="isExternal"
                  outlined
                  :style="{ color: option.font_color }"
                  :href="option.transition_url"
                  target="_blank"
                >
                  {{ option.button_title }}
                </v-btn>
                <v-btn
                  v-else
                  outlined
                  :style="{ color: option.font_color }"
                  @click="buttonClickEvent"
                >
                  {{ option.button_title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-img>
    </v-row>
  </v-sheet>
</template>

<script>
  export default {
    name: 'TradeLaw',
    props: {
      option: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {

      }
    },
    computed: {
      xsOnly () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      },
      isExternal () {
        return this.option.transition_type === 'external'
      }
    },
    methods: {
      imageClickEvent () {
        if (this.option.use_button)
          return false

        this.buttonClickEvent()

      },
      buttonClickEvent () {
        // 各アクションを設定
        switch (this.option.transition_type) {
          case 'item_detail':
            // 商品詳細
            this.$router.push({name: 'ProductShow', params: { id: this.option.product_id }})
            break
          case 'category':
            // 商品一覧(カテゴリーで絞り込み）
            this.$router.push({name: 'ProductIndex', query: {category_id: this.option.category_id}})
            break
          case 'tag':
            // 商品一覧(タグで絞り込み)
            this.$router.push({name: 'ProductIndex', query: {tag_id: this.option.tag_id}})
            break
          case 'my_page':
            // マイページ
            this.$router.push({name: 'MyPageShow', params: {post_name: this.option.my_page_post_name, title: this.option.my_page_title}})
            break
          case 'external':
            // 外部
            window.open(this.option.transition_url, '_blank')
            break
          default:
            break
        }
      },
      dragMove(event, pointer, moveVector) {
        console.log(event, pointer, moveVector);
      },
    },
  }
</script>

<style scoped lang="scss">
.cursor_pointer { cursor: pointer}

</style>