<template>
  <div>
    <v-container
      class="grey lighten-5"
    >
      <v-breadcrumbs
        :items="breadcrumbList"
      />

      <v-card>
        <v-card-title>
          <h2>
            <v-icon
              class="mr-2"
            >
              mdi-office-building
            </v-icon>
            会社情報編集
          </h2>
        </v-card-title>
        <v-card-text>
          <CompanyForm
            v-if="company"
            :is-edit="true"
            :company="company"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>

  import { mapActions } from 'vuex'
  import CompanyForm from './_Form'

  export default {
    name: 'CompanyEdit',
    components: {CompanyForm},
    data () {
      return {
        company: null,
      }
    },
    computed: {

      breadcrumbList: function() {
        const breadcrumbs =  [
          { text: '会社', disabled: false, to: '/admin/companies', exact: true },
          { text: '会社詳細', disabled: false, href: '/admin/companies/' + this.$route.params.id + '/show' },
          { text: '会社情報編集', disabled: false, href: '' }
        ]
        return breadcrumbs
      }

    },
    created() {
      this.editCompany({ id: this.$route.params.id }).then((content) => {
        this.company = content
      })
    },
    methods: {
      ...mapActions('adminCompany', {
        editCompany: 'edit'
      }),
    }
  }
</script>

<style scoped>

</style>