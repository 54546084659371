<!-- バックヤード > タグform -->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|max:30"
                      name="タグ名"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="name"
                        :error-messages="errors"
                        counter="30"
                        dense
                        label="タグ名"
                        hide-details="auto"
                        outlined
                        placeholder=" "
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-checkbox
                      v-model="displayFooter"
                      label="フッターに表示する"
                      hide-details="auto"
                      class="pa-0 ma-0"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|numeric|min_value:0|max_value:2147483647"
                      name="表示順"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="position"
                        :error-messages="errors"
                        dense
                        label="表示順"
                        reverse
                        hide-details="auto"
                        outlined
                        placeholder=" "
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-checkbox
                      v-model="displayProduct"
                      label="商品一覧にタグを表示する"
                      hide-details="auto"
                      class="pa-0 ma-0"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="背景色"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="backgroundColor"
                        :error-messages="errors"
                        dense
                        hide-details="auto"
                        label="背景色"
                        outlined
                        placeholder=" "
                        readonly
                        @click="backgroundColorMenu = true"
                      >
                        <template v-slot:append>
                          <v-menu
                            v-model="backgroundColorMenu"
                            top
                            nudge-bottom="105"
                            nudge-left="16"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on }">
                              <div
                                :style="backgroundColorSwatchStyle"
                                v-on="on"
                              />
                            </template>
                            <v-card>
                              <v-card-text class="pa-0">
                                <v-color-picker
                                  v-model="backgroundColor"
                                  flat
                                />
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>


                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="文字色"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="fontColor"
                        :error-messages="errors"
                        dense
                        hide-details="auto"
                        label="文字色"
                        outlined
                        placeholder=" "
                        readonly
                        @click="fontColorMenu = true"
                      >
                        <template v-slot:append>
                          <v-menu
                            v-model="fontColorMenu"
                            top
                            nudge-bottom="105"
                            nudge-left="16"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on }">
                              <div
                                :style="fontColorSwatchStyle"
                                v-on="on"
                              />
                            </template>
                            <v-card>
                              <v-card-text class="pa-0">
                                <v-color-picker
                                  v-model="fontColor"
                                  flat
                                />
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row v-if="name">
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-card>
                      <v-card-subtitle>
                        プレビュー
                      </v-card-subtitle>
                      <v-card-text>
                        <v-chip
                          :color="backgroundColor"
                          :text-color="fontColor"
                          class="ma-2"
                          label
                          small
                        >
                          #{{ name }}
                        </v-chip>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="isEdit"
                  color="error"
                  @click="tagDelete"
                >
                  削除
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="tagCancel"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="submit"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>

    <confirm
      v-if="isEdit"
      ref="confirm"
    />
  </div>
</template>

<script>

import AlertMessage from '../../../components/AlertMessage'
import Confirm from '../../../components/Confirm'
import { mapActions } from 'vuex'

export default {
  name: 'TagForm',
  components: {
    AlertMessage,
    Confirm,
  },
  mixins: [],
  props: {
    tag: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {

      backgroundColorMenu: false,
      fontColorMenu: false,

      name: this.tag.name,
      position: this.tag.position,
      displayFooter: this.tag.display_footer,
      displayProduct: this.tag.display_product,
      backgroundColor: this.tag.background_color,
      fontColor: this.tag.font_color,
    }
  },
  computed: {
    backgroundColorSwatchStyle() {
      let _default_font_color = this.backgroundColor == null ? '#FFFFFF' : this.backgroundColor
      return this.colorChangeEvent(_default_font_color, this.backgroundColorMenu)
    },
    fontColorSwatchStyle () {
      let _selected_font_color = this.fontColor == null ? '#000000' : this.fontColor
      return this.colorChangeEvent(_selected_font_color, this.fontColorMenu)
    }
  },
  methods: {
    ...mapActions('backTag', {
      createTag: 'create',
      updateTag: 'update',
      destroyTag: 'destroy'
    }),
    colorChangeEvent (color, menu) {
      return {
        backgroundColor: color,
        marginBottom: '7px',
        border: '1px solid #ddd',
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    buildParams () {
      return {
        name: this.name,
        position: this.position,
        display_footer: this.displayFooter,
        display_product: this.displayProduct,
        background_color: this.backgroundColor,
        font_color: this.fontColor,
      }
    },
    tagCancel: function () {
      this.$router.push({name: 'TagIndex'})
    },
    tagDelete: function () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyTag({id: this.$route.params.id }).then(() =>{
            this.$router.push({name: 'TagIndex'})
          })
        }
      })
    },
    submit() {
      const params = this.buildParams()
      if (this.isEdit) {
        this.updateTag({ id: this.$route.params.id, params: params }).then(() =>{
          this.$router.push({name: 'TagIndex'})
        })
      } else {
        this.createTag({ params }).then(() =>{
          this.$router.push({name: 'TagIndex'})
        })
      }
    }
  }
}
</script>

<style lang="scss">
</style>
