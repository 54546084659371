/**
 * 商品在庫
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/products`

const product_stock = {
  namespaced: true,
  state: {
    product_stocks: []
  },
  getters: {},
  actions: {
    fetchAll({ dispatch, commit, state }, { product_id }) {
      const api_path = `${API_PATH}/${product_id}/product_stocks`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          commit(types.BACK_PRODUCT_STOCK, { content })
        })
      })
    },
    new ({ _commit }, { product_id }) {
      const api_path = `${API_PATH}/${product_id}/product_stocks/new`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    edit({ _commit, state }, { product_id, id }) {
      const api_path = `${API_PATH}/${product_id}/product_stocks/${id}/edit`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    create( { commit, state }, { product_id, params } ) {
      const api_path = `${API_PATH}/${product_id}/product_stocks`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          commit(types.BACK_PRODUCT_STOCK_ADD, { content })
          resolve(content)
        } )
      })
    },
    update( {commit, state}, { product_id, params } ) {
      // const oldContent = state.product_stocks.find((o) => {
      //   return o.id === params.id;
      // });
      const api_path = `${API_PATH}/${product_id}/product_stocks/${params.id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          // commit(types.BACK_PRODUCT_STOCK_UPDATE, { oldContent, content })
          resolve(content)
        } )
      })
    },
    destroy( { commit, state }, { product_id, params } ) {
      const remove_id = params.id
      const api_path = `${API_PATH}/${product_id}/product_stocks/${params.id}`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.delete(api_path).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          commit(types.BACK_PRODUCT_STOCK_DELETE, { remove_id })
          resolve(data.content)
        })
      })
    },
  },
  mutations: {
    [types.BACK_PRODUCT_STOCK] (state, { content }) {
      state.product_stocks = content.product_stocks
    },
    [types.BACK_PRODUCT_STOCK_ADD] (state, { content }) {
      state.product_stocks.push(content)
    },
    [types.BACK_PRODUCT_STOCK_UPDATE] (state, { oldContent, content }) {
      Object.assign(oldContent, content);
    },
    [types.BACK_PRODUCT_STOCK_DELETE] (state,  { remove_id }) {
      state.product_stocks = state.product_stocks.filter( o => o.id !== remove_id )
    }
  },
}

export default product_stock