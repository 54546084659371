<!-- バックヤード > 商品詳細 > 関連商品 -->
<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-right mb-3">
              <v-btn
                small
                @click="openItemSearchDialog"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                :headers="relationTableHeaders"
                :items="campaignProducts"
                :items-per-page="-1"
                dense
                disable-sort
                hide-default-footer
              >
                <template
                  v-slot:item.image_url="{ item }"
                  class="py-3"
                >
                  <div>
                    <v-img
                      v-if="item.image_url !== null"
                      :src="item.image_url"
                      max-height="45"
                      max-width="60"
                    />
                  </div>
                </template>
                <template v-slot:item.category_names="{ item }">
                  <span
                    class="wrap_text"
                    style="width: 200px;"
                  >
                    {{ item.category_names }}
                  </span>
                </template>
                <template v-slot:item.price="{ item }">
                  {{ item.price | comma_format }} 円
                </template>
                <template v-slot:item.display_date="{ item }">
                  {{ item.display_start_date | from_to_date_format(item.display_end_date) }}
                </template>
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="error"
                    icon
                    small
                    @click="productDeleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="productDeleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="isCampaignProducts"
            cols="12"
          >
            <v-pagination
              v-model="page"
              :length="pagination.total_pages"
              :total-visible="totalVisible"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              circle
              @input="changePage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <item-search-dialog
      ref="ItemSearchDialog"
      @itemSearchDialogCallback="itemSearchDialogCallback"
    />
    <confirm ref="confirm" />
  </div>
</template>

<script>
import Confirm from '../../../../components/Confirm'
import ItemSearchDialog from '../../../dialogs/ItemSearchDialog'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  components: {
    Confirm,
    ItemSearchDialog,
  },
  mixins: [
  ],
  props: {
    campaign: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      relationTableHeaders: [
        { value: 'image_url', text: '' },
        { value: 'part_number_1', text: '品番1' },
        { value: 'name', text: '商品名' },
        { value: 'category_names', text: 'カテゴリー' },
        { value: 'price', text: '金額' },
        { value: 'display_date', text: '表示期間' },
        { value: 'editable', text: '', align: 'end' },
      ],
      page: 1,
      totalVisible: 7,
    }
  },
  computed: {
    ...mapState('backCampaignProduct', {
      campaignProducts: 'campaign_products',
      pagination: 'pagination',
    }),
    ...mapGetters('backCampaignProduct', {
      isCampaignProducts: 'isCampaignProducts'
    })
  },
  created () {
    this.fetchCampaignProducts({campaign_id: this.campaign.id})
  },
  methods: {
    ...mapActions('backCampaignProduct', {
      fetchCampaignProducts: 'fetchAll',
      createCampaignProducts: 'create',
      destroyCampaignProduct: 'destroy'
    }),
    openItemSearchDialog () {
      this.$refs.ItemSearchDialog.openDialog({})
    },
    itemSearchDialogCallback ( items ) {
      const parameter = { product_ids: items }
      this.createCampaignProducts({ campaign_id: this.campaign.id, params: parameter })
    },
    productDeleteButtonClickEvent (campaign_product) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyCampaignProduct({ campaign_id: this.campaign.id, params: campaign_product })
        }
      })
    },
    changePage () {
      this.fetchCampaignProducts({campaign_id: this.campaign.id, page: this.page})
    },
  },
}
</script>

<style scoped lang="scss">
.wrap_text {
  display: inline-block;
  overflow-wrap: break-word;
}
</style>
