/**
 * キャンペーン
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/campaigns`

const campaign = {
    namespaced: true,
    state: {
        pagination: {
            current_page: 1,
            total_count: 0
        },
        query: {
            title: '',
            display_search: false,
            display_date: '',
        },
    },
    getters: {
        isCampaigns: ( state ) => {
            return (state.pagination.total_count > 0)
        },
    },
    actions: {
        new ({ _commit }) {
            const api_path = `${API_PATH}/new`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    const content = data.content
                    resolve(content)
                })
            })
        },
        edit ({ commit, state }, {id}) {
            const api_path = `${API_PATH}/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    const content = data.content
                    resolve(content)
                })
            })
        },
        create ({ commit, state }, { params }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.post(API_PATH, params).then(data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    const content = data.content
                    resolve(content)
                })
            })
        },
        update ({ commit }, { id, params }) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    const content = data.content
                    resolve(content)
                } )
            })
        },
        destroy( { commit, state }, { id } ) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    const content = data.content
                    resolve(content)
                })
            })
        },
        findCampaigns ({ _commit },{ params }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    resolve(data.content)
                })
            })
        },
        fetchCampaigns({ dispatch, commit, state }, {title, display_search, display_date, page}) {
            dispatch('fetchQuery', {title: title, display_search: display_search, display_date: display_date})
            const params = { title: state.query.title, display_search: state.query.display_search, display_date: state.query.display_date, page: page }

            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    commit(types.BACKYARD_CAMPAIGN, { content })
                    resolve(data.content)
                })
            })
        },
        fetchQuery({ commit, state}, {title, display_search, display_date}) {
            const Title = (title !== undefined)? title : state.query.title
            const displaySearch = (display_search !== undefined)? display_search : state.query.display_search
            const displayDate = (display_date !== undefined)? display_date : state.query.display_date
            const params = { title: Title, display_search: displaySearch, display_date: displayDate}
            commit(types.BACKYARD_CAMPAIGN_QUERY, { params })
        },
        find ({ _commit }, {id}) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        }
    },
    mutations: {
        [types.BACKYARD_CAMPAIGN] (state, { content }) {
            state.pagination = content.pagination
        },
        [types.BACKYARD_CAMPAIGN_QUERY] (state, { params }) {
            state.query.title = params.title
            state.query.display_search = params.display_search
            state.query.display_date = params.display_date
        },
    },
}

export default campaign