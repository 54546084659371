/**
 * 顧客備考
 */
import Vue from 'vue'
import * as httpConst from '../../../../constants/httpConst'
const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/customers`
const customerRemark = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    update ({ commit }, { customer_id, params }) {
      const api_path = `${API_PATH}/${customer_id}/remarks`
      return new Promise((resolve, _reject) => {
        Vue.$http.put(api_path, params).then(data => {
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          const content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {
  },
}
export default customerRemark

