/**
 * パスワード忘れ
 */
import Vue from "vue";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/forget`

const forget = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    create( { commit, state }, { params } ) {
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(API_PATH, params).then( data => {
          let content = data.content
          resolve(content)
        } )
      })
    },
    update( {commit, state}, { token, params } ) {
      const api_path = `${API_PATH}/${token}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          let content = data.content
          resolve(content)
        } )
      })
    }
  },
  mutations: {},
}

export default forget