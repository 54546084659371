/**
 * オプショングループ
 */
import Vue from "vue";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/option_groups`

const option_group = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetch({ _commit }, { id }) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {
  },
}
export default option_group
