<!-- バックヤード > ユーザー追加 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-row>
      <v-col>
        <user-form
          v-if="user"
          :user="user"
          :is-edit="false"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import UserForm from './_Form'

  export default {
    components: {
      UserForm,
    },
    mixins: [],
    data () {
      return {
        user: null,
        breadcrumbs: [
          { text: 'ユーザー一覧', disabled: false, to: '/backyard/users', exact: true },
          { text: 'ユーザー詳細', disabled: true, to: '' },
        ],
      }
    },
    created() {
      this.newUser().then(( content ) => {
        this.user = content
      })
    },
    methods: {
      ...mapActions('backUser', {
        newUser: 'new'
      }),
      userCancel: function () {
        this.$router.push({name: 'byUserIndex'})
      },
      userSave: function () {
        this.$router.push({name: 'byUserIndex'})
      },
    },
  }
</script>

<style lang="scss">

</style>
