<!-- バックヤード > タグ一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          タグマスタ
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              @click="newTag"
            >
              タグ追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="inputName"
                      dense
                      label="タグ名"
                      hide-details="auto"
                      outlined
                      clearable
                      placeholder=" "
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn color="primary" @click="resetTags"> 検索 </v-btn>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    タグ名
                  </th>
                  <th class="text-center">
                    プレビュー
                  </th>
                  <th class="text-center">
                    フッター表示
                  </th>
                  <th class="text-center">
                    表示順
                  </th>
                  <th class="text-center">
                    商品一覧に表示
                  </th>
                  <th class="text-left">
                    作成日時
                  </th>
                  <th class="text-left">
                    更新日時
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="tag in tags"
                  :key="tag.id"
                >
                  <td>{{ tag.name }}</td>
                  <td class="text-center">
                    <v-card-text>
                      <v-chip
                          :color="tag.background_color"
                          :text-color="tag.font_color"
                          class="ma-2"
                          label
                          small
                      >
                        #{{ tag.name }}
                      </v-chip>
                    </v-card-text>
                  </td>
                  <td class="text-center">
                    {{ tag.display_footer ? '表示' : '非表示' }}
                  </td>
                  <td class="text-center">
                    {{ tag.position }}
                  </td>
                  <td class="text-center">
                    {{ tag.display_product ? '表示' : '非表示' }}
                  </td>
                  <td>{{ tag.created_at | unix_datetime_format }}</td>
                  <td>{{ tag.updated_at | unix_datetime_format }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="editTag(tag)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-3">
      <v-col>
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    name: 'TagIndex',
    components: {
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        page: 1,
        totalVisible: 7,
        inputName: '',
        tags:[],
      }
    },
    computed: {
      ...mapState('backTag', {
        pagination: 'pagination',
        searchQuery: 'query',
      }),
      ...mapGetters('backTag', [
        'isTags'
      ])
    },
    created() {
      this.page = this.pagination.current_page
      this.searchTags()
    },
    methods: {
      ...mapActions('backTag', {
        fetchTags: 'fetchTags',
        fetchQuery: 'fetchQuery',
      }),
      resetFilters () {
        this.inputName = ''
        this.resetTags()
      },
      newTag () {
        // タグ新規追加へ
        this.$router.push({name: 'TagNew'})
      },
      editTag (tag) {
        // タグ編集へ
        this.$router.push({name: 'TagEdit', params: { id: tag.id }})
      },
      resetTags () {
        this.filterDialog = false
        this.page = 1
        this.searchTags()
      },
      changePage () {
        this.searchTags()
      },
      searchTags () {
        const params = { name: this.inputName, page: this.page }
        this.fetchTags(params).then((content) => {
          this.tags = content.tags
        })
      }
    }
  }
</script>

<style lang="scss">

</style>
