var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("\n      問い合わせ履歴\n    ")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.ticketTableHeaders,"items":_vm.tickets,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("unix_datetime_format")(item.created_at))+"\n              ")]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("truncate")(item.subject,30))+"\n              ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("ticketStatusFilter")(item.status))+"\n              ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("unix_datetime_format")(item.updated_at))+"\n              ")]}},{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"cyan darken-2"},on:{"click":function($event){return _vm.showEdit(item)}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1):_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.showEdit(item)}}},[_vm._v("\n                  参照する\n                ")])]}}])})],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('v-pagination',{attrs:{"length":_vm.pagination.total_pages,"total-visible":_vm.totalVisible,"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right","circle":""},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }