<template>
  <v-container
    class="grey lighten-5"
  >
    <v-breadcrumbs
      :items="breadcrumbs"
    />
    <v-card v-if="announcement">
      <v-card-title>
        <h2>
          <v-icon
            class="mr-2"
          >
            mdi-bell
          </v-icon>
          システムお知らせ詳細
        </h2>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-btn
              color="error"
              @click="deleteButtonClickEvent"
            >
              削除
            </v-btn>
          </v-col>
          <v-col cols="9">
            <div class="text-right">
              <v-btn
                color="primary"
                @click="editButtonClickEvent"
              >
                編集
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <br>
        <v-row>
          <v-col cols="12">
            <h2>
              詳細情報
            </h2>
            <v-divider class="mt-2" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  タイトル
                </div>
                <h4>{{ announcement.title }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="12"
              >
                <div class="overline">
                  本文
                </div>
                <h4><span class="line-break">{{ announcement.description }}</span></h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  お知らせ日
                </div>
                <h4>{{ announcement.notice_date | date_format }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  表示期間
                </div>
                <h4>{{ announcement.display_start_date | from_to_date_format(announcement.display_end_date) }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  全社配信
                </div>
                <h4>{{ announcement.announce_all }}</h4>
                <v-divider />
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="overline">
                  配信先会社
                </div>
                <h4>{{ announcement.company_names }}</h4>
                <v-divider />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <Confirm ref="confirm" />
  </v-container>
</template>

<script>

import Confirm from '../../../components/Confirm'
import { mapActions } from 'vuex'

export default {
  name: 'AnnouncementShow',
  components: {
    Confirm
  },
  mixins: [],
  data(){
    return {
      announcement: null,
      breadcrumbs: [
        { text: 'システムお知らせ', disabled: false, to: '/admin/announcements', exact: true },
        { text: 'システムお知らせ詳細', disabled: true, href: '' },
      ],
    }
  },
  computed: {
  },
  mounted() {
    this.$nextTick(function () {
      // ビュー全体がレンダリングされた後にのみ実行されるコード
    })
  },
  created() {
    // 詳細取得
    this.showAnnouncement({id: this.$route.params.id}).then(( content ) => {
      this.announcement = content
    })
  },
  methods: {
    ...mapActions('adminAnnouncement', {
      showAnnouncement: 'fetch',
      destroyAnnouncement: 'destroy'
    }),

    // 削除ボタン
    deleteButtonClickEvent () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyAnnouncement({ id: this.$route.params.id }).then(() => {
          this.$router.push({ name: 'adAnnouncementIndex' })})
        }
      })
    },
    // 更新ボタン
    editButtonClickEvent () {
      this.$router.push( { name: 'adAnnouncementEdit', params: { id: this.$route.params.id } } )
    }
  }
}
</script>

<style scoped>

</style>