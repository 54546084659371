/**
 * 店舗への問い合わせ
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/backyard-types";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/tickets`

const ticket = {
  namespaced: true,
  state: {
    unclosedCount: 0,
    query: {
      status_opened: false,
      status_in_process: false,
      status_closed: false
    },
    pagination: {
      current_page: 1,
      total_count: 0,
    },
  },
  getters: {},
  actions: {
    findTickets({ commit }, { params: params }) {
      commit(types.BACK_TICKET_QUERY, { params })
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_TICKET, { content })
          resolve(content)
        })
      })
    },
    resetQueries({commit}) {
      const params = {
        subject: null,
        customer_no: null,
        customer_id: null,
        customer_name: null,
        start_date: null,
        end_date: null,
        status_opened: false,
        status_in_process: false,
        status_closed: false,
      }
      commit(types.BACK_TICKET_QUERY, { params })
    },
    create ({ commit }, { params }) {
      return new Promise((resolve, reject) => {
        Vue.$http.post(API_PATH, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(data.content)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    findTicket ({ _commit }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    close({ commit }, {id}) {
      const api_path = `${API_PATH}/${id}/close`
      return new Promise((resolve, _reject) => {
        Vue.$http.put(api_path).then(data => {
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
    fetchUnclosedCount({ commit }) {
      const api_path = `${API_PATH}/unclosed_count`
      return new Promise((resolve, reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          commit(types.BACK_TICKET_UNCLOSED_COUNT, { count: content.unclosed_count })
          resolve(content.unclosed_count)
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
  },
  mutations: {
    [types.BACK_TICKET] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_TICKET_QUERY] (state, { params }) {
      state.query = params
    },
    [types.BACK_TICKET_UNCLOSED_COUNT] (state, { count }) {
      state.unclosedCount = count
    },
  },
}

export default ticket
