/**
 * 会員管理
 */
import Vue from 'vue'
import * as types from '../../mutation-types/backyard-types'
import * as httpConst from '../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/customers`

const customer = {
    namespaced: true,
    state: {
        pagination: {
            current_page: 1,
            total_count: 0
        },
        query: {
            no: '',
            name: '',
            address: '',
            phone: '',
            international_phone:'',
            email: '',
            withDeleted: false,
        },
    },
    getters: {
        isCustomers: ( state ) => {
            return (state.pagination.total_count > 0)
        },
    },
    actions: {
        show ({ _commit }, {id}) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve) => {
                Vue.$http.get(api_path).then(data => {
                    const content = data.content
                    resolve(content)
                })
            })
        },
        new () {
            const api_path = `${API_PATH}/new`
            return new Promise((resolve) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        edit ({ _commit }, {id}) {
            const api_path = `${API_PATH}/${id}/edit`
            return new Promise((resolve) => {
                Vue.$http.get(api_path).then(data => {
                    const content = data.content
                    resolve(content)
                })
            })
        },
        create ({ commit }, { params }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.post(API_PATH, params).then(data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
        update ({ commit }, { id, params }) {
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(data.content)
                } )
            })
        },
        delete ({ commit }, { id }) {
            const api_path = `${API_PATH}/${id}`
            return new Promise((resolve, reject) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '退会しました' }, { root: true })
                    resolve(data.content)
                }).catch((error) => {
                    console.log(error)
                    reject()
                })
            })
        },
        findCustomers ({ _commit },{ params }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    resolve(data.content)
                })
            })
        },
        fetchCustomers({ dispatch, commit, state }, {no, name, address, phone, international_phone, email, withDeleted, page}) {
            dispatch('fetchQuery', {no: no, name: name, address: address, phone: phone, international_phone: international_phone, email: email, withDeleted: withDeleted })
            const params = {
                no: state.query.no,
                name: state.query.name,
                address: state.query.address,
                phone: state.query.phone,
                international_phone: state.query.international_phone,
                email: state.query.email,
                with_deleted: state.query.withDeleted,
                page: page
            }

            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    commit(types.BACK_CUSTOMER, { content })
                    resolve(data.content)
                })
            })
        },
        fetchQuery({ commit, state}, {no, name, address, phone, international_phone,email, withDeleted}) {
            const No = (no !== undefined)? no : state.query.no
            const Name = (name !== undefined)? name : state.query.name
            const Address = (address !== undefined)? address : state.query.address
            const Phone = (phone !== undefined)? phone : state.query.phone
            const International_phone = (international_phone !== undefined)? phone : state.query.international_phone
            const Email = (email !== undefined)? email : state.query.email
            const WithDeleted = (withDeleted !== undefined)? withDeleted : state.query.withDeleted
            const params = { no: No, name: Name, address: Address, phone: Phone, international_phone:International_phone, email: Email, withDeleted: WithDeleted}
            commit(types.BACK_CUSTOMER_QUERY, { params })
        },

    },
    mutations: {
        [types.BACK_CUSTOMER] (state, { content }) {
            state.pagination = content.pagination
        },
        [types.BACK_CUSTOMER_QUERY] (state, { params }) {
            state.query.no = params.no
            state.query.name = params.name
            state.query.address = params.address
            state.query.phone = params.phone
            state.query.international_phone = params.international_phone
            state.query.email = params.email
            state.query.withDeleted = params.withDeleted
        },
    },
}

export default customer
