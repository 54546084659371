<!-- バックヤード > 商品詳細 > 商品検索設定 -->
<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <h3>タグ</h3>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="showNewTagModal"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="productTags"
                :headers="tagTableHeaders"
                :items="productTags"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="error"
                    icon
                    @click="tagDeleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="tagDeleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col cols="12">
            <h3>キーワード</h3>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="showNewKeywordModal"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="productKeywords"
                :headers="keywordTableHeaders"
                :items="productKeywords"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="error"
                    icon
                    @click="keywordDeleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="keywordDeleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="isShowTagDialog"
      width="400"
      @close="closeTagDialog"
    >
      <validation-observer
        v-slot="{ invalid }"
        ref="tagFormValidation"
      >
        <v-form>
          <v-card>
            <v-card-title>タグ登録</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="タグ"
                    class="requiredMark"
                  >
                    <v-select
                      v-model="productTag.tag_id"
                      :items="tagContent.tags"
                      :error-messages="errors"
                      dense
                      item-text="name"
                      item-value="id"
                      hide-details="auto"
                      label="タグ"
                      outlined
                      placeholder=" "
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeTagDialog">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="tagDialogRegisterButtonClickEvent"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>

    <v-dialog
      v-model="isShowKeywordDialog"
      width="600"
      @close="closeKeywordDialog"
    >
      <validation-observer
        v-slot="{ invalid }"
        ref="keywordFormValidation"
      >
        <v-form>
          <v-card>
            <v-card-title>キーワード登録</v-card-title>
            <v-card-text style="max-height: 70vh;overflow-y: auto;padding-top: 5px;">
              <v-row>
                <v-col>
                  <validation-provider v-slot="{ errors }" rules="required|max:1500" name="キーワード" class="requiredMark">
                    <v-textarea
                      v-model="productKeyword.keyword"
                      :error-messages="errors"
                      auto-grow
                      counter="1500"
                      dense
                      hide-details="auto"
                      label="キーワード"
                      outlined
                      persistent-placeholder
                      required
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeKeywordDialog">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="keywordDialogRegisterButtonClickEvent"
              >
                保存
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>

    <confirm ref="confirm" />
  </div>
</template>

<script>
import Confirm from '../../../../components/Confirm'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    Confirm
  },
  mixins: [
  ],
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      tagTableHeaders: [
        { value: 'tag_name', text: 'タグ名', align: 'start' },
        { value: 'editable', text: '', align: 'end' }
      ],
      keywordTableHeaders: [
        { value: 'keyword', text: 'キーワード', align: 'start' },
        { value: 'editable', text: '', align: 'end' }
      ],
      isShowKeywordDialog: false,
      isShowTagDialog: false,
      productTag: {
        tag_id: null
      },
      productKeyword: {
        keyword: null
      },
    }
  },
  computed: {
    ...mapState('backSearchTag', {
      tagContent: 'content'
    }),
    ...mapState('backProductTag', {
      productTags: 'product_tags'
    }),
    ...mapState('backProductKeyword', {
      productKeywords: 'product_keywords'
    })
  },
  created() {
    this.fetchSearchTag()
    this.fetchProductTags({product_id: this.product.id})
    this.fetchProductKeywords({product_id: this.product.id})
  },
  methods: {
    ...mapActions('backProductTag', {
      fetchProductTags: 'fetchAll',
      createProductTag: 'create',
      destroyProductTag: 'destroy'
    }),
    ...mapActions('backProductKeyword', {
      fetchProductKeywords: 'fetchAll',
      createProductKeyword: 'create',
      destroyProductKeyword: 'destroy'
    }),
    ...mapActions('backSearchTag', {
      fetchSearchTag: 'fetch'
    }),
    tagDialogRegisterButtonClickEvent () {
      this.createProductTag({ product_id: this.product.id, params: this.productTag }).then(() => {
        this.closeTagDialog()
      })
    },
    tagDeleteButtonClickEvent (product_tag) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyProductTag({ product_id: this.product.id, params: product_tag })
        }
      })
    },
    keywordDialogRegisterButtonClickEvent () {
      this.createProductKeyword({ product_id: this.product.id, params: this.productKeyword }).then(() => {
        this.closeKeywordDialog()
      })
    },
    keywordDeleteButtonClickEvent (product_keyword) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyProductKeyword({ product_id: this.product.id, params: product_keyword })
        }
      })
    },
    showNewTagModal () {
      Object.assign(this.$data.productTag, this.$options.data().productTag)
      this.isShowTagDialog = true
    },
    showNewKeywordModal () {
      Object.assign(this.$data.productKeyword, this.$options.data().productKeyword)
      this.isShowKeywordDialog = true
    },
    closeTagDialog () {
      Object.assign(this.$data.productTag, this.$options.data().productTag)
      this.$nextTick(() => this.$refs.tagFormValidation.reset())
      this.isShowTagDialog = false
    },
    closeKeywordDialog () {
      Object.assign(this.$data.productKeyword, this.$options.data().productKeyword)
      this.$nextTick(() => this.$refs.keywordFormValidation.reset())
      this.isShowKeywordDialog = false
    }
  },
}
</script>

<style scoped lang="scss">

</style>
