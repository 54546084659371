<!-- バックヤード > 在庫一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          在庫一覧
        </h2>
      </v-col>
    </v-row>
    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="selectedStatus"
                  :items="statuses"
                  clearable
                  dense
                  hide-details="auto"
                  label="在庫状態"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="selectedCategories"
                  :items="categoryContent.categories"
                  dense
                  hide-details="auto"
                  item-text="name"
                  item-value="id"
                  label="カテゴリー"
                  multiple
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="selectedTags"
                  :items="tagContent.tags"
                  dense
                  hide-details="auto"
                  item-text="name"
                  item-value="id"
                  label="タグ"
                  multiple
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="inputtedPartNo"
                  dense
                  hide-details="auto"
                  label="品番１"
                  outlined
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="inputtedKeyword"
                  dense
                  hide-details="auto"
                  label="キーワード"
                  outlined
                  placeholder=" "
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-checkbox
                  v-model="selectedLocalStockItem"
                  label="現地在庫品"
                  hide-details="auto"
                  class="pa-0 ma-0"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="resetStocks"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th />
                  <th class="text-left">
                    品番1/商品名
                  </th>
                  <th class="text-left">
                    在庫状態
                  </th>
                  <th class="text-center">
                    在庫<br>管理番号
                  </th>
                  <th class="text-center">
                    現地<br>在庫品
                  </th>
                  <th class="text-center">
                    スケジュール<br>使用
                  </th>
                  <th class="text-center">
                    貸出期間
                  </th>
                  <th class="text-left">
                    備考
                  </th>
                  <th class="text-left">
                    管理番号
                  </th>
                  <th class="text-left">
                    在庫場所
                  </th>
                  <th class="text-left">
                    受注日
                  </th>
                  <th class="text-left">
                    注文番号
                  </th>
                  <th class="text-left">
                    会員名
                  </th>
                  <th class="text-center">
                    編集
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="product_stock in productStocks"
                  :key="product_stock.id"
                >
                  <td>
                    <v-img
                      v-if="product_stock.product && product_stock.product.image_url != null"
                      :src="product_stock.product.image_url"
                      max-height="40"
                      max-width="60"
                      contain
                    />
                  </td>
                  <td>
                    <span v-if="product_stock.product">
                      {{ product_stock.product.part_number_1 }}<br>{{ product_stock.product.name }}
                    </span>
                  </td>
                  <td class="text-left">
                    {{ product_stock.status_name }}
                  </td>
                  <td class="text-right">
                    {{ product_stock.no }}
                  </td>
                  <td class="text-center">
                    {{ product_stock.product.local_stock_item ? '◯' : '' }}
                  </td>
                  <td class="text-center">
                    {{ product_stock.product.use_calendar ? '◯' : '' }}
                  </td>
                  <td class="text-center">
                    {{ product_stock.rental_from_date | from_to_date_format(product_stock.rental_to_date) }}
                  </td>
                  <td>
                    <span
                      :title="product_stock.note"
                      class="wrap_text"
                      style="width: 100px;"
                    >
                      {{ product_stock.note }}
                    </span>
                  </td>
                  <td>
                    <span
                      :title="product_stock.control_number"
                      class="wrap_text"
                      style="width: 100px;"
                    >
                      {{ product_stock.control_number }}
                    </span>
                  </td>
                  <td>
                    <span
                      :title="product_stock.stock_location"
                      class="wrap_text"
                      style="width: 100px;"
                    >
                      {{ product_stock.stock_location }}
                    </span>
                  </td>
                  <td>
                    <span v-if="product_stock.order">
                      {{ product_stock.order.order_date }}
                    </span>
                  </td>
                  <td>
                    <a
                      v-if="product_stock.order"
                      class="text-decoration-underline red--text"
                      @click="showOrderDetailButtonClickEvent(product_stock.order)"
                    >
                      {{ product_stock.order.order_no }}
                    </a>
                  </td>
                  <td>
                    <span v-if="product_stock.order">
                      {{ product_stock.order.name }}
                    </span>
                  </td>
                  <td class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }" v-if="!product_stock.order">
                        <v-btn
                          v-bind="attrs"
                          icon
                          color="cyan darken-2"
                          @click="openStockRegistDialog(product_stock)"
                          v-on="on"
                        >
                          <v-icon>mdi-file-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>編集</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="hasStocks"
        cols="12"
      >
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>
    <stock-regist-dialog
      ref="StockRegistDialog"
      @StockDialogCallback="stockDialogCallback"
    />
    <confirm ref="confirm" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Confirm from '../../../components/Confirm'
import StockRegistDialog from '../../dialogs/StockRegistDialog'

export default {
  name: 'ByProductStockIndex',
  components: {
    StockRegistDialog,
    Confirm,
  },
  mixins: [
  ],
  data () {
    return {
      page: 1,
      filterDialog: false,
      totalVisible: 7,
      panel:[],
      selectedTags: [],
      selectedCategories: [],
      inputtedPartNo: null,
      inputtedKeyword: null,
      selectedLocalStockItem: false,
      productStocks: [],
      statuses: [],
      product_statuses: [],
      selectedStatus: null,
    }
  },
  computed: {
    ...mapState('backSearchCategory', {
      categoryContent: 'content'
    }),
    ...mapState('backSearchTag', {
      tagContent: 'content'
    }),
    ...mapState('backStock', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
    ...mapGetters('backStock', [
      /** @typedef {boolean} hasStocks */
      'hasStocks'
    ])
  },
  /**
   * 状態監視
   */
  watch: {
    selectedStatus (newVal) {
      this.fetchQuery({ _status: newVal })
    },
    selectedCategories (newVal) {
      this.fetchQuery({ category_ids: newVal })
    },
    selectedTags (newVal) {
      this.fetchQuery({ tag_ids: newVal })
    },
    inputtedPartNo (newVal) {
      this.fetchQuery({ part_no: newVal })
    },
    inputtedKeyword(newVal) {
      this.fetchQuery({ keyword: newVal })
    },
    selectedLocalStockItem(newVal) {
      this.fetchQuery({ local_stock_item: newVal })
    },
  },
  created() {
    if (this.$route.params.isClearCondition) {
      this.resetQuery()
    }
    const searchPartNumber = this.$route.query.part_no
    if (searchPartNumber) {
      this.inputtedPartNo = searchPartNumber
    } else {
      this.inputtedPartNo = this.searchQuery.part_no
    }

    this.selectedStatus = this.searchQuery.status
    this.selectedCategories = this.searchQuery.category_ids
    this.selectedTags = this.searchQuery.tag_ids
    this.inputtedKeyword = this.searchQuery.keyword
    this.fetchSearchCategory()
    this.fetchSearchTag()
    this.page = this.pagination.current_page
    this.searchStocks()
  },
  methods: {
    ...mapActions('backSearchCategory', {
      fetchSearchCategory: 'fetch'
    }),
    ...mapActions('backSearchTag', {
      fetchSearchTag: 'fetch'
    }),
    ...mapActions('backStock', {
      fetchStocks: 'fetchStocks',
      fetchQuery: 'fetchQuery',
      resetQuery: 'resetQuery'
    }),
    ...mapActions('backProductStock', {
      editProductStock: 'edit',
    }),

    openStockRegistDialog (stock) {
      this.$refs.StockRegistDialog.openDialog({product_id: stock.product.id, product_stock: JSON.parse(JSON.stringify(stock)), product_use_calendar: stock.product.use_calendar})
    },
    resetStocks () {
      this.panel=[]
      this.page = 1
      this.searchStocks()
    },
    changePage () {
      this.searchStocks()
    },
    stockDialogCallback () {
      this.searchStocks();
    },
    showOrderDetailButtonClickEvent (order) {
      this.$router.push({name: 'byOrderShow', params: {id: order.id}})
    },
    searchStocks () {
      const params = {
        status: this.selectedStatus,
        category_ids: this.selectedCategories,
        tag_ids: this.selectedTags,
        part_no: this.inputtedPartNo,
        keyword: this.inputtedKeyword,
        local_stock_item: this.selectedLocalStockItem,
        page: this.page
      }
      this.fetchStocks(params).then((content) => {
        this.productStocks = content.product_stocks
        this.statuses = content.statuses
      })
    }
  }
}
</script>

<style scoped lang="scss">
.wrap_text {
  display: inline-block;
  overflow: hidden;
  //overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th, td {
  padding: 3px !important;
}

</style>
