<!-- バックヤード > システムへの問い合わせ編集 -->
<template>
  <div>
    <EditForm />
    <InquiryRegistDialog />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import EditForm from "./_EditForm"
  import InquiryRegistDialog from "../../dialogs/InquiryRegistDialog";

  export default {
    components: {
      EditForm,
      InquiryRegistDialog,
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        inquiryRegistDialog: false,
      }
    },
    methods: {
      systemInquiryCancel: function () {
        this.$router.push({name: 'bySystemInquiryIndex'})
      },
    },
  }
</script>

<style lang="scss">

</style>
