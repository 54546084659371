<template>
  <v-container>
    <div style="margin: 70px auto;">
      <h1 class="text-center">
        Page Not Found
      </h1>
      <h2 class="text-center">
        お探しのページが見つかりません
      </h2>
      <p class="text-center">
        URLに間違いないか確認し、再度アクセスを試してください。<br>
        また、つながらない場合は時間をおいて再度アクセスしてください。
      </p>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>