import AdminApp from '../admin/App'
import SessionIndex from '../admin/pages/sessions/Index'
import adCompanyIndex from '../admin/pages/companies/Index'
import adCompanyNew from '../admin/pages/companies/New'
import adCompanyShow from '../admin/pages/companies/Show'
import adCompanyEdit from '../admin/pages/companies/Edit'

import adAnnouncementIndex from '../admin/pages/announcements/Index'
import adAnnouncementNew from '../admin/pages/announcements/New'
import adAnnouncementShow from '../admin/pages/announcements/Show'
import adAnnouncementEdit from '../admin/pages/announcements/Edit'
import NotFound from "../components/pages/NotFound";
export default {
    path: '/admin',
    component: AdminApp,
    children: [
        { path: '/', name: 'AdminSessionIndex', component: SessionIndex },
        { path: '/admin/companies', name: 'adCompanyIndex', component: adCompanyIndex },
        { path: '/admin/companies/new', name: 'adCompanyNew', component: adCompanyNew },
        { path: '/admin/companies/:id/show', name: 'adCompanyShow', component: adCompanyShow },
        { path: '/admin/companies/:id/edit', name: 'adCompanyEdit', component: adCompanyEdit },

        { path: '/admin/announcements', name: 'adAnnouncementIndex', component: adAnnouncementIndex },
        { path: '/admin/announcements/new', name: 'adAnnouncementNew', component: adAnnouncementNew },
        { path: '/admin/announcements/:id/show', name: 'adAnnouncementShow', component: adAnnouncementShow },
        { path: '/admin/announcements/:id/edit', name: 'adAnnouncementEdit', component: adAnnouncementEdit },
        { path: '/admin/*', name: 'adNotFound', component: NotFound }
    ]
}