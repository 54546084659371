/**
 * タグ
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/search/tags`

const tag = {
  namespaced: true,
  state: {
    content: [],
  },
  getters: {},
  actions: {
    fetch({ commit }) {

      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          commit(types.BACK_SEARCH_TAG, { content })
          resolve(content)
        })
      })
    },
  },
  mutations: {
    [types.BACK_SEARCH_TAG] (state, { content }) {
      state.content = content
    },
  },
}

export default tag