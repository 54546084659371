<template>
  <div>
    <h3 class="d-flex justify-space-between align-center">
      <v-btn
        icon
        color="grey"
        @click="toBackPayment"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span>
        利用規約同意
        <div class="lang_lbl">{{$t('view.利用規約同意')}}</div>
      </span>
      <span style="min-width: 36px" />
    </h3>
    <alert-message class="mb-0"/>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-row dense>
          <v-card flat>
            <v-card-text>
              <vue-editor :disabled="true" v-model="content.description" class="custom-quill-container"/>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-card
          tile
          class="mt-2 grey lighten-3"
        >
          <v-card-text>
            <div
              v-if="credenceContent"
            >{{ credenceContent.terms_title }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              dark
              color="primary"
              @click="toNext"
            >
              <v-icon class="mr-2">
                mdi-note-text-outline
              </v-icon>規約に同意し注文確認へ
              <div class="lang_lbl">{{$t('view.規約に同意し注文確認へ')}}</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import {mapActions, mapState} from "vuex";
import alertMessage from '../../../../components/AlertMessage'

export default {
  name: 'OrderTerms',
  components: {
    alertMessage,
  },
  mixins: [],
  data () {
    return {
    }
  },
  computed: {
    ...mapState('frontCredence', {
      credenceContent: 'content',
    }),
    ...mapState('frontMyPage', {
      content: 'content',
    }),
  },
  created() {
    this.fetchMyPage({post_name: this.credenceContent.terms_my_page_post_name})
  },
  methods: {
    ...mapActions('frontMyPage', {
      fetchMyPage: 'fetch'
    }),
    toNext () {
      this.$emit('toNextStep')
    },
    toBack () {
      this.$emit('toBackStep')
    },
    toBackPayment () {
      this.$emit('toBackPayment')
    },
  },
}
</script>

<style scoped lang="scss">
.custom-quill-container {
  /deep/ {
    .ql-toolbar {
      display: none;
    }
    .ql-snow {
      border: none !important;
    }
  }
}
</style>