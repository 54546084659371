/**
 * 配送希望時間
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/delivery_desired_times`

const deliveryDesiredTime = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetchAll({ _commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          resolve(data.content)
        })
      })
    },
  },
  mutations: {},
}

export default deliveryDesiredTime
