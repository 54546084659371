<template>
  <v-container>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon class="mr-2">
            mdi-card-account-details
          </v-icon>
          Profile<span class="subtitle-2 ml-4">新規会員登録</span>
          <span class="lang_lbl">{{ $t('view.新規会員登録') }}</span>
          <div class="red--text" style="font-size:12px !important;float:right !important;margin-top:15px;">
            ※印のある項目は入力必須項目です<span class="lang_lbl">{{ $t('view.必須マーク') }}</span>
          </div>
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <v-card>
      <v-card-text>
        <membership-profile-form
          v-if="customer"
          :customer="customer"
          :is-edit="false"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

  import { mapActions } from 'vuex'
  import MembershipProfileForm from './_Form'
  import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";

  export default {
    name: 'MembershipProfileNew',
    components: {
      MembershipProfileForm
    },
    mixins: [
        PageHeaderMixin
    ],
    data () {
      return {
        customer: null,
        breadcrumbs: [
          { text: "ホーム", disabled: false, href: '/' },
          { text: "新規会員登録", disabled: true, to: '' },
        ],
      }
    },
    created() {
      this.descriptionContent = '新規会員登録'
      this.pageTitle = '新規会員登録'
      this.newCustomer().then((content) => {
        this.customer = content
      })
    },
    methods: {
      ...mapActions('frontCustomer', {
        newCustomer: 'new'
      })
    },
  }
</script>

<style scoped lang="scss">
</style>