<template>
  <v-container>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <template v-if="product">
      <v-row>
        <v-col
          cols="12"
          md="5"
        >
          <v-carousel
            v-model="productImageIndex"
            hide-delimiters
          >
            <v-carousel-item
              v-for="(item,i) in product.images"
              :key="i"
            >
              <v-sheet
                color="white"
                height="100%"
              >
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-img
                    contain
                    height="100%"
                    :src="item.url | load_image_url"
                  />
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
          <v-divider />
          <v-row>
            <v-col
              v-for="(item, index) in product.images"
              :key="item.id"
              cols="3"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  tile
                  :elevation="hover ? 12 : 2"
                  class="pa-1 ma-1"
                  @click="changeProductImage(index)"
                >
                  <v-img
                    contain
                    :src="item.url | load_image_url"
                    :lazy-src="item.url | load_image_url"
                    aspect-ratio="1"
                    class="white lighten-2 align-center"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <v-row v-if="isCatalogs">
            <v-col
              cols="12"
              class="pb-0"
            >
              <h4>カタログ</h4>
              <v-divider class="mt-2" />
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-data-table
                :items="product.catalogs"
                hide-default-footer
                hide-default-header
              >
                <template v-slot:item="{ item }">
                  <tr
                    style="cursor: pointer"
                    @click="openCatalog(item)"
                  >
                    <td>{{ item.name }}</td>
                    <td
                      align="end"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="isVideos">
            <v-col cols="12">
              <h4>動画</h4>
              <v-divider class="mt-2" />
            </v-col>
            <v-col
              v-for="item in product.videos"
              :key="item.id"
              cols="12"
              class="py-0"
              align="center"
            >
              <div>
                <youtube
                  :fit-parent="true"
                  :video-id="youtubeId(item.url)"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <div>
            <div class="display-1 mb-0">
              {{ product.name }}
            </div>

            <v-chip-group column multiple >
              <v-chip v-for="category in product.categories" :key="category.id" :value="category.id"
                      class="ma-2"
                      color="#db7093"
                      label
                      outlined
              >
                {{category.name}}
              </v-chip>
            </v-chip-group>

            <v-chip-group column multiple>
              <v-chip v-for="tag in product.tags" :key="tag.id" :value="tag.id"
                      class="ma-2"
                      :color="tag.background_color"
                      :text-color="tag.font_color"
                      label
              >
                {{tag.name}}
              </v-chip>
            </v-chip-group>


            <div class="pt-3">
              <v-row align="center">
                <v-col
                  cols="12"
                  md="6"
                  class="headline"
                >
                  {{ displayPrice | in_tax_yen_format(product.tax_exempt) }}
                </v-col>
                <v-col
                  v-if="isProductReviews"
                  cols="12"
                  md="6"
                  align="end"
                >
                  <v-rating
                    v-model="product.average_evaluation"
                    readonly
                    dense
                  />
                  <v-btn
                    text
                    small
                    color="blue darken-1"
                    @click="$vuetify.goTo('#product_rating')"
                  >
                    レビュー({{ product.review_count }}件)
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col cols="12">
                <span class="body-2 mb-1 line-break">{{ product.description }}</span>
              </v-col>
            </v-row>
            <v-row
              v-for="item in product.anyting_items"
              :key="item.id"
              class="mx-1"
            >
              <v-col
                cols="12"
                class="px-0 py-1"
              >
                <v-divider />
              </v-col>
              <v-col
                cols="12"
                sm="3"
                class="grey lighten-3"
              >
                <span class="body-2">{{ item.title }}</span>
              </v-col>
              <v-col
                cols="12"
                sm="9"
                class="body-2 line-break"
              >{{ item.value }}</v-col>
            </v-row>

            <v-row class="mx-1">
              <v-col
                cols="12"
                class="px-0 py-1"
              >
                <v-divider />
              </v-col>
              <v-col
                cols="12"
                sm="3"
                class="grey lighten-3"
              >
                <span class="body-2">最低落札額</span>
              </v-col>
              <v-col
                cols="12"
                sm="9"
                class="body-2"
              >
                {{ displayPrice | in_tax_yen_format(product.tax_exempt) }}
              </v-col>
            </v-row>

            <v-row class="mx-1">
              <v-col
                cols="12"
                class="px-0 py-1"
              >
                <v-divider />
              </v-col>
              <v-col
                cols="12"
                sm="3"
                class="grey lighten-3"
              >
                <span class="body-2">入札単位額</span>
              </v-col>
              <v-col
                cols="12"
                sm="9"
                class="body-2"
              >
                {{ product.unit_bid_amount | in_tax_yen_format(true)}}
              </v-col>
            </v-row>

            <validation-observer>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    :block="($vuetify.breakpoint.mdAndDown) ? true : false"
                    :disabled="!product.in_stock"
                    @click="addCart"
                  >
                    <v-icon>mdi-star</v-icon>お気に入り追加
                  </v-btn>
                </v-col>
              </v-row>
            </validation-observer>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="isComponentProducts">
        <v-col cols="12">
          <h3>セット内容</h3>
          <v-divider class="mt-2" />
        </v-col>
        <v-col cols="12">
          <v-row dense>
            <v-col
              v-for="item in componentProducts"
              :key="item.id"
              cols="6"
              md="2"
            >
              <v-card class="grey lighten-4">
                <v-img
                  height="200px"
                  contain
                  :src="item.image_url | load_image_url"
                  :lazy-src="item.image_url | load_image_url"
                  aspect-ratio="1"
                  class="white lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
                <v-card-subtitle align="center">
                  {{ item.name }}
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <other-product
        v-if="isRelationProducts"
        title="関連商品"
        :other-products="relationProducts"
      />

      <v-row v-if="isProductQuestions">
        <v-col cols="12">
          <h3>
            Q&A
            <span class="float-right">
              <v-btn
                v-if="loggedIn"
                small
                outlined
                color="primary"
                @click="openQuestion"
              >
                この商品に関する質問を投稿する
              </v-btn>
            </span>
          </h3>
          <v-divider class="mt-2" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <div align="end">
            <v-btn
              v-if="loggedIn"
              small
              outlined
              color="primary"
              :block="($vuetify.breakpoint.mdAndDown) ? true : false"
              @click="openQuestion"
            >
              この商品に関する質問を投稿する
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-list subheader>
        <template v-for="(item, index) in productQuestions">
          <v-list-item :key="item.id">
            <v-list-item-content>
              <v-list-item-title>
                質問：<span class="line-break link-color">{{ item.question_content }}</span>
              </v-list-item-title>
              <v-list-item-title>
                回答：<span class="line-break">{{ item.answer_content }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            :key="`question-${index}`"
            class="ml-4"
          />
        </template>
        <div
          v-if="isProductQuestions"
          class="mt-4"
        >
          <v-btn
            v-if="!qaPagination.is_last_page"
            text
            color="primary"
            @click="moreProductQuestions"
          >
            さらに表示する
          </v-btn>
        </div>
      </v-list>
      <template v-if="this.isProductReviews">
        <v-row
          id="product_rating"
        >
          <v-col cols="12">
            <h3>レビュー</h3>
            <v-divider class="mt-2" />
          </v-col>
        </v-row>
        <v-list
          subheader
          three-line
        >
          <template v-for="(item, index) in productReviews">
            <v-list-item :key="item.id">
              <v-list-item-avatar size="40">
                <v-icon large>
                  mdi-account-circle
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-rating
                  v-model="item.evaluation"
                  small
                  readonly
                  dense
                />
                <v-list-item-subtitle class="mb-2">
                  {{ item.review_date | date_format }}
                  <div class="caption">
                    {{ item.bundle_name }}
                  </div>
                </v-list-item-subtitle>
                <div class="body-2">
                  <span class="line-break">{{ item.comment }}</span>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              :key="`review-${index}`"
              inset
            />
          </template>
          <div
            v-if="isProductReviews"
            class="mt-4"
          >
            <v-btn
              v-if="!reviewPagination.is_last_page"
              text
              color="primary"
              @click="moreProductReviews"
            >
              さらに表示する
            </v-btn>
          </div>
        </v-list>
      </template>
    </template>
    <confirm ref="confirm" />

    <OptionGroupDialog
      ref="OptionGroupDialog"
      @optionGroupDialogCallback="optionGroupDialogCallback"
    />

    <!--  Q&Aダイアログ  -->
    <v-dialog
      v-model="questionDialog"
      :fullscreen="($vuetify.breakpoint.xsOnly) ? true : false"
      :transition="($vuetify.breakpoint.xsOnly) ? 'dialog-bottom-transition' : ''"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-subtitle class="pa-0">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="questionDialog=false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title align="center">
              商品Q&A
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
        </v-card-subtitle>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <validation-observer
                  ref="observer"
                  v-slot="{ invalid }"
                >
                  <v-card-text>
                    <v-row align="center">
                      <v-col
                        v-if="product.images[0]"
                        cols="4"
                        align="center"
                      >
                        <v-avatar
                          tile
                          size="80"
                          color="white"
                        >
                          <v-img
                            :src="product.images[0].url | load_image_url"
                            :lazy-src="product.images[0].url | load_image_url"
                            contain
                          />
                        </v-avatar>
                      </v-col>
                      <v-col cols="8">
                        <div class="subtitle-1">
                          {{ product.name }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="pt-4">
                      <v-col
                        cols="12"
                        class="pb-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:200"
                          name="質問内容"
                          class="requiredMark"
                        >
                          <v-textarea
                            v-model="questionContent"
                            outlined
                            label="質問内容"
                            placeholder="質問内容を入力してください。"
                            value=""
                            counter="200"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      block
                      outlined
                      :disabled="invalid"
                      @click="submitQuestion"
                    >
                      <v-icon>mdi-post-outline</v-icon>投稿する
                    </v-btn>
                  </v-card-actions>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProductMixin from '../../../mixins/product-mixin'
import Confirm from '../../../components/Confirm'
import OtherProduct from './_OtherProduct'
import moment from 'moment'
import OptionGroupDialog from './_OptionGroupDialog'
import PageHeaderMixin from "../../../mixins/frontend/page-header-mixin";


export default {
  name: 'ProductShowAuction',
  components: {
    Confirm,
    OtherProduct,
    OptionGroupDialog
  },
  mixins: [
    ProductMixin,
    PageHeaderMixin
  ],
  props: {
    cart: {
      type: Object,
      default: () => {},
    }
  },
  data () {
    return {
      scheduleDateMenu: false,
      scheduleDate: null,
      returnScheduleDate: null,
      minScheduleDate: null,
      maxScheduleDate: null,
      breadcrumbs: [
        { text: 'ホーム', disabled: false, href: '/' },
        { text: '商品一覧', disabled: false, to: '/products', exact: true },
        { text: '商品詳細', disabled: true, to: '' },
      ],
      productImageIndex: 0,
      quantity: 1,
      rentalDays: null,
      displayPrice: null,
      productPriceId: null,
      questionDialog: false,
      questionContent: null,
      productQuestions: [],
      questionPage: 0,
      productReviews: [],
      reviewPage: 0,
      selectedOptionIds: [],
      optionItems: null,
      productOptions:[],
      menuPrice: 0,
    }
  },
  computed: {
    ...mapState('frontAuth', {
      loggedIn: 'logged_in'
    }),
    ...mapState('frontProductQuestion', {
      qaPagination: 'pagination',
    }),
    ...mapState('frontProductReview', {
      reviewPagination: 'pagination',
    }),
    isProductReviews () {
      if (!this.product.use_review) {
        return false
      } else  {
        return (this.productReviews.length > 0)
      }
    },
    isProductQuestions () {
      return (this.productQuestions.length > 0)
    },
    isCatalogs () {
      return (this.product.catalogs.length > 0)
    },
    isVideos () {
      return (this.product.videos.length > 0)
    }
  },
  created() {

    // 利用予定日のデフォルト
    // 5日(当日を含む6日後)から選択可能
    const nowDate = new Date().toISOString().substr(0, 10)
    let newDate = moment(nowDate).add(6, 'd')
    this.scheduleDate = newDate.toISOString().substr(0, 10)
    this.minScheduleDate = this.scheduleDate

    // 50日後(当日を含む51日後)まで
    this.maxScheduleDate = moment(nowDate).add(51, 'd').toISOString().substr(0, 10)

    this.loadProduct(this.$route.params.id)

  },
  beforeRouteUpdate (to, from, next) {
    // created は 動かないので、otherProduct(関連商品、おすすめ商品)で再描画する前に、データを再取得する
    this.loadProduct(to.params.id)
    next()
  },
  methods: {
    ...mapActions('frontProduct', {
      findProduct: 'find',
      findRelations: 'findRelations',
      findComponents: 'findComponents',
    }),
    ...mapActions('frontCart', {
      createCart: 'create',
      updateCart: 'update',
      fetchCartCount: 'fetchCount',
    }),
    ...mapActions('frontProductQuestion', {
      findQuestions: 'findQuestions',
      createQuestion: 'create',
    }),
    ...mapActions('frontProductReview', {
      findReviews: 'findReviews'
    }),
    changeProductImage (index) {
      this.productImageIndex = index
    },
    loadProduct (productId) {

      const params = {
        id: productId
      }
      this.productImageIndex = 0
      this.findProduct(params).then((content) => {
        this.product = content
        this.meta_tags = content.meta_tags
        this.pageTitle = this.product.name

        this.optionItems = this.product.options
        const idComponentItem = this.product.component_item || false
        if (idComponentItem) {
          this.findComponents(params).then((content) => {
            this.componentProducts = content.product_components
          })
        }

        // 利用予定日を再設定
        if (this.cart) {
          this.quantity = this.cart.quantity
          this.scheduleDate = this.cart.schedule_date
          this.rentalDays = this.cart.rental_days
          this.displayPrice = this.cart.price
          this.productPriceId = this.cart.product_price_id
          this.$data.productOptions = JSON.parse(JSON.stringify(this.cart.cart_option_groups))
        } else {
          this.scheduleDate = content.schedule_start_date
          this.rentalDays = content.rental_days
          this.displayPrice = content.in_tax_price

        }
        this.minScheduleDate = content.schedule_start_date
      })
      this.findRelations(params).then((content) => {
        this.relationProducts = content.products
      })
      this.loadProductQuestions(productId)
      this.loadProductReviews(productId)
    },
    loadProductQuestions (productId) {
      this.questionPage++
      this.findQuestions({product_id: productId, page: this.questionPage}).then((content) => {
        this.productQuestions = [...this.productQuestions, ...content.questions]
      })
    },
    moreProductQuestions () {
      this.loadProductQuestions(this.product.id)
    },
    loadProductReviews (productId) {
      this.reviewPage++
      this.findReviews({product_id: productId, page: this.reviewPage}).then((content) => {
        this.productReviews = [...this.productReviews, ...content.reviews]
      })
    },
    moreProductReviews () {
      this.loadProductReviews(this.product.id)
    },
    addCart () {
      if (this.loggedIn) {
        // カート追加
        let params = {
          product_id: this.product.id,
          quantity: this.quantity,
          schedule_date: this.scheduleDate,
          rental_days: this.rentalDays,
          product_price_id: this.productPriceId
        }

        if (this.cart) {
          this.updateCart({id: this.cart.id, params: params})
        } else {
          this.createCart({params}).then( () => {
            this.fetchCartCount()
          })
        }
      } else {
        this.$refs.confirm.open({title: '確認', message: 'お申し込みにはログインが必要です。', options: {isCancelButton: false}}).then((confirm) => {
          this.$refs.confirm.close()
        })
      }
    },
    selectedPrice() {
      var tmp = this.product.prices.filter(e => e.id == this.productPriceId);
      this.rentalDays = tmp[0].rental_period
      this.menuPrice = tmp[0].price
      this.calcDisplayPrice()
    },
    openQuestion () {
      this.questionDialog = true
    },
    submitQuestion () {

      const params = {
        product_id: this.product.id,
        question_content: this.questionContent
      }
      this.createQuestion(params).then( (content) => {
        this.questionContent = null
        this.$refs.observer.reset()
        this.questionDialog = false
      })
    },
    calcDisplayPrice () {
      const number = Number(this.rentalDays)
      if (!Number.isInteger(number)) return
      if (number <= 0) return

      const quantity = Number(this.quantity)
      if (!Number.isInteger(quantity)) return
      if (quantity <= 0) return

      let totalPrice = 0
      if (this.product.price_type == 'daily_price') {
        totalPrice = this.product.price * this.rentalDays * this.quantity
      } else {
        totalPrice = this.menuPrice * this.quantity
      }

      let in_tax_price = totalPrice
      if (!this.product.tax_exempt) {
        const tax = totalPrice * (this.product.tax_rate / 100)
        in_tax_price = totalPrice + tax
      }
      if (in_tax_price <= 0) in_tax_price = this.product.in_tax_price
      // this.displayPrice = in_tax_price
      this.displayPrice = this.calcRoundPrice(in_tax_price, this.product.tax_fraction)
    },
    openCatalog (item) {
      window.open(item.url)
    },
    youtubeId (url) {
      return this.$youtube.getIdFromUrl(url)
    },
    showOptionGroupDialog (option_group){
      this.$refs.OptionGroupDialog.openDialog({target_id: option_group.id})
    },
    optionGroupDialogCallback (resp) {
      for (const option of this.productOptions) {
        if (option.option_group_id === resp.option_group_id) {
          option.option_item_id = resp.option_item_id
          break
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>