/**
 * 商品検索
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/search/products`

const search_product = {
    namespaced: true,
    getters: {},
    actions: {
        fetchAll({ _commit }, {params}) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH, { params: params }).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
    },
    mutations: {},
}

export default search_product