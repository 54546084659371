<template>
  <v-row
    v-if="backgroundImageContent"
    class="fill-height mx-1"
    :style="{ paddingLeft: leftPadding+'%' }"
  >
    <v-col cols="12">
      <v-row
        class="pt-16"
      >
        <v-col
          cols="12"
          :class="backgroundImageContent.text_align"
        >
          <div
            class="text-h6"
            :style="{color: backgroundImageContent.title_color }"
          >
            {{ backgroundImageContent.title | truncate(10) }}
          </div>
        </v-col>
        <v-col
          cols="12"
          :class="backgroundImageContent.text_align"
        >
          <span
            class="body-2 mb-1 line-break"
            :style="{color: backgroundImageContent.sub_title_color }"
          >{{ backgroundImageContent.sub_title | truncate(20) }}
          </span>
        </v-col>
        <v-col
          v-if="backgroundImageContent.use_button"
          cols="12"
          :class="backgroundImageContent.text_align"
        >
          <v-btn
            small
            outlined
            :style="{color: backgroundImageContent.button_title_color }"
          >
            {{ backgroundImageContent.button_title }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BackgroundImageContentsItem',
    props: {
      backgroundImageContent: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        leftPadding: 0
      }
    },
    computed: {
      isExternal () {
        return this.backgroundImageContent.transition_type === 't_external'
      }
    },
    watch: {
      'backgroundImageContent.left_padding': function(newValue){
        console.log('backgroundImageContent.left_padding changed!')
        if (newValue > 0) {
          this.leftPadding = newValue - 5
        } else {
          this.leftPadding = 0
        }
      }
    },
    created() {
      if (this.backgroundImageContent.left_padding > 0) {
        this.leftPadding = this.backgroundImageContent.left_padding - 5
      } else {
        this.leftPadding = 0
      }
    },
    methods: {
      linkAction () {
      }
    },
  }
</script>

<style scoped lang="scss">
</style>