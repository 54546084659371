/**
 * バナーオプション
 * 一画面で複数コンポーネントを使用するので state で管理しない
 */
import Vue from "vue";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/configurations/banner_option`

const config_banner_option = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    fetch({ _commit }, { params }) {

      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
  },
  mutations: {},
}

export default config_banner_option
