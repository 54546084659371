<template>
  <v-list-group
    link
    no-action
  >
    <template v-slot:activator>
      <v-list-item-title>{{ title }}</v-list-item-title>
    </template>
    <v-list-item
      v-for="item in items"
      :key="item.id"
      link
      class="pl-6"
      @click="moveToProductList(item)"
    >
      <v-list-item-content>
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon>mdi-chevron-right</v-icon>
      </v-list-item-icon>
    </v-list-item>
  </v-list-group>
</template>

<script>

import FooterActionMixin from '../../mixins/frontend/footer-action-mixin'

/**
 * サイドナビリストアイテム
 */
export default {
  name: 'FooterSearchListItemNav',
  mixins: [
    FooterActionMixin,
  ],
  props: {
    items: {
      type: Array,
      default: () => {
        return {}
      },
    },
    title: {
      type: String,
      default: ''
    },
    searchType: {
      type: String,
      default: 'category'
    }
  },
  data() {
    return {
    }
  },
  methods: {
    moveToProductList (item) {
      this.moveToProduct(item, this.searchType)
    }
  },
}

</script>

<style scoped lang="scss">
</style>