<!-- バックヤード > 配送希望・時間追加 -->
<template>
  <div>
    <v-breadcrumbs
        class="px-0 py-2"
        :items="breadcrumbs"
    />
    <delivery-desired-time-form
        v-if="deliveryDesiredTime"
        :delivery-desired-time="deliveryDesiredTime"
        :is-edit="false"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DeliveryDesiredTimeForm from './_Form'

export default {
  name: 'ByDeliveryDesiredTimeNew',
  components: {
    DeliveryDesiredTimeForm,
  },
  data () {
    return {
      deliveryDesiredTime: null,
      // パンくず
      breadcrumbs: [
        { text: '配送希望時間一覧', disabled: false, to: '/backyard/delivery_desired_times', exact: true },
        { text: '配送希望時間追加', disabled: true, to: '' },
      ],
    }
  },

  created () {
    this.newDeliveryDesiredTime().then((content) => {
      this.deliveryDesiredTime = content
    })
  },
  methods: {
    ...mapActions('backDeliveryDesiredTime', {
      newDeliveryDesiredTime: 'new'
    }),
  },
}
</script>

<style scoped lang="scss">
</style>
