var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.isShowProductStockDialog),callback:function ($$v) {_vm.isShowProductStockDialog=$$v},expression:"isShowProductStockDialog"}},[_c('validation-observer',{ref:"stockFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',[_c('v-card-title',[_vm._v("商品在庫登録")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"状態","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.product_stock.statuses,"disabled":_vm.product_use_calendar,"dense":"","hide-details":"auto","label":"状態","outlined":"","placeholder":" "},model:{value:(_vm.product_stock.status),callback:function ($$v) {_vm.$set(_vm.product_stock, "status", $$v)},expression:"product_stock.status"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"備考","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"auto-grow":"","counter":"255","dense":"","hide-details":"auto","label":"備考","outlined":"","placeholder":" ","row-height":"15"},model:{value:(_vm.product_stock.note),callback:function ($$v) {_vm.$set(_vm.product_stock, "note", $$v)},expression:"product_stock.note"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"管理番号","rules":"max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"auto-grow":"","counter":"70","dense":"","hide-details":"auto","label":"管理番号","outlined":"","placeholder":" ","row-height":"15"},model:{value:(_vm.product_stock.control_number),callback:function ($$v) {_vm.$set(_vm.product_stock, "control_number", $$v)},expression:"product_stock.control_number"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"name":"在庫場所","rules":"max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"auto-grow":"","counter":"70","dense":"","hide-details":"auto","label":"在庫場所","outlined":"","placeholder":" ","row-height":"15"},model:{value:(_vm.product_stock.stock_location),callback:function ($$v) {_vm.$set(_vm.product_stock, "stock_location", $$v)},expression:"product_stock.stock_location"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('div',[_vm._v("貸出期間")]),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"290px","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('validation-provider',{class:_vm.product_stock.status === 'check_out' ? 'requiredMark':'',attrs:{"rules":_vm.product_stock.status === 'check_out' ? 'required':'',"name":"開始日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"disabled":_vm.product_stock.status !== 'check_out',"error-messages":errors,"clearable":"","dense":"","hide-details":"auto","label":"開始日","outlined":"","placeholder":" ","readonly":""},on:{"click:clear":function($event){_vm.product_stock.rental_from_date = null}},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.smAndDown)?{key:"append-outer",fn:function(){return [_vm._v("\n                                 〜\n                              ")]},proxy:true}:null],null,true),model:{value:(_vm.product_stock.rental_from_date),callback:function ($$v) {_vm.$set(_vm.product_stock, "rental_from_date", $$v)},expression:"product_stock.rental_from_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.rental_from_date_menu),callback:function ($$v) {_vm.rental_from_date_menu=$$v},expression:"rental_from_date_menu"}},[_vm._v(" "),_c('v-date-picker',{on:{"input":function($event){_vm.rental_from_date_menu = false}},model:{value:(_vm.product_stock.rental_from_date),callback:function ($$v) {_vm.$set(_vm.product_stock, "rental_from_date", $$v)},expression:"product_stock.rental_from_date"}})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"290px","offset-y2":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('validation-provider',{class:_vm.product_stock.status === 'check_out' ? 'requiredMark':'',attrs:{"rules":_vm.product_stock.status === 'check_out' ? 'required':'',"name":"終了日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"disabled":_vm.product_stock.status !== 'check_out',"error-messages":errors,"clearable":"","dense":"","hide-details":"auto","label":"終了日","outlined":"","placeholder":" ","readonly":""},on:{"click:clear":function($event){_vm.product_stock.rental_to_date = null}},model:{value:(_vm.product_stock.rental_to_date),callback:function ($$v) {_vm.$set(_vm.product_stock, "rental_to_date", $$v)},expression:"product_stock.rental_to_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.rental_to_date_menu),callback:function ($$v) {_vm.rental_to_date_menu=$$v},expression:"rental_to_date_menu"}},[_vm._v(" "),_c('v-date-picker',{on:{"input":function($event){_vm.rental_to_date_menu = false}},model:{value:(_vm.product_stock.rental_to_date),callback:function ($$v) {_vm.$set(_vm.product_stock, "rental_to_date", $$v)},expression:"product_stock.rental_to_date"}})],1)],1)],1)],1)],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){_vm.isShowProductStockDialog = false}}},[_vm._v("\n            キャンセル\n          ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.dialogRegisterButtonClickEvent}},[_vm._v("\n            保存\n          ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }