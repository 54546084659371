import { render, staticRenderFns } from "./_OtherProduct.vue?vue&type=template&id=0cd4012c&scoped=true&"
import script from "./_OtherProduct.vue?vue&type=script&lang=js&"
export * from "./_OtherProduct.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd4012c",
  null
  
)

export default component.exports