<template>
  <div>
    <v-container
      fluid
      class="hidden-md-and-up"
      align="center"
    >
      <keyword-search />
    </v-container>
    <v-divider />
  </div>
</template>

<script>

  import KeywordSearch from './field/KeywardSearch'

  export default {
    name: 'SearchNav',
    components: {
      KeywordSearch,
    },
    data() {
      return {
      }
    },
  }

</script>

<style scoped lang="scss">
</style>