var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert-message'),_vm._v(" "),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"名称","dense":"","hide-details":"auto","outlined":"","clearable":"","placeholder":" ","required":"","error-messages":errors},model:{value:(_vm.configuration.name),callback:function ($$v) {_vm.$set(_vm.configuration, "name", $$v)},expression:"configuration.name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":{required: true, regex:/^[0-9]{3}[-][0-9]{4}$/},"name":"郵便番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"郵便番号","dense":"","hint":"半角数字とハイフン(-)で入力して下さい","persistent-hint":"","outlined":"","clearable":"","placeholder":" ","required":"","error-messages":errors},model:{value:(_vm.configuration.zip_code),callback:function ($$v) {_vm.$set(_vm.configuration, "zip_code", $$v)},expression:"configuration.zip_code"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"都道府県"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"都道府県","items":_vm.prefList,"dense":"","hide-details":"auto","outlined":"","placeholder":" ","required":"","error-messages":errors},model:{value:(_vm.configuration.prefecture_code),callback:function ($$v) {_vm.$set(_vm.configuration, "prefecture_code", $$v)},expression:"configuration.prefecture_code"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"住所"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"住所","dense":"","hide-details":"auto","outlined":"","clearable":"","placeholder":" ","required":"","error-messages":errors},model:{value:(_vm.configuration.address),callback:function ($$v) {_vm.$set(_vm.configuration, "address", $$v)},expression:"configuration.address"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"電話番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"電話番号","dense":"","hide-details":"auto","hint":"半角数字とハイフン(-)で入力して下さい","persistent-hint":"","outlined":"","clearable":"","placeholder":" ","required":"","error-messages":errors},model:{value:(_vm.configuration.tel),callback:function ($$v) {_vm.$set(_vm.configuration, "tel", $$v)},expression:"configuration.tel"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.registerButtonClickEvent}},[_vm._v("\n            保存\n          ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }