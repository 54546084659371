/**
 * backyard用の認証
 */
import * as types from '../../mutation-types/backyard-types'
import * as httpConst from '../../../constants/httpConst'
import Vue from 'vue'
import store from 'store'

const isBackLoggedIn = () => {
  return (store.get('backLoggedIn'))
}

const getCurrentUser = () => {
  const default_current_user = {
    name: null,
  }
  let current_user = null
  if (isBackLoggedIn()) current_user = store.get('by_current_user')
  return (current_user) ? current_user : default_current_user
}

const API_PATH = `${httpConst.BACKYARD_BASE}/oauth`

/**
 * logged_in: Boolean (true: ログイン済)
 */
const auth = {
  namespaced: true,
  state: {
    logged_in: isBackLoggedIn(),
    name: getCurrentUser().name
  },
  getters: {
    avatarName: ( state ) => {
      return (state.name) ? state.name.slice(0, 2) : ''
    },
    alive: (state) => {
      return state.logged_in
    }
  },
  actions: {
    signInBackyard ({ commit }, { params }) {
      const fixedParams = {
        grant_type: 'password',
        client_id: process.env.BACKYARD_CLIENT_ID,
        client_secret: process.env.BACKYARD_CLIENT_SECRET,
        scope: 'public backyard'
      }
      const parameter = { ...params, ...fixedParams }

      const api_path = `${API_PATH}/token`
      return new Promise((resolve, _reject) => {
        Vue.$http.post(api_path, parameter).then( data => {
          const user = data.user
          commit(types.SIGN_IN_BACKYARD, { user })
          resolve(data)
        })
      })
    },
    signOutBackyard ({ commit }) {
      const api_path = `${API_PATH}/revoke`
      return new Promise((resolve) => {
        Vue.$http.post(api_path, {}).then( data => {
          commit(types.SIGN_OUT_BACKYARD)
          resolve(data)
        })
      })
    }
  },
  mutations: {
    forceSignOut (state) {
      state.logged_in = false
      store.set('backLoggedIn', false)
      store.set('by_current_user', null)
      store.remove('backLoggedIn')
      store.remove('current_user')
    },
    [types.SIGN_IN_BACKYARD] (state, { user }) {
      state.logged_in = true
      state.name = user.name
      // localStorageに保持
      store.set('backLoggedIn', true)
      store.set('by_current_user', user)
    },
    [types.SIGN_OUT_BACKYARD] (state) {
      state.logged_in = false
      // localStorageに保持
      store.set('backLoggedIn', false)
      store.set('by_current_user', null)
      store.remove('backLoggedIn')
      store.remove('by_current_user')
    },
  },
}

export default auth
