<!-- バックヤード > 商品問い合わせ一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          配送方法・送料 管理
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="prefectureCodes"
                  :items="prefList"
                  dense
                  item-text="text"
                  item-value="value"
                  label="都道府県"
                  multiple
                  placeholder=" "
                  outlined
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="searchDeliveryMethods"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    都道府県名
                  </th>
                  <th class="text-right">
                    基本送料
                  </th>
                  <th class="text-right">
                    変動送料金額
                  </th>
                  <th class="text-right">
                    変動送料
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in delivery_methods"
                  :key="item.id"
                >
                  <td>{{ item.prefecture_name }}</td>
                  <td class="text-right">
                    {{ item.postage | comma_format }} 円
                  </td>
                  <td class="text-right">
                    {{ item.change_postage_price | comma_format }} 円
                  </td>
                  <td class="text-right">
                    {{ item.change_postage | comma_format }} 円
                  </td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="showDeliveryMethod(item)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="my-6" />
    <v-pagination
      v-model="page"
      :length="pagination.total_pages"
      :total-visible="totalVisible"
      circle
      next-icon="mdi-menu-right"
      prev-icon="mdi-menu-left"
      @input="changePage"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import prefectureList from "../../../mixins/PrefectureList";

export default {
  name: 'DeliveryMethodIndex',
  mixins: [
    prefectureList,
  ],
  data () {
    return {
      page: 1,
      totalVisible: 7,
      prefectureCodes: '',
      delivery_methods: [],
    }
  },
  computed: {
    ...mapState('backDeliveryMethod', {
      pagination: 'pagination',
      searchQuery: 'query',
    })
  },
  created () {
    this.page = this.pagination.current_page
    this.prefectureCodes = this.searchQuery.prefecture_codes
    this.searchDeliveryMethods()
  },
  methods: {
    ...mapActions('backDeliveryMethod', {
      fetchDeliveryMethods: 'fetchAll'
    }),
    showDeliveryMethod: function (item) {
      this.$router.push({name: 'byDeliveryMethodEdit', params: { id: item.id }})
    },
    searchDeliveryMethods () {
      const params = {
        prefecture_codes: this.prefectureCodes,
        page: this.page
      }
      this.fetchDeliveryMethods(params).then((content) => {
        this.delivery_methods = content.delivery_methods
      })
    },
    changePage () {
      this.searchDeliveryMethods()
    },
  }
}
</script>

<style lang="scss">

</style>
