import {mapActions, mapGetters, mapState} from "vuex";

const ThemeMixin = {
  data() {
    return {
    }
  },
  computed: {
    ...mapState('frontHeaderOption', {
      theme: 'theme',
    }),
    ...mapGetters('frontHeaderOption', [
      'isHeaderImage', 'isHeaderText'
    ]),
  },
  watch: {
    theme (newVal) {
      this.$vuetify.theme.themes.light.header = newVal.header_color
      this.$vuetify.theme.themes.light.primary = newVal.site_color
    },
  },
  created() {
    this.fetchHeaderOption()
  },
  filters: {},
  methods: {
    ...mapActions('frontHeaderOption', {
      fetchHeaderOption: 'fetch'
    }),
  }
}

export default ThemeMixin
