<template>
  <v-container
    fluid
    fill-height
  >
    <v-card
      outlined
      class="mx-auto"
      style="width: 40rem;"
    >
      <v-card-title
        color="primary"
        class="justify-center"
      >
        <h2 class="text-center">
          メールの送信が完了しました。
          <div class="lang_lbl">{{$t('view.メールの送信が完了しました')}}</div>
        </h2>
      </v-card-title>
      <v-card-subtitle class="mt-2">
        <div>パスワード再設定ページへのリンクを記載したメールをお送りしました。</div>
        <div class="lang_lbl">{{$t('view.パスワード再設定メール送信完了_1')}}</div>
        <div class="mt-2">再設定メールが届かない場合は、迷惑メールボックスの設定解除、ドメイン着信許可の設定をご確認ください。</div>
        <div class="lang_lbl">{{$t('view.パスワード再設定メール送信完了_2')}}</div>
      </v-card-subtitle>
      <v-card-text>
        <div
          class="text-center"
        >
          <v-btn
            @click="backToHomeButtonClickEvent"
            class="lang_btn"
          >
            HOMEに戻る
            <div class="lang_lbl">{{$t('view.HOMEに戻る')}}</div>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: 'ForgetMailSent',
    computed: {
      ...mapState('frontSystemSetting', {
        frontendAuthMode: 'frontend_auth_mode'
      }),
    },
    methods: {
      backToHomeButtonClickEvent () {
      if (this.frontendAuthMode) {
        this.$router.push({name: 'SessionIndex'})
      } else {
        this.$router.push({name: 'HomeIndex'})
      }
    }
  }
}
</script>

<style scoped>

</style>