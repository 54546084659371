<!-- バックヤード > キャンペーン編集 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <campaign-form
      v-if="campaign"
      :campaign="campaign"
      :company-mode-caption-name="company_mode_caption_name"
    />
  </div>
</template>

<script>
import CampaignForm from './_Form'
import { mapActions } from 'vuex'
export default {
  name: 'CampaignsEdit',
  components: {
    CampaignForm,
  },
  mixins: [
  ],
  data () {
    return {
      breadcrumbs: [],
      campaign: null,
      company_mode_caption_name: '',
    }
  },
  created() {
    this.editCampaign({ id: this.$route.params.id }).then((content) => {
      this.breadcrumbs =
          [
            { text: content.company_mode_caption_name + '一覧', disabled: false, to: '/backyard/campaigns', exact: true },
            { text: content.company_mode_caption_name + '詳細', disabled: true, to: '' },
          ]
      this.company_mode_caption_name = content.company_mode_caption_name
      this.campaign = content
    })
  },
  methods: {
    ...mapActions('backCampaign', {
      editCampaign: 'edit',
    }),
  },
}
</script>

<style lang="scss">

</style>
