const _contract_plans = [
    { "value": '0', "text": 'ライト' },
    { "value": '1', "text": 'ミドル' },
    { "value": '2', "text": 'ヘビー' },
]

export default {
    data () {
        return {
            contractPlanList: _contract_plans,
        }
    }
}