<template>
  <v-footer
    padless
  >
    <v-card
      flat
      tile
      style="width: 100%"
      color="grey lighten-5"
    >
      <v-container>
        <v-card-text
          v-if="xsOnly"
          align="center"
        >
          <div>
            <v-img
              v-if="isCompanyImageURL"
              :src="content.company_image_url"
              max-height="120"
              max-width="120"
            />
          </div>
          <div class="py-2">
            <v-btn
              v-if="content.twitter_url"
              :href="content.twitter_url"
              target="_blank"
              fab
              outlined
              small
              style="color: #2daae4"
            >
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn
              v-if="content.facebook_url"
              :href="content.facebook_url"
              target="_blank"
              icon
              large
            >
              <v-icon
                large
                style="color: #39599f"
              >
                mdi-facebook
              </v-icon>
            </v-btn>
          </div>
          <v-divider />
          <div align="start">
            <v-btn
              text
              small
              @click="moveToCompany('information')"
              class="mr-2 lang_btn"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
              会社情報
              <span class="lang_lbl">{{ $t('view.会社情報') }}</span>
            </v-btn>
          </div>
          <div align="start">
            <v-btn
              text
              small
              @click="moveToCompany('trade_law')"
              class="mr-2 lang_btn"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
              特定商取引法に基づく表示
              <span class="lang_lbl">{{ $t('view.特定商取引法に基づく表示') }}</span>
            </v-btn>
          </div>
          <div
            v-for="item in content.my_pages"
            :key="item.id"
            align="start"
          >
            <v-btn
              text
              small
              class="mr-2"
              @click="moveToMyPage(item)"
            >
              <v-icon small>
                mdi-chevron-right
              </v-icon>
              {{ item.title }}
            </v-btn>
          </div>
          <v-divider />
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col
              v-if="isCategories && theme.use_category_fotter"
              :cols="colsNumber"
            >
              <footer-search-list-item
                searchType="category"
                :title="'カテゴリーから探す（' + $t('view.カテゴリーから探す') + '）'"
                :items="this.categories"
              />
            </v-col>
            <v-col
              v-if="isCampaigns && theme.use_campaign_fotter"
              :cols="colsNumber"
            >
              <footer-search-list-item
                v-if="auctionMode"
                searchType="campaign"
                title="オークションから探す"
                :items="campaigns"
              />
              <footer-search-list-item
                v-else
                searchType="campaign"
                :title="'キャンペーンから探す（' + $t('view.キャンペーンから探す') + '）'"
                :items="campaigns"
              />
            </v-col>
            <v-col
              v-if="isTags && theme.use_tag_fotter"
              :cols="colsNumber"
            >
              <footer-search-list-item
                searchType="tag"
                :title="'タグから探す（' + $t('view.タグから探す') + '）'"
                :items="tags"
              />
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col
              v-if="isCompanyImageURL"
              cols="2"
              align="center"
            >
              <v-img
                :src="content.company_image_url"
                max-height="120"
                max-width="120"
              />
            </v-col>
            <v-col
              cols="10"
              align-self="center"
            >
              <div>{{ content.name }}</div>
              <div><span v-if="content.zip_code">〒 {{ content.zip_code }}</span><br><span v-if="content.address">{{ content.address }}</span></div>
              <div>
                <span v-if="content.phone_number">[TEL]：</span>{{ content.phone_number }}
                <span v-if="content.business_hour" class="ml-2">[営業時間]：</span>{{ content.business_hour }}
                <span v-if="content.holiday" class="ml-2">[定休日]：</span>{{ content.holiday }}
              </div>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col cols="3">
              <v-btn
                v-if="content.twitter_url"
                :href="content.twitter_url"
                target="_blank"
                fab
                outlined
                small
                style="color: #2daae4"
              >
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <v-btn
                v-if="content.facebook_url"
                :href="content.facebook_url"
                target="_blank"
                icon
                large
              >
                <v-icon
                  large
                  style="color: #39599f"
                >
                  mdi-facebook
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="9"
              align="end"
              align-self="center"
            >
              <v-btn
                text
                small
                class="mr-2 lang_btn"
                @click="moveToCompany('information')"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
                会社情報
                <span class="lang_lbl">{{ $t('view.会社情報') }}</span>
              </v-btn>
              <v-btn
                text
                small
                class="mr-2 lang_btn"
                @click="moveToCompany('trade_law')"
              >
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
                特定商取引法に基づく表示
                <span class="lang_lbl">{{ $t('view.特定商取引法に基づく表示') }}</span>
              </v-btn>
              <template v-for="item in content.my_pages">
                <v-btn
                  :key="item.id"
                  text
                  small
                  class="mr-2"
                  @click="moveToMyPage(item)"
                >
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                  {{ item.title }}
                </v-btn>
              </template>
            </v-col>
          </v-row>
          <v-divider />
        </v-card-text>
        <v-card-text
          class="text-center"
        >
          {{ content.copy_right }}
        </v-card-text>
      </v-container>
    </v-card>
  </v-footer>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import FooterSearchListItem from './FooterSearchListItem'
  import FooterActionMixin from '../../mixins/frontend/footer-action-mixin'

  /**
   * フッター
   */
  export default {
    name: 'FooterNav',
    components: {
      FooterSearchListItem,
    },
    mixins: [
      FooterActionMixin,
    ],
    data() {
      return {
      }
    },
    computed: {
      ...mapState('frontCredence', {
        content: 'content',
      }),
      ...mapState('frontSystemSetting', {
        auctionMode: 'auction_mode'
      }),
      xsOnly () {
        return this.$vuetify.breakpoint.xsOnly
      },
      isCompanyImageURL () {
        return (this.content.company_image_url)
      }
    },
    created() {
      this.fetch()
    },
    methods: {
      ...mapActions('frontCredence', {
        fetch: 'fetch'
      }),
    },
  }

</script>

<style scoped lang="scss">
</style>