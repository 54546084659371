<!-- バックヤード > 企業情報 > 特定商取引情報タブ -->
<template>
  <div>
    <alert-message />
    <validation-observer v-slot="{ invalid }">
      <v-form>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:50"
                  name="店舗名"
                >
                  <v-text-field
                    v-model="credence.trader_name"
                    :error-messages="errors"
                    counter="50"
                    dense
                    hide-details="auto"
                    label="店舗名"
                    outlined
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:20"
                  name="代表者名"
                >
                  <v-text-field
                    v-model="credence.representative_name"
                    :error-messages="errors"
                    counter="20"
                    dense
                    hide-details="auto"
                    label="代表者名"
                    outlined
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:20"
                  name="販売責任者名"
                >
                  <v-text-field
                    v-model="credence.sales_responsible_name"
                    :error-messages="errors"
                    counter="20"
                    dense
                    hide-details="auto"
                    label="販売責任者名"
                    outlined
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="numeric|max:7"
                  name="郵便番号"
                >
                  <v-text-field
                    v-model="credence.zip_code"
                    :error-messages="errors"
                    counter="7"
                    dense
                    hide-details="auto"
                    hint="ハイフン(-)無しで入力してください"
                    label="郵便番号"
                    persistent-hint
                    outlined
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="credence.prefecture_code"
                  :items="prefList"
                  label="都道府県"
                  dense
                  hide-details="auto"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:100"
                  name="住所"
                >
                  <v-text-field
                    v-model="credence.address"
                    :error-messages="errors"
                    counter="100"
                    dense
                    hide-details="auto"
                    label="住所"
                    outlined
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{ regex: /^[0-9\-]+$/, max:13}"
                  name="フリーダイヤル"
                >
                  <v-text-field
                    v-model="credence.free_dial"
                    :error-messages="errors"
                    counter="13"
                    dense
                    hide-details="auto"
                    hint="ハイフン(-)有りで入力してください"
                    label="フリーダイヤル"
                    outlined
                    persistent-hint
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{ regex: /^[0-9\-]+$/, max:13}"
                  name="電話番号"
                >
                  <v-text-field
                    v-model="credence.tel"
                    :error-messages="errors"
                    counter="13"
                    dense
                    hide-details="auto"
                    hint="ハイフン(-)有りで入力してください"
                    label="電話番号"
                    outlined
                    persistent-hint
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{ regex: /^[0-9\-]+$/, max:13}"
                  name="FAX番号"
                >
                  <v-text-field
                    v-model="credence.fax"
                    :error-messages="errors"
                    counter="13"
                    dense
                    hide-details="auto"
                    hint="ハイフン(-)有りで入力してください"
                    label="FAX番号"
                    outlined
                    persistent-hint
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="email|max:255"
                  name="メールアドレス"
                >
                  <v-text-field
                    v-model="credence.mail_address"
                    :error-messages="errors"
                    autocomplete="email"
                    counter="255"
                    dense
                    hide-details="auto"
                    label="メールアドレス"
                    outlined
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:255"
                  name="URL"
                >
                  <v-text-field
                    v-model="credence.url"
                    :error-messages="errors"
                    counter="255"
                    dense
                    hide-details="auto"
                    label="URL"
                    outlined
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:255"
                  name="店舗営業時間"
                >
                  <v-text-field
                    v-model="credence.business_hours"
                    :error-messages="errors"
                    counter="255"
                    dense
                    hide-details="auto"
                    label="店舗営業時間"
                    outlined
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:255"
                  name="店舗定休日"
                >
                  <v-text-field
                    v-model="credence.holiday"
                    :error-messages="errors"
                    counter="255"
                    dense
                    hide-details="auto"
                    label="店舗定休日"
                    outlined
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :block="$vuetify.breakpoint.mdAndDown"
              :disabled="invalid"
              color="primary"
              @click="submit"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import AlertMessage from '../../../../components/AlertMessage'
import PrefectureList from '../../../../mixins/PrefectureList'
import { mapActions } from 'vuex'

export default {
  name: 'CredenceForm',
  components: {
    AlertMessage,
  },
  mixins: [
    PrefectureList,
  ],
  props: {
    credence: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      dateMenu: false,
    }
  },
  computed: {
  },
  methods: {
    ...mapActions('backCredence', {
      updateCredence: 'update',
    }),
    submit() {
      let params = {
        revision_date: this.credence.revision_date,
        trader_name: this.credence.trader_name,
        representative_name: this.credence.representative_name,
        sales_responsible_name: this.credence.sales_responsible_name,
        zip_code: this.credence.zip_code,
        prefecture_code: this.credence.prefecture_code,
        address: this.credence.address,
        free_dial: this.credence.free_dial,
        tel: this.credence.tel,
        fax: this.credence.fax,
        mail_address: this.credence.mail_address,
        url: this.credence.url,
        business_hours: this.credence.business_hours,
        holiday: this.credence.holiday,
      }
      this.updateCredence({ params: params })
    },
  }
}
</script>

<style lang="scss">

</style>
