<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          xs="12"
          sm="6"
          md="4"
          offset-sm="3"
          offset-md="4"
        >
          <v-form>
            <validation-observer v-slot="{ invalid }">
              <v-card outlined>
                <v-card-title>
                  <span class="title font-weight-light">bizRENT Admin</span>
                </v-card-title>
                <v-card-text>
                  <alert-message />
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="ログインID"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="loginId"
                      label="ログインID"
                      required
                      :error-messages="errors"
                    />
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="パスワード"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="password"
                      type="password"
                      label="パスワード"
                      required
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    block
                    color="primary"
                    :disabled="invalid"
                    @click="login"
                  >
                    Login
                  </v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import {mapActions} from "vuex";
  import alertMessage from '../../../components/AlertMessage'

  export default {
    name: 'AdminSessionIndex',
    components: {
      alertMessage,
    },
    data () {
      return {
        loginId: '',
        password: ''
      }
    },
    methods: {
      ...mapActions('adminAuth', {
        signIn: 'signInAdmin',
        signOut: 'signOutAdmin'
    }),
    login: function () {
      const params = {
        login_id: this.loginId,
        password: this.password,
      }
      this.signIn({params: params}).then((content) => {
        this.$router.push({name: 'adCompanyIndex'})
      })
    }
  },
}
</script>

<style lang="scss">
</style>
