<!-- バックヤード > 配送希望時間一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          配送希望・時間 管理
          <span class="float-right">
            <v-btn
                color="primary"
                @click="newDeliveryDesiredTime"
            >
              配送希望時間追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  表示順
                </th>
                <th class="text-left">
                  配送希望時間
                </th>
                <th />
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in delivery_desired_times"
                  :key="item.id"
              >
                <td>{{ item.position }}</td>
                <td class="text-left">
                  {{ item.desired_time_value }}
                </td>
                <td class="text-right">
                  <v-btn
                      icon
                      color="cyan darken-2"
                      @click="showDeliveryDesiredTime(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DeliveryDesiredTimeIndex',
  data () {
    return {
      delivery_desired_times: [],
    }
  },
  created () {
    this.searchDeliveryDesiredTimes()
  },
  methods: {
    ...mapActions('backDeliveryDesiredTime', {
      fetchDeliveryDesiredTimes: 'fetchAll'
    }),
    newDeliveryDesiredTime () {
      // カテゴリー新規追加へ
      this.$router.push({name: 'byDeliveryDesiredTimeNew'})
    },
    showDeliveryDesiredTime (item) {
      this.$router.push({name: 'byDeliveryDesiredTimeEdit', params: { id: item.id }})
    },
    searchDeliveryDesiredTimes () {
      this.fetchDeliveryDesiredTimes().then((content) => {
        this.delivery_desired_times = content.delivery_desired_times
      })
    },
  }
}
</script>

<style lang="scss">

</style>
