<!-- バックヤード > 配送業者 一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          配送業者・管理
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              @click="deliveryCompanyCreate"
            >
              配送業者追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="inputName"
                  dense
                  label="配送業者名"
                  hide-details="auto"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="resetDeliveryCompanies"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    配送業者
                  </th>
                  <th class="text-left">
                    追跡サイトURL
                  </th>
                  <th class="text-center">
                    表示順
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="deliveryCompany in deliveryCompanies"
                  :key="deliveryCompany.id"
                >
                  <td>{{ deliveryCompany.name }}</td>
                  <td>{{ deliveryCompany.tracking_site_url }}</td>
                  <td class="text-center">
                    {{ deliveryCompany.position }}
                  </td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="deliveryCompanyEdit(deliveryCompany)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-pagination
      v-model="page"
      :total-visible="totalVisible"
      :length="pagination.total_pages"
      circle
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
    />
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    name: 'DeliveryCompanyIndex',  // 画面
    components: {},
    mixins: [],
    data () {
      return {
        page: 1, //ページ
        totalVisible: 7,
        tableHeaders:[
          { value: 'name', text: '配送業者' },
          { value: 'tracking_site_url', text: '追跡サイトURL' },
          { value: 'position', text: '表示順' },
          { value: 'editable', text: '', align: 'end' }
        ],
        deliveryCompanies:[], // 運送業者 配列
        inputName: '',        // 名前検索
      }
    },
    // mapState, mapGetters の場合
    computed: {
      ...mapState('backDeliveryCompany', {
        pagination: 'pagination',
        searchQuery: 'query',
      }),
      ...mapGetters('backDeliveryCompany', [
        'isDeliveryCompanies'
      ])
    },
    created() {
      // 初期データ取得
      this.page = this.pagination.current_page
      this.searchDeliveryCompanies()
    },
    // mapMutations, mapActionsの場合
    methods: {
      // actions 参照
      ...mapActions('backDeliveryCompany', {
        // 関数参照
        fetchDeliveryCompanies: 'fetchDeliveryCompanies',
        fetchQuery: 'fetchQuery',
      }),

      // 新規画面 遷移
      deliveryCompanyCreate: function () {
        this.$router.push({name: 'byDeliveryCompanyNew'})
      },
      // 詳細画面 遷移
      deliveryCompanyEdit: function (deliveryCompany) {
        this.$router.push({name: 'byDeliveryCompanyEdit', params: {id: deliveryCompany.id}})
      },

      // 検索ボタンクリック
      resetDeliveryCompanies () {
        // this.filterDialog = false
        this.page = 1
        this.searchDeliveryCompanies()
      },

      // ページャ切り替え
      changePage () {
        this.searchDeliveryCompanies()
      },
      // データ取得
      searchDeliveryCompanies () {
        // 配送業者名 ページ番号
        const params = { name: this.inputName, page: this.page }
        /**
         * @typedef {Object} content
         * @property {Array} delivery_companies
         */
        // モジュールから一覧データ取得
        this.fetchDeliveryCompanies(params).then((content) => {
          // 書き換え
          this.deliveryCompanies = content.delivery_companies
        })
      }

    }
  }
</script>

<style lang="scss">

</style>
