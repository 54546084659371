<!-- バックヤード > 店舗問い合わせform -->
<template>
  <v-container>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />

    <v-alert
      dense
      elevation="2"
      class="mb-0"
    >
      <div class="body-2">
        {{ ticket.created_at | unix_datetime_format }}
      </div>
      <div class="body-2">
        {{ ticket.subject }}
      </div>
      <v-divider class="my-2" />
      <div class="body-2 line-break">{{ ticket.first_comment }}</div>
    </v-alert>
    <v-row v-if="!isClose">
      <v-col
        cols="12"
        class="text-right"
      >
        <v-btn
          outlined
          color="primary"
          @click="commentDialog=true"
        >
          投稿<span class="lang_lbl">{{ $t('view.投稿する') }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-row
      v-for="item in ticket_comments"
      :key="item.id"
    >
      <v-col cols="12">
        <v-card :elevation="item.my_posted ? '2' : '4'">
          <v-list-item>
            <v-list-item-avatar
              v-if="item.my_posted"
              color="primary"
            >
              <span class="white--text">{{ item.owner_name | avatarNameFilter }}</span>
            </v-list-item-avatar>
            <v-list-item-avatar
              v-else
              color="grey lighten-3"
            >
              <v-icon>mdi-storefront-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>{{ item.created_at | unix_datetime_format }} {{ item.owner_name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mx-4" />
          <v-card-text class="line-break">{{ item.comment }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!pagination.is_last_page">
      <v-col cols="12">
        <div
          class="mt-4"
        >
          <v-btn
            text
            color="primary"
            @click="moreComments"
          >
            さらに表示する<span class="lang_lbl">{{ $t('view.さらに表示する') }}</span>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!--  コメントダイアログ  -->
    <v-dialog
      v-model="commentDialog"
      :fullscreen="($vuetify.breakpoint.xsOnly)"
      :transition="($vuetify.breakpoint.xsOnly) ? 'dialog-bottom-transition' : ''"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-subtitle class="pa-0">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="commentDialog=false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="text-center">
              店舗への問い合わせ<span class="lang_lbl">{{ $t('view.店舗への問い合わせ') }}</span>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <validation-observer
                  ref="observer"
                  v-slot="{ invalid }"
                >
                  <v-card-text>
                    <v-row class="pt-4">
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:10000"
                          :name="'問い合わせ内容（' + $t('view.問い合わせ内容') + '）'"
                          class="requiredMark"
                        >
                          <v-textarea
                            v-model="comment"
                            outlined
                            :label="'問い合わせ内容（' + $t('view.問い合わせ内容') + '）'"
                            :placeholder="'問い合わせ内容を入力してください。（' + $t('multi.入力してください', { item: $t('view.問い合わせ内容') }) + '）'"
                            value=""
                            counter="10000"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      block
                      outlined
                      :disabled="invalid"
                      @click="submitTicketComment"
                    >
                      <v-icon>mdi-post-outline</v-icon>
                      投稿する<span class="lang_lbl">{{ $t('view.投稿する') }}</span>
                    </v-btn>
                  </v-card-actions>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import TicketMixin from '../../../../mixins/ticket-mixin'

import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";

export default {
  name: 'MembershipTicketEdit',
  mixins: [
    TicketMixin,
    PageHeaderMixin
  ],
  data () {
    return {
      page: 1,
      breadcrumbs: [
        { text: "問い合わせ一覧", disabled: false, to: '/tickets', exact: true },
        { text: "問い合わせ詳細", disabled: true, to: '' },
      ],
      commentDialog: false,
      ticketId: this.$route.params.id,
      comment: '',
      ticket_comments: [],
      ticket: {
        status: 'open',
        subject: '',
        first_comment: '',
        created_at: null,
      }
    }
  },
  computed: {
    ...mapState('frontTicketComment', {
      pagination: 'pagination',
    }),
    isClose() {
      return (this.ticket.status === 'closed')
    }
  },
  created() {
    this.descriptionContent = this.$route.meta.siteSubTitle
    this.pageTitle = this.$route.meta.siteSubTitle
    this.findTicket({id: this.ticketId}).then((content) => {
      this.ticket.status = content.status
      this.ticket.subject = content.subject
      this.ticket.first_comment = content.first_comment
      this.ticket.created_at = content.created_at
    })
    this.loadTicketComments()
  },
  methods: {
    ...mapActions('frontTicket', {
      findTicket: 'findTicket'
    }),
    ...mapActions('frontTicketComment', {
      findComments: 'findComments',
      createComment: 'create'
    }),
    submitTicketComment () {
      const params = {
        comment: this.comment
      }
      this.createComment({ id: this.ticketId, params: params}).then(() => {
        this.commentDialog = false
        this.comment = null
        this.$refs.observer.reset()
        this.page = 1
        this.ticket_comments = []
        this.loadTicketComments()
      })
    },
    loadTicketComments() {
      const params = {
        page: this.page
      }
      this.findComments({id: this.ticketId, params: params}).then((content) => {
          this.ticket_comments = [...this.ticket_comments, ...content.ticket_comments]
     })
    },
    moreComments() {
      this.page++
      this.loadTicketComments()
    },
  },
}
</script>

<style lang="scss">

</style>
