/**
 * 会員管理
 */
import Vue from 'vue'
import * as httpConst from '../../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/announcements`

const read_announcement = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        create ({ _commit }, { announcement_id }) {
            return new Promise((resolve, _reject) => {
                const api_path = `${API_PATH}/${announcement_id}/read`
                Vue.$http.post(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
    },
    mutations: { },
}

export default read_announcement
