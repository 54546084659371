<template>
  <div>
    <v-app-bar
      app
      color="black accent-4"
      dense
      dark
      fixed
    >
      <v-app-bar-nav-icon
        @click.stop="left = !left"
      />
      <v-toolbar-title>
        bizRENT Admin
      </v-toolbar-title>
      <v-spacer />
      <strong>{{ avatarName }}</strong>
      <v-btn
        icon
        @click="logout"
      >
        <v-icon>
          mdi-logout
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="left"
      fixed
      temporary
      color="blue lighten-5"
    >
      <v-list>
        <v-list-item
          @click="menuLink('adCompanyIndex')"
        >
          <v-list-item-icon>
            <v-icon>mdi-office-building</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>会社情報</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          @click="menuLink('adAnnouncementIndex')"
        >
          <v-list-item-icon>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>システムお知らせ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'HeaderNav',
    data: () => ({
      left: false,
    }),
    computed : {
      ...mapGetters('adminAuth', [
          'avatarName'
      ])
    },
    methods: {
      ...mapActions('adminAuth', {
        signOut: 'signOutAdmin',
      }),
      menuLink: function (menu) {
        this.$router.push({name: menu} )
      },
      logout: function () {
        this.signOut().then(() => {
          this.$router.push( { name: 'AdminSessionIndex' } )
        })
      }
    }
  }
</script>

<style scoped>

</style>