/**
 * 注文
 */
import Vue from "vue";
import * as httpConst from "../../../constants/httpConst";
import * as types from "../../mutation-types/frontend-types";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/orders`

const order = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0
    },
  },
  getters: {
    isOrder: ( state ) => {
      return (state.pagination.total_count > 0)
    },
  },
  actions: {
    create ({ commit }, { params }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.post(API_PATH, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
    findHistories ({ commit }, { params }) {
      const api_path = `${API_PATH}/histories`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path, { params: params }).then(data => {
          const content = data.content
          commit(types.FRONT_ORDER_DETAIL, { content })
          resolve(content)
        })
      })
    },
    find ({ _commit }, {id}) {
      const api_path = `${API_PATH}/histories/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
  },
  mutations: {
    [types.FRONT_ORDER_DETAIL] (state, { content }) {
      state.pagination = content.pagination
    },
  },
}

export default order
