/**
 * 配送業者 配送時間
 */
import Vue from 'vue'
import * as httpConst from '../../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/delivery_companies`

const delivery_company_time = {
    namespaced: true,

    // アプリケーションの状態（情報）
    state: {},

    // state 内の状態を元に算出した値を返す関数
    getters: {},
    actions: {
        // 運送時間一覧
        fetchAll({_commit},{ delivery_company_id }) {
            // パラメータセット
            const api_path = `${API_PATH}/${delivery_company_id}/delivery_company_times`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        new ({ _commit }, { delivery_company_id  }) {
            const api_path = `${API_PATH}/${delivery_company_id}/delivery_company_times/new`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        edit ({ _commit }, { delivery_company_id, id }) {
            const api_path = `${API_PATH}/${delivery_company_id}/delivery_company_times/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        // 運送時間更新・削除
        create ({ commit }, { delivery_company_id, params }) {
            const api_path = `${API_PATH}/${delivery_company_id}/delivery_company_times`
            return new Promise((resolve, _reject) => {
                Vue.$http.post(api_path, params).then(data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
        update ({ commit }, { delivery_company_id, id, params }) {
            const api_path = `${API_PATH}/${delivery_company_id}/delivery_company_times/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.put(api_path, params).then(data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
        destroy ({ commit }, { delivery_company_id, id }) {
            const api_path = `${API_PATH}/${delivery_company_id}/delivery_company_times/${id}`
            return new Promise((resolve, _reject) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    resolve(data.content)
                })
            })
        },
    },

    // stateのデータを更新
    mutations: {},
}

export default delivery_company_time