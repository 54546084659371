/**
 * オプショングループ
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/option_groups`

const option_group = {
  namespaced: true,
  state: {
    pagination: {
      current_page: 1,
      total_count: 0
    },
    query: {
      keyword: '',
    },
  },
  getters: {
    hasOptionGroups: ( state ) => {
      return (state.pagination.total_count > 0)
    },
    optionGroup(state) {
      return state.option_group
    }
  },
  actions: {
    fetchAll({ dispatch, commit, state }, {keyword, page}) {
      const queries = {
        keyword: keyword,
      }
      dispatch('fetchQuery', queries)
      const params = {
        keyword: state.query.keyword,
        page: page
      }
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH, { params: params }).then(data => {
          let content = data.content
          commit(types.BACK_OPTION_GROUP, { content })
          resolve(content)
        })
      })
    },
    fetchQuery({ commit, state}, { keyword }) {
      const keywordText = (keyword !== undefined)? keyword : state.query.keyword
      const params = {
        keyword: keywordText,
      }
      commit(types.BACK_OPTION_GROUP_QUERY, { params })
    },
    new ({ _commit }) {
      const api_path = `${API_PATH}/new`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    edit({ _commit, state }, { id }) {
      const api_path = `${API_PATH}/${id}/edit`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    create( { commit, state }, { params } ) {
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(API_PATH, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    update( {commit, state}, { id, params } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(content)
        } )
      })
    },
    destroy( { commit, state }, { id } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject ) => {
        Vue.$http.delete(api_path).then(data => {
          commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
  },
  mutations: {
    [types.BACK_OPTION_GROUP] (state, { content }) {
      state.pagination = content.pagination
    },
    [types.BACK_OPTION_GROUP_QUERY] (state, { params }) {
      state.query.keyword = params.keyword
    },
  },
}

export default option_group