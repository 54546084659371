var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"text-right mb-2"},[_c('v-btn',{attrs:{"disabled":!_vm.canAddCommercialLaw,"small":""},on:{"click":_vm.addButtonClickEvent}},[_vm._v("\n                追加\n              ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.commercialLaws,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                  "+_vm._s(_vm._f("truncate")(item.description,30))+"\n                ")]}},{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"cyan darken-2"},on:{"click":function($event){return _vm.editButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1):_c('v-btn',{attrs:{"text":"","color":"cyan"},on:{"click":function($event){return _vm.editButtonClickEvent(item)}}},[_vm._v("\n                    編集する\n                  ")]),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteButtonClickEvent(item)}}},[_vm._v("\n                    削除する\n                  ")])]}}])})],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"800","persistent":"","no-click-animation":""},model:{value:(_vm.showCommercialLawDialog),callback:function ($$v) {_vm.showCommercialLawDialog=$$v},expression:"showCommercialLawDialog"}},[_c('v-form',[_c('alert-message'),_vm._v(" "),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_vm._v("特定商取引")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:30","name":"タイトル"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"30","dense":"","hide-details":"auto","label":"タイトル","outlined":"","placeholder":" ","required":""},model:{value:(_vm.commercial_law.title),callback:function ($$v) {_vm.$set(_vm.commercial_law, "title", $$v)},expression:"commercial_law.title"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:5120","name":"詳細"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{staticClass:"custom-quill-container",attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"詳細","required":""},model:{value:(_vm.commercial_law.description),callback:function ($$v) {_vm.$set(_vm.commercial_law, "description", $$v)},expression:"commercial_law.description"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|numeric|min_value:0|max_value:2147483647","name":"表示順"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"表示順","outlined":"","placeholder":" ","required":"","reverse":""},model:{value:(_vm.commercial_law.position),callback:function ($$v) {_vm.$set(_vm.commercial_law, "position", $$v)},expression:"commercial_law.position"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){_vm.showCommercialLawDialog = false}}},[_vm._v("\n                キャンセル\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":_vm.dialogRegisterButtonClickEvent}},[_vm._v("\n                OK\n              ")])],1)],1)]}}])})],1)],1),_vm._v(" "),_c('Confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }