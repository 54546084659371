var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert-message'),_vm._v(" "),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.customer)?_c('v-form',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|email|max:254","name":"メールアドレス"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"メールアドレス","autocomplete":"off","placeholder":" ","counter":"254","error-messages":errors,"required":""},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:254","name":"ログインID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"ログインID","placeholder":" ","counter":"254","autocomplete":"off","error-messages":errors,"required":""},model:{value:(_vm.customer.login_id),callback:function ($$v) {_vm.$set(_vm.customer, "login_id", $$v)},expression:"customer.login_id"}})]}}],null,true)})],1)],1),_vm._v(" "),(!_vm.customer.id)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","vid":"password","name":"パスワード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":errors,"type":_vm.showPassword ? 'text' : 'password',"autocomplete":"new-password","label":"パスワード","placeholder":" ","required":""},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.customer.password),callback:function ($$v) {_vm.$set(_vm.customer, "password", $$v)},expression:"customer.password"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|confirmed:password","name":"パスワード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","label":"パスワード（確認用）","autocomplete":"new-password","placeholder":" ","required":"","error-messages":errors},model:{value:(_vm.customer.password_confirmation),callback:function ($$v) {_vm.$set(_vm.customer, "password_confirmation", $$v)},expression:"customer.password_confirmation"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:30","name":"氏名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"氏名","counter":"30","placeholder":" ","required":"","error-messages":errors},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"max:30","name":"フリガナ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"フリガナ","placeholder":" ","counter":"30","error-messages":errors},model:{value:(_vm.customer.name_kana),callback:function ($$v) {_vm.$set(_vm.customer, "name_kana", $$v)},expression:"customer.name_kana"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:20","name":"公開名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"20","hint":"商品レビューに表示されます","label":"公開名","placeholder":" ","persistent-hint":"","required":""},model:{value:(_vm.customer.bundle_name),callback:function ($$v) {_vm.$set(_vm.customer, "bundle_name", $$v)},expression:"customer.bundle_name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"生年月日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"error-messages":errors,"label":"生年月日","readonly":"","placeholder":" ","required":""},model:{value:(_vm.customer.birthday),callback:function ($$v) {_vm.$set(_vm.customer, "birthday", $$v)},expression:"customer.birthday"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_vm._v(" "),_c('v-date-picker',{ref:"picker",attrs:{"max":_vm.date,"day-format":function (date) { return new Date(date).getDate(); },"min":"1920-01-01"},on:{"change":_vm.saveBirthday},model:{value:(_vm.customer.birthday),callback:function ($$v) {_vm.$set(_vm.customer, "birthday", $$v)},expression:"customer.birthday"}})],1)],1)],1),_vm._v(" "),(_vm.customer.customer_items)?_c('v-row',_vm._l((_vm.customer.customer_items),function(item){return _c('v-col',{key:item.customer_item_id,attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{class:(item.required)? 'requiredMark' : '',attrs:{"rules":(item.required)? 'required|max:50' : 'max:50',"name":item.title},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":item.title,"placeholder":" ","counter":"50","error-messages":errors,"required":""},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})]}}],null,true)})],1)}),1):_vm._e(),_vm._v(" "),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6","sm":"3"}},[_c('validation-provider',{attrs:{"rules":"max:7","name":"郵便番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"7","hint":"ハイフン(-)無しで入力してください","label":"郵便番号","persistent-hint":"","placeholder":" ","required":""},model:{value:(_vm.customer.zip_code),callback:function ($$v) {_vm.$set(_vm.customer, "zip_code", $$v)},expression:"customer.zip_code"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[_c('zip-code-search',{attrs:{"zip-code":_vm.customer.zip_code},on:{"zipAddress":_vm.setZipAddress}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('validation-provider',{attrs:{"name":"都道府県"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.prefList,"item-text":"text","item-value":"value","label":"都道府県","placeholder":" ","required":""},model:{value:(_vm.customer.prefecture_code),callback:function ($$v) {_vm.$set(_vm.customer, "prefecture_code", $$v)},expression:"customer.prefecture_code"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"max:100","name":"市区町村・番地"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"100","label":"市区町村・番地","placeholder":" ","required":""},model:{value:(_vm.customer.address),callback:function ($$v) {_vm.$set(_vm.customer, "address", $$v)},expression:"customer.address"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"numeric|max:11","name":"電話番号(自宅 または 携帯電話)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"11","hint":"ハイフン(-)無しで入力してください","label":"電話番号(自宅 または 携帯電話)","persistent-hint":"","placeholder":" ","required":""},model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", $$v)},expression:"customer.phone"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"max:25","name":"海外の方の連絡先電話番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{class:_vm.telClassName,staticStyle:{"font-size":"12px"}},[_vm._v("\n                  海外の方の連絡先電話番号\n                ")]),_vm._v(" "),_c('vue-tel-input',_vm._b({attrs:{"name":"海外の方の連絡先電話番号","error-messages":errors},model:{value:(_vm.customer.international_phone),callback:function ($$v) {_vm.$set(_vm.customer, "international_phone", $$v)},expression:"customer.international_phone"}},'vue-tel-input',_vm.vueTelProps,false)),_vm._v(" "),_c('v-input',{directives:[{name:"show",rawName:"v-show",value:(errors[0] != null),expression:"errors[0] != null"}],attrs:{"error-messages":errors}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card-subtitle',{staticClass:"pl-0 pb-0 caption"},[_vm._v("\n                性別\n              ")]),_vm._v(" "),_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":""},model:{value:(_vm.customer.gender),callback:function ($$v) {_vm.$set(_vm.customer, "gender", $$v)},expression:"customer.gender"}},_vm._l((_vm.customer.gender_types),function(item,index){return _c('v-radio',{key:("gender_" + index),attrs:{"label":item.text,"value":item.value}})}),1)],1)],1)],1),_vm._v(" "),(!_vm.customer.id)?_c('h4',{staticClass:"text-center"},[_vm._v("\n          ご登録いただいたメールアドレスに認証メールが届きます。 認証メールに記載されたURLへアクセスすることで認証が完了しログインが可能となります。\n        ")]):_vm._e(),_vm._v(" "),_c('v-card-actions',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-btn',{attrs:{"color":"primary","block":_vm.$vuetify.breakpoint.mdAndDown,"disabled":invalid},on:{"click":_vm.submit}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("\n                  mdi-account-edit-outline\n                ")]),_vm._v("登録\n              ")],1)],1)],1)],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }