<template>
  <v-card outlined>
    <v-card-text class="pb-0">
      <v-row>
        <v-col
          cols="4"
          class="text-center"
        >
          <v-avatar
            tile
            size="80"
          >
            <v-img
              :alt="item.product.name"
              :src="item.product.image_url | load_image_url"
              :lazy-src="item.product.image_url | load_image_url"
            />
          </v-avatar>
        </v-col>
        <v-col
          cols="8"
          class="pb-1"
        >
          <div class="subtitle-1 pb-1">
            {{ item.product.name }}
          </div>
          <div class="subtitle-2">
            注文日：{{ item.order_date | date_format }}
          </div>
          <div class="subtitle-2">
            ご利用終了日：{{ item.return_schedule_date | date_format }}
          </div>
          <div class="subtitle-2">
            金額：{{ item.total_amount | yen_format }}
          </div>
          <div class="subtitle-2">
            数量：{{ item.quantity }}
          </div>
          <div class="caption">
            {{ item.status | statusFilter }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-spacer />
      <v-row class="mx-1">
        <v-col
          cols="12"
          class="px-0 py-1"
        >
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="grey lighten-3"
        >
          <span class="body-2">金額</span>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          class="body-2"
        >
          {{ extendTotalAmount | yen_format }}
        </v-col>
      </v-row>

      <v-row class="mx-1">
        <v-col
          cols="12"
          class="px-0 py-1"
        >
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="grey lighten-3"
        >
          <span class="body-2">延長日数</span>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          class="body-2"
        >
          <v-row>
            <template
              v-if="item.rental_maximum_left_days <= 0"
            >
              <v-col
                cols="12"
                class="py-0"
              >
                この商品は延長できません。
              </v-col>
            </template>
            <template
              v-else
            >
              <v-col
                cols="8"
                md="5"
                xs="5"
                class="py-0"
              >
                <validation-provider
                  v-if="isEdit"
                  v-slot="{ errors }"
                  :rules="{numeric: true, min_value: 0, max_value: item.rental_maximum_left_days || null }"
                  name="延長日数"
                >
                  <v-text-field
                    v-model="extendDays"
                    dense
                    hide-details="auto"
                    outlined
                    reverse
                    :prefix="item.product.rental_unit | rentalUnitFilter"
                    :error-messages="errors"
                  />
                </validation-provider>
                <template v-else>
                  {{ item.extend_days }} {{item.product.rental_unit | rentalUnitFilter}}
                </template>
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                {{ item.rental_maximum_left_days || 0 | rentalMaxUnitFilter(item.product.rental_unit) }}
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="mx-1">
        <v-col
          cols="12"
          class="px-0 py-1"
        >
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="grey lighten-3"
        >
          <span class="body-2">ご利用終了日</span>
        </v-col>
        <v-col
          cols="12"
          sm="8"
          class="body-2"
        >
          {{ extendReturnDate | date_format }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>


<script>
import CartStepperMixin from '../../../../../mixins/frontend/cart-stepper-mixin'
import ProductMixin from "../../../../../mixins/product-mixin"
import {mapActions} from "vuex"
import moment from "moment"

export default {
  name: 'ExtendOrderProduct',
  components: {
    // Confirm,
  },
  filters: {
    statusFilter(status) {
      if (status === 'canceled') {
        return 'キャンセル済'
      } else if (status === 'shipped') {
        return '出荷済'
      } else if (status === 'returned') {
        return '返却済'
      } else {
        return '出荷準備中'
      }
    }
  },
  mixins: [
    ProductMixin,
    CartStepperMixin
  ],
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      extendDays: null,
      extendTotalAmount: 0,
      extendTaxAmount: 0,
      extendReturnDate: null
    }
  },
  watch: {
    extendDays (newVal) {
      if (0 > newVal || this.item.rental_maximum_left_days < newVal) {
        this.invalidVariable()
      } else {
        this.calcDisplayPrice()
      }
    }
  },
  created() {
    this.extendDays = this.item.extend_days
  },
  methods: {
    ...mapActions('frontExtendOrder', {
      updateExtendOrder: 'update'
    }),
    rental_day () {
      if (this.item.product_id) {
        return this.item.rental_days
      } else {
        return this.item.rental_days + '日'
      }
    },
    calcDisplayPrice () {
      const number = Number(this.extendDays)

      if (!Number.isInteger(number)) {
        this.invalidVariable()
        return
      }
      if (number <= 0) {
        this.invalidVariable()
        return
      }

      const quantity = Number(this.item.quantity)

      if (!Number.isInteger(quantity)) {
        this.invalidVariable()
        return
      }
      if (quantity <= 0) {
        this.invalidVariable()
        return
      }
      let product = this.item.product
      let totalPrice = 0

      totalPrice = product.price * this.extendDays * this.item.quantity
      let in_tax_price = totalPrice
      if (!product.tax_exempt) {
        const tax = totalPrice * (product.tax_rate / 100)
        in_tax_price = totalPrice + tax
        this.extendTaxAmount = tax
      }

      if (in_tax_price <= 0) in_tax_price = product.in_tax_price
      this.extendTotalAmount = this.calcRoundPrice(in_tax_price, product.tax_fraction)
      let new_date = moment(this.item.return_schedule_date).add(this.extendDays, 'd')
      this.extendReturnDate = new_date

      this.updateState()

    },
    invalidVariable () {
      this.extendReturnDate = null
      this.extendTotalAmount = 0
      this.extendTaxAmount = 0
      this.updateState()
    },
    updateState () {
      let params = {
        extend_days: this.extendDays,
        extend_return_date: this.extendReturnDate,
        extend_total_amount: this.extendTotalAmount,
        extend_tax_amount: this.extendTaxAmount,
      }
      this.updateExtendOrder({id: this.item.id, params: params})
      this.$emit('reCalcTotalPrice')
    },
    toNext () {
      this.$emit('toNextStep')
    },
  },
}
</script>

<style scoped lang="scss">
</style>