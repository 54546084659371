<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    temporary
  >
    <v-list
      dense
      nav
      class="py-0"
    >
      <v-list-item
        v-if="logged_in"
        class="px-0"
      >
        <v-list-item-avatar color="primary">
          <span class="white--text">{{ avatarName }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle>こんにちは、</v-list-item-subtitle>
          <v-list-item-title class="subtitle-2">
            <div>{{ honorificName }}</div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />
      <v-list-item-group
        v-model="listItem"
        mandatory
        color="primary"
      >
        <v-list-item
          link
          @click="moveToPage('HomeIndex')"
        >
          <v-list-item-icon>
            <v-icon>mdi-storefront-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              ホーム
              <span class="lang_lbl">{{ $t('view.ホーム') }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- カテゴリー -->
        <side-nav-search-list-item
          v-if="isCategories"
          search-type="category"
          title="カテゴリーから探す"
          :items="categories"
        />
        <!-- キャンペーン -->
        <side-nav-search-list-item
          v-if="isCampaigns && auctionMode"
          search-type="campaign"
          title="オークションから探す"
          :items="campaigns"
        />
        <side-nav-search-list-item
          v-if="isCampaigns && !auctionMode"
          search-type="campaign"
          title="キャンペーンから探す"
          :items="campaigns"
        />
        <!-- タグ -->
        <side-nav-search-list-item
          v-if="isTags"
          search-type="tag"
          title="タグから探す"
          :items="tags"
        />
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import FooterActionMixin from '../../mixins/frontend/footer-action-mixin'
  import SideNavSearchListItem from './SideNavSearchListItem'

  export default {
    name: 'SideNav',
    components: {
      SideNavSearchListItem
    },
    mixins: [
      FooterActionMixin,
    ],
    data () {
      return {
        drawer: false,
        listItem: null,
      }
    },
    computed: {
      ...mapState('frontAuth', {
        loggedIn: 'logged_in',
      }),
      ...mapState('frontAuth', {
        name: 'name'
      }),
      ...mapGetters('frontAuth', [
        'avatarName', 'honorificName'
      ]),
      ...mapState('frontSystemSetting', {
        auctionMode: 'auction_mode'
      }),
      logged_in () {
        return this.loggedIn
      },
    },
    created() {
    },
    methods: {
      ...mapActions('frontAuth', {
        signOut: 'signOutFrontend',
      }),
      ...mapActions('frontCart', {
        resetCartCount: 'resetCount'
      }),
      showSideNav () {
        this.drawer = true
      },
    },
  }
</script>

<style scoped lang="scss">
</style>