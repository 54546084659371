
/**
 * 左右スクロール
 */
const ScrollBoxMixin = {
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  filters: {},
  methods: {
    scrollToRight (targetClass) {
      const target = targetClass || 'scroll-box'
      let content = document.querySelector(`.${target}`)
      content.scrollLeft += 100
    },
    scrollToLeft (targetClass) {
      const target = targetClass || 'scroll-box'
      let content = document.querySelector(`.${target}`)
      content.scrollLeft -= 100
    }
  }
}

export default ScrollBoxMixin
