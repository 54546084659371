/**
 * 契約情報
 */

import Vue from 'vue'
import * as httpConst from '../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/contracts`

const contract = {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        // 一覧取得
        show () {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH).then(data => {
                    resolve(data.content)
                })
            })
        },
    },
    mutations: {},

}

export default contract