var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"small":""},on:{"click":_vm.addButtonClickEvent}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.allowIpmasks)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.allowIpmasks,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"cyan darken-2"},on:{"click":function($event){return _vm.editButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1):_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.editButtonClickEvent(item)}}},[_vm._v("\n                  編集する\n                ")]),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.deleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}],null,false,1126302326)}):_vm._e()],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"450","persistent":"","no-click-animation":""},model:{value:(_vm.showAllowIpmaskDialog),callback:function ($$v) {_vm.showAllowIpmaskDialog=$$v},expression:"showAllowIpmaskDialog"}},[_c('v-form',[_c('alert-message'),_vm._v(" "),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_vm._v("IPアドレス設定")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:30","name":"IPアドレス"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"IPアドレス","dense":"","hide-details":"auto","outlined":"","clearable":"","placeholder":"192.168.0.1/32","required":"","counter":"30","error-messages":errors},model:{value:(_vm.allow_ipmask.ip_address),callback:function ($$v) {_vm.$set(_vm.allow_ipmask, "ip_address", $$v)},expression:"allow_ipmask.ip_address"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"max:50","name":"備考"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"備考","dense":"","hide-details":"auto","outlined":"","clearable":"","placeholder":" ","required":"","counter":"50","error-messages":errors},model:{value:(_vm.allow_ipmask.remarks),callback:function ($$v) {_vm.$set(_vm.allow_ipmask, "remarks", $$v)},expression:"allow_ipmask.remarks"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){_vm.showAllowIpmaskDialog = false}}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.dialogRegisterButtonClickEvent}},[_vm._v("\n              OK\n            ")])],1)],1)]}}])})],1)],1),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }