<!-- バックヤード > ユーザーform -->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="ログインID"
                      rules="required"
                    >
                      <v-text-field
                        v-model="user.login_id"
                        :error-messages="errors"
                        counter="254"
                        dense
                        hide-details="auto"
                        label="ログインID"
                        outlined
                        placeholder=" "
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="ユーザー名"
                      rules="required|max:25"
                    >
                      <v-text-field
                        v-model="user.name"
                        :error-messages="errors"
                        counter="25"
                        dense
                        hide-details="auto"
                        label="ユーザー名"
                        outlined
                        placeholder=" "
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="メールアドレス"
                      rules="required|max:254"
                    >
                      <v-text-field
                        v-model="user.email"
                        :error-messages="errors"
                        counter="254"
                        dense
                        hide-details="auto"
                        label="メールアドレス"
                        outlined
                        placeholder=" "
                        required
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-radio-group
                      v-model="user.role"
                      :mandatory="true"
                      class="pa-0 ma-0"
                      dense
                      hide-details="auto"
                      label="権限"
                      row
                    >
                      <template v-for="item in user.roles">
                        <v-radio
                          :key="item.value"
                          :value="item.value"
                          :label="item.text"
                        />
                      </template>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="表示順"
                      rules="required|numeric|min_value:0|max_value:2147483647"
                    >
                      <v-text-field
                        v-model="user.position"
                        :error-messages="errors"
                        dense
                        hide-details="auto"
                        label="表示順"
                        outlined
                        placeholder=" "
                        required
                        reverse
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="isEdit&&!user.initial_user"
                  color="error"
                  @click="deleteButtonClickEvent"
                >
                  削除
                </v-btn>
                <v-spacer />
                <v-btn
                  @click="cancelButtonClickEvent"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="registerButtonClickEvent"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <confirm ref="confirm" />
  </div>
</template>

<script>

import AlertMessage from '../../../components/AlertMessage'
import Confirm from '../../../components/Confirm'
import { mapActions } from 'vuex'
export default {
  components: {
    AlertMessage,
    Confirm,
  },
  mixins: [
  ],
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {

    }
  },
  created() {

  },
  methods: {
    ...mapActions('backUser', {
      createUser: 'create',
      updateUser: 'update',
      destroyUser: 'destroy'
    }),
    cancelButtonClickEvent () {
      this.$router.push({name: 'byUserIndex'})
    },
    registerButtonClickEvent () {

      if ( this.isEdit ) {
        this.updateUser({ id: this.user.id, params: this.user }).then((  ) => {
          this.$router.push({name: 'byUserIndex'})
        })
      } else {
        this.createUser({ params: this.user }).then((  ) => {
          this.$router.push({name: 'byUserIndex'})
        })
      }
    },
    deleteButtonClickEvent () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyUser({ id: this.user.id }).then((  ) => {
            this.$router.push({name: 'byUserIndex'})
          })
        }
      })

    }
  },
}

</script>

<style scoped lang="scss">

div.v-input--radio-group__input legend {
  width: 100%;
  margin-bottom: 10px;
}

</style>
