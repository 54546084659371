/**
 * SHOPサイト画面設定
 */
import Vue from 'vue'
import * as httpConst from '../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/theme_option`

const theme_option = {
    namespaced: true,
    actions: {
        edit ({ _commit }) {
            const api_path = `${API_PATH}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    resolve(data.content)
                })
            })
        },
        update ({ commit }, { params }) {
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(`${API_PATH}`, params,{ headers: { 'content-type': 'multipart/form-data' } }).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(data.content)
                } )
            })
        },
    },
    mutations: {
    },
}

export default theme_option
