import BackyardApp from '../backyard/App'

import CampaignIndex from '../backyard/pages/campaigns/Index'
import CampaignNew from '../backyard/pages/campaigns/New'
import CampaignEdit from '../backyard/pages/campaigns/Edit'
import CategoryIndex from '../backyard/pages/categories/Index'
import CategoryNew from '../backyard/pages/categories/New'
import CategoryEdit from '../backyard/pages/categories/Edit'
import TagIndex from '../backyard/pages/tags/Index'
import TagNew from '../backyard/pages/tags/New'
import TagEdit from '../backyard/pages/tags/Edit'
import LocalPlaceIndex from '../backyard/pages/local_places/Index'
import LocalPlaceNew from '../backyard/pages/local_places/New'
import LocalPlaceEdit from '../backyard/pages/local_places/Edit'

import byOptionGroupIndex from '../backyard/pages/option_groups/Index'
import byOptionGroupNew from '../backyard/pages/option_groups/New'
import byOptionGroupEdit from '../backyard/pages/option_groups/Edit'

import byConfigurationIndex from '../backyard/pages/configuration/Index'

import byContractIndex from '../backyard/pages/contract/Index'
import CreditEdit from '../backyard/pages/credit/Edit'
import byDeliveryCompanyNew from '../backyard/pages/deliveryCompanies/New'
import byDeliveryCompanyEdit from '../backyard/pages/deliveryCompanies/Edit'
import byDeliveryCompanyIndex from '../backyard/pages/deliveryCompanies/Index'

import byDeliveryMethodIndex from '../backyard/pages/deliveryMethods/Index'
import byDeliveryMethodEdit from '../backyard/pages/deliveryMethods/Edit'

import byDeliveryDesiredTimeIndex from '../backyard/pages/deliveryDesiredTimes/Index'
import byDeliveryDesiredTimeNew from '../backyard/pages/deliveryDesiredTimes/New'
import byDeliveryDesiredTimeEdit from '../backyard/pages/deliveryDesiredTimes/Edit'

import byActivateEdit from '../backyard/pages/activate/Edit'
import byActivateCompleted from '../backyard/pages/activate/Completed'

import byForgetIndex from '../backyard/pages/forget/Index'
import byForgetSent from '../backyard/pages/forget/Sent'
import byForgetEdit from '../backyard/pages/forget/Edit'
import byForgetCompleted from '../backyard/pages/forget/Completed'

import themeOptionEdit from '../backyard/pages/themeOption/Edit'

import byHomeIndex from '../backyard/pages/home/Index'
import byInquiryIndex from '../backyard/pages/inquiries/Index'
import byInquiryEdit from '../backyard/pages/inquiries/Edit'
import byMemberIndex from '../backyard/pages/members/Index'
import byMemberShow from '../backyard/pages/members/Show'
import byMemberNew from '../backyard/pages/members/New'
import byMemberEdit from '../backyard/pages/members/Edit'
import byMemberActivityIndex from '../backyard/pages/members/Show'
import byMemberActivityCreateIndex from '../backyard/pages/members/Show'
import byMemberActivityEditIndex from '../backyard/pages/members/Show'
import byMemberInquiryIndex from '../backyard/pages/members/Index'
import byMemberInquiryEditIndex from '../backyard/pages/members/Show'
import byMemberNoticeIndex from '../backyard/pages/members/Index'
import byMemberNoticeEditIndex from '../backyard/pages/members/Show'
import byMemberOrderIndex from '../backyard/pages/members/Index'
import byMemberOrderShowIndex from '../backyard/pages/members/Show'
import byMemberUploadIndex from '../backyard/pages/members/Index'
// import byMemberVersatileIndex from '../backyard/pages/members/Index'

import byNewsIndex from '../backyard/pages/news/Index'
import byNewsNew from '../backyard/pages/news/New'
import byNewsEdit from '../backyard/pages/news/Edit'

import byNoticeMemberCreateIndex from '../backyard/pages/noticeMembers/Create'
import byNoticeMemberEditIndex from '../backyard/pages/noticeMembers/Edit'
import byNoticeMemberIndex from '../backyard/pages/noticeMembers/Index'
import byOrderIndex from '../backyard/pages/orders/Index'
import byOrderShow from '../backyard/pages/orders/Show'
import byRentalScheduleIndex from '../backyard/pages/rentalSchedule/Index'

import byProductIndex from '../backyard/pages/products/Index'
import byProductNew from '../backyard/pages/products/New'
import byProductEdit from '../backyard/pages/products/Edit'

import byProductReviewIndex from '../backyard/pages/product_reviews/Index'
import byProductReview from '../backyard/pages/product_reviews/Show'
import byProductStockIndex from '../backyard/pages/product_stocks/Index'

import bySubscriptionIndex from '../backyard/pages/subscriptions/Index'

import byQaEditIndex from '../backyard/pages/qa/Edit'
import byQaIndex from '../backyard/pages/qa/Index'
import bySessionIndex from '../backyard/pages/sessions/Index'

import byAnnouncementIndex from '../backyard/pages/announcements/Index'
import byAnnouncementShow from '../backyard/pages/announcements/Show'

import bySystemInquiryCreateIndex from '../backyard/pages/systemInquiries/Create'
import bySystemInquiryEditIndex from '../backyard/pages/systemInquiries/Edit'
import bySystemInquiryIndex from '../backyard/pages/systemInquiries/Index'
import byTemplateDocumentEditIndex from '../backyard/pages/templateDocuments/Edit'
import byTemplateDocumentIndex from '../backyard/pages/templateDocuments/Index'
import byTemplateMailCreateIndex from '../backyard/pages/templateMails/Create'
import byTemplateMailEditIndex from '../backyard/pages/templateMails/Edit'
import byTemplateMailIndex from '../backyard/pages/templateMails/Index'

import byUserIndex from '../backyard/pages/users/Index'
import byUserNew from '../backyard/pages/users/New'
import byUserEdit from '../backyard/pages/users/Edit'
import bySendBackIndex from '../backyard/pages/send_backs/Index'

import byMyPageIndex from '../backyard/pages/mypages/Index'
import byMyPageEdit from '../backyard/pages/mypages/Edit'
import NotFound from "../components/pages/NotFound";

// 全てのURLで認証を必要とする。isPublic: true の場合だけ認証不要
export default {
    path: '/backyard',
    component: BackyardApp,
    children: [
        {path: '/', name: 'bySessionIndex', component: bySessionIndex, meta: {isPublic: true}},
        {path: '/backyard/campaigns', name: 'CampaignIndex', component: CampaignIndex},
        {path: '/backyard/campaigns/new', name: 'CampaignNew', component: CampaignNew},
        {path: '/backyard/campaigns/:id/edit', name: 'CampaignEdit', component: CampaignEdit},
        {path: '/backyard/categories', name: 'CategoryIndex', component: CategoryIndex},
        {path: '/backyard/categories/new', name: 'CategoryNew', component: CategoryNew},
        {path: '/backyard/categories/:id/edit', name: 'CategoryEdit', component: CategoryEdit},
        {path: '/backyard/tags', name: 'TagIndex', component: TagIndex},
        {path: '/backyard/tags/new', name: 'TagNew', component: TagNew},
        {path: '/backyard/tags/:id/edit', name: 'TagEdit', component: TagEdit},
        {path: '/backyard/local_places', name: 'LocalPlaceIndex', component: LocalPlaceIndex},
        {path: '/backyard/local_places/new', name: 'LocalPlaceNew', component: LocalPlaceNew},
        {path: '/backyard/local_places/:id/edit', name: 'LocalPlaceEdit', component: LocalPlaceEdit},
        {path: '/backyard/configuration', name: 'byConfigurationIndex', component: byConfigurationIndex},
        {path: '/backyard/contract', name: 'byContractIndex', component: byContractIndex},
        {path: '/backyard/credit/edit', name: 'CreditEdit', component: CreditEdit},

        {path: '/backyard/option_groups', name: 'byOptionGroupIndex', component: byOptionGroupIndex},
        {path: '/backyard/option_groups/new', name: 'byOptionGroupNew', component: byOptionGroupNew},
        {path: '/backyard/option_groups/:id/edit', name: 'byOptionGroupEdit', component: byOptionGroupEdit},

        {path: '/backyard/deliveryCompanies', name: 'byDeliveryCompanyIndex', component: byDeliveryCompanyIndex},
        {path: '/backyard/deliveryCompanies/new', name: 'byDeliveryCompanyNew', component: byDeliveryCompanyNew},
        {path: '/backyard/deliveryCompanies/:id/edit', name: 'byDeliveryCompanyEdit', component: byDeliveryCompanyEdit},

        {path: '/backyard/deliveryMethods', name: 'byDeliveryMethodIndex', component: byDeliveryMethodIndex},
        {path: '/backyard/deliveryMethods/:id/edit', name: 'byDeliveryMethodEdit', component: byDeliveryMethodEdit},

        {path: '/backyard/delivery_desired_times', name: 'byDeliveryDesiredTimeIndex', component: byDeliveryDesiredTimeIndex},
        {path: '/backyard/delivery_desired_times/new', name: 'byDeliveryDesiredTimeNew', component: byDeliveryDesiredTimeNew},
        {path: '/backyard/delivery_desired_times/:id/edit', name: 'byDeliveryDesiredTimeEdit', component: byDeliveryDesiredTimeEdit},

        { path: '/backyard/activate/:token', name: 'byActivateEdit', component: byActivateEdit, meta: {isPublic: true} },
        { path: '/backyard/activate/completed', name: 'byActivateCompleted', component: byActivateCompleted, meta: {isPublic: true} },

        {path: '/backyard/forget', name: 'byForgetIndex', component: byForgetIndex, meta: {isPublic: true}},
        {path: '/backyard/forget/sent', name: 'byForgetSent', component: byForgetSent, meta: { isPublic: true }},
        {path: '/backyard/forget/completed', name: 'byForgetCompleted', component: byForgetCompleted, meta: { isPublic: true }},
        {path: '/backyard/forget/:token', name: 'byForgetEdit', component: byForgetEdit, props: true, meta: { isPublic: true }},

        {path: '/backyard/themeOption/edit', name: 'themeOptionEdit', component: themeOptionEdit},

        {path: '/backyard/home', name: 'byHomeIndex', component: byHomeIndex},
        {path: '/backyard/inquiries', name: 'byInquiryIndex', component: byInquiryIndex},
        {path: '/backyard/inquiries/:id', name: 'byInquiryEdit', component: byInquiryEdit},
        {path: '/backyard/members', name: 'byMemberIndex', component: byMemberIndex},
        {path: '/backyard/members/:id', name: 'byMemberShow', component: byMemberShow},
        {path: '/backyard/members/new', name: 'byMemberNew', component: byMemberNew},
        {path: '/backyard/members/:id/edit', name: 'byMemberEdit', component: byMemberEdit},
        {path: '/backyard/members/:memberId/activities', name: 'byMemberActivityIndex', component: byMemberActivityIndex},
        {path: '/backyard/members/:memberId/activities/create', name: 'byMemberActivityCreateIndex', component: byMemberActivityCreateIndex},
        {path: '/backyard/members/:memberId/activities/:activityId', name: 'byMemberActivityEditIndex', component: byMemberActivityEditIndex},
        {path: '/backyard/members/:memberId/inquiries', name: 'byMemberInquiryIndex', component: byMemberInquiryIndex},
        {path: '/backyard/members/:memberId/inquiries/:inquiryId', name: 'byMemberInquiryEditIndex', component: byMemberInquiryEditIndex},
        {path: '/backyard/members/:memberId/notices', name: 'byMemberNoticeIndex', component: byMemberNoticeIndex},
        {path: '/backyard/members/:memberId/notices/:noticeId', name: 'byMemberNoticeEditIndex', component: byMemberNoticeEditIndex},
        {path: '/backyard/members/:memberId/orders', name: 'byMemberOrderIndex', component: byMemberOrderIndex},
        {path: '/backyard/members/:memberId/orders/:orderId', name: 'byMemberOrderShowIndex', component: byMemberOrderShowIndex},
        {path: '/backyard/members/:memberId/uploads', name: 'byMemberUploadIndex', component: byMemberUploadIndex},
        // {path: '/backyard/members/:memberId/versatile', name: 'byMemberVersatileIndex', component: byMemberVersatileIndex},
        {path: '/backyard/news', name: 'byNewsIndex', component: byNewsIndex},
        {path: '/backyard/news/new', name: 'byNewsNew', component: byNewsNew},
        {path: '/backyard/news/:id/edit', name: 'byNewsEdit', component: byNewsEdit},
        {path: '/backyard/noticeMembers', name: 'byNoticeMemberIndex', component: byNoticeMemberIndex},
        {path: '/backyard/noticeMembers/create', name: 'byNoticeMemberCreateIndex', component: byNoticeMemberCreateIndex},
        {path: '/backyard/noticeMembers/:id', name: 'byNoticeMemberEditIndex', component: byNoticeMemberEditIndex},

        {path: '/backyard/orders', name: 'byOrderIndex', component: byOrderIndex},
        {path: '/backyard/orders/:id', name: 'byOrderShow', component: byOrderShow},

        {path: '/backyard/rentalSchedules', name: 'byRentalScheduleIndex', component: byRentalScheduleIndex},

        {path: '/backyard/products', name: 'byProductIndex', component: byProductIndex},
        {path: '/backyard/products/new', name: 'byProductNew', component: byProductNew},
        {path: '/backyard/products/:id/edit', name: 'byProductEdit', component: byProductEdit},
        {path: '/backyard/subscriptions', name: 'bySubscriptionIndex', component: bySubscriptionIndex },
        {path: '/backyard/product_reviews', name: 'byProductReviewIndex', component: byProductReviewIndex },
        {path: '/backyard/product_reviews/:id', name: 'byProductReview', component: byProductReview },
        {path: '/backyard/product_stocks', name: 'byProductStockIndex', component: byProductStockIndex},
        {path: '/backyard/qas', name: 'byQaIndex', component: byQaIndex},
        {path: '/backyard/qa/:id', name: 'byQaEditIndex', component: byQaEditIndex},
        {path: '/backyard/announcements', name: 'byAnnouncementIndex', component: byAnnouncementIndex},
        {path: '/backyard/announcements/:id', name: 'byAnnouncementShow', component: byAnnouncementShow},
        {path: '/backyard/systemInquiries/', name: 'bySystemInquiryIndex', component: bySystemInquiryIndex},
        {path: '/backyard/systemInquiries/create', name: 'bySystemInquiryCreateIndex', component: bySystemInquiryCreateIndex},
        {path: '/backyard/systemInquiries/:id', name: 'bySystemInquiryEditIndex', component: bySystemInquiryEditIndex},
        {path: '/backyard/templateDocuments', name: 'byTemplateDocumentIndex', component: byTemplateDocumentIndex},
        {path: '/backyard/templateDocuments/:id', name: 'byTemplateDocumentEditIndex', component: byTemplateDocumentEditIndex},
        {path: '/backyard/templateMails', name: 'byTemplateMailIndex', component: byTemplateMailIndex},
        {path: '/backyard/templateMails/create', name: 'byTemplateMailCreateIndex', component: byTemplateMailCreateIndex},
        {path: '/backyard/templateMails/:id', name: 'byTemplateMailEditIndex', component: byTemplateMailEditIndex},
        {path: '/backyard/users', name: 'byUserIndex', component: byUserIndex},
        {path: '/backyard/users/new', name: 'byUserNew', component: byUserNew},
        {path: '/backyard/users/:id/edit', name: 'byUserEdit', component: byUserEdit},
        {path: '/backyard/sendBacks', name: 'bySendBackIndex', component: bySendBackIndex},

        {path: '/backyard/myPages', name: 'byMyPageIndex', component: byMyPageIndex},
        {path: '/backyard/myPages/:id/edit', name: 'byMyPageEdit', component: byMyPageEdit},
        {path: '/backyard/*', name: 'byNotFound', component: NotFound}
    ]
}
