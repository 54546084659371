<!-- バックヤード > システム設定 > 汎用項目タブ -->
<template>
  <div>
    <v-row>
      <v-col>
        <v-card flat>
          <v-card-text>
            <div class="text-right">
              <v-btn
                class="mb-2"
                :disabled="!canAddCustomerItem"
                small
                @click="addButtonClickEvent"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="customerItems"
                :headers="tableHeaders"
                :items="customerItems"
                :items-per-page="-1"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.required="{ item }">
                  {{ item.required ? '必須' : '' }}
                </template>
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="cyan darken-2"
                    @click="editButtonClickEvent(item)"
                  >
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="info"
                    small
                    @click="editButtonClickEvent(item)"
                  >
                    編集する
                  </v-btn>

                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    color="error"
                    icon
                    @click="deleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>

                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="deleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showCustomerItemDialog"
      width="450"
      persistent
      no-click-animation
    >
      <v-form>
        <alert-message />
        <validation-observer v-slot="{ invalid }">
          <v-card>
            <v-card-title>会員汎用項目設定</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:30"
                    name="タイトル"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="customer_item.title"
                      label="タイトル"
                      dense
                      hide-details="auto"
                      outlined
                      clearable
                      placeholder=" "
                      required
                      counter="30"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch
                    v-model="customer_item.required"
                    label="必須"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    class="requiredMark"
                    rules="required|numeric|min_value:0|max_value:2147483647"
                    name="表示順"
                  >
                    <v-text-field
                      v-model="customer_item.position"
                      :error-messages="errors"
                      dense
                      hide-details="auto"
                      label="表示順"
                      outlined
                      placeholder=" "
                      required
                      reverse
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="showCustomerItemDialog = false">
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="dialogRegisterButtonClickEvent"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </v-form>
    </v-dialog>
    <Confirm ref="confirm" />
  </div>
</template>

<script>

import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import { mapActions, mapState, mapGetters } from 'vuex'
export default {
  components: {
    AlertMessage,
    Confirm
  },
  mixins: [
  ],
  data () {
    return {
      tableHeaders: [
        { value: 'id', text: 'No' },
        { value: 'title', text: 'タイトル' },
        { value: 'required', text: '必須', align: 'center' },
        { value: 'position', text: '表示順' },
        { value: 'editable', text: '', align: 'end' },
      ],
      showCustomerItemDialog: false,
      customer_item: null
    }
  },
  computed: {
    ...mapState('backCustomerItem', {
      customerItems: 'customer_items'
    }),
    /**
     * @typedef canAddCustomerItem { Boolean }
     */
    ...mapGetters('backCustomerItem', [
      'canAddCustomerItem'
    ])
  },
  created() {
    this.fetchCustomerItems()
  },
  methods: {
    ...mapActions('backCustomerItem', {
      fetchCustomerItems: 'fetchAll',
      newCustomerItem: 'new',
      editCustomerItem: 'edit',
      createCustomerItem: 'create',
      updateCustomerItem: 'update',
      deleteCustomerItem: 'destroy'
    }),
    addButtonClickEvent () {
      this.newCustomerItem().then( (content) => {
        this.customer_item = content
        this.showCustomerItemDialog = true
      })
    },
    editButtonClickEvent (item) {
      this.editCustomerItem({id: item.id}).then( ( content ) => {
        this.customer_item = content
        this.showCustomerItemDialog = true
      })
    },
    dialogRegisterButtonClickEvent () {
      if (this.customer_item.id) {
        this.updateCustomerItem({id: this.customer_item.id, params: this.customer_item}).then(() => {
          this.showCustomerItemDialog = false
        })
      } else {
        this.createCustomerItem({params: this.customer_item}).then(() => {
          this.showCustomerItemDialog = false
        })
      }
    },
    deleteButtonClickEvent (item) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.deleteCustomerItem({id: item.id})
        }
      })
    }
  },
}
</script>

<style lang="scss">

</style>
