<!-- バックヤード > 商品詳細 > 在庫管理 -->
<template>
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="addButtonClickEvent"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-card-text>
                <v-data-table
                  v-if="productStocks"
                  :headers="tableHeaders"
                  :items="productStocks"
                  :items-per-page="-1"
                  disable-sort
                  hide-default-footer
                >
                  <template v-slot:item.rental_date="{ item }">
                    {{ item.rental_from_date | from_to_date_format(item.rental_to_date) }}
                  </template>
                  <template v-slot:item.note="{ item }">
                    <span
                      :title="item.note"
                      class="wrap_text"
                      style="width: 100px;"
                    >
                      {{ item.note }}
                    </span>
                  </template>
                  <template v-slot:item.control_number="{ item }">
                    <span
                      :title="item.control_number"
                      class="wrap_text"
                      style="width: 100px;"
                    >
                      {{ item.control_number }}
                    </span>
                  </template>
                  <template v-slot:item.stock_location="{ item }">
                    <span
                      :title="item.stock_location"
                      class="wrap_text"
                      style="width: 100px;"
                    >
                      {{ item.stock_location }}
                    </span>
                  </template>
                  <template v-slot:item.order_date  ="{ item }">
                    <span v-if="item.order">
                      {{ item.order.order_date }}
                    </span>
                  </template>
                  <template v-slot:item.order_no="{ item }">
                    <a
                      v-if="item.order"
                      class="text-decoration-underline red--text"
                      @click="showOrderDetailButtonClickEvent(item.order)"
                    >
                      {{ item.order.order_no }}
                    </a>
                  </template>
                  <template v-slot:item.order_name="{ item }">
                    <span v-if="item.order">
                      {{ item.order.name }}
                    </span>
                  </template>
                  <template v-slot:item.editable="{ item }">
                    <v-btn
                      v-if="$vuetify.breakpoint.mdAndUp && !item.order"
                      color="cyan darken-2"
                      icon
                      @click="editButtonClickEvent(item)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                    <v-btn
                        v-else-if="!item.order"
                        color="info"
                        small
                        @click="editButtonClickEvent(item)"
                    >
                      編集する
                    </v-btn>
                    <v-btn
                      v-if="$vuetify.breakpoint.mdAndUp && !item.order"
                      color="error"
                      icon
                      @click="deleteButtonClickEvent(item)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                    <v-btn
                        v-else-if="!item.order"
                      color="error"
                      small
                      @click="deleteButtonClickEvent(item)"
                    >
                      削除する
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <stock-regist-dialog
      ref="StockRegistDialog"
      @StockDialogCallback="stockDialogCallback"
    />
    <confirm ref="confirm" />
  </div>
</template>

<script>

import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import StockRegistDialog from '../../../dialogs/StockRegistDialog'
import { mapActions, mapState } from 'vuex'
export default {
  components: {
    AlertMessage,
    Confirm,
    StockRegistDialog,
  },
  mixins: [
  ],
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      tableHeaders: [
        { value: 'no', text: '在庫管理番号', align: 'end' },
        { value: 'status_name', text: '状態' },
        { value: 'rental_date', text: '貸出期間' },
        { value: 'note', text: '備考' },
        { value: 'control_number', text: '管理番号' },
        { value: 'stock_location', text: '在庫場所' },
        { value: 'order_date', text: '受注日' },
        { value: 'order_no', text: '注文番号' },
        { value: 'order_name', text: '会員名' },
        { value: 'editable', text: '', align: 'end' },
      ],
      rental_from_date_menu: false,
      rental_to_date_menu: false,
      product_stock: {},
    }
  },
  computed: {
    ...mapState('backProductStock', {
      productStocks: 'product_stocks'
    })
  },
  created() {
    this.searchProductStocks()
  },
  methods: {
    ...mapActions('backProductStock', {

      fetchProductStocks: 'fetchAll',
      newProductStock: 'new',
      editProductStock: 'edit',
      destroyProductStock: 'destroy'
    }),
    searchProductStocks () {
      this.fetchProductStocks( { product_id: this.product.id })
    },
    openStockRegistDialog () {
      this.$refs.StockRegistDialog.openDialog({product_id: this.product.id, product_stock: this.product_stock, product_use_calendar: this.product.use_calendar})
    },
    stockDialogCallback () {
      this.searchProductStocks()
    },
    addButtonClickEvent () {
      this.newProductStock({ product_id: this.product.id }).then((content) => {
        this.product_stock = content
        this.openStockRegistDialog()
      })
    },
    editButtonClickEvent (product_stock) {
      this.editProductStock({ product_id: this.product.id, id: product_stock.id }).then((content) => {
        this.product_stock = content
        this.openStockRegistDialog()
      })
    },
    showOrderDetailButtonClickEvent (order) {
      this.$router.push({name: 'byOrderShow', params: {id: order.id}})
    },
    deleteButtonClickEvent (product_stock) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyProductStock({product_id: this.product.id, params: product_stock}).then(() => {
            this.searchProductStocks()
          })
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.wrap_text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
th, td {
  padding: 3px !important;
}
</style>
