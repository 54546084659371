/**
 * 会社信用情報
 */
import Vue from "vue";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/credence`

const credence = {
    namespaced: true,
    state: {},
    actions: {
        edit ({ _commit }) {
            const api_path = `${API_PATH}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
        update ({ commit }, { params }) {
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(`${API_PATH}`, params ).then( data => {
                    let content = data.content
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    resolve(content)
                } )
            })
        },
    },
    mutations: {},
}
export default credence

