var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("カタログ")]),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"small":""},on:{"click":_vm.showNewCatalogModal}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.productCatalogs)?_c('v-data-table',{attrs:{"headers":_vm.catalogTableHeaders,"items":_vm.productCatalogs,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"color":"cyan darken-2","icon":""},on:{"click":function($event){return _vm.showEditCatalogModal(item)}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1):_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.showEditCatalogModal(item)}}},[_vm._v("\n                  編集する\n                ")]),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.catalogDeleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.catalogDeleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}],null,false,2973224502)}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("動画URL")]),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"small":""},on:{"click":_vm.showNewVideoModal}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.productVideos)?_c('v-data-table',{attrs:{"headers":_vm.videoTableHeaders,"items":_vm.productVideos,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"color":"cyan darken-2","icon":""},on:{"click":function($event){return _vm.showEditVideoModal(item)}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1):_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.showEditVideoModal(item)}}},[_vm._v("\n                  編集する\n                ")]),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.videoDeleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.videoDeleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}],null,false,1992268342)}):_vm._e()],1)],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("フリー項目")]),_vm._v(" "),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"small":""},on:{"click":_vm.showNewItemModal}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.productItems)?_c('v-data-table',{attrs:{"headers":_vm.itemTableHeaders,"items":_vm.productItems,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"wrap_text",staticStyle:{"width":"180px"},attrs:{"title":item.value}},[_vm._v("\n                  "+_vm._s(item.value)+"\n                ")])]}},{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"color":"cyan darken-2","icon":""},on:{"click":function($event){return _vm.showEditItemModal(item)}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1):_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.showEditItemModal(item)}}},[_vm._v("\n                  編集する\n                ")]),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.itemDeleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.itemDeleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}],null,false,3041519731)}):_vm._e()],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"450"},on:{"close":_vm.closeCatalogDialog},model:{value:(_vm.isShowCatalogDialog),callback:function ($$v) {_vm.isShowCatalogDialog=$$v},expression:"isShowCatalogDialog"}},[_c('v-form',[_c('validation-observer',{ref:"catalogFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_vm._v("カタログ")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"PDFファイル","rules":"mimes:application/pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{staticClass:"text-center",on:{"dragover":function($event){$event.preventDefault();},"dragenter":_vm.onDragEnter,"dragleave":_vm.onDragLeave,"drop":_vm.onDrop}},[_c('v-col',[_c('v-file-input',{attrs:{"background-color":_vm.isDragging ? 'blue' : 'null',"error-messages":errors,"show-size":1000,"accept":"application/pdf","append-icon":"mdi-folder","counter":"","dense":"","label":"PDFファイルを選択して下さい","outlined":"","placeholder":" ","prepend-icon":"","required":""},on:{"change":_vm.catalogFileChangeEvent},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v("\n                            "+_vm._s(text)+"\n                          ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v("+"+_vm._s(_vm.files.length - 2)+" File(s)")]):_vm._e()]}}],null,true),model:{value:(_vm.productCatalog.file),callback:function ($$v) {_vm.$set(_vm.productCatalog, "file", $$v)},expression:"productCatalog.file"}})],1)],1)]}}],null,true)})],1)],1),_vm._v(" "),(_vm.productCatalog.id != null)?_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"表示順","rules":"required|numeric|min_value:0|max_value:2147483647"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"表示順","outlined":"","placeholder":" ","required":"","reverse":""},model:{value:(_vm.productCatalog.position),callback:function ($$v) {_vm.$set(_vm.productCatalog, "position", $$v)},expression:"productCatalog.position"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"ファイル名","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"30","dense":"","hide-details":"auto","label":"ファイル名","outlined":"","placeholder":" ","required":""},model:{value:(_vm.productCatalog.name),callback:function ($$v) {_vm.$set(_vm.productCatalog, "name", $$v)},expression:"productCatalog.name"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.closeCatalogDialog}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.catalogDialogRegisterButtonClickEvent}},[_vm._v("\n              OK\n            ")])],1)],1)]}}])})],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.isShowVideoDialog),callback:function ($$v) {_vm.isShowVideoDialog=$$v},expression:"isShowVideoDialog"}},[_c('v-form',[_c('validation-observer',{ref:"videoFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_vm._v("動画URL登録")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"タイトル","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"30","dense":"","hide-details":"auto","label":"タイトル","outlined":"","placeholder":" ","required":""},model:{value:(_vm.productVideo.title),callback:function ($$v) {_vm.$set(_vm.productVideo, "title", $$v)},expression:"productVideo.title"}})]}}],null,true)})],1)],1),_vm._v(" "),(_vm.productVideo.id != null)?_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"表示順","rules":"required|numeric|min_value:0|max_value:2147483647"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"表示順","outlined":"","placeholder":" ","required":"","reverse":""},model:{value:(_vm.productVideo.position),callback:function ($$v) {_vm.$set(_vm.productVideo, "position", $$v)},expression:"productVideo.position"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"動画URL","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"255","dense":"","hide-details":"auto","label":"動画URL","outlined":"","placeholder":" ","required":""},model:{value:(_vm.productVideo.url),callback:function ($$v) {_vm.$set(_vm.productVideo, "url", $$v)},expression:"productVideo.url"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.closeVideoDialog}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.videoDialogRegisterButtonClickEvent}},[_vm._v("\n              保存\n            ")])],1)],1)]}}])})],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.isShowItemDialog),callback:function ($$v) {_vm.isShowItemDialog=$$v},expression:"isShowItemDialog"}},[_c('v-form',[_c('validation-observer',{ref:"freeFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_vm._v("フリー項目設定")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"項目名","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"30","dense":"","hide-details":"auto","label":"項目名","outlined":"","placeholder":" ","required":""},model:{value:(_vm.productItem.title),callback:function ($$v) {_vm.$set(_vm.productItem, "title", $$v)},expression:"productItem.title"}})]}}],null,true)})],1)],1),_vm._v(" "),(_vm.productItem.id != null)?_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"表示順","rules":"required|numeric|min_value:0|max_value:2147483647"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"表示順","outlined":"","placeholder":" ","required":"","reverse":""},model:{value:(_vm.productItem.position),callback:function ($$v) {_vm.$set(_vm.productItem, "position", $$v)},expression:"productItem.position"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"内容","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{staticClass:"custom-quill-container",attrs:{"error-messages":errors},model:{value:(_vm.productItem.value),callback:function ($$v) {_vm.$set(_vm.productItem, "value", $$v)},expression:"productItem.value"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.closeItemDialog}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.itemDialogRegisterButtonClickEvent}},[_vm._v("\n              保存\n            ")])],1)],1)]}}])})],1)],1),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }