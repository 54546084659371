<!-- バックヤード > パスワードを忘れた方 -->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          xs="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="3"
        >
          <v-form>
            <validation-observer
              v-slot="{ invalid }"
            >
              <v-card>
                <v-card-title>パスワードの変更</v-card-title>
                <v-card-text>
                  <p
                    class="text-center"
                  >
                    パスワードの再設定を行います。他人に推測されやすいものは避けてください。
                  </p>
                  <v-row>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="password"
                        rules="required|max:72"
                        name="パスワード"
                        class="requiredMark"
                      >
                        <v-text-field
                          v-model="password"
                          label="パスワード"
                          type="password"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                          placeholder=" "
                          counter="72"
                          required
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        vid="confirmation"
                        rules="required|max:72|confirmed:password"
                        name="パスワード(確認用)"
                        class="requiredMark"
                        mode="lazy"
                      >
                        <v-text-field
                          v-model="password_confirmation"
                          label="パスワード(確認用)"
                          type="password"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                          placeholder=" "
                          counter="72"
                          required
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />

                  <v-btn @click="cancelButtonClickEvent">
                    キャンセル
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="invalid"
                    @click="registerButtonClickEvent"
                  >
                    変更
                  </v-btn>
                </v-card-actions>
              </v-card>
            </validation-observer>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

  import { mapActions } from 'vuex'

  export default {
    name: 'ForgetEdit',
    components: {},
    mixins: [],
    data () {
      return {
        password: null,
        password_confirmation: null,
        token: null
      }
    },
    created () {
      this.signOut()
      this.token = this.$route.params.token
    },
    methods: {
      ...mapActions('backyardAuth', {
        signOut: 'signOutBackyard',
      }),
      ...mapActions('backForgetPassword', {
        resetPassword: 'update'
      }),
      cancelButtonClickEvent () {
        this.$router.push({name: 'bySessionIndex'})
      },
      registerButtonClickEvent () {
        let params = {
          password: this.password,
          password_confirmation: this.password_confirmation
        }
        this.resetPassword( { token: this.token, params: params }).then(() => {
          this.$router.push({name: 'byForgetCompleted'})
        })
      }
    },
  }
</script>

<style lang="scss">
</style>
