<!-- バックヤード > 会社情報／特定商取引設定 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          会社情報／特定商取引設定
        </h2>
        <v-tabs
          v-model="credenceForm"
          centered
          fixed-tabs
          icons-and-text
          background-color="blue lighten-4"
        >
          <v-tabs-slider />
          <v-tab href="#tab-credence">
            会社情報
            <v-icon>mdi-bookmark-check-outline</v-icon>
          </v-tab>

          <v-tab href="#tab-commercialLaw">
            特定商取引
            <v-icon>mdi-book-open-page-variant</v-icon>
          </v-tab>

          <v-tabs-items v-model="credenceForm">
            <v-tab-item value="tab-credence">
              <credence-form
                v-if="credence"
                :credence="credence"
              />
            </v-tab-item>

            <v-tab-item value="tab-commercialLaw">
              <commercial-law-form />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import CredenceForm from './forms/_Credence'
  import CommercialLawForm from './forms/_CommercialLaw'

  export default {
    name: 'CreditEdit',
    components: {
      CredenceForm,
      CommercialLawForm,
    },
    mixins: [
    ],
    data () {
      return {
        credence: null,
        credenceForm: 'tab-credence',
      }
    },
    created() {
      this.editCredence().then((content) => {
        this.credence = content
      })
    },
    methods: {
      ...mapActions('backCredence', {
        editCredence: 'edit'
      })
    },
  }
</script>

<style lang="scss">

</style>
