<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-row>
      <v-col>
        <basic-form
          v-if="option_group"
          :is-edit="false"
          :option-group="option_group"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>

import BasicForm from './forms/_Basic'
import { mapActions } from 'vuex'

export default {
  name: 'OptionGroupNew',
  components: {
    BasicForm,
  },
  mixins: [
  ],
  data () {
    return {
      option_group: null,
      breadcrumbs: [
        { text: 'オプショングループ一覧', disabled: false, to: '/backyard/option_groups', exact: true },
        { text: 'オプショングループ作成', disabled: true, to: '' },
      ],
    }
  },
  created() {
    this.newOptionGroup().then( (content) => {
      this.option_group = content
    } )
  },
  methods: {
    ...mapActions('backOptionGroup', {
      newOptionGroup: 'new'
    })
  }

}
</script>

<style scoped>

</style>