/**
 * 会員情報
 */
import Vue from "vue";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/customer`

const customer = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    new ({ _commit }) {
      const api_path = `${API_PATH}/new`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    edit ({ _commit }) {
      const api_path = `${API_PATH}/edit`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    create ({ commit }, { params }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.post(API_PATH, params).then(data => {
          commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
    update ({ commit }, { params }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.put(API_PATH, params).then(data => {
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          resolve(data.content)
        })
      })
    },
    activate ({ commit }, { token }) {
      const api_path = `${API_PATH}/activate`
      const parameter = { activate_token: token }
      return new Promise((resolve, _reject) => {
        Vue.$http.put(api_path, parameter).then(data => {
          resolve(data.content)
        })
      })
    },
  },
  mutations: {},
}

export default customer
