<!-- バックヤード > ユーザー編集 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-row>
      <v-col>
        <user-form
          v-if="user"
          :user="user"
          :is-edit="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import UserForm from './_Form'
export default {
  components: {
    UserForm,
  },
  mixins: [
  ],
  data () {
    return {
      user: null,
      breadcrumbs: [
        { text: 'ユーザー一覧', disabled: false, to: '/backyard/users', exact: true },
        { text: 'ユーザー詳細', disabled: true, to: '' },
      ],
    }
  },
  created() {
    this.editUser({ id: this.$route.params.id }).then(( content ) => {
      this.user = content
    })
  },
  methods: {
    ...mapActions('backUser', {
      editUser: 'edit'
    })
  },
}

</script>

<style lang="scss">

</style>
