<!-- バックヤード > 在庫管理登録 -->
<template>
  <v-dialog
    v-model="isShowProductStockDialog"
    width="500"
    persistent
  >
    <validation-observer
      v-slot="{ invalid }"
      ref="stockFormValidation"
    >
      <v-form>
        <v-card>
          <v-card-title>商品在庫登録</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  class="requiredMark"
                  name="状態"
                  rules="required"
                >
                  <v-select
                    v-model="product_stock.status"
                    :error-messages="errors"
                    :items="product_stock.statuses"
                    :disabled="product_use_calendar"
                    dense
                    hide-details="auto"
                    label="状態"
                    outlined
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="備考"
                  rules="max:255"
                >
                  <v-textarea
                    v-model="product_stock.note"
                    :error-messages="errors"
                    auto-grow
                    counter="255"
                    dense
                    hide-details="auto"
                    label="備考"
                    outlined
                    placeholder=" "
                    row-height="15"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="管理番号"
                  rules="max:70"
                >
                  <v-text-field
                    v-model="product_stock.control_number"
                    :error-messages="errors"
                    auto-grow
                    counter="70"
                    dense
                    hide-details="auto"
                    label="管理番号"
                    outlined
                    placeholder=" "
                    row-height="15"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="在庫場所"
                  rules="max:70"
                >
                  <v-text-field
                    v-model="product_stock.stock_location"
                    :error-messages="errors"
                    auto-grow
                    counter="70"
                    dense
                    hide-details="auto"
                    label="在庫場所"
                    outlined
                    placeholder=" "
                    row-height="15"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div>貸出期間</div>
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-menu
                          v-model="rental_from_date_menu"
                          :close-on-content-click="false"
                          min-width="290px"
                          offset-y
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <validation-provider
                              v-slot="{ errors }"
                              :class="product_stock.status === 'check_out' ? 'requiredMark':''"
                              :rules="product_stock.status === 'check_out' ? 'required':''"
                              name="開始日"
                            >
                              <v-text-field
                                v-model="product_stock.rental_from_date"
                                :disabled="product_stock.status !== 'check_out'"
                                :error-messages="errors"
                                clearable
                                dense
                                hide-details="auto"
                                label="開始日"
                                outlined
                                placeholder=" "
                                readonly
                                v-on="on"
                                @click:clear="product_stock.rental_from_date = null"
                              >
                                <template
                                  v-if="!$vuetify.breakpoint.smAndDown"
                                  v-slot:append-outer
                                >
                                  &emsp;〜
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </template>
                          <v-date-picker
                            v-model="product_stock.rental_from_date"
                            @input="rental_from_date_menu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="5"
                      >
                        <v-menu
                          v-model="rental_to_date_menu"
                          :close-on-content-click="false"
                          min-width="290px"
                          offset-y2
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <validation-provider
                              v-slot="{ errors }"
                              :class="product_stock.status === 'check_out' ? 'requiredMark':''"
                              :rules="product_stock.status === 'check_out' ? 'required':''"
                              name="終了日"
                            >
                              <v-text-field
                                v-model="product_stock.rental_to_date"
                                :disabled="product_stock.status !== 'check_out'"
                                :error-messages="errors"
                                clearable
                                dense
                                hide-details="auto"
                                label="終了日"
                                outlined
                                placeholder=" "
                                readonly
                                v-on="on"
                                @click:clear="product_stock.rental_to_date = null"
                              />
                            </validation-provider>
                          </template>
                          <v-date-picker
                            v-model="product_stock.rental_to_date"
                            @input="rental_to_date_menu = false"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row><!-- /貸出期間 -->
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="isShowProductStockDialog = false">
              キャンセル
            </v-btn>
            <v-btn
              color="primary"
              :disabled="invalid"
              @click="dialogRegisterButtonClickEvent"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  components: {},
  mixins: [],
  data () {
    return {
      rental_from_date_menu: false,
      rental_to_date_menu: false,
      isShowProductStockDialog: false,
      product_stock: {},
      product_id: null,
      product_use_calendar: null,
    }
  },
  computed: {
    ...mapState('backProductStock', {
      productStocks: 'product_stocks',
    })
  },
  methods: {
    ...mapActions('backProductStock', {
      createProductStock: 'create',
      updateProductStock: 'update',
    }),

    openDialog (params) {
      this.product_stock = params['product_stock'] ? params['product_stock'] : false
      this.product_id = params['product_id'] ? params['product_id'] : false
      this.product_use_calendar = params['product_use_calendar'] ? params['product_use_calendar'] : false
      this.isShowProductStockDialog = true
    },

    dialogRegisterButtonClickEvent () {
      if (this.product_stock.id) {
        this.updateProductStock({ product_id: this.product_id, params: this.product_stock }).then(() => {
          this.isShowProductStockDialog = false
          this.$emit('StockDialogCallback')
        })
      } else {
        this.createProductStock( { product_id: this.product_id, params: this.product_stock }).then(() => {
          this.isShowProductStockDialog = false
        })
      }
    }

  },
}
</script>

<style scoped>
</style>