/**
 * loading
 */

const loading = {
  namespaced: true,
  state: {
    isLoading: false,
  },
  getters: {},
  actions: {
  },
  mutations: {
    started (state) {
      state.isLoading = true
    },
    finished (state) {
      state.isLoading = false
    },
  },
}

export default loading
