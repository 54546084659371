var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("お知らせ")]),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"text-right mb-3"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addCustomerNotice}},[_vm._v("\n          お知らせ追加\n        ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.noticeTableHeaders,"items":_vm.notices,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm._f("truncate")(item.description,30))+"\n            ")]}},{key:"item.notice_date",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm._f("date_format")(item.notice_date))+"\n            ")]}},{key:"item.display_date",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("from_to_date_format")(item.display_start_date,item.display_end_date)))])]}},{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"cyan darken-2"},on:{"click":function($event){return _vm.editCustomerNotice(item)}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1):_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.editCustomerNotice(item)}}},[_vm._v("\n                編集する\n              ")])]}}])})],1)],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.isCustomerNotice)?_c('v-pagination',{attrs:{"length":_vm.pagination.total_pages,"total-visible":_vm.totalVisible,"prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right","circle":""},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"scrollable":"","max-width":"600px"},model:{value:(_vm.noticeDialog),callback:function ($$v) {_vm.noticeDialog=$$v},expression:"noticeDialog"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("\n            お知らせ登録\n          ")]),_vm._v(" "),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"タイトル","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"50","dense":"","label":"タイトル","outlined":"","placeholder":"お知らせタイトル","required":""},model:{value:(_vm.customerNotice.title),callback:function ($$v) {_vm.$set(_vm.customerNotice, "title", $$v)},expression:"customerNotice.title"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"詳細","rules":"required|max:1024"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"counter":"1024","label":"詳細","outlined":"","placeholder":"詳細を入力してください。","required":"","value":""},model:{value:(_vm.customerNotice.description),callback:function ($$v) {_vm.$set(_vm.customerNotice, "description", $$v)},expression:"customerNotice.description"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"お知らせ日","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"error-messages":errors,"clearable":"","dense":"","hide-details":"auto","label":"お知らせ日","outlined":"","placeholder":" ","readonly":""},model:{value:(_vm.customerNotice.notice_date),callback:function ($$v) {_vm.$set(_vm.customerNotice, "notice_date", $$v)},expression:"customerNotice.notice_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.noticeDateMenu),callback:function ($$v) {_vm.noticeDateMenu=$$v},expression:"noticeDateMenu"}},[_vm._v(" "),_c('v-date-picker',{on:{"input":function($event){_vm.noticeDateMenu = false}},model:{value:(_vm.customerNotice.notice_date),callback:function ($$v) {_vm.$set(_vm.customerNotice, "notice_date", $$v)},expression:"customerNotice.notice_date"}})],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"290px","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('validation-provider',{attrs:{"name":"開始日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"error-messages":errors,"clearable":"","dense":"","hide-details":"auto","label":"開始日","outlined":"","placeholder":" ","readonly":""},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.smAndDown)?{key:"append-outer",fn:function(){return [_vm._v("\n                             〜\n                          ")]},proxy:true}:null],null,true),model:{value:(_vm.customerNotice.display_start_date),callback:function ($$v) {_vm.$set(_vm.customerNotice, "display_start_date", $$v)},expression:"customerNotice.display_start_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.displayStartDateMenu),callback:function ($$v) {_vm.displayStartDateMenu=$$v},expression:"displayStartDateMenu"}},[_vm._v(" "),_c('v-date-picker',{on:{"input":function($event){_vm.displayStartDateMenu = false}},model:{value:(_vm.customerNotice.display_start_date),callback:function ($$v) {_vm.$set(_vm.customerNotice, "display_start_date", $$v)},expression:"customerNotice.display_start_date"}})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"290px","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('validation-provider',{attrs:{"name":"終了日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"error-messages":errors,"clearable":"","dense":"","hide-details":"auto","label":"終了日","outlined":"","placeholder":" ","readonly":""},model:{value:(_vm.customerNotice.display_end_date),callback:function ($$v) {_vm.$set(_vm.customerNotice, "display_end_date", $$v)},expression:"customerNotice.display_end_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.displayEndDateMenu),callback:function ($$v) {_vm.displayEndDateMenu=$$v},expression:"displayEndDateMenu"}},[_vm._v(" "),_c('v-date-picker',{on:{"input":function($event){_vm.displayEndDateMenu = false}},model:{value:(_vm.customerNotice.display_end_date),callback:function ($$v) {_vm.$set(_vm.customerNotice, "display_end_date", $$v)},expression:"customerNotice.display_end_date"}})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"hide-details":"auto","label":"メール送信する"},model:{value:(_vm.customerNotice.use_mail),callback:function ($$v) {_vm.$set(_vm.customerNotice, "use_mail", $$v)},expression:"customerNotice.use_mail"}})],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[(_vm.isEdit)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.deleteCustomerNotice}},[_vm._v("\n              削除\n            ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){_vm.noticeDialog = false}}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.submitCustomerNotice}},[_vm._v("\n              保存\n            ")])],1)],1)],1)]}}])})],1),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }