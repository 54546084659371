var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('alert-message'),_vm._v(" "),(_vm.company)?_c('v-form',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:30","name":"コード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"コード","placeholder":"半角英数字","counter":"30","autocomplete":"none","required":"","error-messages":errors},model:{value:(_vm.company.code),callback:function ($$v) {_vm.$set(_vm.company, "code", $$v)},expression:"company.code"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"モード"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v("モード")]),_vm._v(" "),_c('v-radio-group',{staticClass:"pa-0 ma-0",attrs:{"row":"","dense":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.company.mode),callback:function ($$v) {_vm.$set(_vm.company, "mode", $$v)},expression:"company.mode"}},[_vm._l((_vm.company.mode_s),function(item,index){return [_c('v-radio',{key:item.value,attrs:{"value":item.value,"label":item.text,"disabled":index==1}})]})],2)]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"契約種別"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v("契約種別")]),_vm._v(" "),_c('v-radio-group',{staticClass:"pa-0 ma-0",attrs:{"row":"","dense":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.company.contract_type),callback:function ($$v) {_vm.$set(_vm.company, "contract_type", $$v)},expression:"company.contract_type"}},[_vm._l((_vm.company.contract_types),function(item,index){return [_c('v-radio',{key:item.value,attrs:{"value":item.value,"label":item.text}})]})],2)]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:255","name":"会社名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"name","label":"会社名","placeholder":"","required":"","counter":"255","autocomplete":"none","error-messages":errors},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"max:255","name":"会社名カナ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"kana_name","label":"会社名カナ","placeholder":"","counter":"255","autocomplete":"none","error-messages":errors},model:{value:(_vm.company.kana_name),callback:function ($$v) {_vm.$set(_vm.company, "kana_name", $$v)},expression:"company.kana_name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:25","name":"担当者"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"担当者","placeholder":"","required":"","counter":"25","error-messages":errors},model:{value:(_vm.company.contractor),callback:function ($$v) {_vm.$set(_vm.company, "contractor", $$v)},expression:"company.contractor"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"契約開始日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","readonly":"","persistent-hint":"","label":"契約開始日","required":"","error-messages":errors},model:{value:(_vm.company.contract_start_date),callback:function ($$v) {_vm.$set(_vm.company, "contract_start_date", $$v)},expression:"company.contract_start_date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.contract_start_date_menu),callback:function ($$v) {_vm.contract_start_date_menu=$$v},expression:"contract_start_date_menu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.contract_start_date_menu = false}},model:{value:(_vm.company.contract_start_date),callback:function ($$v) {_vm.$set(_vm.company, "contract_start_date", $$v)},expression:"company.contract_start_date"}})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"rules":"","name":"契約終了日"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"契約終了日","clearable":"","persistent-hint":"","readonly":"","error-messages":errors},model:{value:(_vm.company.contract_end_date),callback:function ($$v) {_vm.$set(_vm.company, "contract_end_date", $$v)},expression:"company.contract_end_date"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.contract_end_date_menu),callback:function ($$v) {_vm.contract_end_date_menu=$$v},expression:"contract_end_date_menu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.contract_end_date_menu = false}},model:{value:(_vm.company.contract_end_date),callback:function ($$v) {_vm.$set(_vm.company, "contract_end_date", $$v)},expression:"company.contract_end_date"}})],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":{required: true, regex:/^[0-9]{3}[-][0-9]{4}$/},"name":"郵便番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"郵便番号","placeholder":"111-2222","required":"","hint":"半角数字とハイフン(-)で入力して下さい","counter":"8","error-messages":errors,"autocomplete":"none"},model:{value:(_vm.company.zip_code),callback:function ($$v) {_vm.$set(_vm.company, "zip_code", $$v)},expression:"company.zip_code"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"都道府県"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.company.prefectures,"item-text":"name","item-value":"code","label":"都道府県","required":"","error-messages":errors},model:{value:(_vm.company.prefecture_code),callback:function ($$v) {_vm.$set(_vm.company, "prefecture_code", $$v)},expression:"company.prefecture_code"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"9"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:100","name":"住所"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"住所","required":"","counter":"100","autocomplete":"none","error-messages":errors},model:{value:(_vm.company.address),callback:function ($$v) {_vm.$set(_vm.company, "address", $$v)},expression:"company.address"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":{ regex: /^[0-9\-]+$/, max:13, required: true},"name":"電話番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"電話番号","placeholder":"半角数字","hint":"ハイフン(-)有りで入力してください","autocomplete":"none","required":"","error-messages":errors},model:{value:(_vm.company.phone),callback:function ($$v) {_vm.$set(_vm.company, "phone", $$v)},expression:"company.phone"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":{ regex: /^[0-9\-]+$/, max:13},"name":"FAX番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"FAX番号","placeholder":"半角数字","hint":"ハイフン(-)有りで入力してください","autocomplete":"none","error-messages":errors},model:{value:(_vm.company.fax),callback:function ($$v) {_vm.$set(_vm.company, "fax", $$v)},expression:"company.fax"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|email|max:255","name":"メールアドレス"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"メールアドレス","counter":"255","autocomplete":"none","required":"","error-messages":errors},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"URL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"URL","autocomplete":"none","error-messages":errors},model:{value:(_vm.company.url),callback:function ($$v) {_vm.$set(_vm.company, "url", $$v)},expression:"company.url"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"フロントエンド認証"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"フロントエンド認証","hide-details":"auto","error-messages":errors},model:{value:(_vm.company.use_frontend_auth),callback:function ($$v) {_vm.$set(_vm.company, "use_frontend_auth", $$v)},expression:"company.use_frontend_auth"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"会員登録方法"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-label',[_vm._v("会員登録方法")]),_vm._v(" "),_c('v-radio-group',{staticClass:"pa-0 ma-0",attrs:{"row":"","dense":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.company.customer_reg_type),callback:function ($$v) {_vm.$set(_vm.company, "customer_reg_type", $$v)},expression:"company.customer_reg_type"}},[_vm._l((_vm.company.customer_reg_types),function(item,index){return [_c('v-radio',{key:item.value,attrs:{"value":item.value,"label":item.text}})]})],2)]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"定期課金使用"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"定期課金使用","hide-details":"auto","error-messages":errors},model:{value:(_vm.company.use_subscription),callback:function ($$v) {_vm.$set(_vm.company, "use_subscription", $$v)},expression:"company.use_subscription"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"販売使用"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"販売使用","hide-details":"auto","error-messages":errors},model:{value:(_vm.company.use_sale),callback:function ($$v) {_vm.$set(_vm.company, "use_sale", $$v)},expression:"company.use_sale"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-label',[_vm._v("決済方法")]),_vm._v(" "),_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"クレジットカード使用有無","hide-details":"auto","error-messages":_vm.errors},model:{value:(_vm.company.use_credit_card),callback:function ($$v) {_vm.$set(_vm.company, "use_credit_card", $$v)},expression:"company.use_credit_card"}}),_vm._v(" "),_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"銀行振込使用有無","hide-details":"auto","error-messages":_vm.errors},model:{value:(_vm.company.use_bank_transfer),callback:function ($$v) {_vm.$set(_vm.company, "use_bank_transfer", $$v)},expression:"company.use_bank_transfer"}}),_vm._v(" "),_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"代引き使用有無","hide-details":"auto","error-messages":_vm.errors},model:{value:(_vm.company.use_cash_on_delivery),callback:function ($$v) {_vm.$set(_vm.company, "use_cash_on_delivery", $$v)},expression:"company.use_cash_on_delivery"}})],1)],1),_vm._v(" "),_c('h2',[_vm._v("振込先")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"銀行"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.banks,"item-text":"text","item-value":"id","label":"銀行","hint":"選択してください","error-messages":errors},model:{value:(_vm.company.bank_id),callback:function ($$v) {_vm.$set(_vm.company, "bank_id", $$v)},expression:"company.bank_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"支店"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.branches,"item-text":"text","item-value":"id","label":"支店","hint":"選択してください","error-messages":errors},model:{value:(_vm.company.branch_id),callback:function ($$v) {_vm.$set(_vm.company, "branch_id", $$v)},expression:"company.branch_id"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"","name":"預金種別"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"pa-0 ma-0",attrs:{"label":"預金種別","row":"","dense":"","hide-details":"auto","error-messages":errors},model:{value:(_vm.company.bank_account_type),callback:function ($$v) {_vm.$set(_vm.company, "bank_account_type", $$v)},expression:"company.bank_account_type"}},[_vm._l((_vm.company.bank_account_types),function(item,index){return [_c('v-radio',{key:item.value,attrs:{"value":item.value,"label":item.text}})]})],2)]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"口座番号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"口座番号","autocomplete":"none","error-messages":errors},model:{value:(_vm.company.bank_account_number),callback:function ($$v) {_vm.$set(_vm.company, "bank_account_number", $$v)},expression:"company.bank_account_number"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required","name":"口座名義"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"口座名義","autocomplete":"none","error-messages":errors},model:{value:(_vm.company.bank_account_holder_name),callback:function ($$v) {_vm.$set(_vm.company, "bank_account_holder_name", $$v)},expression:"company.bank_account_holder_name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('h2',[_vm._v("決済代行サービス")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-datetime-picker',{attrs:{"label":"決済開通日時","time-format":"HH:mm"},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var parent = ref.parent;
return [_c('v-btn',{attrs:{"color":"lighten-1"},nativeOn:{"click":function($event){return parent.clearHandler($event)}}},[_vm._v("クリア")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":parent.okHandler}},[_vm._v("反映")])]}}],null,true),model:{value:(_vm.company.settlement_opening_at),callback:function ($$v) {_vm.$set(_vm.company, "settlement_opening_at", $$v)},expression:"company.settlement_opening_at"}},[_vm._v(" "),_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v("mdi-calendar-blank")])],1),_vm._v(" "),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v("mdi-clock-outline")])],1)],2)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|min_value:0|max_value:95","name":"プラットフォーム利用料率"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"プラットフォーム利用料率","placeholder":"","type":"number","step":"0.1","max":"95","autocomplete":"none","error-messages":errors},model:{value:(_vm.company.platform_usage_rate),callback:function ($$v) {_vm.$set(_vm.company, "platform_usage_rate", $$v)},expression:"company.platform_usage_rate"}},[_c('template',{slot:"append"},[_vm._v("\n                ％\n              ")])],2)]}}],null,true)})],1)],1),_vm._v(" "),_c('div',[_c('h2',[_vm._v("\n          店舗 \n          "),(_vm.company.id === null)?_c('small',[_vm._v("初期登録時に作成する店舗情報を入力します")]):_vm._e()]),_vm._v(" "),_c('hr')]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:30","name":"店舗名"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"店舗名","counter":"30","placeholder":"","autocomplete":"none","required":"","error-messages":errors},model:{value:(_vm.company.shop_name),callback:function ($$v) {_vm.$set(_vm.company, "shop_name", $$v)},expression:"company.shop_name"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|min:3|max:22|excluded:www,ftp,mail,pop,smtp,admin,ssl,sftp","name":"サブドメイン"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"store_url","label":"サブドメイン","required":"","counter":"22","placeholder":"3から22文字","error-messages":errors},model:{value:(_vm.company.sub_domain),callback:function ($$v) {_vm.$set(_vm.company, "sub_domain", $$v)},expression:"company.sub_domain"}},[_c('template',{slot:"prepend"},[_vm._v("\n                https://\n              ")]),_vm._v(" "),_c('template',{slot:"append"},[_vm._v("\n                .bizrent.biz\n              ")])],2)]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"独自ドメイン"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"独自ドメイン","placeholder":"https://xxxxxx.com","counter":"","error-messages":errors},model:{value:(_vm.company.original_domain),callback:function ($$v) {_vm.$set(_vm.company, "original_domain", $$v)},expression:"company.original_domain"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('div',{staticClass:"float-right"},[_c('v-btn',{on:{"click":_vm.cancelButtonClickEvent}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.registerButtonClickEvent}},[_vm._v("\n              保存\n            ")])],1)])],1)]}}],null,false,1265919130)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }