var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"small":""},on:{"click":_vm.showNewModal}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.productDocuments)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.productDocuments,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.documentDeleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.documentDeleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}],null,false,2174017688)}):_vm._e()],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"450","persistent":"","no-click-animation":""},on:{"close":_vm.closeDocumentDialog},model:{value:(_vm.isShowDocumentDialog),callback:function ($$v) {_vm.isShowDocumentDialog=$$v},expression:"isShowDocumentDialog"}},[_c('v-form',[_c('validation-observer',{ref:"documentFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_vm._v("同封書類")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"PDFファイル","rules":"required|mimes:application/pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-row',{staticClass:"text-center",on:{"dragover":function($event){$event.preventDefault();},"dragenter":_vm.onDragEnter,"dragleave":_vm.onDragLeave,"drop":_vm.onDrop}},[_c('v-col',[_c('v-file-input',{attrs:{"background-color":_vm.isDragging ? 'blue' : 'null',"error-messages":errors,"show-size":1000,"accept":"application/pdf","append-icon":"mdi-folder","counter":"","dense":"","label":"PDFファイル","outlined":"","persistent-hint":"","placeholder":" ","prepend-icon":"","required":""},on:{"change":_vm.documentFileChangeEvent},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [(index < 2)?_c('v-chip',{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v("\n                            "+_vm._s(text)+"\n                          ")]):(index === 2)?_c('span',{staticClass:"overline grey--text text--darken-3 mx-2"},[_vm._v("+"+_vm._s(_vm.files.length - 2)+" File(s)")]):_vm._e()]}}],null,true),model:{value:(_vm.productDocument.file),callback:function ($$v) {_vm.$set(_vm.productDocument, "file", $$v)},expression:"productDocument.file"}})],1)],1)]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"name":"ファイル名","rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"counter":"30","dense":"","hide-details":"auto","label":"ファイル名","outlined":"","placeholder":" ","required":""},model:{value:(_vm.productDocument.name),callback:function ($$v) {_vm.$set(_vm.productDocument, "name", $$v)},expression:"productDocument.name"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":_vm.closeDocumentDialog}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.documentDialogRegisterButtonClickEvent}},[_vm._v("\n              OK\n            ")])],1)],1)]}}])})],1)],1),_vm._v(" "),_c('confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }