<!-- バックヤード > 会員詳細 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-sheet class="my-3">
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-card flat>
            <v-card-title style="font-size: .875rem;">
              <v-list-item-avatar
                v-if="customer"
                color="primary"
              >
                <span
                  class="white--text"
                >
                  {{ customer.name.slice(0, 2) }}
                </span>
              </v-list-item-avatar>
              <div
                v-if="customer"
                class="headline ml-2"
              >
                {{ customer.name }}
              </div>
            </v-card-title>
          </v-card>

          <v-divider />

          <v-list dense>
            <v-list-item-group
              v-model="activeMenu"
              active-class="info--text"
            >
              <template v-for="(item, index) in menuItems">
                <v-list-item
                  :key="item.text"
                  :value="item.value"
                  :disabled="activeMenu === item.value"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" />
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index < menuItems.length - 1"
                  :key="index"
                />
              </template>
            </v-list-item-group>
          </v-list>
          <v-divider />
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          <basic-form
            v-if="activeMenu === 'basic' && customer"
            :customer="customer"
          />
          <order-index v-if="activeMenu === 'orders'" />
          <inquiry-index v-if="activeMenu === 'inquiries'" />
          <notice-index v-if="activeMenu === 'notices'" />
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import BasicForm from './basic/Show'
import OrderIndex from './orders/Index'
import InquiryIndex from './inquiries/Index'
import NoticeIndex from './notices/Index'
import { mapActions } from 'vuex'
export default {
  components: {
    BasicForm,
    OrderIndex,
    InquiryIndex,
    NoticeIndex,
  },
  data () {
    return {
      breadcrumbs: [
        { text: '会員一覧', disabled: false, to: '/backyard/members', exact: true },
        { text: '会員詳細', disabled: true, to: '' },
      ],
      menuItems: [
        { value: 'basic', text: '登録情報' },
        { value: 'orders', text: '受注履歴' },
        { value: 'inquiries', text: '問い合わせ履歴' },
        { value: 'notices', text: 'お知らせ' },
      ],
      activeMenu: 'basic',
      customer: null,
    }
  },
  created() {
    this.showCustomer({ id: this.$route.params.id }).then((content) => {
      this.customer = content
    })
  },
  methods: {
    ...mapActions('backCustomer', {
      showCustomer: 'show',
    }),
  },
}

</script>

<style lang="scss">
</style>
