<!-- バックヤード > 商品form -->
<template>
  <div>
    <v-row>
      <v-col>
        <v-tabs
          v-model="productForm"
          background-color="blue lighten-4"
          centered
          icons-and-text
          fixed-tabs
        >
          <v-tabs-slider />

          <v-tab href="#tab-basic">
            基本情報
            <v-icon>mdi-diamond-stone</v-icon>
          </v-tab>

          <v-tab href="#tab-description">
            商品説明
            <v-icon>mdi-comment-text-outline</v-icon>
          </v-tab>

          <v-tab href="#tab-images">
            商品画像設定
            <v-icon>mdi-image-multiple</v-icon>
          </v-tab>

          <v-tab href="#tab-search">
            商品検索設定
            <v-icon>mdi-feature-search</v-icon>
          </v-tab>

          <v-tab href="#tab-detail">
            商品詳細
            <v-icon>mdi-layers</v-icon>
          </v-tab>

          <v-tab href="#tab-option">
            オプション
            <template
              v-if="productDetail.component_item"
            >
              /セット商品
            </template>
            <v-icon>mdi-puzzle</v-icon>
          </v-tab>

          <v-tab
            v-if="!productDetail.component_item"
            href="#tab-stocks"
          >
            在庫管理
            <v-icon>mdi-database</v-icon>
          </v-tab>

          <v-tab href="#tab-relations">
            関連商品登録
            <v-icon>mdi-flower-poppy</v-icon>
          </v-tab>

          <v-tab href="#tab-deliveries">
            発送時同封書類
            <v-icon>mdi-clipboard-multiple-outline</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="productForm">
          <v-tab-item value="tab-basic">
            <basic-form
              :is-edit="true"
              :product="product"
              :company-mode="companyMode"
              @updateCallback="updateMainCallback"
            />
          </v-tab-item>

          <v-tab-item value="tab-description">
            <description-form
                :is-edit="true"
                :product="product"
                :company-mode="companyMode"
                @updateCallback="updateMainCallback"
            />
          </v-tab-item>

          <v-tab-item value="tab-images">
            <image-form :product="product" />
          </v-tab-item>

          <v-tab-item value="tab-search">
            <search-form :product="product" />
          </v-tab-item>

          <v-tab-item value="tab-detail">
            <detail-form :product="product" />
          </v-tab-item>

          <v-tab-item value="tab-option">
            <option-form :product="product" />
          </v-tab-item>

          <v-tab-item value="tab-stocks">
            <stock-form :product="product" />
          </v-tab-item>

          <v-tab-item value="tab-relations">
            <relation-form :product="product" />
          </v-tab-item>

          <v-tab-item value="tab-deliveries">
            <delivery-form :product="product" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import BasicForm from "./forms/_Basic"
  import DescriptionForm from "./forms/_Description"
  import ImageForm from "./forms/_Images"
  import SearchForm from "./forms/_Search"
  import DetailForm from "./forms/_Detail"
  import OptionForm from "./forms/_Option"
  import StockForm from "./forms/_Stocks"
  import RelationForm from "./forms/_Relations"
  import DeliveryForm from "./forms/_Deliveries"
  import {mapState} from "vuex";

  export default {
    components: {
      BasicForm,
      DescriptionForm,
      ImageForm,
      SearchForm,
      DetailForm,
      OptionForm,
      StockForm,
      RelationForm,
      DeliveryForm,
    },
    mixins: [
    ],
    props: {
      product: {
        type: Object,
        default: () => {
          return {}
        },
      },
      companyMode: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        productForm: this.$route.params.tab,
      }
    },
    computed: {
      ...mapState('backProduct', {
        productDetail: 'product'
      }),
    },
    methods: {
      updateMainCallback () {
        this.$emit('updateCallback')
      }
    },
  }
</script>

<style lang="scss">
  .v-window-item > .v-card
  {
    min-height:300px;
  }
</style>
