<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon class="mr-2">
            mdi-bell
          </v-icon>Notice<span class="subtitle-2 ml-4">店舗からのお知らせ</span>
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="notices"
          loading-text="読込中"
          no-data-text="店舗からのお知らせはありません。"
          hide-default-footer
          hide-default-header
        >
          <template v-slot:item="{ item }">
            <tr
              style="cursor: pointer"
              @click="showNoticeDialog(item)"
            >
              <td>{{ item.notice_date | date_format }}<div>{{ item.title }}</div></td>
              <td align="end">
                <v-icon>mdi-chevron-right</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-divider class="my-6" />
    <v-pagination
      v-model="page"
      :length="pagination.total_pages"
      :total-visible="totalVisible"
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      @input="changePage"
    />
    <!--  お知らせダイアログ  -->
    <notice-dialog ref="noticeDialog" />
  </v-container>
</template>

<script>

  import { mapActions, mapState } from 'vuex'
  import NoticeDialog from '../../../components/NoticeDialog'
  import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";
  export default {
    name: 'MembershipNotificationIndex',
    components: {
      NoticeDialog,
    },
    mixins: [
      PageHeaderMixin
    ],
    data () {
      return {
        page: 1,
        totalVisible: 7,
        headers: [
          {text: 'title', align: 'start', value: 'title'},
          {text: '', align: 'start', value: 'action'},
        ],
        notices: [],
      }
    },
    computed: {
      ...mapState('frontCustomerNotice', {
        pagination: 'pagination',
      })
    },
    created() {
      this.descriptionContent = this.$route.meta.siteSubTitle
      this.pageTitle = this.$route.meta.siteSubTitle
      this.page = this.pagination.current_page
      this.searchCustomerNotice()
    },
    methods: {
      ...mapActions('frontCustomerNotice', {
        findCustomerNotices: 'findCustomerNotices',
        showCustomerNotice: 'show',
      }),
      searchCustomerNotice () {
        const params = {
          page: this.page
        }
        this.findCustomerNotices({params}).then((content) => {
          this.notices = content.notices
        })
      },
      showNoticeDialog (item) {
        this.showCustomerNotice({id: item.id}).then((content) => {
          this.$refs.noticeDialog.open({headerTitle: '店舗からのお知らせ', title: content.title, description: content.description, noticeDate: content.notice_date})
        })
      },
      changePage() {
        this.searchCustomerNotice()
      }
    }
  }
</script>

<style scoped lang="scss">
</style>