<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :elevation="hover ? 12 : 2"
      class="mx-auto grey lighten-4"
      @click="showProduct(product)"
    >
      <v-img
        :alt="product.name"
        height="200px"
        contain
        :src="product.image_url | load_image_url"
        :lazy-src="product.image_url | load_image_url"
        aspect-ratio="1"
        class="white lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
      </v-img>
      <v-card-subtitle
        class="pb-0 text-center"
      >
        {{ product.name }}
      </v-card-subtitle>

      <v-chip-group
        column
        multiple
      >
        <v-chip
          v-for="category in product.categories"
          :key="category.id"
          :value="category.id"
          class="ml-1 mb-0"
          color="#db7093"
          label
          outlined
          x-small
        >
          {{ category.name }}
        </v-chip>
      </v-chip-group>
      <v-chip-group
        column
        multiple
      >
        <v-chip
          v-for="tag in product.tags"
          :key="tag.id"
          :color="tag.background_color"
          :text-color="tag.font_color"
          :value="tag.id"
          class="ml-1 mb-0"
          label
          x-small
        >
          {{ tag.name }}
        </v-chip>
      </v-chip-group>

      <v-card-text
        class="text--primary text-center"
        style="min-height: 37px"
      >
        <div v-if="!product.in_stock && !product.use_calendar">
          在庫なし
        </div>
        <div v-else>
          <div v-if="mode==='auction'">
            {{ product.in_tax_price | in_tax_yen_format(product.tax_exempt) }}
          </div>
          <div v-else-if="!product.changed_rental_period">
            {{ product.in_tax_price | in_tax_yen_format(product.tax_exempt) }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>

  export default {
    name: 'ProductCard',
    props: {
      product: {
        type: Object,
        default: () => {
          return {}
        },
      },
      mode: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
      }
    },
    methods: {
      showProduct (item) {
        // 商品詳細へ(タイトルを変更するために productName を付与)
        if (item.mode == 'rental') {
          this.$router.push({name: 'ProductShow', params: { id: item.id, productName: item.name}})
        } else {
          this.$router.push({name: 'ProductShowAuction', params: { id: item.id, productName: item.name}})
        }

      },
    }
  }

</script>

<style scoped lang="scss">
</style>