<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          xs="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="3"
        >
          <v-card outlined>
            <v-card-text>
              <h2 class="text-center">
                メールの送信が完了しました。
              </h2>
              <p>パスワード再設定ページへのリンクを記載したメールをお送りしました。</p>
              <p>再設定メールが届かない場合は、迷惑メールボックスの設定解除、ドメイン着信許可の設定をご確認ください。</p>
            </v-card-text>
            <v-card-text>
              <div class="text-center">
                <v-btn
                  @click="backToLoginButtonClickEvent"
                >
                  ログイン画面に戻る
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: "ForgetMailSent",
    methods: {
      backToLoginButtonClickEvent () {
      this.$router.push({name: 'bySessionIndex'})
    }
  }
}
</script>

<style scoped>

</style>