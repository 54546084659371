/**
 * 商品
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/product_descriptions`

const product_description = {
  namespaced: true,
  actions: {
    edit({ _commit, state }, { id }) {
      const api_path = `${API_PATH}/${id}/edit`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
    update({commit, state},{ id, params } ) {
      const api_path = `${API_PATH}/${id}`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.patch(api_path, params).then( data => {
          let content = data.content
          commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
          commit(types.BACK_PRODUCT_DESCRIPTION, { content })
          resolve(content)
        } )
      })
    },
  },
  mutations: {
    [types.BACK_PRODUCT_DESCRIPTION] (state, { content }) {
      state.product = content
    }
  },
}

export default product_description