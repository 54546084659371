/**
 * ニュース
 */
import Vue from 'vue'
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/notices`

const notice = {
  namespaced: true,
  state: {
  },
  getters: {},
  actions: {
    findNotices({ commit }) {
      return new Promise((resolve, _reject) => {
        Vue.$http.get(API_PATH).then(data => {
          let content = data.content
          resolve(content)
        })
      })
    },
    find ({ _commit }, {id}) {
      const api_path = `${API_PATH}/${id}`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          resolve(data.content)
        })
      })
    },
  },
  mutations: {},
}

export default notice
