<template>
  <div>
    <alert-message />
    <v-form v-if="announcement">
      <validation-observer v-slot="{ invalid }">
        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:50"
              name="タイトル"
              class="requiredMark"
            >
              <v-text-field
                v-model="announcement.title"
                label="タイトル"
                dense
                hide-details="auto"
                clearable
                required
                counter="50"
                placeholder=" "
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <validation-provider
              v-slot="{ errors }"
              rules="max:255"
              name="本文"
              class=""
            >
              <v-textarea
                v-model="announcement.description"
                label="本文"
                clearable
                counter="255"
                auto-grow
                row-height="15"
                dense
                hide-details="auto"
                placeholder=" "
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-menu
              v-model="announcement.notice_date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="お知らせ日"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="announcement.notice_date"
                    clearable
                    readonly
                    persistent-hint
                    label="お知らせ日"
                    required
                    v-bind="attrs"
                    :error-messages="errors"
                    v-on="on"
                  />
                </validation-provider>
              </template>
              <v-date-picker
                v-model="announcement.notice_date"
                no-title
                @input="notice_date_menu = false"
              />
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-menu
              v-model="display_start_date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="表示開始日"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="announcement.display_start_date"
                    label="表示開始日"
                    clearable
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    :error-messages="errors"
                    v-on="on"
                  />
                </validation-provider>
              </template>


              <v-date-picker
                v-model="announcement.display_start_date"
                no-title
                @input="display_start_date_menu = false"
              />
            </v-menu>
          </v-col>


          <v-col cols="3">
            <v-menu
              v-model="display_end_date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="表示終了日"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="announcement.display_end_date"
                    label="表示終了日"
                    clearable
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    :error-messages="errors"
                    v-on="on"
                  />
                </validation-provider>
              </template>


              <v-date-picker
                v-model="announcement.display_end_date"
                no-title
                @input="display_end_date_menu = false"
              />
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules=""
              name="全社配信"
              class=""
            >
              <v-radio-group
                v-model="announcement.announce_all"
                label="全社配信"
                row
                dense
                hide-details="auto"
                class="pa-0 ma-0"
                :error-messages="errors"
              >
                <v-radio
                  label="しない"
                  :value="false"
                />
                <v-radio
                  label="する"
                  :value="true"
                />
              </v-radio-group>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-show="announcement.announce_all === false"
            cols="6"
          >
            <validation-provider
              v-slot="{ errors }"
              rules=""
              name="配信先会社"
            >
              <v-select
                v-model="announcement.company_ids"
                multiple
                :items="announcement.company_all"
                item-text="name"
                item-value="id"
                label="配信先会社"
                hint="選択してください"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="float-right">
              <v-btn
                @click="cancelButtonClickEvent"
              >
                キャンセル
              </v-btn>
              <v-btn
                color="primary"
                :disabled="invalid"
                @click="submit"
              >
                保存
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </validation-observer>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import alertMessage from '../../../components/AlertMessage'
// import ContractPlan from '../../components/ContractPlan'

export default {
  components: {
    alertMessage
  },
  // mixins: [ ContractPlan ],

  props: {
    announcement: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: false
    },

  },
  data () {
    return {
      notice_date_menu: false,
      display_start_date_menu: false,
      display_end_date_menu: false
    }
  },

  computed: {},
  mounted() {},
  created () {

  },
  methods: {
    ...mapActions('adminAnnouncement', {
      createAnnouncement: 'create',
      updateAnnouncement: 'update'
    }),

    buildParams () {
      // true 全社配信 サーバサイドで全社取得 false 指定した会社情報を配列で追加
      var company_ids = null
      if(this.announcement.announce_all == false) {
        company_ids = this.announcement.company_ids
      }
      const params = {title: this.announcement.title,
                      description: this.announcement.description,
                      announce_all: this.announcement.announce_all,
                      notice_date: this.announcement.notice_date,
                      display_start_date: this.announcement.display_start_date,
                      display_end_date: this.announcement.display_end_date,
                      company_ids: company_ids
      };
      return params
    },

    // キャンセルボタンクリック
    cancelButtonClickEvent: function () {

      if (this.isEdit) {
        // 詳細遷移
        this.$router.push( { name: 'adAnnouncementShow', params: { id: this.$route.params.id } } )
      } else {
        // 一覧遷移
        this.$router.push( { name: 'adAnnouncementIndex' } )
      }
    },

    // 保存ボタンクリック
    submit() {

      const params = this.buildParams()

      if (this.isEdit) {

        // 更新処理
        this.updateAnnouncement({ id: this.$route.params.id, params: params }).then(() =>{
          this.$router.push({name: 'adAnnouncementIndex'})
        })

      } else {
        // 新規処理
        this.createAnnouncement({ params: params }).then(() =>{
          this.$router.push({name: 'adAnnouncementIndex'})
        })
      }
    }

  },
}
</script>

<style lang="scss">
.v-picker__title { border-radius: 0 !important; }
</style>
