<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon class="mr-2">
            mdi-cart
          </v-icon>
          History
          <span class="subtitle-2 ml-4">取引履歴</span>
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <v-row
      v-if="isOrder"
      dense
    >
      <v-col
        v-for="item in orderDetails"
        :key="item.id"
        cols="12"
        sm="6"
        md="4"
      >
        <v-card outlined>
          <v-card-text class="pb-0">
            <v-row>
              <v-col
                cols="4"
                align="center"
              >
                <v-avatar
                  tile
                  size="80"
                >
                  <v-img
                    :src="item.image_url | load_image_url"
                    :lazy-src="item.image_url | load_image_url"
                  />
                </v-avatar>
              </v-col>
              <v-col
                cols="8"
                class="pb-1"
              >
                <div class="subtitle-1 pb-1">
                  {{ item.product_name }}
                </div>
                <div class="subtitle-2">
                  注文番号：{{ item.order_no }}
                </div>
                <div class="subtitle-2">
                  注文日：{{ item.order_date | date_format }}
                </div>
                <div class="subtitle-2">
                  購入金額：{{ item.total_amount | yen_format }}
                </div>
                <div class="caption">
                  {{ item.status | statusFilter }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              small
              color="primary"
              @click="showProduct(item)"
            >
              <v-icon>mdi-cart-outline</v-icon>再度レンタル
            </v-btn>
            <v-btn
              v-if="item.use_review"
              text
              small
              color="primary"
              @click="openReview(item)"
            >
              <v-icon>mdi-star-outline</v-icon>レビューを書く
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        v-if="isOrder"
        cols="12"
      >
        <v-divider />
        <div class="text-center mt-6">
          <v-pagination
            v-model="page"
            :length="pagination.total_pages"
            :total-visible="totalVisible"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            @input="changePage"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        align="center"
      >
        取引履歴がありません。
      </v-col>
    </v-row>


    <!--  レビューダイアログ  -->
    <v-dialog
      v-model="reviewDialog"
      :fullscreen="($vuetify.breakpoint.xsOnly)"
      :transition="($vuetify.breakpoint.xsOnly) ? 'dialog-bottom-transition' : ''"
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-card-subtitle class="pa-0">
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="reviewDialog=false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title align="center">
              商品レビュー
            </v-toolbar-title>
          </v-toolbar>
        </v-card-subtitle>
        <v-card-text v-if="reviewItem !== null">
          <v-row>
            <v-col cols="12">
              <v-card outlined>
                <validation-observer
                  ref="observer"
                  v-slot="{ invalid }"
                >
                  <v-card-text>
                    <v-row align="center">
                      <v-col
                        cols="4"
                        align="center"
                      >
                        <v-avatar
                          tile
                          size="80"
                        >
                          <v-img
                            :src="reviewItem.image_url | load_image_url"
                            :lazy-src="reviewItem.image_url | load_image_url"
                          />
                        </v-avatar>
                      </v-col>
                      <v-col cols="8">
                        <div class="subtitle-1">
                          {{ reviewItem.product_name }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-row>
                      <v-col cols="12">
                        <div class="text-center">
                          <v-rating
                            v-model="review.rating"
                            :large="(!$vuetify.breakpoint.xsOnly)"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="pt-4">
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:20"
                          name="公開名"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="review.bundle_name"
                            outlined
                            label="公開名"
                            placeholder="れんたる"
                            counter="20"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:50"
                          name="レビュータイトル"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="review.title"
                            outlined
                            label="レビュータイトル"
                            placeholder="タイトルを入力してください"
                            counter="50"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|max:200"
                          name="レビュー内容"
                          class="requiredMark"
                        >
                          <v-textarea
                            v-model="review.comment"
                            outlined
                            label="レビュー内容"
                            placeholder="レビュー内容を入力してください。"
                            value=""
                            counter="200"
                            :error-messages="errors"
                            required
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      block
                      outlined
                      :disabled="invalid"
                      @click="submitReview"
                    >
                      <v-icon>mdi-post-outline</v-icon>投稿する
                    </v-btn>
                  </v-card-actions>
                </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";

export default {
  name: 'MembershipHistoryIndexAuction',
  components: {
  },
  filters: {
    statusFilter(status) {
      if (status === 'canceled') {
        return 'キャンセル済'
      } else if (status === 'shipped') {
        return '出荷済'
      } else {
        return '出荷準備中'
      }
    }
  },
  mixins: [
      PageHeaderMixin
  ],
  data () {
    return {
      page: 1,
      totalVisible: 7,
      orderDetails: null,
      reviewDialog: false,
      reviewItem: null,
      review: {
        title: '',
        comment: '',
        rating: 1,
        bundle_name: '',
      },
      bundle_name: null,
    }
  },
  computed: {
    ...mapState('frontOrder', {
      pagination: 'pagination',
    }),
    ...mapGetters('frontOrder', [
      'isOrder'
    ])
  },
  created() {
    this.descriptionContent = this.$route.meta.siteSubTitle
    this.pageTitle = this.$route.meta.siteSubTitle
    this.loadOrderDetails()
    this.editCustomer().then((content) => {
      this.bundle_name = content.bundle_name
    })
  },
  methods: {
    ...mapActions('frontOrder', {
      findHistories: 'findHistories',
    }),
    ...mapActions('frontCustomer', {
      editCustomer: 'edit'
    }),
    ...mapActions('frontProductReview', {
      createReview: 'create'
    }),
    showProduct (item) {
      // 商品詳細へ
      this.$router.push({name: 'ProductShow', params: { id: item.product_id }})
    },
    openReview (item) {
      this.reviewItem = item
      this.review.bundle_name = this.bundle_name
      this.reviewDialog = true
    },
    loadOrderDetails () {
      const params = {
        page: this.page
      }
      this.findHistories({ params }).then((content) => {
        this.orderDetails = content.order_details
      })
    },
    changePage () {
      this.loadOrderDetails()
    },
    submitReview () {
      const params = {
        title: this.review.title,
        comment: this.review.comment,
        evaluation: this.review.rating,
        bundle_name: this.review.bundle_name,
      }
      this.createReview({ product_id: this.reviewItem.product_id, params: params }).then( () => {
        this.review.title = null
        this.review.comment = null
        this.review.rating = 1
        this.$refs.observer.reset()
        this.reviewDialog = false
      })
    }

  },
}
</script>

<style scoped lang="scss">
</style>