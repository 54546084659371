<!-- バックヤード > 商品への問い合わせ一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          商品への問い合わせ
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="query.keyword"
                  dense
                  hide-details="auto"
                  label="キーワード"
                  outlined
                  placeholder=" "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <p class="mb-0">
                  ステータス
                </p>
                <v-radio-group
                  v-model="query.status"
                  class="pa-0 ma-0"
                  dense
                  hide-details="auto"
                  row
                >
                  <v-radio
                    value="all"
                    label="全て"
                  />
                  <v-radio
                    value="unanswered"
                    label="未回答のみ"
                  />
                  <v-radio
                    value="answered"
                    label="回答済みのみ"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="searchProductQuestions"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th />
                  <th class="text-left">
                    品番1/商品名
                  </th>
                  <th class="text-left">
                    質問者
                  </th>
                  <th class="text-left">
                    質問日時
                  </th>
                  <th class="text-left">
                    質問
                  </th>
                  <th class="text-left">
                    回答日時
                  </th>
                  <th class="text-left">
                    回答者
                  </th>
                  <th class="text-left">
                    Q&Aに表示
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in product_questions"
                  :key="item.id"
                >
                  <td class="text-center">
                    <v-img
                      :src="item.image.url | load_image_url"
                      max-height="40"
                      max-width="60"
                      contain
                    />
                  </td>
                  <td>{{ item.part_number_1 }}&nbsp;/&nbsp;{{ item.product_name }}</td>
                  <td>{{ item.customer_name }}</td>
                  <td>{{ item.question_at | unix_datetime_format }}</td>
                  <td>{{ item.question_content | truncate(30) }}</td>
                  <td>{{ item.answer_at | unix_datetime_format }}</td>
                  <td>{{ item.answer_user_name }}</td>
                  <td>{{ item.display_answer ? '表示' : '非表示' }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="showProductQuestion(item)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>

  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    data () {
      return {
        page: 1,
        totalVisible: 7,
        query: {
          keyword: '',
          status: 'all',
        },
        product_questions: [],
      }
    },
    computed: {
      ...mapState('backProductQuestion', {
        pagination: 'pagination',
        searchQuery: 'query',
      })
    },
    created () {
      if (this.$route.params.isClearCondition) {
        this.resetQuery()
      }

      this.query = this.searchQuery
      const searchStatus = this.$route.query.status
      if (searchStatus) {
        this.query.status = searchStatus
      }
      this.page = this.pagination.current_page
      this.searchProductQuestions()
    },
    methods: {
      ...mapActions('backProductQuestion', {
        fetchProductQuestions: 'fetch',
        resetQuery: 'resetQueries'
      }),
      showProductQuestion: function (item) {
        this.$router.push({name: 'byQaEditIndex', params: {id: item.id}})
      },
      searchProductQuestions () {
        const params = {
          keyword: this.query.keyword,
          status: this.query.status,
          page: this.page
        }
        this.fetchProductQuestions({params}).then((content) => {
          this.product_questions = content.product_questions
        })
      },
      changePage () {
        this.searchProductQuestions()
      },
    }
  }
</script>

<style lang="scss">

</style>
