<!-- バックヤード > マイページ一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          フリーページ
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              @click="addButtonClickEvent"
            >
              フリーページ追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="title"
                      dense
                      label="タイトル"
                      hide-details="auto"
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="postName"
                      dense
                      label="パーマリンク（URL）"
                      hide-details="auto"
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-checkbox
                      v-model="onlyFooter"
                      label="フッター表示のみ"
                      hide-details="auto"
                      class="pa-0 ma-0"
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="searchMyPages"
                  >
                    検索
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    タイトル
                  </th>
                  <th class="text-left">
                    パーマリンク（URL）
                  </th>
                  <th class="text-center">
                    フッター表示
                  </th>
                  <th class="text-center">
                    表示順
                  </th>
                  <th class="text-left">
                    最終更新者
                  </th>
                  <th class="text-left">
                    最終更新日時
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="my_page in myPages"
                  :key="my_page.id"
                >
                  <td>{{ my_page.title }}</td>
                  <td>{{ my_page.post_name }}</td>
                  <td class="text-center">
                    {{ my_page.display_footer ? '表示' : '非表示' }}
                  </td>
                  <td class="text-center">
                    {{ my_page.position }}
                  </td>
                  <td>{{ my_page.updated_user }}</td>
                  <td>{{ my_page.updated_at | unix_datetime_format }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="editButtonClickEvent(my_page)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-3">
      <v-col cols="12">
        <v-pagination
          v-if="isMyPages"
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          circle
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="changePage"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="enableAddDialog"
      width="450"
      no-click-animation
      @close="closeButtonClickEvent"
    >
      <validation-observer
        v-slot="{ invalid }"
        ref="myPageFormValidation"
      >
        <v-form>
          <v-card>
            <v-card-title>フリーページ</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:50"
                    name="タイトル"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="myPage.title"
                      :error-messages="errors"
                      counter="50"
                      dense
                      hide-details="auto"
                      label="タイトル"
                      outlined
                      placeholder=" "
                      required
                      persistent-placeholder
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|alpha_dash|max:50"
                    name="パーマリンク（URL）"
                    class="requiredMark"
                  >
                    <v-text-field
                      v-model="myPage.post_name"
                      label="パーマリンク（URL）"
                      dense
                      hide-details="auto"
                      outlined
                      required
                      counter="50"
                      placeholder=" "
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click="closeButtonClickEvent">
                キャンセル
              </v-btn>
              <v-btn
                :disabled="invalid"
                color="primary"
                @click="registerButtonClickEvent"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'

/**
 * @typedef myPages { Array }
 * @property updated_user { string }
 * @property updated_at { Number }
 */
export default {
  data () {
    return {
      page: 1,
      totalVisible: 7,
      title: null,
      postName: null,
      onlyFooter: false,
      enableAddDialog: false,
      myPages: [],
      myPage: {
        title: null,
        post_name: null,
      }
    }
  },
  computed: {
    ...mapState('backMyPage', {
      pagination: 'pagination',
      searchQuery: 'query',
    }),
    ...mapGetters('backMyPage', [
      'isMyPages'
    ]),
  },
  created() {
    this.title = this.searchQuery.title
    this.postName = this.searchQuery.post_name
    this.onlyFooter = this.searchQuery.only_footer
    this.page = this.pagination.current_page
    this.loadMyPages()
  },
  methods: {
    ...mapActions('backMyPage', {
      fetchAll: 'findMyPages',
      fetchQuery: 'fetchQuery',
      create: 'create'
    }),
    searchMyPages () {
      this.page = 1
      this.loadMyPages()
    },
    loadMyPages () {
      const params = {
        title: this.title,
        post_name: this.postName,
        only_footer: this.onlyFooter,
        page: this.page
      }
      this.fetchAll(params).then((content) => {
        this.myPages = content.my_pages
      })
    },
    editButtonClickEvent (my_page) {
      this.$router.push({name: 'byMyPageEdit', params: {id: my_page.id}})
    },
    changePage () {
      this.loadMyPages()
    },
    addButtonClickEvent () {
      this.enableAddDialog = true
    },
    registerButtonClickEvent () {
      this.create({params: this.myPage}).then((content) => {
        this.closeButtonClickEvent()
        this.$router.push({name: 'byMyPageEdit', params: {id: content.id}})
      })
    },
    closeButtonClickEvent () {
      Object.assign(this.$data.myPage, this.$options.data().myPage);
      this.enableAddDialog = false
    }
  }
}
</script>

<style lang="scss">

</style>
