<!-- バックヤード > 受注編集 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <order-form />
  </div>
</template>

<script>
  import OrderForm from './_Form'
  export default {
    components: {
      OrderForm,
    },
    data () {
      return {
        breadcrumbs: [
          { text: '受注一覧', disabled: false, to: '/backyard/orders', exact: true },
          { text: '受注詳細', disabled: true, to: '' },
        ]
      }
    },
  }
</script>

<style lang="scss">

</style>
