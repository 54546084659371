/**
 * 注文情報
 * 顧客情報、備考、カードトークン
 */
import * as types from "../../../mutation-types/frontend-types"

const extend_payment = {
  namespaced: true,
  state: {
    delivery: {
      payment_type: null,
      card_token: null,
    }
  },
  getters: {},
  actions: {
    fetch({ commit }, {payment_type, card_token}) {
      commit(types.FRONT_EXTEND_PAYMENT, {payment_type, card_token})
    },
  },
  mutations: {
    [types.FRONT_EXTEND_PAYMENT] (state, { payment_type, card_token }) {
      state.delivery.payment_type = payment_type
      state.delivery.card_token = card_token
    },
  },
}

export default extend_payment
