<!-- バックヤード > スケジュール登録ダイアログ -->
<template>
  <v-dialog
    v-model="isShowRentalScheduleDialog"
    width="500"
    persistent
  >
    <validation-observer
      v-slot="{ invalid }"
      ref="stockFormValidation"
    >
      <v-form>
        <v-card>
          <v-card-title>スケジュール登録</v-card-title>
          <v-card-text>

            <v-row>
              <v-col>
                <div>商品</div>
                <v-card outlined>
                  <v-card-text>
                    <v-row v-if="!rental_schedule.is_edit">
                      <div class="text-left" style="margin-left:10px" >
                        <v-btn
                          class="mb-2"
                          small
                          @click="openItemSearchDialog"
                        >
                          商品検索
                        </v-btn>
                      </div>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="現地名"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="local_place_name"
                            label="現地名"
                            hide-details="auto"
                            :disabled = "rental_schedule.is_edit"
                            outlined
                            placeholder=" "
                            required
                            :error-messages="errors"
                            dense
                            readonly
                            persistent-hint
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="商品名"
                          class="requiredMark"
                        >
                          <v-text-field
                            v-model="part_name"
                            label="商品名"
                            hide-details="auto"
                            :disabled = "rental_schedule.is_edit"
                            outlined
                            placeholder=" "
                            required
                            :error-messages="errors"
                            dense
                            readonly
                            persistent-hint
                          />

                        </validation-provider>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <div>ご利用期間</div>
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-menu
                          v-model="from_date_menu"
                          :close-on-content-click="false"
                          min-width="290px"
                          offset-y
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <validation-provider
                              v-slot="{ errors }"
                              class="requiredMark"
                              rules="required"
                              name="開始日"
                            >
                              <v-text-field
                                v-model="rental_schedule.from_date"
                                :error-messages="errors"
                                :disabled = "rental_schedule.is_edit"
                                clearable
                                dense
                                hide-details="auto"
                                label="開始日"
                                outlined
                                placeholder=" "
                                readonly
                                v-on="on"
                                @click:clear="rental_schedule.from_date = null"
                              >
                                <template
                                  v-if="!$vuetify.breakpoint.smAndDown"
                                  v-slot:append-outer
                                >
                                  &emsp;〜
                                </template>
                              </v-text-field>
                            </validation-provider>
                          </template>
                          <v-date-picker
                            v-model="rental_schedule.from_date"
                            @input="from_date_menu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        md="5"
                      >
                        <v-menu
                          v-model="to_date_menu"
                          :close-on-content-click="false"
                          min-width="290px"
                          offset-y2
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on }">
                            <validation-provider
                              v-slot="{ errors }"
                              class="requiredMark"
                              :rules="(rental_schedule.is_edit)? 'required|to_date_check' : 'required'"
                              name="終了日"
                            >
                              <v-text-field
                                v-model="rental_schedule.to_date"
                                :error-messages="errors"

                                clearable
                                dense
                                hide-details="auto"
                                label="終了日"
                                outlined
                                placeholder=" "
                                readonly
                                v-on="on"
                                @click:clear="rental_schedule.to_date = null"
                              />
                            </validation-provider>
                          </template>
                          <v-date-picker
                            v-model="rental_schedule.to_date"
                            @input="to_date_menu = false"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="9"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:30"
                  name="氏名"
                  class="requiredMark"
                >
                  <v-text-field
                    v-model="rental_schedule.name"
                    label="氏名"
                    :disabled = "rental_schedule.has_order"
                    counter="30"
                    outlined
                    placeholder=" "
                    required
                    :error-messages="errors"
                    dense
                    persistent-hint
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="9"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="{ regex: /^[0-9\-]+$/, max:11}"
                  name="電話番号"
                >
                  <v-text-field
                    v-model="rental_schedule.phone"
                    :error-messages="errors"
                    counter="11"
                    :disabled = "rental_schedule.has_order"
                    dense
                    hide-details="auto"
                    hint="ハイフン(-)無しで入力してください"
                    label="電話番号"
                    outlined
                    persistent-hint
                    placeholder=" "
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="管理番号"
                  rules="max:70"
                >
                  <v-text-field
                    v-model="rental_schedule.control_number"
                    :error-messages="errors"
                    auto-grow
                    counter="70"
                    dense
                    hide-details="auto"
                    label="管理番号"
                    outlined
                    placeholder=" "
                    row-height="15"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="備考"
                  rules="max:255"
                >
                  <v-text-field
                    v-model="rental_schedule.remarks"
                    :error-messages="errors"
                    auto-grow
                    counter="70"
                    dense
                    hide-details="auto"
                    label="備考"
                    outlined
                    placeholder=" "
                    row-height="15"
                  />
                </validation-provider>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions>
            <v-btn
              v-if="rental_schedule.is_edit && !rental_schedule.has_order"
              color="error"
              @click="deleteButtonClickEvent"
            >
              削除
            </v-btn>
            <v-spacer />
            <v-btn @click="isShowRentalScheduleDialog = false">
              キャンセル
            </v-btn>
            <v-btn
              color="primary"
              :disabled="invalid"
              @click="dialogRegisterButtonClickEvent"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
    <item-search-dialog
      ref="ItemSearchDialog"
      @itemSearchDialogCallback="itemSearchDialogCallback"
    />
    <confirm ref="confirm" />
  </v-dialog>

</template>

<script>
import Confirm from "../../components/Confirm";
import ItemSearchDialog from '../dialogs/ItemSearchDialog'
import { mapActions, mapState } from 'vuex'
import { extend } from 'vee-validate'

let from_date = "";
let to_date = "";

/*
 * 終了日の日付チェック（更新時のみ）
 */
extend('to_date_check', (value) => {
  if (value > to_date || value < from_date) {
    return "終了日は、" + from_date + "から" + to_date + "の期間内で入力してください。"
  }
  return true
})

export default {
  components: {
    ItemSearchDialog,
    Confirm,
  },
  mixins: [],
  data () {
    return {
      isShowRentalScheduleDialog: false,
      from_date_menu: false,
      to_date_menu: false,
      rental_schedule: {},
      product_name: null,
      local_place_name: null,
    }
  },
  computed: {
    ...mapState('backProductStock', {
      productStocks: 'product_stocks',
    })
  },
  methods: {
    ...mapActions('backRentalSchedule', {
      createRentalSchedule: 'create',
      updateRentalSchedule: 'update',
      destroyRentalSchedule: 'destroy'
    }),
    ...mapActions('backProduct', {
      fetchProduct: 'fetch',
    }),

    openDialog (params) {
      this.rental_schedule = params['rental_schedule'] ? params['rental_schedule'] : {}
      this.local_place_name = this.rental_schedule.local_place_name
      this.part_name = this.rental_schedule.part_name
      this.isShowRentalScheduleDialog = true
      from_date = this.rental_schedule.from_date
      to_date = this.rental_schedule.to_date
    },

    openItemSearchDialog () {
      this.$refs.ItemSearchDialog.openDialog({singleSelect: true, use_calendar: true})
    },

    itemSearchDialogCallback (product_id) {
      this.fetchProduct({ id: product_id }).then((content) => {
        this.rental_schedule.product_id = content.id
        this.part_name = content.name
        this.local_place_name = content.local_place_name
      })
    },

    dialogRegisterButtonClickEvent () {

      const params = {product_id: this.rental_schedule.product_id,
        from_date: this.rental_schedule.from_date,
        to_date: this.rental_schedule.to_date,
        name: this.rental_schedule.name,
        phone: this.rental_schedule.phone,
        control_number: this.rental_schedule.control_number,
        remarks: this.rental_schedule.remarks,
      };

      if (this.rental_schedule.id) {
        this.updateRentalSchedule({ id: this.rental_schedule.id, params: params }).then(() => {
          this.isShowRentalScheduleDialog = false
          this.$emit('rentalScheduleDialogCallback')
        })
      } else {
        this.createRentalSchedule( { params: params }).then(() => {
          this.isShowRentalScheduleDialog = false
          this.$emit('rentalScheduleDialogCallback')
        })
      }
    },
    // 削除
    deleteButtonClickEvent () {
      // 削除確認ダイアログ立ち上げ
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        // OKなら削除
        if (confirm) {
          this.destroyRentalSchedule({ id: this.rental_schedule.id }).then(() => {
            this.isShowRentalScheduleDialog = false
            this.$emit('rentalScheduleDialogCallback')
          })
        }
      })
    },


  },
}
</script>

<style scoped>
</style>