<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon class="mr-2">
            mdi-card-account-details
          </v-icon>Profile<span class="subtitle-2 ml-4">新規会員登録</span>
        </h2>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <membership-profile-form />
  </div>
</template>

<script>

import MembershipProfileForm from './basic/_Form'

export default {
  name: 'MembershipProfileNew',
  components: {
    MembershipProfileForm
  },
  data () {
    return {
      customer: null,
      breadcrumbs: [
        { text: '会員管理', disabled: false, to: '/backyard/members', exact: true },
        { text: '新規会員登録', disabled: true, to: '' },
      ],
    }
  },
  created() {},
  methods: {},
}
</script>

<style scoped lang="scss">
</style>