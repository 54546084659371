<!-- バックヤード > 会員詳細 > 登録情報 -->
<template>
  <div v-if="customer">
    <alert-message />
    <v-card flat>
      <v-card-title>登録情報</v-card-title>
      <v-card-text>
        <v-row
          v-for="(item, index) in customerItems"
          :key="index"
        >
          <v-col
            cols="12"
            sm="3"
            class="grey lighten-3"
          >
            <span class="font-weight-bold">{{ item.title }}</span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            class="line-break">{{ item.text }}</v-col>
          <v-col
            cols="12"
            class="px-0 py-2"
          >
            <v-divider />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      v-if="customer_items"
      flat
    >
      <h5> 会員汎用項目</h5>
      <v-card-text>
        <v-row
          v-for="(item, index) in customer_items"
          :key="index"
        >
          <v-col
            cols="12"
            sm="3"
            class="grey lighten-3"
          >
            <span class="font-weight-bold">{{ item.title }}</span>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            class="line-break">{{ item.value }}</v-col>
          <v-col
            cols="12"
            class="px-0 py-2"
          >
            <v-divider />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      v-if="identifications"
      flat
    >
      <h5> 本人確認書類</h5>
      <v-card-text>
        <v-row>
          <v-col
            v-for="image in identifications"
            cols="8"
            md="3"
          >
            <v-card
              :key="image.id"
              flat
              tile
              outlined
              style="background-color: #fafafa;"
            >
              <v-card-text
                class="justify-center"
              >
                <v-card
                  flat
                  tile
                >
                  <v-img
                    :src="image.thumb_url | load_image_url"
                    max-height="200"
                    contain
                    aspect-ratio="1"
                    class="gray lighten-4 align-center"
                  />
                </v-card>
              </v-card-text>
              <v-divider class="mx-4" />
              <v-card-text>
                更新日時：{{ image.updated_at | unix_datetime_format }}
              </v-card-text>
              <v-card-actions
                v-if="image.can_download"
                class="pt-0"
              >
                <v-btn
                  color="primary"
                  block
                  small
                  outlined
                  @click="imageDownload(image)"
                >
                  ダウンロード
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-divider />
      </v-card-text>
    </v-card>
    <validation-observer v-slot="{ invalid }">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="備考"
                rules="max:5120"
              >
                <v-textarea
                  v-model="customer.remarks"
                  :error-messages="errors"
                  auto-grow
                  counter="5120"
                  dense
                  hide-details="auto"
                  label="備考"
                  outlined
                  placeholder=" "
                  rows="3"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!customer.deleted_at"
            color="error"
            @click="deleteButtonClickEvent"
          >
            退会
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="invalid"
            @click="saveButtonClickEvent"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
    <Confirm ref="confirm" />
  </div>
</template>

<script>
import AlertMessage from '../../../../components/AlertMessage'
import Confirm from '../../../../components/Confirm'
import { mapActions } from 'vuex'
export default {
  components: {
    AlertMessage,
    Confirm,
  },
  props: {
    /**
     * @typedef customer {Object} 顧客
     * @property gender_label {String} 性別
     * @property activate_mail_sent_at { String } 会員招待メール送信日時
     * @property activated_at { String } 会員登録日時
     * @property deleted_at { Integer } 削除日時
     */
    customer: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      customerItems: [
        {title: '会員番号', text: this.customer.no},
        {title: '会員名', text: this.customer.name},
        {title: '会員名カナ', text: this.customer.name_kana},
        {title: '公開名', text: this.customer.bundle_name},
        {title: 'ログインID', text: this.customer.login_id},
        {title: '性別', text: this.customer.gender_label},
        {title: '電話番号', text: this.customer.phone},
        {title: '国際電話番号', text: this.customer.international_phone},
        {title: '住所', text: `〒 ${this.customer.zip_code || ''} ${this.customer.address || ''}`},
        {title: 'メールアドレス', text: this.customer.email},
        {title: '生年月日', text: this.customer.birthday},
        {title: '会員招待メール送信日時', text: this.customer.activate_mail_sent_at },
        {title: '会員登録日時', text: this.customer.activated_at },
        {title: '退会日時', text: this.$options.filters.unix_datetime_format(this.customer.deleted_at) },
      ],
      customer_items: this.customer.customer_items,
      identifications: this.customer.identifications,
    }
  },
  methods: {
    ...mapActions('backCustomerRemark', {
      updateRemark: 'update'
    }),
    ...mapActions('backCustomer', {
      deleteCustomer: 'delete',
    }),
    saveButtonClickEvent() {
      let params = {
        remarks: this.customer.remarks,
      }
      this.updateRemark(({ customer_id: this.customer.id, params: params }))
    },
    deleteButtonClickEvent () {
      this.$refs.confirm.open({ title: '退会', message: '退会してよろしいですか？', options: { titleColor: 'red' } }).then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.deleteCustomer({ id: this.customer.id }).then(() => {
            this.$router.push({name: 'byMemberIndex'})
          })
        }
      })
    },
    imageDownload (item) {
      const extension = item.image_url.split(".").pop()
      let link = document.createElement('a')
      link.href = item.image_url
      link.download = `identification_${Date.now()}.${extension}`
      link.target = '_blank'
      link.click()
    }
  }
}
</script>

<style lang="scss">
</style>
