<!-- バックヤード > パスワードを忘れた方 -->
<template>
  <v-container
    fluid
    fill-height
  >
    <v-card
      outlined
      class="mx-auto"
      style="width: 40rem;"
    >
      <v-card-title
        class="justify-center"
      >
        <h3 class="text-center">
          メールアドレスの認証が完了しました。
          <div class="lang_lbl">{{ $t('view.メールアドレスの認証が完了しました') }}</div>
        </h3>
      </v-card-title>
      <v-card-text>
        <div class="text-center">
          設定したパスワードでログインしてください
        </div>
        <div class="text-center lang_lbl">{{ $t('view.設定したパスワードでログインしてください') }}</div>
        <div class="text-center mt-5">
          <v-btn
            @click="backButtonClickEvent"
          >
            ログイン画面に戻る
            <div class="lang_lbl">{{ $t('view.ログイン画面に戻る') }}</div>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import PageHeaderMixin from "../../../../mixins/frontend/page-header-mixin";
import {mapActions} from "vuex";

export default {
  name: 'ActivateCompleted',
  components: {
  },
  mixins: [
      PageHeaderMixin
  ],
  data () {
    return {
      token: null,
    }
  },
  created () {
    this.token = this.$route.query.activate_token
    this.activateCustomer({ token: this.token})
    this.descriptionContent = this.$route.meta.siteSubTitle
    this.pageTitle = this.$route.meta.siteSubTitle
  },
  methods: {
    ...mapActions('frontCustomer', {
      activateCustomer: 'activate'
    }),
    backButtonClickEvent () {
      this.$router.push({name: 'HomeIndex'})
    }
  },
}
</script>

<style scoped lang="scss">
</style>
