<template>
  <v-row
    class="fill-height mx-16"
    align="center"
    :style="{ paddingLeft: paddingSize() + '%' }"
  >
    <v-col cols="12">
      <v-row
        class="pt-16"
      >
        <v-col
          cols="12"
          :class="backgroundImageContent.text_align"
        >
          <div
            class="text-h4"
            :style="{color: backgroundImageContent.title_color }"
          >
            {{ backgroundImageContent.title }}
          </div>
        </v-col>
        <v-col
          cols="12"
          :class="backgroundImageContent.text_align"
        >
          <span
            class="text-h6 body-2 mb-1 line-break"
            :style="{color: backgroundImageContent.sub_title_color }"
          >{{ backgroundImageContent.sub_title }}
          </span>
        </v-col>
        <v-col
          v-if="backgroundImageContent.use_button"
          cols="12"
          :class="backgroundImageContent.text_align"
        >
          <v-btn
            v-if="isExternal"
            outlined
            :href="backgroundImageContent.transition_url"
            target="_blank"
            :style="{color: backgroundImageContent.button_title_color }"
          >
            {{ backgroundImageContent.button_title }}
          </v-btn>
          <v-btn
            v-else
            outlined
            @click="linkAction"
            :style="{color: backgroundImageContent.button_title_color }"
          >
            {{ backgroundImageContent.button_title }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BackgroundImageContentsItem',
    props: {
      backgroundImageContent: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
      }
    },
    computed: {
      isExternal () {
        return this.backgroundImageContent.transition_type === 't_external'
      }
    },
    created() {
      window.addEventListener('resize', this.debounce(this.paddingSize, 300), false);
    },
    methods: {
      linkAction() {
        this.$emit('linkClickEventCallback')
      },
      debounce(fn, interval) {
        var timer
        return function () {
          clearTimeout(timer)
          timer = setTimeout(function () {
            fn()
          }, interval)
        }
      },
      paddingSize() {
        if (this.$vuetify.breakpoint.xsOnly) {
          return 0
        } else {
          return this.backgroundImageContent.left_padding
        }
      },
    }
  }
</script>

<style scoped lang="scss">
</style>