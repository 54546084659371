<script>
import {mapActions} from "vuex";

export default {
  name: "PaymentNoteDialog",
  components: {},
  mixins: [],
  props: {
    /**
     * タイトル
     */
    title: {
      type: String,
      default: null,
    },
    /**
     * 種別名
     */
    postName: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      enableDialog: false,
      content: null,
    }
  },
  computed: {
  },
  watch: {
    enableDialog: function(newValue) {
      this.content = null;
      if (newValue) {
        this.loadMyPage();
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions("frontMyPage", {
      fetchMyPage: "fetch",
    }),
    loadMyPage () {
      this.fetchMyPage({ post_name: this.postName }).then((content) => {
        this.content = content;
      })
    }
  }
}
</script>

<template>
  <v-dialog
    v-model="enableDialog"
    scrollable
    width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        v-on="on"
      >
        {{ title }}
        <v-icon small>mdi-alert-circle</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar :flat="true" color="grey lighten-4">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text v-if="content">
        <v-row>
          <v-col>
            <div v-html="content.description"></div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="enableDialog = false"
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>
