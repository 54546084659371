<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="($vuetify.breakpoint.xsOnly)"
    :transition="($vuetify.breakpoint.xsOnly) ? 'dialog-bottom-transition' : ''"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-subtitle class="pa-0">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title align="center">
            {{ headerTitle }}
          </v-toolbar-title>
        </v-toolbar>
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title>
                <div>
                  <div
                    v-if="canShowNewsDate"
                    class="text-body-2"
                  >
                    {{ noticeDate | date_format }}
                  </div>
                  <div>{{ title }}</div>
                </div>
              </v-card-title>
              <v-divider class="mx-2" />
              <v-card-text class="line-break">{{ description }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="onClose"
        >
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  /**
   * 各種お知らせダイアログ
   * example
   * <notice-dialog ref="noticeDialog"/>
   * this.$refs.noticeDialog.open({headerTitle: 'ヘッダータイトル', title: 'title', description: 'message', noticeDate: 'yyyy-mm-dd'})
   */
  export default {
    name: 'NoticeDialog',
    components: {},
    data () {
      return {
        dialog: false,
        resolve: null,
        headerTitle: null,
        title: null,
        description: null,
        noticeDate: null,
        canShowNewsDate: false,
      }
    },
    computed: {},
    methods: {
      open ({headerTitle, title, description, noticeDate, canShowNewsDate}) {
        this.headerTitle = headerTitle
        this.title = title
        this.description = description
        this.noticeDate = noticeDate
        this.canShowNewsDate = canShowNewsDate
        this.dialog = true
        return new Promise((resolve) => {
          this.resolve = resolve
        })
      },
      onClose () {
        this.dialog = false
        this.resolve(true)
      },
    }
  }

</script>

<style lang="scss">
</style>
