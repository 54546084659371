<template>
  <div id="app">
    <v-app>
      <v-main v-if="!loading">
        <session-header-nav v-if="isSessionComponent" />
        <header-nav v-else />
        <router-view />
        <session-footer-nav
          v-if="isSessionComponent"
          class="mt-4"
        />
        <footer-nav
          v-else
          class="mt-4"
        />
      </v-main>
      <snackbar />
      <loading />
    </v-app>
  </div>
</template>

<script>

  import HeaderNav from './components/HeaderNav'
  import FooterNav from './components/FooterNav'
  import SessionHeaderNav from './components/SessionHeaderNav'
  import SessionFooterNav from './components/SessionFooterNav'
  import Loading from '../components/Loading'
  import Snackbar from '../components/Snackbar'
  import {mapActions, mapState} from 'vuex'

  export default {
    name: 'App',
    components: {
      HeaderNav,
      FooterNav,
      SessionHeaderNav,
      SessionFooterNav,
      Loading,
      Snackbar,
    },
    data () {
      return {
        loading: true,
        description: '',
      }
    },
    computed: {
      ...mapState('frontAuth', {
        loggedIn: 'logged_in'
      }),
      isSessionComponent () {
        let components = ['SessionIndex', 'MembershipProfileNew', 'ForgetIndex', 'ForgetSent', 'ForgetEdit', 'ForgetCompleted', 'CardSwitchShow']
        return components.includes(this.$route.name)
      }
    },
    created() {
      this.find().then((content) => {
        if (content.description != null) {
          this.description = content.description
        }
        this.$emit('updateHead')
        if (content.use_frontend_auth === true && !this.loggedIn && this.$route.query.forget != 'true') {
          this.$router.push({ name: 'SessionIndex' })
        }
        this.loading = false

      })
    },
    methods: {
      ...mapActions('frontSystemSetting', {
        find: 'find',
      }),
    },
    // Usage with context the component
    head: {
      // meta () {
      //   return [
      //     { name: 'description', content: this.description, id: 'description_1' },
      //   ]
      // },
    },
  }
</script>

<style scoped lang="scss">

  // グローバルスタイルを設定
  #app {
      /*background-color: whitesmoke;*/
      min-height: 100vh;
  }

</style>
