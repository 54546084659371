import store from 'store'
import * as appConst from "../../constants/appConst"

const getCartStepper = () => {
  let stepper = store.get(appConst.FRONT_STORE_CART_STEPPER)
  return (stepper) ? stepper : 1
}

/**
 * カートのstepperを管理
 * router/index.js でもリセットする
 */
const CartStepperMixin = {
  data() {
    return {
      stepper: getCartStepper(),
    }
  },
  computed: {},
  watch: {
    stepper (newVal) {
      store.set(appConst.FRONT_STORE_CART_STEPPER, newVal)
      if (!store.get('frontReload') && this.stepper == 3) {
        window.addEventListener('beforeunload', this.reload)
      }
    }
  },
  mounted() {
    if (store.get('frontReload')) {
      store.remove('frontReload')
      this.$router.push({ name: 'HomeIndex' })
    }
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.reload)
  },
  filters: {},
  methods: {
    nextStep () {
      this.stepper++
      this.scrollToTop()
    },
    backStep () {
      this.stepper--
      this.scrollToTop()
    },
    toOrderPayment () {
      this.stepper = 2
      this.scrollToTop()
    },
    toOrderTeams () {
      this.stepper = 3
      this.scrollToTop()
    },
    resetStep () {
      this.stepper = 1
      this.scrollToTop()
    },
    reload() {
      store.set('frontReload', true)
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    moveToHome () {
      this.resetStep()
      this.$router.push({ name: 'HomeIndex' })
    },
  }
}

export default CartStepperMixin
