/**
 * マイページ画像
 */
import Vue from 'vue'
import * as httpConst from '../../../../constants/httpConst'

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/my_pages`

const my_page_image = {
    namespaced: true,
    state: {
        product_images: []
    },
    actions: {
        create( { commit}, { my_page_id, params } ) {

            const api_path = `${API_PATH}/${my_page_id}/images`

            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(api_path, params, { headers: { 'content-type': 'multipart/form-data' } }).then( data => {
                    // commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    // commit(types.BACK_PRODUCT_IMAGE_ADD, { content })
                    resolve(data.content)
                } )
            })
        },

    },
    mutations: {
    },
}

export default my_page_image