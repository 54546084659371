<!-- バックヤード > システムお知らせ詳細 -->
<template>
  <div v-if="announce">
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <v-card flat>
      <v-card-text>
        <v-card flat>
          <v-card-title>
            {{ announce.title }}
          </v-card-title>
          <v-card-subtitle>{{ announce.notice_date | date_format }}</v-card-subtitle>
          <v-card-text>
            <span class="body-2 line-break">{{ announce.description }}</span>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  components: {
  },
  mixins: [
  ],
  props: {
    fromIndex: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      breadcrumbs: [
        { text: 'システムからのお知らせ', disabled: false, to: '/backyard/announcements', exact: true },
        { text: 'システムからのお知らせ詳細', disabled: true, to: '' },
      ],
      announce: null,
    }
  },
  created() {
    // 詳細取得
    this.showAnnounce({id: this.$route.params.id}).then(( content ) => {
      this.announce = content
      this.createRead({ announcement_id: this.$route.params.id })
    })
  },
  methods: {
    ...mapActions('backAnnouncement', {
      showAnnounce: 'show',
    }),
    ...mapActions('backReadAnnouncement', {
      createRead: 'create'
    })
  },
}
</script>

<style lang="scss">

</style>
