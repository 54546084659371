<template>
  <div v-if="isItems">
    <v-divider />
    <v-chip-group column multiple active-class="primary--text" v-model="targetItems" @change="changeItems">
      <v-chip filter outlined v-for="item in items" :key="item.id" :value="item.id">{{ item.name }}</v-chip>
    </v-chip-group>
  </div>
</template>

<script>

  /**
   * 検索条件
   * example
   * <chip-condition :items="[{id: 1, name: 'xxx'}]" :selected-items="selectedCategories" v-model="selectedCategories"/>
   */
  export default {
    name: 'ChipCondition',
    props: {
      items: {
        type: Array,
        default: () => [],
      },
      selectedItems: {
        type: Array,
        default: () => [],
      }
    },
    data () {
      return {
        targetItems: this.selectedItems
      }
    },
    computed: {
      isItems () {
        return this.items.length > 0
      }
    },
    methods: {
      changeItems () {
        this.$emit('input', this.targetItems)
      },
      updateSelectedItems (items) {
        // 親コンポーネントの beforeRouteUpdate から呼び出し
        this.targetItems = items
      }
    }
  }

</script>

<style scoped lang="scss">
</style>