<!-- バックヤード > マイページform -->
<template style="position: relative;">
  <div>
    <alert-message />
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form v-if="myPage">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|max:50"
                      name="タイトル"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="myPage.title"
                        label="タイトル"
                        dense
                        hide-details="auto"
                        outlined
                        required
                        counter="50"
                        placeholder=" "
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      rules="required|alpha_dash|max:50"
                      name="パーマリンク（URL）"
                      class="requiredMark"
                    >
                      <v-text-field
                        v-model="myPage.post_name"
                        label="パーマリンク（URL）"
                        dense
                        hide-details="auto"
                        outlined
                        required
                        counter="50"
                        placeholder=" "
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>

                <!-- メタタグ -->
                <v-row v-if="myPage.description_meta_tag_attributes">
                  <v-col cols="12">
                    <h4>独自メタ情報</h4>
                    <v-card outlined>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            md="1"
                            :class="$vuetify.breakpoint.smAndDown ? '' : 'text-right'"
                          >
                            {{ myPage.description_meta_tag_attributes.meta_name }}
                          </v-col>
                          <v-col
                            cols="12"
                            md="11"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              rules="max:140"
                              name="説明"
                            >
                              <v-text-field
                                v-model="myPage.description_meta_tag_attributes.content"
                                outlined
                                counter="140"
                                label=""
                                dense
                                hide-details="auto"
                                placeholder=" "
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row><!-- /メタタグ -->

                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="表示順"
                      rules="required|numeric|min_value:0|max_value:2147483647"
                    >
                      <v-text-field
                        v-model="myPage.position"
                        label="表示順"
                        dense
                        reverse
                        hide-details="auto"
                        outlined
                        placeholder=" "
                        required
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-checkbox
                      v-model="myPage.display_footer"
                      label="フッターに表示する"
                      hide-details="auto"
                      class="pt-2 ma-0"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:65535"
                      name="詳細"
                    >
                      <div>
                        <vue-editor
                          v-model="myPage.description"
                          :error-messages="errors"
                          class="custom-quill-container"
                          useCustomImageHandler
                          @image-added="handleImageAdded"
                        />
                      </div>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  color="error"
                  @click="deleteButtonClickEvent"
                >
                  削除
                </v-btn>
                <v-spacer />
                <v-btn @click="cancelButtonClickEvent">
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="registerButtonClickEvent"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <confirm ref="confirm" />
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import AlertMessage from '../../../components/AlertMessage'
import Confirm from '../../../components/Confirm'

export default {
  name: 'MyPageForm',
  components: {
    AlertMessage, Confirm
  },
  mixins: [],
  props: {
  },
  data () {
    return {
      myPage: {},
    }
  },
  created() {
    this.fetch({ id: this.$route.params.id }).then((content) => {
      this.myPage = content
    })
  },
  methods: {
    ...mapActions('backMyPage', {
      fetch: 'show',
      updateMyPage: 'update',
      deleteMyPage: 'destroy'
    }),
    ...mapActions('backMyPageImage', {
      createImage: 'create'
    }),
    /**
     * ファイル選択イベント
     * @param file
     * @param Editor
     * @param cursorLocation
     * @param resetUploader
     */
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append('file', file);
      this.createImage({my_page_id: this.myPage.id, params: formData}).then((content) => {
        Editor.insertEmbed(cursorLocation, 'image', content.url);
        resetUploader();
      })
    },
    /**
     * キャンセルボタンクリック イベント
     */
    cancelButtonClickEvent () {
      this.goToIndex()
    },
    /**
     * 保存ボタンクリック イベント
     */
    registerButtonClickEvent () {
      this.updateMyPage({ id: this.myPage.id, params: this.myPage }).then(() => {
        this.goToIndex()
      })
    },
    /**
     * 削除ボタンクリック イベント
     */
    deleteButtonClickEvent () {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.deleteMyPage( { id: this.myPage.id }).then(() => {
            this.goToIndex()
          })
        }
      })
    },
    goToIndex() {
      this.$router.push({name: 'byMyPageIndex'})
    }
  },
}

</script>

<style scoped lang="scss">
.custom-quill-container {
  /deep/ {
    #quill-container {
      height: 40vw;
    }
  }
}
</style>
