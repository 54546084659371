<!-- バックヤード > システムお知らせ一覧 -->
<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>
          システムからのお知らせ
        </h2>
      </v-col>
    </v-row>

    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>検索</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card flat>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="keyword"
                  dense
                  hide-details="auto"
                  label="キーワード"
                  outlined
                  placeholder="タイトル、本文部分一致"
                />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="resetResult"
              >
                検索
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    お知らせ日
                  </th>
                  <th class="text-left">
                    タイトル
                  </th>
                  <th>
                    表示期間
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="announce in announcements"
                  :key="announce.id"
                >
                  <td>{{ announce.notice_date | date_format }}</td>
                  <td>
                    {{ announce.title }}
                  </td>
                  <td>{{ announce.display_start_date | from_to_date_format(announce.display_end_date) }}</td>
                  <td class="text-right">
                    <v-btn
                      icon
                      color="cyan darken-2"
                      @click="systemAnnounceShow(announce.id)"
                    >
                      <v-icon>mdi-file-edit-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-pagination
      v-if="hasAnnounces"
      v-model="page"
      :length="pagination.total_pages"
      circle
      prev-icon="mdi-menu-left"
      next-icon="mdi-menu-right"
      :total-visible="totalVisible"
      @input="changePage"
    />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import {mapActions, mapGetters, mapState} from "vuex";

  export default {
    components: {
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        keyword: null,
        page: 1,
        totalVisible: 7,
        announcements:[],
        index: true
      }
    },

    computed: {
      // state参照
      ...mapState('backAnnouncement', {
        pagination: 'pagination',
        searchQuery: 'query',
      }),
      ...mapGetters('backAnnouncement', [
        'hasAnnounces'
      ]),
    },

    created() {
      // 検索条件
      this.keyword = this.searchQuery.keyword
      this.page = this.pagination.current_page
      // 一覧取得
      this.searchAnnounces()
    },

    methods: {

      ...mapActions('backAnnouncement', {
        // 一覧取得

        // モジュール参照
        fetchAnnounces: 'fetchAll',
        fetchQuery: 'fetchQuery',
      }),

      // 検索
      resetResult () {
        this.page = 1
        this.searchAnnounces()
      },

      // ページャー
      changePage () {
        this.searchAnnounces()
      },

      // 一覧取得
      searchAnnounces () {
        const params = {
          keyword: this.keyword,
          page: this.page
        }
        this.fetchAnnounces(params).then((content) => {
          this.announcements = content.announcements
        })
      },
      // 詳細遷移
      systemAnnounceShow (announcement_id) {
        this.$router.push({name: 'byAnnouncementShow', params: {id: announcement_id, from_index: this.index }})
      },

    }
  }
</script>

<style lang="scss">

</style>
