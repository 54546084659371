<!-- バックヤード > 商品詳細 > 関連商品 -->
<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="text-right">
              <v-btn
                class="mb-2"
                small
                @click="openItemSearchDialog"
              >
                追加
              </v-btn>
            </div>
            <v-card outlined>
              <v-data-table
                v-if="productRelations"
                :headers="tableHeaders"
                :items="productRelations"
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.image_url="{ item }">
                  <v-img
                    :src="item.image_url!=null? item.image_url:''"
                    max-height="40"
                    max-width="100"
                    contain
                  />
                </template>
                <template v-slot:item.price="{ item }">
                  {{ item.price | comma_format }} 円
                </template>
                <template v-slot:item.display_period_date="{ item }">
                  {{ item.display_period_start_date | from_to_date_format(item.display_period_end_date) }}
                </template>
                <template v-slot:item.category_names="{ item }">
                  <span
                    class="wrap_text"
                    style="width: 240px;"
                  >
                    {{ item.category_names }}
                  </span>
                </template>
                <template v-slot:item.editable="{ item }">
                  <v-btn
                    v-if="$vuetify.breakpoint.mdAndUp"
                    icon
                    color="error"
                    @click="relationDeleteButtonClickEvent(item)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    color="error"
                    small
                    @click="relationDeleteButtonClickEvent(item)"
                  >
                    削除する
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="isProductRelations"
            cols="12"
          >
            <v-pagination
              v-model="page"
              :length="pagination.total_pages"
              :total-visible="totalVisible"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              circle
              @input="changePage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <item-search-dialog
      ref="ItemSearchDialog"
      @itemSearchDialogCallback="itemSearchDialogCallback"
    />
    <confirm ref="confirm" />
  </div>
</template>

<script>
import Confirm from '../../../../components/Confirm'
import ItemSearchDialog from '../../../dialogs/ItemSearchDialog'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    ItemSearchDialog,
    Confirm,
  },
  mixins: [
  ],
  props: {
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data () {
    return {
      tableHeaders: [
        { value: 'image_url', text: '', align: 'start' },
        { value: 'part_number_1', text: '品番1' },
        { value: 'name', text: '商品名' },
        { value: 'category_names', text: 'カテゴリー' },
        { value: 'price', text: '金額', align: 'end' },
        { value: 'display_period_date', text: '表示期間', align: 'center' },
        { value: 'editable', text: '', align: 'end' },
      ],
      page: 1,
      totalVisible: 7,
    }
  },
  computed : {
    ...mapState('backProductRelation', {
      productRelations: 'product_relations',
      pagination: 'pagination',
    }),
    ...mapGetters('backProductRelation', [
      'isProductRelations'
    ])
  },
  watch: {
  },
  created () {
    this.page = this.pagination.current_page
    this.fetchProductRelations({ product_id: this.product.id })
  },
  methods: {
    ...mapActions('backProductRelation', {
      fetchProductRelations: 'fetchAll',
      createProductRelation: 'create',
      destroyProductRelation: 'destroy'
    }),
    openItemSearchDialog () {
      this.$refs.ItemSearchDialog.openDialog({exclude_product_id: this.product.id})
    },
    itemSearchDialogCallback (items) {
      const parameter = { relation_ids: items }
      this.createProductRelation({ product_id: this.product.id, params: parameter }).then(() => {
        this.fetchProductRelations({ product_id: this.product.id })
      })
    },
    relationDeleteButtonClickEvent (product_relation) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.destroyProductRelation({ product_id: this.product.id, params: product_relation }).then(() => {
            this.fetchProductRelations({ product_id: this.product.id })
          })
        }
      })
    },
    changePage () {
      this.fetchProductRelations({product_id: this.product.id, page: this.page})
    },

  },
}
</script>

<style scoped lang="scss">
.wrap_text {
  display: inline-block;
  overflow-wrap: break-word;
}
</style>

