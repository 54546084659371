<!-- バックヤード > ヘッダー -->
<template>
  <div>
    <v-app-bar
      app
      color="#4fd2c2"
      dense
      dark
      fixed
    >
      <v-app-bar-nav-icon
        v-if="alive"
        @click.stop="left = !left"
      />
      <router-link
        v-if="loggedIn"
        to="/backyard/home"
        style="text-decoration: none;"
      >
        <v-toolbar-title
          class="white--text"
        >
          bizRENT
        </v-toolbar-title>
      </router-link>

      <v-spacer />

      <v-menu
        v-if="alive"
        v-model="showMenu"
        style="max-width: 400px"
      >
        <template
          v-slot:activator="{ on, attrs }"
        >
          <v-list-item-avatar
            color="primary"
          >
            <span
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              {{ avatarName }}
            </span>
          </v-list-item-avatar>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in userMenus"
            :key="index"
            @click="callDialog(item)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-if="loggedIn"
        icon
        @click="helpLink"
      >
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>

      <v-btn
        v-if="loggedIn"
        icon
        @click="logout"
      >
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-if="alive"
      v-model="left"
      fixed
      temporary
      width="280"
    >
      <v-list dense>
        <v-list-item
          :class="{headerMenuBack: activeMenu === 'home'}"
          :disabled="activeMenu === 'home'"
          @click="menuLink('byHomeIndex')"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>HOME</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-group color="indigo">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>サイト管理</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'themeOption'}"
            :disabled="activeMenu === 'themeOption'"
            @click="menuLink('themeOptionEdit')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-storefront-outline</v-icon>
                SHOP画面設定
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'credit'}"
            :disabled="activeMenu === 'credit'"
            @click="menuLink('CreditEdit')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-heart</v-icon>
                会社情報／特定商取引設定
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'myPages'}"
            :disabled="activeMenu === 'myPages'"
            @click="menuLink('byMyPageIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-page-layout-body</v-icon>
                フリーページ
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider />

        <v-list-group color="indigo">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-bell</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>お知らせ管理</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'news'}"
            :disabled="activeMenu === 'news'"
            @click="menuLink('byNewsIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-alert-rhombus</v-icon>
                ニュース登録
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider />

        <v-list-group color="indigo">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-database</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>商品管理</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'categories'}"
            :disabled="activeMenu === 'categories'"
            @click="menuLink('CategoryIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-group</v-icon>
                カテゴリーマスタ
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'tags'}"
            :disabled="activeMenu === 'tags'"
            @click="menuLink('TagIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-bookmark-check-outline</v-icon>
                タグマスタ
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'local_places'}"
            :disabled="activeMenu === 'local_places'"
            @click="menuLink('LocalPlaceIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-map-marker-outline</v-icon>
                現地マスタ
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'option_groups'}"
            :disabled="activeMenu === 'option_groups'"
            @click="menuLink('byOptionGroupIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-plus-box-multiple-outline</v-icon>
                オプショングループ
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-list-item
            :class="{headerMenuBack: activeMenu === 'products'}"
            :disabled="activeMenu === 'products'"
            @click="menuLink('byProductIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-diamond-stone</v-icon>
                商品登録
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'campaigns'}"
            :disabled="activeMenu === 'campaigns'"
            @click="menuLink('CampaignIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-bullhorn-outline</v-icon>
                {{ company_mode_caption_name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'product_stocks'}"
            :disabled="activeMenu === 'product_stocks'"
            @click="menuLink('byProductStockIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-clipboard-list-outline</v-icon>
                在庫一覧
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider />

        <v-list-item
          :class="{headerMenuBack: activeMenu === 'members'}"
          :disabled="activeMenu === 'members'"
          @click="menuLink('byMemberIndex')"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>会員管理</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-group color="indigo">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-account-network</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>運用管理</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'orders'}"
            :disabled="activeMenu === 'orders'"
            @click="menuLink('byOrderIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-briefcase-check-outline</v-icon>
                受注管理&emsp;
                <v-badge
                  v-if="undispatchedCount > 0"
                  color="lime accent-4"
                  :content="undispatchedCount"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'rental_schedules'}"
            :disabled="activeMenu === 'rental_schedules'"
            @click="menuLink('byRentalScheduleIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-clock-outline</v-icon>
                スケジュール&emsp;
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <template v-if="use_subscription">
            <v-list-item
              :class="{headerMenuBack: activeMenu === 'subscriptions'}"
              :disabled="activeMenu === 'subscription'"
              @click="menuLink('bySubscriptionIndex')"
            >
              <v-list-item-content>
                <v-list-item-title>
                  &emsp;&emsp;
                  <v-icon>mdi-refresh</v-icon>
                  定期課金管理&emsp;
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'send_backs'}"
            :disabled="activeMenu === 'send_backs'"
            @click="menuLink('bySendBackIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-keyboard-return</v-icon>
                返却管理
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'product_reviews'}"
            :disabled="activeMenu === 'product_reviews'"
            @click="menuLink('byProductReviewIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-star-outline</v-icon>
                商品レビュー
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'inquiries'}"
            :disabled="activeMenu === 'inquiries'"
            @click="menuLink('byInquiryIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-comment-alert-outline</v-icon>
                店舗への問い合わせ&emsp;
                <v-badge
                  v-if="unclosedCount > 0"
                  color="orange lighten-2"
                  :content="unclosedCount"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'qa'}"
            :disabled="activeMenu === 'qa'"
            @click="menuLink('byQaIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-comment-question-outline</v-icon>
                商品への問い合わせ&emsp;
                <v-badge
                  v-if="unansweredCount > 0"
                  color="lime accent-4"
                  :content="unansweredCount"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            :class="{headerMenuBack: activeMenu === 'announcements'}"
            :disabled="activeMenu === 'announcements'"
            @click="menuLink('byAnnouncementIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-coach-lamp</v-icon>
                システムからのお知らせ
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider />

        <v-list-group color="indigo">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-home-city</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>運営者情報</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'contract'}"
            :disabled="activeMenu === 'contract'"
            @click="menuLink('byContractIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-clipboard-check</v-icon>
                契約情報
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'users'}"
            :disabled="activeMenu === 'users'"
            @click="menuLink('byUserIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-account-box-multiple</v-icon>
                ユーザー管理
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'configuration'}"
            :disabled="activeMenu === 'configuration'"
            @click="menuLink('byConfigurationIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-cog</v-icon>
                システム設定
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'deliveryDesiredTimes'}"
            :disabled="activeMenu === 'deliveryDesiredTimes'"
            @click="menuLink('byDeliveryDesiredTimeIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-clock-time-three-outline</v-icon>
                配送希望・時間 管理
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'deliveryCompanies'}"
            :disabled="activeMenu === 'deliveryCompanies'"
            @click="menuLink('byDeliveryCompanyIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-truck-outline</v-icon>
                配送業者・管理
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :class="{headerMenuBack: activeMenu === 'deliveryMethods'}"
            :disabled="activeMenu === 'deliveryMethods'"
            @click="menuLink('byDeliveryMethodIndex')"
          >
            <v-list-item-content>
              <v-list-item-title>
                &emsp;&emsp;
                <v-icon>mdi-cube-send</v-icon>
                配送方法・送料 管理
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider />
      </v-list>
    </v-navigation-drawer>

    <ChangePasswordDialog ref="changePasswordDialog" />
  </div>
</template>

<script>
  import ChangePasswordDialog from "../dialogs/ChangePasswordDialog"
  import {mapActions, mapGetters, mapState} from "vuex";

  export default {
    components: {
      ChangePasswordDialog
    },
    data: () => ({
      showMenu: false,
      left: false,
      supportInfo: false,
      company_mode_caption_name: 'キャンペーン',
      use_subscription: false,
      userMenus: [
        {
          title: 'パスワード変更',
          type: 'change_password'
        },
      ]
    }
    ),
    computed: {
      ...mapGetters('backyardAuth', [
        'avatarName',
         'alive'
      ]),
      ...mapState('backProductQuestion', {
        unansweredCount: 'unansweredCount'
      }),
      ...mapState('backTicket', {
        unclosedCount: 'unclosedCount',
      }),
      ...mapState('backOrder', {
        undispatchedCount: 'undispatchedCount'
      }),
      activeMenu: function () {
        let path = this.$route.path
        console.log(path)
        if (path.includes('/backyard/products')) {
          return 'products'
        } else if (path.includes('/backyard/campaigns')) {
          return 'campaigns'
        } else if (path.includes('/backyard/product_stocks')) {
          return 'product_stocks'
        } else if (path.includes('/backyard/qa')) {
          return 'qa'
        } else if (path.includes('/backyard/inquiries')) {
          return 'inquiries'
        } else if (path.includes('/backyard/orders')) {
          return 'orders'
        } else if (path.includes('/backyard/rentalSchedules')) {
          return 'rental_schedules'
        } else if (path.includes('/backyard/sendBacks')) {
          return 'send_backs'
        } else if (path.includes('/backyard/members')) {
          return 'members'
        } else if (path.includes('/backyard/news')) {
          return 'news'
        } else if (path.includes('/backyard/noticeMembers')) {
          return 'noticeMembers'
        } else if (path.includes('/backyard/frontSetting')) {
          return 'frontSetting'
        } else if (path.includes('/backyard/themeOption')) {
          return 'themeOption'
        } else if (path.includes('/backyard/credit')) {
          return 'credit'
        } else if (path.includes('/backyard/categories')) {
          return 'categories'
        } else if (path.includes('/backyard/tags')) {
          return 'tags'
        } else if (path.includes('/backyard/local_places')) {
          return 'local_places'
        } else if (path.includes('/backyard/option_groups')) {
          return 'option_groups'
        } else if (path.includes('/backyard/deliveryCompanies')) {
          return 'deliveryCompanies'
        } else if (path.includes('/backyard/deliveryMethods')) {
          return 'deliveryMethods'
        } else if (path.includes('/backyard/templateMails')) {
          return 'templateMails'
        } else if (path.includes('/backyard/templateDocuments')) {
          return 'templateDocuments'
        } else if (path.includes('/backyard/contract')) {
          return 'contract'
        } else if (path.includes('/backyard/users')) {
          return 'users'
        } else if (path.includes('/backyard/configuration')) {
          return 'configuration'
        } else if (path.includes('/backyard/systemInquiries')) {
          return 'systemInquiries'
        } else if (path.includes('/backyard/announcements')) {
          return 'announcements'
        } else if (path.includes('/backyard/myPages')) {
          return 'myPages'
        } else if (path.includes('/backyard/product_reviews')) {
          return 'product_reviews'
        } else if (path.includes('/backyard/delivery_desired_times')) {
          return 'deliveryDesiredTimes'
        }
        return 'home'
      },
      ...mapState('backyardAuth', {
        loggedIn: 'logged_in'
      }),
      isLogin: function () {
        return this.loggedIn
      }
    },
    created() {
      if (this.alive) {
        this.fetchUnclosedCount()
        this.fetchUnansweredCount()
        this.fetchUndispatchedCount()
        this.searchCompany()
      }
    },
    methods: {
      ...mapActions('backyardAuth', {
        signOut: 'signOutBackyard',
      }),
      ...mapActions('backProductQuestion', {
        fetchUnansweredCount: 'fetchUnansweredCount',
      }),
      ...mapActions('backTicket', {
        fetchUnclosedCount: 'fetchUnclosedCount',
      }),
      ...mapActions('backOrder', {
        fetchUndispatchedCount: 'fetchUndispatchedCount'
      }),
      ...mapActions('backHomeCompany', {
        find: 'find',
      }),

      callDialog (item) {
        if (item.type === 'change_password') {
          this.$refs.changePasswordDialog.openDialog({})
        }
      },
      selfEdit () {
        this.$router.push({name: 'byUserEditIndex'})
      },
      menuLink (menu) {
        this.$router.push({ name: menu, params: { isClearCondition: true } })
      },
      helpLink () {

        //window.open( 'https://www.google.com/?hl=ja', "_blank" )

        let path = this.$route.path
        if (path.includes('/backyard/home')) {
          window.open( 'https://document.bizrent.biz/?p=461', "_blank" )
        } else if (path.includes('/backyard/themeOption/edit')) {
          window.open( 'https://document.bizrent.biz/?p=430', "_blank" )
        } else if (path.includes('/backyard/credit/edit')) {
          window.open( 'https://document.bizrent.biz/?p=439', "_blank" )
        } else if (path.includes('/backyard/myPages')) {
          window.open( 'https://document.bizrent.biz/?p=448', "_blank" )
        } else if (path.includes('/backyard/news')) {
          window.open( 'https://document.bizrent.biz/?p=317', "_blank" )
        } else if (path.includes('/backyard/categories')) {
          window.open( 'https://document.bizrent.biz/?p=282', "_blank" )
        } else if (path.includes('/backyard/tags')) {
          window.open( 'https://document.bizrent.biz/?p=298', "_blank" )
        } else if (path.includes('/backyard/option_groups')) {
          window.open( 'https://document.bizrent.biz/?p=488', "_blank" )
        } else if (path.includes('/backyard/products')) {
          window.open( 'https://document.bizrent.biz/?p=290', "_blank" )
        } else if (path.includes('/backyard/campaigns')) {
          window.open( 'https://document.bizrent.biz/?p=307', "_blank" )
        } else if (path.includes('/backyard/members')) {
          window.open( 'https://document.bizrent.biz/?p=466', "_blank" )
        } else if (path.includes('/backyard/orders')) {
          window.open( 'https://document.bizrent.biz/?p=513', "_blank" )
        } else if (path.includes('/backyard/sendBacks')) {
          window.open( 'https://document.bizrent.biz/?p=537', "_blank" )
        } else if (path.includes('/backyard/inquiries')) {
          window.open( 'https://document.bizrent.biz/?p=546', "_blank" )
        } else if (path.includes('/backyard/qas')) {
          window.open( 'https://document.bizrent.biz/?p=555', "_blank" )
        } else if (path.includes('/backyard/announcements')) {
          window.open( 'https://document.bizrent.biz/?p=560', "_blank" )
        } else if (path.includes('/backyard/contract')) {
          window.open( 'https://document.bizrent.biz/?p=43', "_blank" )
        } else if (path.includes('/backyard/users')) {
          window.open( 'https://document.bizrent.biz/?p=57', "_blank" )
        } else if (path.includes('/backyard/configuration')) {
          window.open( 'https://document.bizrent.biz/?p=154', "_blank")
        } else if (path.includes('/backyard/delivery_desired_times')) {
          window.open( 'https://document.bizrent.biz/?p=952', "_blank")
        } else if (path.includes('/backyard/deliveryCompanies')) {
          window.open( 'https://document.bizrent.biz/?p=208', "_blank" )
        } else if (path.includes('/backyard/deliveryMethods')) {
          window.open( 'https://document.bizrent.biz/?p=278', "_blank" )
        }

      },
      logout () {
        this.signOut().then(() => {
          this.$router.push({name: 'bySessionIndex'})
        })
      },
      searchCompany () {
        this.find().then((content) => {
          this.company_mode_caption_name = content.company_mode_caption_name
          this.use_subscription = content.use_subscription
        })
      }

    }
  }
</script>

<style scoped>

  .headerMenuBack {
    background: #157469;
    color:#fff !important;
  }

  .headerMenuBack .theme--light.v-icon {
    color:#fff !important;
  }


</style>
