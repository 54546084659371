/**
 * システム設定
 */
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";
import Vue from 'vue'
import store from 'store'

const getSystemMode = () => {
    return (store.get('system_mode'))
}

const getCustomerRegType= () => {
    return (store.get('customer_reg_type'))
}

const hasAuctionMode = () => {
    return (getSystemMode() === 'auction')
}

const hasBackendCustomerInput = () => {
    return (getCustomerRegType() === 'back_input' || getCustomerRegType() === 'front_back_input')
}

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/system_setting`

const system_setting = {
    namespaced: true,
    state: {
        system_mode: getSystemMode(),
        customer_reg_type: getCustomerRegType(),
        auction_mode: hasAuctionMode(),
        backend_customer_input: hasBackendCustomerInput(),

    },
    actions: {
        find ({ commit }) {
            return new Promise((resolve) => {
                Vue.$http.get(API_PATH).then( data => {
                    const system_setting = data.content.system_setting
                    commit(types.BACK_SYSTEM_SETTING, { system_setting })
                    resolve(system_setting)
                })
            })
        },
    },
    mutations: {
        [types.BACK_SYSTEM_SETTING] (state, { system_setting }) {
            state.system_mode = system_setting.mode
            state.customer_reg_type = system_setting.customer_reg_type
            // localStorageに保持
            store.set('system_mode', state.system_mode)
            store.set('customer_reg_type', state.customer_reg_type)
        },
    },
}

export default system_setting
