<template>
  <div>
    <v-row
      v-if="otherProducts"
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <h3>{{ title }}</h3>
        <v-divider class="mt-2" />
      </v-col>
    </v-row>
    <v-row
      v-if="otherProducts"
      align="center"
    >
      <v-col cols="1" class="py-0 pr-0 pl-1 text-center">
        <v-btn
          icon
          small
          @click="scrollToLeft()"
        >
          <v-icon>mdi-menu-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="10" class="px-0">
        <div class="overflow-x-auto scroll-box">
          <v-card
            class="d-flex align-center"
            flat
            tile
          >
            <v-hover
              v-for="item in otherProducts"
              v-slot:default="{ hover }"
              :key="item.id"
            >
              <v-card
                tile
                :elevation="hover ? 12 : 2"
                class="pa-2 ma-2"
                align="center"
                @click="showProduct(item)"
              >
                <v-img
                  contain
                  height="125px"
                  width="125px"
                  :src="item.image_url | load_image_url"
                  :lazy-src="item.image_url | load_image_url"
                  aspect-ratio="1"
                  class="white lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      />
                    </v-row>
                  </template>
                </v-img>
                <v-card-subtitle
                  class="pb-0"
                  align="center"
                >
                  {{ item.name }}
                </v-card-subtitle>
                <v-card-text
                  class="text--primary"
                  align="center"
                  style="min-height: 37px"
                >
                  <div v-if="!item.in_stock">在庫なし</div>
                  <div v-else>
                    <div v-if="!item.changed_rental_period">{{ item.in_tax_price | in_tax_yen_format(item.tax_exempt) }}</div>
                  </div>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-card>
        </div>
      </v-col>
      <v-col cols="1" class="py-0 pr-1 pl-0 text-center">
        <v-btn
          icon
          small
          @click="scrollToRight()"
        >
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  import ScrollBoxMixin from '../../../mixins/frontend/scroll-box-mixin'

  export default {
    name: 'OtherProduct',
    props: {
      title: {
        type: String,
        default: null,
      },
      otherProducts: {
        type: Array,
        default: () => [],
      }
    },
    mixins:[
      ScrollBoxMixin
    ],
    data () {
      return {
      }
    },
    created() {
    },
    methods: {
      showProduct (product) {
        // 商品詳細を再取得（beforeRouteUpdate で再取得)
        if (product.mode == 'rental') {
          this.$router.push({name: 'ProductShow', params: { id: product.id, productName: product.name}})
        } else {
          this.$router.push({name: 'ProductShowAuction', params: { id: product.id, productName: product.name}})
        }
      },
    }
  }
</script>

<style scoped lang="scss">
</style>