<!-- バックヤード > 契約情報 -->
<template>
  <div>
    <h2>
      契約情報
    </h2>
    <PaymentRequest :key="reload" />
    <v-card flat>
      <v-card-text>
        <v-row
          v-for="(item, index) in tradeLawItems"
          :key="index"
        >
          <v-col
            cols="12"
            sm="3"
            class="grey lighten-3"
          >
            <span class="font-weight-bold">{{ item.title }}</span>
          </v-col>
          <v-col
            v-if="!item.btn"
            cols="12"
            sm="9"
          >
            <template v-if="Array.isArray(item.text)">
              <div v-for="(member, index) in item.text" :key="index">
                {{ member }}
              </div>
            </template>
            <template v-else>
              {{ item.text }}
            </template>
          </v-col>
          <v-col
            v-else
            cols="12"
            sm="9"
          >
            <v-btn
              text
              target="_blank"
              color="primary"
              style="text-transform: none"
              class="pl-0"
              @click="moveTo(item.text)"
            >
              <v-icon>mdi-newspaper-variant-multiple</v-icon>
              &nbsp;{{ item.text }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            class="px-0 py-2"
          >
            <v-divider />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row v-if="useCreditCard">
      <v-col>
        <v-card>
          <v-card-title>
            <h3 style="width: 100%">
              申請状況&nbsp;{{ shopStatus }}
              <span class="float-right">
                <v-btn
                  v-if="afterApply"
                  color="teal lighten-3"
                  @click="applyCardBrandLinkClickEvent"
                >
                  カード申請
                </v-btn>

                <v-btn
                  v-if="showApplyButton"
                  color="teal lighten-3"
                  @click="completedApplyButtonClickEvent"
                >
                  開通依頼
                </v-btn>
              </span>
            </h3>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="applyTableHeaders"
              :items="applyResults"
              :items-per-page="-1"
              disable-sort
              hide-default-footer
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <Confirm ref="confirm" />
  </div>
</template>

<script>

import { mapActions } from 'vuex'
import Confirm from "../../../components/Confirm";
import PaymentRequest from "../../components/PaymentRequest";
import moment from "moment";

export default {
  components: {
    Confirm, PaymentRequest
  },
  mixins: [
  ],
  data () {
    return {
      applyTableHeaders: [
        { value: 'brand', text: 'カードブランド', align: 'start' },
        { value: 'status_name', text: '審査ステータス' },
        { value: 'available_at', text: '利用可能開始日時' },
      ],
      contracts: '',
      tradeLawItems: [],
      applyResults:[],
      showApplyButton: false,
      afterApply: false,
      shopStatus: '',
      useCreditCard: false,
      reload: null
    }
  },
  created() {
    this.show().then((content) => {
      /**
       * @typedef content {Object}
       * @property kana_name {String}
       * @property contract_start_date {String}
       * @property contract_end_date {String}
       * @property settlement_opening_at {String}
       */
      this.contracts = content
       let paymentTypes = []
      if (this.contracts.use_credit_card) {
        paymentTypes.push('カード決済')
      }
      if (this.contracts.use_bank_transfer) {
        paymentTypes.push("銀行振込")
      }
      if (this.contracts.use_cash_on_delivery) {
        paymentTypes.push("代引き")
      }

      let bankInfo = [];
      if (this.contracts.bank_account) {
        bankInfo.push("銀行名: " + this.contracts.bank_account.bank_name)
        bankInfo.push("支店名: " + this.contracts.bank_account.branch_name)
        bankInfo.push("口座種別: " + this.contracts.bank_account.bank_account_type)
        bankInfo.push("口座番号: " + this.contracts.bank_account.bank_account_number)
        bankInfo.push("口座名義人: " + this.contracts.bank_account.bank_account_holder_name)
      }

      this.tradeLawItems = [
        {title: '会社名', text: this.contracts.name},
        {title: '会社名カナ', text: this.contracts.kana_name},
        {title: '住所', text: `〒 ${this.contracts.zip_code} ${this.contracts.address}`},
        {title: '電話番号', text: this.contracts.phone},
        {title: 'FAX', text: this.contracts.fax},
        {title: 'メールアドレス', text: this.contracts.email},
        {title: 'URL', text: this.contracts.url},
        { title: "フロントエンド認証", text: this.contracts.use_frontend_auth ? "利用する" : "利用しない"},
        { title: "会員登録方法", text: this.contracts.customer_reg_type },
        { title: "定期課金使用", text: this.contracts.use_subscription ? "使用する" : "使用しない" },
        { title: "販売使用", text: this.contracts.use_sale ? "使用する" : "使用しない" },
        { title: "決済方法", text: paymentTypes },
        { title: "振込先口座情報", text: bankInfo },
        {title: '契約期間', text: `${ this.dateFormat(this.contracts.contract_start_date) } 〜 ${ this.dateFormat(this.contracts.contract_end_date) }`} ,
        {title: '店舗URL', text: this.contracts.store_url, btn: 'true' },
        {title: '店舗開通日', text: `${ this.dateFormat(this.contracts.settlement_opening_at)}`},
      ]
    })
    this.showPayment()
  },

  methods: {
    ...mapActions('backContract', {
      show: 'show',
    }),
    ...mapActions('backPaymentRequest', {
      showPaymentRequest: 'fetch',
      sendPaymentRequest: 'create',
    }),
    // PAY.JP申請URL発行
    ...mapActions('backHomeGenerateApplyUrl', {
      buildUrl: 'create'
    }),
    showPayment () {
      this.showPaymentRequest().then((content) => {
        /**
         * @typedef content {Object}
         * @property apply_results { Array }
         * @property after_apply_flag { Boolean }
         * @property show_apply_button_flag { Boolean }
         * @property use_credit_card { Boolean }
         * @property shop_status { String }
         */
        this.applyResults = content.apply_results
        this.afterApply = content.after_apply_flag
        this.showApplyButton = content.show_apply_button_flag
        this.useCreditCard = content.use_credit_card
        if (content.shop_status === 'requesting') {
          this.shopStatus = '依頼中'
        } else if (content.shop_status === 'opened'){
          this.shopStatus = 'ショップオープン'
        }
      })
    },
    dateFormat (value) {
      if (value === null) {
        return ''
      } else {
        return moment(value).format('YYYY/MM/DD')
      }
    },
    moveTo: function (item) {
      window.open( item, "_blank" )
    },
    completedApplyButtonClickEvent () {
      this.$refs.confirm.open({title: '開通依頼', message: '開通依頼を送信します。よろしいですか？', options: { width: 400 }}).then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          this.sendPaymentRequest().then(() => {
            this.showPayment ()
            // アンカー非表示用
            this.reload = ''
          })
        }
      })
    },

    applyCardBrandLinkClickEvent () {
      this.buildUrl().then((content) => {
        if (content.url) {
          window.open(content.url, '_blank')
        }
      })
    },

  },
}
</script>

<style lang="scss">
.strike-text {
  text-decoration: line-through;
}
</style>