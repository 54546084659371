<template>
  <div>
    <v-card class="ma-1">
      <v-img
        height="200px"
        contain
        :src="item.image_url | load_image_url"
        :lazy-src="item.image_url | load_image_url"
        aspect-ratio="1"
        class="white lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            />
          </v-row>
        </template>
      </v-img>
      <v-card-title class="pb-0">
        {{ item.name }}
      </v-card-title>
      <v-card-text class="text--primary py-2">
        <v-row dense>
          <v-col
            cols="12"
            class="py-0"
          >
            最低落札価格：{{ item.price | in_tax_yen_format(item.tax_exempt) }}
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            入札単位額：{{ item.unit_bid_amount | in_tax_yen_format(true)}}
          </v-col>
          <v-expand-transition>
            <v-row v-show="showOption">
              <v-col cols="12">
                <v-divider />
                <v-card-text>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th>オプション名</th>
                        <th
                          class="text-right"
                        >
                          金額
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="option in item.product_options"
                        :key="option.id"
                      >
                        <td>
                          <strong>{{ option.label }}</strong>
                          <br>
                          {{ option.name }}
                        </td>
                        <td style="text-align: end">
                          {{ option.price | in_tax_yen_format(option.tax_exempt) }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <v-divider />
              </v-col>
            </v-row>
          </v-expand-transition>
          <v-col
            v-if="!item.is_stock"
            cols="12"
          >
            在庫状況：<span style="color: red">在庫なし</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions v-if="isEdit">
        <v-btn
          text
          color="primary"
          @click="moveToProduct(item)"
        >
          <v-icon>mdi-pencil-outline</v-icon>編集
        </v-btn>
        <v-spacer />
        <v-btn
          text
          color="red"
          @click="onDelete(item)"
        >
          <v-icon>mdi-trash-can-outline</v-icon>削除
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm ref="confirm" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Confirm from './../../../../components/Confirm'
import CartStepperMixin from '../../../../mixins/frontend/cart-stepper-mixin'

export default {
  name: 'OrderProductAuction',
  components: {
    Confirm,
  },
  mixins: [
    CartStepperMixin
  ],
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isEdit: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showOption: false,
    }
  },
  created() {
  },
  methods: {
    ...mapActions('frontCart', {
      deleteCart: 'delete'
    }),
    moveToProduct (cart) {
      this.resetStep()
      this.$router.push({name: 'ProductShowAuction', params: { id: cart.product_id, cart: cart }})
    },
    onDelete (cart) {
      this.$refs.confirm.showDeleteConfirm().then((confirm) => {
        this.$refs.confirm.close()
        if (confirm) {
          // カート削除
          this.deleteCart({id: cart.id}).then(() => {
            // 金額計算、削除があるので再検索
            this.$emit('loadCart')
          })
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
</style>