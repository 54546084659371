<!-- バックヤード > 配送方法・送料編集 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <alert-message />
    <v-alert
      dense
      outlined
    >
      <p class="mb-0">
        「変動送料金額」を設定すると注文時の送料は、「変動送料」が適用されます。
      </p>
    </v-alert>
    <v-card flat>
      <v-card-text>
        <validation-observer v-slot="{ invalid }">
          <v-form>
            <v-card flat>
              <v-card-text>
                <h3>{{ prefectureName }}</h3>
                <v-row>
                  <v-col
                    cols="10"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="基本送料"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="postage"
                        :error-messages="errors"
                        dense
                        hide-details="auto"
                        label="基本送料"
                        outlined
                        placeholder=" "
                        prefix="円"
                        required
                        reverse
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="10"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="変動送料金額"
                      rules="numeric"
                    >
                      <v-text-field
                        v-model="changePostagePrice"
                        :error-messages="errors"
                        dense
                        hide-details="auto"
                        label="変動送料金額"
                        outlined
                        placeholder=" "
                        prefix="円"
                        reverse
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="2"
                    md="2"
                    align-self="center"
                  >
                    <div>以上</div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="10"
                    md="3"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      class="requiredMark"
                      name="変動送料"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="changePostage"
                        :error-messages="errors"
                        dense
                        hide-details="auto"
                        label="変動送料"
                        outlined
                        placeholder=" "
                        prefix="円"
                        required
                        reverse
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="onCancel">
                  キャンセル
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="invalid"
                  @click="submit"
                >
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AlertMessage from '../../../components/AlertMessage'
import {mapActions} from 'vuex'

export default {
  components: {
    AlertMessage,
  },
  data () {
    return {
      breadcrumbs: [
        { text: '配送方法・送料一覧', disabled: false, to: '/backyard/deliveryMethods', exact: true },
        { text: '配送方法・送料詳細', disabled: true, to: '' },
      ],
      deliveryMethodId: null,
      prefectureName: '',
      postage: 0,
      changePostagePrice: null,
      changePostage: 0,
    }
  },

  created () {
    this.deliveryMethodId = this.$route.params.id
    const params = {
      id: this.deliveryMethodId
    }
    /**
     * @typedef {Object} content
     * @property {string} prefecture_name
     * @property {number} postage
     * @property {number} change_postage_price
     * @property {number} change_postage
     */
    this.showDeliveryMethod(params).then((content) => {
      this.prefectureName = content.prefecture_name
      this.postage = content.postage
      this.changePostagePrice = content.change_postage_price
      this.changePostage = content.change_postage
    })
  },

  methods: {
    ...mapActions('backDeliveryMethod', {
      showDeliveryMethod: 'show',
      updateDeliveryMethod: 'update'
    }),
    buildParams () {
      return {
        postage: this.postage,
        change_postage_price: this.changePostagePrice,
        change_postage: this.changePostage
      }
    },
    onCancel: function () {
      this.$router.push({name: 'byDeliveryMethodIndex'})
    },
    submit: function() {
      const params = this.buildParams()
      this.updateDeliveryMethod({ id: this.deliveryMethodId, params }).then(() => {
        this.$router.push({name: 'byDeliveryMethodIndex'})
      })
    },
  },
}
</script>

<style lang="scss">

</style>
