<template>
  <v-snackbar
    v-model="isSnackbar"
    :color="color"
    :timeout="timeout"
  >
    <div
      v-for="(item, index) in messages"
      :key="`message_${index}`"
    >
<!--      {{ item }}-->
      <div v-html="item"></div>
    </div>
    <div
        v-if="isLoginBtn"
    >
      <a
          @click="loginButtonClickEvent"
      >
        ログイン画面に戻る
      </a> 
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="hideSnackbar"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

  import { mapState, mapActions } from 'vuex'

  /**
   * example
   * <snackbar />
   */
  export default {
    name: 'Snackbar',
    data () {
      return {
        timeout: 3000,
        isSnackbar: false,
        isLoginBtn: false,
        messages: [],
      }
    },
    computed: {
      ...mapState('snackbar', {
        isShow: 'isShow',
        isShowLoginBtn: 'isShowLoginBtn',
        message: 'message',
        color: 'color'
      }),
    },
    watch: {
      isShow (newVal, oldVal) {
        this.isSnackbar = newVal
      },
      message (newVal) {
        this.messages = (newVal === null) ? [] : newVal.split(',')
      },
      isSnackbar(newVal) {
        if (!newVal) this.hide()
      },
      isShowLoginBtn (newVal) {
        this.isLoginBtn = newVal
        this.timeout = (newVal) ? 10000 : 3000;
      },
    },
    methods: {
      ...mapActions('snackbar', {
        hide: 'hide'
      }),
      hideSnackbar () {
          this.hide()
      },
      loginButtonClickEvent () {
        this.$router.push({name: 'bySessionIndex'})
      },
    }
  }

</script>

<style scoped lang="scss">
</style>
