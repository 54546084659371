var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2",attrs:{"disabled":!_vm.canAddCustomerItem,"small":""},on:{"click":_vm.addButtonClickEvent}},[_vm._v("\n              追加\n            ")])],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[(_vm.customerItems)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.customerItems,"items-per-page":-1,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.required",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(item.required ? '必須' : '')+"\n              ")]}},{key:"item.editable",fn:function(ref){
var item = ref.item;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"icon":"","color":"cyan darken-2"},on:{"click":function($event){return _vm.editButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1):_c('v-btn',{attrs:{"color":"info","small":""},on:{"click":function($event){return _vm.editButtonClickEvent(item)}}},[_vm._v("\n                  編集する\n                ")]),_vm._v(" "),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.deleteButtonClickEvent(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteButtonClickEvent(item)}}},[_vm._v("\n                  削除する\n                ")])]}}],null,false,1912680280)}):_vm._e()],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"450","persistent":"","no-click-animation":""},model:{value:(_vm.showCustomerItemDialog),callback:function ($$v) {_vm.showCustomerItemDialog=$$v},expression:"showCustomerItemDialog"}},[_c('v-form',[_c('alert-message'),_vm._v(" "),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_vm._v("会員汎用項目設定")]),_vm._v(" "),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|max:30","name":"タイトル"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"タイトル","dense":"","hide-details":"auto","outlined":"","clearable":"","placeholder":" ","required":"","counter":"30","error-messages":errors},model:{value:(_vm.customer_item.title),callback:function ($$v) {_vm.$set(_vm.customer_item, "title", $$v)},expression:"customer_item.title"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"必須"},model:{value:(_vm.customer_item.required),callback:function ($$v) {_vm.$set(_vm.customer_item, "required", $$v)},expression:"customer_item.required"}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('validation-provider',{staticClass:"requiredMark",attrs:{"rules":"required|numeric|min_value:0|max_value:2147483647","name":"表示順"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"dense":"","hide-details":"auto","label":"表示順","outlined":"","placeholder":" ","required":"","reverse":""},model:{value:(_vm.customer_item.position),callback:function ($$v) {_vm.$set(_vm.customer_item, "position", $$v)},expression:"customer_item.position"}})]}}],null,true)})],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{on:{"click":function($event){_vm.showCustomerItemDialog = false}}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.dialogRegisterButtonClickEvent}},[_vm._v("\n              OK\n            ")])],1)],1)]}}])})],1)],1),_vm._v(" "),_c('Confirm',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }