/**
 * 配送業者
 */
import Vue from "vue";
import * as types from "../../../mutation-types/backyard-types";
import * as httpConst from "../../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/search/delivery_companies`

const search_delivery_company = {
    namespaced: true,
    state: {
        content: [],
    },
    getters: {},
    actions: {
        fetchAll({ commit }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH).then(data => {
                    let content = data.content
                    commit(types.BACK_SEARCH_DELIVERY_COMPANY, { content })
                    resolve(content)
                })
            })
        }
    },
    mutations: {
        [types.BACK_SEARCH_DELIVERY_COMPANY] (state, { content }) {
            state.content = content.delivery_companies
        },
    },
}

export default search_delivery_company
