
/**
 * favicon 設定（backyard/admin）
 * frontend は[views/layout/front_application.html.erb]で設定
 */
const FaviconMixin = {
  data() {
    return {
    }
  },
  created() {
    // ファビコン設定
    const link_fav = document.querySelector('#bizRENT_fav')
    link_fav.href = require('../assets/favicon.ico')
  },
  mounted() {},
  filters: {},
  methods: {
  }
}

export default FaviconMixin
