/**
 * alert message
 */

const alertMessage = {
  namespaced: true,
  state: {
    isShow: false,
    color: 'red',
    message: null,
  },
  getters: {},
  actions: {},
  mutations: {
    reset(state) {
      state.isShow = false
      state.message = null
    },
    showError (state, { message }) {
      state.isShow = true
      state.color = 'red'
      state.message = message
    },
  },
}

export default alertMessage
