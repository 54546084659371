<!-- バックヤード > 現地マスタ追加 -->
<template>
  <div>
    <v-breadcrumbs
      class="px-0 py-2"
      :items="breadcrumbs"
    />
    <local-place-form
      v-if="local_place"
      :local_place="local_place"
      :is-edit="false"
    />
  </div>
</template>

<script>
  import Utils from "../../../mixins/Utils";
  import {mapActions} from "vuex";
  import LocalPlaceForm from "./_Form";

  export default {
    name: 'LocalPlaceNew',
    components: {
      LocalPlaceForm,
    },
    mixins: [
      Utils,
    ],
    data () {
      return {
        breadcrumbs: [
          { text: '現地一覧', disabled: false, to: '/backyard/local_places', exact: true },
          { text: '現地新規', disabled: true, to: '' },
        ],
        local_place: null,
      }
    },
    created() {
      this.newLocalPlace().then((content) => {
        this.local_place = content
      })
    },
    methods: {
      ...mapActions('backLocalPlace', {
        newLocalPlace: 'new'
      })
    },
  }
</script>

<style lang="scss">

</style>
