/*
 * 注文詳細 返却申請
 */
import Vue from 'vue'
import * as httpConst from "../../../../constants/httpConst";
import * as types from "../../../mutation-types/frontend-types";

const API_PATH = `${httpConst.FRONTEND_ENDPOINT}/orders`

const extend_order = {
  namespaced: true,
  state: {
    content: {},
    total_price: 0,
  },
  getters: {
    hasExtendOrders: ( state ) => {
      return (state.content && state.content.extend_orders && state.content.extend_orders.length > 0)
    },
    totalPrice: (state) => {
      return state.total_price
    }
  },
  actions: {
    fetchAll({ commit }, {order_id}) {
      const api_path = `${API_PATH}/${order_id}/extend_orders`
      return new Promise((resolve, _reject) => {
        Vue.$http.get(api_path).then(data => {
          let content = data.content
          commit(types.FRONT_EXTEND_ORDER, { content })
          resolve(content)
        })
      })
    },
    create( { commit, state }, { id, params } ) {
      const api_path = `${API_PATH}/${id}/extend_orders`
      return new Promise( ( resolve, _reject) => {
        Vue.$http.post(api_path, params).then( data => {
          let content = data.content
          resolve(content)
        } )
      })
    },
    update({state, commit}, { id, params }) {
      const oldContent = state.content.extend_orders.find((o) => {
        return o.id === id;
      });
      commit(types.FRONT_EXTEND_ORDER_UPDATE, { oldContent, params })
    },
    resetExtendState({commit}) {
      commit(types.FRONT_EXTEND_ORDER_RESET)
    },
  },
  mutations: {
    [types.FRONT_EXTEND_ORDER] (state, { content }) {
      state.content = content
    },
    [types.FRONT_EXTEND_ORDER_UPDATE] (state, { oldContent, params }) {
      if (oldContent) {
        oldContent.extend_days = params.extend_days
        oldContent.extend_return_date = params.extend_return_date
        oldContent.extend_tax_amount = params.extend_tax_amount
        oldContent.extend_total_amount = params.extend_total_amount
      }
      Object.assign(oldContent, state.content);
      let total_price = 0
      for( const item of state.content.extend_orders ) {
        total_price = total_price + item.extend_total_amount
      }
      state.total_price = total_price

    },
    [types.FRONT_EXTEND_ORDER_RESET] (state) {
      let defaultState = { content:{}, total_price: 0 }
      Object.assign(state, defaultState)
    }
  },
}

export default extend_order
