<!-- バックヤード > 商品一覧 -->
<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          商品登録
          <span
            class="float-right"
          >
            <v-btn
              color="primary"
              @click="productCreate"
            >
              商品追加
            </v-btn>
          </span>
        </h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-expansion-panels
          v-model="panel"
          accordion
        >
          <v-expansion-panel>
            <v-expansion-panel-header>検索</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="selectedCategories"
                      :items="categoryContent.categories"
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="id"
                      label="カテゴリー"
                      multiple
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="selectedTags"
                      :items="tagContent.tags"
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="id"
                      label="タグ"
                      multiple
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="inputtedPartNo"
                      dense
                      hide-details="auto"
                      label="品番１"
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="inputtedKeyword"
                      dense
                      hide-details="auto"
                      label="キーワード"
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="inputtedReceiveComment"
                      dense
                      hide-details="auto"
                      label="受取場所"
                      outlined
                      placeholder=" "
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-checkbox
                      v-model="enableDisplayDate"
                      label="表示日で絞り込む"
                      hide-details="auto"
                      class="pa-0 ma-0"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-menu
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :disabled="enableDisplayDate === false"
                      transition="scale-transition"
                      min-width="290px"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="displayDate"
                          :disabled="enableDisplayDate === false"
                          clearable
                          dense
                          hide-details="auto"
                          hint="時点表示されているもの"
                          label="表示日"
                          outlined
                          placeholder=" "
                          persistent-hint
                          readonly
                          v-on="on"
                          @click:clear="displayDate = null"
                        />
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="displayDate"
                        @input="dateMenu = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-checkbox
                      v-model="selectedNewArrival"
                      label="新着商品のみ"
                      hide-details="auto"
                      class="pa-0 ma-0"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-checkbox
                      v-model="selectedOnlyPublished"
                      label="非公開を除く"
                      hide-details="auto"
                      class="pa-0 ma-0"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-checkbox
                      v-model="selectedOnlyUninspected"
                      label="検品中のみ"
                      hide-details="auto"
                      class="pa-0 ma-0"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-checkbox
                      v-model="selectedLocalStockItem"
                      label="現地在庫品"
                      hide-details="auto"
                      class="pa-0 ma-0"
                    />
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="resetProducts"
                  >
                    検索
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card outlined>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th />
                  <th class="text-left" />
                  <th>No</th>
                  <th
                    class="text-center"
                  >
                    品番1/商品名
                  </th>
                  <th
                    class="text-center"
                  >
                    セット商品
                  </th>
                  <th
                    class="text-center"
                  >
                    現地在庫品
                  </th>
                  <th
                    class="text-center"
                  >
                    スケジュール</br>使用
                  </th>
                  <th
                    class="text-left"
                  >
                    カテゴリー
                  </th>
                  <th
                    class="text-left"
                  >
                    タグ
                  </th>
                  <th
                    class="text-left"
                  >
                    表示期間
                  </th>
                  <th class="text-center">
                    支払方法
                  </th>
                  <th
                    class="text-center"
                  >
                    公開設定
                  </th>
                  <th
                    class="text-center"
                  >
                    表示順
                  </th>
                  <th
                    class="text-center"
                  >
                    編集
                  </th>
                  <th
                    class="text-center"
                  >
                    複写
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="product in products"
                  :key="product.id"
                >
                  <td>
                    <v-chip
                      v-if="product.new_arrival === true"
                      small
                      label
                      color="yellow lighten-2"
                    >
                      新着
                    </v-chip>
                  </td>
                  <td>
                    <v-img
                      v-if="product.image_url !== null"
                      :src="product.image_url"
                      max-height="40"
                      max-width="50"
                      contain
                    />
                  </td>
                  <td class="text-right">
                    {{ product.no }}
                  </td>
                  <td>
                    {{ product.part_number_1 }}<br />{{ product.name }}
                  </td>
                  <td
                    class="text-center"
                  >
                    {{ product.component_item ? '◯' : '' }}
                  </td>
                  <td
                    class="text-center"
                  >
                    {{ product.local_stock_item ? '◯' : '' }}
                  </td>
                  <td
                    class="text-center"
                  >
                    {{ product.use_calendar ? '◯' : '' }}
                  </td>
                  <td style="">
                    <span
                      class="wrap_text"
                      style="width: 180px;font-size: 80%"
                    >
                      {{ product.category_names }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="wrap_text"
                      style="width: 180px;font-size: 80%"
                    >
                      {{ product.tag_names }}
                    </span>
                  </td>
                  <td style="font-size: 90%">
                    {{ product.display_period_start_date | from_to_date_format(product.display_period_end_date) }}
                  </td>
                  <td class="text-center">
                    {{ product.payment_type | payment_label }}
                  </td>
                  <td class="text-center">
                    {{ product.published ? '公開中' : '非公開' }}
                  </td>
                  <td class="text-right">
                    {{ product.position }}
                  </td>
                  <td class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          icon
                          color="cyan darken-2"
                          @click="productEdit(product)"
                          v-on="on"
                        >
                          <v-icon>mdi-file-edit-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>編集</span>
                    </v-tooltip>
                  </td>
                  <td class="text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          icon
                          color="cyan darken-2"
                          @click="productDuplicateButtonClick(product)"
                          v-on="on"
                        >
                          <v-icon>mdi-content-duplicate</v-icon>
                        </v-btn>
                      </template>
                      <span>複写</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="isProducts"
        cols="12"
      >
        <v-pagination
          v-model="page"
          :length="pagination.total_pages"
          :total-visible="totalVisible"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          circle
          @input="changePage"
        />
      </v-col>
    </v-row>
    <Confirm ref="confirm" />
  </div>
</template>

<script>
  import Confirm from "../../../components/Confirm";
  import { mapState, mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      Confirm,
    },
    mixins: [
    ],
    data () {
      return {
        page: 1,
        filterDialog: false,
        totalVisible: 7,
        panel:[],
        selectedTags: [],
        selectedCategories: [],
        selectedNewArrival: false,
        selectedOnlyPublished: false,
        selectedOnlyUninspected: false,
        selectedLocalStockItem: false,
        inputtedPartNo: null,
        inputtedKeyword: null,
        inputtedReceiveComment: null,
        enableDisplayDate: false,
        displayDate: new Date().toISOString().substr(0, 10),
        products: [],
        dateMenu: false,
      }
    },
    computed: {
      ...mapState('backSearchCategory', {
        categoryContent: 'content'
      }),
      ...mapState('backSearchTag', {
        tagContent: 'content'
      }),
      ...mapState('backProduct', {
        pagination: 'pagination',
        searchQuery: 'query',
      }),
      ...mapGetters('backProduct', [
        'isProducts'
      ])
    },
    /**
     * 状態監視
     */
    watch: {
      selectedCategories (newVal) {
        this.fetchQuery({ category_ids: newVal })
      },
      selectedTags (newVal) {
        this.fetchQuery({ tag_ids: newVal })
      },
      inputtedPartNo (newVal) {
        this.fetchQuery({ part_no: newVal })
      },
      inputtedKeyword(newVal) {
        this.fetchQuery({ keyword: newVal })
      },
      selectedNewArrival(newVal) {
        this.fetchQuery({ new_arrival: newVal })
      },
      enableDisplayDate(newVal) {
        this.fetchQuery({ enable_display_date: newVal })
      },
      displayDate(newVal) {
        this.fetchQuery({ display_date: newVal })
      },
      selectedOnlyPublished(newVal) {
        this.fetchQuery({ only_published: newVal })
      },
      selectedOnlyUninspected(newVal) {
        this.fetchQuery({ only_uninspected: newVal })
      }
    },
    created() {
      if (this.$route.params.isClearCondition) {
        this.resetQuery()
      }
      const searchPartNumber = this.$route.query.part_no
      if (searchPartNumber) {
        this.inputtedPartNo = searchPartNumber
      } else {
        this.inputtedPartNo = this.searchQuery.part_no
      }
      const searchOnlyUninspected = this.$route.query.only_uninspected
      if (searchOnlyUninspected) {
        this.selectedOnlyUninspected = searchOnlyUninspected
      } else {
        this.selectedOnlyUninspected = this.searchQuery.only_uninspected
      }

      this.selectedCategories = this.searchQuery.category_ids
      this.selectedTags = this.searchQuery.tag_ids
      this.inputtedKeyword = this.searchQuery.keyword
      this.inputtedReceiveComment = this.searchQuery.receive_comment
      this.selectedNewArrival = this.searchQuery.new_arrival
      this.enableDisplayDate = this.searchQuery.enable_display_date
      this.displayDate = this.searchQuery.display_date
      this.selectedOnlyPublished = this.searchQuery.only_published
      this.selectedLocalStockItem = this.searchQuery.local_stock_item
      this.fetchSearchCategory()
      this.fetchSearchTag()
      this.page = this.pagination.current_page
      this.searchProducts()
    },
    methods: {
      ...mapActions('backSearchCategory', {
        fetchSearchCategory: 'fetch'
      }),
      ...mapActions('backSearchTag', {
        fetchSearchTag: 'fetch'
      }),
      ...mapActions('backProduct', {
        fetchProducts: 'fetchProducts',
        fetchQuery: 'fetchQuery',
        resetQuery: 'resetQuery',
        duplicateProduct: 'duplicate'
      }),
      productCreate () {
        this.$router.push({name: 'byProductNew'})
      },
      productEdit (product) {
        this.$router.push({name: 'byProductEdit', params: {id: product.id}})
      },
      productDuplicateButtonClick (product) {
        this.$refs.confirm.showDuplicateConfirm().then((confirm) => {
          this.$refs.confirm.close()
          if (confirm) {
            this.duplicateProduct({id: product.id }).then(() =>{
              this.searchProducts()
            })
          }
        })
      },
      resetProducts () {
        this.panel=[]
        this.page = 1
        this.searchProducts()
      },
      changePage () {
        this.searchProducts()
      },
      searchProducts () {
        const params = {
          category_ids: this.selectedCategories,
          tag_ids: this.selectedTags,
          part_no: this.inputtedPartNo,
          keyword: this.inputtedKeyword,
          receive_comment: this.inputtedReceiveComment,
          new_arrival: this.selectedNewArrival,
          enable_display_date: this.enableDisplayDate,
          display_date: this.displayDate,
          only_published: this.selectedOnlyPublished,
          only_uninspected: this.selectedOnlyUninspected,
          local_stock_item: this.selectedLocalStockItem,
          page: this.page
        }
        this.fetchProducts(params).then((content) => {
          this.products = content.products
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .wrap_text {
    display: inline-block;
    overflow-wrap: break-word;
  }

  th, td {
    padding: 3px !important;
  }

</style>
