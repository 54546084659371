/**
 * 会員汎用項目設定
 */
import Vue from "vue";
import * as types from "../../mutation-types/backyard-types";
import * as httpConst from "../../../constants/httpConst";

const API_PATH = `${httpConst.BACKYARD_ENDPOINT}/customer_items`

const customer_item = {
    namespaced: true,
    state: {
        customer_items: []
    },
    getters: {
        canAddCustomerItem: ( state ) => {
            return (state.customer_items.length < 20)
        },
    },
    actions: {
        fetchAll({ commit, state }) {
            return new Promise((resolve, _reject) => {
                Vue.$http.get(API_PATH).then(data => {
                    let content = data.content
                    commit(types.BACK_CUSTOMER_ITEM, { content })
                })
            })
        },
        new ({ _commit }) {
            const api_path = `${API_PATH}/new`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
        edit({ _commit, state }, { id }) {
            const api_path = `${API_PATH}/${id}/edit`
            return new Promise((resolve, _reject) => {
                Vue.$http.get(api_path).then(data => {
                    let content = data.content
                    resolve(content)
                })
            })
        },
        create( { commit, state }, { params } ) {
            return new Promise( ( resolve, _reject) => {
                Vue.$http.post(API_PATH, params).then( data => {
                    commit('snackbar/showInfo', { message: '登録しました' }, { root: true })
                    let content = data.content
                    commit(types.BACK_CUSTOMER_ITEM_ADD, { content })
                    resolve(content)
                } )
            })
        },
        update( {commit, state}, { id, params } ) {

            const oldContent = state.customer_items.find((o) => {
                return o.id === id;
            });
            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject) => {
                Vue.$http.patch(api_path, params).then( data => {
                    commit('snackbar/showInfo', { message: '更新しました' }, { root: true })
                    let content = data.content
                    commit(types.BACK_CUSTOMER_ITEM_UPDATE, { oldContent, content })
                    resolve(content)
                } )
            })
        },
        destroy( { commit, state }, { id } ) {

            const remove_id = id

            const api_path = `${API_PATH}/${id}`
            return new Promise( ( resolve, _reject ) => {
                Vue.$http.delete(api_path).then(data => {
                    commit('snackbar/showInfo', { message: '削除しました' }, { root: true })
                    let content = data.content
                    commit(types.BACK_CUSTOMER_ITEM_DELETE, { remove_id })
                    resolve(content)
                })
            })
        },
    },
    mutations: {
        [types.BACK_CUSTOMER_ITEM] (state, { content }) {
            state.customer_items = content.customer_items
        },
        [types.BACK_CUSTOMER_ITEM_ADD] (state, { content }) {
            state.customer_items.push(content)
        },
        [types.BACK_CUSTOMER_ITEM_UPDATE] (state, { oldContent, content }) {
            Object.assign(oldContent, content);
        },
        [types.BACK_CUSTOMER_ITEM_DELETE] (state,  { remove_id }) {
            state.customer_items = state.customer_items.filter( o => o.id !== remove_id )
        }

    },
}

export default customer_item